<template>
  <div>
    <JazzListPage
      v-show="!showDetailFGTS"
      @search="searchAnticipations"
      :fields="fields"
      hide-button-header
    >
      <div class="header">
        <JazzTable
          :loading="loadingTable"
          :header="header"
          :styleHeaderCel="styleHeaderCel"
          :style-header="styleHeader"
          :rows="anticipations"
          :pagination="pagination"
          @inputNewPage="paginate($event)"
        >
          <template slot="reportRow" slot-scope="props">
            <AnticipationRow
              v-if="props.paramsItem.statusContratoAntecipacao !== 'cancelado'"
              :anticipations="anticipations"
              @detailsFGTS="detailsFGTS($event)"
              :chaveDeConta="chaveDeConta"
              :paramsItem="props.paramsItem"
              :anticipation="anticipation || anticipationType"
              :filters="fields"
              :pagination="pagination"
            />
          </template>
        </JazzTable>
      </div>
    </JazzListPage>
    <div v-if="showDetailFGTS" class="details-fgts">
      <JazzListHeader
        class="mt-8"
        @backBreadcrumb="showDetailFGTS = false"
        title="Antecipações"
        breadcrumb="FGTS"
        hide-button-header
      />
      <JazzFormFields
        v-if="showDetailFGTS"
        class="mt-7"
        :fields="fieldsDetailFGTS"
      />
      <JazzDataTable
        :header="tableDetails.header"
        :items="tableDetails.items"
      />
    </div>
  </div>
</template>

<script>
import JazzTable from "@/components/table/JazzTable";
import AnticipationRow from "@/components/rowsTable/AnticipationRow";
import JazzListPage from "@/components/template/JazzListPage";
import globalMethods from "@/mixins/globalMethods";
import { AntecipacaoFgtsService } from "@/services/api";
import JazzListHeader from "@/components/template/JazzListHeader";
import JazzFormFields from "@/components/form/JazzFormFields";
import JazzDataTable from "@/components/table/JazzDataTable";
import { AntecipacaoSalarialBackofficeService } from "@/services/apibackoffice";

export default {
  name: "AccountAnticipation",
  components: {
    JazzDataTable,
    JazzFormFields,
    JazzListHeader,
    JazzListPage,
    JazzTable,
    AnticipationRow,
  },
  mixins: [globalMethods],
  props: {
    account: Object,
  },
  data() {
    return {
      showDetailFGTS: false,
      searchForm: {},
      fieldsDetailFGTS: {},
      anticipationType: 1,
      loadingTable: false,
      fields: {},
      filters: null,
      anticipation: null,
      pagination: {
        page: 1,
        perPage: 45,
      },
      contractNumberFilter: false,
      styleHeader: {
        width: "100%",
        height: "70px",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        alignContent: "center",
        textAlign: "center",
      },
      styleHeaderCel: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "14%",
        textAlign: "center",
        fontSize: "14px",
      },
      header: [
        {
          title: "Status",
          align: "center",
        },
        {
          title: "Tipo de contrato",
          align: "center",
        },
        {
          title: "Número de contrato",
          align: "center",
        },
        {
          title: "Data do contrato",
          align: "center",
        },
        {
          title: "Valor do contrato",
          align: "center",
        },
        {
          title: "Parcelas",
          align: "center",
        },
        {
          title: "Valor Parcela",
          align: "center",
        },
      ],
      anticipations: [],
      tableDetails: {
        header: [],
        items: [],
      },
      chaveDeConta:
        this.params?.chaveDeConta || this.$route.params?.chaveDeConta,
    };
  },
  mounted() {
    if (this.$route.params?.anticipations) {
      this.anticipations = this.$route.params?.anticipations;
      this.fields = this.$route.params?.filters;
      this.anticipation = this.fields.tipoContratoAntecipacao.valueField.value;
      this.pagination = this.$route.params?.pagination;
    } else {
      this.createFilters();
    }
  },
  methods: {
    detailsFGTS(fgts) {
      const {
        contrato,
        localizador,
        financiador,
        statusContratoAntecipacao,
        data,
        valorFinanciado,
        valorLiberado,
        taxaJuros,
        prazo,
        dataConclusao,
      } = fgts.response;

      this.fieldsDetailFGTS = {
        numeroProposta: {
          label: "Código do Contrato",
          field: "TextField",
          valueField: contrato,
          disabled: true,
          cols: 3,
        },
        localizador: {
          label: "Localizador",
          field: "TextField",
          disabled: true,
          valueField: localizador,
          cols: 3,
        },
        financiador: {
          label: "Banco Financiador",
          field: "TextField",
          disabled: true,
          valueField: financiador,
          cols: 3,
        },
        statusContrato: {
          label: "Status do Contrato",
          field: "TextField",
          disabled: true,
          valueField: statusContratoAntecipacao,
          cols: 3,
        },
        dataContrato: {
          label: "Data do Contrato",
          field: "TextField",
          disabled: true,
          valueField: this.dateFormat(data),
          cols: 4,
        },
        valorBloqueadoFGTS: {
          label: "Valor Bloqueado FGTS",
          field: "TextField",
          disabled: true,
          valueField: `R$ ${this.money2(valorFinanciado)}`,
          cols: 4,
        },
        valorCreditado: {
          label: "Valor Creditado em Conta",
          field: "TextField",
          disabled: true,
          valueField: `R$ ${this.money2(valorLiberado)}`,
          cols: 4,
        },
        periodoAntecipado: {
          label: "Periodo Antecipado",
          field: "TextField",
          disabled: true,
          valueField: `${prazo} ano(s)`,
          cols: 4,
        },
        terminoContrato: {
          label: "Término do Contrato",
          field: "TextField",
          disabled: true,
          valueField: this.dateFormat(dataConclusao),
          cols: 4,
        },
        taxaJuros: {
          label: "Taxa de Juros",
          field: "TextField",
          disabled: true,
          valueField: `${this.money2(taxaJuros)}% ao mês`,
          cols: 4,
        },
      };

      this.tableDetails.header = [
        { text: "Parcelas", sortable: false, value: "dataVencimento" },
        { text: "Valor", sortable: false, value: "valor" },
        { text: "Status", sortable: false, value: "status" },
      ];
      this.tableDetails.items = fgts.response.recebiveis.map((item) => {
        return {
          dataVencimento: this.dateFormat(item.dataVencimento),
          valor: `R$ ${this.money2(item.valor)}`,
          status: item.status || "--",
        };
      });
      this.showDetailFGTS = true;
    },
    changeFilters(contractNumber) {
      let disabled = contractNumber.length > 0;
      this.contractNumberFilter = disabled;
      this.fields.statusContratoAntecipacao.disabled = disabled;
      this.fields.dataFinal.disabled = disabled;
      this.fields.dataInicial.disabled = disabled;
    },
    createFilters() {
      this.fields = {
        tipoContratoAntecipacao: {
          label: "Tipo de contrato",
          field: "SelectField",
          keyName: "name",
          keyValue: "value",
          cols: 6,
          items: [
            {
              name: "Selecione",
              value: 0,
            },
            {
              name: "Antecipação FGTS",
              value: 1,
            },
            {
              name: "Crédito Privado",
              value: 2,
            },
          ],
          valueField: {
            value: 0,
          },
          rules: [
            (fields) =>
              fields?.tipoContratoAntecipacao?.valueField?.value !== 0 ||
              "Tipo de contrato inválido!",
          ],
          changeValue: (fields) => this.changeAnticipationType(fields),
        },
        numeroContrato: {
          label: "Número do contrato",
          field: "TextFieldMask",
          placeholder: "ex.: 123456789",
          maskFormat: "##########################",
          valueField: "",
          helpText:
            "Caso o número de contrato seja preenchido, os campos abaixo serão desconsiderados!",
          changeValue: (fields) =>
            this.changeFilters(fields.numeroContrato.valueField),
          cols: 6,
        },
        statusContratoAntecipacao: {
          label: "Status do contrato",
          field: "SelectField",
          keyName: "name",
          keyValue: "value",
          cols: 3,
          items: [],
          valueField: {
            name: "Todos",
            value: undefined,
          },
        },
        dataInicial: {
          label: "Data inicial",
          field: "DateField",
          cols: 3,
          rules: [
            (fields) => {
              let fieldValue = fields.dataInicial.valueField;
              let today = this.dateNowFormat("YYYY-MM-DD");

              return (
                this.initialDateValidation(today, fieldValue) ||
                "Data inicial deve ser anterior a hoje!"
              );
            },
          ],
          valueField: this.dateSubtract30Days(),
        },
        dataFinal: {
          label: "Data final",
          field: "DateField",
          cols: 3,
          rules: [
            (fields) => {
              let initialDate = fields.dataInicial.valueField;
              let finalDate = fields.dataFinal.valueField;

              return (
                this.compareFinalDate(initialDate, finalDate) ||
                "Data final deve ser igual ou posterior a data inicial!"
              );
            },
          ],
          valueField: this.dateEnFormat(),
        },
      };
    },
    compareFinalDate(initialDate, finalDate) {
      let initial = this.getDateNumbers(initialDate);
      let final = this.getDateNumbers(finalDate);

      if (final.year === initial.year) {
        if (final.month === initial.month) {
          if (final.day >= initial.day) {
            return true;
          }
        } else if (final.month > initial.month) {
          return true;
        }
      } else if (final.year > initial.year) {
        return true;
      }

      return false;
    },
    initialDateValidation(todayDate, compareDate) {
      let today = this.getDateNumbers(todayDate);
      let compare = this.getDateNumbers(compareDate);

      if (compare.year < today.year) {
        return true;
      } else if (compare.year === today.year && compare.month < today.month) {
        return true;
      } else if (
        compare.year === today.year &&
        compare.month === today.month &&
        compare.day < today.day
      ) {
        return true;
      }

      return false;
    },
    //Formato de entrada YYYY-MM-DD ou YYYY/MM-DD
    getDateNumbers(date) {
      return {
        year: date.substring(0, 4),
        month: date.substring(5, 7),
        day: date.substring(8, 10),
      };
    },
    searchAnticipationFGTS(fields, page = 0) {
      this.loadingTable = true;
      let params = {};
      if (!this.contractNumberFilter && fields) {
        this.filters = {
          dataInicio: fields.values.dataInicial,
          dataFim: fields.values.dataFinal,
          situacaoContrato: fields?.values?.statusContratoAntecipacao?.value,
        };
      } else {
        this.filters = {
          numeroDoContrato: fields.values.numeroContrato,
        };
      }
      params = {
        ...this.filters,
        pagina: page,
        chaveDeConta: this.chaveDeConta,
        porPagina: 45,
        cancelarPropostasPendentes: false,
      };
      AntecipacaoFgtsService.contratos(params)
        .then((res) => {
          this.anticipations = res?.result?.items;
          this.pagination.page = res?.result?.pagina;
          this.pagination.perPage = res?.result?.qtdPorPagina;
          this.pagination.total = res?.result?.totalItems;
          this.pagination.pages = res?.result?.qtdPaginas;
        })
        .finally(() => (this.loadingTable = false));
    },
    searchAnticipationSallary(page) {
      this.loadingTable = true;
      let params = {};
      let fields = this.fields;

      if (!fields.numeroContrato.valueField) {
        this.filters = {
          dataInicial: fields.dataInicial.valueField,
          dataFinal: fields.dataFinal.valueField,
          statusContratoAntecipacao:
            fields?.statusContratoAntecipacao?.valueField.value,
        };
      } else {
        this.filters = {
          numeroDoContrato: fields.numeroContrato.valueField,
        };
      }
      params = {
        ...this.filters,
        pagina: page,
        chaveDeConta: this.chaveDeConta,
        porPagina: this.pagination.perPage,
      };

      AntecipacaoSalarialBackofficeService.contratos(params)
        .then((res) => {
          this.anticipations = res?.result?.items;
          this.pagination.page = res?.result?.pagina;
          this.pagination.perPage = res?.result?.qtdPorPagina;
          this.pagination.total = res?.result?.totalItems;
          this.pagination.pages = res?.result?.qtdPaginas;
        })
        .catch((e) => e)
        .finally(() => (this.loadingTable = false));
    },
    searchAnticipations(fields, page = 1) {
      this.searchForm = fields;
      this.anticipations = [];
      if (fields?.values?.tipoContratoAntecipacao?.value) {
        this.anticipationType = fields?.values?.tipoContratoAntecipacao?.value;
      }
      if (this.anticipationType === 2) {
        this.searchAnticipationSallary(page);
      } else if (this.anticipationType === 1) {
        this.searchAnticipationFGTS(fields, page);
      }
    },
    paginate(page) {
      this.anticipations = [];
      this.anticipationType =
        this.searchForm?.values?.tipoContratoAntecipacao?.value;
      if (this.anticipationType === 2) {
        this.searchAnticipationSallary(page);
      } else if (this.anticipationType === 1) {
        this.searchAnticipationFGTS(this.searchForm, page);
      }
    },
    changeAnticipationType(fields) {
      const { statusContratoAntecipacao, tipoContratoAntecipacao } = fields;
      if (tipoContratoAntecipacao?.valueField?.value === 2) {
        statusContratoAntecipacao["items"] = [
          {
            name: "Todos",
            value: 1,
          },
          {
            name: "Efetivado",
            value: 2,
          },
          {
            name: "Liquidado",
            value: 3,
          },
          {
            name: "Pendente de análise",
            value: 5,
          },
          {
            name: "Análise de crédito",
            value: 6,
          },
          {
            name: "Em Andamento",
            value: 7,
          },
        ];
      } else {
        statusContratoAntecipacao["items"] = [
          {
            name: "Todos",
            value: undefined,
          },
          {
            name: "Efetivado",
            value: 1,
          },
          {
            name: "Pendente de assinatura",
            value: 2,
          },
          {
            name: "Cancelado",
            value: 3,
          },
        ];
      }
      statusContratoAntecipacao.valueField = statusContratoAntecipacao.items[0];
      this.filters = null;
    },
  },
};
</script>

<style scoped lang="scss">
.header {
  margin-top: 30px !important;
}
</style>
