<template>
  <JazzListPage
    :loading="loadingFilters"
    :disableSearchButton="loadingFilters"
    :fields="fields"
    title="Comunicados"
    @search="searchCommunications"
    :creationRoute="creationRoute"
    button-with-label
    @createCommunication="showModal = true"
  >
    <JazzSkeletonLoader class="mt-15" v-if="loading" type="cards" />
    <div v-if="hasCommunication && !loading">
      <v-tabs
        centered
        background-color="transparent"
        slider-color="var(--primaryColor)"
      >
        <v-tab :to="{ name: 'ActiveTab' }" data-cy="active-tab">
          Ativos
        </v-tab>
        <v-tab :to="{ name: 'ScheduleTab' }" data-cy="schedule-tab">
          Agendados
        </v-tab>
        <v-tab :to="{ name: 'InactiveTab' }" data-cy="inactive-tab">
          Inativos
        </v-tab>
      </v-tabs>
      <div class="content-tabs">
        <transition name="fade" mode="out-in">
          <keep-alive>
            <router-view
              :communications="communications"
              class="scroll-box"
              @edit="
                selectedCard = $event;
                type = 'edit';
              "
              @delete="deleteSelectedCard = $event"
            />
          </keep-alive>
        </transition>
      </div>
    </div>
    <ContentModal
      v-if="showModal"
      title="Comunicado"
      @close="
        showModal = false;
        selectedCard = null;
        type = '';
      "
      @save="type === 'edit' ? updateCommunication() : createCommunication()"
      :showDeleteButton="type === 'edit' ? true : false"
      @delete="confirmationModalShow = true"
      :disable-button="disabled"
    >
      <CommunicationForm
        :selectedCard="selectedCard"
        :tesouraryAccounts="tesouraryAccounts"
        @enableButton="enableButton($event)"
      />
    </ContentModal>
    <ConfirmationModal
      v-if="confirmationModalShow"
      :title="confirmationModal.title"
      :icon="confirmationModal.icon"
      :messages="confirmationModal.message"
      :cancel-text="confirmationModal.cancel"
      :confirm-text="confirmationModal.confirm"
      @confirm="deleteCommunication()"
      @close="confirmationModalShow = false"
    />
  </JazzListPage>
</template>

<script>
import { mapMutations } from "vuex";
import globalMethods from "../../../mixins/globalMethods";
import JazzListPage from "../../../components/template/JazzListPage";
import JazzSkeletonLoader from "../../../components/template/JazzSkeletonLoader";
import ContentModal from "../../../components/modal/ContentModal";
import CommunicationForm from "./components/CommunicationForm";
import ConfirmationModal from "../../../components/modal/ConfirmationModal";
import {
  TesourariasService,
  UnidadesOrganizacionaisService,
} from "../../../services/api";

export default {
  name: "CommunicationIndex",
  components: {
    JazzListPage,
    JazzSkeletonLoader,
    ContentModal,
    CommunicationForm,
    ConfirmationModal
  },
  mixins: [globalMethods],
  props: {
    emit: {
      type: String,
    },
  },
  data() {
    return {
      loadingFilters: true,
      loading: false,
      tesouraryAccounts: [],
      fields: {
        partnerSelectField: {
          valueField: null,
        },
      },
      creationRoute: {
        text: "Adicionar novo comunicado",
        title: null,
        route: null,
        eventName: "createCommunication",
      },
      showModal: false,
      disabled: true,
      type: "",
      payload: {},
      communications: null,
      hasCommunication: false,
      selectedCard: null,
      deleteSelectedCard: null,
      confirmationModal: {
        title: "Confirmação de Exclusão",
        icon: "fas fa-exclamation-circle",
        message: [
          `Ao excluir um comunicado, ele não poderá mais ser visualizado ou consultado`,
        ],
        cancel: "Cancelar",
        confirm: "Confirmar",
      },
      confirmationModalShow: false,
      filters: {},
    };
  },
  async mounted() {
    await this.createFilters();
  },
  methods: {
    ...mapMutations(["CHANGE_LOADING"]),
    async createFilters() {
      this.loadingFilters = true;
      await this.getTesouraryAccounts();
      this.fields = {
        partnerSelectField: {
          label: "Parceiro",
          field: "JazzAutoCompleteField",
          cols: 8,
          items: [
            { organizationUnitId: null, nome: "Selecione" },
            ...this.tesouraryAccounts,
          ],
          keyName: "nome",
          keyValue: "organizationUnitId",
          valueField:
            this.tesouraryAccounts?.length === 1
              ? this.tesouraryAccounts[0]
              : null,
          rules: [
            (fields) =>
              !!fields.partnerSelectField.valueField?.organizationUnitId ||
              "O campo Parceiro é obrigatório",
          ],
        },
      };
      if (this.tesouraryAccounts?.length === 1) {
        this.fields.partnerSelectField.valueField = this.tesouraryAccounts[0];
      }
      this.loadingFilters = false;
    },
    async getTesouraryAccounts() {
      this.tesouraryAccounts =
        await TesourariasService.consultarContaTesouraria().then(
          (response) => response?.result.items || []
        );
    },
    updateCommunication() {
      this.CHANGE_LOADING(true);
      this.showModal = false;
      UnidadesOrganizacionaisService.alterar({
        body: {
          ...this.payload,
          chaveDeCardComunicacao: this.selectedCard?.chaveDeCardComunicacao,
        },
      })
        .then(() => {
          this.$store.dispatch(
            "action_changeMessageSnackBar",
            "Comunicado alterado com sucesso!",
            { module: "template" }
          );
          this.searchCommunications();
        })
        .finally(() => {
          this.CHANGE_LOADING(false);
          this.selectedCard = null;
          this.type = "";
        });
    },
    createCommunication() {
      this.CHANGE_LOADING(true);
      this.showModal = false;
      UnidadesOrganizacionaisService.criar({
        body: this.payload,
      })
        .then(() => {
          this.filters.organizationUnitId = this.payload.organizationUnitId;
          this.fields.partnerSelectField.valueField = this.findObjectFromArray(
            this.tesouraryAccounts,
            "organizationUnitId",
            this.payload.organizationUnitId
          );
          this.searchCommunications();
          this.$store.dispatch(
            "action_changeMessageSnackBar",
            "Comunicado criado com sucesso!",
            { module: "template" }
          );
        })
        .finally(() => {
          this.CHANGE_LOADING(false);
        });
    },
    deleteCommunication() {
      this.CHANGE_LOADING(true);
      this.confirmationModalShow = false;
      this.showModal = false;
      UnidadesOrganizacionaisService.excluir({
        body: {
          chaveDeCardComunicacao: this.selectedCard?.chaveDeCardComunicacao,
        },
      })
        .then(() => {
          this.$store.dispatch(
            "action_changeMessageSnackBar",
            "Comunicado excluído com sucesso!",
            { module: "template" }
          );
          this.searchCommunications();
        })
        .finally(() => {
          this.CHANGE_LOADING(false);
          this.selectedCard = null;
          this.type = "";
        });
    },
    enableButton(e) {
      this.disabled = !e.enable;
      this.payload = e.payload;
    },
    async searchCommunications(fields) {
      if ((fields && fields?.isValid) || !fields) {
        this.loading = true;
        this.loadingTable = true;
        if (fields) {
          const { organizationUnitId } = fields.values.partnerSelectField;
          this.filters = {
            organizationUnitId: organizationUnitId,
          };
        }
        this.communications = await UnidadesOrganizacionaisService.consultar({
          body: this.filters,
        }).then((response) => response?.result.items);
        if (this.communications.length > 0) this.hasCommunication = true;
        this.loading = false;
      }
    },
  },
  watch: {
    selectedCard() {
      if (this.selectedCard) {
        this.showModal = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.v-tabs {
  width: 100% !important;
  overflow-x: hidden;
}

.v-tab {
  font-size: 14px;
  text-transform: none;
  font-weight: normal;
  color: $blackColor !important;
  letter-spacing: 0;
}

.v-tab.v-tab--active {
  font-size: 14px;
  color: $primaryColor !important;
}

.content-tabs {
  margin-top: 15px;
}
.scroll-box {
  height: 700px;
  overflow-y: scroll;
}
</style>
