<template>
  <div class="loading">
    <v-progress-circular
      :size="50"
      color="#002e37"
      indeterminate
    ></v-progress-circular>
  </div>
</template>

<script>
export default {
  name: "MasterLoading",
};
</script>

<style scoped>
.loading {
  background: var(--backgroundColor);
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  cursor: progress;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
