<template>
  <div class="w-100">
    <embed
      :src="`${base64}${
        hideToolbar ? '#toolbar=0&navpanes=0&scrollbar=0' : ''
      }`"
    />
  </div>
</template>

<script>
export default {
  name: "JazzPdfPreview",
  props: {
    base64: {
      type: String,
      required: true,
    },
    hideToolbar: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
embed {
  padding: 15px;
  width: 100%;
  height: 300px;
  @media only screen and (min-width: 1920px) {
    height: 500px;
  }
}
</style>
