<template>
  <div v-if="!loading && accounts">
    <AccountRow
      data-cy="account-row"
      :key="account.chaveDeConta"
      @click.native="openAccount(account)"
      v-for="account in accounts"
      :params-item="account"
    />
    <JazzNotFoundMessage v-if="!loading && accounts.length === 0" />
    <JazzSkeletonLoader v-if="loading" type="table" />
  </div>
</template>

<script>
import AccountRow from "../../../components/rowsTable/AccountRow";
import JazzSkeletonLoader from "../../../components/template/JazzSkeletonLoader";
import { ContasBackofficeService } from "../../../services/apibackoffice";
import JazzNotFoundMessage from "../../../components/typography/JazzNotFoundMessage";

export default {
  name: "ClientAccounts",
  components: { JazzNotFoundMessage, JazzSkeletonLoader, AccountRow },
  data() {
    return {
      loading: true,
      accounts: [],
      account: null,
      params: this.$route.params,
    };
  },
  mounted() {
    this.getAccounts();
  },
  methods: {
    async getAccounts() {
      const { chaveDeCliente } = this.params;
      this.accounts = await ContasBackofficeService.consultarContas({
        body: {
          chaveDeCliente: chaveDeCliente,
        },
      })
        .then((res) => res?.result?.items)
        .finally(() => (this.loading = false));
      if (this.accounts.length === 1) {
        this.openAccount(this.accounts[0]);
      }
    },
    openAccount(account) {
      this.account = account;
      this.$router.push({
        name: "AccountShow",
        params: { ...this.params, chaveDeConta: account?.chaveDeConta },
      });
    },
  },
};
</script>

<style scoped></style>
