<template>
  <div class="w-100">
    <ChangeLimitData :client="client" />
    <ChangeClientData :client="client" class="mb-10"/>
  </div>
</template>

<script>
import globalMethods from "../../../../../mixins/globalMethods";
import ChangeClientData from "../ChangeClientData";
import ChangeLimitData from "../ChangeLimitData";

export default {
  components: {
    ChangeClientData,
    ChangeLimitData,
  },
  name: "TravelClientDataTab",
  mixins: [globalMethods],
  props: {
    client: {
      type: Object,
      default: undefined,
    },
  },
};
</script>

<style scoped></style>
