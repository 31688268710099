<template>
  <div>
    <div class="grid-container">
      <JazzTextGroup
        :label="`Responsável pela ${validateEvent(paramsItem.alteracoes)}`"
        :text="paramsItem.responsavel"
        id="item1"
      />
      <JazzTextGroup
        :label="`Data e Hora da ${validateEvent(paramsItem.alteracoes)}`"
        :text="dateTimeFormat(paramsItem.data)"
        id="item2"
      />
      <JazzTextGroup
        :label="`Valor do Cartão ${validateEvent(
          paramsItem.alteracoes,
          'Valor'
        )}`"
        :text="valueText(paramsItem)"
        :textColor="valueColor(paramsItem)"
        id="item3"
      />
    </div>
  </div>
</template>

<script>
import JazzTextGroup from "../../../../../components/typography/JazzTextGroup";
import globalMethods from "../../../../../mixins/globalMethods";

export default {
  mixins: [globalMethods],
  name: "TravelCardsHistoryRow",
  components: {
    JazzTextGroup,
  },
  props: {
    paramsItem: Object,
  },

  methods: {
    validateEvent(changes, type) {
      if (
        changes[0].valorAntigo === null &&
        parseInt(changes[0].valorNovo) > 0
      ) {
        return type === "Valor" ? "Criado" : "Criação";
      } else {
        return type === "Valor" ? "Alterado" : "Alteração";
      }
    },

    calculateValue(changes) {
      return changes[0].valorNovo - changes[0].valorAntigo;
    },
    valueText(paramsItem) {
      let event = this.validateEvent(paramsItem.alteracoes);

      if (event === "Criação") {
        return `R$ ${this.money2(paramsItem.alteracoes[0].valorNovo)}`;
      } else {
        let valorResult = this.calculateValue(paramsItem.alteracoes);

        if (valorResult > 0) {
          return `+ R$ ${this.money2(valorResult)}`;
        } else if (valorResult < 0) {
          return `- R$ ${this.money2(valorResult * -1)} `;
        }
      }
    },
    valueColor(paramsItem) {
      let event = this.validateEvent(paramsItem.alteracoes);

      if (event === "Criação") {
        return "black";
      } else {
        let valorResult = this.calculateValue(paramsItem.alteracoes);

        if (valorResult > 0) {
          return "green-deep";
        } else if (valorResult < 0) {
          return "dark-red";
        }
      }
    },
  },
};
</script>

<style scoped lang="scss">
.grid-container {
  width: 95%;
  display: grid;
  margin-left: 30px;
  grid-template-columns: [item1]32% [item2 item3]34% 1fr;
}

#item1 {
  grid-row: 1;
  grid-column: 1/2;
  word-break: break-all;
}
#item2 {
  grid-row: 1;
  grid-column: 2/3;
  margin-left: 26px;
}
#item3 {
  grid-row: 1;
  grid-column: 3/4;
  margin-left: 16px;
}
</style>
