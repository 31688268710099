import Dashboard from "../views/Dashboard";

export default [
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
    meta: {
      permission: [],
      permissionName: "",
    },
  },
];
