const forge = require("node-forge");

export class SymmetricCryptoForge {
  constructor() {
    this.ivSize = 128;
    this.saltSize = 128;
  }

  encrypt(input) {
    let cipher = forge.cipher.createCipher("AES-GCM", this.key);
    cipher.start({ iv: this.iv });
    cipher.update(forge.util.createBuffer(input, "utf8"));
    cipher.finish();
    const tag = cipher.mode.tag;
    return `${forge.util.encode64(
      cipher.output.getBytes()
    )}${forge.util.bytesToHex(tag.getBytes())}`;
  }

  decrypt(input) {
    const tag = input.slice(input.length - 32);
    input = input.substr(0, input.length - 32);

    let deCipher = forge.cipher.createDecipher("AES-GCM", this.key);
    deCipher.start({
      iv: this.iv,
      tag: tag,
      tagLength: 256,
    });
    deCipher.update(forge.util.createBuffer(forge.util.decode64(input)));
    deCipher.finish();
    return forge.util.decodeUtf8(deCipher.output.getBytes());
  }

  getAlgKeyAndIv() {
    return `aes-256-gcm;${forge.util.bytesToHex(
      this.key
    )};${forge.util.bytesToHex(this.iv)}`;
  }

  generateSecret(password = "", isCypress) {
    const salt = forge.random.getBytesSync(this.saltSize / 8);
    const secret = `${password}${
      window.crypto.getRandomValues(new Uint32Array(1))[0]
    }`.substring(0, 128);
    this.iv = isCypress
      ? ";_Ü:ÕZS¤_¤È'Þ%5"
      : forge.random.getBytesSync(this.ivSize / 8);
    this.key = isCypress
      ? "yLÎkL^ïEèÀ}ü*êdç3â{Cd\t~ÿµE|"
      : forge.pkcs5.pbkdf2(secret, salt, 1000, 32);
  }
}
