<template>
  <v-col class="box" :cols="cols">
    <v-checkbox
      :data-cy="dataCy"
      :class="rules ? 'has-rules' : ''"
      class="checkobox"
      off-icon="far fa-square"
      on-icon="fas fa-check-square"
      v-model="check"
      @change="$emit('update:value', check), $emit('changeValue')"
      :label="label"
    >
    </v-checkbox>
  </v-col>
</template>

<script>
export default {
  name: "CheckBox",
  props: ["dataCy", "label", "cols", "rules", "valueField"],
  data() {
    return {
      check: false,
    };
  },
  // watch:{
  //     valueField(){
  //         this.check = this.valueField;
  //         this.$emit('update:value', this.text);
  //     }
  // },
  mounted() {
    this.check = this.valueField;
    this.$emit("update:value", this.check);
  },
};
</script>

<style scoped>
.v-input {
  margin: 0 !important;
  padding: 0 !important;
}

.v-input >>> i {
  font-size: 20px;
  color: var(--blackColor);
}

.v-input >>> .v-input__slot {
  min-height: 48px !important;
  margin: 0;
}

.v-input >>> label {
  color: var(--grayColor) !important;
  font-family: "Roboto", sans-serif;
  font-size: 12px !important;
}

.v-input.v-input--is-label-active >>> i {
  color: var(--blackColor) !important;
}

.v-input >>> .v-messages {
  display: none;
}

.v-input.has-rules >>> .v-messages {
  display: block;
}
</style>
