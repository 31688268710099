<template>
  <v-col>
    <button
      :class="`${backgroundColor}-color`"
      :disabled="loading"
      class="search-button"
    >
      {{ text || "Pesquisar" }}
    </button>
  </v-col>
</template>

<script>
export default {
  name: "SearchButton",
  props: {
    loading: Boolean,
    text: String,
    backgroundColor: {
      type: String,
      default: () => "primary",
    },
  },
};
</script>

<style scoped lang="scss">
.search-button {
  width: 100%;
  height: 48px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  color: #ffffff;
  font-weight: bold;
}
.search-button:hover {
  cursor: pointer;
}
.primary-color {
  background-color: $primaryColor;
  border: 1px solid $primaryColor;
}
.secondary-color {
  background-color: $secondaryColor;
  border: 1px solid $secondaryColor;
}
.error-color {
  background-color: $dangerColor;
  border: 1px solid $dangerColor;
}
.white-color {
  background-color: $whiteColor;
  border: 1px solid $whiteColor;
}
.black-color {
  background-color: $blackColor;
  border: 1px solid $blackColor;
}
.grey-color {
  background-color: $grayColor;
  border: 1px solid $grayColor;
}
.success-color {
  background-color: $successColor;
  border: 1px solid $successColor;
}
</style>
