<template>
  <div>
    <v-card outlined class="card-image">
      <JazzImagePreview
        :imageText="paramsItem.mensagem"
        :url="paramsItem.imagem"
        size="small"
      >
      </JazzImagePreview>
      <div class="row-text">
        <JazzText class="w-90" weight="bold" size="subtitle" text-align="left">
          {{ paramsItem.mensagem }}
        </JazzText>
        <v-btn
          v-if="paramsItem.link"
          icon
          fab
          x-small
          @click="openUrl(paramsItem.link)"
          title="Abrir link em uma nova página"
        >
          <v-icon size="15" color="blue">fas fa-link</v-icon>
        </v-btn>
      </div>
      <JazzTextGroup class="row-date" :label="dateText" :text="effectiveDate" />
      <DefaultButton
        data-cy="edit-card-button"
        text="Editar"
        class="button-edit"
        @click.native="$emit('edit', paramsItem)"
      />
    </v-card>
  </div>
</template>
<script>
import JazzImagePreview from "../../../../components/template/JazzImagePreview";
import JazzText from "../../../../components/typography/JazzText";
import JazzTextGroup from "../../../../components/typography/JazzTextGroup";
import DefaultButton from "../../../../components/button/DefaultButton";
import globalMethods from "../../../../mixins/globalMethods";

export default {
  name: "CardCommunicationColumn",
  components: { JazzImagePreview, JazzText, JazzTextGroup, DefaultButton },
  mixins: [globalMethods],
  props: {
    paramsItem: {
      type: Object,
    },
    tab: {
      type: String,
      default: "active",
      validator: (propsTab) =>
        ["active", "scheduled", "inactive"].includes(propsTab),
    },
  },
  methods: {
    openLink(url) {
      window.open(url, "_blank");
    },
  },
  computed: {
    effectiveDate() {
      const { inicioVigencia, fimVigencia } = this.paramsItem;
      let data = "";
      if (inicioVigencia && fimVigencia) {
        if (this.tab === "scheduled") {
          data = this.dateTimeFormatMinutes(inicioVigencia).split(" ");
        } else {
          data = this.dateTimeFormatMinutes(fimVigencia).split(" ");
        }
      } else {
        data = ["--/--/----", "--:--"];
      }
      return `${data[0]} às ${data[1]}`;
    },
    dateText() {
      const tabList = {
        active: "Ativo até",
        scheduled: "Ativo à partir de",
        inactive: "Inativada em",
      };
      return tabList[this.tab] || "Ativo em";
    },
  },
};
</script>
<style scoped lang="scss">
.card-image {
  width: 190px;
  height: 450px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin: 15px;
  padding: 10px;
  border-radius: 10px !important;
  align-items: center;
}

.row-text {
  width: 180px;
  display: flex;
  flex-direction: row;
  padding: 0 10px 0 10px;
  align-items: center;
  justify-content: space-between;
}
.row-date {
  width: 90%;
}
</style>
