import TravelInvoicesIndex from "../../views/Travel/Invoices/TravelInvoicesIndex";
import ReportDetails from "../../views/Travel/Invoices/components/ReportDetails";
import GenerateReportTab from "../../views/Travel/Invoices/components/tab/GenerateReportTab";
import GenerateTicketTab from "../../views/Travel/Invoices/components/tab/GenerateTicketTab";

export default [
  {
    path: "/travel/faturas",
    name: "TravelInvoicesIndex",
    component: TravelInvoicesIndex,
    meta: {
      permission: ["Pages.TravelFaturas"],
      permissionName: "Travel",
    },
  },
  {
    path: "/travel/faturas/:razaoSocial/:identificacaoFiscal/:chaveDeLote/:dataVencimento/:statusLote/detalhes",
    name: "ReportDetails",
    component: ReportDetails,
    redirect: { name: "GenerateReportTab" },
    meta: {
      permission: ["Pages.TravelFaturas"],
      permissionName: "Travel",
    },
    children: [
      {
        path: "relatorio",
        name: "GenerateReportTab",
        component: GenerateReportTab,
      },
      {
        path: "boleto",
        name: "GenerateTicketTab",
        component: GenerateTicketTab,
      },
    ],
  },
];
