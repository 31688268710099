<template>
  <JazzDetailsPage
    title="Dados do cliente"
    :loading="loading"
    :groupsText="groupsText"
    @back="
      $emit('back', $router.push({ name: 'ClientsIndex', params: params }))
    "
  >
    <v-tabs class="tabs-client" centered v-if="client">
      <v-tab :to="{ name: 'ClientDetails', params: params }">Informações</v-tab>
      <v-tab v-if="showRhTab" :to="{ name: 'ClientRH', params: params }"
        >Dados RH
      </v-tab>
      <v-tab
        data-cy="identificacao-tab"
        v-if="showIdentificacaoTab"
        :to="{ name: 'ClientIdentification', params: params }"
        >Identificação
      </v-tab>
      <v-tab
        data-cy="endereco-contato-tab"
        :to="{ name: 'ClientAddress', params: params }"
        >Endereço e Contato
      </v-tab>
      <v-tab
        data-cy="limites-tab"
        :to="{ name: 'ClientLimits', params: params }"
        >Limites
      </v-tab>
      <v-tab
        data-cy="contas-tab"
        :to="{ name: 'ClientAccounts', params: params }"
        >Contas vinculadas
      </v-tab>
    </v-tabs>
    <div class="content-tabs" v-if="client">
      <ClientBlocking
        data-cy="block-account-info"
        v-if="!isPjClient"
        :client="client"
      />
      <CustomerCancellation
        data-cy="cancellation-account-info"
        v-if="client.detalhesCancelamento"
        :client="client"
      />
      <transition name="fade" mode="out-in">
        <keep-alive>
          <router-view :client="client" />
        </keep-alive>
      </transition>
    </div>
    <JazzSkeletonLoader type="form" v-else />
  </JazzDetailsPage>
</template>

<script>
import JazzDetailsPage from "../../../components/template/JazzDetailsPage";
import { ClientesService } from "../../../services/api";
import globalMethods from "../../../mixins/globalMethods";
import JazzSkeletonLoader from "../../../components/template/JazzSkeletonLoader";
import ClientBlocking from "./components/ClientBlocking";
import CustomerCancellation from "./components/CustomerCancellation";

export default {
  name: "ClientShow",
  components: {
    CustomerCancellation,
    ClientBlocking,
    JazzSkeletonLoader,
    JazzDetailsPage,
  },
  mixins: [globalMethods],
  data() {
    return {
      client: null,
      groupsText: [],
      loading: true,
      tab: 0,
      params: null,
    };
  },
  async mounted() {
    this.params = this.$route.params;
    await this.getClient();
  },
  methods: {
    async getClient() {
      const { chaveDeCliente } = this.params;
      this.client = await ClientesService[
        this.isPjClient
          ? "obterDadosPessoaJuridicaPorChave"
          : "obterDadosPessoaFisicaPorChave"
      ]({
        body: {
          chaveDeCliente: chaveDeCliente,
        },
      }).then((res) => res?.result);

      if (this.client) {
        const {
          nome,
          organizationUnitId,
          organizationUnitDescricao,
          nomeFantasia,
        } = this.client;
        this.groupsText = [
          {
            label: this.isPjClient ? "Nome Fantasia" : "Nome",
            text: nome || nomeFantasia,
          },
          {
            label: this.isPjClient ? "CNPJ" : "CPF",
            text: this.identificationType,
          },
          {
            label: "Parceiro",
            text: organizationUnitId + " - " + organizationUnitDescricao,
          },
        ];
      }
      this.loading = false;
    },
  },
  computed: {
    showRhTab() {
      return !this.isPjClient;
    },
    showIdentificacaoTab() {
      return !this.isPjClient;
    },
    isPjClient() {
      return this.params?.tipo === "pj";
    },
    identificationType() {
      const { identificacaoFiscal } = this.client;
      if (identificacaoFiscal?.length === 14) {
        return this.cnpjMask(identificacaoFiscal);
      }
      return this.cpfMask(identificacaoFiscal);
    },
  },
};
</script>

<style lang="scss" scoped>
.content-tabs {
  padding: 30px 0 60px 0;
}

.v-tabs {
  width: 100% !important;
  overflow-x: hidden;
}

.v-tab {
  font-size: 14px;
  text-transform: none;
  font-weight: normal;
  color: $blackColor !important;
  letter-spacing: 0;
}

.v-tab.v-tab--active {
  font-size: 14px;
  color: $primaryColor !important;
}
</style>
