<template>
  <div class="jazz-table">
    <template v-if="!loading && rows">
      <div
        class="report-header"
        :class="styleHeader ? null : 'report-header-custom'"
        :style="styleHeader"
        v-if="header && Array.isArray(header) && header.length > 0"
      >
        <div
          :key="index"
          :class="styleHeaderCel ? null : 'report-header-cel'"
          v-for="(reportHeader, index) in header"
          :style="
            styleHeaderCel || {
              width: reportHeader.pixelWidth
                ? `${reportHeader.pixelWidth}px`
                : `${reportHeader.percentWidth || 100 / header.length}%`,
              justifyContent: reportHeader.align || 'center',
              background: reportHeader.background || 'transparent',
            }
          "
        >
          {{ reportHeader.title || "" }}
        </div>
      </div>
      <v-data-table
        data-cy="table"
        v-show="pagination.page"
        class="report-table"
        :items="rows"
        hide-default-footer
        hide-default-header
        :page.sync="pagination.page"
        :items-per-page="pagination.perPage"
      >
        <template v-slot:item="{ item }">
          <slot :paramsItem="item" name="reportRow" />
        </template>
        <template slot="no-data">
          <NotFoundMessage :message="emptyMessage" />
        </template>
        <template slot="loading"></template>
      </v-data-table>
      <div
        class="pagination-container"
        v-show="rows && rows.length > 0 && pagination.page && !hidePagination"
      >
        <v-pagination
          data-cy="pagination"
          prev-icon="fas fa-angle-left"
          color="var(--primaryColor)"
          next-icon="fas fa-angle-right"
          circle
          :value="pagination.page"
          :length="pagination.pages"
          :total-visible="6"
          @input="$emit('inputNewPage', $event)"
        />
      </div>
    </template>
    <JazzSkeletonLoader v-if="loading" type="table" />
  </div>
</template>

<script>
import NotFoundMessage from "../../components/template/NotFoundMessage";
import JazzSkeletonLoader from "../template/JazzSkeletonLoader";

export default {
  name: "JazzTable",
  components: { JazzSkeletonLoader, NotFoundMessage },
  props: {
    loading: Boolean,
    rows: Array,
    pagination: Object,
    hidePagination: {
      type: Boolean,
      default: false,
    },
    header: Array,
    styleHeader: Object,
    styleHeaderCel: Object,
    emptyMessage: String,
  },
  watch: {
    loading() {
      window.scrollTo(0, 0);
    },
  },
};
</script>

<style scoped>
.v-data-table {
  background: transparent !important;
}

.v-data-table >>> table th span {
  color: var(--blackColor) !important;
  font-size: 14px;
}

.v-data-table >>> table td {
  color: var(--blackColor) !important;
  font-size: 14px;
}

.pagination-container {
  margin: 30px 0;
}

.v-pagination >>> .v-pagination__item {
  font-size: 12px;
}

.v-pagination >>> .v-pagination__navigation i {
  font-size: 14px;
}

.v-data-table >>> td.text-center .container-table-box {
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.v-data-table.report-table >>> td {
  height: 70px !important;
  font-size: 14px;
}

.v-data-table.report-table
  >>> tr:not(:last-child)
  td:not(.v-data-table__mobile-row) {
  border: 0 !important;
}

.report-header {
  margin-bottom: 2px;
  display: flex;
  flex-direction: row;
  width: 100%;
  min-height: 48px;
  align-items: center;
  border-top: 1px solid var(--grayContrast);
  border-bottom: 1px solid var(--grayContrast);
  color: var(--blackColor);
}

.report-header-cel {
  min-height: 48px;
  display: flex;
  align-items: center;
  padding: 5px 10px;
  font-size: 16px;
  font-weight: bold;
}
</style>
