<template>
  <div>
    <div class="box-row">
      <div class="row-data">
        <JazzText :color="contractStatusColor(paramsItem.statusParcela)">
          <v-icon
            :class="contractStatusColor(paramsItem.statusParcela)"
            size="12"
          >
            fas fa-circle
          </v-icon>
          {{
            capitalizeFirstLetter(
              removeMaskCharactersChangeToSpace(paramsItem.statusParcela)
            )
          }}
        </JazzText>
      </div>
      <div class="row-data">
        <JazzText> {{ parcelMonth }}</JazzText>
      </div>

      <div class="row-data">
        <JazzText> {{ dateFormat(paramsItem.dataDeVencimento) }}</JazzText>
      </div>

      <div class="row-data">
        <JazzText> R$ {{ money2(paramsItem.valorParcela) }}</JazzText>
      </div>

      <div class="row-data">
        <JazzText>
          {{ dateFormat(paramsItem.dataPagamentoParcela) || "-" }}
        </JazzText>
      </div>
    </div>
  </div>
</template>

<script>
import globalMethods from "@/mixins/globalMethods";
import JazzText from "@/components/typography/JazzText.vue";

export default {
  name: "ContractParcelsRow",
  props: {
    paramsItem: Object,
  },
  methods: {
    alterarStatusParcela(status) {
      switch (status.toLowerCase()) {
        case "pago":
          return "green-deep";
        case "à vencer":
          return "blueColor";
        case "em atraso":
          return "dark-red";
        default:
          return "black";
      }
    },
    contractStatusColor(status) {
      switch (status.toLowerCase()) {
        case "pago":
          return "green-deep";
        case "à vencer":
          return "blueColor";
        case "em atraso":
          return "dark-red";
        default:
          return "black";
      }
    },
  },
  mixins: [globalMethods],
  components: { JazzText },
  computed: {
    parcelMonth() {
      let months = [
        "Janeiro",
        "Fevereiro",
        "Março",
        "Abril",
        "Maio",
        "Junho",
        "Julho",
        "Agosto",
        "Setembro",
        "Outubro",
        "Novembro",
        "Dezembro",
      ];
      let currentMonth =
        parseInt(
          this.dateEnFormat(this.paramsItem.dataDeVencimento).substring(5, 7)
        ) - 1;
      let currentYear = this.dateEnFormat(
        this.paramsItem.dataDeVencimento
      ).substring(0, 4);

      return `${months[currentMonth]} de ${currentYear}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.box-row {
  width: 100%;
  height: 70px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: center;
  position: relative;
}

.row-data {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex: 1;

  label {
    color: $blackColor;
  }
}
</style>
