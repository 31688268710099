<template>
  <v-col :cols="cols">
    <div class="label-box">
      <v-label>{{ label }}</v-label>
    </div>
    <div :data-cy="`${dataCy}-container`" class="container-input">
      <v-menu
        :data-cy="`${dataCy}-menu`"
        ref="menu"
        v-model="menu"
        :close-on-content-click="false"
        :return-value.sync="date"
        transition="scale-transition"
        offset-y
        min-width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            :data-cy="dataCy"
            :disabled="disabled"
            :class="{ 'has-rules': rules, disabledField: disabled }"
            :rules="rules ? rules : []"
            readonly
            v-bind="attrs"
            :placeholder="placeholder"
            v-on="on"
            :value="formattedDate"
            @input="$emit('update:value', date)"
            @keyup.enter="$emit('onEnter', date)"
          >
          </v-text-field>
        </template>
        <v-date-picker
          :data-cy="`${dataCy}-picker`"
          @input="$refs.menu.save(date)"
          :next-icon="'fas fa-angle-right'"
          :prev-icon="'fas fa-angle-left'"
          :color="'var(--primaryColor)'"
          :header-color="'var(--primaryColor)'"
          v-model="date"
          locale="pt-br"
          :type="dateType"
          scrollable
        />
      </v-menu>
    </div>
  </v-col>
</template>

<script>
import globalMethods from "../../mixins/globalMethods.vue";

export default {
  name: "DateField",
  props: [
    "label",
    "dataCy",
    "placeholder",
    "type",
    "cols",
    "prependInnerIcon",
    "rules",
    "clearValue",
    "valueField",
    "disabled",
    "withButton",
    "buttonColor",
    "borderSuccess",
    "borderDanger",
    "maskMoney",
    "dateType",
  ],
  mixins: [globalMethods],
  data() {
    return {
      date: this.dateEnFormat(),
      menu: false,
    };
  },
  watch: {
    clearValue() {
      this.date = null;
    },
    valueField() {
      this.date = this.valueField;
      this.$emit("update:value", this.date);
    },
  },
  mounted() {
    if (this.valueField) {
      this.date = this.valueField;
      this.$emit("update:value", this.date);
    }
  },
  computed: {
    formattedDate() {
      this.$emit("update:value", this.date);
      return this.dateType === "month"
        ? this.dateMonthOnly(this.date)
        : this.dateFormat(this.date, "YYYY-MM-DD");
    },
  },
};
</script>

<style scoped>
label {
  color: var(--grayColor) !important;
  font-family: "Roboto", sans-serif;
  font-size: 12px !important;
}

.label-box {
  margin-bottom: 5px;
  margin-top: 1px;
}

.date-time-input >>> .vdatetime-popup__header {
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}

.date-time-input >>> .vdatetime-popup {
  border-radius: 10px;
}

.v-input >>> input {
  background: #ffffff !important;
  min-height: 48px !important;
  border-radius: 10px;
  color: var(--blackColor);
  font-size: 14px;
  font-weight: bold;  padding: 0 15px;
  width: 100%;
}

.v-input >>> .vdatetime-popup__actions {
  margin-top: 15px;
}

h6 {
  color: var(--blackColor) !important;
  font-weight: bold;  font-size: 18px !important;
  margin-right: 15px;
}

h6:hover {
  cursor: pointer;
}

.v-input >>> .v-input__slot:before {
  display: none !important;
}

.v-input.primary--text {
  color: transparent !important;
}

.v-text-field {
  padding-top: 0;
}

.container-input {
  height: 55px;
}

.v-picker >>> .v-date-picker-header i {
  color: var(--primaryColor);
}

.v-input.error--text >>> .v-input__slot {
  border: solid var(--dangerColor) 1px !important;
}

.v-input >>> .v-input__slot {
  background: #ffffff !important;
  border-radius: 10px;
  border: 1px solid var(--fieldBox) !important;
  min-height: 48px !important;
}

.v-input.borderSuccess >>> .v-input__slot {
  border: solid var(--successColor) 2px !important;
}

.v-input.borderDanger >>> .v-input__slot {
  border: solid var(--dangerColor) 2px !important;
}

.v-input.disabledField >>> .v-input__slot {
  background: var(--disabledColor) !important;
  font-style: italic;
}

.v-input.disabledField >>> input {
  color: var(--grayColor) !important;
  font-family: "Roboto Medium", sans-serif !important;
}
</style>
