<template>
  <div class="w-100">
    <JazzListPage
      v-show="!transactionSelected"
      @search="searchTransactions"
      :fields="fieldsFilters"
      :countRows="!loading && showTable ? transactions.count : 0"
      :title="'Consultar Transações'"
      :filterButton="filterButton"
      @changeFilterButton="filterButton = !filterButton"
    >
      <div v-if="showTable && !loading && transactions.length > 0">
        <div class="buttons-row">
          <div class="buttons">
            <TableLabelButton
              data-cy="report-transaction-button"
              title=""
              label="Relatório de
                transações"
              @click.native="gerarRelatorioTransacoes(1)"
              icon="fas fa-file-download fa-lg"
              textTransform="capitalize"
              buttonColor="primary"
              iconColor="white"
              size="large"
              weight="bold"
            />
            <TableLabelButton
              data-cy="report-value-button"
              title=""
              label="Valor total por
                Cliente"
              textTransform="capitalize"
              buttonColor="primary"
              iconColor="white"
              @click.native="gerarRelatorioTransacoes(2)"
              icon="fas fa-file-download fa-lg"
              size="large"
              weight="bold"
            />
          </div>
        </div>
        <JazzTable
          v-if="showTable"
          :rows="transactions"
          :pagination="pagination"
          @inputNewPage="searchTransactions(null, $event)"
        >
          <template slot="reportRow" slot-scope="props">
            <TravelTransactionRow
              :itemTransaction="props.paramsItem"
              @click.native="transactionSelected = props.paramsItem"
            />
          </template>
        </JazzTable>
      </div>
      <DefaultLoading v-show="loading" />
    </JazzListPage>
    <div v-if="transactionSelected">
      <TransactionDetails
        :transaction="transactionSelected"
        @voltar="transactionSelected = null"
      />
    </div>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import globalMethods from "../../../mixins/globalMethods";
import JazzListPage from "../../../components/template/JazzListPage";
import DefaultLoading from "../../../components/template/DefaultLoading";
import JazzTable from "../../../components/table/JazzTable";
import TravelTransactionRow from "./components/rowsTable/TravelTransactionRow";
import TableLabelButton from "../../../components/button/TableLabelButton";
import TransactionDetails from "../../../views/Travel/Transactions/TransactionDetails";
import {
  ClientesTravelService,
  TransacoesService,
  TravelUtilidadesService,
} from "../../../services/apitravel";

export default {
  name: "TravelTransactionsIndex",
  mixins: [globalMethods],
  components: {
    JazzListPage,
    DefaultLoading,
    JazzTable,
    TravelTransactionRow,
    TableLabelButton,
    TransactionDetails,
  },
  data() {
    return {
      pagination: {
        page: 0,
        perPage: 45,
        total: 0,
        pages: 0,
      },
      filters: {
        pagina: 1,
      },
      showTable: false,
      transactionSelected: null,
      fieldsFilters: {},
      filterButton: true,
      loading: false,
      organizationUnitId: null,
      loadingRelatorio: false,
      intermediarios: [],
      clients: [],
      transactions: [],
    };
  },
  mounted() {
    this.init();
  },

  methods: {
    ...mapMutations(["CHANGE_LOADING"]),
    async searchTransactions(data = null, page = 1) {
      if (
        data &&
        data.values.intermediarySelectField.organizationUnitId === null
      ) {
        this.openMessageModal(
          "danger",
          "Atenção",
          "Por favor, selecione um intermediário para continuar."
        );
      } else {
        if (data && !data.values.clientSelectField.chaveDeCliente) {
          this.openMessageModal(
            "danger",
            "Atenção",
            "Por favor, selecione um cliente para continuar."
          );
        } else {
          this.loading = true;

          if (data) {
            this.filters = {
              chaveDeCliente: data.values.clientSelectField?.chaveDeCliente,
              dataInicio: data.values.dataInicialDateField,
              dataFim: data.values.dataFinalDateField,
            };
          }
          await TransacoesService.consultarPorCliente({
            body: {
              ...this.filters,
              pagina: page,
              porPagina: this.pagination.perPage,
            },
          })
            .then((response) => {
              this.pagination.page = response.result.pagina;
              this.pagination.perPage = response.result.qtdPorPagina;
              this.pagination.total = response.result.totalItems;
              this.pagination.pages = response.result.qtdPaginas;
              this.transactions = response.result.items;
              this.loading = false;
              this.showTable = true;
              if (this.transactions.length > 0) {
                this.filterButton = !this.filterButton;
              }
            })
            .catch(() => {
              this.loading = false;
            });
        }
      }
    },
    async gerarRelatorioTransacoes(tipo) {
      this.CHANGE_LOADING(true);
      let payload = {
        organizationUnitId:
          this.fieldsFilters.intermediarySelectField.valueField
            ?.organizationUnitId,
        chaveDeCliente: this.filters.chaveDeCliente,
        dataInicio: this.filters.dataInicio,
        dataFim: this.filters.dataFim,
      };

      let url = "";

      switch (tipo) {
        case 1:
          await TransacoesService.gerarRelatorioPorCliente({
            body: { ...payload },
          }).then((response) => {
            url = response.result.urlRelatorio;
          });
          break;
        case 2:
          await TransacoesService.gerarRelatorioValorTotalPorCliente({
            body: { ...payload },
          }).then((response) => {
            url = response.result.urlRelatorio;
          });
          break;
      }

      if (url) {
        window.open(url);
      }

      this.CHANGE_LOADING(false);
    },
    async populateIntermediates() {
      this.intermediarios = [];
      return await TravelUtilidadesService.listarIntegradoresDisponiveis().then(
        (response) => (this.intermediarios = response.result.integradores)
      );
    },
    async init() {
      this.loading = true;
      await this.populateIntermediates();
      this.createForms();
      this.loading = false;
    },
    async changeIntegrador(form) {
      const { intermediarySelectField, clientSelectField } = form;
      const intermediary =
        intermediarySelectField?.valueField?.organizationUnitId;
      if (intermediary) {
        intermediarySelectField.loading = true;
        clientSelectField.loading = true;
        const clients = await this.getClients(intermediary);
        clientSelectField.items = [];
        clientSelectField.items = [
          { chaveDeCliente: null, razaoSocial: "Selecione" },
          ...clients,
        ];
        clientSelectField.valueField = clients.length === 1 ? clients[0] : null;
        intermediarySelectField.loading = false;
        clientSelectField.loading = false;
        clientSelectField.disabled = false;
      } else {
        clientSelectField.items = [
          { chaveDeCliente: null, razaoSocial: "Selecione" },
        ];
        clientSelectField.loading = false;
        clientSelectField.disabled = true;
      }
    },
    async getClients(uo) {
      return ClientesTravelService.filtrar({
        body: {
          organizationUnitId: uo,
        },
      })
        .then((res) => res?.result?.items || [])
        .catch(() => []);
    },
    createForms() {
      this.fieldsFilters = {
        intermediarySelectField: {
          label: "Integrador",
          field: "JazzAutoCompleteField",
          cols: 6,
          items: [
            { organizationUnitId: null, nome: "Selecione" },
            ...this.intermediarios,
          ],
          valueField: null,
          changeValue: (fields) => this.changeIntegrador(fields),
          keyName: "nome",
          keyValue: "organizationUnitId",
          disabled: false,
          loading: false,
        },
        clientSelectField: {
          label: "Cliente",
          field: "JazzAutoCompleteField",
          cols: 6,
          valueField: null,
          items: [{ chaveDeCliente: null, razaoSocial: "Selecione" }],
          keyName: "razaoSocial",
          keyValue: "chaveDeCliente",
          disabled: true,
          loading: false,
        },
        dataInicialDateField: {
          label: "Data Inicial",
          variableName: "dataInicialDateField",
          field: "DateField",
          cols: 3,
          valueField: this.dateSubtract30Days(),
          dateType: "date",
        },
        dataFinalDateField: {
          label: "Data Final",
          variableName: "dataFinalDateField",
          field: "DateField",
          cols: 3,
          valueField: this.dateEnFormat(),
          dateType: "date",
        },
      };

      if (this.intermediarios?.length === 1) {
        this.fieldsFilters.intermediarySelectField.valueField =
          this.intermediarios[0];
      }
    },
  },
};
</script>

<style scoped>
.buttons {
  display: flex;
  width: 50%;
}

.buttons-row {
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
}
</style>
