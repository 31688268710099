import CollaboratorsIndex from "../../views/Benefit/Collaborators/CollaboratorsIndex";
import CollaboratorShow from "../../views/Benefit/Collaborators/CollaboratorShow";
import CollaboratorInformation from "../../views/Benefit/Collaborators/components/tabs/CollaboratorInformation";
import CollaboratorIdentification from "../../views/Benefit/Collaborators/components/tabs/CollaboratorIdentification";
import CollaboratorAddress from "../../views/Benefit/Collaborators/components/tabs/CollaboratorAddress";
import CollaboratorAccounts from "../../views/Benefit/Collaborators/components/tabs/CollaboratorsAccounts";
import CollaboratorAccountShow from "../../views/Benefit/Collaborators/CollaboratorAccountShow";
import CollaboratorAccountLoad from "../../views/Benefit/Collaborators/components/CollaboratorAccountLoad";

export default [
  {
    path: "empresa/colaboradores",
    name: "CollaboratorsIndex",
    component: CollaboratorsIndex,
    meta: {
      permission: ["Pages.Colaboradores"],
      permissionName: "Colaboradores",
    },
  },
  {
    path: "/colaborador/:tipo?/:chaveDeCliente",
    name: "CollaboratorDetails",
    component: CollaboratorShow,
    redirect: { name: "CollaboratorInformation" },
    meta: {
      permission: ["Pages.Colaboradores"],
      permissionName: "Colaboradores",
    },
    children: [
      {
        path: "informacoes",
        name: "CollaboratorInformation",
        component: CollaboratorInformation,
      },
      {
        path: "identificacao",
        name: "CollaboratorIdentification",
        component: CollaboratorIdentification,
      },
      {
        path: "endereco",
        name: "CollaboratorAddress",
        component: CollaboratorAddress,
      },
      {
        path: "contas",
        name: "CollaboratorAccounts",
        component: CollaboratorAccounts,
      },
      {
        path: "conta/:chaveDeConta",
        name: "CollaboratorAccountShow",
        component: CollaboratorAccountShow,
        redirect: { name: "CollaboratorAccountLoad" },
        children: [
          {
            path: "carga",
            name: "CollaboratorAccountLoad",
            component: CollaboratorAccountLoad,
          },
        ],
      },
    ],
  },
];
