<template>
  <div :class="reverse && 'column-reverse'" class="group-text">
    <div class="group-text-label" :class="textAlignLabel">
      <JazzText
        :style-text="{ marginBottom: '3px' }"
        :size="isHeader ? 'description' : 'label'"
        :color="textColorLabel"
        >{{ label }}
      </JazzText>
    </div>
    <div
      class="d-flex"
      :class="[
        textAlign,
        backgroundColor !== 'transparent'
          ? 'background ' + backgroundColor
          : '',
      ]"
    >
      <JazzText
        :color="textColor"
        weight="bold"
        :size="isHeader ? 'subtitle' : 'description'"
        >{{ text }}
      </JazzText>
    </div>
  </div>
</template>

<script>
import JazzText from "./JazzText";

export default {
  name: "JazzTextGroup",
  components: { JazzText },
  props: {
    reverse: Boolean,
    label: {
      type: String,
      required: true,
    },
    text: {
      type: [String, Number],
      required: true,
    },
    textColor: {
      type: String,
      default: "black",
      validator: (propColor) =>
        [
          "primary",
          "secondary",
          "error",
          "white",
          "black",
          "grey",
          "orange",
          "green-deep",
          "blueColor",
          "dark-red",
          "orange",
          "blueColor",
        ].includes(propColor),
    },
    textColorLabel: {
      type: String,
      default: "grey",
      validator: (propColor) =>
        [
          "primary",
          "secondary",
          "error",
          "white",
          "black",
          "grey",
          "green-deep",
          "dark-red",
          "orange",
          "blueColor",
        ].includes(propColor),
    },
    backgroundColor: {
      type: String,
      default: "transparent",
      validator: (propsBackgroundColor) =>
        ["transparent", "lightRedColor"].includes(propsBackgroundColor),
    },
    textAlign: {
      type: String,
      default: "left",
      validator: (propsTextAlign) =>
        ["left", "center", "right"].includes(propsTextAlign),
    },
    textAlignLabel: {
      type: String,
      default: "leftLabel",
      validator: (propsTextAlignLabel) =>
        ["leftLabel", "centerLabel", "rightLabel"].includes(
          propsTextAlignLabel
        ),
    },
    isHeader: {
      type: Boolean,
    },
  },
};
</script>
<style>
.group-text {
  display: flex;
  flex-direction: column;
  margin: 10px;
  line-height: 1.4 !important;
}

.column-reverse {
  flex-direction: column-reverse;
}

.group-text-label {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.centerLabel {
  margin-left: 40px;
}

.leftLabel {
  text-align: left;
}

.left {
  text-align: left;
}

.center {
  margin-left: 70px;
}

.background {
  width: 150px;
  padding: 10px;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
}

.transparent {
  background-color: transparent;
}

.lightRedColor {
  background-color: #f49999;
}
</style>
