import CommunicationIndex from "../../views/Benefit/Communication/CommunicationIndex";
import ActiveTab from "../../views/Benefit/Communication/components/tabs/ActiveTab";
import ScheduleTab from "../../views/Benefit/Communication/components/tabs/ScheduleTab";
import InactiveTab from "../../views/Benefit/Communication/components/tabs/InactiveTab";

export default [
  {
    path: "/beneficio/comunicados",
    name: "BenefitCommunicationIndex",
    component: CommunicationIndex,
    meta: {
      permission: ["Pages.Comunicados"],
      permissionName: "Comunicados",
    },
    redirect: { name: "ActiveTab" },
    children: [
      {
        path: "ativos",
        name: "ActiveTab",
        component: ActiveTab,
      },
      {
        path: "agendados",
        name: "ScheduleTab",
        component: ScheduleTab,
      },
      {
        path: "inativos",
        name: "InactiveTab",
        component: InactiveTab,
      },
    ],
  },
];
