<template>
  <div @click="showDetailsParcel = !showDetailsParcel" class="parcel-row">
    <div class="title">
      <label> {{ paramsItem.numeroParcela }} </label>
    </div>

    <div class="title">
      <label> {{ dateFormat(paramsItem.dataVencimento) }} </label>
    </div>

    <div class="title">
      <label
        :class="
          paramsItem.statusParcela === 'pago'
            ? 'status-quitado'
            : paramsItem.statusParcela === 'em_atraso'
            ? 'status-atraso'
            : 'status-aberto'
        "
      >
        {{
          capitalizeFirstLetter(
            removeMaskCharactersChangeToSpace(paramsItem.statusParcela)
          )
        }}
      </label>
    </div>

    <div class="title">
      <label>
        {{ dateFormat(paramsItem.dataPagamento) || "--/--/----" }}
      </label>
    </div>
  </div>
</template>

<script>
import globalMethods from "../../mixins/globalMethods";

export default {
  name: "AnticipationParcelRow",
  props: ["paramsItem"],
  mixins: [globalMethods],
};
</script>

<style scoped lang="scss">
.parcel-row {
  width: 100%;
  height: 70%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;
  align-content: center;
  padding: 8px;
  border-bottom: 1px solid grey;
  margin-bottom: 2px;
  background-color: var(--fieldBox);
}

.title {
  font-weight: bold;
  font-size: 0.5em;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  text-align: center;
}

.status-aberto {
  color: $orangeColor;
  font-weight: bolder;
}

.status-quitado {
  color: $successColor;
  font-weight: bolder;
}

.status-atraso {
  color: $dangerColor;
  font-weight: bolder;
}
</style>
