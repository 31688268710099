import { TesourariasService } from "@/services/api";

export const treasuryAccountStore = {
  state: () => ({
    extracts: [],
    loading: false,
    totalCountExtractsHeader: 0,
    account: {
      chaveDeConta: null,
      nome: null,
      saldo: null,
      descricao: null,
      unidadeOrganizacional: null,
    },
    accounts: [],
    typesTreasuryTransaction: [],
    uiid_key: null,
  }),
  mutations: {
    SET_EXTRACTS(state, payload) {
      state.extracts = payload;
    },
    SET_TYPES_TREASURY_TRANSACTION(state, payload) {
      state.typesTreasuryTransaction = payload;
    },
    CHANGE_TREASURY_ACCOUNT_LOADING(state) {
      state.loading = !state.loading;
    },
    SET_TOTAL_COUNT_EXTRACTS_HEADER(state, payload) {
      state.totalCountExtractsHeader = payload;
    },
    SET_TREASURY_ACCOUNTSS(state, payload) {
      state.accounts = payload;
    },
    SET_TREASURY_ACCOUNT(state, payload) {
      state.account.chaveDeConta = payload.chaveDeConta;
      state.account.nome = payload.nome;
      state.account.saldo = payload.saldo;
      state.account.descricao = payload.descricao;
      state.account.unidadeOrganizacional = payload.unidadeOrganizacional;
    },
  },
  actions: {
    async action_treasuryAccountUpdateCredit(context, payload) {
      context.commit("CHANGE_LOADING", true, { module: "template" });
      await TesourariasService.aporteDeSaldoTesouraria({ body: payload })
        .then((response) => {
          if (response) {
            context.dispatch(
              "action_changeMessageSnackBar",
              "Aporte realizado com sucesso!",
              { module: "template" }
            );
          }
          context.commit("CHANGE_LOADING", false, { module: "template" });
          return response;
        })
        .catch(() => {
          context.commit("CHANGE_LOADING", false, { module: "template" });
        });
    },
    async action_getTreasuryBalance(context, payload) {
      return await TesourariasService.consultarSaldoTesouraria({
        body: payload,
      })
        .then((response) => {
          return response;
        })
        .catch((e) => e);
    },
    async action_transferirSaldoDaContaTesourariaParaTesouraria(
      context,
      payload
    ) {
      return await TesourariasService.transferirSaldoDaContaTesourariaParaTesouraria(
        { body: payload }
      )
        .then((response) => {
          return response;
        })
        .catch((e) => e);
    },
    async action_treasuryAccountUpdateDebit(context, payload) {
      context.commit("CHANGE_LOADING", true, { module: "template" });
      await TesourariasService.debitoTesouraria({ body: payload })
        .then((response) => {
          if (response) {
            context.dispatch(
              "action_changeMessageSnackBar",
              "Aporte realizado com sucesso!",
              { module: "template" }
            );
          }
          context.commit("CHANGE_LOADING", false, { module: "template" });
          return response;
        })
        .catch(() => {
          context.commit("CHANGE_LOADING", false, { module: "template" });
        });
    },
    async action_treasuryAccountShowExtract(context, payload) {
      context.commit("SET_EXTRACTS", []);
      return await TesourariasService.consultarExtratoTesouraria({
        body: payload,
      })
        .then((response) => {
          context.commit("SET_EXTRACTS", response.result.items);
          context.commit(
            "SET_TOTAL_COUNT_EXTRACTS_HEADER",
            response.result.totalItems
          );
          return response.result;
        })
        .catch(() => {});
    },
    async action_getTreasuryAccounts(context, payload) {
      context.commit("SET_TREASURY_ACCOUNTSS", []);
      context.commit("CHANGE_LOADING", true, { module: "template" });
      return await TesourariasService.consultarContaTesouraria({
        body: payload,
      })
        .then((response) => {
          context.commit("SET_TREASURY_ACCOUNTSS", response.result.items);
          context.commit("CHANGE_LOADING", false, { module: "template" });
          return response.result.items;
        })
        .catch(() => {
          context.commit("CHANGE_LOADING", false, { module: "template" });
        });
    },

    async action_getTypesTreasuryTransaction(context) {
      await TesourariasService.consultarTipoTransacaoTesouraria()
        .then((response) => {
          context.commit("SET_TYPES_TREASURY_TRANSACTION", response.result);
          return response;
        })
        .catch(() => {});
    },
    async action_postTransferClientToTreasury(context, payload) {
      return await TesourariasService.transferirSaldoDaContaClienteParaTesouraria(
        { body: payload }
      )
        .then((response) => {
          return response;
        })
        .catch(() => {});
    },
    async action_postTransferTreasuryToClient(context, payload) {
      return await TesourariasService.transferirSaldoDaTesourariaParaContaCliente(
        { body: payload }
      )
        .then((response) => {
          return response;
        })
        .catch(() => {});
    },
    action_clearAccountData(context) {
      context.commit("SET_TREASURY_ACCOUNT", {
        chaveDeConta: null,
        nome: null,
        saldo: null,
        descricao: null,
        unidadeOrganizacional: null,
      });
    },
  },
  getters: {},
};

export default treasuryAccountStore;
