<template>
  <div class="box-cad-row">
    <div class="item-first-column">
      <div class="item-icon">
        <v-icon>
          fas fa-credit-card
        </v-icon>
      </div>
      <div class="item-column">
        <JazzTextGroup
          label="Data e Hora de Criação"
          :text="dateTimeFormat(paramsItem.dataCriacao)"
        />
      </div>
      <div class="item-column">
        <JazzTextGroup
          label="Valor"
          :text="'R$ ' + money2(paramsItem.saldoTotal.toString())"
        />
      </div>
      <div class="item-column">
        <JazzTextGroup
          label="Nome Impresso"
          :text="paramsItem.nomeImpresso.toUpperCase()"
        />
      </div>
      <div class="item-column">
        <JazzTextGroup
          label="Número do Cartão"
          :text="`**** **** **** ${
            paramsItem.numeroCartao
              ? paramsItem.numeroCartao.slice(12, 16)
              : '-'
          }`"
        />
      </div>
      <div class="item-column">
        <JazzTextGroup
          label="Status"
          :text="statusName"
          :textColor="statusColor"
        />
      </div>
    </div>
  </div>
</template>

<script>
import globalMethods from "../../../../../mixins/globalMethods";
import JazzTextGroup from "../../../../../components/typography/JazzTextGroup";
export default {
  name: "TravelCardsRow",
  mixins: [globalMethods],
  props: { paramsItem: Object },
  components: {
    JazzTextGroup,
  },
  computed: {
    statusColor() {
      switch (this.paramsItem.statusPedido) {
        case "Vigente":
          return "primary";
        case "Fechado":
          return "error";
        default:
          return "black";
      }
    },
    statusName() {
      switch (this.paramsItem.statusPedido) {
        case "Vigente":
          return "Ativo";
        case "Fechado":
          return "Cancelado";
        default:
          return this.paramsItem.statusPedido;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.box-cad-row {
  width: 100%;
  height: 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  padding: 12px;
  border-bottom: thin solid $opacityGrayColor;
  position: relative;
  margin-bottom: 5px;
}

.box-cad-row:hover {
  background: $grayContrast;
  cursor: pointer;
}

.item-first-column {
  display: flex;
  flex: 1;
  justify-content: flex-start;
  align-items: center;
  width: 30%;
}

.item-icon {
  margin-right: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.item-icon i {
  color: $primaryColor;
  font-size: 35px;
}

.item-column {
  width: 30%;
}
</style>
