<template>
  <div class="not-found-message">
    <JazzText color="grey" text-align="left">
      {{ message ? message : "Nenhuma informação encontrada." }}
    </JazzText>
  </div>
</template>

<script>
import JazzText from "./JazzText";
export default {
  name: "JazzNotFoundMessage",
  components: { JazzText },
  props: ["message"],
};
</script>

<style lang="scss" scoped>
.not-found-message {
  width: 100%;
  padding: 30px 0;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
</style>
