<template>
  <div class="w-100">
    <v-expansion-panel data-cy="expansive-panel-row">
      <v-expansion-panel-header
        expand-icon="fa-chevron-down"
        data-cy="expansion-panel-header-row"
      >
        <div class="w-100">
          <slot name="Header" />
        </div>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <div class="w-100">
          <slot name="Content" />
        </div>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </div>
</template>

<script>
import globalMethods from "../../../mixins/globalMethods";

export default {
  name: "JazzExpansiveRow",
  mixins: [globalMethods],
};
</script>

<style lang="scss" scoped>
.v-expansion-panel,
.v-expansion-panel-content {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  background: transparent !important;
}

.v-expansion-panel-content {
  border-bottom: 1px solid $lightGrayColor;
}

.v-expansion-panel:before,
.v-expansion-panel::before {
  box-shadow: none;
}

.v-expansion-panel:hover {
  background-color: $grayContrast;
}

.v-expansion-panel-header,
.v-expansion-panel-content {
  display: flex;
  width: 100%;
  word-break: break-word;
  padding: 0 24px !important;
}

.v-expansion-panel-content {
  padding-right: 45px !important;
}

.v-expansion-panel-content::v-deep .v-expansion-panel-content__wrap {
  padding: 0 !important;
}
</style>
