<template>
  <div class="w-100">
    <div class="content">
      <CustomerCard
        v-if="card"
        :card="card"
        @downloadButton="downloadCardImage"
      />
      <div class="card-footer">
        <div v-if="card" class="box-saldo">
          <JazzAccountBalance
            data-cy="balance-div"
            title="Saldo Total"
            :balance="card.valorPedido || 0"
            information="Saldo total do cartão gerado"
            text-color="black"
          />
          <JazzAccountBalance
            data-cy="balance-div"
            title="Saldo Disponível"
            :balance="card.saldo || 0"
            information="Saldo disponível no cartão para utilização"
            text-color="black"
          />
        </div>
        <EditCardValues v-if="card" :card="card" @reload="$emit('reload')" />
      </div>
    </div>
    <div>
      <InformationCard v-if="card" :card="card" />
    </div>
    <div class="form-and-button">
      <div class="managerial-fields">
        <JazzSkeletonLoader v-if="mainloading" type="form" />
        <JazzFormFields
          ref="infoCardForm"
          @checkValidity="updatedManagerial($event)"
          :fields="managerialFields"
          :disabled="!editFields"
        />
      </div>
      <div v-if="!mainloading" class="rows-buttons">
        <DefaultButton
          data-cy="edit-button"
          v-if="!editFields"
          @click.native="editFields = true"
          text="Editar"
          class="edit-button"
        />
        <DefaultButton
          data-cy="cancel-button"
          v-if="editFields"
          @click.native="cancelEdition()"
          colorStyle="gray"
          class="cancel-button"
          text="Cancelar"
        />
        <DefaultButton
          data-cy="save-button"
          v-if="editFields"
          @click.native="$refs.infoCardForm.validateForms()"
          class="save-button"
          secondary-color="true"
          text="Salvar"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import globalMethods from "../../../../../mixins/globalMethods";
import JazzFormFields from "../../../../../components/form/JazzFormFields";
import DefaultButton from "../../../../../components/button/DefaultButton";
import JazzAccountBalance from "../../../../../components/JazzAccountBalance";
import JazzSkeletonLoader from "../../../../../components/template/JazzSkeletonLoader";
import CustomerCard from "../CustomerCard";
import InformationCard from "../InformationCard";
import EditCardValues from "../EditCardValues";
import { PedidosCartaoService } from "../../../../../services/apitravel";

export default {
  name: "CardDataTab",
  mixins: [globalMethods],
  components: {
    JazzFormFields,
    DefaultButton,
    CustomerCard,
    InformationCard,
    JazzAccountBalance,
    EditCardValues,
    JazzSkeletonLoader,
  },
  props: {
    card: Object,
  },
  data() {
    return {
      showModal: false,
      managerialFields: {},
      dataCards: {},
      editFields: false,
      mainloading: true,
      params: {},
      loading: false,
      groupsText: [],
    };
  },
  mounted() {
    this.params = this.$route.params;
    this.searchManagerial();
  },
  methods: {
    ...mapMutations(["CHANGE_LOADING"]),
    downloadCardImage() {
      this.CHANGE_LOADING(true);
      let buttonImage = document.getElementById("line-button");
      buttonImage.classList.add("hide-button");
      let imageName = this.card.nomeImpresso.replaceAll(" ", "_") + "_CARTAO";
      this.convertDomToImagePng("card", imageName)
        .then(() => {
          this.CHANGE_LOADING(false);
          buttonImage.classList.remove("hide-button");
          this.$store.dispatch(
            "action_changeMessageSnackBar",
            `Download realizado com sucesso!`,
            { module: "template" }
          );
        })
        .catch(() => {
          this.CHANGE_LOADING(false);
          buttonImage.classList.remove("hide-button");
          this.openMessageModal(
            "danger",
            "Atenção",
            "Erro ao gerar imagem do cartão, tente novamente."
          );
        });
    },
    cancelEdition() {
      Object.keys(this.managerialFields).forEach((fieldName) => {
        this.managerialFields[fieldName].valueField =
          this.dataCards[fieldName] || "";
      });
      this.editFields = false;
    },
    checkCardActivation() {
      let payload = {
        chaveDePedidoCartao: this.card.chaveDePedidoCartao,
      };
      PedidosCartaoService.finalizar({
        body: payload,
      })
        .then(() => {
          this.$store.dispatch(
            "action_changeMessageSnackBar",
            `Cartão finalizado!`,
            { module: "template" }
          );
          this.$router.back();
          this.showModal = false;
        })
        .catch(() => {
          this.showModal = false;
        });
    },
    async searchManagerial() {
      let payload = {
        chaveDePedidoCartao: this.card.chaveDePedidoCartao,
      };

      this.mainloading = true;

      await PedidosCartaoService.obterDados1({
        body: payload,
      })
        .then((response) => {
          this.dataCards = response.result;
          this.createForm();
        })
        .finally(() => (this.mainloading = false));
    },
    createForm() {
      this.managerialFields = {
        title: {
          field: "FormFieldsHeader",
          title: "Campos Gerenciais",
        },
        numeroDePedido: {
          field: "TextField",
          label: "Número do pedido",
          cols: 3,
          valueField: this.dataCards.numeroDePedido,
        },
        centroDeCusto: {
          field: "TextField",
          label: "Centro de Custo",
          cols: 3,
          valueField: this.dataCards.centroDeCusto,
        },
        aprovador: {
          field: "TextField",
          label: "Aprovador",
          cols: 3,
          valueField: this.dataCards.aprovador,
        },
        solicitante: {
          field: "TextField",
          label: "Solicitante",
          cols: 3,
          valueField: this.dataCards.solicitante,
        },
        checkIn: {
          field: "TextField",
          label: "Check in",
          cols: 3,
          valueField: this.dataCards.checkIn,
        },
        checkOut: {
          field: "TextField",
          label: "Check out",
          cols: 3,
          valueField: this.dataCards.checkOut,
        },
        trechoDeIda: {
          field: "TextField",
          label: "Trecho de ida",
          cols: 3,
          valueField: this.dataCards.trechoDeIda,
        },
        trechoDeVolta: {
          field: "TextField",
          label: "Trecho de volta",
          cols: 3,
          valueField: this.dataCards.trechoDeVolta,
        },
        localizador: {
          field: "TextField",
          label: "Localizador",
          cols: 3,
          valueField: this.dataCards.localizador,
        },
        numTicket: {
          field: "TextField",
          label: "Número do Ticket",
          cols: 3,
          valueField: this.dataCards.numeroDoTicket,
        },
      };
    },
    updatedManagerial(event) {
      if (!event.isValid) {
        return;
      }

      this.CHANGE_LOADING(true);

      let {
        numeroDePedido,
        centroDeCusto,
        aprovador,
        solicitante,
        checkIn,
        checkOut,
        trechoDeIda,
        trechoDeVolta,
        numTicket,
        localizador,
      } = event.values;

      let payload = {
        chaveDePedidoCartao: this.card.chaveDePedidoCartao,
        numeroDePedido: numeroDePedido,
        centroDeCusto: centroDeCusto,
        aprovador: aprovador,
        solicitante: solicitante,
        checkIn: checkIn,
        checkOut: checkOut,
        trechoDeIda: trechoDeIda,
        trechoDeVolta: trechoDeVolta,
        localizador: localizador,
        numeroDoTicket: numTicket,
      };
      PedidosCartaoService.cadastrar1({
        body: payload,
      })
        .then(() => {
          this.dataCards = payload;
          this.$store.dispatch(
            "action_changeMessageSnackBar",
            `Campos gerenciais alterados com sucesso!`,
            { module: "template" }
          );
          this.editFields = false;
        })
        .catch(() => {
          this.cancelEdition();
        })
        .finally(() => {
          this.CHANGE_LOADING(false);
        });
    },
  },
};
</script>

<style scoped lang="scss">
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 20px;
}

.card-footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.box-saldo {
  padding-top: 30px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  align-self: center;
}

.form-and-button {
  padding: 20px;
}

.managerial-fields {
  padding-top: 20px;
}

.cancel-button {
  margin-right: 10px;
  width: 170px;
}

.save-button {
  width: 170px;
}

.rows-buttons {
  display: flex;
  justify-content: flex-end;
  text-align: right;
  margin-top: 50px;
}

.edit-button {
  width: 170px;
}

@media screen and (min-width: 800px) {
  .content {
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
  .box-saldo {
    align-items: center;
    width: 75%;
    justify-content: space-evenly;
  }

  .card-footer {
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }
  .box-saldo {
    align-items: center;
    width: 75%;
    justify-content: space-evenly;
  }
}

@media screen and (min-width: 1380px) {
  .content {
    flex-direction: row;
  }

  .box-saldo {
    align-items: center;
  }
}
</style>
