<template>
  <div class="w-100">
    <JazzListPage
      title="Cartões"
      :creationRoute="creationRoute"
      :fields="fields"
      @search="searchRequest"
      :loading="mainloading"
    >
      <div class="button-row" v-if="!loadingTable && cardsList.length > 0">
        <v-col cols="3">
          <TableLabelButton
            data-cy="report-generated-cards-button"
            title=""
            label="Relatório de
                Cartões Gerados"
            @click.native="generateReport()"
            icon="fas fa-file-download fa-lg"
            textTransform="capitalize"
            buttonColor="primary"
            iconColor="white"
            size="large"
            weight="bold"
          />
        </v-col>
      </div>

      <JazzTable
        :loading="loadingTable"
        :rows="cardsList"
        :pagination="pagination"
        @inputNewPage="searchRequest(null, $event)"
      >
        <template slot="reportRow" slot-scope="props">
          <TravelCardsRow
            :paramsItem="props.paramsItem"
            @click.native="setParams(props.paramsItem)"
          />
        </template>
      </JazzTable>
    </JazzListPage>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import globalMethods from "../../../mixins/globalMethods";
import statusCartao from "../../../dbjson/statusCartao.json";
import JazzListPage from "../../../components/template/JazzListPage";
import TableLabelButton from "../../../components/button/TableLabelButton";
import JazzTable from "../../../components/table/JazzTable";
import TravelCardsRow from "./components/rowsTable/TravelCardsRow";
import {
  ClientesTravelService,
  PedidosCartaoService,
} from "../../../services/apitravel";

export default {
  name: "TravelCardsIndex",
  mixins: [globalMethods],
  components: {
    JazzListPage,
    JazzTable,
    TableLabelButton,
    TravelCardsRow,
  },
  data() {
    return {
      creationRoute: {
        title: "Gerar cartão",
        route: "TravelGenerationCardsForm",
      },
      fields: {},
      cardsList: [],
      clientsList: [],
      loadingTable: false,
      mainloading: false,
      nomeCliente: "",
      filters: {},
      pagination: {
        page: 1,
        perPage: 45,
        total: 1,
        pages: 1,
      },
    };
  },
  async mounted() {
    const params = this.$route.params;

    if (params?.clientsList) {
      this.clientsList = params?.clientsList;
      this.filters = params?.filters;
      this.pagination = params?.pagination;
      this.cardsList = params?.cardsList;
      this.nomeCliente = params?.nomeCliente;
    } else {
      this.mainloading = true;
      this.clientsList =
        await ClientesTravelService.consultarDisponiveis().then(
          (response) => response.result.clientes || []
        );
      this.mainloading = false;
    }
    this.createForm();
  },
  methods: {
    ...mapMutations(["CHANGE_LOADING"]),
    createForm() {
      this.fields = {
        client: {
          label: "Cliente",
          field: "SelectField",
          cols: 4,
          valueField: this.findObjectFromArray(
            this.clientsList,
            "chaveDeCliente",
            this.filters?.chaveDeCliente
          ),
          items: [
            { chaveDeCliente: null, razaoSocial: "Selecione" },
            ...this.clientsList,
          ],
          keyName: "razaoSocial",
          keyValue: "chaveDeCliente",
          rules: [
            (fields) =>
              !!fields.client.valueField.chaveDeCliente ||
              "O campo Cliente é obrigatório",
          ],
        },
        cardEndNumber: {
          field: "TextField",
          label: "Número do Cartão (4 últimos dígitos)",
          cols: 4,
          valueField: this.filters?.ultimos4Digitos || null,
          maxLength: "4",
          helpTextLabel:
            "Digite os 4 últimos dígitos do cartão. Ex: xxxx xxxx xxxx 1234",
        },
        cardName: {
          field: "TextField",
          label: "Nome Impresso",
          type: "text",
          cols: 4,
          valueField: this.filters?.nomeImpresso || null,
        },
        initialDate: {
          field: "JazzDateField",
          label: "Data Inicial",
          cols: 3,
          valueField: this.filters?.initialDate || this.dateSubtract30Days(),
          rules: [
            (fields) =>
              !this.compareDateAfterAndCurrent(
                fields.initialDate.valueField,
                fields.finalDate.valueField
              ) || "Data inicial deve ser menor que data final",
          ],
        },
        finalDate: {
          field: "JazzDateField",
          label: "Data Final",
          cols: 3,
          valueField: this.filters?.finalDate || this.dateEnFormat(),
          rules: [
            (fields) =>
              !this.compareDateAfterAndCurrent(
                fields.initialDate.valueField,
                fields.finalDate.valueField
              ) || "Data final deve ser maior que data inicial",
          ],
        },
        status: {
          label: "Status",
          field: "SelectField",
          cols: 3,
          items: [{ value: "", key: "Selecione" }, ...statusCartao],
          keyName: "key",
          value: "value",
          valueField: this.findObjectFromArray(
            statusCartao,
            "key",
            this.filters?.statusCartao
          ),
        },
      };
      if (this.clientsList?.length === 1) {
        this.fields.client.valueField = this.clientsList[0];
      }
    },

    async searchRequest(filtersValues = null, page = 1) {
      if (filtersValues?.isValid || this.filters?.chaveDeCliente) {
        this.loadingTable = true;

        if (filtersValues) {
          this.nomeCliente = filtersValues.values.client.razaoSocial;
          const {
            client,
            initialDate,
            finalDate,
            cardName,
            cardEndNumber,
            status,
          } = filtersValues.values;

          this.filters = {
            chaveDeCliente: this.convertArrayObjectFilter(
              client,
              "chaveDeCliente"
            ),
            dataInicio: this.dateISOFormat(
              initialDate + " 00:00:00 +0000",
              "YYYY-MM-DD HH:mm:ss Z"
            ),
            dataFim: this.dateISOFormat(
              finalDate + " 23:59:59 +0000",
              "YYYY-MM-DD HH:mm:ss Z"
            ),
            nomeImpresso: cardName || null,
            ultimos4Digitos: cardEndNumber || null,
            status: this.convertArrayObjectFilter(status, "value") || 0,
          };
        }

        await PedidosCartaoService.consultarCartoes({
          body: {
            ...this.filters,
            pagina: page,
            porPagina: this.pagination.perPage,
          },
        })
          .then((response) => {
            let { pagina, qtdPorPagina, totalItems, qtdPaginas } =
              response.result;
            this.pagination.page = pagina;
            this.pagination.perPage = qtdPorPagina;
            this.pagination.total = totalItems;
            this.pagination.pages = qtdPaginas;
            this.cardsList = response.result.items;
          })
          .finally(() => {
            this.loadingTable = false;
          });
      }
    },
    setParams(card) {
      this.$router.push({
        name: "TravelCardsDetails",
        params: {
          idPedido: card.idPedido,
          chaveDePedidoCartao: card.chaveDePedidoCartao,
          chaveDeCliente: this.fields.client.valueField.chaveDeCliente,
          nomeCliente: this.nomeCliente,
          cardsList: this.cardsList,
          clientsList: this.clientsList,
          pagination: this.pagination,
          filters: this.filters,
        },
      });
    },
    async generateReport() {
      this.CHANGE_LOADING(true);
      await PedidosCartaoService.gerarRelatorioCartoes({
        body: {
          ...this.filters,
        },
      })
        .then((response) => {
          let csvContent =
            "data:text/csv;charset=utf-8," + encodeURI(response.result.arquivo);
          this.downloadCsvString(
            csvContent,
            "CARTOES_" + this.nomeCliente.replaceAll(" ", "")
          );
          this.$store.dispatch(
            "action_changeMessageSnackBar",
            "Arquivo baixado com sucesso!",
            { module: "template" }
          );
        })
        .catch(() => {
        })
        .finally(() => {
          this.CHANGE_LOADING(false);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.button-row {
  display: flex;
  flex-direction: row-reverse;
}
</style>
