<template>
  <ContentModal
    title="Importar novo arquivo"
    @close="$emit('showModal', false)"
    @save="sendFile()"
    :disable-button="disabled"
    hide-cancel-button
    @secondButton="$emit('showHelpModal')"
    show-second-button
    secondButtonText="Dúvidas? Como importar arquivo"
  >
    <JazzFormFields
      :fields="fields"
      @update:values="
        inputsValues = $event;
        validate++;
      "
      :validate="validate"
      @checkValidity="enableButton"
      @downloadModel="downloadModel()"
    />
    <JazzInformationTooltip
      class="mt-5"
      v-if="!hideFileInput"
      :content="content"
    />
  </ContentModal>
</template>

<script>
import { mapMutations } from "vuex";
import globalMethods from "../../../../../mixins/globalMethods";
import ContentModal from "../../../../../components/modal/ContentModal";
import JazzFormFields from "../../../../../components/form/JazzFormFields";
import JazzInformationTooltip from "../../../../../components/tooltip/JazzInformationTooltip";
import { ClientesService } from "../../../../../services/api";

export default {
  name: "PRegistrationImportModal",
  mixins: [globalMethods],
  components: { ContentModal, JazzFormFields, JazzInformationTooltip },
  props: {
    tesouraryAccounts: Array,
  },
  data() {
    return {
      disabled: true,
      fields: {},
      validate: 0,
      inputsValues: {},
      content: [
        "Informe o CPF desejado para o registro.",
        "Em algumas versões do Excel, o arquivo salvo em CSV pode desconfigurar. Para ajustar, abra o arquivo usando o bloco de notas e salve novamente.",
        "Limite de 10.000 linhas por arquivo ou 10mb.",
      ],
      hideFileInput: true,
    };
  },
  mounted() {
    this.createForm();
  },
  methods: {
    ...mapMutations(["CHANGE_LOADING"]),
    async sendFile() {
      this.CHANGE_LOADING(true);
      const { partnerAutoCompleteField, csvFileUpload } = this.inputsValues;
      let payload = {
        organizationUnitId: this.convertArrayObjectFilter(
          partnerAutoCompleteField,
          "organizationUnitId"
        ),
        arquivo: csvFileUpload.arquivo,
        nomeArquivo: csvFileUpload.nomeArquivo,
      };
      await ClientesService.importarClientes({
        body: payload,
      })
        .then(() => {
          this.CHANGE_LOADING(false);
          this.$store.dispatch(
            "action_changeMessageSnackBar",
            "Arquivo enviado com sucesso!",
            { module: "template" }
          );
          this.$emit("getProcessingFiles", payload.organizationUnitId);
        })
        .catch(() => {
          this.CHANGE_LOADING(false);
        });
    },
    createForm() {
      this.fields = {
        title: {
          field: "FormFieldsHeader",
          title:
            "Selecione a empresa que deseja adicionar o registro do cliente",
          hideTitleBorder: true,
          color: "black",
        },
        partnerAutoCompleteField: {
          label: "Empresa",
          field: "JazzAutoCompleteField",
          cols: 12,
          items: [
            { organizationUnitId: null, nome: "Selecione" },
            ...this.tesouraryAccounts,
          ],
          valueField: null,
          keyName: "nome",
          keyValue: "organizationUnitId",
          rules: [
            (fields) =>
              !!fields.partnerAutoCompleteField.valueField
                ?.organizationUnitId || "O campo Empresa é obrigatório",
          ],
          changeValue: (fields) => this.showFields(fields),
        },
        downloadModelButton: {
          isButton: true,
          hideInput: true,
          field: "InternalButton",
          cols: 12,
          text: "Baixar arquivo modelo (CSV)",
          colorStyle: "white-green ultra-radius large",
          event: "downloadModel",
        },
        csvFileUpload: {
          field: "JazzUploadFileField",
          label: "Carregar Arquivo (CSV)",
          cols: 12,
          valueField: null,
          hideInput: true,
          rules: [
            (field) =>
              !!field.csvFileUpload?.valueField ||
              "O campo Arquivo é obrigatório",
          ],
        },
      };
    },
    downloadModel() {
      let csvContent =
        "data:text/csv;charset=utf-8," +
        encodeURI(
          "IdentificacaoFiscal\n" + "12345678900\n" + "987.654.321-00;"
        );

      this.downloadCsvString(csvContent, "modelo_importacao.csv");
      this.$store.dispatch(
        "action_changeMessageSnackBar",
        "Arquivo baixado com sucesso!",
        { module: "template" }
      );
    },
    showFields(fields) {
      const { partnerAutoCompleteField, csvFileUpload, downloadModelButton } =
        fields;
      if (!partnerAutoCompleteField?.valueField?.organizationUnitId) {
        this.hideFileInput = true;
        csvFileUpload.hideInput = true;
        downloadModelButton.hideInput = true;
      } else {
        this.hideFileInput = false;
        csvFileUpload.hideInput = false;
        downloadModelButton.hideInput = false;
      }
    },
    enableButton(e) {
      if (!e.isValid) {
        this.disabled = true;
      } else {
        this.disabled = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
