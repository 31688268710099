<template>
  <v-col :cols="cols || 12">
    <h5 v-if="title" :class="`${color}-color ${borderBottom}`">
      {{ title }}
    </h5>
    <p v-if="mainText" class="secondary-color">{{ mainText }}</p>
    <h4 v-if="subtitle" :class="`${color}-color`">{{ subtitle }}</h4>
    <p v-if="secondaryText" class="secondary-color">{{ secondaryText }}</p>
  </v-col>
</template>

<script>
export default {
  name: "FormFieldsHeader",
  props: {
    title: {
      type: String,
    },
    mainText: String,
    subtitle: String,
    secondaryText: String,
    hideTitleBorder: {
      type: Boolean,
      default: false,
    },
    cols: String || Number,
    color: {
      type: String,
      default: "primary",
      validator: (propColor) =>
        [
          "primary",
          "secondary",
          "error",
          "white",
          "black",
          "grey",
          "success",
        ].includes(propColor),
    },
  },
  computed: {
    borderBottom() {
      return this.hideTitleBorder ? "" : "border-bottom";
    },
  },
};
</script>

<style scoped lang="scss">
h4,
h5,
p {
  padding-bottom: 6px;
  margin-bottom: 10px;
  margin-top: 10px;
  padding-left: 6px;
  background-color: transparent;
}

h5 {
  font-size: 18px;
  font-weight: bold;
  color: $primaryColor;
  width: 100%;
}

p {
  font-size: 16px;
}

.border-bottom {
  border-bottom: thin solid #f3eef1;
}

.primary-color {
  color: $primaryColor;
}

.secondary-color {
  color: $secondaryColor;
}

.error-color {
  color: $dangerColor;
}

.white-color {
  color: $whiteColor;
}

.black-color {
  color: $blackColor;
}

.grey-color {
  color: $grayColor;
}

.success-color {
  color: $successColor;
}
</style>
