<template>
  <div class="w-100">
    <JazzText class="mb-5" weight="bold" size="description">
      Passo 2. Edite o arquivo modelo com os dados do cliente.
    </JazzText>
    <img class="w-40" src="../../images/Excel_1.png" alt="Exemplo de arquivo" />
    <JazzInformationTooltip class="mt-5 mb-5" with-slot>
      <JazzText size="description"> Informe o CPF do cliente </JazzText>
      <div class="instructions">
        <div class="w-100">
          <JazzText
            size="description"
            :key="index"
            v-for="(item, index) in column"
          >
            <strong>{{ item.title }} = </strong>{{ item.content }}
          </JazzText>
        </div>
      </div>
    </JazzInformationTooltip>
  </div>
</template>

<script>
import globalMethods from "../../../../../../mixins/globalMethods";
import JazzText from "../../../../../../components/typography/JazzText";
import JazzInformationTooltip from "../../../../../../components/tooltip/JazzInformationTooltip";

export default {
  name: "Step2",
  mixins: [globalMethods],
  components: { JazzText, JazzInformationTooltip },
  data() {
    return {
      column: [
        {
          title: "Identificação fiscal",
          content: "CPF ou CNPJ do cliente",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.instructions {
  display: flex;
  flex-direction: row;
}
</style>
