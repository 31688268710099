<template>
  <div class="background">
    <div data-cy="confirmation-modal" class="dialog-box">
      <div data-cy="message-modal-div" class="message-text">
        <div v-if="icon">
          <v-icon class="icon-modal" :color="iconColor">
            {{ icon }}
          </v-icon>
        </div>
        <h6>{{ title }}</h6>
      </div>
      <slot />
      <div>
        <p :key="index" v-for="(message, index) in getMessages()">
          {{ message }}
        </p>
      </div>
      <div v-if="!hideButtons" class="buttons-box">
        <button
          v-if="!hideCancelButton"
          @click="$emit('close')"
          data-cy="modal-cancel-button"
          class="btn-modal cancel"
        >
          {{ cancelText }}
        </button>
        <button
          @click="$emit('confirm')"
          data-cy="modal-confirm-button"
          class="btn-modal confirm"
        >
          {{ confirmText }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ConfirmationModal",
  props: {
    title: {},
    messages: {},
    cancelText: {},
    confirmText: {},
    icon: {},
    colorIcon: {},
    hideCancelButton: {
      type: Boolean,
      default: false,
    },
    hideButtons: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      modal: false,
    };
  },
  methods: {
    getMessages() {
      if (typeof this.messages === "function") return this.messages();

      return this.messages;
    },
  },
  computed: {
    iconColor() {
      if (this.colorIcon === "success") {
        return { color: `var(--successColor)` };
      }
      return { color: `var(--warningColor)` };
    },
  },
};
</script>

<style scoped>
.background {
  background-color: rgba(0, 0, 0, 0.9);
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 500;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.dialog-box {
  background: #ffffff;
  min-width: 333px;
  max-width: 400px;
  min-height: 280px;
  padding: 40px;
  border-radius: 20px;
  text-align: center;
  line-break: auto;
  word-break: normal;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
}
.dialog-box i {
  font-size: 100px;
  margin: 0 0 40px 0;
  color: var(--dangerColor);
}
.dialog-box h6 {
  font-weight: bold;  font-weight: normal;
  font-size: 24px;
  color: var(--blackColor);
  margin-bottom: 15px;
  line-height: 28px;
}
.dialog-box p {
  font-size: 14px;
  
  color: var(--blackColor);
}
.btn-modal {
  height: 48px;
  padding: 0 38px;
  font-weight: bold;  font-size: 18px;
  border-radius: 20px;
  margin: 0 5px;
}
.buttons-box {
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn-modal.confirm {
  background: var(--successColor);
  color: #ffffff;
}
.btn-modal.cancel {
  background: var(--opacityGrayColor);
  color: var(--blackColor);
}
.btn-modal.cancel:hover {
  background: var(--grayContrast);
}
.btn-modal.confirm:hover {
  background: var(--primaryColor);
}
.dialog-box .icon-modal {
  font-size: 4rem;
}
</style>