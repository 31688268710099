<template>
  <div>
    <JazzSkeletonLoader v-if="loading" type="filters" />
    <div v-if="!loading">
      <JazzListPage @search="searchExtract" :fields="fields" hide-button-header>
        <JazzTable
          @inputNewPage="searchExtract(null, $event)"
          :loading="loadingTable"
          :pagination="pagination"
          :rows="transactions"
        >
          <template slot="reportRow" slot-scope="props">
            <ExtractAccountRow
              data-cy="extract-account-row"
              :paramsItem="props.paramsItem"
            />
          </template>
        </JazzTable>
      </JazzListPage>
    </div>
  </div>
</template>

<script>
import globalMethods from "../../../../mixins/globalMethods";
import JazzSkeletonLoader from "../../../../components/template/JazzSkeletonLoader";
import JazzListPage from "../../../../components/template/JazzListPage";
import motivosBeneficio from "../../../../dbjson/motivosBeneficio.json";
import JazzTable from "../../../../components/table/JazzTable";
import ExtractAccountRow from "./rowsTable/ExtractAccountRow";
import { ContasService } from "../../../../services/api";

export default {
  name: "CollaboratorAccountLoad",
  components: {
    JazzSkeletonLoader,
    JazzListPage,
    JazzTable,
    ExtractAccountRow,
  },
  mixins: [globalMethods],
  props: {
    account: Object,
    collaborator: Object,
  },
  data() {
    return {
      loading: false,
      params: this.$route?.params,
      transactions: null,
      fields: {},
      pagination: {
        page: 1,
      },
      loadingTable: false,
    };
  },
  mounted() {
    this.createFields();
  },
  methods: {
    async searchExtract(fields = null, page = 1) {
      if ((fields && fields?.isValid) || !fields) {
        this.loadingTable = true;
        if (fields) {
          const {
            initialDateJazzDateField,
            finalDateJazzDateField,
            loadSelectField,
          } = fields.values;
          this.filters = {
            chaveDeConta: this.params?.chaveDeConta,
            dataInicio: this.dateISOFormat(
              initialDateJazzDateField + " 00:00:00 +0000",
              "YYYY-MM-DD HH:mm:ss Z"
            ),
            dataFim: this.dateISOFormat(
              finalDateJazzDateField + " 23:59:59 +0000",
              "YYYY-MM-DD HH:mm:ss Z"
            ),
            tiposTransacao: [30],
            motivoTransacao:
              this.convertArrayObjectFilter(loadSelectField, "value") || null,
          };
        }
        const result = await ContasService.consultarExtratoBeneficio({
          body: {
            ...this.filters,
            pagina: page,
            porPagina: 15,
          },
        })
          .then((res) => res?.result?.pagina)
          .finally(() => (this.loadingTable = false));
        this.transactions = result?.items;
        this.pagination.page = result?.pagina;
        this.pagination.perPage = result?.qtdPorPagina;
        this.pagination.total = result?.totalItems;
        this.pagination.pages = result?.qtdPaginas;
      }
    },
    createFields() {
      this.fields = {
        loadSelectField: {
          label: "Carga",
          field: "SelectField",
          cols: 12,
          items: motivosBeneficio,
          keyName: "key",
          value: "value",
          valueField: null,
        },
        initialDateJazzDateField: {
          label: "Data Inicial",
          field: "JazzDateField",
          cols: 4,
          valueField: this.dateSubtract30Days(),
          dateType: "date",
        },
        finalDateJazzDateField: {
          label: "Data Final",
          field: "JazzDateField",
          cols: 4,
          valueField: this.dateEnFormat(),
          dateType: "date",
        },
      };
    },
  },
};
</script>

<style scoped></style>
