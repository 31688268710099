<template>
  <v-col :cols="cols">
    <div class="label-box">
      <label :class="{ darkStyle: darkStyle }">{{ label }}</label>
    </div>
    <div class="container-input">
      <v-text-field
        :data-cy="dataCy"
        autocomplete="new-password"
        :append-icon="
          appendIcon ? (showAppend ? appendIcon[0] : appendIcon[1]) : ''
        "
        v-model.lazy="maskMoney ? price : ''"
        v-money="maskMoney ? money : ''"
        :disabled="disabled"
        :class="{
          'has-rules': rules,
          withButton: withButton,
          borderSuccess: borderSuccess,
          borderDanger: borderDanger,
          darkStyle: darkStyle,
        }"
        :rules="rules ? rules : []"
        :prepend-inner-icon="prependInnerIcon"
        v-model="text"
        :type="type"
        :placeholder="placeholder"
        class="text-field"
        @input="$emit('update:value', text)"
        @keyup.enter="$emit('onEnter', text)"
        @keydown="
          maskMoney && $event.key === '-' ? $event.preventDefault() : null
        "
        outlined
      >
      </v-text-field>
      <v-tooltip top>
        <template #activator="{ on, attrs }">
          <span v-bind="attrs" v-on="on">
            <TableButton
              class="spacingButton"
              icon="fas fa-copy"
              @click.native="copyValue"
            />
          </span>
        </template>
        <span>{{ tooltipText }}</span>
      </v-tooltip>
    </div>
  </v-col>
</template>

<script>
import { VMoney } from "v-money";
import TableButton from "../button/TableButton";

export default {
  name: "CopyTextField",
  components: { TableButton },
  props: [
    "dataCy",
    "label",
    "placeholder",
    "type",
    "cols",
    "prependInnerIcon",
    "appendIcon",
    "rules",
    "clearValue",
    "valueField",
    "disabled",
    "withButton",
    "buttonColor",
    "borderSuccess",
    "borderDanger",
    "maskMoney",
    "darkStyle",
    "tooltipText",
  ],
  data() {
    return {
      showAppend: false,
      text: this.valueField || "",
      price: this.valueField || 0,
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "R$ ",
        suffix: "",
        precision: 2,
        masked: false,
      },
      value: "",
    };
  },
  directives: { money: VMoney },
  methods: {
    copyValue() {
      this.$store.dispatch(
        "action_changeMessageSnackBar",
        `Copiado com sucesso!`,
        { module: "template" }
      );
      navigator.clipboard.writeText(this.text);
    },
  },
  watch: {
    clearValue() {
      this.text = "";
    },
    valueField() {
      this.text = this.valueField;
      this.$emit("update:value", this.text);
    },
  },
  mounted() {
    if (this.valueField) {
      this.text = this.valueField;
      this.$emit("update:value", this.text);
    }
  },
};
</script>

<style scoped>
.spacingButton {
  margin-top: 10px;
}

label {
  color: var(--grayColor) !important;
  font-family: "Roboto", sans-serif;
  font-size: 12px !important;
}

label.darkStyle {
  font-size: 14px !important;
  color: #ffffff !important;
  font-family: "Roboto Light", sans-serif !important;
}

.label-box {
  margin-bottom: 5px;
}

.v-input >>> input {
  color: var(--blackColor) !important;
  font-family: "Roboto Medium", sans-serif;
  font-size: 14px;
}

.v-input >>> fieldset {
  color: transparent !important;
}

.v-input >>> .v-text-field__details {
  display: none;
}

.v-input.error--text >>> .v-text-field__details {
  display: block;
}

/*.v-input.has-rules >>> .v-text-field__details{*/
/*    display: block;*/
/*}*/

.v-input >>> .v-messages__message {
  color: var(--dangerColor);
  font-family: "Roboto", sans-serif;
}

.v-input.error--text >>> .v-input__slot {
  border: solid var(--dangerColor) 1px !important;
}

.v-input >>> .v-input__slot {
  background: #ffffff !important;
  border-radius: 10px;
  border: 1px solid var(--fieldBox) !important;
  min-height: 48px !important;
}

.v-input.borderSuccess >>> .v-input__slot {
  border: solid var(--successColor) 2px !important;
}

.v-input.borderDanger >>> .v-input__slot {
  border: solid var(--dangerColor) 2px !important;
}

.v-input >>> i {
  color: var(--lightGrayColor);
  font-size: 18px;
}

.v-input >>> .v-input__prepend-inner {
  margin: 10px 5px 0 0;
}

.v-input >>> input::placeholder {
  color: var(--lightGrayColor) !important;
  font-size: 14px;
  font-family: "Roboto", sans-serif !important;
}

.v-input >>> input {
  color: var(--blackColor) !important;
  caret-color: var(--blackColor) !important;
  font-family: "Roboto Black", sans-serif !important;
}

.v-input >>> input:focus {
  color: var(--blackColor) !important;
}

.v-input--is-focused >>> i {
  color: var(--blackColor) !important;
}

.container-input {
  display: flex;
}

.v-input.withButton >>> .v-input__slot {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

button {
  border: 0;
  height: 48px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  padding: 0 20px;
  font-size: 14px;
  font-weight: bold;  color: #ffffff;
}
</style>
