<template>
  <DefaultCard>
    <DefaultLoading v-if="loadingCSVPreview" />
    <div data-cy="csv-preview" class="preview" v-else>
      <JazzCsvPreview v-if="base64" :base64="base64" remove-last-line />
      <JazzNotFoundMessage
        message="Relatório não emitido"
        v-else
        textColor="black"
      />
    </div>
    <div class="button-row">
      <DefaultButton
        data-cy="download-button"
        text="Download"
        @click.native="downloadReport"
        :class="{ disabled: isActive === false }"
      />
    </div>
  </DefaultCard>
</template>

<script>
import globalMethods from "../../../../../mixins/globalMethods";
import DefaultCard from "../../../../../components/cards/DefaultCard";
import DefaultButton from "../../../../../components/button/DefaultButton";
import JazzCsvPreview from "../../../../../components/template/JazzCsvPreview";
import DefaultLoading from "../../../../../components/template/DefaultLoading";
import JazzNotFoundMessage from "../../../../../components/typography/JazzNotFoundMessage";
import { TransacoesService } from "../../../../../services/apitravel";

export default {
  name: "GenerateReportTab",
  mixins: [globalMethods],
  components: {
    DefaultCard,
    DefaultButton,
    JazzCsvPreview,
    DefaultLoading,
    JazzNotFoundMessage,
  },
  data() {
    return {
      url: "",
      base64: null,
      loadingCSVPreview: true,
      params: {},
      isActive: false,
    };
  },
  async mounted() {
    this.params = this.$route.params;
    this.loadingCSVPreview = true;
    await this.getBatchUrl();
  },
  methods: {
    async getBatchUrl() {
      await TransacoesService.gerarRelatorioPorLote({
        body: { chaveDeLote: this.params.chaveDeLote },
      })
        .then((response) => {
          this.url = response.result.urlRelatorio;
          this.showCsvPreview();
        })
        .catch(() => {
          this.base64 = null;
          this.isActive = false;
          this.loadingCSVPreview = false;
        });
    },
    downloadReport() {
      if (this.url) {
        window.open(this.url);
        this.$store.dispatch(
          "action_changeMessageSnackBar",
          `Download realizado com sucesso!`,
          { module: "template" }
        );
      }
    },
    async showCsvPreview() {
      this.base64 = await this.convertUrlToBase64(this.url);

      if (this.base64 !== "data:") {
        this.isActive = true;
        this.loadingCSVPreview = false;
      } else {
        this.base64 = null;
        this.isActive = false;
      }

      this.loadingCSVPreview = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.button-row {
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
  padding: 15px;
  border-top: thin solid $grayContrast;
}

.preview {
  width: 100%;
  display: flex;
  justify-content: space-around;
  padding: 15px 0 15px 0;
}
</style>
