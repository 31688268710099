<template>
  <div class="jazz-skeleton-loader">
    <div v-if="type === 'filters'" class="row-skeleton-filters">
      <VSkeletonLoader
        type="image"
        height="46"
        width="59%"
        transition="fade-transition"
      />
      <VSkeletonLoader
        type="image"
        height="46"
        width="39%"
        transition="fade-transition"
      />
      <VSkeletonLoader
        type="image"
        height="46"
        width="39%"
        transition="fade-transition"
      />
      <VSkeletonLoader
        type="image"
        height="46"
        width="59%"
        transition="fade-transition"
      />
    </div>
    <div v-else-if="type === 'table'">
      <div v-for="i in 10" :key="i" class="row-skeleton-table">
        <VSkeletonLoader type="avatar" transition="fade-transition" />
        <VSkeletonLoader
          type="image"
          height="30"
          width="30%"
          transition="fade-transition"
        />
        <VSkeletonLoader
          type="image"
          height="30"
          width="15%"
          transition="fade-transition"
        />
        <VSkeletonLoader
          type="image"
          height="30"
          width="15%"
          transition="fade-transition"
        />
        <VSkeletonLoader
          type="image"
          height="30"
          width="25%"
          transition="fade-transition"
        />
      </div>
    </div>
    <div v-else-if="type === 'cardHeader'" class="row-skeleton-card-header">
      <VSkeletonLoader
        type="image"
        height="55"
        width="28%"
        transition="fade-transition"
      />
      <VSkeletonLoader
        type="image"
        height="55"
        width="28%"
        transition="fade-transition"
      />
      <VSkeletonLoader
        type="image"
        height="55"
        width="28%"
        transition="fade-transition"
      />
    </div>
    <div v-else-if="type === 'form'" class="row-skeleton-form">
      <VSkeletonLoader
        type="image"
        height="46"
        width="59%"
        transition="fade-transition"
      />
      <VSkeletonLoader
        type="image"
        height="46"
        width="39%"
        transition="fade-transition"
      />
      <VSkeletonLoader
        type="image"
        height="46"
        width="24%"
        transition="fade-transition"
      />
      <VSkeletonLoader
        type="image"
        height="46"
        width="24%"
        transition="fade-transition"
      />
      <VSkeletonLoader
        type="image"
        height="46"
        width="24%"
        transition="fade-transition"
      />
      <VSkeletonLoader
        type="image"
        height="46"
        width="24%"
        transition="fade-transition"
      />
      <VSkeletonLoader
        type="image"
        height="46"
        width="32%"
        transition="fade-transition"
      />
      <VSkeletonLoader
        type="image"
        height="46"
        width="32%"
        transition="fade-transition"
      />
      <VSkeletonLoader
        type="image"
        height="46"
        width="32%"
        transition="fade-transition"
      />
      <VSkeletonLoader
        type="image"
        height="46"
        width="59%"
        transition="fade-transition"
      />
      <VSkeletonLoader
        type="image"
        height="46"
        width="39%"
        transition="fade-transition"
      />
    </div>
    <div v-else-if="type === 'field'" class="row-skeleton-form">
      <VSkeletonLoader
        type="image"
        height="46"
        width="100%"
        transition="fade-transition"
      />
    </div>
    <div v-else-if="type === 'cards'" class="row-skeleton-card">
      <VSkeletonLoader
        type="image"
        height="450"
        width="15%"
        transition="fade-transition"
      />
      <VSkeletonLoader
        type="image"
        height="450"
        width="15%"
        transition="fade-transition"
      />
      <VSkeletonLoader
        type="image"
        height="450"
        width="15%"
        transition="fade-transition"
      />
      <VSkeletonLoader
        type="image"
        height="450"
        width="15%"
        transition="fade-transition"
      />
      <VSkeletonLoader
        type="image"
        height="450"
        width="15%"
        transition="fade-transition"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "JazzSkeletonLoader",
  props: {
    type: {
      type: String,
      validator: (propsType) =>
        ["filters", "form", "table", "cardHeader", "field", "cards"].includes(
          propsType
        ),
    },
  },
};
</script>

<style lang="scss" scoped>
.jazz-skeleton-loader {
  width: 100%;
  min-height: 30px;
}

.row-skeleton-filters {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 45px;
}

.row-skeleton-filters div {
  margin-bottom: 40px !important;
}

.row-skeleton-table {
  padding: 20px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.row-skeleton-table:first-child {
  margin-top: 20px;
}

.row-skeleton-form {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 45px;
}

.row-skeleton-form div {
  margin-bottom: 40px !important;
}

.row-skeleton-card-header {
  display: flex;
  width: 100%;
  min-height: 30px;
  justify-content: space-around;
  align-items: center;
  @media only screen and (max-width: 1200px) {
    justify-content: space-between;
    margin: 0 30px 0 0;
  }
  @media only screen and (max-width: 700px) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.row-skeleton-card-header:only-child {
  margin: 0 20px;
  @media only screen and (max-width: 700px) {
    margin: 20px;
  }
}

.row-skeleton-card {
  display: flex;
  width: 100%;
  min-height: 150px;
  justify-content: space-between;
  align-items: center;
  @media only screen and (max-width: 1200px) {
    justify-content: space-between;
  }
  @media only screen and (max-width: 700px) {
    flex-direction: column;
    align-items: flex-start;
  }
}
</style>
