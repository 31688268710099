<template>
  <JazzListPage
    :change-show-filters="showFilters"
    :loading="loadingFilters"
    :disableSearchButton="loadingFilters || loadingTable"
    :fields="fields"
    :hide-button-header="false"
    title="Consultar Clientes"
    @search="searchClients"
  >
    <JazzTable
      @inputNewPage="searchClients(null, $event)"
      :loading="loadingTable"
      :pagination="pagination"
      :rows="clients"
    >
      <template slot="reportRow" slot-scope="props">
        <ClientRow
          data-cy="client-row"
          @click.native="
            openClient(
              props.paramsItem.identificacaoFiscal,
              props.paramsItem.chaveDeCliente
            )
          "
          :params-item="props.paramsItem"
        />
      </template>
    </JazzTable>
  </JazzListPage>
</template>

<script>
import statusCadastroCliente from "../../../dbjson/statusCadastroCliente.json";
import JazzListPage from "../../../components/template/JazzListPage";
import { TesourariasService } from "../../../services/api";
import { ClientesBackofficeService } from "../../../services/apibackoffice";
import globalMethods from "../../../mixins/globalMethods";
import JazzTable from "../../../components/table/JazzTable";
import ClientRow from "./components/rowsTable/ClientRow";

export default {
  name: "ClientsIndex",
  components: { ClientRow, JazzTable, JazzListPage },
  mixins: [globalMethods],
  data() {
    return {
      tesouraryAccounts: [],
      showFilters: true,
      pagination: {},
      fields: {},
      clients: [],
      filters: {
        pagina: 1,
      },
      loadingFilters: true,
      loadingTable: false,
    };
  },
  mounted() {
    this.createFilters();
  },
  methods: {
    async createFilters() {
      const params = this.$route.params;
      if (params?.clients) {
        this.filters = params.filters;
        this.pagination = params.pagination;
        this.clients = params.clients;
        this.tesouraryAccounts = params.tesouraryAccounts;
      } else {
        const params = {
          body: {
            tipoOrdenacao: 1,
          },
        };
        this.tesouraryAccounts =
          await TesourariasService.consultarContaTesouraria(params).then(
            (contas) => contas?.result.items || []
          );
      }
      this.fields = {
        nameTextField: {
          field: "TextField",
          type: "text",
          label: "Nome do cliente",
          cols: 8,
          valueField: this.filters?.nome,
        },
        statusSelectField: {
          label: "Status cadastro",
          field: "SelectField",
          cols: 4,
          items: [{ value: "", key: "Selecione" }, ...statusCadastroCliente],
          keyName: "key",
          value: "value",
          valueField: this.findObjectFromArray(
            statusCadastroCliente,
            "key",
            this.filters?.statusCadastroCliente
          ),
        },
        identificationTextFieldMask: {
          field: "TextFieldMask",
          type: "text",
          label: "CPF/CNPJ",
          cols: 5,
          maskFormat: ["###.###.###-##", "##.###.###/####-##"],
          valueField: this.filters?.identificacaoFiscal,
        },
        parceiroSelectField: {
          label: "Parceiro",
          field: "SelectField",
          cols: 4,
          items: [
            { organizationUnitId: 0, nome: "Selecione" },
            ...this.tesouraryAccounts,
          ],
          keyName: "nome",
          keyValue: "organizationUnitId",
          valueField: this.findObjectFromArray(
            this.tesouraryAccounts,
            "organizationUnitId",
            this.filters?.organizationUnitId
          ),
        },
      };
      this.loadingFilters = false;
    },
    async searchClients(fields = null, page = 1) {
      if ((fields && fields?.isValid) || !fields) {
        this.loadingTable = true;
        if (fields) {
          const {
            nameTextField,
            identificationTextFieldMask,
            parceiroSelectField,
            statusSelectField,
          } = fields.values;
          this.filters = {
            nome: nameTextField,
            identificacaoFiscal: this.removeMaskCharacters(
              identificationTextFieldMask
            ),
            organizationUnitId:
              this.convertArrayObjectFilter(
                parceiroSelectField,
                "organizationUnitId"
              ) || 0,
            statusCadastroCliente: this.convertArrayObjectFilter(
              statusSelectField,
              "value"
            ),
          };
        }
        const result =
          await ClientesBackofficeService.listarClientesGeraisBackoffice({
            body: {
              ...this.filters,
              porPagina: 45,
              pagina: page,
            },
          });

        this.clients = result?.items;
        this.pagination.page = result?.pagina;
        this.pagination.perPage = result?.qtdPorPagina;
        this.pagination.total = result?.totalItems;
        this.pagination.pages = result?.qtdPaginas;
        this.loadingTable = false;
        this.showFilters = result?.items?.length === 0;
      }
    },
    openClient(identificacaoFiscal, chaveDeCliente) {
      this.$router.push({
        name: "ClientDetails",
        params: {
          tipo: identificacaoFiscal.length === 14 ? "pj" : undefined,
          chaveDeCliente: chaveDeCliente,
          filters: this.filters,
          pagination: this.pagination,
          clients: this.clients,
          tesouraryAccounts: this.tesouraryAccounts,
        },
      });
    },
  },
};
</script>

<style scoped></style>
