<template>
  <div class="client-row" @click="$emit('actionRow')">
    <div class="client">
      <v-icon>
        {{ clientIcon }}
      </v-icon>
      <div>
        <JazzText
          textTransform="capitalize"
          color="black"
          size="description"
          weight="bold"
          >{{ clientName }}</JazzText
        >
        <JazzText color="grey" size="label">{{ identificationType }}</JazzText>
      </div>
    </div>
    <div class="status-account-mobile">
      <v-icon :color="`var(--${statusAccountColor}-color)`">
        fas fa-circle
      </v-icon>
      <JazzText weight="bold" size="description" :color="statusAccountColor">
        {{ paramsItem.status }}
      </JazzText>
    </div>
    <div class="row-group">
      <div class="unit">
        <JazzTextGroup
          label="Parceiro"
          :text="
            paramsItem.organizationUnitId +
            ' - ' +
            paramsItem.organizationUnitDescricao
          "
        />
      </div>
      <div class="status-client">
        <JazzTextGroup
          :text-color="statusClientColor"
          label="Status cliente"
          :text="paramsItem.statusClienteDescricao || ''"
        />
      </div>
    </div>
    <div class="status-account">
      <v-icon :color="`var(--${statusAccountColor}Color)`">
        fas fa-circle
        </v-icon>
      <JazzText weight="bold" size="description" :color="statusAccountColor">
        {{ paramsItem.status }}
      </JazzText>
    </div>
  </div>
</template>

<script>
import globalMethods from "../../../../../mixins/globalMethods";
import JazzText from "../../../../../components/typography/JazzText";
import JazzTextGroup from "../../../../../components/typography/JazzTextGroup";

export default {
  name: "ClientRow",
  components: { JazzTextGroup, JazzText },
  mixins: [globalMethods],
  props: {
    paramsItem: Object,
  },
  computed: {
    clientName() {
      const { nome } = this.paramsItem;
      if (nome) {
        return nome;
      }
      return "Nome não definido";
    },
    identificationType() {
      const { identificacaoFiscal } = this.paramsItem;
      if (identificacaoFiscal?.length === 14) {
        return `CNPJ: ${this.cnpjMask(identificacaoFiscal)}`;
      }
      return `CPF: ${this.cpfMask(identificacaoFiscal)}`;
    },
    clientIcon() {
      return this.paramsItem?.identificacaoFiscal?.length === 14
        ? "fa-store"
        : "fa-user";
    },
    statusAccountColor() {
      switch (this.paramsItem.status) {
        case "CadastroCompleto":
          return "primary";
        case "KYCNegado":
        case "CAFNegado":
        case "Cancelado":
          return "error";
        default:
          return "black";
      }
    },
    statusClientColor() {
      switch (this.paramsItem.statusClienteDescricao) {
        case "Bloqueado - Prevenção":
        case "Conta Cancelada":
        case "Cancelado":
          return "error";
        default:
          return "black";
      }
    },
  },
};
</script>

<style scoped lang="scss">
.client-row {
  width: 100%;
  padding: 0 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  align-content: stretch;

  @media only screen and (max-width: 1160px) {
    padding: 10px;
  }
}

.client-row:hover {
  background-color: $grayContrast;
  cursor: pointer;
}

.client {
  margin: 15px 0;
  display: flex;
  align-items: center;
  width: 300px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  @media only screen and (max-width: 1160px) {
    flex: 1;
  }
}

.row-group {
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.unit {
  width: 250px;
}

.client .v-icon {
  font-size: 24px;
  color: $blackColor;
  margin-right: 20px;
  width: 30px;
  text-align: center;
}

.status-client {
  width: 150px;
  display: block;
}

.status-account {
  display: flex;
  align-items: center;
  width: 300px;
}

.status-account i {
  font-size: 8px;
  margin-right: 10px;
}

.status-account {
  margin: 15px 0;
  width: 180px;
  text-align: right;
  display: flex;
  justify-content: flex-end;

  @media only screen and (max-width: 512px) {
    display: none;
  }
}

.status-account-mobile {
  display: none;
  margin: 15px 0;
  width: 180px;
  text-align: right;
  justify-content: flex-end;

  @media only screen and (max-width: 512px) {
    display: flex;
    flex: 1;
  }
}

.primary-color {
  color: $successColor;
}

.error-color {
  color: $dangerColor;
}

.black-color {
  color: $blackColor;
}
</style>
