import configAxios from "axios";
import router from "../router";
import store from "../store";
import { uuid } from "vue-uuid";
import { serviceOptions as ApiService } from "./api";
import { serviceOptions as BackofficeService } from "./apibackoffice";
import { serviceOptions as TravelService } from "./apitravel";
const apiURL = getEnvironment();

function getEnvironment() {
  return process?.env?.VUE_APP_API_URL;
}

function isDebugMode() {
  return process?.env?.VUE_APP_DEBUG_MODE === "true";
}

async function encryptData(config) {
  try {
    const { symmetric, asymmetric } = { ...store.state.auth.secure };
    const accessToken = localStorage.getItem("access_token");
    await symmetric.generateSecret("", window.Cypress);
    const bearerToken = accessToken ? `Bearer ${accessToken}` : null;
    const jwt = bearerToken ? await symmetric.encrypt(`${bearerToken}`) : null;
    const secret = await asymmetric.encrypt(symmetric.getAlgKeyAndIv());
    const method = await symmetric.encrypt(config.method);
    const chaveDeIdempotencia = uuid.v4();
    const payload = await symmetric.encrypt(
      JSON.stringify({
        ...(config.data || config.params),
        chaveDeIdempotencia: chaveDeIdempotencia,
      })
    );

    if (isDebugMode()) {
      config.debug = {
        route: `${apiURL}${config.url}`,
        data: {
          ...(config.data || config.params),
          chaveDeIdempotencia: chaveDeIdempotencia,
        },
        method: config.method,
      };
    }
    config.params = undefined;
    const route = await symmetric.encrypt(`${apiURL}${config.url}`);
    const headers = await symmetric.encrypt(
      JSON.stringify({
        UO: `${store.state.auth.user.idOrganizationUnit || "1"}`,
        "App-Versao": store.state.packageVersion || "0",
      })
    );
    const securePayload = {
      p: payload,
      j: jwt,
      s: secret,
      r: route,
      m: method,
      h: headers,
    };
    config.data = JSON.stringify({
      payload: btoa(JSON.stringify(securePayload)),
    });
    config.keyIv = symmetric.getAlgKeyAndIv() || null;
    config.url = undefined;
    config.method = "post";
    config.baseURL = process?.env?.VUE_APP_BASE_URL;
    return config;
  } catch (e) {
    console.log("e", e);
    store.dispatch("action_openMessageModal", {
      styleMessage: "danger",
      message:
        "Ocorreu um erro durante a requisição. Por favor, tente novamente.\n (Código interno: CPT001)",
      title: "Atenção",
    });
    return config;
  }
}

async function decryptData(crypt, keyIv) {
  try {
    const { symmetric } = { ...store.state.auth.secure };
    if (crypt && keyIv) {
      return JSON.parse(await symmetric.decrypt(crypt, keyIv));
    }
    return "Ocorreu um erro durante a requisição. Por favor, tente novamente.\n (Código interno: CPT002)";
  } catch (e) {
    if (isDebugMode()) {
      console.log("(Código interno: CPT002)", e);
    }
  }
}

async function errorHandling(errorDecrypted, response) {
  const { message, details, validationErrors } = errorDecrypted.error;
  let detailsError = "\n";
  if (message === "Network Error") {
    store
      .dispatch("action_confirmationModal", {
        title: "Tempo Esgotado",
        message:
          "O tempo de permanência da página acabou, para retornar o seu acesso clique em <strong>recarregar página</strong>.",
        cancelText: "Cancelar",
        confirmText: "Recarregar página",
        directButton: "line",
        icon: "fas fa-exclamation-circle",
      })
      .then((confirm) => {
        if (confirm) {
          router.go();
        } else {
          localStorage.removeItem("token");
          localStorage.removeItem("access_token");
          localStorage.removeItem("encrypted_token");
          localStorage.removeItem("user_id");
          router.push("/login");
        }
      });
  }
  if (errorDecrypted) {
    try {
      if (Array.isArray(JSON.parse(details))) {
        JSON.parse(details).forEach((error) => {
          detailsError = `${detailsError}${error?.ErrorMessage || error}.\n`;
        });
      }

      if (validationErrors && Array.isArray(JSON.parse(validationErrors))) {
        JSON.parse(validationErrors).forEach((error) => {
          detailsError = `${detailsError}${error?.message || error}.\n`;
        });
      }

      if (!validationErrors && !details) {
        store.dispatch("action_openMessageModal", {
          styleMessage: "danger",
          message: message,
          title: "Atenção",
        });
      } else {
        store.dispatch("action_openMessageModal", {
          styleMessage: "danger",
          message: `${message || ""}${
            Array.isArray(JSON.parse(details)) ||
            (validationErrors && Array.isArray(JSON.parse(validationErrors)))
              ? `. ${detailsError}`
              : details || ""
          }`,
          title: "Atenção",
        });
      }
    } catch (er) {
      store.dispatch("action_openMessageModal", {
        styleMessage: "danger",
        message: `${message || ""}${details || ""}`,
        title: "Atenção",
      });
    }
  }
  return (
    errorDecrypted?.error ||
    "Ocorreu um erro durante a requisição. Por favor, tente novamente.\n (Código interno: CPT003)"
  );
}

const apiInstance = configAxios.create({
  baseURL: process?.env?.VUE_APP_BASE_URL,
  method: "post",
  timeout: "300000",
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    "X-Requested-With": "XMLHttpRequest",
  },
});

apiInstance.interceptors.request.use(async (config) => {
  return encryptData(config);
});
apiInstance.interceptors.response.use(
  async (response) => {
    if (window.Cypress && response?.data?.fixture) {
      response.data = require(`../../tests/e2e/fixtures/${response?.data?.fixture}`);
    }
    const responseDecrypted = await decryptData(
      response?.data?.response,
      response?.config?.keyIv
    );
    if (isDebugMode()) {
      console.log(`${response?.config.debug.route}`, {
        ...response?.config.debug,
        response: responseDecrypted,
      });
    }
    return Promise.resolve({
      data: responseDecrypted,
    });
  },
  async (error) => {
    const { response, config } = error;
    const errorDecrypted = await decryptData(
      response?.data?.response,
      config?.keyIv
    );
    if (isDebugMode()) {
      console.log(`${response?.config.debug.route}`, {
        ...config.debug,
        response: errorDecrypted,
      });
    }
    return Promise.reject(await errorHandling(errorDecrypted, response));
  }
);

ApiService.axios = apiInstance;
BackofficeService.axios = apiInstance;
TravelService.axios = apiInstance;

export default apiInstance;
