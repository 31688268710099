<template>
  <div class="arrow-tab">
    <v-tabs
      active-class="text-decoration-underline text-capitalize active-text"
      background-color="transparent"
      hide-slider
      v-model="tab"
    >
      <v-btn
        v-if="!hideArrow"
        @click="$emit('arrowClick')"
        class="arrow-back"
        text
        fab
        small
      >
        <v-icon size="30"> fa-solid fa-arrow-left</v-icon>
      </v-btn>
      <v-tab v-for="(tab, index) in tabs" :key="index" class="tab-text">{{
        tab.tabName
      }}</v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item v-for="(tab, index) in tabs" :key="index">
        <slot :name="tab.template" />
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
export default {
  props: {
    hideArrow: {
      type: Boolean,
      default: false,
    },
    tabs: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      tab: null,
    };
  },
};
</script>

<style lang="scss" scoped>
.tab-text {
  text-transform: capitalize !important;
  font-weight: bold;
  font-size: 1.7rem;
  letter-spacing: -0.1rem;
  color: $primaryColor;
  opacity: 0.5;
}
.active-text {
  opacity: 1;
}
.arrow-back {
  color: $primaryColor;
  margin-right: 0.6rem;
  margin-top: 3px;
}
</style>
