import TravelClientsIndex from "../../views/Travel/Client/TravelClientsIndex";
import TravelClientDetails from "../../views/Travel/Client/TravelClientDetails";
import TravelClientDataTab from "../../views/Travel/Client/components/tabs/TravelClientDataTab";
import TravelClientContractTab from "../../views/Travel/Client/components/tabs/TravelClientContractTab";
import TravelClientConfigurationTab from "../../views/Travel/Client/components/tabs/TravelClientConfigurationTab";
import TravelClientCardsTab from "../../views/Travel/Client/components/tabs/TravelClientCardsTab";
import CardDataTab from "../../views/Travel/Cards/components/tabs/CardDataTab";
import TransactionsTab from "../../views/Travel/Cards/components/tabs/TransactionsTab";
import HistoryTab from "../../views/Travel/Cards/components/tabs/HistoryTab";
import TravelClientTransactionsTab from "../../views/Travel/Client/components/tabs/TravelClientTransactionsTab";

export default [
  {
    path: "/travel/clientes",
    name: "TravelClientsIndex",
    component: TravelClientsIndex,
    meta: {
      permission: ["Pages.TravelClientes"],
      permissionName: "Travel",
    },
  },
  {
    path: "/travel/cliente/:integrador/:chaveDeCliente",
    name: "TravelClientDetails",
    component: TravelClientDetails,
    redirect: { name: "TravelClientData" },
    meta: {
      permission: ["Pages.TravelClientes"],
      permissionName: "Travel",
    },
    children: [
      {
        path: "dados",
        name: "TravelClientData",
        component: TravelClientDataTab,
      },
      {
        path: "contrato",
        name: "TravelClientContract",
        component: TravelClientContractTab,
      },
      {
        path: "configuracoes",
        name: "TravelClientConfiguration",
        component: TravelClientConfigurationTab,
      },
      {
        path: "cartoes",
        name: "TravelClientCards",
        component: TravelClientCardsTab,
        redirect: { name: "ClientCardDataTab" },
        children: [
          {
            path: "dados",
            name: "ClientCardDataTab",
            component: CardDataTab,
          },
          {
            path: "transacoes",
            name: "ClientCardTransactionsTab",
            component: TransactionsTab,
          },
          {
            path: "historico",
            name: "ClientCardHistoryTab",
            component: HistoryTab,
          },
        ],
      },
      {
        path: "transacoes",
        name: "TravelClientTransactions",
        component: TravelClientTransactionsTab,
      },
    ],
  },
];
