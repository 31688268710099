<template>
  <div class="w-100">
    <div v-if="cardSelected">
      <TravelCardsDetails
        :selectedCard="cardSelected"
        @back="cardSelected = null"
        internal-screen
        :tabsRedirect="[
          'ClientCardDataTab',
          'ClientCardTransactionsTab',
          'ClientCardHistoryTab',
        ]"
      />
    </div>
    <div v-else>
      <JazzListPage
        title="Cartões Gerados"
        :fields="fields"
        @search="searchRequest"
        hide-button-header
      >
        <div class="button-row" v-if="!loadingTable && cardsList.length > 0">
          <v-col cols="4">
            <TableLabelButton
              data-cy="report-generated-cards-button"
              title=""
              label="Relatório de
                Cartões Gerados"
              @click.native="generateReport()"
              icon="fas fa-file-download fa-lg"
              textTransform="capitalize"
              buttonColor="primary"
              iconColor="white"
              size="large"
              weight="bold"
            />
          </v-col>
        </div>

        <JazzTable
          :loading="loadingTable"
          :rows="cardsList"
          :pagination="pagination"
          @inputNewPage="searchRequest(null, $event)"
        >
          <template slot="reportRow" slot-scope="props">
            <TravelCardsRow
              :paramsItem="props.paramsItem"
              @click.native="cardSelected = props.paramsItem"
            />
          </template>
        </JazzTable>
      </JazzListPage>
    </div>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import globalMethods from "../../../../../mixins/globalMethods";
import statusCartao from "../../../../../dbjson/statusCartao.json";
import JazzListPage from "../../../../../components/template/JazzListPage";
import TableLabelButton from "../../../../../components/button/TableLabelButton";
import JazzTable from "../../../../../components/table/JazzTable";
import TravelCardsDetails from "../../../Cards/TravelCardsDetails";
import TravelCardsRow from "../../../Cards/components/rowsTable/TravelCardsRow";
import { PedidosCartaoService } from "../../../../../services/apitravel";

export default {
  name: "TravelCardsIndex",
  mixins: [globalMethods],
  components: {
    JazzListPage,
    JazzTable,
    TableLabelButton,
    TravelCardsDetails,
    TravelCardsRow,
  },
  props: {
    client: Object,
  },
  data() {
    return {
      fields: {},
      cardsList: [],
      loadingTable: false,
      filters: {},
      pagination: {},
      params: {},
      cardSelected: null,
    };
  },
  async mounted() {
    this.params = this.$route.params;

    if (this.params?.clientsList) {
      this.filters = this.params?.filters;
      this.pagination = this.params?.pagination;
      this.cardsList = this.params?.cardsList;
    }

    this.createForm();
  },
  methods: {
    ...mapMutations(["CHANGE_LOADING"]),
    createForm() {
      this.fields = {
        initialDate: {
          field: "JazzDateField",
          label: "Data Inicial",
          cols: 4,
          valueField: this.filters?.initialDate || this.dateSubtract30Days(),
          rules: [
            (fields) =>
              !this.compareDateAfterAndCurrent(
                fields.initialDate.valueField,
                fields.finalDate.valueField
              ) || "Data inicial deve ser menor que data final",
          ],
        },
        finalDate: {
          field: "JazzDateField",
          label: "Data Final",
          cols: 4,
          valueField: this.filters?.finalDate || this.dateEnFormat(),
          rules: [
            (fields) =>
              !this.compareDateAfterAndCurrent(
                fields.initialDate.valueField,
                fields.finalDate.valueField
              ) || "Data final deve ser maior que data inicial",
          ],
        },
        status: {
          label: "Status",
          field: "SelectField",
          cols: 4,
          items: [{ value: "", key: "Selecione" }, ...statusCartao],
          keyName: "key",
          value: "value",
          valueField: this.findObjectFromArray(
            statusCartao,
            "key",
            this.filters?.statusCartao
          ),
        },
        cardEndNumber: {
          field: "TextField",
          label: "Número do Cartão (4 últimos dígitos)",
          cols: 4,
          valueField: this.filters?.ultimos4Digitos || null,
          maxLength: "4",
          helpTextLabel:
            "Digite os 4 últimos dígitos do cartão. Ex: xxxx xxxx xxxx 1234",
        },
        cardName: {
          field: "TextField",
          label: "Nome Impresso",
          type: "text",
          cols: 4,
          valueField: this.filters?.nomeImpresso || null,
        },
      };
    },

    async searchRequest(filtersValues = null, page = 1) {
      if (filtersValues?.isValid || this.filters?.chaveDeCliente) {
        this.loadingTable = true;

        if (filtersValues) {
          const { initialDate, finalDate, cardName, cardEndNumber, status } =
            filtersValues.values;

          this.filters = {
            chaveDeCliente: this.params.chaveDeCliente,
            dataInicio: this.dateISOFormat(
              initialDate + " 00:00:00 +0000",
              "YYYY-MM-DD HH:mm:ss Z"
            ),
            dataFim: this.dateISOFormat(
              finalDate + " 23:59:59 +0000",
              "YYYY-MM-DD HH:mm:ss Z"
            ),
            nomeImpresso: cardName || null,
            ultimos4Digitos: cardEndNumber || null,
            status: this.convertArrayObjectFilter(status, "value") || 0,
          };
        }

        await PedidosCartaoService.consultarCartoes({
          body: {
            ...this.filters,
            pagina: page,
            porPagina: 45,
          },
        })
          .then((response) => {
            let { pagina, qtdPorPagina, totalItems, qtdPaginas } =
              response.result;
            this.pagination.page = pagina;
            this.pagination.perPage = qtdPorPagina;
            this.pagination.total = totalItems;
            this.pagination.pages = qtdPaginas;
            this.cardsList = response.result.items;
          })
          .finally(() => {
            this.loadingTable = false;
          });
      }
    },
    async generateReport() {
      this.CHANGE_LOADING(true);
      await PedidosCartaoService.gerarRelatorioCartoes({
        body: {
          ...this.filters,
        },
      })
        .then((response) => {
          let csvContent =
            "data:text/csv;charset=utf-8," + encodeURI(response.result.arquivo);
          this.downloadCsvString(
            csvContent,
            "CARTOES_" + this.client?.razaoSocial.replaceAll(" ", "")
          );
          this.$store.dispatch(
            "action_changeMessageSnackBar",
            "Arquivo baixado com sucesso!",
            { module: "template" }
          );
        })
        .catch(() => {
        })
        .finally(() => {
          this.CHANGE_LOADING(false);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.button-row {
  display: flex;
  flex-direction: row-reverse;
}
</style>
