import TravelCardsIndex from "../../views/Travel/Cards/TravelCardsIndex";
import TravelGenerationCardsForm from "../../views/Travel/Cards/TravelGenerationCardsForm";
import TravelCardsDetails from "../../views/Travel/Cards/TravelCardsDetails";
import CardDataTab from "../../views/Travel/Cards/components/tabs/CardDataTab";
import TransactionsTab from "../../views/Travel/Cards/components/tabs/TransactionsTab";
import HistoryTab from "../../views/Travel/Cards/components/tabs/HistoryTab";

export default [
  {
    path: "/travel/cartoes",
    name: "TravelCardsIndex",
    component: TravelCardsIndex,
    meta: {
      permission: ["Pages.TravelCartoes"],
      permissionName: "CartoesTravel",
    },
  },
  {
    path: "/travel/cartoes/novo_cartao_virtual",
    name: "TravelGenerationCardsForm",
    component: TravelGenerationCardsForm,
    meta: {
      permission: ["Pages.TravelCartoes"],
      permissionName: "CartoesTravel",
    },
  },
  {
    path: "/travel/cartao/:nomeCliente/:chaveDePedidoCartao/:idPedido?",
    name: "TravelCardsDetails",
    component: TravelCardsDetails,
    meta: {
      permission: ["Pages.TravelCartoes"],
      permissionName: "CartoesTravel",
    },
    redirect: { name: "CardDataTab" },
    children: [
      {
        path: "dados",
        name: "CardDataTab",
        component: CardDataTab,
      },
      {
        path: "transacoes",
        name: "TransactionsTab",
        component: TransactionsTab,
      },
      {
        path: "historico",
        name: "HistoryTab",
        component: HistoryTab,
      },
    ],
  },
];
