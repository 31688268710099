<template>
  <v-col :cols="cols">
    <button type="button" :class="colorStyle" class="internal-button intern" @click="$emit('internalButton')">
      {{ text }}
    </button>
  </v-col>
</template>
<script>
export default {
  name: "InternalButton",
  props: {
    text: String,
    colorStyle: String,
    cols: {},
  },
};
</script>

<style scoped lang="scss">
.internal-button {
  padding: 0 30px;
  height: 46px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  background: $primaryColor;
  color: $whiteColor;
  font-weight: bold;
  border: 1px solid $primaryColor;
  margin-top: 30px;
  text-align: center;
}

.internal-button:hover {
  background: $primaryColor;
  cursor: pointer;
}

.internal-button.secondary-color {
  background: transparent;
  border: 2px solid $primaryColor;
  color: $primaryColor;
}

.internal-button.secondary-color:hover {
  background: $primaryColor;
  border: 2px solid $primaryColor;
  color: $whiteColor;
}

.internal-button.gray-green {
  background: $opacityGrayColor;
  border: 2px solid $primaryColor;
  color: $primaryColor;
}

.internal-button.gray-green:hover {
  background: $primaryColor;
  border: 2px solid $primaryColor;
  color: $whiteColor;
}

.internal-button.success {
  background: $successColor;
  border: 2px solid $successColor;
  color: $whiteColor;
  font-weight: bold;
}

.internal-button.danger {
  background: var(--dangerColor) !important;
  border: 2px solid var(--dangerColor) !important;
  color: #ffffff;
  font-weight: bold;
}

.internal-button.disabled {
  background: $lightGrayColor;
  border: $lightGrayColor;
  pointer-events: none !important;
  cursor: default !important;
}

.internal-button.gray {
  background: $opacityGrayColor;
  border: 2px solid $lightGrayColor;
  color: $lightGrayColor;
  font-weight: bold;
}

.internal-button.gray:hover {
  background: $dangerColor;
  border: 2px solid $dangerColor;
  color: $whiteColor;
}

.internal-button.white-green {
  background: $whiteColor;
  border: 2px solid $primaryColor;
  color: $primaryColor;
}

.internal-button.white-green:hover {
  background: $primaryColor;
  border: 2px solid $primaryColor;
  color: $whiteColor;
}

.ultra-radius {
  border-radius: 100px;
}

.large {
  width: 100%;
}
</style>
