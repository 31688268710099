<template>
  <div>
    <template v-if="!loading && client">
      <JazzFormFields
        @cancel="enableContactFields(false)"
        :with-buttons="enableContactButtons"
        :with-button="labelContactButton"
        @checkValidity="enableContactFields(true, $event)"
        :fields="fieldsContact"
      />

      <JazzExpansionPanel title="Endereço do Cadastro">
        <template v-slot:header>
          <TextField
            disabled
            label="Endereço Resumido"
            :value-field="addressString"
          />
        </template>
        <template v-slot:content>
          <JazzFormFields disabled :fields="fieldsAddress" />
        </template>
      </JazzExpansionPanel>
      <JazzExpansionPanel
        data-cy="endereco-correspondencia-panel"
        :loading="loadingMailing"
        title="Endereço de Correspondência"
      >
        <template v-slot:header>
          <TextField
            disabled
            label="Endereço Resumido"
            :value-field="mailingAddressString"
          />
        </template>
        <template v-slot:content>
          <JazzFormFields
            @checkValidity="updateMailingAddress"
            @cancel="cancelAction"
            :with-buttons="enablefieldsMailingAddress"
            :disabled="!enablefieldsMailingAddress"
            :fields="fieldsMailingAddress"
          />
          <div v-show="!enablefieldsMailingAddress" class="container-button">
            <div class="editable-button">
              <SearchButton
                data-cy="edit-address-button"
                @click.native="
                  enablefieldsMailingAddress = !enablefieldsMailingAddress
                "
                text="Editar"
              />
            </div>
          </div>
        </template>
      </JazzExpansionPanel>
    </template>
    <JazzSkeletonLoader v-if="loading" type="form" />
  </div>
</template>

<script>
import JazzFormFields from "../../../components/form/JazzFormFields";
import estadosBrasileiros from "../../../dbjson/estadosBrasileiros.json";
import JazzSkeletonLoader from "../../../components/template/JazzSkeletonLoader";
import JazzExpansionPanel from "../../../components/JazzExpansionPanel";
import TextField from "../../../components/inputs/TextField";
import SearchButton from "../../../components/button/SearchButton";
import { ClientesService, UtilidadesService } from "../../../services/api";
import globalMethods from "../../../mixins/globalMethods";
import { mapActions } from "vuex";
import { ClientesBackofficeService } from "../../../services/apibackoffice";

export default {
  name: "ClientAddress",
  mixins: [globalMethods],
  components: {
    SearchButton,
    TextField,
    JazzExpansionPanel,
    JazzSkeletonLoader,
    JazzFormFields,
  },
  props: {
    client: Object,
  },
  data() {
    return {
      fieldsContact: {},
      fieldsAddress: {},
      fieldsMailingAddress: {},
      labelContactButton: "Editar",
      enableContactButtons: false,
      enablefieldsMailingAddress: false,
      loading: true,
      loadingMailing: false,
      addressString: "",
      mailingAddressString: "",
      chaveDeCliente: this.$route.params.chaveDeCliente,
    };
  },
  mounted() {
    if (this.client) {
      this.createContactFields(false);
      this.createAddressFields();
      this.createMailingAddressFields(
        this.client?.enderecoCorrespondencia || this.client?.endereco
      );
    }
  },
  methods: {
    ...mapActions(["action_changeMessageSnackBar"]),
    async cancelAction() {
      this.enablefieldsMailingAddress = false;
      await this.createMailingAddressFields(
        this.client.enderecoCorrespondencia || this.client.endereco
      );
    },
    async searchCep(fields) {
      const cep = this.removeMaskCharacters(
        fields?.addressZipCodeTextField.valueField
      );
      if (this.enablefieldsMailingAddress === true && cep.length === 8) {
        this.loadingMailing = true;
        const address = await UtilidadesService.consultarCep({
          body: {
            cep: cep,
          },
        }).then((res) => res?.result);
        if (address) {
          const { localidade, bairro, logradouro, uf } = address;
          fields.addressStateTextField.valueField = this.findObjectFromArray(
            estadosBrasileiros,
            "key",
            uf
          );
          fields.addressCityTextField.valueField = localidade;
          fields.addressNeighborhoodTextField.valueField = bairro;
          fields.addressStreetTextField.valueField = logradouro;
          fields.addressNumberStreetTextField.valueField = "";
          fields.addressComplementTextField.valueField = "";
        }
        this.loadingMailing = false;
      }
    },
    async updateMailingAddress(form) {
      if (form?.isValid) {
        const {
          addressStreetTextField,
          addressNumberStreetTextField,
          addressComplementTextField,
          addressNeighborhoodTextField,
          addressZipCodeTextField,
          addressCityTextField,
          addressStateTextField,
        } = form?.values;
        this.loadingMailing = true;
        await ClientesService.cadastrarEnderecoCorrespondencia({
          body: {
            enderecoCorrespondencia: {
              rua: addressStreetTextField,
              numero: addressNumberStreetTextField,
              complemento: addressComplementTextField,
              bairro: addressNeighborhoodTextField,
              cep: this.removeMaskCharacters(addressZipCodeTextField),
              cidade: addressCityTextField,
              uf: addressStateTextField?.key,
            },
            chaveDeCliente: this.chaveDeCliente,
          },
        })
          .then(() => {
            this.mailingAddressString = `${addressStreetTextField}, ${addressNeighborhoodTextField} - ${addressCityTextField}`;
            this.enablefieldsMailingAddress = false;
          })
          .finally(() => (this.loadingMailing = false));
        this.action_changeMessageSnackBar("Endereço atualizado com sucesso!");
      }
    },
    createMailingAddressFields(address) {
      this.mailingAddressString = `${address?.rua}, ${address?.bairro} - ${address?.cidade}`;
      this.fieldsMailingAddress = {
        addressZipCodeTextField: {
          field: "TextFieldMask",
          label: "CEP",
          maskFormat: "#####-###",
          cols: 5,
          changeValue: (fields) => this.searchCep(fields),
          valueField: address?.cep,
          rules: [
            (fields) =>
              !!fields.addressZipCodeTextField.valueField ||
              "Campo obrigatório",
          ],
        },
        addressStateTextField: {
          field: "SelectField",
          label: "Estado",
          cols: 7,
          valueField:
            address && address?.uf
              ? this.findObjectFromArray(estadosBrasileiros, "key", address?.uf)
              : null,
          items: [{ key: null, value: "Selecione" }, ...estadosBrasileiros],
          keyName: "value",
          keyValue: "key",
          rules: [
            (fields) =>
              !!fields.addressStateTextField.valueField?.key ||
              "Campo obrigatório",
          ],
        },
        addressCityTextField: {
          field: "TextField",
          label: "Cidade",
          cols: 6,
          valueField: address?.cidade,
          rules: [
            (fields) =>
              !!fields.addressCityTextField.valueField || "Campo obrigatório",
          ],
        },
        addressNeighborhoodTextField: {
          field: "TextField",
          label: "Bairro",
          cols: 6,
          valueField: address?.bairro,
          rules: [
            (fields) =>
              !!fields.addressNeighborhoodTextField.valueField ||
              "Campo obrigatório",
          ],
        },
        addressStreetTextField: {
          field: "TextField",
          label: "Logradouro",
          cols: 9,
          valueField: address?.rua,
          rules: [
            (fields) =>
              !!fields.addressStreetTextField.valueField || "Campo obrigatório",
          ],
        },
        addressNumberStreetTextField: {
          field: "TextFieldMask",
          label: "Número",
          maskFormat: "######",
          cols: 3,
          valueField: address?.numero,
          rules: [
            (fields) =>
              !!fields.addressNumberStreetTextField.valueField ||
              "Campo obrigatório",
          ],
        },
        addressComplementTextField: {
          field: "TextField",
          label: "Complemento",
          cols: 12,
          valueField: address?.complemento,
        },
      };
    },
    createContactFields(enableFields = false) {
      this.fieldsContact = {
        contactHeader: {
          field: "FormFieldsHeader",
          title: "Contato",
        },
        emailTextField: {
          field: "TextField",
          label: "E-mail",
          cols: !enableFields ? 6 : 9,
          valueField: this.client?.email,
          disabled: !enableFields,
        },
        celTextField: {
          field: "TextFieldMask",
          label: "Celular",
          cols: 3,
          maskFormat: "(##) #####-####",
          valueField:
            this.client && this.client?.telefoneMovel?.ddd
              ? this.client?.telefoneMovel?.ddd.replace("0", "") +
                this.client?.telefoneMovel?.numero
              : "",
          disabled: !enableFields,
        },
      };
      this.loading = false;
    },
    createAddressFields() {
      this.addressString = `${this.client?.endereco?.rua}, ${this.client?.endereco?.bairro} - ${this.client?.endereco?.cidade}`;
      this.fieldsAddress = {
        addressZipCodeTextField: {
          field: "TextFieldMask",
          label: "CEP",
          maskFormat: "#####-###",
          cols: 5,
          valueField: this.client?.endereco?.cep,
        },
        addressStateTextField: {
          field: "SelectField",
          label: "Estado",
          cols: 7,
          valueField:
            this.client && this.client.endereco && this.client?.endereco?.uf
              ? this.client?.endereco?.uf.toUpperCase()
              : "",
          items: [{ key: "", value: "Selecione" }, ...estadosBrasileiros],
          keyName: "value",
          keyValue: "key",
        },
        addressCityTextField: {
          variableName: "addressCityTextField",
          field: "TextField",
          label: "Cidade",
          cols: 6,
          valueField: this.client?.endereco?.cidade,
        },
        addressNeighborhoodTextField: {
          field: "TextField",
          label: "Bairro",
          cols: 6,
          valueField: this.client?.endereco?.bairro,
        },
        addressStreetTextField: {
          field: "TextField",
          label: "Logradouro",
          cols: 9,
          valueField: this.client?.endereco?.rua,
        },
        addressNumberStreetTextField: {
          field: "TextFieldMask",
          label: "Número",
          maskFormat: "######",
          cols: 3,
          valueField: this.client?.endereco?.numero,
        },
        addressComplementTextField: {
          field: "TextField",
          label: "Complemento",
          cols: 12,
          valueField: this.client?.endereco?.complemento,
        },
      };
      this.loading = false;
    },
    async updateEmail(email) {
      if (!email || email === "") return true;
      return await ClientesBackofficeService.alterarDadosEmail({
        body: {
          chaveDeCliente: this.chaveDeCliente,
          emailDto: {
            email: email,
            confirmacaoEmail: email,
          },
        },
      });
    },
    async updatePhone(phone) {
      if (!phone || phone === "") return true;
      phone = this.removeMaskCharacters(phone);
      let objPhone = {
        ddd: `0${phone.substring(0, 2)}`,
        numero: phone.substring(2, 11),
      };
      return await ClientesBackofficeService.alterarDadosCelular({
        body: {
          chaveDeCliente: this.chaveDeCliente,
          telefoneMovel: objPhone,
        },
      });
    },
    async enableContactFields(enableFields, form = undefined) {
      if (this.enableContactButtons && enableFields && form?.isValid) {
        try {
          this.loading = true;
          await Promise.all([
            this.updateEmail(form?.values?.emailTextField),
            this.updatePhone(form?.values?.celTextField),
          ]);
          await this.enableContactFields(false);
          this.fieldsContact.celTextField.valueField =
            form?.values?.celTextField;
          this.fieldsContact.emailTextField.valueField =
            form?.values?.emailTextField;
          this.action_changeMessageSnackBar("Contato atualizado com sucesso!");
        } finally {
          this.loading = false;
        }
      } else {
        this.labelContactButton = enableFields ? undefined : "Editar";
        this.enableContactButtons = enableFields;
        this.createContactFields(enableFields);
      }
    },
  },
  watch: {
    client() {
      this.createContactFields();
      this.createAddressFields();
    },
  },
};
</script>

<style scoped>
.container-button {
  display: flex;
  justify-content: flex-end;
}

.editable-button {
  width: 160px;
}
</style>
