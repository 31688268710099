<template>
  <menu data-cy="menu">
    <div>
      <a @click="$router.push({ name: 'Dashboard' })" class="img-box">
        <img width="130" alt="logo" :src="imgLogo" />
        <span class="version">v {{ $store.getters.appVersion }}</span>
      </a>
      <div class="menu-scroll">
        <ul>
          <ItemMenu v-if="hasAnyPermission([
            'Pages.Beneficio',
            'Pages.CadastroBeneficio',
            'Pages.Colaboradores',
            'Pages.Comunicados',
          ])
            " :name="'Benefício'" :class="childrensActive([
    'CustomerBenefitRegistration',
    'CustomerBenefitLoad',
    'CollaboratorsIndex',
    'CommunicationIndex',
  ])
    " :icon="'fas fa-hand-holding-usd'" :childrens="[
    {
      name: 'Cadastro',
      icon: 'fas fa-file',
      route_name: 'CustomerBenefitRegistration',
      show: hasPermission(['Pages.CadastroBeneficio']),
    },
    {
      name: 'Carga',
      icon: 'fas fa-list-alt',
      route_name: 'CustomerBenefitLoad',
      show: hasPermission(['Pages.Beneficio']),
    },
    {
      name: 'Colaboradores',
      icon: 'fas fa-users',
      route_name: 'CollaboratorsIndex',
      show: hasPermission(['Pages.Colaboradores']),
    },
    {
      name: 'Comunicados',
      icon: 'fas fa-sharp fa-suitcase',
      route_name: 'BenefitCommunicationIndex',
      show: hasPermission(['Pages.Comunicados']),
    },
  ]">
          </ItemMenu>

          <ItemMenu v-if="hasAnyPermission(['Pages.Clientes', 'Pages.FilaDeEspera'])" :name="'Antecipação'"
            :class="activeMenu('ClientsIndex')" :icon="'fas fa-users'" :childrens="[
              {
                name: 'Consulta',
                icon: 'fas fa-users',
                route_name: 'ClientsIndex',
                show: hasPermission(['Pages.Clientes']),
              },
              {
                name: 'Pré-cadastro',
                icon: 'fas fa-list-alt',
                route_name: 'PreviousRegistration',
                show: hasPermission(['Pages.Clientes']),
              },
            ]">
          </ItemMenu>
          <ItemMenu v-if="hasAnyPermission([
            'Pages.TravelEfetivacaoClientes',
            'Pages.TravelCadastroClientes',
            'Pages.TravelClientes',
            'TravelCustomerRegistration',
            'Pages.TravelTransacoes',
            'Pages.TravelFaturas',
            'Pages.TravelCartoes',
          ])
            " :name="'Travel'" :class="childrensActive([
    'TravelClientsIndex',
    'TravelTransactionsIndex',
    'TravelCardsIndex',
    'TravelInvoicesIndex',
  ])
    " :icon="'fas fa-plane'" :childrens="[
    {
      name: 'Clientes',
      icon: 'fas fa-building',
      route_name: 'TravelClientsIndex',
      show: hasAnyPermission(['Pages.TravelClientes']),
    },
    {
      name: 'Transações',
      icon: 'fas fa-exchange-alt',
      route_name: 'TravelTransactionsIndex',
      show: hasAnyPermission(['Pages.TravelTransacoes']),
    },
    {
      name: 'Faturas',
      icon: 'fas fa-file-invoice-dollar',
      route_name: 'TravelInvoicesIndex',
      show: hasAnyPermission(['Pages.TravelFaturas']),
    },
    {
      name: 'Cartões',
      icon: 'fas fa-credit-card',
      route_name: 'TravelCardsIndex',
      show: hasAnyPermission(['Pages.TravelCartoes']),
    },
  ]">
          </ItemMenu>
          <ItemMenu :name="'Alterar Senha'" :class="activeMenu('PasswordUpdate')" :route_name="'PasswordUpdate'"
            :icon="'fas fa-lock'">
          </ItemMenu>
        </ul>
      </div>
    </div>
    <router-link :key="$route.fullPath" class="menu-item-float" :to="{ name: 'Logout' }" tag="li">
      <div>
        <v-icon>
          fas fa-times
        </v-icon>
        Sair
      </div>
    </router-link>
  </menu>
</template>

<script>
import globalMethods from "../mixins/globalMethods";
import ItemMenu from "../components/template/ItemMenu";
import { mapGetters } from "vuex";

export default {
  name: "Menu",
  components: { ItemMenu },
  mixins: [globalMethods],
  methods: {
    childrensActive(routes) {
      let val = "inactive";
      routes.forEach((route) => {
        if (this.$route.name === route) {
          val = "active";
        }
      });
      return val;
    },
    activeMenu(route) {
      if (this.$route.name === route) {
        return "active";
      }
      return "inactive";
    },
  },
  computed: mapGetters(["imgLogo"]),
};
</script>

<style scoped lang="scss">
menu {
  background: $backgroundColorMenu;
  width: 290px;
  min-height: 100vh;
  height: 100vh;
  max-height: 100vh;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  -webkit-box-shadow: 1px 0 3px 0 rgba(212, 212, 212, 0.4);
  -moz-box-shadow: 1px 0 3px 0 rgba(212, 212, 212, 0.4);
  box-shadow: 1px 0 3px 0 rgba(212, 212, 212, 0.4);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: fixed;
  overflow-y: scroll;
  overflow-x: hidden;
  z-index: 10;
}

menu::-webkit-scrollbar {
  width: 0;
  height: 0;
}

menu::-webkit-scrollbar-thumb {
  background: transparent;
}

.img-box {
  height: 95px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.menu-item-float {
  padding: 0 20px;
  display: flex;
  align-items: center;
  margin: 10px 10px 30px 10px;
  border-radius: 30px;
  height: 48px !important;
  font-size: 14px;
  justify-content: space-between;
  color: $primaryColor;
  background: $whiteColor;
  cursor: pointer;
}

.menu-item-float div {
  height: 48px;
  display: flex;
  align-items: center;
}

li div .v-icon {
  margin-right: 10px;
  font-size: 18px;
  font-weight: bold;
}

span.version {
  position: absolute;
  top: 75px;
  font-size: 8pt;
  color: $whiteColor;
  float: right;
}
</style>
