<script>
import moment from "moment";
import store from "../store";
import domtoimage from "dom-to-image";
import { uuid } from "vue-uuid";

export default {
  methods: {
    dateSubtract30Days() {
      return moment().subtract(30, "days").format("YYYY-MM-DD");
    },
    dateNowFormat(format) {
      return moment().format(format);
    },
    dateTimeTransform(date, format) {
      return moment(date).format(format);
    },
    dateISOFormat(date, format) {
      return moment(date, format).toISOString();
    },
    dateFormat(date) {
      if (date === null || date === undefined) return null;
      return moment(date).format("DD/MM/YYYY");
    },
    dateMonthOnly(date) {
      if (date === null || date === undefined) return null;
      moment.updateLocale("pt", {
        months: [
          "Janeiro",
          "Fevereiro",
          "Março",
          "Abril",
          "Maio",
          "Junho",
          "Julho",
          "Agosto",
          "Setembro",
          "Outubro",
          "Novembro",
          "Dezembro",
        ],
      });
      return moment(date).format("MMMM/YYYY");
    },
    dateTimeFormat(date) {
      return moment(date).format("DD/MM/YYYY HH:mm:ss");
    },

    dateEnFormat(date, format) {
      return moment(date, format).format("YYYY-MM-DD");
    },

    dateAddOneDay() {
      return moment().add(1, "days").format("YYYY-MM-DD");
    },
    dateTimeFormatMinutes(date) {
      return moment(date).format("DD/MM/YYYY HH:mm");
    },

    getISODateWithTime(date) {
      let dateFormat = (date || moment().format("DD/MM/YYYY")).split("/");
      let ano = dateFormat[2],
        mes = dateFormat[1],
        dia = dateFormat[0];
      let startTime = this.dateISOFormat(
        `${ano}/${mes}/${dia} 00:00:00 +0000`,
        "YYYY-MM-DD HH:mm:ss Z"
      );
      let endTime = this.dateISOFormat(
        `${ano}/${mes}/${dia} 23:59:59 +0000`,
        "YYYY-MM-DD HH:mm:ss Z"
      );

      return {
        normalTime: `${dia}/${mes}/${ano}`,
        startTime: startTime,
        endTime: endTime,
      };
    },

    compareDateAfter(inicial, final) {
      return moment(inicial).isAfter(moment(final));
    },

    compareDateBefore(inicial, final) {
      return moment(inicial).isSameOrBefore(moment(final));
    },

    compareDateAfterAndCurrent(inicial, final) {
      return moment(inicial).isSameOrAfter(moment(final));
    },

    diffBetweenDates(pastDate, currentDate) {
      const now = moment(currentDate);
      const past = moment(pastDate);
      const duration = moment.duration(now.diff(past));

      const days = duration.asDays();

      return days;
    },
    isTheSameDate(date1, date2) {
      if (date1 === date2) {
        return true;
      } else {
        return false;
      }
    },
    timeAddOneHour(rounded) {
      const date = new Date();
      const hour = date.getHours() + 1;
      const minutes = date.getMinutes();
      if (rounded) {
        return hour + ":00";
      } else {
        return hour + ":" + minutes;
      }
    },
    getCurrentTime() {
      const date = new Date();
      const hour = date.getHours();
      const minutes = date.getMinutes();
      return hour + ":" + minutes;
    },
    compareHours(hour1, hour2, allowSameHour) {
      hour1 = hour1.split(":");
      hour2 = hour2.split(":");

      var d = new Date();
      var date1 = new Date(
        d.getFullYear(),
        d.getMonth(),
        d.getDate(),
        hour1[0],
        hour1[1]
      );
      var date2 = new Date(
        d.getFullYear(),
        d.getMonth(),
        d.getDate(),
        hour2[0],
        hour2[1]
      );
      if (allowSameHour) {
        return date1 >= date2;
      } else {
        return date1 > date2;
      }
    },
    getHourFromDate(date) {
      var time = date.split("T").pop();
      time = time.split(":").slice(0, 2);
      return time.join(":");
    },
    pontoPorVirgula(string) {
      return string.replace(".", ",");
    },
    byteToKByte(bytes, fixed) {
      return this.pontoPorVirgula((bytes / 1000).toFixed(fixed) + " KB");
    },
    byteToMByte(bytes, fixed) {
      return this.pontoPorVirgula((bytes / 1000000).toFixed(fixed) + " MB");
    },
    byteToGByte(bytes, fixed) {
      return this.pontoPorVirgula((bytes / 1000000000).toFixed(fixed) + " GB");
    },
    byteFormat(bytes, format) {
      switch (format) {
        case "KB":
          return this.byteToKByte(bytes, 2);
        case "MB":
          return this.byteToMByte(bytes, 2);
        case "GB":
          return this.byteToGByte(bytes, 2);
      }
    },
    openMessageModal(styleMessage, title, message) {
      const msg = {
        show: true,
        styleMessage: styleMessage,
        title: title,
        message: message,
      };
      if (title === undefined && message === undefined) {
        msg.title = "Ocorreu um erro!";
        msg.message =
          "Verifique a sua conexão com a internet e tente novamente mais tarde.";
      } else if (
        (message === undefined || message === null) &&
        title !== undefined
      ) {
        msg.title = "Atenção !";
        msg.message = title;
      }
      store.commit("CHANGE_MESSAGE_MODAL", msg);
    },
    openMessageSnackbar(message) {
      store.dispatch("action_changeMessageSnackBar", message);
    },
    convertStringFilter(value) {
      return value && value.length > 0 ? value : undefined;
    },
    convertArrayObjectFilter(object, key) {
      return object && object[key] && object[key] !== null && object[key] !== ""
        ? object[key]
        : null;
    },
    convertArrayObjectFilterBoolean(object, key) {
      return object &&
        object[key] !== undefined &&
        object[key] !== null &&
        object[key] !== ""
        ? object[key]
        : null;
    },
    maskInputMoney(value) {
      return (value * 1).toFixed(2) / 100;
    },
    money2(value) {
      return (value * 1)
        .toFixed(2)
        .replace(".", ",")
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    moneyInput2MoneyFloat(value) {
      return parseFloat(
        value.replace(".", "").replace(",", ".").replace("R$ ", "")
      );
    },
    removePrefixMoney(value) {
      return value.toString().split("R$ ").join("");
    },
    stringToDecimal(string) {
      if (!string) {
        return null;
      }
      let value = string
        .toString()
        .split(".")
        .join("")
        .split(",")
        .join(".")
        .split("R$ ")
        .join("")
        .split(" ")
        .join("");
      return parseFloat(value);
    },
    removeMaskMoney(string) {
      if (!string) {
        return null;
      }
      let value = string
        .toString()
        .split(".")
        .join("")
        .split(",")
        .join("")
        .split("R$ ")
        .join("")
        .split(" ")
        .join("");
      return parseInt(value);
    },
    //retorna o tamanho da coluna de acordo com o tamanho do array e o index
    returnColByLength(index, length) {
      if (length === 1) return 12;
      if (length % 2 === 0) return 6;
      if (length - 1 === index) return 12;
      return 6;
    },
    removeMaskCharacters(string) {
      if (!string) {
        return null;
      }
      return string
        .toString()
        .split("/")
        .join("")
        .split("(")
        .join("")
        .split(")")
        .join("")
        .split(".")
        .join("")
        .split(",")
        .join("")
        .split("R$ ")
        .join("")
        .split(" ")
        .join("")
        .split("-")
        .join("")
        .split("_")
        .join("");
    },
    splitWordsByUppercase(string) {
      return string.match(/[A-Z][a-z]+|[0-9]+/g).join(" ");
    },
    removeObjectFromArray(array, key, value) {
      return array.filter((e) => e[key] !== value);
    },
    findObjectFromArray(array, key, value) {
      return array.find((e) => `${e[key]}` === `${value}`);
    },
    hasAnyPermission(requiredPermissions) {
      let n = 0;
      requiredPermissions.forEach((permission) => {
        if (store.state.auth.user.permissions.includes(permission)) {
          n++;
        }
      });
      return n > 0;
    },

    downloadBase64(url, title) {
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", title);
      document.body.appendChild(link);
      link.click();
    },

    downloadCsvString(url, title) {
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", title);
      document.body.appendChild(link);
      link.click();
    },

    hasPermission(requiredPermissions) {
      if (!requiredPermissions) return false;
      let n = requiredPermissions.length;
      requiredPermissions.forEach((perm) => {
        if (store.state.auth.user.permissions.includes(perm)) {
          n--;
        }
      });
      return n === 0;
    },
    filterTypesTreasuryTransaction(item) {
      switch (item) {
        case "DESCARGA_TESOURARIA":
          return "Descarga";
        default:
          return item;
      }
    },
    filterTypeStatusManageCard(item) {
      switch (item) {
        case 1:
          return "Desbloqueado";
        case 2:
        case 5010:
          return "Bloqueado por Pedido Cliente";
        case 3:
        case 5000:
          return "Bloqueado por Fraude";
        case 4:
          return "Bloqueado por Senha Incorreta";
        case 5:
          return "Situação Judicical";
        case 6:
          return "Bloqueado por Roubo";
        case 7:
          return "Bloqueado por Perda";
        case 8:
          return "Cancelado/Desativado";
        case 9:
        case 5006:
          return "Bloqueado Por Extravio";
        case 10:
        case 5007:
          return "Bloqueado por Suspeita de Fraude";
        case 11:
        case 5005:
          return "Bloqueado por Emissão";
        case 12:
        case 5008:
          return "Bloqueado PLD";
        case 13:
        case 5011:
          return "Bloqueado Danificado";
        case 5001:
          return "Bloqueado por Tentativas de Acesso";
        case 5002:
          return "Bloqueado por Roubo";
        case 5003:
          return "Bloqueado por Perda";
        case 5004:
          return "Bloqueado por Solicitação Judicial";
        case 6000:
          return "Desbloqueio por Contestação de Fraude";
        case 6001:
          return "Desbloqueio por Tentativas de Acesso";
        case 6002:
          return "Desbloqueio por Roubo";
        case 6003:
          return "Desbloqueio por Perda";
        case 6004:
          return "Desbloqueio por Solicitação Judicial";
        case 6005:
          return "Desbloqueio por Emissão";
        default:
          return item;
      }
    },
    returnSVGCardFlag(name) {
      if (name) {
        let cleanName = name.toLowerCase().split(" ").join("");
        return require(`@/assets/flag-cards/${cleanName}.svg`);
      }
      return "";
    },
    statusCadastroCliente() {
      return [
        { value: 1, key: "Pré cadastro" },
        { value: 2, key: "Cadastro completo" },
        { value: 3, key: "Em análise KYC" },
        { value: 5, key: "Negado por KYC" },
        { value: 7, key: "Em análise CAF" },
        { value: 8, key: "Negado por CAF" },
        { value: 9, key: "Aguardando assinatura" },
        { value: 10, key: "Aguardando solicitacao link" },
        { value: 11, key: "Aguardando confirmação de documentos" },
        { value: 12, key: "Cancelado" },
      ];
    },
    arrayBrStates() {
      return [
        { key: "AC", value: "Acre" },
        { key: "AL", value: "Alagoas" },
        { key: "AP", value: "Amapá" },
        { key: "AM", value: "Amazonas" },
        { key: "BA", value: "Bahia" },
        { key: "CE", value: "Ceará" },
        { key: "DF", value: "Distrito Federal" },
        { key: "ES", value: "Espírito Santo" },
        { key: "GO", value: "Goiás" },
        { key: "MA", value: "Maranhão" },
        { key: "MT", value: "Mato Grosso" },
        { key: "MS", value: "Mato Grosso do Sul" },
        { key: "MG", value: "Minas Gerais" },
        { key: "PA", value: "Pará" },
        { key: "PB", value: "Paraíba" },
        { key: "PR", value: "Paraná" },
        { key: "PE", value: "Pernambuco" },
        { key: "PI", value: "Piauí" },
        { key: "RJ", value: "Rio de Janeiro" },
        { key: "RN", value: "Rio Grande do Norte" },
        { key: "RS", value: "Rio Grande do Sul" },
        { key: "RO", value: "Rondônia" },
        { key: "RR", value: "Roraima" },
        { key: "SC", value: "Santa Catarina" },
        { key: "SP", value: "São Paulo" },
        { key: "SE", value: "Sergipe" },
        { key: "TO", value: "Tocantins" },
      ];
    },
    arrayEmittingOrgan() {
      return [
        {
          value: "SSP",
          label: "SSP - Secretaria de Segurança Pública do Estado",
        },
        { value: "RCPN", label: "RCPN - Cartório Civil" },
        { value: "PF", label: "PF - Polícia Federal" },
        { value: "DETRAN", label: "DETRAN" },
        { value: "ABNC", label: "ABNC - Academia Brasileira de Neurocirurgia" },
        {
          value: "CGPI/DUREX/DPF",
          label:
            "CGPI/DUREX/DPF - Coordenação Geral de Polícia de Imigração da Polícia Federal",
        },
        {
          value: "CGPI",
          label: "CGPI - Coordenação-Geral de Privilégios e Imunidades",
        },
        {
          value: "CGPMAF",
          label:
            "CGPMAF - Coordenadoria Geral de Polícia Marítima, Aeronáutica e de Fronteiras",
        },
        { value: "CNIG", label: "CNIG - Conselho Nacional de Imigração" },
        { value: "CNT", label: "CNT - Confederação Nacional de Transporte" },
        { value: "COREN", label: "COREN - Conselho Regional de Enfermagem" },
        { value: "CORECON", label: "CORECON - Conselho Regional de Economia" },
        { value: "CRA", label: "CRA - Conselho Regional de Administração" },
        {
          value: "CRAS",
          label: "CRAS - Centro de Referência de Assistência Social",
        },
        { value: "CRB", label: "CRB - Conselho Regional de Biblioteconomia" },
        { value: "CRC", label: "CRC - Conselho Regional de Contabilidade" },
        { value: "CRE", label: "CRE - Conselho Regional de Estatística" },
        {
          value: "CREA",
          label: "CREA - Conselho Regional de Engenharia e Agronomia",
        },
        {
          value: "CRECI",
          label: "CRECI - Conselho Regional de Corretores de Imóveis",
        },
        {
          value: "CREFIT",
          label:
            "CREFIT - Conselho Regional de Fisioterapia e Terapia Ocupacional",
        },
        {
          value: "CRESS",
          label: "CRESS - Conselho Regional de Serviço Social",
        },
        { value: "CRF", label: "CRF - Conselho Regional de Farmácia" },
        { value: "CRM", label: "CRM - Conselho Regional de Medicina" },
        { value: "CRN", label: "CRN - Conselho Regional de Nutrição" },
        { value: "CRO", label: "CRO - Conselho Regional de Odontologia" },
        { value: "CRP", label: "CRP - Conselho Regional de Psicologia" },
        {
          value: "CRPRE",
          label:
            "CRPRE - Conselho Regional de Profissionais de Relações Públicas",
        },
        { value: "CRQ", label: "CRQ - Conselho Regional de Química" },
        {
          value: "CRRC",
          label: "CRRC - Conselho Regional de Representantes Comerciais",
        },
        {
          value: "CRMV",
          label: "CRMV - Conselho Regional de Medicina Veterinária",
        },
        { value: "CSC", label: "CSC - Carteira Sede Carpina de Pernambuco" },
        {
          value: "CTPS",
          label: "CTPS - Carteira de Trabalho e Previdência Social",
        },
        { value: "DIC", label: "DIC - Diretoria de Identificação Civil" },
        { value: "DIREX", label: "DIREX - Diretoria-Executiva" },
        {
          value: "DPMAF",
          label: "DPMAF - Divisão de Polícia Marítima, Área e de Fronteiras",
        },
        { value: "DPT", label: "DPT - Departamento de Polícia Técnica Geral" },
        { value: "DST", label: "DST - Programa Municipal DST/Aids" },
        {
          value: "FGTS",
          label: "FGTS - Fundo de Garantia do Tempo de Serviço",
        },
        {
          value: "FIPE",
          label: "FIPE - Fundação Instituto de Pesquisas Econômicas",
        },
        { value: "FLS", label: "FLS - Fundação Lyndolpho Silva" },
        { value: "GOVGO", label: "GOVGO - Governo do Estado de Goiás" },
        { value: "I CLA", label: "I CLA - Carteira de Identidade Classista" },
        { value: "IFP", label: "IFP - Instituto Félix Pacheco" },
        { value: "IGP", label: "IGP - Instituto Geral de Perícias" },
        {
          value: "IICCECF/RO",
          label:
            "IICCECF/RO - Instituto de Identificação Civil e Criminal Engrácia da Costa Francisco de Rondônia",
        },
        { value: "IIMG", label: "IIMG - Inter-institutional Monitoring Group" },
        { value: "IML", label: "IML - Instituto Médico-Legal" },
        { value: "IPC", label: "IPC - Índice de Preços ao Consumidor" },
        { value: "IPF", label: "IPF - Instituto Pereira Faustino" },
        { value: "MAE", label: "MAE - Ministério da Aeronáutica" },
        { value: "MEX", label: "MEX - Ministério do Exército" },
        { value: "MMA", label: "MMA - Ministério da Marinha" },
        { value: "OAB", label: "OAB - Ordem dos Advogados do Brasil" },
        { value: "OMB", label: "OMB - Ordens dos Músicos do Brasil" },
        {
          value: "PCMG",
          label: "PCMG - Polícia Civil do Estado de Minas Gerais",
        },
        {
          value: "PMMG",
          label: "PMMG - Polícia Militar do Estado de Minas Gerais",
        },
        { value: "POM", label: "POM - Polícia Militar" },
        {
          value: "SDS",
          label: "SDS - Secretaria de Defesa Social (Pernambuco)",
        },
        {
          value: "SNJ",
          label:
            "SNJ - Secretaria Nacional de Justiça / Departamento de Estrangeiros",
        },
        { value: "SECC", label: "SECC - Secretaria de Estado da Casa Civil" },
        {
          value: "SEJUSP",
          label:
            "SEJUSP - Secretaria de Estado de Justiça e Segurança Pública – Mato Grosso",
        },
        { value: "EST", label: "EST - Carteira de Estrangeiro" },
        {
          value: "SESP",
          label: "SESP - Secretaria de Estado da Segurança Pública do Paraná",
        },
        { value: "SJS", label: "SJS - Secretaria da Justiça e Segurança" },
        {
          value: "SJTC",
          label: "SJTC - Secretaria da Justiça do Trabalho e Cidadania",
        },
        {
          value: "SJTS",
          label: "SJTS - Secretaria da Justiça do Trabalho e Segurança",
        },
        {
          value: "SPTC",
          label: "SPTC - Secretaria de Polícia Técnico-Científica",
        },
      ];
    },
    validateDate(date) {
      if (date.length === 10) {
        let day = date[0] + date[1];
        let month = date[3] + date[4];
        let year = date[6] + date[7] + date[8] + date[9];
        return (
          day >= "01" &&
          day <= "31" &&
          month >= "01" &&
          month <= "12" &&
          year >= "1900" &&
          year <= "2070"
        );
      }
      return false;
    },
    validateCpf(value) {
      if (!value) return false;

      let cpf = value.replace(/[^\d]+/g, "");

      let sum = 0;
      let remainder;
      let i;

      if (
        cpf.length !== 11 ||
        cpf == "00000000000" ||
        cpf == "11111111111" ||
        cpf == "22222222222" ||
        cpf == "33333333333" ||
        cpf == "44444444444" ||
        cpf == "55555555555" ||
        cpf == "66666666666" ||
        cpf == "77777777777" ||
        cpf == "88888888888" ||
        cpf == "99999999999"
      )
        return false;
      for (i = 1; i <= 9; i++)
        sum += parseInt(cpf.substring(i - 1, i)) * (11 - i);
      remainder = (sum * 10) % 11;
      if (remainder == 10 || remainder == 11) remainder = 0;
      if (remainder != parseInt(cpf.substring(9, 10))) return false;
      sum = 0;
      for (i = 1; i <= 10; i++)
        sum += parseInt(cpf.substring(i - 1, i)) * (12 - i);
      remainder = (sum * 10) % 11;
      if (remainder == 10 || remainder == 11) remainder = 0;
      if (remainder != parseInt(cpf.substring(10, 11))) return false;
      return true;
    },
    validateCnpj(s) {
      if (!s) return false;

      let cnpj = s.replace(/[^\d]+/g, "");

      if (
        cnpj.length != 14 ||
        cnpj == "00000000000000" ||
        cnpj == "11111111111111" ||
        cnpj == "22222222222222" ||
        cnpj == "33333333333333" ||
        cnpj == "44444444444444" ||
        cnpj == "55555555555555" ||
        cnpj == "66666666666666" ||
        cnpj == "77777777777777" ||
        cnpj == "88888888888888" ||
        cnpj == "99999999999999"
      )
        return false;
      var tamanho = cnpj.length - 2;
      var numeros = cnpj.substring(0, tamanho);
      var digitos = cnpj.substring(tamanho);
      var soma = 0;
      var pos = tamanho - 7;
      for (var i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--;
        if (pos < 2) pos = 9;
      }
      var resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
      if (resultado != digitos.charAt(0)) return false;
      tamanho = tamanho + 1;
      numeros = cnpj.substring(0, tamanho);
      soma = 0;
      pos = tamanho - 7;
      for (let i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--;
        if (pos < 2) pos = 9;
      }
      resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
      if (resultado != digitos.charAt(1)) return false;
      return true;
    },
    validateEmail(string) {
      if (!string) return false;
      let user = string.substring(0, string.indexOf("@"));
      let domain = string.substring(string.indexOf("@") + 1, string.length);

      if (
        user.length >= 1 &&
        domain.length >= 3 &&
        user.search("@") == -1 &&
        domain.search("@") == -1 &&
        user.search(" ") == -1 &&
        domain.search(" ") == -1 &&
        domain.search(".") != -1 &&
        domain.indexOf(".") >= 1 &&
        domain.lastIndexOf(".") < domain.length - 1
      ) {
        return true;
      } else {
        return false;
      }
    },
    cpfMask(value) {
      if (value && value.length === 11) {
        return (
          value.substring(0, 3) +
          "." +
          value.substring(3, 6) +
          "." +
          value.substring(6, 9) +
          "-" +
          value.substring(9, 11)
        );
      }
      return "---";
    },
    cnpjMask(value) {
      if (value && value.length === 14) {
        return (
          value.substring(0, 2) +
          "." +
          value.substring(2, 5) +
          "." +
          value.substring(5, 8) +
          "/" +
          value.substring(8, 12) +
          "-" +
          value.substring(12, 14)
        );
      }
      return "---";
    },
    phoneMask(v) {
      return v.replace(/(\d)(\d{4})$/, "$1-$2");
    },
    removeTelMask(value) {
      return {
        ddd: "0" + value.substr(1, 2),
        numero: value.substr(5, value.length).split("-").join(""),
      };
    },
    removeMaskCharactersChangeToSpace(string) {
      if (!string) {
        return null;
      }
      return string
        .toString()
        .split("/")
        .join(" ")
        .split("(")
        .join(" ")
        .split(")")
        .join(" ")
        .split(".")
        .join(" ")
        .split(",")
        .join(" ")
        .split("R$ ")
        .join(" ")
        .split(" ")
        .join(" ")
        .split("-")
        .join(" ")
        .split("_")
        .join(" ");
    },
    capitalizeFirstLetter(string) {
      if (!string) {
        return null;
      }
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    isObjectEmpty(value) {
      return (
        Object.prototype.toString.call(value) === "[object Object]" &&
        JSON.stringify(value) === "{}"
      );
    },
    fixedTwoDecimalPlaces(value) {
      const factorOfTen = Math.pow(10, 2);
      let roundResult = Math.round(value * factorOfTen) / factorOfTen;
      return this.pontoPorVirgula(roundResult.toString());
    },
    convertDomToImageJpeg(id, imageName) {
      let domId = document.getElementById(id);

      return domtoimage.toJpeg(domId, { quality: 0.95 }).then((dataUrl) => {
        var link = document.createElement("a");
        link.download = imageName + ".jpeg";
        link.href = dataUrl;
        link.click();
      });
    },
    convertDomToImagePng(id, imageName) {
      let domId = document.getElementById(id);

      return domtoimage.toPng(domId, { quality: 0.95 }).then((dataUrl) => {
        var link = document.createElement("a");
        link.download = imageName + ".png";
        link.href = dataUrl;
        link.click();
      });
    },
    decodeBase64(base64) {
      return decodeURIComponent(atob(base64));
    },
    convertCsvToJson(csv) {
      const csvToArray = csv.split("\n");
      const jsonKeys = csvToArray[0].split(";");

      var result = [];

      const jsonArray = csvToArray.map((li) => {
        const obj = {};

        li.split(";").forEach((value, i) => {
          obj[jsonKeys[i].trim()] = value.trim();
        });

        result.push(obj);

        return obj;
      });

      jsonArray.shift();

      return result;
    },
    async convertUrlToBase64(url) {
      return await new Promise((resolve, reject) => {
        fetch(url, {
          method: "GET",
          headers: {
            Origin: "Anonymous",
          },
        })
          .then((response) => response.blob())
          .then((blob) => {
            const reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
          });
      });
    },
    downloadFile(url) {
      let link = document.createElement("a");
      link.href = url;
      link.click();
    },
    generateGuid() {
      return uuid.v4();
    },
    passwordRules(password) {
      let lowerLetter = /^(?=.*[a-z])/;
      let upperLetter = /^(?=.*[A-Z])/;
      let specialCharacter = /^(?=.*[$*&@!%¨()=#])/;
      let numero = /^(?=.*[0-9])/;

      if (!(this.id !== "new" ? true : !!password)) {
        return "O campo Senha é obrigatório";
      } else if (password === "") {
        return "O campo Senha é obrigatório";
      } else if (
        password !== undefined &&
        password !== null &&
        (password === "" ? true : password.length < 8 && password.length > 8)
      ) {
        return "A senha deve conter 8 caracteres";
      } else if (!lowerLetter.test(password)) {
        return "É necessário ao menos uma letra minúscula";
      } else if (!upperLetter.test(password)) {
        return "É necessário ao menos uma letra maiúscula";
      } else if (!specialCharacter.test(password)) {
        return "É necessário ao menos um caracter especial";
      } else if (!numero.test(password)) {
        return "É necessário ao menos um numero";
      } else if (password.length !== 8) {
        return "É necessário ter 8 caracteres";
      } else {
        return true;
      }
    },
    openUrl(url) {
      window.open(url, "_blank");
    },
    convertFileExtension(extension) {
      const fileExtensionsList = {
        pdf: 1,
        PDF: 1,
        jpg: 2,
        JPG: 2,
        png: 5,
        PNG: 5,
        jpeg: 6,
        JPEG: 6,
        csv: 7,
        CSV: 7,
      };

      return fileExtensionsList[extension] || null;
    },
  },
};
</script>
