<template>
  <div class="account-row" @click="$emit('actionRow')">
    <div class="account">
      <v-icon>
        fas fa-user-circle
      </v-icon>
      <div>
        <JazzTextGroup
          label="Empresa"
          :text="paramsItem.parceiro || 'Não definido'"
        />
      </div>
    </div>
    <div
      class="status-account-mobile"
      v-show="paramsItem.statusCadastroContaId"
    >
      <v-icon :class="`${statusAccountColor}-color`">
        fas fa-user-circle
      </v-icon>
      <JazzText weight="bold" size="description" :color="statusAccountColor">
        {{ statusAccount }}
      </JazzText>
    </div>
    <div class="row-group">
      <div class="status-client-account">
        <JazzTextGroup
          :text-color="statusClientAccountColor"
          label="Status da conta"
          :text="statusAccountDescription"
        />
      </div>
      <div
        class="status-password"
        v-if="statusAccountDescription != 'Cancelada'"
      >
        <JazzTextGroup
          label="Status da senha"
          :text="
            paramsItem.bloqueadaPorPinIncorreto ? 'Bloqueada' : 'Desbloqueada'
          "
        />
      </div>
      <div class="product">
        <JazzTextGroup label="Produto" :text="paramsItem.produto" />
      </div>
    </div>
    <div class="status-account" v-show="paramsItem.statusCadastroContaId">
      <v-icon :class="`${statusAccountColor}-color`">
        fas fa-circle
      </v-icon>
      <JazzText weight="bold" size="description" :color="statusAccountColor">
        {{ statusAccount }}
      </JazzText>
    </div>
  </div>
</template>

<script>
import globalMethods from "../../../../../mixins/globalMethods";
import JazzText from "../../../../../components/typography/JazzText";
import JazzTextGroup from "../../../../../components/typography/JazzTextGroup";
import statusCadastroClienteEmpresa from "../../../../../dbjson/statusCadastroClienteEmpresa";

export default {
  name: "AccountRow",
  components: { JazzTextGroup, JazzText },
  mixins: [globalMethods],
  props: {
    paramsItem: Object,
  },
  computed: {
    clientName() {
      const { nome } = this.paramsItem;
      if (nome) {
        return nome;
      }
      return "Nome não definido";
    },
    identificationType() {
      const { identificacaoFiscal } = this.paramsItem;
      if (identificacaoFiscal?.length === 11) {
        return `CPF: ${this.cpfMask(identificacaoFiscal)}`;
      }
      return `CNPJ: ${this.cnpjMask(identificacaoFiscal)}`;
    },
    statusClientAccountColor() {
      return this.paramsItem.statusConta !== "Desbloqueada"
        ? "error"
        : "primary";
    },
    statusAccount() {
      return this.findObjectFromArray(
        statusCadastroClienteEmpresa,
        "value",
        this.paramsItem.statusCadastroContaId
      )?.key;
    },
    statusAccountDescription() {
      const status = this.paramsItem.statusConta;
      if (status.includes("BloqueadoCancelamento")) {
        return "Cancelada";
      } else {
        return status;
      }
    },
    statusAccountColor() {
      switch (this.paramsItem.statusCadastroContaId) {
        case 2:
          return "primary";
        case 5:
        case 8:
        case 12:
          return "error";
        default:
          return "black";
      }
    },
  },
};
</script>

<style scoped lang="scss">
.account-row {
  width: 100%;
  padding: 0 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  align-content: stretch;
  @media only screen and (max-width: 1160px) {
    padding: 10px;
  }
}

.account-row:hover {
  background-color: $grayContrast;
  cursor: pointer;
}

.account {
  margin: 15px 0;
  display: flex;
  align-items: center;
  min-width: 140px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  @media only screen and (max-width: 800px) {
    width: 50%;
    justify-content: center;
  }
}

.row-group {
  margin: 0;
  padding: 0;
  flex: 1;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  @media only screen and (max-width: 800px) {
    width: 100%;
    justify-content: space-evenly;
  }
}

.product {
  width: 160px;
  display: block;
}

.account i {
  font-size: 24px;
  color: $blackColor;
  margin-right: 10px;
  width: 30px;
  text-align: center;
}

.status-client-account {
  width: 120px;
  display: block;
}

.status-password {
  width: 120px;
  display: block;
}

.status-account {
  display: flex;
  align-items: center;
  width: 300px;
}

.status-account i {
  font-size: 8px;
  margin-right: 10px;
}

.status-account {
  margin: 15px 0;
  width: 180px;
  text-align: right;
  display: flex;
  justify-content: flex-end;
  @media only screen and (max-width: 800px) {
    display: none;
  }
}

.status-account-mobile {
  display: none;
  margin: 15px 0;
  width: 180px;
  text-align: right;
  justify-content: flex-end;
  @media only screen and (max-width: 800px) {
    display: flex;
    justify-content: flex-start;
    width: 50%;
  }
}

.primary-color {
  color: $successColor;
}

.error-color {
  color: $dangerColor;
}

.black-color {
  color: $blackColor;
}
</style>
