<template>
  <div class="w-100">
    <JazzText weight="bold" size="subtitle" color="primary">
      Como importar novos colaboradores?
    </JazzText>
    <JazzText class="mt-5 mb-5" size="description">
      Veja como cadastrar diversos colaboradores de uma só vez, fazendo uma
      carga em massa, por meio de uma planilha (.csv)
    </JazzText>
    <DefaultTooltip class="mt-5 mb-5" with-slot title="Importante">
      Para cadastrar seus colaboradores de forma massiva, você precisará
      utilizar o <strong>Excel</strong> ou <strong>Google Sheets</strong> para
      ler e editar arquivos no formato <strong>.CSV</strong>, que é o formato
      suportado por nossa plataforma.
    </DefaultTooltip>
    <JazzText weight="bold" size="description">
      Passo 1. Baixe o arquivo modelo
    </JazzText>
    <DefaultButton
      class="mt-5 mb-5"
      data-cy="download-model-button"
      color-style="white-green ultra-radius large"
      text="Baixar arquivo modelo (CSV)"
      @click.native="downloadModel"
    />
    <JazzText size="description">
      Baixe o arquivo modelo e abra em seu editor de preferência: Excel ou
      Google Sheets.
    </JazzText>
  </div>
</template>

<script>
import globalMethods from "../../../../../../mixins/globalMethods";
import JazzText from "../../../../../../components/typography/JazzText";
import DefaultTooltip from "../../../../../../components/tooltip/DefaultTooltip";
import DefaultButton from "../../../../../../components/button/DefaultButton";

export default {
  name: "Step1",
  mixins: [globalMethods],
  components: { JazzText, DefaultTooltip, DefaultButton },
  methods: {
    downloadModel() {
      let csvContent =
        "data:text/csv;charset=utf-8," +
        encodeURI(
          "IdentificacaoFiscal;" +
            "Nome;" +
            "DataNascimento;" +
            "NomeMae;" +
            "DDDMovel;" +
            "TelefoneMovel;" +
            "Email;" +
            "Rua;" +
            "Numero;" +
            "Complemento;" +
            "Bairro;" +
            "CEP;" +
            "Cidade;" +
            "UF\n" +
            "07524084889;" +
            "Pietro Oliver Kevin Gomes;" +
            "05/08/1991;" +
            "Tania Emily;" +
            "015;" +
            "989306526;" +
            "pietrooliverkevingomes_@directnet.com;" +
            "Rua Professor José de Oliveira Barreto 17;" +
            "669;" +
            ";" +
            "Centro;" +
            "18325970;" +
            "Barra do Chapeu;" +
            "SP"
        );
      this.downloadCsvString(csvContent, "modelo_importacao.csv");
      this.$store.dispatch(
        "action_changeMessageSnackBar",
        "Arquivo baixado com sucesso!",
        { module: "template" }
      );
    },
  },
};
</script>

<style lang="scss" scoped></style>
