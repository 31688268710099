var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"invoices-row"},[_c('JazzTextGroup',{staticClass:"first-column",attrs:{"label":"Data de Emissão do boleto","text":_vm.paramsItem.dataEmissao
        ? this.dateFormat(_vm.paramsItem.dataEmissao)
        : '--/--/----'}}),_c('JazzTextGroup',{staticClass:"small",attrs:{"label":"Período do Lote","text":`${
      _vm.paramsItem.dataInicio
        ? this.dateFormat(_vm.paramsItem.dataInicio)
        : '--/--/----'
    } a 
      ${
        _vm.paramsItem.dataFim
          ? this.dateFormat(_vm.paramsItem.dataFim)
          : '--/--/----'
      }`}}),_c('JazzTextGroup',{staticClass:"small",attrs:{"label":"Vencimento","text":_vm.paramsItem.dataVencimento
        ? this.dateFormat(_vm.paramsItem.dataVencimento)
        : '--/--/----'}}),_c('JazzTextGroup',{staticClass:"small",attrs:{"label":"Status","text":_vm.statusText,"textColor":_vm.statusColor}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }