import CustomerBenefitLoad from "../../views/Benefit/Load/CustomerBenefitLoad";

export default [
  {
    path: "/beneficio/carga-massiva",
    name: "CustomerBenefitLoad",
    component: CustomerBenefitLoad,
    meta: {
      permission: ["Pages.Beneficio"],
      permissionName: "BeneficioCargaMassiva",
    },
  },
];
