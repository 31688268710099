<template>
  <div>
    <div class="box-row">
      <div class="row-data">
        <label>
          {{ dataEventoContrato }}
        </label>
      </div>
      <div class="row-data">
        <label> {{ paramsItem.usuario }} </label>
      </div>
      <div class="row-data description">
        <v-icon
          v-if="paramsItem.descricaoHistorico"
          size="25"
          class="mr-2"
          color="red"
          >fas fa-exclamation-circle</v-icon
        >
        <label>
          {{ paramsItem.descricaoHistorico.replace(":", ": ") }}
        </label>
      </div>
    </div>
  </div>
</template>

<script>
import globalMethods from "@/mixins/globalMethods";

export default {
  name: "ContractHistoryRow",
  props: {
    paramsItem: Object,
  },
  mixins: [globalMethods],
  mounted() {
    console.log(this.paramsItem.dataEHora);
  },
  computed: {
    dataEventoContrato() {
      return this.dateFormat(this.paramsItem.dataEHora);
    },
  },
};
</script>

<style lang="scss" scoped>
.box-row {
  width: 100%;
  height: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: center;
  position: relative;
}

.row-data {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  flex: 1;
}
.description {
  margin: 1rem 0 1rem;
}
</style>
