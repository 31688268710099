<template>
  <div class="transaction-row">
    <div class="item-first-column">
      <div class="item-icon">
        <v-icon>
          fas fa-dollar-sign
        </v-icon>
      </div>
      <div class="item-column">
        <JazzTextGroup
          label="Nome do Cliente"
          :text="this.itemTransaction.nomeImpressoCartao || '-'"
        />
      </div>
      <div class="item-column">
        <JazzTextGroup
          label="Cliente Travel"
          :text="this.itemTransaction.nomeCliente || '-'"
        />
      </div>
      <div class="item-column">
        <JazzTextGroup
          label="Valor"
          :text="'R$ ' + this.money2(this.itemTransaction.valor) || '-'"
        />
      </div>
      <div class="item-column">
        <JazzTextGroup
          label="Data e Hora da Transação"
          :text="this.dateTimeFormat(this.itemTransaction.dataTransacao) || '-'"
        />
      </div>
      <div class="item-column">
        <JazzTextGroup
          label="Número do Cartão"
          :text="`**** **** **** ${
            this.itemTransaction.numeroCartaoMascarado
              ? this.itemTransaction.numeroCartaoMascarado.slice(12, 16)
              : '-'
          }`"
        />
      </div>
    </div>
  </div>
</template>

<script>
import globalMethods from "../../../../../mixins/globalMethods";
import JazzTextGroup from "../../../../../components/typography/JazzTextGroup";
export default {
  name: "TravelTransactionRow",
  mixins: [globalMethods],
  props: { itemTransaction: Object },
  components: {
    JazzTextGroup,
  },
};
</script>

<style scoped lang="scss">
.transaction-row {
  width: 100%;
  height: 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  padding: 12px;
  border-bottom: thin solid $opacityGrayColor;
  position: relative;
  margin: 15px 0 5px 0;
}

.transaction-row:hover {
  background: $grayContrast;
  cursor: pointer;
}

.item-first-column {
  display: flex;
  flex: 1;
  justify-content: flex-start;
  align-items: center;
  width: 30%;
}

.item-icon {
  margin-right: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.item-icon i {
  color: $primaryColor;
  font-size: 35px;
}

.item-column {
  width: 30%;
}
</style>
