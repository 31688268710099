<template>
  <div class="recomended-size">
    <div class="size-row">
      <v-icon class="mr-2" color="black" size="25">
        fas fa-exclamation-circle</v-icon
      >
      <JazzText weight="bold" size="subtitle"> Tamanho recomendado: </JazzText>
    </div>
    <div class="size-row mt-5">
      <div class="size-row w-30">
        <v-icon class="mr-2" color="black" size="25">fas fa-arrow-right</v-icon>
        <JazzText weight="bold" size="description"> {{ width }} </JazzText>
      </div>
      <div class="size-row w-30 ml-2">
        <v-icon class="mr-2" color="black" size="25"> fas fa-arrow-up </v-icon>
        <JazzText weight="bold" size="description"> {{ height }} </JazzText>
      </div>
    </div>
  </div>
</template>

<script>
import JazzText from "../typography/JazzText";

export default {
  name: "JazzRecommendedSize",
  components: { JazzText },
  props: {
    height: { type: String, default: "150px" },
    width: { type: String, default: "150px" },
    title: { type: String, default: "Tamanho recomendado:" },
  },
};
</script>

<style lang="scss" scoped>
.recomended-size {
  background-color: $lightGrayBorderColor;
  border-radius: 15px;
  padding: 15px;
  margin-top: 25px;
}
.size-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}
</style>
