<template>
  <div>
    <template v-if="!loading">
      <JazzFormFields
        ref="form"
        :disabled="!hasPendencies"
        v-if="client"
        :fields="fields"
      />
      <div class="container-button" v-if="hasPendencies">
        <div class="editable-button">
          <SearchButton @click.native="updateClient" text="Atualizar Cliente" />
        </div>
      </div>
      <div v-if="hasPendencies" class="px-2 mt-3">
        <ClientStatusBox :status="statusClient" />
      </div>
    </template>
    <JazzSkeletonLoader v-if="!client || loading" type="form" />
  </div>
</template>

<script>
import JazzFormFields from "../../../components/form/JazzFormFields";
import globalMethods from "../../../mixins/globalMethods";
import JazzSkeletonLoader from "../../../components/template/JazzSkeletonLoader";
import ClientStatusBox from "../../../components/ClientStatusBox";
import { ClientesService } from "../../../services/api";
import { ClientesBackofficeService } from "../../../services/apibackoffice";
import SearchButton from "../../../components/button/SearchButton";

export default {
  name: "ClientInformation",
  mixins: [globalMethods],
  components: {
    SearchButton,
    ClientStatusBox,
    JazzSkeletonLoader,
    JazzFormFields,
  },
  props: {
    client: Object,
  },
  data() {
    return {
      fields: {},
      loading: true,
      statusClient: null,
      params: null,
    };
  },
  mounted() {
    if (this.client) {
      this.params = this.$route.params;
      this.createFields();
      this.getStatusClient();
    }
  },
  methods: {
    async updateClient() {
      const { isValid, values } = await this.$refs.form.validateForms();
      if (isValid) {
        this.loading = true;
        const {
          nomeTextField,
          unidadeOrganizacionalSelectField,
          sexoSelectField,
          dataNascimentoTextField,
          nacionalidadeSelectField,
          rendaTextField,
          nomeMaeTextField,
          nomepaiTextField,
        } = values;
        const {
          apelido,
          identificacaoFiscal,
          email,
          endereco,
          rg,
          telefoneFixo,
          telefoneMovel,
          pessoaPoliticamenteExposta,
          nomeEmpresa,
        } = this.client;
        await ClientesService.cadastrarPessoaFisica({
          body: {
            nome: nomeTextField,
            organizationUnitId: unidadeOrganizacionalSelectField,
            sexo: sexoSelectField?.id,
            dataNascimento: this.dateISOFormat(
              dataNascimentoTextField,
              "DD/MM/YYYY"
            ),
            nacionalidade: nacionalidadeSelectField?.id,
            rendaMensal: this.maskInputMoney(
              this.removeMaskCharacters(rendaTextField)
            ),
            nomeMae: nomeMaeTextField,
            nomePai: nomepaiTextField,
            apelido: apelido,
            identificacaoFiscal: identificacaoFiscal,
            email: email,
            endereco: endereco,
            rg: rg,
            telefoneFixo: telefoneFixo,
            telefoneMovel: telefoneMovel,
            pessoaPoliticamenteExposta: pessoaPoliticamenteExposta,
            nomeEmpresa: nomeEmpresa,
          },
        }).finally(() => (this.loading = false));
      }
    },
    async getStatusClient() {
      if (!this.isPjClient) {
        const { chaveDeCliente } = this.$route.params;
        this.statusClient =
          await ClientesBackofficeService.verificarStatusCliente({
            body: {
              chaveDeCliente: chaveDeCliente,
            },
          })
            .then((res) => res?.result)
            .finally(() => (this.loading = false));
      }
      this.loading = false;
    },
    createFields() {
      if (this.isPjClient) {
        this.fields = {
          infoHeader: {
            field: "FormFieldsHeader",
            title: "Informações Básicas",
          },
          nomeTextField: {
            field: "TextField",
            label: "Nome Fantasia",
            cols: 6,
            valueField: this.client?.nomeFantasia,
          },
          razaoTextField: {
            field: "TextField",
            label: "Razão Social",
            cols: 6,
            valueField: this.client?.razaoSocial,
          },
          cnpjTextField: {
            field: "TextField",
            label: "CNPJ",
            cols: 4,
            valueField: this.cnpjMask(this.client?.identificacaoFiscal),
          },
          unidadeOrganizacionalSelectField: {
            field: "TextField",
            label: "Unidade organizacional",
            cols: 4,
            valueField:
              this.client?.organizationUnitDescricao ||
              this.client?.organizationUnitId,
          },
          dataNascimentoTextField: {
            field: "TextFieldMask",
            label: "Data Início de Atividade",
            cols: 4,
            valueField:
              this.client && this.client.dataInicioAtividade
                ? this.dateFormat(this.client.dataInicioAtividade)
                : "",
            maskFormat: "##/##/####",
          },
          cnaeTextField: {
            field: "TextField",
            label: "CNAE",
            cols: 3,
            valueField: this.client?.cnae,
          },
          descricaoCnaeTextField: {
            field: "TextField",
            label: "Descrição CNAE",
            cols: 6,
            valueField: this.client?.cnaeDescricao,
          },
          naturezaTextField: {
            field: "TextField",
            label: "Natureza Jurídica",
            cols: 3,
            valueField: this.client?.naturezaJuridica,
          },
          porteTextField: {
            field: "TextField",
            label: "Porte",
            cols: 3,
            valueField: this.client?.porte,
          },
          grupoTextField: {
            field: "TextField",
            label: "Grupo",
            cols: 3,
            valueField: this.client?.grupo,
          },
          origemTextField: {
            field: "TextField",
            label: "Origem Capital Social",
            cols: 3,
            valueField: this.client?.origemCapitalSocial,
          },
          capitalTextField: {
            field: "TextField",
            label: "Capital Social",
            cols: 3,
            valueField: `R$ ${this.money2(this.client?.capitalSocial)}`,
          },
        };
      } else {
        this.fields = {
          infoHeader: {
            field: "FormFieldsHeader",
            title: "Informações Básicas",
          },
          nomeTextField: {
            field: "TextField",
            label: "Nome Completo",
            cols: 8,
            valueField: this.client?.nome,
            rules: [
              (fields) =>
                !!fields.nomeTextField.valueField || "O nome é obrigatório",
            ],
          },
          unidadeOrganizacionalSelectField: {
            field: "TextField",
            label: "Unidade organizacional",
            cols: 4,
            valueField:
              this.client.organizationUnitDescricao ||
              this.client.organizationUnitId,
            rules: [
              (fields) =>
                !!fields.unidadeOrganizacionalSelectField.valueField ||
                "A Unidade organizacional é obrigatória",
            ],
          },
          sexoSelectField: {
            field: "SelectField",
            label: "Sexo",
            cols: 3,
            items: [
              { id: "", name: "Selecione" },
              { id: "M", name: "Masculino" },
              { id: "F", name: "Feminino" },
            ],
            valueField: this.client
              ? this.findObjectFromArray(
                  [
                    { id: "", name: "Selecione" },
                    { id: "M", name: "Masculino" },
                    { id: "F", name: "Feminino" },
                  ],
                  "id",
                  this.client.sexo
                )
              : "",
            keyName: "name",
            keyValue: "id",
            rules: [
              (fields) =>
                !!fields.sexoSelectField.valueField?.id ||
                "O sexo é obrigatório",
            ],
          },
          dataNascimentoTextField: {
            field: "TextFieldMask",
            label: "Data de nascimento",
            cols: 3,
            valueField:
              this.client && this.client.dataNascimento
                ? this.dateFormat(this.client.dataNascimento)
                : "",
            maskFormat: "##/##/####",
            rules: [
              (fields) =>
                !!fields.dataNascimentoTextField.valueField ||
                "A data de nascimento é obrigatória",
            ],
          },
          nacionalidadeSelectField: {
            field: "SelectField",
            label: "Nacionalidade",
            cols: 3,
            valueField: this.client
              ? this.findObjectFromArray(
                  [
                    { id: "", name: "Selecione" },
                    { id: "N", name: "Brasileiro" },
                    { id: "E", name: "Estrangeiro" },
                  ],
                  "id",
                  this.client.nacionalidade
                )
              : "",
            items: [
              { id: "", name: "Selecione" },
              { id: "N", name: "Brasileiro" },
              { id: "E", name: "Estrangeiro" },
            ],
            keyName: "name",
            keyValue: "id",
            rules: [
              (fields) =>
                !!fields.nacionalidadeSelectField.valueField?.id ||
                "A nacionalidade é obrigatória",
            ],
          },
          rendaTextField: {
            field: "TextField",
            valueField: this.money2(this.client?.rendaMensal || 0),
            label: "Renda mensal",
            maskMoney: true,
            cols: 3,
            rules: [
              (fields) =>
                !!fields.rendaTextField.valueField ||
                "A renda mensal é obrigatória",
            ],
          },
          nomeMaeTextField: {
            field: "TextField",
            label: "Nome da mãe",
            valueField: this.client.nomeMae,
            cols: 6,
            rules: [
              (fields) =>
                !!fields.nomeMaeTextField.valueField ||
                "O nome da mãe é obrigatório",
            ],
          },
          nomepaiTextField: {
            field: "TextField",
            label: "Nome do pai",
            valueField: this.client.nomePai,
            cols: 6,
            rules: [
              (fields) =>
                !!fields.nomepaiTextField.valueField ||
                "O nome do pai é obrigatório",
            ],
          },
        };
      }
    },
  },
  watch: {
    client() {
      this.createFields();
    },
  },
  computed: {
    isPjClient() {
      return this.params?.tipo === "pj";
    },
    hasPendencies() {
      return (
        !this.isPjClient &&
        this.statusClient &&
        this.statusClient.pendencias.length > 0
      );
    },
  },
};
</script>

<style scoped>
.container-button {
  display: flex;
  justify-content: flex-end;
}

.editable-button {
  width: 160px;
}
</style>
