<template>
  <JazzTable
    class="mt-3"
    :pagination="pagination"
    :header="header"
    :rows="parcels"
    :loading="loading"
    :hidePagination="true"
  >
    <template v-slot:reportRow="{ paramsItem }">
      <ContractParcelsRow :paramsItem="paramsItem" />
    </template>
  </JazzTable>
</template>

<script>
import JazzTable from "@/components/table/JazzTable";
import ContractParcelsRow from "@/views/Client/Client/Account/CreditAccount/ContractParcelsRow";

export default {
  components: {
    ContractParcelsRow,
    JazzTable,
  },
  props: {
    parcels: Array,
  },
  data() {
    return {
      loading: false,
      pagination: {
        page: 1,
        perPage: 100,
        total: 0,
        pages: 0,
      },
      header: [
        { title: "Status" },
        { title: "Parcela" },
        { title: "Data de vencimento" },
        { title: "Valor da parcela" },
        { title: "Data de pagamento" },
      ],
    };
  },
};
</script>
