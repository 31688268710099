<template>
  <div>
    <JazzCardHeader
      @back="$router.push({ name: 'TravelClientsIndex', params: params })"
      :groups-text="groupsText"
      v-on="$listeners"
      iconName="fas fa-lg fa-building"
      :loading="loading"
    />

    <v-tabs
      class="mb-5"
      centered
      v-if="client"
      background-color="transparent"
      slider-color="var(--primaryColor)"
    >
      <v-tab
        data-cy="dados-tab"
        :to="{ name: 'TravelClientData', params: params }"
        >Dados</v-tab
      >
      <v-tab
        data-cy="contract-tab"
        :to="{ name: 'TravelClientContract', params: params }"
        >Contrato</v-tab
      >
      <v-tab
        data-cy="configuration-tab"
        :to="{ name: 'TravelClientConfiguration', params: params }"
        >Configurações</v-tab
      >
      <v-tab
        data-cy="cards-tab"
        :to="{ name: 'TravelClientCards', params: params }"
        >Cartões</v-tab
      >
      <v-tab
        data-cy="transactions-tab"
        :to="{ name: 'TravelClientTransactions', params: params }"
        >Transações</v-tab
      >
    </v-tabs>

    <DefaultCard>
      <div class="content-tabs" v-if="client">
        <transition name="fade" mode="out-in">
          <keep-alive>
            <router-view :client="client" />
          </keep-alive>
        </transition>
      </div>
      <JazzSkeletonLoader type="form" v-else />
    </DefaultCard>
  </div>
</template>

<script>
import globalMethods from "../../../mixins/globalMethods";
import DefaultCard from "../../../components/cards/DefaultCard";
import JazzCardHeader from "../../../components/template/JazzCardHeader";
import JazzSkeletonLoader from "../../../components/template/JazzSkeletonLoader";
import { ClientesTravelService } from "../../../services/apitravel";

export default {
  name: "TravelClientDetails",
  mixins: [globalMethods],
  components: {
    DefaultCard,
    JazzCardHeader,
    JazzSkeletonLoader,
  },
  data() {
    return {
      selectedTab: 0,
      client: null,
      groupsText: [],
      params: {},
      loading: false,
    };
  },
  async mounted() {
    this.loading = true;

    this.params = this.$route.params;

    let payload = {
      chaveDeCliente: this.params.chaveDeCliente,
    };

    await ClientesTravelService.obterDados1({
      body: payload,
    })
      .then((response) => {
        this.client = response.result;

        this.groupsText = [
          {
            label: "Cliente",
            text: this.client.nomeFantasia || "-",
          },
          {
            label: "Razão Social",
            text: this.client.razaoSocial || "-",
          },
          {
            label: "Identificação Fiscal",
            text: this.identificacaoFiscal || "-",
          },
          {
            label: "Integrador",
            text: this.params.integrador || "-",
          },
        ];
      })
      .finally(() => {
        this.loading = false;
      });
  },
  computed: {
    identificacaoFiscal() {
      const { identificacaoFiscal } = this.client;
      if (identificacaoFiscal?.length === 14) {
        return this.cnpjMask(identificacaoFiscal);
      }
      return this.cpfMask(identificacaoFiscal);
    },
  },
};
</script>

<style lang="scss" scoped>
.content-tabs {
  padding: 0 15px 15px 15px;
}

.v-tabs {
  width: 100% !important;
  overflow-x: hidden;
}

.v-tab {
  font-size: 14px;
  text-transform: none;
  font-weight: normal;
  color: $blackColor !important;
  letter-spacing: 0;
}

.v-tab.v-tab--active {
  font-size: 14px;
  color: $primaryColor !important;
}
</style>
