<template>
  <div class="w-100">
    <JazzText weight="bold" size="subtitle" color="primary">
      Como importar arquivo de Pré-cadastro?
    </JazzText>
    <JazzText class="mt-5 mb-5" size="description">
      Veja como importar o arquivo para realizar o pré-cadastro, por meio de uma
      planilha (.csv)
    </JazzText>
    <JazzInformationTooltip class="mt-5 mb-5" with-slot title="Importante">
      Para importar o arquivo, você precisará utilizar o
      <strong>Excel</strong> ou <strong>Google Sheets</strong> para ler e editar
      arquivos no formato <strong>.CSV</strong>, que é o formato suportado por
      nossa plataforma.
    </JazzInformationTooltip>
    <JazzText weight="bold" size="description">
      Passo 1. Baixe o arquivo modelo
    </JazzText>
    <DefaultButton
      class="mt-5 mb-5"
      color-style="white-green ultra-radius large"
      text="Baixar arquivo modelo (CSV)"
      @click.native="downloadModel"
    />
    <JazzText size="description">
      Baixe o arquivo modelo e abra em seu editor de preferência: Excel ou
      Google Sheets.
    </JazzText>
  </div>
</template>

<script>
import JazzText from "../../../../../../components/typography/JazzText";
import JazzInformationTooltip from "../../../../../../components/tooltip/JazzInformationTooltip";
import DefaultButton from "../../../../../../components/button/DefaultButton";
import globalMethods from "../../../../../../mixins/globalMethods";

export default {
  name: "Step1",
  mixins: [globalMethods],
  components: { JazzText, JazzInformationTooltip, DefaultButton },
  methods: {
    downloadModel() {
      let csvContent =
        "data:text/csv;charset=utf-8," +
        encodeURI(
          "IdentificacaoFiscal\n" + "12345678900\n" + "987.654.321-00;"
        );
      this.downloadCsvString(csvContent, "modelo_importacao.csv");
      this.$store.dispatch(
        "action_changeMessageSnackBar",
        "Arquivo baixado com sucesso!",
        { module: "template" }
      );
    },
  },
};
</script>
<style scoped lang="scss"></style>
