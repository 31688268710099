<template>
  <div class="information-card">
    <div class="grid-container">
      <JazzTextGroup
        v-for="(group, index) in groupsText"
        :key="index"
        :id="`item${index + 1}`"
        :label="group.label"
        :text="group.text"
        :textColor="group.textColor"
      />
    </div>
    <div class="row-button">
      <DefaultButton
        data-cy="finalize-card-button"
        @click.native="showModal = true"
        text="Finalizar Cartão"
        class="finalize-card-button"
      />
      <ConfirmationModal
        v-if="showModal"
        @close="showModal = false"
        @confirm="checkCardActivation"
        title="Ao finalizar o cartão,caso ainda exista saldo, o mesmo será restituído.Deseja confirmar finalização?"
        cancelText="Cancelar"
        confirm-text="Confirmar"
      />
    </div>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import globalMethods from "../../../../mixins/globalMethods";
import JazzTextGroup from "../../../../components/typography/JazzTextGroup";
import ConfirmationModal from "../../../../components/modal/ConfirmationModal";
import DefaultButton from "../../../../components/button/DefaultButton";
import { PedidosCartaoService } from "../../../../services/apitravel";

export default {
  name: "InformationCard",
  mixins: [globalMethods],
  props: { card: Object },
  components: {
    JazzTextGroup,
    DefaultButton,
    ConfirmationModal,
  },
  data() {
    return {
      showModal: false,
      groupsText: [],
    };
  },
  computed: {
    statusColor() {
      switch (this.card.statusPedido) {
        case "Vigente":
          return "primary";
        case "Fechado":
          return "error";
        default:
          return "black";
      }
    },
    statusName() {
      switch (this.card.statusPedido) {
        case "Vigente":
          return "Ativo";
        case "Fechado":
          return "Cancelado";
        default:
          return this.card.statusPedido;
      }
    },
  },
  mounted() {
    this.params = this.$route.params;
    this.groupsText = [
      {
        label: "Chave de Cliente",
        text: this.card.chaveDeCliente || "-",
      },
      {
        label: "Chave de Pedido",
        text: this.card.chaveDePedidoCartao || "-",
      },
      {
        label: "Início Vigência",
        text: this.dateFormat(this.card.inicioVigencia) || "--/--/----",
      },
      {
        label: "Fim Vigêcia",
        text: this.dateFormat(this.card.fimVigencia) || "--/--/----",
      },
      {
        label: "Status",
        text: this.statusName || "-",
        textColor: this.statusColor,
      },
      {
        label: "Descrição do Pedido",
        text: this.card.descricao || "-",
      },
    ];
  },
  methods: {
    ...mapMutations(["CHANGE_LOADING"]),
    checkCardActivation() {
      this.CHANGE_LOADING(true);
      let payload = {
        chaveDePedidoCartao: this.card.chaveDePedidoCartao,
      };
      PedidosCartaoService.finalizar({
        body: payload,
      })
        .then(() => {
          this.$store.dispatch(
            "action_changeMessageSnackBar",
            `Cartão finalizado!`,
            { module: "template" }
          );
          this.showModal = false;
          this.CHANGE_LOADING(false);
          this.$router.go();
        })
        .catch(() => {
          this.showModal = false;
          this.CHANGE_LOADING(false);
        });
    },
  },
};
</script>

<style scoped lang="scss">
.information-card {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.row-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  align-content: space-evenly;
}

.finalize-card-button {
  margin-bottom: 21px;
  align-self: flex-end;
  width: 170px;
}

.grid-container {
  width: 100%;
  display: grid;
  grid-template-columns: (4, 1fr);
  gap: 10px;
  margin: 0 40px 0 0;
}

#item1 {
  grid-row: 1;
  word-break: break-all;
  grid-column: 1/3;
}

#item2 {
  grid-row: 1;
  word-break: break-all;
  grid-column: 3/5;
}
#item3 {
  grid-row: 2;
  grid-column: 1/3;
}

#item4 {
  grid-row: 2;
  grid-column: 3/5;
}

#item5 {
  grid-row: 3;
  grid-column: 1/3;
}

#item6 {
  grid-row: 3;
  grid-column: 3/5;
  word-break: break-all;
}

@media screen and (min-width: 800px) {
  .information-card {
    padding-top: 0;
    padding-left: 20px;
  }
  .row-button {
    flex-direction: row;
  }

  #item1 {
    grid-row: 1;
    grid-column: 1/3;
  }

  #item2 {
    grid-row: 1;
    grid-column: 3/5;
  }

  #item3 {
    grid-row: 2;
    grid-column: 1/3;
  }

  #item4 {
    grid-row: 2;
    grid-column: 3/5;
  }

  #item5 {
    grid-row: 3;
    grid-column: 1/3;
  }

  #item6 {
    grid-row: 3;
    grid-column: 3/5;
  }
}
@media screen and (min-width: 1380px) {
  .information-card {
    height: 100%;
    max-width: 110%;
    max-height: 110%;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }

  .row-button {
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
  }

  #item1 {
    grid-row: 1;
    grid-column: 1/2;
  }
  #item2 {
    grid-row: 1;
    grid-column: 2/3;
  }
  #item3 {
    grid-row: 1;
    grid-column: 3/4;
  }

  #item4 {
    grid-row: 1;
    grid-column: 4/5;
  }

  #item5 {
    grid-row: 2;
    grid-column: 1/2;
  }

  #item6 {
    grid-row: 2;
    grid-column: 2/3;
  }

  .finalize-card-button {
    align-self: flex-start;
    padding: 0;
  }
}
</style>
