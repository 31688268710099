var render = function render(){var _vm=this,_c=_vm._self._c;return _c('h6',{staticClass:"jazz-text",class:[
    _vm.color,
    _vm.weight,
    _vm.textAlign,
    _vm.styleText,
    _vm.textTransform,
    _vm.textDecoration,
    _vm.size,
  ],style:(_vm.styleText)},[_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }