import TravelTransactionsIndex from "../../views/Travel/Transactions/TravelTransactionsIndex";

export default [
  {
    path: "/travel/transacoes",
    name: "TravelTransactionsIndex",
    component: TravelTransactionsIndex,
    meta: {
      permission: ["Pages.TravelTransacoes"],
      permissionName: "Travel",
    },
  },
];
