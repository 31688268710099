/** Generate by swagger-axios-codegen */
// @ts-nocheck
/* eslint-disable */
// Add default options
export const serviceOptions = {};
// Instance selector
export function axios(configs, resolve, reject) {
    if (serviceOptions.axios) {
        return serviceOptions.axios
            .request(configs)
            .then(res => {
            resolve(res.data);
        })
            .catch(err => {
            reject(err);
        });
    }
    else {
        throw new Error('please inject yourself instance like axios  ');
    }
}
export function getConfigs(method, contentType, url, options) {
    const configs = Object.assign(Object.assign({}, options), { method, url });
    configs.headers = Object.assign(Object.assign({}, options.headers), { 'Content-Type': contentType });
    return configs;
}
export const basePath = '';
export class ListResultDto {
}
export class PagedResultDto {
}
// customer definition
// empty
export class AdquirenciasService {
    /**
     * Realiza a consulta do saldo de adquirência disponível para a conta
     */
    static obterSaldoAdquirencia(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/adquirencia/obter-saldo-adquirencia';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static consultarTipoAdquirencia(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/adquirencia/backoffice/consultar-tipo-adquirencia';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static consultarTipoAdquirenciaProduto(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/adquirencia/backoffice/consultar-tipo-adquirencia-produto';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
}
export class AntecipacaoFgtsService {
    /**
     * Rota para realizar uma Simulação sem Consulta na Caixa Federal
     */
    static simulacao(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/conta/{chaveDeConta}/antecipacao/fgts/simulacao';
            url = url.replace('{chaveDeConta}', params['chaveDeConta'] + '');
            const configs = getConfigs('get', 'application/json', url, options);
            configs.params = { valorDisponivelFgts: params['valorDisponivelFgts'] };
            let data = null;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Rota para realizar uma Simulação com consulta direta na Caixa Federal
     */
    static simulacaoAutorizadora(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/conta/{chaveDeConta}/antecipacao/fgts/simulacao-autorizadora';
            url = url.replace('{chaveDeConta}', params['chaveDeConta'] + '');
            const configs = getConfigs('get', 'application/json', url, options);
            configs.params = { valorSolicitado: params['valorSolicitado'] };
            let data = null;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Rota que realiza a consulta somente por valores minimos e maximos para a contratação
     */
    static valorLiberado(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/conta/{chaveDeConta}/antecipacao/fgts/valor-liberado';
            url = url.replace('{chaveDeConta}', params['chaveDeConta'] + '');
            const configs = getConfigs('get', 'application/json', url, options);
            configs.params = {
                valorSolicitado: params['valorSolicitado'],
                consultaSaldoFgtsCaixa: params['consultaSaldoFgtsCaixa']
            };
            let data = null;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Rota que realiza do termo (CCB) de contratação de Credito FGTS
     */
    static termo(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/conta/{chaveDeConta}/antecipacao/fgts/termo';
            url = url.replace('{chaveDeConta}', params['chaveDeConta'] + '');
            const configs = getConfigs('get', 'application/json', url, options);
            let data = null;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Rota que realiza a devolução do termo assinado (CCB) de contratação de Credito FGTS
     */
    static termo1(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/conta/{chaveDeConta}/antecipacao/fgts/termo/{contratoId}';
            url = url.replace('{chaveDeConta}', params['chaveDeConta'] + '');
            url = url.replace('{contratoId}', params['contratoId'] + '');
            const configs = getConfigs('get', 'application/json', url, options);
            let data = null;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Rota que realiza a consulta de todos os contratos vinculados a uma conta
     */
    static contratos(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/conta/{chaveDeConta}/antecipacao/fgts/contratos';
            url = url.replace('{chaveDeConta}', params['chaveDeConta'] + '');
            const configs = getConfigs('get', 'application/json', url, options);
            configs.params = {
                SituacaoContrato: params['situacaoContrato'],
                DataInicio: params['dataInicio'],
                DataFim: params['dataFim'],
                Ordenacao: params['ordenacao'],
                Pagina: params['pagina'],
                porPagina: params['porPagina'],
                CancelarPropostasPendentes: params['cancelarPropostasPendentes']
            };
            let data = null;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Rota que realiza a consulta pelo historio de um contrato específico
     */
    static contratos1(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/conta/{chaveDeConta}/antecipacao/fgts/contratos/{contratoId}';
            url = url.replace('{chaveDeConta}', params['chaveDeConta'] + '');
            url = url.replace('{contratoId}', params['contratoId'] + '');
            const configs = getConfigs('get', 'application/json', url, options);
            let data = null;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Realiza o Envio/Validação de um Token para assinatura de um contrato (CCB)
     */
    static token(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/conta/{chaveDeConta}/antecipacao/fgts/token';
            url = url.replace('{chaveDeConta}', params['chaveDeConta'] + '');
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Realiza a operação que cria uma proposta de contratação para um antecipação FGTS
     */
    static proposta(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/conta/{chaveDeConta}/antecipacao/fgts/proposta';
            url = url.replace('{chaveDeConta}', params['chaveDeConta'] + '');
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Realiza a efetivação de uma proposta de antecipação FGTS
     */
    static contrato(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/conta/{chaveDeConta}/antecipacao/fgts/proposta/{propostaId}/contrato';
            url = url.replace('{chaveDeConta}', params['chaveDeConta'] + '');
            url = url.replace('{propostaId}', params['propostaId'] + '');
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
}
export class AntecipacaoFgtsParceiroService {
    /**
     * Rota para realizar uma simulação consultando a Caixa Econômica Federal
     */
    static simulacaoAutorizada(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/antecipacao/fgts/simulacao-autorizada';
            const configs = getConfigs('get', 'application/json', url, options);
            configs.params = {
                Cpf: params['cpf'],
                DataNascimento: params['dataNascimento'],
                ValorSolicitado: params['valorSolicitado']
            };
            let data = null;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Rota para recuperar uma simulação por ID
     */
    static simulacaoAutorizada1(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/antecipacao/fgts/simulacao-autorizada/{id}';
            url = url.replace('{id}', params['id'] + '');
            const configs = getConfigs('get', 'application/json', url, options);
            let data = null;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Rota para realizar a consulta do pré-cadastro de proposta de antecipação do saldo do FGTS
     */
    static preCadastroProposta(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/antecipacao/fgts/pre-cadastro-proposta/{id}';
            url = url.replace('{id}', params['id'] + '');
            const configs = getConfigs('get', 'application/json', url, options);
            let data = null;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Rota para enviar o token de confirmação para o cliente que possui proposta pendente de efetivação ou contratos ativos
     */
    static enviarTokenEmail(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/antecipacao/fgts/enviar-token-email';
            const configs = getConfigs('get', 'application/json', url, options);
            configs.params = { email: params['email'] };
            let data = null;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Rota para gerar o termo genérico (CCB) para contratação de credito FGTS
     */
    static termoGenerico(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/antecipacao/fgts/{chaveDeCliente}/termo-generico';
            url = url.replace('{chaveDeCliente}', params['chaveDeCliente'] + '');
            const configs = getConfigs('get', 'application/json', url, options);
            configs.params = { organizationUnitId: params['organizationUnitId'] };
            let data = null;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Rota para consultar os contratos
     */
    static contratos(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/antecipacao/fgts/contratos';
            const configs = getConfigs('get', 'application/json', url, options);
            configs.params = {
                DataInicio: params['dataInicio'],
                DataFim: params['dataFim'],
                Cpf: params['cpf'],
                Status: params['status'],
                Ordenacao: params['ordenacao'],
                ApartirDoItem: params['apartirDoItem'],
                ItensPorPagina: params['itensPorPagina']
            };
            let data = null;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Rota para realizar a busca por um contrato específico por seu identificador gerado pelo parceiro
     */
    static contratos1(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/antecipacao/fgts/contratos/{identificador}';
            url = url.replace('{identificador}', params['identificador'] + '');
            const configs = getConfigs('get', 'application/json', url, options);
            let data = null;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Rota para realizar a consulta dos contratos
     */
    static consultaContratosBackoffice(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/antecipacao/fgts/consulta-contratos-backoffice';
            const configs = getConfigs('get', 'application/json', url, options);
            configs.params = {
                Contrato: params['contrato'],
                Cpf: params['cpf'],
                SituacaoContrato: params['situacaoContrato'],
                DataInicio: params['dataInicio'],
                DataFim: params['dataFim'],
                Pagina: params['pagina'],
                PorPagina: params['porPagina']
            };
            let data = null;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Rota para gerar relatorios da consulta dos contratos
     */
    static gerarRelatoriosContratosBackoffice(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/antecipacao/fgts/gerar-relatorios-contratos-backoffice';
            const configs = getConfigs('get', 'application/json', url, options);
            configs.params = {
                Contrato: params['contrato'],
                Cpf: params['cpf'],
                SituacaoContrato: params['situacaoContrato'],
                DataInicio: params['dataInicio'],
                DataFim: params['dataFim'],
                Pagina: params['pagina'],
                PorPagina: params['porPagina']
            };
            let data = null;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Rota para realizar uma proposta de antecipação de FGTS
     */
    static solicitacaoProposta(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/antecipacao/fgts/solicitacao-proposta';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Rota para completar os dados de uma proposta de antecipação de FGTS
     */
    static completarSolicitacaoProposta(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/antecipacao/fgts/completar-solicitacao-proposta/{id}';
            url = url.replace('{id}', params['id'] + '');
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Rota para cadastrar pessoa fisica
     */
    static cadastrarPessoaFisica(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/antecipacao/fgts/cadastrar-pessoa-fisica';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Enviar Documento
     */
    static enviarDocumentoCliente(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/antecipacao/fgts/enviar-documento-cliente';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Rota para enviar o token de confirmação do cadastro de pessoa física
     */
    static enviarTokenConfirmacaoEmail(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/antecipacao/fgts/enviar-token-confirmacao-email';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Rota para realizar uma proposta de antecipação de FGTS
     */
    static proposta(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/antecipacao/fgts/proposta';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Rota para realizar a efetivação de uma proposta de antecipação de FGTS
     */
    static contrato(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/antecipacao/fgts/efetivacao-proposta/{propostaId}/contrato';
            url = url.replace('{propostaId}', params['propostaId'] + '');
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Realiza o envio de um token para assinatura de um contrato (CCB)
     */
    static reenviarToken(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/antecipacao/fgts/{chaveDeCliente}/reenviar-token';
            url = url.replace('{chaveDeCliente}', params['chaveDeCliente'] + '');
            const configs = getConfigs('post', 'application/json', url, options);
            configs.params = { organizationUnitId: params['organizationUnitId'] };
            let data = null;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
}
export class AntecipacaoSalarialService {
    /**
     * Primeiro Acesso
     */
    static primeiroAcesso(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/antecipacao-salarial/primeiro-acesso';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Solicitar Antecipação Salarial
     */
    static solicitarAntecipacaoSalarial(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/antecipacao-salarial/solicitar-antecipacao-salarial';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Assinar Antecipação Salarial
     */
    static assinarAntecipacaoSalarial(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/antecipacao-salarial/assinar-antecipacao-salarial';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Solicitar Antecipação Parcelada
     */
    static solicitarAntecipacaoParcelada(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/antecipacao-salarial/solicitar-antecipacao-parcelada';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Assinar Antecipação Parcelada
     */
    static assinarAntecipacaoParcelada(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/antecipacao-salarial/assinar-antecipacao-parcelada';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Assinar Segunda Via CCB Antecipação Privada
     */
    static assinarSegundaViaAntecipacaoPrivada(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/antecipacao-salarial/assinar-segunda-via-antecipacao-privada';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Consultar Informações de Renegociação
     */
    static consultarInfoRenegociacao(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/antecipacao-salarial/consultar-info-renegociacao';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Consultar Margem de Antecipação
     */
    static consultarMargemAntecipacao(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/antecipacao-salarial/consultar-margem-antecipacao';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Obter Termo Antecipação Salarial
     */
    static obterTermoAntecipacaoSalarial(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/antecipacao-salarial/obter-termo-antecipacao-salarial';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Obter Termo Antecipação Parcelada
     */
    static obterTermoAntecipacaoParcelada(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/antecipacao-salarial/obter-termo-antecipacao-parcelada';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Obter Termo Assinado Antecipação Salarial
     */
    static obterTermoAssinadoAntecipacaoSalarial(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/antecipacao-salarial/obter-termo-assinado-antecipacao-salarial';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Obter Termo Assinado Antecipação Parcelada
     */
    static obterTermoAssinadoAntecipacaoParcelada(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/antecipacao-salarial/obter-termo-assinado-antecipacao-parcelada';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Calcular Valor da Parcela
     */
    static calcularValorParcela(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/antecipacao-salarial/calcular-valor-parcela';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Cancela as propostas de antecipação salarial pendentes passíveis de serem canceladas.
     */
    static cancelarPropostasPendentes(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/antecipacao-salarial/cancelar-propostas-pendentes';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Consultar Extrato Contrato Cliente.
     */
    static consultarExtratoContratoCliente(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/antecipacao-salarial/consultar-extrato-contrato-cliente';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Consultar Detalhes do Contrato.
     */
    static consultarExtratoContratoClienteDetalhado(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/antecipacao-salarial/consultar-extrato-contrato-cliente-detalhado';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Consultar Margem de Antecipação
     */
    static margens(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/contas/{chaveDeConta}/antecipacoes/salariais/margens';
            url = url.replace('{chaveDeConta}', params['chaveDeConta'] + '');
            const configs = getConfigs('get', 'application/json', url, options);
            configs.params = { prazoMaximoEmMeses: params['prazoMaximoEmMeses'] };
            let data = null;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Solicitar Proposta de Antecipação Salarial
     */
    static propostas(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/contas/{chaveDeConta}/antecipacoes/salariais/propostas';
            url = url.replace('{chaveDeConta}', params['chaveDeConta'] + '');
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Assinar Antecipação Salarial
     */
    static contratos(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/contas/{chaveDeConta}/antecipacoes/salariais/propostas/{propostaId}/contratos';
            url = url.replace('{chaveDeConta}', params['chaveDeConta'] + '');
            url = url.replace('{propostaId}', params['propostaId'] + '');
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Consultar Contratos de um Cliente.
     */
    static contratos1(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/contas/{ChaveDeConta}/antecipacoes/salariais/contratos';
            url = url.replace('{ChaveDeConta}', params['chaveDeConta'] + '');
            const configs = getConfigs('get', 'application/json', url, options);
            configs.params = {
                DataInicial: params['dataInicial'],
                DataFinal: params['dataFinal'],
                StatusContrato: params['statusContrato'],
                Pagina: params['pagina'],
                porPagina: params['porPagina']
            };
            let data = null;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Simular Renegociação
     */
    static simulacoes(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath +
                '/contas/{chaveDeConta}/renegociacoes/antecipacoes/salariais/simulacoes/{valorDesejado}/{prazoEmMesesDesejado}';
            url = url.replace('{chaveDeConta}', params['chaveDeConta'] + '');
            url = url.replace('{valorDesejado}', params['valorDesejado'] + '');
            url = url.replace('{prazoEmMesesDesejado}', params['prazoEmMesesDesejado'] + '');
            const configs = getConfigs('get', 'application/json', url, options);
            let data = null;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Solicitar Proposta de Renegociaçao de Contratos
     */
    static propostas1(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/contas/{chaveDeConta}/renegociacoes/antecipacoes/salariais/propostas';
            url = url.replace('{chaveDeConta}', params['chaveDeConta'] + '');
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Assinar Renegociação
     */
    static contratos2(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/contas/{chaveDeConta}/renegociacoes/antecipacoes/salariais/propostas/{propostaId}/contratos';
            url = url.replace('{chaveDeConta}', params['chaveDeConta'] + '');
            url = url.replace('{propostaId}', params['propostaId'] + '');
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Consultar detalhes do contrato de crédito
     */
    static contratos3(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/contas/{chaveDeConta}/antecipacoes/salariais/contratos/{localizador}';
            url = url.replace('{chaveDeConta}', params['chaveDeConta'] + '');
            url = url.replace('{localizador}', params['localizador'] + '');
            const configs = getConfigs('get', 'application/json', url, options);
            let data = null;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Calcular Valor da Parcela
     */
    static simulacoes1(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/contas/{chaveDeConta}/antecipacoes/salariais/simulacoes/{valorSolicitado}/{prazoEmMeses}';
            url = url.replace('{chaveDeConta}', params['chaveDeConta'] + '');
            url = url.replace('{valorSolicitado}', params['valorSolicitado'] + '');
            url = url.replace('{prazoEmMeses}', params['prazoEmMeses'] + '');
            const configs = getConfigs('get', 'application/json', url, options);
            let data = null;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Obter Parâmetros da Unidade Organizacional
     */
    static parametrosUnidadeOrganizacional(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/contas/{chaveDeConta}/antecipacoes/salariais/parametros-unidade-organizacional';
            url = url.replace('{chaveDeConta}', params['chaveDeConta'] + '');
            const configs = getConfigs('get', 'application/json', url, options);
            let data = null;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
}
export class AssinaturasDeEventosService {
    /**
     * Cria uma chamada de Webhook, onde o parceiro recebe as atualizações das informações da aplicação.
     */
    static assinar(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/assinatura-evento/assinar';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Exclui a assinatura do Webhook da base de dados, utilizando o tipoSubscription para identificar qual chamada será descontinuada.
     */
    static encerrarAssinatura(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/assinatura-evento/encerrar-assinatura';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Exclui a assinatura do Webhook da base de dados, utilizando ids para identificar quais chamadas serão descontinuadas.
     */
    static encerrarAssinaturaPorId(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/assinatura-evento/encerrar-assinatura-por-id';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Busca e lista todas as chamadas de Webhook já inseridas na base, utilizando Unidade Organizacional como filtro
     */
    static obterAssinaturasPorUnidadeOrganizacional(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/assinatura-evento/obter-assinaturas-por-unidade-organizacional';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Rota utilizada para alterar a url da chamada de webhook
     */
    static alterarChaveAssinatura(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/assinatura-evento/alterar-chave-assinatura';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Rota utilizada para listar os tipos de evento de webhook
     */
    static listarTiposWebhook(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/assinatura-evento/listar-tipos-webhook';
            const configs = getConfigs('get', 'application/json', url, options);
            configs.params = { ChaveDeIdempotencia: params['chaveDeIdempotencia'], Metadados: params['metadados'] };
            let data = null;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Rota criada para realização de teste com envios de Webhook.
     */
    static enviarMensagemTeste(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/assinatura-evento/enviar-mensagem-teste';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
}
export class AutenticacoesService {
    /**
     * Realiza a autenticação utilizando o e-mail do cliente
     */
    static cliente(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/autenticar/cliente';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Realiza a autenticação utilizando o e-mail do cliente
     */
    static clienteViaUra(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/autenticar/cliente-via-ura';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Rota utilizada para desconectar cliente/usuário logado na API
     */
    static desconectar(options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/autenticar/desconectar';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = null;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Realiza a autenticação utilizando o usuário
     */
    static usuario(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/autenticar/usuario';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Realiza a autenticação do usuário através do PIN da conta
     */
    static usuarioConta(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/autenticar/usuario-conta';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static bloquearDispositivo(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/autenticar/bloquear-dispositivo/{hash}';
            url = url.replace('{hash}', params['hash'] + '');
            const configs = getConfigs('get', 'application/json', url, options);
            let data = null;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static autorizarDispositivo(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/autenticar/autorizar-dispositivo/{hash}';
            url = url.replace('{hash}', params['hash'] + '');
            const configs = getConfigs('get', 'application/json', url, options);
            let data = null;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
}
export class BancosService {
    /**
     * Consultar Banco
     */
    static consultarBancos(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/banco/consultar-bancos';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static atualizarBancos(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/banco/debug/atualizar-bancos';
            const configs = getConfigs('post', 'multipart/form-data', url, options);
            let data = null;
            data = new FormData();
            if (params['files']) {
                if (Object.prototype.toString.call(params['files']) === '[object Array]') {
                    for (const item of params['files']) {
                        data.append('files', item);
                    }
                }
                else {
                    data.append('files', params['files']);
                }
            }
            if (params['senha']) {
                if (Object.prototype.toString.call(params['senha']) === '[object Array]') {
                    for (const item of params['senha']) {
                        data.append('senha', item);
                    }
                }
                else {
                    data.append('senha', params['senha']);
                }
            }
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Verificar Agência
     */
    static verificarAgencia(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/banco/verificar-agencia';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
}
export class BloqueioJudicialService {
    /**
     *
     */
    static consultarOrdensBloqueioJudicial(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/bloqueio-judicial/consultar-ordens-bloqueio-judicial';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static excluirOrdensBloqueioJudicial(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/bloqueio-judicial/debug/excluir-ordens-bloqueio-judicial';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
}
export class CachingService {
    /**
     *
     */
    static listarCaches(options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/cache/debug/listar-caches';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = null;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static limparCache(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/cache/debug/limpar-cache';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static limparTodosCaches(options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/cache/debug/limpar-todos-caches';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = null;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
}
export class CarteiraDigitalService {
    /**
     *
     */
    static criptografarCartaoFormatoElo(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/integrador/criptografar-cartao-formato-elo';
            const configs = getConfigs('post', 'application/json', url, options);
            configs.params = { guidCartao: params['guidCartao'] };
            let data = null;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static provisionings(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/integrador/carteira-digital/provisionings';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static tokens(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/integrador/carteira-digital/tokens';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static tokenNotifications(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/integrador/carteira-digital/token-notifications';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static notifychoosenIdVoption(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/integrador/carteira-digital/notifychoosenIDVoption';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static opaqueCardInfo(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/carteira-digital/opaque-card-info';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static obterTermosECondicoes(options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/integrador/carteira-digital/obter-termos-e-condicoes';
            const configs = getConfigs('get', 'application/json', url, options);
            let data = null;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static obterPoliticasDeUso(options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/integrador/carteira-digital/obter-politicas-de-uso';
            const configs = getConfigs('get', 'application/json', url, options);
            let data = null;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
}
export class CartoesService {
    /**
     * Solicita um novo cartão físico
     */
    static solicitarCartaoFisico(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/cartao/solicitar-cartao-fisico';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Solicita uma segunda via de cartão físico
     */
    static solicitarSegundaViaCartaoFisico(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/cartao/solicitar-segunda-via-cartao-fisico';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Solicita um novo cartão virtual
     */
    static solicitarCartaoVirtual(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/cartao/solicitar-cartao-virtual';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Solicita segunda via cartão virtual
     */
    static solicitarSegundaViaCartaoVirtual(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/cartao/solicitar-segunda-via-cartao-virtual';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Realiza o bloqueio de cartão por extravio
     */
    static bloquearCartaoExtravio(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/cartao/bloquear-cartao-extravio';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Realiza o bloqueio de cartão por pedido do cliente
     */
    static bloquearCartaoPedidoCliente(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/cartao/bloquear-cartao-pedido-cliente';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Realiza o bloqueio de cartão por perda
     */
    static bloquearCartaoPerda(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/cartao/bloquear-cartao-perda';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Realiza o bloqueio de cartão por uso indevido
     */
    static bloquearCartaoUsoIndevido(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/cartao/bloquear-cartao-uso-indevido';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Realiza o bloqueio de cartão por migração
     */
    static bloquearCartaoMigracao(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/cartao/bloquear-cartao-migracao';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Realiza o bloqueio de cartão por roubo
     */
    static bloquearCartaoRoubo(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/cartao/bloquear-cartao-roubo';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Realiza o bloqueio de cartão por danos
     */
    static bloquearCartaoDanificado(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/cartao/bloquear-cartao-danificado';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Realiza o bloqueio de cartão por fraude
     */
    static bloquearCartaoFraude(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/cartao/bloquear-cartao-fraude';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Realiza o desbloqueio de cartão por pedido do cliente
     */
    static desbloquearCartaoPedidoCliente(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/cartao/desbloquear-cartao-pedido-cliente';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Realiza o desbloqueio de cartão por emissão
     */
    static desbloquearCartaoEmissao(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/cartao/desbloquear-cartao-emissao';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Realiza o desbloqueio por emissão de um cartão, atribuindo um PIN novo no cartão com o PIN informado da conta.
     */
    static desbloquearCartaoEmissaoSyncSenhaConta(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/cartao/desbloquear-cartao-emissao-sync-senha-conta';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Realiza o desbloqueio de cartão bloqueado por tentativas de senha inválida
     */
    static desbloquearCartaoPorSenhaIncorreta(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/cartao/desbloquear-cartao-por-senha-incorreta';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Realiza o desbloqueio de cartão sem Pin
     */
    static desbloquearCartaoSemPin(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/cartao/desbloquear-cartao-sem-pin';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Realiza a alteração da senha do cartão, utilizando o Pin do Cartão
     */
    static alterarSenha(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/cartao/alterar-senha';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Realiza a alteração da senha do cartão, utilizando Token
     */
    static alterarSenhaPorToken(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/cartao/alterar-senha-por-token';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Realiza a consulta de cartões utilizando a Chave de Conta
     */
    static consultarCartoesPorChaveConta(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/cartao/consultar-cartoes-por-chave-conta';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Realiza a consulta de cartões das subcontas utilizando a Chave de Cliente e id do portador
     */
    static consultarCartoesDeSubcontas(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/cartao/consultar-cartoes-de-subcontas';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Realiza a consulta das informações do cartão
     */
    static obterCartaoCompletoPorChaveCartao(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/cartao/obter-cartao-completo-por-chave-cartao';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Realiza a consulta de cartões disponíveis para a conta
     */
    static consultarCartoesDisponiveisParaConta(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/cartao/consultar-cartoes-disponiveis-para-conta';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Realiza o cadastro de parâmetro de CVV dinâmico
     */
    static cadastrarParametroCvvDinamico(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/cartao/cadastrar-parametro-cvv-dinamico';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Realiza a alteração de parâmetro de CVV dinâmico
     */
    static alterarParametroCvvDinamico(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/cartao/alterar-parametro-cvv-dinamico';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Realiza a exclusão de parâmetro de CVV dinâmico
     */
    static excluirParametroCvvDinamico(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/cartao/excluir-parametro-cvv-dinamico';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Realiza a consulta dos parâmetros de CVV dinâmico
     */
    static consultarParametroCvvDinamico(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/cartao/consultar-parametro-cvv-dinamico';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static consultarMcc(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/cartao/consultar-mcc';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static obterControleConfiguracao(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/cartao/debug/obter-controle-configuracao';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static alterarConfiguracaoControle(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/cartao/debug/alterar-configuracao-controle';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static criarConfiguracaoControle(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/cartao/debug/criar-configuracao-controle';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Ativa e desativa transações via contactless
     */
    static configurarContactless(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/cartao/configurar-contactless';
            const configs = getConfigs('put', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Consulta os dados de transação contactless
     */
    static obterConfiguracoes(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/cartao/obter-configuracoes';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Altera o valor máximo permitido para efetuar transações via contactless
     */
    static configurarLimiteContactless(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/cartao/configurar-limite-contactless';
            const configs = getConfigs('put', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static cadastrarControleLimite(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/cartao/debug/cadastrar-controle-limite';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static consultarControleLimite(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/cartao/debug/consultar-controle-limite';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static atualizarControleLimite(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/cartao/debug/atualizar-controle-limite';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static ativarControleLimite(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/cartao/debug/ativar-controle-limite';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static desativarControleLimite(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/cartao/debug/desativar-controle-limite';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static listarMotivosSegundaVia(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/cartao/listar-motivos-segunda-via';
            const configs = getConfigs('get', 'application/json', url, options);
            configs.params = { ChaveDeIdempotencia: params['chaveDeIdempotencia'], Metadados: params['metadados'] };
            let data = null;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static listarMotivosSegundaViaVirtual(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/cartao/listar-motivos-segunda-via-virtual';
            const configs = getConfigs('get', 'application/json', url, options);
            configs.params = { ChaveDeIdempotencia: params['chaveDeIdempotencia'], Metadados: params['metadados'] };
            let data = null;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
}
export class CartoesGarantiaService {
    /**
     * Configurações do cartão com garantia
     */
    static configuracao(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/contas/{chaveDeConta}/cartao-garantia/configuracao';
            url = url.replace('{chaveDeConta}', params['chaveDeConta'] + '');
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Solicitar contrato de cartão com garantia
     */
    static contratos(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/contas/{chaveDeConta}/cartao-garantia/contratos';
            url = url.replace('{chaveDeConta}', params['chaveDeConta'] + '');
            const configs = getConfigs('post', 'application/json', url, options);
            let data = null;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Registrar a assinatura do contrato de cartão com garantia
     */
    static assinatura(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/contas/{chaveDeConta}/cartao-garantia/contrato-corrente/assinatura';
            url = url.replace('{chaveDeConta}', params['chaveDeConta'] + '');
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Consultar produto investimento.
     */
    static produtoInvestimento(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/contas/{chaveDeConta}/cartao-garantia/produto-investimento';
            url = url.replace('{chaveDeConta}', params['chaveDeConta'] + '');
            const configs = getConfigs('get', 'application/json', url, options);
            let data = null;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Consultar situação da contratação de cartão com garantia
     */
    static situacao(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/contas/{chaveDeConta}/cartao-garantia/contratacao/situacao';
            url = url.replace('{chaveDeConta}', params['chaveDeConta'] + '');
            const configs = getConfigs('get', 'application/json', url, options);
            let data = null;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Consultar contrato corrente do cartão com garantia
     */
    static contratoCorrente(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/contas/{chaveDeConta}/cartao-garantia/contrato-corrente';
            url = url.replace('{chaveDeConta}', params['chaveDeConta'] + '');
            const configs = getConfigs('get', 'application/json', url, options);
            let data = null;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Adicionar aditivo de investimento no cartão com garantia
     */
    static aditivo(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/contas/{chaveDeConta}/cartao-garantia/contrato-corrente/aditivo';
            url = url.replace('{chaveDeConta}', params['chaveDeConta'] + '');
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Assinar contrato de aditivo de investimento no cartão com garantia
     */
    static assinatura1(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/contas/{chaveDeConta}/cartao-garantia/contrato-corrente/aditivo/assinatura';
            url = url.replace('{chaveDeConta}', params['chaveDeConta'] + '');
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Consultar lista de contratos do cartão com garantia
     */
    static investimentos(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/contas/{chaveDeConta}/cartao-garantia/investimentos';
            url = url.replace('{chaveDeConta}', params['chaveDeConta'] + '');
            const configs = getConfigs('get', 'application/json', url, options);
            let data = null;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Consultar extrato do cartão com garantia
     */
    static extrato(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/contas/{chaveDeConta}/cartao-garantia/extrato';
            url = url.replace('{chaveDeConta}', params['chaveDeConta'] + '');
            const configs = getConfigs('get', 'application/json', url, options);
            configs.params = {
                dataInicio: params['dataInicio'],
                dataFim: params['dataFim'],
                tipoOrdenacao: params['tipoOrdenacao'],
                pagina: params['pagina'],
                porPagina: params['porPagina']
            };
            let data = null;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Consulta informações de investimentos e créditos referentes a garantia do investimento
     */
    static posicao(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/contas/{chaveDeConta}/cartao-garantia/posicao';
            url = url.replace('{chaveDeConta}', params['chaveDeConta'] + '');
            const configs = getConfigs('get', 'application/json', url, options);
            let data = null;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Consultar lista de faturas do cartão com garantia
     */
    static faturas(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/contas/{chaveDeConta}/cartao-garantia/faturas';
            url = url.replace('{chaveDeConta}', params['chaveDeConta'] + '');
            const configs = getConfigs('get', 'application/json', url, options);
            configs.params = { inicio: params['inicio'], fim: params['fim'] };
            let data = null;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Consultar informações da fatura atual do cartão com garantia
     */
    static informacoesUso(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/contas/{chaveDeConta}/cartao-garantia/informacoes-uso';
            url = url.replace('{chaveDeConta}', params['chaveDeConta'] + '');
            const configs = getConfigs('get', 'application/json', url, options);
            let data = null;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Configurar a data de vencimento da fatura do cartão com garantia
     */
    static dataVencimento(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/contas/{chaveDeConta}/cartao-garantia/data-vencimento';
            url = url.replace('{chaveDeConta}', params['chaveDeConta'] + '');
            const configs = getConfigs('put', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Solicita o resgate da garantia do investimento
     */
    static solicitarResgate(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/contas/{chaveDeConta}/cartao-garantia/solicitar-resgate';
            url = url.replace('{chaveDeConta}', params['chaveDeConta'] + '');
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Simula o pagamento da fatura atual do cartão com garantia
     */
    static simulacaoPagamento(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/contas/{chaveDeConta}/cartao-garantia/faturas/{idFatura}/simulacao-pagamento';
            url = url.replace('{chaveDeConta}', params['chaveDeConta'] + '');
            url = url.replace('{idFatura}', params['idFatura'] + '');
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Simular resgate do investimento
     */
    static simulacaoResgate(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/contas/{chaveDeConta}/cartao-garantia/simulacao-resgate';
            url = url.replace('{chaveDeConta}', params['chaveDeConta'] + '');
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Simular pagamento parcelado da fatura
     */
    static simulacaoPagamentoParcelado(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/contas/{chaveDeConta}/cartao-garantia/simulacao-pagamento-parcelado';
            url = url.replace('{chaveDeConta}', params['chaveDeConta'] + '');
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Simular pagamento parcelado da fatura
     */
    static debitoAutomatico(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/contas/{chaveDeConta}/cartao-garantia/debito-automatico';
            url = url.replace('{chaveDeConta}', params['chaveDeConta'] + '');
            const configs = getConfigs('put', 'application/json', url, options);
            let data = null;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Simular pagamento parcelado da fatura
     */
    static boleto(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/contas/{chaveDeConta}/cartao-garantia/faturas/{idFatura}/boleto';
            url = url.replace('{chaveDeConta}', params['chaveDeConta'] + '');
            url = url.replace('{idFatura}', params['idFatura'] + '');
            const configs = getConfigs('post', 'application/json', url, options);
            let data = null;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Simular pagamento parcelado da fatura
     */
    static qrCodePix(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/contas/{chaveDeConta}/cartao-garantia/faturas/{idFatura}/qr-code-pix';
            url = url.replace('{chaveDeConta}', params['chaveDeConta'] + '');
            url = url.replace('{idFatura}', params['idFatura'] + '');
            const configs = getConfigs('post', 'application/json', url, options);
            let data = null;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Pagamento parcelado da fatura cartão com garantia
     */
    static parcelamento(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/contas/{chaveDeConta}/cartao-garantia/faturas/{idFatura}/parcelamento';
            url = url.replace('{chaveDeConta}', params['chaveDeConta'] + '');
            url = url.replace('{idFatura}', params['idFatura'] + '');
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Detalhe da fatura
     */
    static detalhes(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/contas/{chaveDeConta}/cartao-garantia/faturas/{idFatura}/detalhes';
            url = url.replace('{chaveDeConta}', params['chaveDeConta'] + '');
            url = url.replace('{idFatura}', params['idFatura'] + '');
            const configs = getConfigs('get', 'application/json', url, options);
            let data = null;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Pagamento da fatura com débito em conta
     */
    static debitoConta(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/contas/{chaveDeConta}/cartao-garantia/faturas/{idFatura}/debito-conta';
            url = url.replace('{chaveDeConta}', params['chaveDeConta'] + '');
            url = url.replace('{idFatura}', params['idFatura'] + '');
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
}
export class ClientesService {
    /**
     * Rota para Cadastrar Pessoa Fisica
     */
    static cadastrarPessoaFisica(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/cliente/cadastrar-pessoa-fisica';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Enviar Documento
     */
    static enviarDocumento(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/cliente/enviar-documento';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static arquivo(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/cliente/{chaveDeCliente}/arquivo';
            url = url.replace('{chaveDeCliente}', params['chaveDeCliente'] + '');
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Rota para importar clientes
     */
    static importarClientes(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/clientes/backoffice/importar-clientes';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Lista as importações de clientes
     */
    static listarImportacoesClientes(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/clientes/backoffice/listar-importacoes-clientes';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Download do retorno da importações de clientes
     */
    static baixarRetornoImportacao(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/clientes/backoffice/baixar-retorno-importacao';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Importar clientes benefício simplificado
     */
    static importarClientesBeneficioSimplificada(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/clientes/backoffice/importar-clientes-beneficio-simplificada';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Listagem de importações dos Clientes BS
     */
    static listarImportacoesClientesBeneficioSimplificada(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/clientes/backoffice/listar-importacoes-clientes-beneficio-simplificada';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Baixar retorno de importação benefício simplificada
     */
    static baixarRetornoImportacaoBeneficioSimplificada(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/clientes/backoffice/baixar-retorno-importacao-beneficio-simplificada';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Verifica Status Cliente
     */
    static verificarStatusCliente(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/cliente/verificar-status-cliente';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Obter Chave do Cliente por Identificação Fiscal
     */
    static obterChaveClientePorIdentificaoFiscal(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/cliente/obter-chave-cliente-por-identificao-fiscal';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Obter Dados Pessoa Física por chave
     */
    static obterDadosPessoaFisicaPorChave(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/cliente/obter-dados-pessoa-fisica-por-chave';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Obter Termo Genérico
     */
    static obterTermoGenerico(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/cliente/obter-termo-generico';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Obtém o termo genérico de Residência de abertura de conta.
     */
    static obterDeclaracaoDeResidenciaGenerica(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/cliente/obter-declaracao-de-residencia-generica';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Obter Termo Assinado
     */
    static obterTermoAssinado(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/cliente/obter-termo-assinado';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Obter Declaração de residência assinada
     */
    static obterDeclaracaoDeResidenciaAssinada(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/cliente/obter-declaracao-de-residencia-assinada';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Obtém o relatório CAF
     */
    static obterRelatorioCaf(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/cliente/obter-relatorio-caf';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Obter Relatório da CAF Base64
     */
    static obterRelatorioCafBase64(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/cliente/obter-relatorio-caf-base64';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static excluirCliente(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/cliente/debug/excluir-cliente';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static resetarCliente(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/cliente/debug/resetar-cliente';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Atualizar e-mail
     */
    static atualizarEmail(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/cliente/atualizar-email';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Atualizar email por link
     */
    static atualizarEmailPorLink(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/cliente/atualizar-email-por-link';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Cadastrar endereço de correspondência
     */
    static cadastrarEnderecoCorrespondencia(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/cliente/cadastrar-endereco-correspondencia';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Assinar Termo
     */
    static assinarTermo(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/cliente/assinar-termo';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Assinar Declaração de Residência
     */
    static assinarDeclaracaoDeResidencia(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/cliente/assinar-declaracao-de-residencia';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static assinarTermoPrivacidade(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/cliente/assinar-termo-privacidade';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Cadastrar Pessoa Jurídica
     */
    static cadastrarClientePj(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/cliente/cadastrar-cliente-pj';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Realiza a consulta dos Dados cadastrais de um Cliente PJ, utilizando a sua chave de cliente como identificador na chamada
     */
    static obterDadosPessoaJuridicaPorChave(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/cliente/obter-dados-pessoa-juridica-por-chave';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Solicita o envio de documentação de pessoa jurídica.
     */
    static solicitarEnvioDocumentosPj(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/cliente/solicitar-envio-documentos-pj';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static cadastrarLinkEnvioDocumentosClientePj(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/backoffice/cadastrar-link-envio-documentos-cliente-pj';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Atualiza os dados cadastrais de um Cliente PJ.
     */
    static atualizarDadosClientePj(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/cliente/atualizar-dados-cliente-pj';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Cadastrar Sócio Cliente PJ
     */
    static cadastrarSociosClientePj(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/cliente/socio/cadastrar-socios-cliente-pj';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Consultar Quadro Societário
     */
    static consultarQuadroSocietario(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/cliente/socio/consultar-quadro-societario';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Excluir Sócio
     */
    static excluirSocio(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/cliente/socio/excluir-socio';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static migrarClienteUnidadeOrganizacional(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/cliente/backoffice/migrar-cliente-unidade-organizacional';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Lista os clientes cadastrados.
     */
    static listarClientesResumido(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/cliente/listar-clientes-resumido';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Lista os clientes cadastrados.
     */
    static listarClientes(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/cliente/listar-clientes';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static listarClientesPj(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/backoffice/cliente/listar-clientes-pj';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Extrair ORC do documento.
     */
    static extrairOcrDocumento(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/cliente/extrair-ocr-documento';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Extrair ORC do documento.
     */
    static extrairOcrDocumentoTrust(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/cliente/extrair-ocr-documento-trust';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * .
     */
    static facematch(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/cliente/facematch';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * .
     */
    static criarParametroGenerico(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/cliente/criar-parametro-generico';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Obter parâmetro genérico.
     */
    static obterParametroGenerico(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/cliente/obter-parametro-generico';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Consultar endereço de entrega do cliente
     */
    static consultarEnderecoEntrega(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/cliente/consultar-endereco-entrega';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Excluir parâmetro genérico.
     */
    static excluirParametroGenerico(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/cliente/excluir-parametro-generico';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Retorna uma confirmação de email.
     */
    static confirmacaoEmail(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/cliente/confirmacao-email';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static preCadastroParceiro(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/cliente/pre-cadastro-parceiro';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
}
export class CombateFraudeService {
    /**
     * Validar o Facematch nas esteiras da Trust conforme o tipo passado!
     */
    static validarCombateFraude(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/combatefraude/validar-combate-fraude';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Validar o Facematch nas esteiras da Trust conforme o tipo passado!
     */
    static validarCombateFraudeFaceautenticator(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/combatefraude/validar-combate-fraude-faceautenticator';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
}
export class ComprovantesService {
    /**
     * Realiza consulta de um único comprovante
     */
    static obterComprovante(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/comprovante/obter-comprovante';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Realiza a consulta de de vários comprovantes
     */
    static consultarComprovantes(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/comprovante/consultar-comprovantes';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Realiza a consulta de um comprovante usando o Id da Transação
     */
    static consultarComprovantesPorIdTransacao(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/comprovante/consultar-comprovantes-por-id-transacao';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
}
export class ConsignadoPublicoService {
    /**
     * Consultar Convênios Publicos
     */
    static consultarConvenioPublico(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/consignado-publico/consultar-convenio-publico';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static simulacao(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/consignado-publico/fepweb/simulacao';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Simula Callback CAF
     */
    static simulacao1(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/consignado-publico/fepweb/caf/simulacao';
            const configs = getConfigs('post', 'application/json', url, options);
            configs.params = { chaveDeCliente: params['chaveDeCliente'], transacaoId: params['transacaoId'] };
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static processos(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/consignado-publico/fepweb/processos';
            const configs = getConfigs('get', 'application/json', url, options);
            configs.params = { chaveDeCliente: params['chaveDeCliente'] };
            let data = null;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static redis(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/consignado-publico/fepweb/redis';
            const configs = getConfigs('get', 'application/json', url, options);
            configs.params = { chaveRedis: params['chaveRedis'] };
            let data = null;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static redis1(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/consignado-publico/fepweb/redis/{chaveRedis}';
            url = url.replace('{chaveRedis}', params['chaveRedis'] + '');
            const configs = getConfigs('post', 'application/json', url, options);
            let data = null;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
}
export class ContasService {
    /**
     * Realiza o cadastro de uma nova conta
     */
    static cadastrarConta(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/conta/cadastrar-conta';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Realiza o cadastro de uma nova sub-conta
     */
    static cadastrarSubconta(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/conta/cadastrar-subconta';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Alterar o endereço do cliente/conta usando validação por token
     */
    static alterarDadosEndereco(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/conta/alterar-dados-endereco';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Esta rota será descontinuada em 01/08/2022 - Substituir por 'conta/editar-dados-email'
     */
    static alterarDadosEmail(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/conta/alterar-dados-email';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Alterar o email do cliente, ou portador quando a chave passada for subconta, usando validação por token
     */
    static editarDadosEmail(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/conta/editar-dados-email';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Enviar link para atualizar email do cliente/conta/usuário
     */
    static atualizarEmailPorLink(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/conta/atualizar-email-por-link';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Esta rota será descontinuada em 01/08/2022 - Substituir por 'conta/editar-dados-celular'
     */
    static alterarDadosCelular(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/conta/alterar-dados-celular';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Alterar o número de celular do cliente, ou portador quando a chave passada for subconta, usando validação por token
     */
    static editarDadosCelular(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/conta/editar-dados-celular';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Realiza a troca do Pin da conta
     */
    static trocarPinConta(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/conta/trocar-pin-conta';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Realiza a alteração do PIN da conta, alterando também o PIN de TODOS os cartões da conta.
     */
    static trocarPinContaSyncPinCartoes(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/conta/trocar-pin-conta-sync-pin-cartoes';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Altera o pin da conta através do seu Token.
     */
    static alterarPinContaPorToken(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/conta/alterar-pin-conta-por-token';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Realiza a alteração do PIN da conta via autenticação token SMS ou EMAIL, alterando também o PIN de TODOS os cartões da conta.
     */
    static alterarPinContaPorTokenSyncPinCartoes(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/conta/alterar-pin-conta-por-token-sync-pin-cartoes';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Bloquear conta.
     */
    static bloquear(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/conta/bloquear';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Consultar Saldo.
     */
    static consultarSaldo(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/conta/consultar-saldo';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Consultar Extrato Completo.
     */
    static consultarExtratoCompleto(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/conta/consultar-extrato-completo';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Consultar extrato benefício
     */
    static consultarExtratoBeneficio(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/conta/consultar-extrato-beneficio';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Consultar Extrato e seus detalhes.
     */
    static consultarDetalheExtrato(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/conta/consultar-detalhe-extrato';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Obter Dados Conta.
     */
    static obterDadosConta(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/conta/obter-dados-conta';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Consultar Contas.
     */
    static consultarContas(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/conta/consultar-contas';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Consultar Subcontas.
     */
    static consultarSubcontas(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/conta/consultar-subcontas';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Consultar Subcontas por Portador.
     */
    static consultarContasPortador(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/conta/consultar-contas-portador';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Consulta lançamentos futuros.
     */
    static consultarLancamentosFuturos(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/conta/consultar-lancamentos-futuros';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Consulta agendamentos de pagamento de PIX, TED, Boletos e transações no período estipulado.
     */
    static consultarAgendamentos(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/conta/consultar-agendamentos';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Cancelar conta.
     */
    static cancelarConta(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/conta/cancelar-conta';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Realiza a finalização de uma subconta
     */
    static finalizarSubconta(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/conta/finalizar-subconta';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Consultar as últimas três transações.
     */
    static consultarUltimasTresTransacoes(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/conta/consultar-ultimas-tres-transacoes';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Retona os dados de informe de rendimento tal como o base64 que representa os mesmos no formato pdf
     */
    static obterDadosInformeRendimento(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/obter-dados-informe-rendimento';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Realiza migração para um novo produto
     */
    static migracaoDeProdutos(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/conta/migracao-de-produtos';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static retryMigracaoDeProdutos(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/conta/debug/retry-migracao-de-produtos';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Realiza a validação do pin da conta
     */
    static validarPinConta(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/conta/validar-pin-conta';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Realiza a validação do uso do facematch para efetivação das transações
     */
    static necessitaValidacaoTransacionalFacematch(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/conta/{chaveDeConta}/necessita-validacao-transacional-facematch';
            url = url.replace('{chaveDeConta}', params['chaveDeConta'] + '');
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Consultar Serviços Conta.
     */
    static consultarServicosConta(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/conta/consultar-servicos-conta';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
}
export class CreditoCleanService {
    /**
     * Retorna status atual da conta
     */
    static statusConta(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/creditoclean/status-conta';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Analise de crédito
     */
    static analiseDeCredito(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/creditoclean/analise-de-credito';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Configurações do portador
     */
    static configuracoesPortador(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/creditoclean/configuracoes-portador';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Solicitar contrato de cartão de crédito clean.
     */
    static solicitarContrato(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/creditoclean/solicitar-contrato';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Registrar a assinatura do contrato de cartão de crédito clean.
     */
    static assinarContrato(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/creditoclean/assinar-contrato';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Resumo de utilização do cartão de crédito clean.
     */
    static informacoesDeUso(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/creditoclean/informacoes-de-uso';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Listar faturas futuras.
     */
    static listarFaturasFuturas(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/creditoclean/listar-faturas-futuras';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Lista transações realizadas no cartão de crédito.
     */
    static extrato(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/creditoclean/extrato';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Detalhamento de fatura.
     */
    static detalharFatura(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/creditoclean/detalhar-fatura';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Detalhamento de fatura futura.
     */
    static detalharFaturaFutura(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/creditoclean/detalhar-fatura-futura';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Listar faturas
     */
    static listarFaturas(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/creditoclean/listar-faturas';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Charge Back
     */
    static chargeback(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/creditoclean/chargeback';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Detalhamento do Charge Back
     */
    static detalhesChargeback(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/creditoclean/detalhes-chargeback';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Detalhamento do Charge Back
     */
    static simularPagamentoParcelado(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/creditoclean/simular-pagamento-parcelado';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Pagamento
     */
    static pagamento(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/creditoclean/pagamento';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Simular paragemntos
     */
    static simularPagamento(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/creditoclean/simular-pagamento';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
}
export class DepositosService {
    /**
     * Solicitar Boleto Para Depósito
     */
    static solicitarBoletoParaDeposito(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/deposito/solicitar-boleto-para-deposito';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Consultar Boleto
     */
    static consultarBoleto(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/deposito/consultar-boleto';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Consulta Boletos de Depósito por Data Emissão
     */
    static consultarBoletosDeDepositoPorDataEmissao(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/deposito/consultar-boletos-de-deposito-por-data-emissao';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Consultar Boletos de Depósito por Data Validade
     */
    static consultarBoletosDeDepositoPorDataValidade(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/deposito/consultar-boletos-de-deposito-por-data-validade';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Cancelar Boletos de Recarga Conta
     */
    static cancelarBoletoDeDeposito(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/deposito/cancelar-boleto-de-deposito';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Solicitar depósito ATM
     */
    static solicitarDepositoAtm(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/deposito/solicitar-deposito-atm';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
}
export class DimoQrCodeService {
    /**
     * Rota para validação do QR Code Dimo
     */
    static validar(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/dimoqrcode/validar';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Rota para efetuar o pagamento do QR Code Dimo
     */
    static efetuarPagamento(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/dimoqrcode/efetuar-pagamento';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
}
export class DispositivosService {
    /**
     * Consultar Dispositivos
     */
    static consultarDispositivos(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/dispositivo/consultar-dispositivos';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Excluir Dispositivo
     */
    static excluirDispositivo(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/dispositivo/excluir-dispositivo';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Bloquear Dispositivo
     */
    static bloquearDispositivo(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/dispositivos/bloquear-dispositivo';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Atualizar Dispositivo
     */
    static atualizarDispositivo(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/dispositivos/atualizar-dispositivo';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Configurar uso OTPT
     */
    static configurarUsoOtpt(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/dispositivos/configurar-uso-otpt';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Configurar Notificações Push
     */
    static configurarNotificacoesPush(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/dispositivos/configurar-notificacoes-push';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
}
export class FavoritosService {
    /**
     * Cadastra um contato favorito
     */
    static cadastrarFavorito(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/favorito/cadastrar-favorito';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Atualizar Favorito
     */
    static atualizarFavorito(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/favorito/atualizar-favorito';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Consultar Favoritos
     */
    static consultarFavoritos(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/favorito/consultar-favoritos';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Consultar Favoritos - Exibir Chaves
     */
    static consultarChavesFavorito(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/favorito/consultar-chaves-favorito';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Excluir uma chave do contato favorito
     */
    static excluirChaveFavorito(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/favorito/excluir-chave-favorito';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Excluir Favorito e todas as chaves
     */
    static excluirContatoFavorito(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/favorito/excluir-contato-favorito';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
}
export class FeatureFlagsService {
    /**
     *
     */
    static listarFeatureFlags(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/feature-flags/backoffice/listar-feature-flags';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static atualizarFeatureFlag(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/feature-flags/backoffice/atualizar-feature-flag';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
}
export class FinanciamentoMobileService {
    /**
     * Realiza simulacao de valores para um financiamento
     */
    static simulacao(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/financiamento/mobile/simulacao';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Realiza a análise financeira do cliente para avaliar a liberação de crédito.
     */
    static analiseCredito(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/financiamento/mobile/analise-credito';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static proposta(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/financiamento/mobile/proposta';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static contrato(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/financiamento/mobile/proposta/{idProposta}/contrato';
            url = url.replace('{idProposta}', params['idProposta'] + '');
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Cria uma proposta de financiamento
     */
    static propostas(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/financiamento/mobile/propostas';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Assina um contrato de financiamento
     */
    static contratos(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/financiamento/mobile/contratos/{codigoContrato}';
            url = url.replace('{codigoContrato}', params['codigoContrato'] + '');
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Vincula o aparelho ao contrato de financiamento
     */
    static vincularAparelho(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/financiamento/mobile/contratos/{codigoContrato}/vincular-aparelho';
            url = url.replace('{codigoContrato}', params['codigoContrato'] + '');
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static aparelho(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/financiamento/mobile/contrato/{idContrato}/aparelho';
            url = url.replace('{idContrato}', params['idContrato'] + '');
            const configs = getConfigs('put', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static proposta1(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/financiamento/mobile/proposta/{idProposta}';
            url = url.replace('{idProposta}', params['idProposta'] + '');
            const configs = getConfigs('delete', 'application/json', url, options);
            let data = null;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static lojas(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/financiamento/mobile/lojas';
            const configs = getConfigs('get', 'application/json', url, options);
            configs.params = {
                Cnpj: params['cnpj'],
                Apelido: params['apelido'],
                Cidade: params['cidade'],
                Pagina: params['pagina'],
                porPagina: params['porPagina']
            };
            let data = null;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static lojas1(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/financiamento/mobile/lojas';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static lojas2(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/financiamento/mobile/lojas/{idLoja}';
            url = url.replace('{idLoja}', params['idLoja'] + '');
            const configs = getConfigs('get', 'application/json', url, options);
            let data = null;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static lojas3(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/financiamento/mobile/lojas/{idLoja}';
            url = url.replace('{idLoja}', params['idLoja'] + '');
            const configs = getConfigs('put', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static contratos1(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/financiamento/mobile/contas/{chaveDeConta}/contratos';
            url = url.replace('{chaveDeConta}', params['chaveDeConta'] + '');
            const configs = getConfigs('get', 'application/json', url, options);
            let data = null;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static contratos2(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/financiamento/mobile/contas/{chaveDeConta}/contratos/{idContrato}';
            url = url.replace('{chaveDeConta}', params['chaveDeConta'] + '');
            url = url.replace('{idContrato}', params['idContrato'] + '');
            const configs = getConfigs('get', 'application/json', url, options);
            let data = null;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static parcelas(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/financiamento/mobile/contas/{chaveDeConta}/contratos/{idContrato}/parcelas';
            url = url.replace('{chaveDeConta}', params['chaveDeConta'] + '');
            url = url.replace('{idContrato}', params['idContrato'] + '');
            const configs = getConfigs('get', 'application/json', url, options);
            let data = null;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static propostas1(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/financiamento/mobile/clientes/{chaveDeCliente}/propostas';
            url = url.replace('{chaveDeCliente}', params['chaveDeCliente'] + '');
            const configs = getConfigs('get', 'application/json', url, options);
            configs.params = { idProposta: params['idProposta'] };
            let data = null;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static ccb(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/financiamento/mobile/cliente/{chaveDeCliente}/ccb';
            url = url.replace('{chaveDeCliente}', params['chaveDeCliente'] + '');
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static ccb1(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/financiamento/mobile/cliente/{chaveDeCliente}/ccb';
            url = url.replace('{chaveDeCliente}', params['chaveDeCliente'] + '');
            const configs = getConfigs('get', 'application/json', url, options);
            configs.params = { organizationUnitId: params['organizationUnitId'] };
            let data = null;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
}
export class IntegradoresService {
    /**
     *
     */
    static confirmacao(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/api/webhook/saque-atm/confirmacao';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static confirmacao1(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/integrador/deposito-atm/confirmacao';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static notificacaoCaf(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/api/webhook/notificacao-caf';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static notificacaoTrust(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/api/webhook/notificacao-trust';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static notificacaoLinkOnboardingCaf(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/integrador/notificacao-link-onboarding-caf';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static notificacaoLinkCartaoCreditoCleanTrust(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/integrador/notificacao-link-cartao-credito-clean-trust';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static notificacaoLinkOnboardingTrust(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/integrador/notificacao-link-onboarding-trust';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static bloqueioJudicialNotificacao(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/integrador/bloqueio-judicial-notificacao';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static atualizarStatusBoleto(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/integrador/atualizar-status-boleto';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static confirmarProvisionamentoRecursoFinanciamento(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/integrador/confirmar-provisionamento-recurso-financiamento';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static transactions(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/integrador/callbacks/transactions/{transactionId}';
            url = url.replace('{transactionId}', params['transactionId'] + '');
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static notificarCompraCartao(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/integrador/notificar-compra-cartao';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static notificarPagamentoCartao(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/integrador/notificar-pagamento-cartao';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static consultarRelacionamentoPorConductorAjusteType(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/integrador/debug/consultar-relacionamento-por-conductor-ajuste-type';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static consultarRelacionamentoPorConductorOperacaoType(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/integrador/debug/consultar-relacionamento-por-conductor-operacao-type';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static obterDadosContaPorIdContaDigital(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/integrador/debug/obter-dados-conta-por-id-conta-digital';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static notificarAvisoFechamentoFatura(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/integrador/controle-credito-bancario/notificar-aviso-fechamento-fatura';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static notificarFechamentoFatura(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/integrador/controle-credito-bancario/notificar-fechamento-fatura';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static gerarBoletoFatura(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/integrador/controle-credito-bancario/gerar-boleto-fatura';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static enviarGed(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/integrador/enviar-GED';
            const configs = getConfigs('post', 'application/json', url, options);
            configs.params = { chave: params['chave'] };
            let data = null;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static reembolsarPagamentoQrcode(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/integrador/reembolsar-pagamento-qrcode';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static notificacaoFepWebConsignadoPublico(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/api/webhook/notificacao-fep-web-consignado-publico';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
}
export class LimitesTarifasService {
    /**
     * Consultar Tarifa Operação.
     */
    static consultarTarifaOperacao(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/limites-tarifas/consultar-tarifa-operacao';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Consultar tarifas da conta.
     */
    static consultarTarifasConta(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/tarifas/consultar-tarifas-conta';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Consultar Motivos Tarifa.
     */
    static consultarMotivosTarifa(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/tarifas/consultar-motivos-tarifa';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static criarTarifa(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/tarifas/backoffice/criar-tarifa';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static consultarTarifas(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/tarifas/backoffice/consultar-tarifas';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static obterTarifa(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/tarifas/backoffice/obter-tarifa';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static excluirTarifa(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/tarifas/backoffice/excluir-tarifa';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Consultar Bloqueios Conta.
     */
    static consultarBloqueiosConta(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/bloqueios/consultar-bloqueios-conta';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static criarBloqueio(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/bloqueios/backoffice/criar-bloqueio';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static consultarBloqueios(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/bloqueios/backoffice/consultar-bloqueios';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static obterBloqueio(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/bloqueios/backoffice/obter-bloqueio';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Solicitar Alteração Limite Operação
     */
    static solicitarAlteracaoLimiteOperacao(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/limites-cliente/solicitar-alteracao-limite-operacao';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Consultar solicitações pendentes.
     */
    static consultarSolicitacoesPendentes(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/limites-cliente/consultar-solicitacoes-pendentes';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Consulta as últimas três solicitações.
     */
    static consultarUltimasTresSolicitacoes(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/limites-cliente/consultar-ultimas-tres-solicitacoes';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Consultar Histórico de Solicitações.
     */
    static consultarHistoricoSolicitacoes(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/limites-cliente/consultar-historico-solicitacoes';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Consulta o limite do cliente.
     */
    static consultarLimites(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/limites-cliente/consultar-limites';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Altera o limite noturno pix
     */
    static alterarLimiteNoturnoPix(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/limites-cliente/alterar-limite-noturno-pix';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static consultarMotivosTarifas(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/motivos-tarifas/backoffice/consultar-motivos-tarifas';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static cadastrarMotivoTarifa(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/motivos-tarifas/debug/cadastrar-motivo-tarifa';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static excluirMotivoTarifa(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/motivos-tarifas/debug/excluir-motivo-tarifa';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
}
export class MotoSafeService {
    /**
     * Solicitar Bloqueio Device MotoSafe
     */
    static solicitarBloqueioDevice(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/motosafe/solicitar-bloqueio-device';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Solicitar Desbloqueio Device MotoSafe
     */
    static solicitarDesbloqueioDevice(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/motosafe/solicitar-desbloqueio-device';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
}
export class NotificacoesService {
    /**
     * .
     */
    static confirmarNotificacoesVisualizadas(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/notificacoes/confirmar-notificacoes-visualizadas';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * .
     */
    static consultarNotificacoesCliente(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/notificacoes/consultar-notificacoes-cliente';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * -.
     */
    static obterQuantidadeNotificacoesNaoVisualizadas(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/notificacoes/obter-quantidade-notificacoes-nao-visualizadas';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
}
export class NotificacoesKycService {
    /**
     *
     */
    static kycNotificacao(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/integrador/kyc-notificacao';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
}
export class PagamentosService {
    /**
     * Rota utilizada para realizar agendamentos de boletos
     */
    static efetuarAgendamentoBoleto(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/pagamento/efetuar-agendamento-boleto';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Cancelar Agendamento Boleto.
     */
    static cancelarAgendamentoBoleto(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/pagamento/cancelar-agendamento-boleto';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Obter Agendamento Boleto
     */
    static obterAgendamentoBoleto(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/pagamento/obter-agendamento-boleto';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Consultar Agendamento Boleto.
     */
    static consultarAgendamentoBoleto(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/pagamento/consultar-agendamento-boleto';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Alterar Agendamento Boleto.
     */
    static alterarAgendamentoBoleto(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/pagamento/alterar-agendamento-boleto';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Validar Boleto (Novo)
     */
    static validarBoleto(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/pagamento/validar-boleto';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Pagar Boleto (Novo)
     */
    static pagarBoleto(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/pagamento/pagar-boleto';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
}
export class ParametrosOnboardService {
    /**
     *
     */
    static cadastrarParametrosOnboardCliente(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/onboarding/cadastrar-parametros-onboard-cliente';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static alterarParametrosOnboardCliente(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/onboarding/alterar-parametros-onboard-cliente';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static obterParametrosOnboardCliente(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/onboarding/obter-parametros-onboard-cliente';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static excluirParametrosOnboardCliente(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/onboarding/excluir-parametros-onboard-cliente';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Cadastrar cliente na fila de onboarding
     */
    static cadastrarClienteFila(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/onboarding/cadastrar-cliente-fila';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Obter a posição na fila de onboardign
     */
    static obterPosicaoFila(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/onboarding/obter-posicao-fila';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Verificar vagas para contas na Unidade Organizacional
     */
    static verificarPossuiVagas(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/onboarding/verificar-possui-vagas';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
}
export class PixEnderecamentoService {
    /**
     * Rota utilizada para cadastro de chave PIX do tipo Identificação fiscal.
     */
    static cadastrarChaveIdentificacaoFiscal(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/pix/enderecamento/cadastrar-chave-identificacao-fiscal';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Rota utilizada para cadastro de chave PIX do tipo email.
     */
    static cadastrarChaveEmail(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/pix/enderecamento/cadastrar-chave-email';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Rota utilizada para cadastro de chave PIX do tipo celular
     */
    static cadastrarChaveCelular(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/pix/enderecamento/cadastrar-chave-celular';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Rota utilizada para cadastro de chave PIX do tipo EVP
     */
    static cadastrarChaveEvp(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/pix/enderecamento/cadastrar-chave-evp';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Rota utilizada para gerar token para validação de chave PIX
     */
    static gerarTokenValidacao(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/pix/enderecamento/gerar-token-validacao';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Rota utilizada para excluir chaves PIX cadastradas.
     */
    static excluirChave(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/pix/enderecamento/excluir-chave';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Rota utilizada para consultar as chaves PIX cadastradas.
     */
    static consultarChaves(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/pix/enderecamento/consultar-chaves';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Rota utilizada para consultar as chaves PIX cadastradas.
     */
    static consultarDadosPorChave(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/pix/enderecamento/consultar-dados-por-chave';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
}
export class PixOperacoesService {
    /**
     * Enviar ordem de pagamento por chave.
     */
    static enviarOrdemPagamentoPorChave(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/pix/operacao/enviar-ordem-pagamento-por-chave';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Enviar ordem de pagamento agência.
     */
    static enviarOrdemPagamentoPorAgencia(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/pix/operacao/enviar-ordem-pagamento-por-agencia';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Enviar ordem de pagamento por QR Code.
     */
    static enviarOrdemPagamentoPorQrcode(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/pix/operacao/enviar-ordem-pagamento-por-qrcode';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Enviar ordem de devolução
     */
    static enviarOrdemDevolucao(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/pix/operacao/enviar-ordem-devolucao';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Consultar Extrato.
     */
    static consultarExtrato(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/pix/operacao/consultar-extrato';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Busca lista de instituições participantes do pix
     */
    static consultarInstituicoesParticipantes(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/pix/operacao/consultar-instituicoes-participantes';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Busca lista das principais instituições participantes do pix
     */
    static consultarPrincipaisInstituicoesParticipantes(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/pix/operacao/consultar-principais-instituicoes-participantes';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Realiza o agendamento de pix por sua chave
     */
    static efetuarAgendamentoPixChave(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/pix/operacao/efetuar-agendamento-pix-chave';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Efetuar agendamento Pix por agência
     */
    static efetuarAgendamentoPixAgencia(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/pix/operacao/efetuar-agendamento-pix-agencia';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Obtem os agendamentos Pix
     */
    static obterAgendamentoPix(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/pix/operacao/obter-agendamento-pix';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Alterar o agendamento Pix por chave
     */
    static alterarAgendamentoPixChave(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/pix/operacao/alterar-agendamento-pix-chave';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Alterar agendamento pix por agência
     */
    static alterarAgendamentoPixAgencia(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/pix/operacao/alterar-agendamento-pix-agencia';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Cancelar o agendamento de pix por sua chave
     */
    static cancelarAgendamentoPix(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/pix/operacao/cancelar-agendamento-pix';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Consultar agendamento Pix
     */
    static consultarAgendamentoPix(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/pix/operacao/consultar-agendamento-pix';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
}
export class PixQrCodeService {
    /**
     * Gerar novo QR Code - Dinâmico.
     */
    static gerarQrcodeDinamico(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/pix/qrcode/gerar-qrcode-dinamico';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Gerar novo QR Code - Estático
     */
    static gerarQrcodeEstatico(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/pix/qrcode/gerar-qrcode-estatico';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Processar QR Code
     */
    static processarQrcode(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/pix/qrcode/processar-qrcode';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
}
export class PixReivindicacaoService {
    /**
     * Cadastrar Reivindicação por Celular.
     */
    static reivindicarCelular(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/pix/reivindicacao/reivindicar-celular';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Cadastrar Reivindicação por E-mail.
     */
    static reivindicarEmail(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/pix/reivindicacao/reivindicar-email';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Rota Utilizada para cadastrar reividicação Pix por identificação fiscal
     */
    static reivindicarIdentificacaoFiscal(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/pix/reivindicacao/reivindicar-identificacao-fiscal';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Cancelar Reivindicação.
     */
    static cancelar(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/pix/reivindicacao/cancelar';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Confirmar Reivindicação.
     */
    static confirmar(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/pix/reivindicacao/confirmar';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Concluir Reivindicação.
     */
    static concluir(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/pix/reivindicacao/concluir';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Rota utilizada para realizar a consulta de reinvidicações
     */
    static consultarReinvidicacoes(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/pix/reivindicacao/consultar-reinvidicacoes';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
}
export class PortabilidadesSalarialService {
    /**
     * Realiza a solicitação da Portabilidade
     */
    static solicitar(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/portabilidade-salarial/solicitar';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Realiza a consulta da situação atualizada da Portabilidade
     */
    static consultar(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/portabilidade-salarial/consultar';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Lista as portabilidades vinculadas a conta informada
     */
    static listar(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/portabilidade-salarial/listar';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Realiza o cancelamento da portabilidade.
     */
    static cancelar(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/portabilidade-salarial/cancelar';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
}
export class PortadoresService {
    /**
     * Cadastra um novo portador.
     */
    static cadastrarPortador(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/portador/cadastrar-portador';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Atualiza dados do portador.
     */
    static atualizarPortador(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/portador/atualizar-portador';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Lista os portadores de um cliente.
     */
    static listarPortadores(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/portador/listar-portadores';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
}
export class ProdutosService {
    /**
     *
     */
    static criarProduto(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/produto/backoffice/criar-produto';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static alterarProduto(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/produto/backoffice/alterar-produto';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static excluirProduto(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/produto/backoffice/excluir-produto';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static consultarProdutos(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/produto/backoffice/consultar-produtos';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static consultarProdutosProcessadora(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/produto/backoffice/consultar-produtos-processadora';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static obterProduto(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/produto/backoffice/obter-produto';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static vincularServicos(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/produto/backoffice/vincular-servicos';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static atribuirTipoAdquirencia(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/produto/backoffice/atribuir-tipo-adquirencia';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static atribuirParametroDimo(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/produto/backoffice/atribuir-parametro-dimo';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static importarAlteracaoGrupomccCartoes(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/produto/backoffice/importar-alteracao-grupomcc-cartoes';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static listarAlteracoesGrupomccCartoes(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/produto/backoffice/listar-alteracoes-grupomcc-cartoes';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static baixarRetornoAlteracaoGrupomccCartoes(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/produto/backoffice/baixar-retorno-alteracao-grupomcc-cartoes';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static listarLimitesPeriodos(options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/produto/backoffice/listar-limites-periodos';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = null;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Realiza a consulta de todos os produtos disponíveis para uma determinada UO.
     */
    static consultarProdutosDisponiveis(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/produto/consultar-produtos-disponiveis';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Realiza a consulta de todos os produtos disponíveis para um determinado cliente.
     */
    static consultarProdutosDisponiveisCliente(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/produto/consultar-produtos-disponiveis-cliente';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Realiza a consulta de serviços.
     */
    static consultarServicos(options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/produto/consultar-servicos';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = null;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static tipo(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/produto/backoffice/cartao/tipo';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
}
export class PromocoesService {
    /**
     * Obter Código de Indicação
     */
    static obterCodigoIndicacao(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/promocao/obter-codigo-indicacao';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Ativar Código de Indicação
     */
    static ativarCodigoIndicacao(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/promocao/ativar-codigo-indicacao';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
}
export class RecargasService {
    /**
     * Retorna uma lista de provedores de celular disponíveis para o código de área informado.
     */
    static consultarProvedoresCelular(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/recarga/consultar-provedores-celular';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Consulta os provedores de serviço disponíveis para o código de área informado.
     */
    static consultarProvedoresServicos(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/recarga/consultar-provedores-servicos';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Consulta os valores disponíveis para recarga para o provedor e código de área informado.
     */
    static consultarValores(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/recarga/consultar-valores';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Realiza uma recarga de créditos para celular utilizando os dados informados.
     */
    static recarregarCelular(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/recarga/recarregar-celular';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Realiza uma recarga de crédito para o serviço informado.
     */
    static recarregarServico(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/recarga/recarregar-servico';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Realiza o agendamento de uma recarga de celular.
     */
    static efetuarAgendamentoRecargaCelular(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/recarga/efetuar-agendamento-recarga-celular';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Realiza a consulta dos dados de um agendamento de recarga de celular específico por ID
     */
    static obterAgendamentoRecargaCelular(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/recarga/obter-agendamento-recarga-celular';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Realiza a consulta de todos agendamentos de recarga de celular filtrados pela data de agendamento, limitados dentro de um data inicio e fim de tempo e relacionados a uma chave de Conta.
     */
    static listarAgendamentosRecargaCelular(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/recarga/listar-agendamentos-recarga-celular';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Realiza o agendamento de uma recarga de celular.
     */
    static alterarAgendamentoRecargaCelular(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/recarga/alterar-agendamento-recarga-celular';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Cancela agendamentos de recarga de celular de todos os ids especificados vinculados a uma conta
     */
    static cancelarAgendamentosRecargaCelular(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/recarga/cancelar-agendamentos-recarga-celular';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
}
export class RelacaoProdutosUoService {
    /**
     *
     */
    static criarRelacaoProdutoOrganizacao(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/produto/backoffice/criar-relacao-produto-organizacao';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static excluirRelacaoProdutoOrganizacao(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/produto/backoffice/excluir-relacao-produto-organizacao';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static consultarRelacaoProdutoOrganizacao(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/produto/backoffice/consultar-relacao-produto-organizacao';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
}
export class SaquesService {
    /**
     * Realiza a solicitação de um saque em caixa eletrônico com QrCode.
     */
    static solicitarSaqueAtm(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/saque/solicitar-saque-atm';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Consulta ATMs próximos.
     */
    static consultarAtmsProximos(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/saque/consultar-atms-proximos';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
}
export class SegmentosBeneficioService {
    /**
     * Lista os segmentos de benefício cadastrados
     */
    static listar(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/segmento-beneficio/listar';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
}
export class TermosOrganizacoesService {
    /**
     * Obter um termo
     */
    static termos(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/organizacoes/{OrganizacaoId}/termos/{TipoDeTermo}';
            url = url.replace('{OrganizacaoId}', params['organizacaoId'] + '');
            url = url.replace('{TipoDeTermo}', params['tipoDeTermo'] + '');
            const configs = getConfigs('get', 'application/json', url, options);
            configs.params = {
                ExtensaoDoArquivo: params['extensaoDoArquivo'],
                Generico: params['generico'],
                ChaveDeConta: params['chaveDeConta']
            };
            let data = null;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Assinar Termo
     */
    static assinados(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/organizacoes/{OrganizacaoId}/termos/assinados/{tipoDeTermo}';
            url = url.replace('{OrganizacaoId}', params['organizacaoId'] + '');
            url = url.replace('{tipoDeTermo}', params['tipoDeTermo'] + '');
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Obter o link do termo
     */
    static links(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/organizacoes/{OrganizacaoId}/termos/assinados/links/{contratoId}';
            url = url.replace('{OrganizacaoId}', params['organizacaoId'] + '');
            url = url.replace('{contratoId}', params['contratoId'] + '');
            const configs = getConfigs('get', 'application/json', url, options);
            configs.params = { chaveDeConta: params['chaveDeConta'] };
            let data = null;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
}
export class TermosPrivacidadeService {
    /**
     * Realiza o aceite do termo de privacidade
     */
    static aceiteTermoPrivacidade(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/termo-privacidade/aceite-termo-privacidade';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Busca o termo de privacidade
     */
    static obterTermoPrivacidade(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/termo-privacidade/obter-termo-privacidade/{organizationUnitId}';
            url = url.replace('{organizationUnitId}', params['organizationUnitId'] + '');
            const configs = getConfigs('get', 'application/json', url, options);
            let data = null;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
}
export class TesourariasService {
    /**
     * Transferir Saldo de Tesouraria para Conta Cliente
     */
    static transferirSaldoDaTesourariaParaContaCliente(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/tesouraria/transferir-saldo-da-tesouraria-para-conta-cliente';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Transferir Saldo da Conta para Tesouraria
     */
    static transferirSaldoDaContaClienteParaTesouraria(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/tesouraria/transferir-saldo-da-conta-cliente-para-tesouraria';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Transferir Saldo da Conta Tesouraria para Tesouraria
     */
    static transferirSaldoDaContaTesourariaParaTesouraria(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/tesouraria/transferir-saldo-da-conta-tesouraria-para-tesouraria';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Transferir saldo de tesouraria para uma subconta de cliente
     */
    static transferirSaldoDaTesourariaParaContaPortador(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/tesouraria/transferir-saldo-da-tesouraria-para-conta-portador';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static ajusteFinanceiro(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/api/backoffice/conta/ajusteFinanceiro';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static ajustarSaldoContaCorrenteInterno(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/tesouraria/backoffice/ajustar-saldo-conta-corrente-interno';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static aporteDeSaldoTesouraria(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/tesouraria/backoffice/aporte-de-saldo-tesouraria';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static debitoTesouraria(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/tesouraria/backoffice/debito-tesouraria';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static estornarTransacao(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/tesouraria/backoffice/estornar-transacao';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static consultarContaTesouraria(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/tesouraria/backoffice/consultar-conta-tesouraria';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static alterarLimiteContas(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/tesouraria/backoffice/alterar-limite-contas';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Consultar Saldo Tesouraria
     */
    static consultarSaldoTesouraria(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/tesouraria/consultar-saldo-tesouraria';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Consultar Extrato Tesouraria
     */
    static consultarExtratoTesouraria(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/tesouraria/consultar-extrato-tesouraria';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static consultarTipoTransacaoTesouraria(options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/tesouraria/backoffice/consultar-tipo-transacao-tesouraria';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = null;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Carga Massiva
     */
    static cargaMassiva(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/tesouraria/carga-massiva';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Carga Massiva
     */
    static creditoMassivoPorMotivo(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/tesouraria/credito-massivo-por-motivo';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Listar Cargas Massivas
     */
    static listarCargasMassivas(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/tesouraria/listar-cargas-massivas';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Baixar Retorno Carga Massiva
     */
    static baixarRetornoCargaMassiva(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/tesouraria/baixar-retorno-carga-massiva';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Gerar boleto para depósito
     */
    static gerarBoletoDeposito(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/tesouraria/gerar-boleto-deposito';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Consultar boletos gerados para depósito
     */
    static consultarBoletosDeposito(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/tesouraria/consultar-boletos-deposito';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Obtém os dados de um boleto de depósito
     */
    static obterBoletoDeposito(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/tesouraria/obter-boleto-deposito';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
}
export class TokensService {
    /**
     * Realiza envio de token para assinatura de termo.
     */
    static gerar2FaAssinaturaDeTermo(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/token/gerar-2fa-assinatura-de-termo';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Realiza envio de token para assinatura de antecipação salarial
     */
    static gerar2FaAssinaturaDeTermoAntecipacaSalarial(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/token/gerar-2fa-assinatura-de-termo-antecipaca-salarial';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Realiza envio de token para assinatura de renegociação salarial
     */
    static gerar2FaAssinaturaDeTermoAntecipacaoParcelada(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/token/gerar-2fa-assinatura-de-termo-antecipacao-parcelada';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Realiza envio de token para assinatura de financiamento
     */
    static gerar2FaAssinaturaDeTermoFinanciamento(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/token/gerar-2fa-assinatura-de-termo-financiamento';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Realiza envio de token para alteração de pin do Cartão
     */
    static gerar2FaAlteracaoPinCartao(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/token/gerar-2fa-alteracao-pin-cartao';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Realiza envio de token para alteração de pin da Conta
     */
    static gerar2FaAlteracaoPinConta(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/token/gerar-2fa-alteracao-pin-conta';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Realiza envio de token para cancelamento de conta multipla
     */
    static gerar2FaCancelamentoDeContaMultipla(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/token/gerar-2fa-cancelamento-de-conta-multipla';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Realiza envio de token para alteração de e-mail
     */
    static gerar2FaAlteracaoEmail(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/token/gerar-2fa-alteracao-email';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Esta rota será descontinuada em 01/08/2022 - Substituir por 'token/gerar-2fa-edicao-email-conta'
     */
    static gerar2FaAlteracaoEmailConta(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/token/gerar-2fa-alteracao-email-conta';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Realiza envio de token para alteração de e-mail
     */
    static gerar2FaEdicaoEmailConta(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/token/gerar-2fa-edicao-email-conta';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Realiza envio de token para alteração de telefone
     */
    static gerar2FaAlteracaoCelular(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/token/gerar-2fa-alteracao-celular';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Esta rota será descontinuada em 01/08/2022 - Substituir por 'token/gerar-2fa-edicao-celular-conta'
     */
    static gerar2FaAlteracaoCelularConta(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/token/gerar-2fa-alteracao-celular-conta';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Realiza envio de token para alteração de telefone
     */
    static gerar2FaEdicaoCelularConta(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/token/gerar-2fa-edicao-celular-conta';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Realiza envio de token para alteração de endereço
     */
    static gerar2FaAlteracaoEndereco(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/token/gerar-2fa-alteracao-endereco';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Realiza envio de token para alteração de endereço
     */
    static gerar2FaAlteracaoEnderecoConta(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/token/gerar-2fa-alteracao-endereco-conta';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Realiza envio de token
     */
    static tokens(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/clientes/{chaveDeCliente}/tokens/{operacao}';
            url = url.replace('{chaveDeCliente}', params['chaveDeCliente'] + '');
            url = url.replace('{operacao}', params['operacao'] + '');
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Realiza a validação do token
     */
    static tokens1(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/clientes/{chaveDeCliente}/tokens';
            url = url.replace('{chaveDeCliente}', params['chaveDeCliente'] + '');
            const configs = getConfigs('put', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Realiza envio de token para liberação de atendimento
     */
    static gerar2FaAtendimento(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/token/gerar-2fa-atendimento';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Realiza a validação de token para liberação de atendimento
     */
    static validarTokenAtendimento(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/token/validar-token-atendimento';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
}
export class TransferenciasService {
    /**
     * Retorna a chave de conta se for encontrado
     */
    static obterChaveContaPorDadosPessoa(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/transferencias/obter-chave-conta-por-dados-pessoa';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Retorna a lista de contas se for encontrado
     */
    static consultarContasPorIdentificacaoFiscal(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/transferencias/consultar-contas-por-identificacao-fiscal';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Realiza o pagamento de uma TED
     */
    static efetuarTed(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/transferencias/efetuar-ted';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Realiza o pagamento de uma Transferência Interna
     */
    static efetuarTransferenciaInterna(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/transferencias/efetuar-transferencia-interna';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Realiza o pagamento de uma Transferência Interna
     */
    static efetuarTransferenciaEntreContasArbi(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/transferencias/efetuar-transferencia-entre-contas-arbi';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Realiza o pagamento de uma Transferência para a conta pai
     */
    static efetuarTransferenciaParaContaPai(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/transferencias/efetuar-transferencia-para-conta-pai';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Realiza o agendamento de uma TED
     */
    static efetuarAgendamentoTed(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/transferencias/efetuar-agendamento-ted';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Realiza a consulta de um agendamento de TED
     */
    static obterAgendamentoTed(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/transferencias/obter-agendamento-ted';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Realiza a consulta várias agendamento de TED
     */
    static consultarAgendamentosTed(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/transferencias/consultar-agendamentos-ted';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Realiza a alteração de um agendamento de TED
     */
    static alterarAgendamentoTed(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/transferencias/alterar-agendamento-ted';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Realiza a exclusão de um ou mais agendamentos de TED
     */
    static excluirAgendamentoTed(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/transferencias/excluir-agendamento-ted';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Realiza o agendamento de uma Transferência Interna
     */
    static efetuarAgendamentoTransferenciaInterna(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/transferencias/efetuar-agendamento-transferencia-interna';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Realiza o agendamento de uma Transferência Arbi-To-Arbi
     */
    static efetuarAgendamentoTransferenciaEntreContasArbi(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/transferencias/efetuar-agendamento-transferencia-entre-contas-arbi';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Realiza a consulta de um agendamento de Transferência Interna
     */
    static obterAgendamentoTransferenciaInterna(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/transferencias/obter-agendamento-transferencia-interna';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Realiza a consulta várias agendamento de Transferência Interna
     */
    static consultarAgendamentosTransferenciaInterna(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/transferencias/consultar-agendamentos-transferencia-interna';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Realiza a alteração de um agendamento de Transferência Interna
     */
    static alterarAgendamentoTransferenciaInterna(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/transferencias/alterar-agendamento-transferencia-interna';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Realiza a exclusão de um ou mais agendamentos de Transferência Interna
     */
    static cancelarAgendamentoTransferenciaInterna(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/transferencias/cancelar-agendamento-transferencia-interna';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
}
export class UnidadesOrganizacionaisService {
    /**
     * Solicita a criação de uma unidade organizacional.
     */
    static criarUnidade(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/unidades-organizacionais/criar-unidade';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Altera os dados das unidades organizacionais.
     */
    static alterarUnidade(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/unidades-organizacionais/alterar-unidade';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static alterarLimitesDeCredito(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/unidades-organizacionais/backoffice/alterar-limites-de-credito';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Consulta as unidades organizacionais.
     */
    static consultarUnidades(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/unidades-organizacionais/consultar-unidades';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Busca as unidades organizacionais de acordo com os filtros recebidos.
     */
    static consultarUnidadesFiltro(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/unidades-organizacionais/backoffice/consultar-unidades-filtro';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * .
     */
    static consultarUnidadeFilha(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/unidades-organizacionais/consultar-unidade-filha';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Obtêm as unidades organizacionais.
     */
    static obterUnidade(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/unidades-organizacionais/obter-unidade';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Consulta os usuários das unidades organizacionais.
     */
    static consultarUsuarios(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/unidades-organizacionais/consultar-usuarios';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Altera o tremplate dos emails das unidades organizacionais.
     */
    static alterarEmailTemplate(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/unidades-organizacionais/alterar-email-template';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * .
     */
    static obterEmailTemplate(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/unidades-organizacionais/obter-email-template';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Criar card de comunicação.
     */
    static criar(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/unidades-organizacionais/card-comunicacao/criar';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Alterar card de comunicação.
     */
    static alterar(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/unidades-organizacionais/card-comunicacao/alterar';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Consultar cards de comunicação.
     */
    static consultar(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/unidades-organizacionais/card-comunicacao/consultar';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Excluir card de comunicação.
     */
    static excluir(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/unidades-organizacionais/card-comunicacao/excluir';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
}
export class UsuariosService {
    /**
     * Realiza a inserção de um usuário do tipo Cliente, pessoa física, baseado na identificação fiscal do mesmo.
     */
    static criarUsuario(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/usuarios/criar-usuario';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Realiza a inserção de um usuário do tipo Cliente, pessoa jurídica, baseado na identificação fiscal do mesmo.
     */
    static criarUsuarioPj(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/usuarios/criar-usuario-pj';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Realiza o envio de um link que possibilita o reset de senha via e-mail.
     */
    static solicitarAlteracaoSenha(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/usuarios/solicitar-alteracao-senha';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Realiza a alteração da senha do usuário do tipo Cliente, substituindo a senha antiga pela nova senha inserida.
     */
    static alterarSenhaAcessoCliente(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/usuarios/alterar-senha-acesso-cliente';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Solicitação de reset de senha via token.
     */
    static solicitarResetSenhaPorToken(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/usuarios/solicitar-reset-senha-por-token';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Solicitação de reset de senha via token.
     */
    static alterarSenhaUsuarioPorToken(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/usuarios/alterar-senha-usuario-por-token';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Lista todos os usuários, em uma determinada Unidade Organizacional.
     */
    static listarUsuariosPorIdentificacaoFiscal(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/usuarios/listar-usuarios-por-identificacao-fiscal';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Realiza a consulta de todas as tentativas de login realizadas com o ID do usuário logado na sessão.
     */
    static tentativasLogin(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/usuarios/tentativas-login';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Realiza o bloqueio de usuário.
     */
    static bloquear(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/usuarios/bloquear';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
}
export class UtilidadesService {
    /**
     *
     */
    static testarEnvioEmailRetornoCaf(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/utilidades/debug/testar-envio-email-retorno-caf';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static consultarRetornoCafEnviarEmail(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/utilidades/debug/consultar-retorno-caf-enviar-email';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static testarEnvioEmails(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/utilidades/debug/testar-envio-emails';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static assinarTermoNovamente(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/utilidades/debug/assinar-termo-novamente';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static executarRotinaFinalizacaoCadastroUnico(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/utilidades/debug/executar-rotina-finalizacao-cadastro-unico';
            const configs = getConfigs('post', 'application/json', url, options);
            configs.params = { chaveDeCliente: params['chaveDeCliente'] };
            let data = null;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static atualizarCafCliente(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/utilidades/debug/atualizar-caf-cliente';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Realiza a simulação dos retornos da CAF
     */
    static chamarMockCaf(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/utilidades/debug/chamar-mock-caf';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static reenviarGedCliente(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/utilidades/debug/reenviar-ged-cliente';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static atualizarIdempotencias(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/utilidades/debug/atualizar-idempotencias';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static inserirCafMock(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/utilidades/debug/inserir-caf-mock';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static deletarClientesCafNegado(options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/utilidades/debug/deletar-clientes-caf-negado';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = null;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static criarSenhaPciParaTodasAsContas(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/utilidades/debug/criar-senha-pci-para-todas-as-contas';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static iniciarContaManualParaFinanciamento(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/utilidades/debug/iniciar-conta-manual-para-financiamento/{idProposta}';
            url = url.replace('{idProposta}', params['idProposta'] + '');
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static criacaoContaCompleta(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/utilidades/debug/criacao-conta-completa';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static abrirConta(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/utilidades/debug/abrir-conta';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Executar Teste Echo
     */
    static executarTesteEcho(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/utilidades/executar-teste-echo';
            const configs = getConfigs('post', 'application/json', url, options);
            configs.params = { input: params['input'] };
            let data = null;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static headers(options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/utilidades/echo/headers';
            const configs = getConfigs('get', 'application/json', url, options);
            let data = null;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Executar Exception
     */
    static executarException(options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/utilidades/executar-exception';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = null;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Consultar Hora Sistema
     */
    static consultarHoraSistema(options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/utilidades/consultar-hora-sistema';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = null;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Consultar Próximo dia Útil
     */
    static consultarProximoDiaUtil(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/utilidades/consultar-proximo-dia-util';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Consultar CEP
     */
    static consultarCep(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/utilidades/consultar-cep';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Consultar feriados nacionais de um ano específico
     */
    static consultarFeriados(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/utilidades/consultar-feriados/{ano}';
            url = url.replace('{ano}', params['ano'] + '');
            const configs = getConfigs('get', 'application/json', url, options);
            let data = null;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Verifica se a data informada é um feriado nacional
     */
    static consultarFeriados1(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/utilidades/consultar-feriados/{data}';
            url = url.replace('{data}', params['data'] + '');
            const configs = getConfigs('get', 'application/json', url, options);
            let data = null;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Consultar Feriados
     */
    static consultarFeriados2(options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/utilidades/consultar-feriados';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = null;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static verificarExtratos(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/utilidades/debug/verificar-extratos';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static testarDecryptConductor(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/utilidades/debug/testar-decrypt-conductor';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static consultarExtratoCompletoBackend(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/utilidades/debug/consultar-extrato-completo-backend';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static consultarDadosGerais(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/utilidades/debug/consultar-dados-gerais';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static testeExecutarValidacaoEntrada(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/utilidades/teste-executar-validacao-entrada';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static testeExecutarCompletarDadosCaf(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/utilidades/debug/teste-executar-completar-dados-caf';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static resetarDocumentosCliente(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/utilidades/debug/resetar-documentos-cliente';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static gerarLinkOnboarding(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/utilidades/debug/gerar-link-onboarding';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static importarEnderecosCorrespondencia(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/utilidades/debug/importar-enderecos-correspondencia';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static saldoCelcoin(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/utilidades/debug/saldo-celcoin';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static atualizarTemplates(options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/utilidades/debug/notificacoes-service/atualizar-templates';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = null;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static adicionarBodyOrganizacao(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/utilidades/debug/notificacoes-service/adicionar-body-organizacao';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static atualizarTermoParceiro(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/utilidades/debug/atualizar-termo-parceiro';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static debitos(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/utilidades/contas/{chaveDeConta}/debitos';
            url = url.replace('{chaveDeConta}', params['chaveDeConta'] + '');
            const configs = getConfigs('get', 'application/json', url, options);
            let data = null;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static cadastrarConductorTipoAjuste(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/utilidades/debug/cadastrar-conductor-tipo-ajuste';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static confirmar(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/utilidades/debug/celcoin/confirmar';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static estornar(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/utilidades/debug/celcoin/estornar';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static cancelar(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/utilidades/debug/celcoin/cancelar';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static enviarInformeRendimento(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/utilidades/debug/enviar-informe-rendimento';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static cadastrarIcone(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/utilidades/debug/gruposmcc/cadastrar-icone';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static consultarIcone(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/utilidades/debug/gruposmcc/consultar-icone';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static excluirIcone(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/utilidades/debug/gruposmcc/excluir-icone';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static obterAssetsMobile(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/utilidades/debug/obter-assets-mobile';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static assetMobile(options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/utilidades/debug/asset-mobile';
            const configs = getConfigs('get', 'application/json', url, options);
            let data = null;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static preencherCampoUltimos4Digitos(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/utilidades/debug/preencher-campo-ultimos-4-Digitos';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
}
export class UtilidadesJobsService {
    /**
     *
     */
    static executarJobAgendamentosTed(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/utilidades-jobs/debug/executar-job-agendamentos-ted';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static executarJobAgendamentosTransferenciaInterna(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/utilidades-jobs/debug/executar-job-agendamentos-transferencia-interna';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static executarJobAgendamentosPix(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/utilidades-jobs/debug/executar-job-agendamentos-pix';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static executarJobAgendamentosBoleto(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/utilidades-jobs/debug/executar-job-agendamentos-boleto';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static executarJobAgendamentosRecarga(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/utilidades-jobs/debug/executar-job-agendamentos-recarga';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static executarJobProcessarPortabilidades(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/utilidades-jobs/debug/executar-job-processar-portabilidades';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static executarJobDebitosAgendados(options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/utilidades-jobs/debug/executar-job-debitos-agendados';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = null;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static executarJobExpirarContratosCreditoPrivadoPendentes(options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/utilidades-jobs/debug/executar-job-expirar-contratos-credito-privado-pendentes';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = null;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
}
export class ActionResult {
    constructor(data = {}) { }
}
export class AddChaveContaDto {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeConta'] = data['chaveDeConta'];
    }
}
export class AddDefaultOutput {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
    }
}
export class AddInputDto {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
    }
}
export class AddOutputDto {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
    }
}
export class AgendamentosCadastrados {
    constructor(data = {}) {
        this['idAgendamento'] = data['idAgendamento'];
        this['dataAgendamento'] = data['dataAgendamento'];
        this['sequencia'] = data['sequencia'];
    }
}
export class AjaxResponseBase {
    constructor(data = {}) {
        this['targetUrl'] = data['targetUrl'];
        this['success'] = data['success'];
        this['error'] = data['error'];
        this['unAuthorizedRequest'] = data['unAuthorizedRequest'];
        this['__abp'] = data['__abp'];
    }
}
export class AnalyticsResult {
    constructor(data = {}) {
        this['advice'] = data['advice'];
        this['rule'] = data['rule'];
    }
}
export class AparelhoCDto {
    constructor(data = {}) {
        this['imei'] = data['imei'];
        this['versaoMotosafe'] = data['versaoMotosafe'];
        this['sku'] = data['sku'];
        this['ean'] = data['ean'];
        this['codigoXt'] = data['codigoXt'];
        this['modelo'] = data['modelo'];
        this['valorCelular'] = data['valorCelular'];
        this['valorSeguro'] = data['valorSeguro'];
    }
}
export class Arquivo {
    constructor(data = {}) {
        this['base64'] = data['base64'];
        this['extensao'] = data['extensao'];
        this['url'] = data['url'];
    }
}
export class AssinaturasEventosDto {
    constructor(data = {}) {
        this['organizationUnitId'] = data['organizationUnitId'];
        this['tipoSubscriptionDescricao'] = data['tipoSubscriptionDescricao'];
        this['tipoSubscriptionId'] = data['tipoSubscriptionId'];
        this['url'] = data['url'];
        this['id'] = data['id'];
        this['dataAssinatura'] = data['dataAssinatura'];
        this['responsavel'] = data['responsavel'];
    }
}
export class AtribuirTipoDeCartoesServicos {
    constructor(data = {}) {
        this['servicoId'] = data['servicoId'];
        this['servicoDeCartaoId'] = data['servicoDeCartaoId'];
    }
}
export class Autenticacao2FADto {
    constructor(data = {}) {
        this['tipo'] = data['tipo'];
        this['token'] = data['token'];
        this['agente'] = data['agente'];
        this['faceMatch'] = data['faceMatch'];
    }
}
export class Banco {
    constructor(data = {}) {
        this['id'] = data['id'];
        this['ispb'] = data['ispb'];
        this['codigo'] = data['codigo'];
        this['nomeInstituicao'] = data['nomeInstituicao'];
        this['temPix'] = data['temPix'];
        this['principal'] = data['principal'];
        this['urlLogo'] = data['urlLogo'];
    }
}
export class BooleanAjaxResponse {
    constructor(data = {}) {
        this['targetUrl'] = data['targetUrl'];
        this['success'] = data['success'];
        this['error'] = data['error'];
        this['unAuthorizedRequest'] = data['unAuthorizedRequest'];
        this['__abp'] = data['__abp'];
        this['result'] = data['result'];
    }
}
export class CPF {
    constructor(data = {}) {
        this['statusCode'] = data['statusCode'];
        this['registrationStatusCode'] = data['registrationStatusCode'];
        this['registrationStatusMessage'] = data['registrationStatusMessage'];
        this['name'] = data['name'];
        this['birthDate'] = data['birthDate'];
        this['issueDate'] = data['issueDate'];
        this['deathYear'] = data['deathYear'];
        this['deathMessage'] = data['deathMessage'];
    }
}
export class CacheDto {
    constructor(data = {}) {
        this['name'] = data['name'];
    }
}
export class CacheDtoListResultDto {
    constructor(data = {}) {
        this['items'] = data['items'];
    }
}
export class CadastroChavesPixDto {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeConta'] = data['chaveDeConta'];
        this['chaveEnderecamento'] = data['chaveEnderecamento'];
        this['autenticacao2FA'] = data['autenticacao2FA'];
    }
}
export class CalculoParcela {
    constructor(data = {}) {
        this['valorParcela'] = data['valorParcela'];
        this['descricaoParcela'] = data['descricaoParcela'];
        this['parcela'] = data['parcela'];
    }
}
export class CartaoGarantiaAditivoDto {
    constructor(data = {}) {
        this['contractId'] = data['contractId'];
        this['contractDocumentText'] = data['contractDocumentText'];
        this['status'] = data['status'];
        this['result'] = data['result'];
    }
}
export class CartaoGarantiaAssinarAditivoDto {
    constructor(data = {}) {
        this['status'] = data['status'];
        this['result'] = data['result'];
        this['contractId'] = data['contractId'];
    }
}
export class CartaoGarantiaConfiguracaoDto {
    constructor(data = {}) {
        this['status'] = data['status'];
        this['result'] = data['result'];
    }
}
export class CartaoGarantiaConsultarExtratoDto {
    constructor(data = {}) {
        this['status'] = data['status'];
        this['result'] = data['result'];
        this['transacoes'] = data['transacoes'];
    }
}
export class CartaoGarantiaDetalheFaturaDto {
    constructor(data = {}) {
        this['status'] = data['status'];
        this['result'] = data['result'];
        this['statusFatura'] = data['statusFatura'];
        this['dataVencimento'] = data['dataVencimento'];
        this['dataPagamento'] = data['dataPagamento'];
        this['totalNacional'] = data['totalNacional'];
        this['totalInternacional'] = data['totalInternacional'];
        this['cambio'] = data['cambio'];
        this['taxasTotais'] = data['taxasTotais'];
        this['saldoPendente'] = data['saldoPendente'];
        this['valorFatura'] = data['valorFatura'];
        this['creditoAnterior'] = data['creditoAnterior'];
        this['pagamentoMinimo'] = data['pagamentoMinimo'];
        this['items'] = data['items'];
    }
}
export class CartaoGarantiaDetalheParcelaDto {
    constructor(data = {}) {
        this['mes'] = data['mes'];
        this['valor'] = data['valor'];
        this['numeroParcela'] = data['numeroParcela'];
    }
}
export class CartaoGarantiaInformacoesUsoDto {
    constructor(data = {}) {
        this['status'] = data['status'];
        this['result'] = data['result'];
        this['faturaId'] = data['faturaId'];
        this['limiteDisponivel'] = data['limiteDisponivel'];
        this['limiteUtilizado'] = data['limiteUtilizado'];
        this['limiteTotal'] = data['limiteTotal'];
        this['dataVencimento'] = data['dataVencimento'];
        this['statusFatura'] = data['statusFatura'];
        this['valor'] = data['valor'];
    }
}
export class CartaoGarantiaListarFaturaDetalheDto {
    constructor(data = {}) {
        this['faturaId'] = data['faturaId'];
        this['vencimento'] = data['vencimento'];
        this['valor'] = data['valor'];
        this['statusFatura'] = data['statusFatura'];
    }
}
export class CartaoGarantiaListarFaturasDto {
    constructor(data = {}) {
        this['status'] = data['status'];
        this['result'] = data['result'];
        this['items'] = data['items'];
    }
}
export class CartaoGarantiaPagamentoFaturaDebitoContaDto {
    constructor(data = {}) {
        this['status'] = data['status'];
        this['result'] = data['result'];
        this['pagamentoId'] = data['pagamentoId'];
    }
}
export class CartaoGarantiaPagamentoFaturaParceladaDto {
    constructor(data = {}) {
        this['status'] = data['status'];
        this['result'] = data['result'];
        this['parcelamentoId'] = data['parcelamentoId'];
    }
}
export class CartaoGarantiaPosicaoDto {
    constructor(data = {}) {
        this['status'] = data['status'];
        this['result'] = data['result'];
        this['valorInvestido'] = data['valorInvestido'];
        this['rendimentoTotal'] = data['rendimentoTotal'];
        this['saldoDisponivelResgate'] = data['saldoDisponivelResgate'];
        this['limiteCreditoAtual'] = data['limiteCreditoAtual'];
    }
}
export class CartaoGarantiaSimularPagamentoParceladoDto {
    constructor(data = {}) {
        this['status'] = data['status'];
        this['result'] = data['result'];
        this['simulacaoId'] = data['simulacaoId'];
        this['dataVencimentoFatura'] = data['dataVencimentoFatura'];
        this['percentualJurosMes'] = data['percentualJurosMes'];
        this['valorTotalFatura'] = data['valorTotalFatura'];
        this['valorEntrada'] = data['valorEntrada'];
        this['qtdParcelas'] = data['qtdParcelas'];
        this['valorTotalParcelamento'] = data['valorTotalParcelamento'];
        this['valorTotalJuros'] = data['valorTotalJuros'];
        this['parcelasDetalhe'] = data['parcelasDetalhe'];
    }
}
export class CartaoGarantiaTransacoesDto {
    constructor(data = {}) {
        this['transacaoId'] = data['transacaoId'];
        this['dataHoraTransacao'] = data['dataHoraTransacao'];
        this['descricaoAbreviada'] = data['descricaoAbreviada'];
        this['descricaoEstabelecimento'] = data['descricaoEstabelecimento'];
        this['nomeEstabelecimento'] = data['nomeEstabelecimento'];
        this['descricaoTipoTransacao'] = data['descricaoTipoTransacao'];
        this['valorBRL'] = data['valorBRL'];
        this['valorUSD'] = data['valorUSD'];
        this['parcelamento'] = data['parcelamento'];
        this['parcelaNumero'] = data['parcelaNumero'];
    }
}
export class CelularDto {
    constructor(data = {}) {
        this['numeroPais'] = data['numeroPais'];
        this['numeroDDD'] = data['numeroDDD'];
        this['numeroCelular'] = data['numeroCelular'];
    }
}
export class ChosenIDVMethod {
    constructor(data = {}) {
        this['type'] = data['type'];
    }
}
export class CipheredDataFormat {
    constructor(data = {}) {
        this['encoding'] = data['encoding'];
        this['ciphering'] = data['ciphering'];
    }
}
export class Ciphering {
    constructor(data = {}) {
        this['keyReference'] = data['keyReference'];
        this['algo'] = data['algo'];
        this['icv'] = data['icv'];
    }
}
export class ClienteFepWebConsignadoPublico {
    constructor(data = {}) {
        this['nome'] = data['nome'];
        this['identificacaoFiscal'] = data['identificacaoFiscal'];
        this['tipoIdentificacaoFiscal'] = data['tipoIdentificacaoFiscal'];
        this['email'] = data['email'];
    }
}
export class ClienteUoDto {
    constructor(data = {}) {
        this['chaveDeCliente'] = data['chaveDeCliente'];
        this['organizationUnitId'] = data['organizationUnitId'];
        this['organizationUnitDescricao'] = data['organizationUnitDescricao'];
        this['possuiUsuario'] = data['possuiUsuario'];
        this['statusCadastroClienteDescricao'] = data['statusCadastroClienteDescricao'];
        this['statusCadastroClienteId'] = data['statusCadastroClienteId'];
    }
}
export class ComboOutputDto {
    constructor(data = {}) {
        this['id'] = data['id'];
        this['descricao'] = data['descricao'];
    }
}
export class ComprovanteDto {
    constructor(data = {}) {
        this['comprovanteId'] = data['comprovanteId'];
        this['chaveDeConta'] = data['chaveDeConta'];
        this['servicosExecucaoId'] = data['servicosExecucaoId'];
        this['organizationUnitId'] = data['organizationUnitId'];
        this['dataOperacao'] = data['dataOperacao'];
        this['textoComprovante'] = data['textoComprovante'];
        this['autenticacao'] = data['autenticacao'];
        this['tipoOperacao'] = data['tipoOperacao'];
    }
}
export class ConsultaContratosFgtsBackOffice {
    constructor(data = {}) {
        this['cpf'] = data['cpf'];
        this['parceiro'] = data['parceiro'];
        this['nome'] = data['nome'];
        this['valorParcela'] = data['valorParcela'];
        this['tipoContrato'] = data['tipoContrato'];
        this['idContrato'] = data['idContrato'];
        this['valorTotalContrato'] = data['valorTotalContrato'];
        this['qtdParcelas'] = data['qtdParcelas'];
        this['localizador'] = data['localizador'];
        this['financiador'] = data['financiador'];
        this['situacaoContrato'] = data['situacaoContrato'];
        this['dataContrato'] = data['dataContrato'];
        this['valorBloqueadoFGTS'] = data['valorBloqueadoFGTS'];
        this['valorCreditadoConta'] = data['valorCreditadoConta'];
        this['periodoAntecipado'] = data['periodoAntecipado'];
        this['terminoContrato'] = data['terminoContrato'];
        this['taxaJuros'] = data['taxaJuros'];
        this['totalPaginas'] = data['totalPaginas'];
        this['recebivelFgtsParceiro'] = data['recebivelFgtsParceiro'];
    }
}
export class ConsultarInfoContrato {
    constructor(data = {}) {
        this['id'] = data['id'];
        this['localizador'] = data['localizador'];
        this['saldo_devedor'] = data['saldo_devedor'];
        this['recebiveis_em_renegociacao'] = data['recebiveis_em_renegociacao'];
    }
}
export class ConsultasAgendamentosDto {
    constructor(data = {}) {
        this['idAgendamento'] = data['idAgendamento'];
        this['tipoAgendamentoEnum'] = data['tipoAgendamentoEnum'];
        this['tipoAgendamentoEnumId'] = data['tipoAgendamentoEnumId'];
        this['dataAgendamento'] = data['dataAgendamento'];
        this['valor'] = data['valor'];
        this['descricao'] = data['descricao'];
        this['identificacaoFiscal'] = data['identificacaoFiscal'];
        this['destinatario'] = data['destinatario'];
    }
}
export class ContaBancariaDto {
    constructor(data = {}) {
        this['banco'] = data['banco'];
        this['agencia'] = data['agencia'];
        this['conta'] = data['conta'];
    }
}
export class ContaCompletaDto {
    constructor(data = {}) {
        this['chaveDeConta'] = data['chaveDeConta'];
        this['chaveDeCliente'] = data['chaveDeCliente'];
        this['chaveDeProduto'] = data['chaveDeProduto'];
        this['descricaoConta'] = data['descricaoConta'];
        this['dataAbertura'] = data['dataAbertura'];
        this['dataEncerramento'] = data['dataEncerramento'];
        this['nomeCliente'] = data['nomeCliente'];
        this['apelido'] = data['apelido'];
        this['identificacaoFiscal'] = data['identificacaoFiscal'];
        this['descricaoProduto'] = data['descricaoProduto'];
        this['contaBancarizada'] = data['contaBancarizada'];
        this['idTipoContaBancaria'] = data['idTipoContaBancaria'];
        this['descricaoTipoContaBancaria'] = data['descricaoTipoContaBancaria'];
        this['idStatusConta'] = data['idStatusConta'];
        this['descricaoStatusConta'] = data['descricaoStatusConta'];
        this['subcontas'] = data['subcontas'];
        this['possuiBloqueioJudicial'] = data['possuiBloqueioJudicial'];
    }
}
export class ContaCorrente {
    constructor(data = {}) {
        this['banco'] = data['banco'];
        this['agencia'] = data['agencia'];
        this['conta'] = data['conta'];
    }
}
export class ContaDto {
    constructor(data = {}) {
        this['banco'] = data['banco'];
        this['bancoNome'] = data['bancoNome'];
        this['agencia'] = data['agencia'];
        this['numero'] = data['numero'];
        this['tipoConta'] = data['tipoConta'];
    }
}
export class ContaUnicaDto {
    constructor(data = {}) {
        this['banco'] = data['banco'];
        this['agencia'] = data['agencia'];
        this['conta'] = data['conta'];
        this['tipoConta'] = data['tipoConta'];
    }
}
export class ContratoFgtsParceiroItem {
    constructor(data = {}) {
        this['identificador'] = data['identificador'];
        this['status'] = data['status'];
        this['localizador'] = data['localizador'];
        this['contrato'] = data['contrato'];
        this['cpf'] = data['cpf'];
        this['dataSolicitacao'] = data['dataSolicitacao'];
        this['dataPrimeiroVencimento'] = data['dataPrimeiroVencimento'];
        this['dataUltimoVencimento'] = data['dataUltimoVencimento'];
        this['prazo'] = data['prazo'];
        this['valorLiberado'] = data['valorLiberado'];
        this['totalRecebiveis'] = data['totalRecebiveis'];
        this['taxaJuros'] = data['taxaJuros'];
        this['iof'] = data['iof'];
        this['iofAdicional'] = data['iofAdicional'];
        this['cetMensal'] = data['cetMensal'];
        this['cetAnual'] = data['cetAnual'];
    }
}
export class ConvenioPublicoDTO {
    constructor(data = {}) {
        this['convenioId'] = data['convenioId'];
        this['orgaoPublico'] = data['orgaoPublico'];
        this['nivelOrgaoPublico'] = data['nivelOrgaoPublico'];
        this['ativo'] = data['ativo'];
    }
}
export class DadosBancariosDto {
    constructor(data = {}) {
        this['codigoBanco'] = data['codigoBanco'];
        this['numeroAgencia'] = data['numeroAgencia'];
        this['numeroConta'] = data['numeroConta'];
        this['digitoConta'] = data['digitoConta'];
        this['chavePix'] = data['chavePix'];
    }
}
export class DadosConsultaBloqueiosConta {
    constructor(data = {}) {
        this['descricao'] = data['descricao'];
        this['motivoTransacao'] = data['motivoTransacao'];
        this['periodoLimite'] = data['periodoLimite'];
        this['tipoContagemLimite'] = data['tipoContagemLimite'];
        this['limiteInferior'] = data['limiteInferior'];
        this['limiteSuperior'] = data['limiteSuperior'];
    }
}
export class DadosConsultaExtratoBeneficio {
    constructor(data = {}) {
        this['processadoraId'] = data['processadoraId'];
        this['transacaoId'] = data['transacaoId'];
        this['dataHoraTransacao'] = data['dataHoraTransacao'];
        this['descricaoAbreviada'] = data['descricaoAbreviada'];
        this['descricaoEstabelecimento'] = data['descricaoEstabelecimento'];
        this['nomeEstabelecimento'] = data['nomeEstabelecimento'];
        this['nomeFantasiaEstabelecimento'] = data['nomeFantasiaEstabelecimento'];
        this['descricaoTipoTransacao'] = data['descricaoTipoTransacao'];
        this['numeroCartaoMascarado'] = data['numeroCartaoMascarado'];
        this['motivoTransacaoId'] = data['motivoTransacaoId'];
        this['motivoTransacao'] = data['motivoTransacao'];
        this['valorBRL'] = data['valorBRL'];
        this['valorUSD'] = data['valorUSD'];
        this['processada'] = data['processada'];
        this['detalhe'] = data['detalhe'];
        this['tarifa'] = data['tarifa'];
        this['temComprovante'] = data['temComprovante'];
        this['idMcc'] = data['idMcc'];
        this['idGrupoMcc'] = data['idGrupoMcc'];
        this['iconeGrupoMcc'] = data['iconeGrupoMcc'];
        this['segmento'] = data['segmento'];
    }
}
export class DadosConsultaExtratoBeneficioAddOutputPaginated {
    constructor(data = {}) {
        this['items'] = data['items'];
        this['qtdPaginas'] = data['qtdPaginas'];
        this['qtdPorPagina'] = data['qtdPorPagina'];
        this['totalItems'] = data['totalItems'];
        this['pagina'] = data['pagina'];
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
    }
}
export class DadosConsultaTarifas {
    constructor(data = {}) {
        this['limitesTarifaId'] = data['limitesTarifaId'];
        this['relacaoProdutoUoId'] = data['relacaoProdutoUoId'];
        this['motivoTransacao'] = data['motivoTransacao'];
        this['periodoLimite'] = data['periodoLimite'];
        this['tipoContagemLimite'] = data['tipoContagemLimite'];
        this['limiteInferior'] = data['limiteInferior'];
        this['limiteSuperior'] = data['limiteSuperior'];
        this['aplicarLimiteAFilhos'] = data['aplicarLimiteAFilhos'];
        this['acaoTarifa'] = data['acaoTarifa'];
        this['tipoValor'] = data['tipoValor'];
        this['valorTarifa'] = data['valorTarifa'];
        this['motivoTransacaoTarifa'] = data['motivoTransacaoTarifa'];
        this['localDebitar'] = data['localDebitar'];
        this['descricao'] = data['descricao'];
        this['tipoPessoaTarifa'] = data['tipoPessoaTarifa'];
    }
}
export class DadosConsultaTarifasConta {
    constructor(data = {}) {
        this['descricao'] = data['descricao'];
        this['motivoTransacao'] = data['motivoTransacao'];
        this['periodoLimite'] = data['periodoLimite'];
        this['tipoContagemLimite'] = data['tipoContagemLimite'];
        this['limiteInferior'] = data['limiteInferior'];
        this['limiteSuperior'] = data['limiteSuperior'];
        this['tipoValor'] = data['tipoValor'];
        this['valorTarifa'] = data['valorTarifa'];
        this['motivoTransacaoTarifa'] = data['motivoTransacaoTarifa'];
        this['localDebitar'] = data['localDebitar'];
    }
}
export class DadosContaDto {
    constructor(data = {}) {
        this['banco'] = data['banco'];
        this['agencia'] = data['agencia'];
        this['conta'] = data['conta'];
    }
}
export class DadosDocumentoClienteCDto {
    constructor(data = {}) {
        this['tipoDocumento'] = data['tipoDocumento'];
        this['numeroDoRG'] = data['numeroDoRG'];
        this['dataEmissaoDocumento'] = data['dataEmissaoDocumento'];
        this['orgaoEmissorRG'] = data['orgaoEmissorRG'];
        this['ufOrgaoEmissorRG'] = data['ufOrgaoEmissorRG'];
    }
}
export class DadosSimplificadosContratoDto {
    constructor(data = {}) {
        this['idContrato'] = data['idContrato'];
        this['valorRecebivel'] = data['valorRecebivel'];
        this['statusContrato'] = data['statusContrato'];
        this['dataFinanciamento'] = data['dataFinanciamento'];
        this['numeroNotaFiscal'] = data['numeroNotaFiscal'];
        this['numeroDeParcelas'] = data['numeroDeParcelas'];
        this['valorTotalFinanciado'] = data['valorTotalFinanciado'];
        this['valorEntrada'] = data['valorEntrada'];
        this['emAtraso'] = data['emAtraso'];
        this['dataProximoVencimento'] = data['dataProximoVencimento'];
    }
}
export class DadosUnidadeOrganizacionalDto {
    constructor(data = {}) {
        this['idUnidadeOrganizacional'] = data['idUnidadeOrganizacional'];
        this['codigo'] = data['codigo'];
        this['nome'] = data['nome'];
        this['filhos'] = data['filhos'];
    }
}
export class DadosUsuariosDto {
    constructor(data = {}) {
        this['idUsuario'] = data['idUsuario'];
        this['usuario'] = data['usuario'];
        this['nome'] = data['nome'];
        this['email'] = data['email'];
    }
}
export class Dados_Bancarios {
    constructor(data = {}) {
        this['tipo_conta'] = data['tipo_conta'];
        this['codigo_banco'] = data['codigo_banco'];
        this['numero_agencia'] = data['numero_agencia'];
        this['numero_conta'] = data['numero_conta'];
    }
}
export class Data {
    constructor(data = {}) {
        this['birthDate'] = data['birthDate'];
        this['cpf'] = data['cpf'];
        this['name'] = data['name'];
        this['fatherName'] = data['fatherName'];
        this['motherName'] = data['motherName'];
    }
}
export class DecodeQrCodePixDto {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeConta'] = data['chaveDeConta'];
        this['qrCode'] = data['qrCode'];
    }
}
export class DetalheContrato {
    constructor(data = {}) {
        this['identificador'] = data['identificador'];
        this['status'] = data['status'];
        this['localizador'] = data['localizador'];
        this['contrato'] = data['contrato'];
        this['cpf'] = data['cpf'];
        this['dataSolicitacao'] = data['dataSolicitacao'];
        this['dataPrimeiroVencimento'] = data['dataPrimeiroVencimento'];
        this['dataUltimoVencimento'] = data['dataUltimoVencimento'];
        this['prazo'] = data['prazo'];
        this['valorLiberado'] = data['valorLiberado'];
        this['totalRecebiveis'] = data['totalRecebiveis'];
        this['taxaJuros'] = data['taxaJuros'];
        this['iof'] = data['iof'];
        this['iofAdicional'] = data['iofAdicional'];
        this['cetMensal'] = data['cetMensal'];
        this['cetAnual'] = data['cetAnual'];
        this['recebiveis'] = data['recebiveis'];
    }
}
export class DetalheParcelaExtrato {
    constructor(data = {}) {
        this['numeroParcela'] = data['numeroParcela'];
        this['dataVencimento'] = data['dataVencimento'];
        this['valorParcelaNoVencimento'] = data['valorParcelaNoVencimento'];
        this['valorAtualParcela'] = data['valorAtualParcela'];
        this['jurosPago'] = data['jurosPago'];
        this['statusParcela'] = data['statusParcela'];
        this['dataPagamento'] = data['dataPagamento'];
        this['valorPago'] = data['valorPago'];
        this['saldoDevedorDataReferencia'] = data['saldoDevedorDataReferencia'];
    }
}
export class DetalhesBloqueioDto {
    constructor(data = {}) {
        this['motivo'] = data['motivo'];
        this['dataBloqueio'] = data['dataBloqueio'];
        this['usuarioBloqueio'] = data['usuarioBloqueio'];
        this['tipoBloqueio'] = data['tipoBloqueio'];
    }
}
export class DetalhesCancelamentoConta {
    constructor(data = {}) {
        this['dataCancelamento'] = data['dataCancelamento'];
        this['motivoCancelamento'] = data['motivoCancelamento'];
        this['usuarioCancelamento'] = data['usuarioCancelamento'];
        this['statusCancelamentoProcessadora'] = data['statusCancelamentoProcessadora'];
    }
}
export class DetalhesContaDto {
    constructor(data = {}) {
        this['chaveDeConta'] = data['chaveDeConta'];
        this['organizationUnitId'] = data['organizationUnitId'];
        this['organizationUnitNome'] = data['organizationUnitNome'];
        this['contaCorrente'] = data['contaCorrente'];
        this['contaPagamento'] = data['contaPagamento'];
    }
}
export class DetalhesContaPix {
    constructor(data = {}) {
        this['agencia'] = data['agencia'];
        this['conta'] = data['conta'];
        this['codInstituicao'] = data['codInstituicao'];
        this['nomeInstituicao'] = data['nomeInstituicao'];
        this['tipoConta'] = data['tipoConta'];
    }
}
export class DetalhesPessoaPix {
    constructor(data = {}) {
        this['identificacaoFiscal'] = data['identificacaoFiscal'];
        this['nomeCliente'] = data['nomeCliente'];
        this['nomeFantasia'] = data['nomeFantasia'];
        this['tipoPessoa'] = data['tipoPessoa'];
    }
}
export class DevolucaoPixDto {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeConta'] = data['chaveDeConta'];
        this['endToEnd'] = data['endToEnd'];
        this['autenticacao2FA'] = data['autenticacao2FA'];
        this['valorOperacao'] = data['valorOperacao'];
        this['descricao'] = data['descricao'];
    }
}
export class DispositivoDto {
    constructor(data = {}) {
        this['deviceId'] = data['deviceId'];
        this['nome'] = data['nome'];
        this['modelo'] = data['modelo'];
        this['versaoSistema'] = data['versaoSistema'];
        this['sistemaOperacional'] = data['sistemaOperacional'];
        this['autorizado'] = data['autorizado'];
        this['recebePush'] = data['recebePush'];
        this['usaOtpt'] = data['usaOtpt'];
    }
}
export class DtoAssociaProdutoOrganizacao {
    constructor(data = {}) {
        this['produto'] = data['produto'];
        this['organizationUnitId'] = data['organizationUnitId'];
        this['situacao'] = data['situacao'];
        this['organizationUnitDescricao'] = data['organizationUnitDescricao'];
    }
}
export class DtoDadosSocietarioAdministrador {
    constructor(data = {}) {
        this['chaveDeSocio'] = data['chaveDeSocio'];
        this['chaveDeCliente'] = data['chaveDeCliente'];
        this['nomeCompleto'] = data['nomeCompleto'];
        this['identificacaoFiscal'] = data['identificacaoFiscal'];
        this['nomeMae'] = data['nomeMae'];
        this['qualificacaoSocio'] = data['qualificacaoSocio'];
        this['sexo'] = data['sexo'];
        this['telefone'] = data['telefone'];
        this['email'] = data['email'];
        this['percentualParticipacao'] = data['percentualParticipacao'];
    }
}
export class DtoLimite {
    constructor(data = {}) {
        this['limiteQuantidade'] = data['limiteQuantidade'];
        this['limiteUnitarioInferior'] = data['limiteUnitarioInferior'];
        this['limiteUnitarioSuperior'] = data['limiteUnitarioSuperior'];
        this['periodo'] = data['periodo'];
    }
}
export class DtoServico {
    constructor(data = {}) {
        this['id'] = data['id'];
        this['descricao'] = data['descricao'];
        this['tarifa'] = data['tarifa'];
        this['limites'] = data['limites'];
        this['geraCartao'] = data['geraCartao'];
        this['tipoDeCartao'] = data['tipoDeCartao'];
        this['parametroDimoQrCode'] = data['parametroDimoQrCode'];
    }
}
export class DtoSocietarioAdministrador {
    constructor(data = {}) {
        this['nomeCompleto'] = data['nomeCompleto'];
        this['identificacaoFiscal'] = data['identificacaoFiscal'];
        this['nomeMae'] = data['nomeMae'];
        this['qualificacaoSocio'] = data['qualificacaoSocio'];
        this['sexo'] = data['sexo'];
        this['telefone'] = data['telefone'];
        this['email'] = data['email'];
        this['percentualParticipacao'] = data['percentualParticipacao'];
    }
}
export class DtoTarifa {
    constructor(data = {}) {
        this['valor'] = data['valor'];
    }
}
export class DtoTipoDeCartao {
    constructor(data = {}) {
        this['id'] = data['id'];
        this['descricao'] = data['descricao'];
        this['package'] = data['package'];
        this['subPackage'] = data['subPackage'];
        this['bandeira'] = data['bandeira'];
        this['possuiPlastico'] = data['possuiPlastico'];
        this['modalidade'] = data['modalidade'];
        this['recarregavel'] = data['recarregavel'];
        this['fisico'] = data['fisico'];
        this['idImagem'] = data['idImagem'];
        this['idTipoPlastico'] = data['idTipoPlastico'];
    }
}
export class EloIDVNotification {
    constructor(data = {}) {
        this['requestInfo'] = data['requestInfo'];
        this['deviceTokenId'] = data['deviceTokenId'];
        this['chosenIDVMethod'] = data['chosenIDVMethod'];
        this['otpValue'] = data['otpValue'];
    }
}
export class EloProvisioningRequest {
    constructor(data = {}) {
        this['cipheredCardData'] = data['cipheredCardData'];
        this['cipheredCardDataFormat'] = data['cipheredCardDataFormat'];
        this['tokenRequestorId'] = data['tokenRequestorId'];
        this['inputMethod'] = data['inputMethod'];
        this['enrollAuthInfo'] = data['enrollAuthInfo'];
        this['enrollmentInformation'] = data['enrollmentInformation'];
    }
}
export class EloQrCodeCallbackRequest {
    constructor(data = {}) {
        this['status'] = data['status'];
        this['error'] = data['error'];
        this['transactionInfo'] = data['transactionInfo'];
    }
}
export class EloTokenNotificationRequest {
    constructor(data = {}) {
        this['tokenId'] = data['tokenId'];
        this['status'] = data['status'];
        this['timestamp'] = data['timestamp'];
        this['reason'] = data['reason'];
    }
}
export class EloTokenRequest {
    constructor(data = {}) {
        this['cardReferenceId'] = data['cardReferenceId'];
        this['tokenRequestorId'] = data['tokenRequestorId'];
        this['cipheredTokenConstraints'] = data['cipheredTokenConstraints'];
        this['cipheredTokenDataFormat'] = data['cipheredTokenDataFormat'];
        this['deviceId'] = data['deviceId'];
        this['deviceMetadata'] = data['deviceMetadata'];
        this['enrollAuthInfo'] = data['enrollAuthInfo'];
        this['enrollmentInformation'] = data['enrollmentInformation'];
        this['analyticsResult'] = data['analyticsResult'];
    }
}
export class EmailDto {
    constructor(data = {}) {
        this['email'] = data['email'];
        this['confirmacaoEmail'] = data['confirmacaoEmail'];
    }
}
export class Endereco {
    constructor(data = {}) {
        this['rua'] = data['rua'];
        this['numero'] = data['numero'];
        this['complemento'] = data['complemento'];
        this['bairro'] = data['bairro'];
        this['cep'] = data['cep'];
        this['cidade'] = data['cidade'];
        this['uf'] = data['uf'];
    }
}
export class EnderecoCDto {
    constructor(data = {}) {
        this['logradouro'] = data['logradouro'];
        this['numero'] = data['numero'];
        this['complemento'] = data['complemento'];
        this['bairro'] = data['bairro'];
        this['cep'] = data['cep'];
        this['cidade'] = data['cidade'];
        this['uf'] = data['uf'];
    }
}
export class EnderecoCorrespondenciaDto {
    constructor(data = {}) {
        this['rua'] = data['rua'];
        this['numero'] = data['numero'];
        this['complemento'] = data['complemento'];
        this['bairro'] = data['bairro'];
        this['cep'] = data['cep'];
        this['cidade'] = data['cidade'];
        this['uf'] = data['uf'];
    }
}
export class EnderecoDto {
    constructor(data = {}) {
        this['rua'] = data['rua'];
        this['numero'] = data['numero'];
        this['complemento'] = data['complemento'];
        this['bairro'] = data['bairro'];
        this['cep'] = data['cep'];
        this['cidade'] = data['cidade'];
        this['uf'] = data['uf'];
    }
}
export class EnrollAuthInfo {
    constructor(data = {}) {
        this['ts'] = data['ts'];
        this['validityTs'] = data['validityTs'];
        this['status'] = data['status'];
        this['authType'] = data['authType'];
        this['provider'] = data['provider'];
    }
}
export class EnrollmentInfo {
    constructor(data = {}) {
        this['tsm'] = data['tsm'];
        this['issuer'] = data['issuer'];
        this['wallet'] = data['wallet'];
    }
}
export class Error {
    constructor(data = {}) {
        this['code'] = data['code'];
        this['description'] = data['description'];
    }
}
export class ErrorInfo {
    constructor(data = {}) {
        this['code'] = data['code'];
        this['message'] = data['message'];
        this['details'] = data['details'];
        this['validationErrors'] = data['validationErrors'];
    }
}
export class ErrosCafDto {
    constructor(data = {}) {
        this['retornoAntiFraudeId'] = data['retornoAntiFraudeId'];
        this['retornoAntiFraudeDescricao'] = data['retornoAntiFraudeDescricao'];
        this['sessaoId'] = data['sessaoId'];
        this['sessaoDescricao'] = data['sessaoDescricao'];
    }
}
export class ExtratoCompletoBancoProcessadora {
    constructor(data = {}) {
        this['saldoArbi'] = data['saldoArbi'];
        this['saldoProcessadora'] = data['saldoProcessadora'];
        this['identificacaoFiscal'] = data['identificacaoFiscal'];
        this['nomeCliente'] = data['nomeCliente'];
        this['organizationUnitId'] = data['organizationUnitId'];
        this['organizacao'] = data['organizacao'];
        this['contaCorrente'] = data['contaCorrente'];
        this['contaConductor'] = data['contaConductor'];
        this['contaArbi'] = data['contaArbi'];
        this['inicio'] = data['inicio'];
        this['fim'] = data['fim'];
        this['transacoes'] = data['transacoes'];
    }
}
export class ExtratoConciliacao {
    constructor(data = {}) {
        this['tipoOperacao'] = data['tipoOperacao'];
        this['valor'] = data['valor'];
        this['descricaoOperacao'] = data['descricaoOperacao'];
        this['dataOperacao'] = data['dataOperacao'];
        this['idDatashelf'] = data['idDatashelf'];
        this['idBackend'] = data['idBackend'];
        this['idProcessadora'] = data['idProcessadora'];
        this['idArbi'] = data['idArbi'];
    }
}
export class ExtratoPixDto {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeConta'] = data['chaveDeConta'];
        this['dataInicio'] = data['dataInicio'];
        this['dataFim'] = data['dataFim'];
    }
}
export class FaceMatch {
    constructor(data = {}) {
        this['statusCode'] = data['statusCode'];
        this['confidence'] = data['confidence'];
        this['identical'] = data['identical'];
    }
}
export class Fatores {
    constructor(data = {}) {
        this['taxa_nominal'] = data['taxa_nominal'];
        this['prazo'] = data['prazo'];
        this['fator'] = data['fator'];
        this['valor_minimo'] = data['valor_minimo'];
        this['valor_maximo'] = data['valor_maximo'];
        this['parcela_minima'] = data['parcela_minima'];
        this['parcela_maxima'] = data['parcela_maxima'];
        this['tarifas'] = data['tarifas'];
        this['seguro'] = data['seguro'];
        this['seguro_porcentagem'] = data['seguro_porcentagem'];
    }
}
export class FavoritoChavesDto {
    constructor(data = {}) {
        this['agencia'] = data['agencia'];
        this['conta'] = data['conta'];
        this['identificacaoFiscal'] = data['identificacaoFiscal'];
        this['tipoDeConta'] = data['tipoDeConta'];
        this['codigoOuISPB'] = data['codigoOuISPB'];
        this['chavePix'] = data['chavePix'];
        this['tipoChavePix'] = data['tipoChavePix'];
    }
}
export class FeatureFlagDto {
    constructor(data = {}) {
        this['nome'] = data['nome'];
        this['descricao'] = data['descricao'];
        this['valor'] = data['valor'];
        this['tipo'] = data['tipo'];
    }
}
export class FepWebConsignadoPublicoRequest {
    constructor(data = {}) {
        this['chaveDeCliente'] = data['chaveDeCliente'];
        this['transacaoId'] = data['transacaoId'];
        this['covenantID'] = data['covenantID'];
        this['contratoTermoID'] = data['contratoTermoID'];
        this['contratoResidenciaID'] = data['contratoResidenciaID'];
        this['contraChequeBase64'] = data['contraChequeBase64'];
        this['contraChequeExtensao'] = data['contraChequeExtensao'];
        this['selfieBase64'] = data['selfieBase64'];
        this['rgFrenteBase64'] = data['rgFrenteBase64'];
        this['rgVersoBase64'] = data['rgVersoBase64'];
        this['clienteFepWeb'] = data['clienteFepWeb'];
    }
}
export class Images {
    constructor(data = {}) {
        this['selfie'] = data['selfie'];
        this['back'] = data['back'];
        this['front'] = data['front'];
    }
}
export class InAbrirConta {
    constructor(data = {}) {
        this['chave'] = data['chave'];
        this['senha'] = data['senha'];
    }
}
export class InAceitarTermoGenerico {
    constructor(data = {}) {
        this['valorSolicitado'] = data['valorSolicitado'];
    }
}
export class InAdicionarBodyOrganizacao {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['organizacaoId'] = data['organizacaoId'];
        this['tipo'] = data['tipo'];
        this['assuntoEmail'] = data['assuntoEmail'];
        this['arquivo'] = data['arquivo'];
    }
}
export class InAgendamentoTransferenciaInterna {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveContaOrigem'] = data['chaveContaOrigem'];
        this['autenticacao2FA'] = data['autenticacao2FA'];
        this['chaveContaDestino'] = data['chaveContaDestino'];
        this['valor'] = data['valor'];
        this['dataAgendamento'] = data['dataAgendamento'];
        this['numeroOcorrencias'] = data['numeroOcorrencias'];
    }
}
export class InAgendarPixPorAgencia {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeConta'] = data['chaveDeConta'];
        this['descricao'] = data['descricao'];
        this['autenticacao2FA'] = data['autenticacao2FA'];
        this['valorOperacao'] = data['valorOperacao'];
        this['tipoPix'] = data['tipoPix'];
        this['valorSaqueTroco'] = data['valorSaqueTroco'];
        this['codInstituicaoBeneficiario'] = data['codInstituicaoBeneficiario'];
        this['agenciaBeneficiario'] = data['agenciaBeneficiario'];
        this['contaBeneficiario'] = data['contaBeneficiario'];
        this['tipoContaBeneficiario'] = data['tipoContaBeneficiario'];
        this['identificacaoFiscalBeneficiario'] = data['identificacaoFiscalBeneficiario'];
        this['nomeBeneficiario'] = data['nomeBeneficiario'];
        this['recorrencia'] = data['recorrencia'];
        this['dataPagamento'] = data['dataPagamento'];
        this['horaPagamento'] = data['horaPagamento'];
    }
}
export class InAgendarPixPorChave {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeConta'] = data['chaveDeConta'];
        this['descricao'] = data['descricao'];
        this['autenticacao2FA'] = data['autenticacao2FA'];
        this['valorOperacao'] = data['valorOperacao'];
        this['tipoPix'] = data['tipoPix'];
        this['valorSaqueTroco'] = data['valorSaqueTroco'];
        this['chaveEnderecamento'] = data['chaveEnderecamento'];
        this['recorrencia'] = data['recorrencia'];
        this['dataPagamento'] = data['dataPagamento'];
        this['horaPagamento'] = data['horaPagamento'];
    }
}
export class InAgendarRecargaCelular {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeConta'] = data['chaveDeConta'];
        this['autenticacao2FA'] = data['autenticacao2FA'];
        this['provedorId'] = data['provedorId'];
        this['ddi'] = data['ddi'];
        this['ddd'] = data['ddd'];
        this['numero'] = data['numero'];
        this['valor'] = data['valor'];
        this['dataAgendamento'] = data['dataAgendamento'];
        this['numeroRecorrencias'] = data['numeroRecorrencias'];
    }
}
export class InAjusteFinanceiro {
    constructor(data = {}) {
        this['conta'] = data['conta'];
        this['valor'] = data['valor'];
        this['codHistorico'] = data['codHistorico'];
        this['complementoHistorico'] = data['complementoHistorico'];
        this['descricao'] = data['descricao'];
        this['bancoorigem'] = data['bancoorigem'];
        this['agenciaorigem'] = data['agenciaorigem'];
        this['contaorigem'] = data['contaorigem'];
        this['inscricao'] = data['inscricao'];
        this['nome'] = data['nome'];
        this['motivoDevolucao'] = data['motivoDevolucao'];
        this['chave'] = data['chave'];
        this['numOrigemStr'] = data['numOrigemStr'];
        this['nroMovimento'] = data['nroMovimento'];
        this['nroMovimentoProvisorio'] = data['nroMovimentoProvisorio'];
        this['dataAtu'] = data['dataAtu'];
    }
}
export class InAjusteFinanceiroInterno {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['conta'] = data['conta'];
        this['valor'] = data['valor'];
        this['codHistorico'] = data['codHistorico'];
    }
}
export class InAlteracaoDeStatusCAF {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['type'] = data['type'];
        this['report'] = data['report'];
        this['uuid'] = data['uuid'];
        this['status'] = data['status'];
        this['date'] = data['date'];
        this['onboardingId'] = data['onboardingId'];
    }
}
export class InAlteracaoDeStatusFepWebConsignadoPublico {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['formId'] = data['formId'];
        this['cpf'] = data['cpf'];
        this['status'] = data['status'];
        this['dados_bancarios'] = data['dados_bancarios'];
    }
}
export class InAlteracaoDeStatusTrust {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['type'] = data['type'];
        this['report'] = data['report'];
        this['uuid'] = data['uuid'];
        this['status'] = data['status'];
        this['personId'] = data['personId'];
        this['date'] = data['date'];
        this['onboardingId'] = data['onboardingId'];
        this['isMatch'] = data['isMatch'];
        this['onboardingToken'] = data['onboardingToken'];
        this['tenant'] = data['tenant'];
    }
}
export class InAlterarAgendamentoBoleto {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['autenticacao2FA'] = data['autenticacao2FA'];
        this['chaveDeConta'] = data['chaveDeConta'];
        this['idAgendamento'] = data['idAgendamento'];
        this['dataAgendamento'] = data['dataAgendamento'];
    }
}
export class InAlterarAgendamentoPixPorChave {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeConta'] = data['chaveDeConta'];
        this['descricao'] = data['descricao'];
        this['autenticacao2FA'] = data['autenticacao2FA'];
        this['valorOperacao'] = data['valorOperacao'];
        this['tipoPix'] = data['tipoPix'];
        this['valorSaqueTroco'] = data['valorSaqueTroco'];
        this['chaveEnderecamento'] = data['chaveEnderecamento'];
        this['idAgendamento'] = data['idAgendamento'];
        this['dataPagamento'] = data['dataPagamento'];
        this['horaPagamento'] = data['horaPagamento'];
    }
}
export class InAlterarAgendamentoPorConta {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeConta'] = data['chaveDeConta'];
        this['descricao'] = data['descricao'];
        this['autenticacao2FA'] = data['autenticacao2FA'];
        this['valorOperacao'] = data['valorOperacao'];
        this['tipoPix'] = data['tipoPix'];
        this['valorSaqueTroco'] = data['valorSaqueTroco'];
        this['codInstituicaoBeneficiario'] = data['codInstituicaoBeneficiario'];
        this['agenciaBeneficiario'] = data['agenciaBeneficiario'];
        this['contaBeneficiario'] = data['contaBeneficiario'];
        this['tipoContaBeneficiario'] = data['tipoContaBeneficiario'];
        this['identificacaoFiscalBeneficiario'] = data['identificacaoFiscalBeneficiario'];
        this['nomeBeneficiario'] = data['nomeBeneficiario'];
        this['idAgendamento'] = data['idAgendamento'];
        this['dataPagamento'] = data['dataPagamento'];
        this['horaPagamento'] = data['horaPagamento'];
    }
}
export class InAlterarAgendamentoRecargaCelular {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['idAgendamento'] = data['idAgendamento'];
        this['chaveDeConta'] = data['chaveDeConta'];
        this['autenticacao2FA'] = data['autenticacao2FA'];
        this['provedorId'] = data['provedorId'];
        this['ddi'] = data['ddi'];
        this['ddd'] = data['ddd'];
        this['numero'] = data['numero'];
        this['valor'] = data['valor'];
        this['dataAgendamento'] = data['dataAgendamento'];
    }
}
export class InAlterarAgendamentoTransferenciaInterna {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['autenticacao2FA'] = data['autenticacao2FA'];
        this['chaveDeConta'] = data['chaveDeConta'];
        this['idAgendamento'] = data['idAgendamento'];
        this['valor'] = data['valor'];
        this['dataAgendamento'] = data['dataAgendamento'];
    }
}
export class InAlterarChaveAssinatura {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['organizationUnitId'] = data['organizationUnitId'];
        this['chaveAssinatura'] = data['chaveAssinatura'];
    }
}
export class InAlterarConfigContactless {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeCartao'] = data['chaveDeCartao'];
        this['isContactless'] = data['isContactless'];
        this['limiteContactlessSemSenha'] = data['limiteContactlessSemSenha'];
    }
}
export class InAlterarLimiteContas {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['organizationUnitId'] = data['organizationUnitId'];
        this['limite'] = data['limite'];
    }
}
export class InAlterarLimitesDeCredito {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['organizationUnitId'] = data['organizationUnitId'];
        this['limiteMaximoDeCredito'] = data['limiteMaximoDeCredito'];
        this['limiteDisponivelDeCredito'] = data['limiteDisponivelDeCredito'];
    }
}
export class InAlterarOnboard {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['identificacaoFiscal'] = data['identificacaoFiscal'];
        this['organizationUnitId'] = data['organizationUnitId'];
        this['tipoConta'] = data['tipoConta'];
        this['chaveDeProduto'] = data['chaveDeProduto'];
        this['aberturaAutomaticaConta'] = data['aberturaAutomaticaConta'];
        this['parametrosId'] = data['parametrosId'];
    }
}
export class InAlterarPinCartaoInterno {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeConta'] = data['chaveDeConta'];
        this['autenticacao2FA'] = data['autenticacao2FA'];
        this['novoPin'] = data['novoPin'];
    }
}
export class InAlterarProduto {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['idProduto'] = data['idProduto'];
        this['descricao'] = data['descricao'];
        this['idProdutoProcessadora'] = data['idProdutoProcessadora'];
        this['idOrigemComercialProcessadora'] = data['idOrigemComercialProcessadora'];
    }
}
export class InAlterarSenha {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['autenticacao2FA'] = data['autenticacao2FA'];
        this['novoPin'] = data['novoPin'];
        this['chaveDeCartao'] = data['chaveDeCartao'];
    }
}
export class InAlterarSenhaCliente {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeCliente'] = data['chaveDeCliente'];
        this['novaSenha'] = data['novaSenha'];
        this['autenticacao2FA'] = data['autenticacao2FA'];
    }
}
export class InAlterarSenhaToken {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeCartao'] = data['chaveDeCartao'];
        this['autenticacao2FA'] = data['autenticacao2FA'];
        this['novoPin'] = data['novoPin'];
    }
}
export class InAlterarSenhaUsuarioPorToken {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['identificacaoFiscal'] = data['identificacaoFiscal'];
        this['organizationUnitId'] = data['organizationUnitId'];
        this['autenticacao2FA'] = data['autenticacao2FA'];
        this['novaSenha'] = data['novaSenha'];
        this['confirmarNovaSenha'] = data['confirmarNovaSenha'];
    }
}
export class InAlterarUnidadeOrganizacional {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['nome'] = data['nome'];
        this['pai'] = data['pai'];
        this['cnpj'] = data['cnpj'];
        this['razaoSocial'] = data['razaoSocial'];
        this['contatoNome'] = data['contatoNome'];
        this['dadosConta'] = data['dadosConta'];
        this['contatoTelefone'] = data['contatoTelefone'];
        this['contatoEmail'] = data['contatoEmail'];
        this['endereco'] = data['endereco'];
        this['processoGedId'] = data['processoGedId'];
        this['desativarMensageria'] = data['desativarMensageria'];
        this['autorizarAssinaturas'] = data['autorizarAssinaturas'];
        this['idUnidadeOrganizacional'] = data['idUnidadeOrganizacional'];
    }
}
export class InAnaliseCreditoClean {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeConta'] = data['chaveDeConta'];
    }
}
export class InAparelhoContrato {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeCliente'] = data['chaveDeCliente'];
        this['numeroNotaFiscal'] = data['numeroNotaFiscal'];
        this['serieNotaFiscal'] = data['serieNotaFiscal'];
        this['imei'] = data['imei'];
        this['versaoMotosafe'] = data['versaoMotosafe'];
        this['modelo'] = data['modelo'];
        this['codigoEAN'] = data['codigoEAN'];
    }
}
export class InAparelhoPropostaFinanciamentoMobile {
    constructor(data = {}) {
        this['valorCelular'] = data['valorCelular'];
        this['valorSeguro'] = data['valorSeguro'];
        this['imei'] = data['imei'];
        this['versaoMotosafe'] = data['versaoMotosafe'];
        this['sku'] = data['sku'];
        this['ean'] = data['ean'];
        this['codigoXt'] = data['codigoXt'];
        this['modelo'] = data['modelo'];
    }
}
export class InArquivoCliente {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['arquivo'] = data['arquivo'];
        this['tipoDocumento'] = data['tipoDocumento'];
    }
}
export class InAssinarAntecipacao {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['concorda'] = data['concorda'];
        this['localizacao'] = data['localizacao'];
        this['chaveDeConta'] = data['chaveDeConta'];
        this['autenticacao2FA'] = data['autenticacao2FA'];
    }
}
export class InAssinarAntecipacaoSalarial {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['concorda'] = data['concorda'];
        this['localizacao'] = data['localizacao'];
        this['autenticacao2FA'] = data['autenticacao2FA'];
    }
}
export class InAssinarContratoCreditoClean {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeConta'] = data['chaveDeConta'];
        this['autenticacao2FA'] = data['autenticacao2FA'];
    }
}
export class InAssinarContratoFgts {
    constructor(data = {}) {
        this['token'] = data['token'];
        this['senha'] = data['senha'];
    }
}
export class InAssinarRenegociacao {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['concorda'] = data['concorda'];
        this['autenticacao2FA'] = data['autenticacao2FA'];
        this['localizacao'] = data['localizacao'];
    }
}
export class InAssinarSegundaViaAntecipacaoPrivada {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeConta'] = data['chaveDeConta'];
        this['localizador'] = data['localizador'];
        this['contratoId'] = data['contratoId'];
    }
}
export class InAssinarTermoAntecipacao {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeConta'] = data['chaveDeConta'];
        this['autenticacao2FA'] = data['autenticacao2FA'];
        this['ipCliente'] = data['ipCliente'];
    }
}
export class InAssinarTermoNovamente {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeCliente'] = data['chaveDeCliente'];
        this['tipoTermo'] = data['tipoTermo'];
    }
}
export class InAssinaturaCCB {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['token'] = data['token'];
        this['tipoEnvioToken'] = data['tipoEnvioToken'];
        this['organizationUnitId'] = data['organizationUnitId'];
    }
}
export class InAssinaturaEvento {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['organizationUnitId'] = data['organizationUnitId'];
        this['tipoSubscription'] = data['tipoSubscription'];
    }
}
export class InAssinaturaFinanciamento {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['token'] = data['token'];
        this['concorda'] = data['concorda'];
        this['chaveDeCliente'] = data['chaveDeCliente'];
    }
}
export class InAtivarCodigoIndicacao {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeConta'] = data['chaveDeConta'];
        this['codigoIndicacao'] = data['codigoIndicacao'];
    }
}
export class InAtribuirParametroDimo {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['produtoId'] = data['produtoId'];
        this['banco'] = data['banco'];
        this['agencia'] = data['agencia'];
        this['conta'] = data['conta'];
    }
}
export class InAtribuirServicoDeCartoesProduto {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['produtoId'] = data['produtoId'];
        this['servicos'] = data['servicos'];
    }
}
export class InAtribuirTipoAdquirencia {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['produtoId'] = data['produtoId'];
        this['tipoAdquirencia'] = data['tipoAdquirencia'];
    }
}
export class InAtualizaEmail {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['autenticacao2FA'] = data['autenticacao2FA'];
        this['emailDto'] = data['emailDto'];
        this['chaveDeCliente'] = data['chaveDeCliente'];
    }
}
export class InAtualizarClientePJDto {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['razaoSocial'] = data['razaoSocial'];
        this['nomeFantasia'] = data['nomeFantasia'];
        this['identificacaoFiscal'] = data['identificacaoFiscal'];
        this['organizationUnitId'] = data['organizationUnitId'];
        this['email'] = data['email'];
        this['naturezaJuridica'] = data['naturezaJuridica'];
        this['porte'] = data['porte'];
        this['grupo'] = data['grupo'];
        this['cnae'] = data['cnae'];
        this['cnaeDescricao'] = data['cnaeDescricao'];
        this['capitalSocial'] = data['capitalSocial'];
        this['origemCapitalSocial'] = data['origemCapitalSocial'];
        this['dataInicioAtividade'] = data['dataInicioAtividade'];
        this['endereco'] = data['endereco'];
        this['telefoneFixo'] = data['telefoneFixo'];
        this['telefoneMovel'] = data['telefoneMovel'];
        this['contaDePagamento'] = data['contaDePagamento'];
        this['contaDeCorrente'] = data['contaDeCorrente'];
        this['autenticacao2FA'] = data['autenticacao2FA'];
        this['chaveDeProduto'] = data['chaveDeProduto'];
    }
}
export class InAtualizarDispositivo {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeConta'] = data['chaveDeConta'];
        this['deviceId'] = data['deviceId'];
        this['nome'] = data['nome'];
        this['modelo'] = data['modelo'];
        this['versaoSistema'] = data['versaoSistema'];
        this['sistemaOperacional'] = data['sistemaOperacional'];
    }
}
export class InAtualizarFavorito {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeConta'] = data['chaveDeConta'];
        this['identificacaoFiscal'] = data['identificacaoFiscal'];
        this['favoritoId'] = data['favoritoId'];
        this['favoritoChaveId'] = data['favoritoChaveId'];
        this['nomeCompleto'] = data['nomeCompleto'];
        this['apelido'] = data['apelido'];
        this['chave'] = data['chave'];
    }
}
export class InAtualizarFeatureFlag {
    constructor(data = {}) {
        this['nome'] = data['nome'];
        this['valor'] = data['valor'];
    }
}
export class InAtualizarIdempotencias {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['senha'] = data['senha'];
    }
}
export class InAtualizarOtptDispositivo {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeConta'] = data['chaveDeConta'];
        this['deviceId'] = data['deviceId'];
        this['ativo'] = data['ativo'];
    }
}
export class InAtualizarPortador {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeCliente'] = data['chaveDeCliente'];
        this['vinculoCliente'] = data['vinculoCliente'];
        this['identificacaoFiscal'] = data['identificacaoFiscal'];
        this['nome'] = data['nome'];
        this['nomeMae'] = data['nomeMae'];
        this['email'] = data['email'];
        this['dataNascimento'] = data['dataNascimento'];
        this['telefoneMovel'] = data['telefoneMovel'];
        this['endereco'] = data['endereco'];
        this['nomeEmpresa'] = data['nomeEmpresa'];
        this['portadorId'] = data['portadorId'];
    }
}
export class InAtualizarPushDispositivo {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeConta'] = data['chaveDeConta'];
        this['deviceId'] = data['deviceId'];
        this['ativo'] = data['ativo'];
        this['pushId'] = data['pushId'];
    }
}
export class InAtualizarStatusBoleto {
    constructor(data = {}) {
        this['baixatitulos'] = data['baixatitulos'];
    }
}
export class InAtualizarTermoAberturaContaParceiro {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['idTermo'] = data['idTermo'];
        this['conteudo'] = data['conteudo'];
    }
}
export class InAuthCliente {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['email'] = data['email'];
        this['senha'] = data['senha'];
        this['minutosValidosSessao'] = data['minutosValidosSessao'];
        this['token2fa'] = data['token2fa'];
    }
}
export class InAuthConta {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['identificacaoFiscal'] = data['identificacaoFiscal'];
        this['senha'] = data['senha'];
        this['organizationUnitId'] = data['organizationUnitId'];
    }
}
export class InAuthUsuario {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['usuario'] = data['usuario'];
        this['senha'] = data['senha'];
        this['minutosValidosSessao'] = data['minutosValidosSessao'];
        this['token2fa'] = data['token2fa'];
    }
}
export class InBaixaTitulo {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeConta'] = data['chaveDeConta'];
        this['nossoNumero'] = data['nossoNumero'];
    }
}
export class InBaixarRetornoAlteracaoGrupoMccCartoes {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['alteracaoId'] = data['alteracaoId'];
    }
}
export class InBaixarRetornoCargaMassiva {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['cargaId'] = data['cargaId'];
        this['organizationUnitId'] = data['organizationUnitId'];
    }
}
export class InBaixarRetornoImportacaoClientes {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['importacaoId'] = data['importacaoId'];
        this['organizationUnitId'] = data['organizationUnitId'];
    }
}
export class InBaixarRetornoImportacaoClientesBS {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['importacaoId'] = data['importacaoId'];
        this['organizationUnitId'] = data['organizationUnitId'];
    }
}
export class InBloquearCartao {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeCartao'] = data['chaveDeCartao'];
    }
}
export class InBloquearDeviceMotoSafe {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['autenticacao2FA'] = data['autenticacao2FA'];
        this['chaveDeConta'] = data['chaveDeConta'];
    }
}
export class InBloquearDispositivo {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeConta'] = data['chaveDeConta'];
        this['deviceId'] = data['deviceId'];
    }
}
export class InBloquearUsuario {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['userId'] = data['userId'];
        this['organizationUnitId'] = data['organizationUnitId'];
    }
}
export class InBloqueioJudicialNotificacao {
    constructor(data = {}) {
        this['dataOrdem'] = data['dataOrdem'];
        this['protocoloSolicitacao'] = data['protocoloSolicitacao'];
        this['sequencialBloqueio'] = data['sequencialBloqueio'];
        this['reiteracaoBloqueio'] = data['reiteracaoBloqueio'];
        this['sequencialSolicitacao'] = data['sequencialSolicitacao'];
        this['reiteracaoSolicitacao'] = data['reiteracaoSolicitacao'];
        this['cpfCnpjEnvolvido'] = data['cpfCnpjEnvolvido'];
        this['nomeEnvolvido'] = data['nomeEnvolvido'];
        this['valorSolicitado'] = data['valorSolicitado'];
        this['numProcesso'] = data['numProcesso'];
        this['vara'] = data['vara'];
        this['nome'] = data['nome'];
        this['tipo'] = data['tipo'];
        this['conta'] = data['conta'];
        this['valorAtendido'] = data['valorAtendido'];
        this['tribunal'] = data['tribunal'];
        this['status'] = data['status'];
        this['cancelareiteracao'] = data['cancelareiteracao'];
    }
}
export class InBoletoRecarga {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeConta'] = data['chaveDeConta'];
        this['dataVencimento'] = data['dataVencimento'];
        this['valorOperacao'] = data['valorOperacao'];
        this['gerarImpressao'] = data['gerarImpressao'];
    }
}
export class InBuscarBoleto {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeConta'] = data['chaveDeConta'];
        this['boletoId'] = data['boletoId'];
    }
}
export class InCadastrarClienteFilaOnboard {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['organizationUnitId'] = data['organizationUnitId'];
        this['nomeCompleto'] = data['nomeCompleto'];
        this['identificacaoFiscal'] = data['identificacaoFiscal'];
        this['email'] = data['email'];
        this['telefoneMovel'] = data['telefoneMovel'];
    }
}
export class InCadastrarConductorTipoAjuste {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['conductorAjusteType'] = data['conductorAjusteType'];
        this['conductorDescricao'] = data['conductorDescricao'];
        this['motivoTransacaoDescricao'] = data['motivoTransacaoDescricao'];
        this['tipoTransacaoId'] = data['tipoTransacaoId'];
    }
}
export class InCadastrarFavorito {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeConta'] = data['chaveDeConta'];
        this['identificacaoFiscal'] = data['identificacaoFiscal'];
        this['nomeCompleto'] = data['nomeCompleto'];
        this['apelido'] = data['apelido'];
        this['chaves'] = data['chaves'];
    }
}
export class InCadastrarIconeGrupoMcc {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['grupoMcc'] = data['grupoMcc'];
        this['icone'] = data['icone'];
    }
}
export class InCadastrarUnidadeOrganizacional {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['nome'] = data['nome'];
        this['pai'] = data['pai'];
        this['cnpj'] = data['cnpj'];
        this['razaoSocial'] = data['razaoSocial'];
        this['contatoNome'] = data['contatoNome'];
        this['dadosConta'] = data['dadosConta'];
        this['contatoTelefone'] = data['contatoTelefone'];
        this['contatoEmail'] = data['contatoEmail'];
        this['endereco'] = data['endereco'];
        this['processoGedId'] = data['processoGedId'];
        this['desativarMensageria'] = data['desativarMensageria'];
        this['autorizarAssinaturas'] = data['autorizarAssinaturas'];
    }
}
export class InCadastroConta {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeCliente'] = data['chaveDeCliente'];
        this['chaveDeProduto'] = data['chaveDeProduto'];
        this['tipoContaBancaria'] = data['tipoContaBancaria'];
        this['primeiroPin'] = data['primeiroPin'];
        this['descricao'] = data['descricao'];
        this['dataEncerramento'] = data['dataEncerramento'];
    }
}
export class InCalcularValorParcela {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeConta'] = data['chaveDeConta'];
        this['saldoDevedor'] = data['saldoDevedor'];
        this['valorAdicional'] = data['valorAdicional'];
    }
}
export class InCancelarAgendamentoPix {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeConta'] = data['chaveDeConta'];
        this['listaIdAgendamento'] = data['listaIdAgendamento'];
        this['autenticacao2FA'] = data['autenticacao2FA'];
        this['motivoCancelamento'] = data['motivoCancelamento'];
    }
}
export class InCancelarAgendamentoRecargaCelular {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeConta'] = data['chaveDeConta'];
        this['autenticacao2FA'] = data['autenticacao2FA'];
        this['idsAgendamentos'] = data['idsAgendamentos'];
    }
}
export class InCancelarContaCliente {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeConta'] = data['chaveDeConta'];
        this['motivoCancelamento'] = data['motivoCancelamento'];
        this['autenticacao2FA'] = data['autenticacao2FA'];
    }
}
export class InCancelarPortabilidade {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDePortabilidade'] = data['chaveDePortabilidade'];
        this['motivoCancelamento'] = data['motivoCancelamento'];
        this['complementoMotivoCancelamento'] = data['complementoMotivoCancelamento'];
        this['autenticacao2FA'] = data['autenticacao2FA'];
    }
}
export class InCancelarPropostasPendentesCliente {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeConta'] = data['chaveDeConta'];
    }
}
export class InCardComunicacaoAlterar {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['organizationUnitId'] = data['organizationUnitId'];
        this['mensagem'] = data['mensagem'];
        this['imagem'] = data['imagem'];
        this['link'] = data['link'];
        this['inicioVigencia'] = data['inicioVigencia'];
        this['fimVigencia'] = data['fimVigencia'];
        this['chaveDeCardComunicacao'] = data['chaveDeCardComunicacao'];
    }
}
export class InCardComunicacaoConsultar {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['organizationUnitId'] = data['organizationUnitId'];
        this['chaveDeCardComunicacao'] = data['chaveDeCardComunicacao'];
        this['dataHoraAtiva'] = data['dataHoraAtiva'];
        this['pagina'] = data['pagina'];
        this['porPagina'] = data['porPagina'];
    }
}
export class InCardComunicacaoCriar {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['organizationUnitId'] = data['organizationUnitId'];
        this['mensagem'] = data['mensagem'];
        this['imagem'] = data['imagem'];
        this['link'] = data['link'];
        this['inicioVigencia'] = data['inicioVigencia'];
        this['fimVigencia'] = data['fimVigencia'];
    }
}
export class InCardComunicacaoExcluir {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeCardComunicacao'] = data['chaveDeCardComunicacao'];
    }
}
export class InCargaMassiva {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['organizationUnitId'] = data['organizationUnitId'];
        this['arquivo'] = data['arquivo'];
        this['nomeArquivo'] = data['nomeArquivo'];
        this['chaveDeContaTesouraria'] = data['chaveDeContaTesouraria'];
    }
}
export class InCartaoGarantiaAditivo {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['valor'] = data['valor'];
    }
}
export class InCartaoGarantiaAssinarContrato {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['autenticacao2FA'] = data['autenticacao2FA'];
        this['ipAssinatura'] = data['ipAssinatura'];
    }
}
export class InCartaoGarantiaConfiguracao {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['valor'] = data['valor'];
        this['diaVencimentoFatura'] = data['diaVencimentoFatura'];
        this['debitoAutomatico'] = data['debitoAutomatico'];
    }
}
export class InCartaoGarantiaDataVencimento {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['diaVencimentoFatura'] = data['diaVencimentoFatura'];
    }
}
export class InCartaoGarantiaFaturaSimularPagamento {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['valor'] = data['valor'];
    }
}
export class InCartaoGarantiaPagamentoFatura {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['autenticacao2FA'] = data['autenticacao2FA'];
        this['simulacaoId'] = data['simulacaoId'];
    }
}
export class InCartaoGarantiaSimularPagamentoParcelado {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['valorEntrada'] = data['valorEntrada'];
        this['parcelamento'] = data['parcelamento'];
    }
}
export class InCartaoGarantiaSimularResgate {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['valor'] = data['valor'];
    }
}
export class InCartaoGarantiaSolicitarResgate {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['valorResgate'] = data['valorResgate'];
        this['autenticacao2FA'] = data['autenticacao2FA'];
    }
}
export class InCartaoOpaqueJson {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeCartao'] = data['chaveDeCartao'];
        this['autenticacao2FA'] = data['autenticacao2FA'];
        this['clientWalletAccountId'] = data['clientWalletAccountId'];
        this['clientDeviceId'] = data['clientDeviceId'];
        this['clientAppId'] = data['clientAppId'];
    }
}
export class InCartoesSubcontas {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeCliente'] = data['chaveDeCliente'];
        this['portadorId'] = data['portadorId'];
    }
}
export class InChamarMockCaf {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['senha'] = data['senha'];
        this['clienteId'] = data['clienteId'];
        this['portadorId'] = data['portadorId'];
    }
}
export class InChargeBack {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeConta'] = data['chaveDeConta'];
        this['transacaoId'] = data['transacaoId'];
        this['metodo'] = data['metodo'];
        this['detalhamento'] = data['detalhamento'];
        this['descricao'] = data['descricao'];
        this['arquivos'] = data['arquivos'];
    }
}
export class InCheckAgencia {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['codigoBanco'] = data['codigoBanco'];
        this['codigoAgencia'] = data['codigoAgencia'];
        this['codigoISPBBanco'] = data['codigoISPBBanco'];
    }
}
export class InCheckBanco {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['filtro'] = data['filtro'];
        this['pagina'] = data['pagina'];
        this['porPagina'] = data['porPagina'];
    }
}
export class InClienteDto {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['identificacaoFiscal'] = data['identificacaoFiscal'];
        this['organizationUnitId'] = data['organizationUnitId'];
    }
}
export class InClienteLinkDoc {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeCliente'] = data['chaveDeCliente'];
        this['url'] = data['url'];
    }
}
export class InClienteTermo {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeCliente'] = data['chaveDeCliente'];
        this['autenticacao2FA'] = data['autenticacao2FA'];
        this['ipAssinatura'] = data['ipAssinatura'];
        this['visualizarTermos'] = data['visualizarTermos'];
    }
}
export class InConFirmarVisualizacaoNotificacao {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeCliente'] = data['chaveDeCliente'];
        this['idNotificacoes'] = data['idNotificacoes'];
    }
}
export class InConfiguracoesPortador {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeConta'] = data['chaveDeConta'];
        this['valorCreditoEscolhido'] = data['valorCreditoEscolhido'];
        this['diaVencimentoFatura'] = data['diaVencimentoFatura'];
        this['debitoAutomatico'] = data['debitoAutomatico'];
        this['contactless'] = data['contactless'];
    }
}
export class InConfigurarContactless {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeCartao'] = data['chaveDeCartao'];
        this['permiteContactless'] = data['permiteContactless'];
    }
}
export class InConfigurarLimiteContactless {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeCartao'] = data['chaveDeCartao'];
        this['valorLimite'] = data['valorLimite'];
    }
}
export class InConfirmacaoRepasseLoteContratos {
    constructor(data = {}) {
        this['xAuth'] = data['xAuth'];
        this['loteId'] = data['loteId'];
        this['dataRepasse'] = data['dataRepasse'];
        this['valorRepassado'] = data['valorRepassado'];
    }
}
export class InConfirmarEmailCliente {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeCliente'] = data['chaveDeCliente'];
    }
}
export class InConsultaAssinaturas {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['organizationUnitId'] = data['organizationUnitId'];
    }
}
export class InConsultaCEP {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['cep'] = data['cep'];
    }
}
export class InConsultaContratosFgts {
    constructor(data = {}) {
        this['situacaoContrato'] = data['situacaoContrato'];
        this['dataInicio'] = data['dataInicio'];
        this['dataFim'] = data['dataFim'];
        this['ordenacao'] = data['ordenacao'];
        this['pagina'] = data['pagina'];
        this['porPagina'] = data['porPagina'];
        this['cancelarPropostasPendentes'] = data['cancelarPropostasPendentes'];
    }
}
export class InConsultaContratosFgtsBackOffice {
    constructor(data = {}) {
        this['contrato'] = data['contrato'];
        this['cpf'] = data['cpf'];
        this['situacaoContrato'] = data['situacaoContrato'];
        this['dataInicio'] = data['dataInicio'];
        this['dataFim'] = data['dataFim'];
        this['pagina'] = data['pagina'];
        this['porPagina'] = data['porPagina'];
    }
}
export class InConsultaContratosFgtsParceiro {
    constructor(data = {}) {
        this['dataInicio'] = data['dataInicio'];
        this['dataFim'] = data['dataFim'];
        this['cpf'] = data['cpf'];
        this['status'] = data['status'];
        this['ordenacao'] = data['ordenacao'];
        this['apartirDoItem'] = data['apartirDoItem'];
        this['itensPorPagina'] = data['itensPorPagina'];
    }
}
export class InConsultaExtratoDuplo {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['numeroConta'] = data['numeroConta'];
        this['dataInicio'] = data['dataInicio'];
        this['dataFim'] = data['dataFim'];
    }
}
export class InConsultaParametrosCVVDinamico {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['pagina'] = data['pagina'];
        this['porPagina'] = data['porPagina'];
    }
}
export class InConsultaProvedores {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeConta'] = data['chaveDeConta'];
        this['ddd'] = data['ddd'];
    }
}
export class InConsultaTesouraria {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeConta'] = data['chaveDeConta'];
    }
}
export class InConsultaTesteEmailCaf {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeCliente'] = data['chaveDeCliente'];
        this['email'] = data['email'];
        this['comando'] = data['comando'];
    }
}
export class InConsultaValoresProvedor {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeConta'] = data['chaveDeConta'];
        this['provedorId'] = data['provedorId'];
        this['ddd'] = data['ddd'];
    }
}
export class InConsultarAdquirenciaProduto {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeProduto'] = data['chaveDeProduto'];
    }
}
export class InConsultarAgendamentoBoleto {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeConta'] = data['chaveDeConta'];
        this['dataInicio'] = data['dataInicio'];
        this['dataFim'] = data['dataFim'];
        this['status'] = data['status'];
        this['pagina'] = data['pagina'];
        this['porPagina'] = data['porPagina'];
    }
}
export class InConsultarAgendamentoPix {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeConta'] = data['chaveDeConta'];
        this['dataInicial'] = data['dataInicial'];
        this['dataFinal'] = data['dataFinal'];
        this['status'] = data['status'];
        this['pagina'] = data['pagina'];
        this['porPagina'] = data['porPagina'];
    }
}
export class InConsultarAgendamentoTransferenciaInterna {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeConta'] = data['chaveDeConta'];
        this['dataDe'] = data['dataDe'];
        this['dataAte'] = data['dataAte'];
        this['status'] = data['status'];
        this['pagina'] = data['pagina'];
        this['porPagina'] = data['porPagina'];
    }
}
export class InConsultarBoletosDeposito {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeContaTesouraria'] = data['chaveDeContaTesouraria'];
        this['dataInicioVencimento'] = data['dataInicioVencimento'];
        this['dataFimVencimento'] = data['dataFimVencimento'];
        this['statusBoleto'] = data['statusBoleto'];
    }
}
export class InConsultarChavesFavorito {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeConta'] = data['chaveDeConta'];
        this['identificacaoFiscal'] = data['identificacaoFiscal'];
        this['filtroNomeApelido'] = data['filtroNomeApelido'];
        this['favoritoId'] = data['favoritoId'];
        this['tipoChaveFiltro'] = data['tipoChaveFiltro'];
        this['tipoOrdenacao'] = data['tipoOrdenacao'];
        this['pagina'] = data['pagina'];
        this['porPagina'] = data['porPagina'];
    }
}
export class InConsultarComprovante {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeConta'] = data['chaveDeConta'];
        this['dataInicial'] = data['dataInicial'];
        this['dataFinal'] = data['dataFinal'];
        this['ordem'] = data['ordem'];
    }
}
export class InConsultarComprovanteTransacao {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeConta'] = data['chaveDeConta'];
        this['transacaoId'] = data['transacaoId'];
        this['gerarPdf'] = data['gerarPdf'];
    }
}
export class InConsultarContaPorContaDigital {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['idContaDigital'] = data['idContaDigital'];
    }
}
export class InConsultarContaTesouraria {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['tipoOrdenacao'] = data['tipoOrdenacao'];
        this['pagina'] = data['pagina'];
        this['porPagina'] = data['porPagina'];
    }
}
export class InConsultarContratoClienteDetalhado {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeConta'] = data['chaveDeConta'];
        this['localizador'] = data['localizador'];
    }
}
export class InConsultarContratosClienteAtivosLiquidados {
    constructor(data = {}) {
        this['chaveDeConta'] = data['chaveDeConta'];
        this['dataInicial'] = data['dataInicial'];
        this['dataFinal'] = data['dataFinal'];
        this['statusContrato'] = data['statusContrato'];
        this['pagina'] = data['pagina'];
        this['porPagina'] = data['porPagina'];
    }
}
export class InConsultarConveniosPublicos {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['orgaoPublico'] = data['orgaoPublico'];
        this['nivelOrgaoPublico'] = data['nivelOrgaoPublico'];
        this['pagina'] = data['pagina'];
        this['porPagina'] = data['porPagina'];
        this['ordenacao'] = data['ordenacao'];
        this['somenteAtivos'] = data['somenteAtivos'];
    }
}
export class InConsultarDadosGerais {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeCliente'] = data['chaveDeCliente'];
        this['chaveDeConta'] = data['chaveDeConta'];
        this['chaveDeCartao'] = data['chaveDeCartao'];
        this['identificacaoFiscal'] = data['identificacaoFiscal'];
    }
}
export class InConsultarDiaUtil {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['diaAConsultar'] = data['diaAConsultar'];
    }
}
export class InConsultarEnderecoDeEntrega {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeCliente'] = data['chaveDeCliente'];
    }
}
export class InConsultarExtratoBeneficio {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeConta'] = data['chaveDeConta'];
        this['dataInicio'] = data['dataInicio'];
        this['dataFim'] = data['dataFim'];
        this['ultimosQuatroDigitosCartao'] = data['ultimosQuatroDigitosCartao'];
        this['tiposTransacao'] = data['tiposTransacao'];
        this['motivoTransacao'] = data['motivoTransacao'];
        this['tipoOrdenacao'] = data['tipoOrdenacao'];
        this['pagina'] = data['pagina'];
        this['porPagina'] = data['porPagina'];
    }
}
export class InConsultarExtratoCliente {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeConta'] = data['chaveDeConta'];
        this['dataInicial'] = data['dataInicial'];
        this['dataFinal'] = data['dataFinal'];
        this['statusContratoAntecipacao'] = data['statusContratoAntecipacao'];
        this['pagina'] = data['pagina'];
        this['porPagina'] = data['porPagina'];
    }
}
export class InConsultarExtratoCompleto {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeConta'] = data['chaveDeConta'];
        this['dataInicio'] = data['dataInicio'];
        this['dataFim'] = data['dataFim'];
        this['tiposTransacao'] = data['tiposTransacao'];
        this['tipoOrdenacao'] = data['tipoOrdenacao'];
        this['pagina'] = data['pagina'];
        this['porPagina'] = data['porPagina'];
        this['motivoTransacaoExtrato'] = data['motivoTransacaoExtrato'];
    }
}
export class InConsultarFavoritos {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeConta'] = data['chaveDeConta'];
        this['identificacaoFiscal'] = data['identificacaoFiscal'];
        this['tipoOrdenacao'] = data['tipoOrdenacao'];
        this['filtroNomeApelido'] = data['filtroNomeApelido'];
        this['pagina'] = data['pagina'];
        this['porPagina'] = data['porPagina'];
    }
}
export class InConsultarHistoricoSolicitacoes {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeCliente'] = data['chaveDeCliente'];
        this['pagina'] = data['pagina'];
        this['porPagina'] = data['porPagina'];
    }
}
export class InConsultarIconeGrupoMcc {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['grupoMcc'] = data['grupoMcc'];
    }
}
export class InConsultarInforRenegociacao {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeConta'] = data['chaveDeConta'];
    }
}
export class InConsultarLimitesCliente {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeCliente'] = data['chaveDeCliente'];
    }
}
export class InConsultarLoja {
    constructor(data = {}) {
        this['cnpj'] = data['cnpj'];
        this['apelido'] = data['apelido'];
        this['cidade'] = data['cidade'];
        this['pagina'] = data['pagina'];
        this['porPagina'] = data['porPagina'];
    }
}
export class InConsultarMccCartao {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeCartao'] = data['chaveDeCartao'];
    }
}
export class InConsultarMotivosTarifa {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['motivoTransacao'] = data['motivoTransacao'];
    }
}
export class InConsultarNotificacoes {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeCliente'] = data['chaveDeCliente'];
    }
}
export class InConsultarNotificacoesPaginada {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeCliente'] = data['chaveDeCliente'];
        this['pagina'] = data['pagina'];
        this['porPagina'] = data['porPagina'];
    }
}
export class InConsultarPorConductorAjusteType {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['conductorAjusteType'] = data['conductorAjusteType'];
    }
}
export class InConsultarPorConductorOperacaoType {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['conductorOperacaoType'] = data['conductorOperacaoType'];
    }
}
export class InConsultarPortabilidade {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDePortabilidade'] = data['chaveDePortabilidade'];
    }
}
export class InConsultarPosicaoClienteFilaOnboarding {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['organizationUnitId'] = data['organizationUnitId'];
        this['identificacaoFiscal'] = data['identificacaoFiscal'];
    }
}
export class InConsultarProduto {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['descricao'] = data['descricao'];
        this['ordenacao'] = data['ordenacao'];
        this['pagina'] = data['pagina'];
        this['porPagina'] = data['porPagina'];
    }
}
export class InConsultarServicos {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeConta'] = data['chaveDeConta'];
    }
}
export class InConsultarSolicitacoesClientesUltimasTres {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['organizationUnitId'] = data['organizationUnitId'];
        this['identificacaoFiscal'] = data['identificacaoFiscal'];
        this['tipoOperacaoLimiteClienteEnum'] = data['tipoOperacaoLimiteClienteEnum'];
    }
}
export class InConsultarSolicitacoesLimitesClientes {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['organizationUnitId'] = data['organizationUnitId'];
        this['chaveDeCliente'] = data['chaveDeCliente'];
        this['identificacaoFiscal'] = data['identificacaoFiscal'];
        this['nome'] = data['nome'];
        this['tipoOperacaoLimiteClienteEnum'] = data['tipoOperacaoLimiteClienteEnum'];
        this['pagina'] = data['pagina'];
        this['porPagina'] = data['porPagina'];
    }
}
export class InConsultarTarifaOperacao {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeConta'] = data['chaveDeConta'];
        this['motivoTransacao'] = data['motivoTransacao'];
        this['valorTransacao'] = data['valorTransacao'];
    }
}
export class InConsultarTarifas {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['organizationUnitId'] = data['organizationUnitId'];
        this['produtoId'] = data['produtoId'];
        this['motivoTransacao'] = data['motivoTransacao'];
        this['descricao'] = data['descricao'];
    }
}
export class InConsultarTarifasConta {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeConta'] = data['chaveDeConta'];
    }
}
export class InConsultarTedAgendada {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeConta'] = data['chaveDeConta'];
        this['dataInicial'] = data['dataInicial'];
        this['dataFinal'] = data['dataFinal'];
        this['statusTed'] = data['statusTed'];
        this['ordem'] = data['ordem'];
        this['pagina'] = data['pagina'];
        this['porPagina'] = data['porPagina'];
    }
}
export class InConsultarUnidadeFilha {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['idUnidadeOrganizacionalFilha'] = data['idUnidadeOrganizacionalFilha'];
        this['idUnidadeOrganizacionalPai'] = data['idUnidadeOrganizacionalPai'];
    }
}
export class InConsultarUnidadeOrganizacional {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['razaoSocial'] = data['razaoSocial'];
    }
}
export class InConsultarUnidadeOrganizacionalComFiltros {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['tiposDeConta'] = data['tiposDeConta'];
    }
}
export class InConsultarUsuario {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['organizationUnitId'] = data['organizationUnitId'];
        this['identificacaoFiscal'] = data['identificacaoFiscal'];
        this['email'] = data['email'];
    }
}
export class InConsultarVagasFilaOnboarding {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['organizationUnitId'] = data['organizationUnitId'];
    }
}
export class InConsultarValidacaoFaceMatch {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['valor'] = data['valor'];
    }
}
export class InConsultasAgendamentos {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['dataDe'] = data['dataDe'];
        this['dataAte'] = data['dataAte'];
        this['chaveDeConta'] = data['chaveDeConta'];
        this['tiposAgendamento'] = data['tiposAgendamento'];
        this['tipoOrdenacao'] = data['tipoOrdenacao'];
        this['pagina'] = data['pagina'];
        this['porPagina'] = data['porPagina'];
    }
}
export class InContaCorrente {
    constructor(data = {}) {
        this['banco'] = data['banco'];
        this['agencia'] = data['agencia'];
        this['conta'] = data['conta'];
    }
}
export class InContratoMobile {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['tokenAssinatura'] = data['tokenAssinatura'];
        this['numeroNotaFiscal'] = data['numeroNotaFiscal'];
        this['serieNotaFiscal'] = data['serieNotaFiscal'];
        this['aparelho'] = data['aparelho'];
        this['chaveDeCliente'] = data['chaveDeCliente'];
    }
}
export class InControleLimitesCartao {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeCartao'] = data['chaveDeCartao'];
    }
}
export class InCreditoMassivo {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['organizationUnitId'] = data['organizationUnitId'];
        this['arquivo'] = data['arquivo'];
        this['nomeArquivo'] = data['nomeArquivo'];
        this['motivo'] = data['motivo'];
    }
}
export class InCriacaoContaCompletaDto {
    constructor(data = {}) {
        this['chave'] = data['chave'];
        this['senha'] = data['senha'];
    }
}
export class InCriarBloqueio {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['organizationUnitId'] = data['organizationUnitId'];
        this['produtoId'] = data['produtoId'];
        this['descricao'] = data['descricao'];
        this['motivoTransacao'] = data['motivoTransacao'];
        this['periodoLimite'] = data['periodoLimite'];
        this['tipoContagemLimite'] = data['tipoContagemLimite'];
        this['limiteInferior'] = data['limiteInferior'];
        this['limiteSuperior'] = data['limiteSuperior'];
        this['tipoPessoa'] = data['tipoPessoa'];
        this['aplicarLimiteAFilhos'] = data['aplicarLimiteAFilhos'];
    }
}
export class InCriarCliente {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['nome'] = data['nome'];
        this['apelido'] = data['apelido'];
        this['identificacaoFiscal'] = data['identificacaoFiscal'];
        this['organizationUnitId'] = data['organizationUnitId'];
        this['email'] = data['email'];
        this['dataNascimento'] = data['dataNascimento'];
        this['nomeMae'] = data['nomeMae'];
        this['nomePai'] = data['nomePai'];
        this['nacionalidade'] = data['nacionalidade'];
        this['sexo'] = data['sexo'];
        this['endereco'] = data['endereco'];
        this['rg'] = data['rg'];
        this['telefoneFixo'] = data['telefoneFixo'];
        this['telefoneMovel'] = data['telefoneMovel'];
        this['pessoaPoliticamenteExposta'] = data['pessoaPoliticamenteExposta'];
        this['rendaMensal'] = data['rendaMensal'];
        this['nomeEmpresa'] = data['nomeEmpresa'];
    }
}
export class InCriarClientePJ {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['razaoSocial'] = data['razaoSocial'];
        this['nomeFantasia'] = data['nomeFantasia'];
        this['identificacaoFiscal'] = data['identificacaoFiscal'];
        this['organizationUnitId'] = data['organizationUnitId'];
        this['email'] = data['email'];
        this['naturezaJuridica'] = data['naturezaJuridica'];
        this['porte'] = data['porte'];
        this['grupo'] = data['grupo'];
        this['cnae'] = data['cnae'];
        this['cnaeDescricao'] = data['cnaeDescricao'];
        this['capitalSocial'] = data['capitalSocial'];
        this['origemCapitalSocial'] = data['origemCapitalSocial'];
        this['dataInicioAtividade'] = data['dataInicioAtividade'];
        this['endereco'] = data['endereco'];
        this['telefoneFixo'] = data['telefoneFixo'];
        this['telefoneMovel'] = data['telefoneMovel'];
    }
}
export class InCriarConfigContactless {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeCartao'] = data['chaveDeCartao'];
    }
}
export class InCriarMotivoTarifa {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['motivoTransacaoOrigem'] = data['motivoTransacaoOrigem'];
        this['motivoTransacaoTarifaALancar'] = data['motivoTransacaoTarifaALancar'];
    }
}
export class InCriarParametroGenerico {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeCliente'] = data['chaveDeCliente'];
        this['parametro'] = data['parametro'];
        this['valor'] = data['valor'];
    }
}
export class InCriarPortador {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeCliente'] = data['chaveDeCliente'];
        this['vinculoCliente'] = data['vinculoCliente'];
        this['identificacaoFiscal'] = data['identificacaoFiscal'];
        this['nome'] = data['nome'];
        this['nomeMae'] = data['nomeMae'];
        this['email'] = data['email'];
        this['dataNascimento'] = data['dataNascimento'];
        this['telefoneMovel'] = data['telefoneMovel'];
        this['endereco'] = data['endereco'];
        this['nomeEmpresa'] = data['nomeEmpresa'];
    }
}
export class InCriarProduto {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['descricao'] = data['descricao'];
        this['tipoConta'] = data['tipoConta'];
        this['tipoSegmento'] = data['tipoSegmento'];
        this['idProdutoProcessadora'] = data['idProdutoProcessadora'];
        this['idOrigemComercialProcessadora'] = data['idOrigemComercialProcessadora'];
        this['autorizacaoInterna'] = data['autorizacaoInterna'];
    }
}
export class InCriarQuadroSocietarioAdministrador {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeCliente'] = data['chaveDeCliente'];
        this['societarios'] = data['societarios'];
    }
}
export class InCriarTarifa {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['organizationUnitId'] = data['organizationUnitId'];
        this['produtoId'] = data['produtoId'];
        this['descricao'] = data['descricao'];
        this['motivoTransacao'] = data['motivoTransacao'];
        this['motivoTransacaoTarifa'] = data['motivoTransacaoTarifa'];
        this['periodoLimite'] = data['periodoLimite'];
        this['tipoContagemLimite'] = data['tipoContagemLimite'];
        this['limiteInferior'] = data['limiteInferior'];
        this['limiteSuperior'] = data['limiteSuperior'];
        this['tipoPessoa'] = data['tipoPessoa'];
        this['aplicarLimiteAFilhos'] = data['aplicarLimiteAFilhos'];
        this['tipoValor'] = data['tipoValor'];
        this['valorTarifa'] = data['valorTarifa'];
        this['localDebitar'] = data['localDebitar'];
    }
}
export class InCriarToken {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['organizationUnitId'] = data['organizationUnitId'];
        this['chaveDeCliente'] = data['chaveDeCliente'];
    }
}
export class InCriarUsuario {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['organizationUnitId'] = data['organizationUnitId'];
        this['nome'] = data['nome'];
        this['sobrenome'] = data['sobrenome'];
        this['identificacaoFiscal'] = data['identificacaoFiscal'];
        this['email'] = data['email'];
        this['enviarPassword'] = data['enviarPassword'];
        this['senha'] = data['senha'];
        this['apelido'] = data['apelido'];
        this['celular'] = data['celular'];
    }
}
export class InCriarUsuarioPj {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['organizationUnitId'] = data['organizationUnitId'];
        this['razaoSocial'] = data['razaoSocial'];
        this['identificacaoFiscal'] = data['identificacaoFiscal'];
        this['email'] = data['email'];
        this['enviarPassword'] = data['enviarPassword'];
        this['senha'] = data['senha'];
    }
}
export class InDadosBancarios {
    constructor(data = {}) {
        this['banco'] = data['banco'];
        this['agencia'] = data['agencia'];
        this['agenciaDac'] = data['agenciaDac'];
        this['conta'] = data['conta'];
        this['contaDac'] = data['contaDac'];
        this['tipoConta'] = data['tipoConta'];
    }
}
export class InDadosCartao {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDoCartao'] = data['chaveDoCartao'];
        this['autenticacao2FA'] = data['autenticacao2FA'];
    }
}
export class InDadosCartoes {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeConta'] = data['chaveDeConta'];
    }
}
export class InDadosCelular {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['autenticacao2FA'] = data['autenticacao2FA'];
        this['chaveDeConta'] = data['chaveDeConta'];
        this['telefoneMovel'] = data['telefoneMovel'];
    }
}
export class InDadosCliente {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['identificacaoFiscal'] = data['identificacaoFiscal'];
        this['nomeCompleto'] = data['nomeCompleto'];
        this['email'] = data['email'];
        this['numeroTelefone'] = data['numeroTelefone'];
        this['profissao'] = data['profissao'];
        this['rendaMensal'] = data['rendaMensal'];
        this['tipoContratacao'] = data['tipoContratacao'];
        this['estadoCivil'] = data['estadoCivil'];
        this['genero'] = data['genero'];
        this['tempoServicoEmMeses'] = data['tempoServicoEmMeses'];
        this['dataNascimento'] = data['dataNascimento'];
        this['valorSolicitacao'] = data['valorSolicitacao'];
        this['valorParcela'] = data['valorParcela'];
        this['quantidadeParcelas'] = data['quantidadeParcelas'];
    }
}
export class InDadosEmail {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['autenticacao2FA'] = data['autenticacao2FA'];
        this['chaveDeConta'] = data['chaveDeConta'];
        this['emailDto'] = data['emailDto'];
    }
}
export class InDadosEndCorrespondencia {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeCliente'] = data['chaveDeCliente'];
        this['enderecoCorrespondencia'] = data['enderecoCorrespondencia'];
    }
}
export class InDadosEndereco {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['autenticacao2FA'] = data['autenticacao2FA'];
        this['chaveDeConta'] = data['chaveDeConta'];
        this['endereco'] = data['endereco'];
    }
}
export class InDadosParametroCVVDinamico {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['minutos'] = data['minutos'];
    }
}
export class InDadosSoftCliente {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeCliente'] = data['chaveDeCliente'];
        this['senha'] = data['senha'];
    }
}
export class InDefinirControleLimitesCartao {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeCartao'] = data['chaveDeCartao'];
        this['limiteDiario'] = data['limiteDiario'];
        this['multiplicadorSemanal'] = data['multiplicadorSemanal'];
        this['multiplicadorMensal'] = data['multiplicadorMensal'];
        this['multiplicadorCiclo'] = data['multiplicadorCiclo'];
    }
}
export class InDesbloquearCartao {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['autenticacao2FA'] = data['autenticacao2FA'];
        this['chaveDeCartao'] = data['chaveDeCartao'];
    }
}
export class InDesbloquearCartaoCliente {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeCartao'] = data['chaveDeCartao'];
    }
}
export class InDesbloquearCartaoEmissao {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeCartao'] = data['chaveDeCartao'];
        this['cvv'] = data['cvv'];
        this['dataValidade'] = data['dataValidade'];
    }
}
export class InDesbloquearCartaoEmissaoSyncSenha {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['autenticacao2FA'] = data['autenticacao2FA'];
        this['chaveDeCartao'] = data['chaveDeCartao'];
        this['cvv'] = data['cvv'];
        this['dataValidade'] = data['dataValidade'];
    }
}
export class InDesbloquearCartaoSemPin {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['motivoTransacoesEnum'] = data['motivoTransacoesEnum'];
        this['chaveDeCartao'] = data['chaveDeCartao'];
    }
}
export class InDesbloquearDeviceMotoSafe {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeConta'] = data['chaveDeConta'];
    }
}
export class InDestinatarioTed {
    constructor(data = {}) {
        this['nome'] = data['nome'];
        this['identificacaoFiscal'] = data['identificacaoFiscal'];
        this['banco'] = data['banco'];
        this['agencia'] = data['agencia'];
        this['numero'] = data['numero'];
        this['tipoConta'] = data['tipoConta'];
    }
}
export class InDetalheFaturaCreditoClean {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeConta'] = data['chaveDeConta'];
        this['idFatura'] = data['idFatura'];
    }
}
export class InDetalheFaturaFuturaCreditoClean {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeConta'] = data['chaveDeConta'];
        this['idFaturaFutura'] = data['idFaturaFutura'];
    }
}
export class InDetalhesChargeBack {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeConta'] = data['chaveDeConta'];
        this['transacaoId'] = data['transacaoId'];
    }
}
export class InDetalhesExtrato {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['idTransacao'] = data['idTransacao'];
        this['chaveDeConta'] = data['chaveDeConta'];
    }
}
export class InDocumento {
    constructor(data = {}) {
        this['tipo'] = data['tipo'];
        this['numero'] = data['numero'];
        this['dataEmissao'] = data['dataEmissao'];
        this['emissor'] = data['emissor'];
        this['uf'] = data['uf'];
        this['organizationUnitId'] = data['organizationUnitId'];
    }
}
export class InEfetivarProposta {
    constructor(data = {}) {
        this['chaveDeCliente'] = data['chaveDeCliente'];
        this['organizationUnitId'] = data['organizationUnitId'];
        this['token'] = data['token'];
    }
}
export class InEfetuarAgendamentoBoleto {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['autenticacao2FA'] = data['autenticacao2FA'];
        this['linhaDigitavel'] = data['linhaDigitavel'];
        this['codigoDeBarras'] = data['codigoDeBarras'];
        this['chaveDeConta'] = data['chaveDeConta'];
        this['dataPagamento'] = data['dataPagamento'];
    }
}
export class InEfetuarAgendamentoTed {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeConta'] = data['chaveDeConta'];
        this['autenticacao2FA'] = data['autenticacao2FA'];
        this['contaDestino'] = data['contaDestino'];
        this['valor'] = data['valor'];
        this['dataAgendamento'] = data['dataAgendamento'];
        this['numeroRecorrencias'] = data['numeroRecorrencias'];
    }
}
export class InEfetuarAgendamentoTransferenciaEntreArbi {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveContaOrigem'] = data['chaveContaOrigem'];
        this['autenticacao2FA'] = data['autenticacao2FA'];
        this['agenciaDestino'] = data['agenciaDestino'];
        this['contaDestino'] = data['contaDestino'];
        this['valor'] = data['valor'];
        this['dataAgendamento'] = data['dataAgendamento'];
        this['numeroOcorrencias'] = data['numeroOcorrencias'];
    }
}
export class InEfetuarCancelamentoBoleto {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['autenticacao2FA'] = data['autenticacao2FA'];
        this['chaveDeConta'] = data['chaveDeConta'];
        this['idAgendamentos'] = data['idAgendamentos'];
        this['motivoCancelamento'] = data['motivoCancelamento'];
    }
}
export class InEfetuarCancelamentoTransferenciaInterna {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['autenticacao2FA'] = data['autenticacao2FA'];
        this['chaveDeConta'] = data['chaveDeConta'];
        this['idAgendamentos'] = data['idAgendamentos'];
        this['motivoCancelamento'] = data['motivoCancelamento'];
    }
}
export class InEfetuarPagamentoBoletoNovo {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeConta'] = data['chaveDeConta'];
        this['autenticacao2FA'] = data['autenticacao2FA'];
        this['linhaDigitavel'] = data['linhaDigitavel'];
        this['codigoDeBarras'] = data['codigoDeBarras'];
        this['valor'] = data['valor'];
    }
}
export class InEfetuarPagamentoDimoQrCode {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['qrCode'] = data['qrCode'];
        this['chaveDeConta'] = data['chaveDeConta'];
        this['autenticacao2FA'] = data['autenticacao2FA'];
    }
}
export class InEfetuarTed {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeConta'] = data['chaveDeConta'];
        this['autenticacao2FA'] = data['autenticacao2FA'];
        this['contaDestino'] = data['contaDestino'];
        this['valor'] = data['valor'];
    }
}
export class InEfetuarTransferenciaArbi2Arbi {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['autenticacao2FA'] = data['autenticacao2FA'];
        this['chaveContaOrigem'] = data['chaveContaOrigem'];
        this['identificacaoFiscalDestinatario'] = data['identificacaoFiscalDestinatario'];
        this['nomeDestinatario'] = data['nomeDestinatario'];
        this['valor'] = data['valor'];
        this['contaDestino'] = data['contaDestino'];
    }
}
export class InEfetuarTransferenciaInterna {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveContaOrigem'] = data['chaveContaOrigem'];
        this['autenticacao2FA'] = data['autenticacao2FA'];
        this['chaveContaDestino'] = data['chaveContaDestino'];
        this['valor'] = data['valor'];
    }
}
export class InEfetuarTransferenciaParaContaPai {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['autenticacao2FA'] = data['autenticacao2FA'];
        this['chaveDeConta'] = data['chaveDeConta'];
        this['chaveDeSubConta'] = data['chaveDeSubConta'];
        this['valor'] = data['valor'];
    }
}
export class InEmailTemplate {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['idUnidadeOrganizacional'] = data['idUnidadeOrganizacional'];
        this['header'] = data['header'];
        this['footer'] = data['footer'];
    }
}
export class InEmitenteProposta {
    constructor(data = {}) {
        this['cpf'] = data['cpf'];
        this['nome'] = data['nome'];
        this['sobrenome'] = data['sobrenome'];
        this['email'] = data['email'];
        this['telefoneCelular'] = data['telefoneCelular'];
        this['sexo'] = data['sexo'];
        this['nacionalidade'] = data['nacionalidade'];
        this['naturalidade'] = data['naturalidade'];
        this['dependentes'] = data['dependentes'];
        this['dataNascimento'] = data['dataNascimento'];
        this['nomeMae'] = data['nomeMae'];
        this['nomePai'] = data['nomePai'];
        this['endereco'] = data['endereco'];
        this['documento'] = data['documento'];
        this['dadosBancarios'] = data['dadosBancarios'];
    }
}
export class InEmitenteRealizarProposta {
    constructor(data = {}) {
        this['cpf'] = data['cpf'];
        this['nome'] = data['nome'];
        this['sobrenome'] = data['sobrenome'];
        this['email'] = data['email'];
        this['telefoneCelular'] = data['telefoneCelular'];
        this['sexo'] = data['sexo'];
        this['nacionalidade'] = data['nacionalidade'];
        this['naturalidade'] = data['naturalidade'];
        this['dependentes'] = data['dependentes'];
        this['dataNascimento'] = data['dataNascimento'];
        this['nomeMae'] = data['nomeMae'];
        this['nomePai'] = data['nomePai'];
        this['endereco'] = data['endereco'];
        this['documento'] = data['documento'];
        this['dadosBancarios'] = data['dadosBancarios'];
        this['chaveDeCliente'] = data['chaveDeCliente'];
    }
}
export class InEncerrarAssinaturas {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['organizationUnitId'] = data['organizationUnitId'];
        this['listaDeIdsParaExclusao'] = data['listaDeIdsParaExclusao'];
    }
}
export class InEndereco {
    constructor(data = {}) {
        this['cep'] = data['cep'];
        this['logradouro'] = data['logradouro'];
        this['numero'] = data['numero'];
        this['complemento'] = data['complemento'];
        this['bairro'] = data['bairro'];
        this['cidade'] = data['cidade'];
        this['uf'] = data['uf'];
        this['pais'] = data['pais'];
        this['residenciaTempo'] = data['residenciaTempo'];
    }
}
export class InEnviarEmailTeste {
    constructor(data = {}) {
        this['chaveDeCliente'] = data['chaveDeCliente'];
        this['email'] = data['email'];
        this['tipoEmail'] = data['tipoEmail'];
    }
}
export class InEnviarInformeDeRendimentos {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeConta'] = data['chaveDeConta'];
        this['anoBase'] = data['anoBase'];
    }
}
export class InEstornarTransacao {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['transacaoId'] = data['transacaoId'];
        this['autenticacao2FA'] = data['autenticacao2FA'];
    }
}
export class InExcluiDispositivo {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeConta'] = data['chaveDeConta'];
        this['deviceId'] = data['deviceId'];
    }
}
export class InExcluirFavorito {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeConta'] = data['chaveDeConta'];
        this['identificacaoFiscal'] = data['identificacaoFiscal'];
        this['favoritoId'] = data['favoritoId'];
        this['chaveFavoritoId'] = data['chaveFavoritoId'];
    }
}
export class InExcluirIconeGrupoMcc {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['grupoMcc'] = data['grupoMcc'];
    }
}
export class InExcluirMotivoTarifa {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['motivoTarifaId'] = data['motivoTarifaId'];
    }
}
export class InExcluirParametrosOnBoardCliente {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['parametroId'] = data['parametroId'];
        this['identificacaoFiscal'] = data['identificacaoFiscal'];
        this['unidadeOrganizacional'] = data['unidadeOrganizacional'];
    }
}
export class InExcluirProduto {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['idProduto'] = data['idProduto'];
    }
}
export class InExcluirSocio {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeSocio'] = data['chaveDeSocio'];
    }
}
export class InExcluirTedAgendada {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeConta'] = data['chaveDeConta'];
        this['listaIdAgendamento'] = data['listaIdAgendamento'];
        this['autenticacao2FA'] = data['autenticacao2FA'];
    }
}
export class InExcluirTodosFavoritos {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeConta'] = data['chaveDeConta'];
        this['identificacaoFiscal'] = data['identificacaoFiscal'];
    }
}
export class InExclusaoRelacaoProdutoUO {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['produtoId'] = data['produtoId'];
        this['organizationUnitId'] = data['organizationUnitId'];
    }
}
export class InExtrairOcrDocumento {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeCliente'] = data['chaveDeCliente'];
        this['tipoDocumento'] = data['tipoDocumento'];
    }
}
export class InExtratoCreditoClean {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeConta'] = data['chaveDeConta'];
        this['dataInicial'] = data['dataInicial'];
        this['dataFinal'] = data['dataFinal'];
        this['tiposTransacao'] = data['tiposTransacao'];
        this['tipoOrdenacao'] = data['tipoOrdenacao'];
        this['pagina'] = data['pagina'];
        this['porPagina'] = data['porPagina'];
    }
}
export class InExtratoTesouraria {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeConta'] = data['chaveDeConta'];
        this['dataInicial'] = data['dataInicial'];
        this['dataFinal'] = data['dataFinal'];
        this['tiposTransacao'] = data['tiposTransacao'];
        this['ordenacao'] = data['ordenacao'];
        this['pagina'] = data['pagina'];
    }
}
export class InFinalizarSubconta {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeConta'] = data['chaveDeConta'];
        this['autenticacao2FA'] = data['autenticacao2FA'];
    }
}
export class InGeraLinkSenha {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['identificacaoFiscal'] = data['identificacaoFiscal'];
        this['organizationUnitId'] = data['organizationUnitId'];
    }
}
export class InGerar2FAEdicaoCelularConta {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeConta'] = data['chaveDeConta'];
        this['autenticacao2FA'] = data['autenticacao2FA'];
        this['telefone'] = data['telefone'];
    }
}
export class InGerar2FAEdicaoEmailConta {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeConta'] = data['chaveDeConta'];
        this['autenticacao2FA'] = data['autenticacao2FA'];
        this['email'] = data['email'];
    }
}
export class InGerarBoletoDeposito {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeContaTesouraria'] = data['chaveDeContaTesouraria'];
        this['dataVencimento'] = data['dataVencimento'];
        this['valor'] = data['valor'];
        this['gerarImpressao'] = data['gerarImpressao'];
    }
}
export class InGerarBoletoFatura {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeLimite'] = data['chaveDeLimite'];
        this['identificacaoFiscal'] = data['identificacaoFiscal'];
        this['valor'] = data['valor'];
        this['dataVencimento'] = data['dataVencimento'];
        this['agencia'] = data['agencia'];
        this['numConta'] = data['numConta'];
        this['numContaHeader'] = data['numContaHeader'];
    }
}
export class InGerarLinkOnboardingCliente {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeCliente'] = data['chaveDeCliente'];
        this['origem'] = data['origem'];
    }
}
export class InIdUnidadeOrganizacional {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['idUnidadeOrganizacional'] = data['idUnidadeOrganizacional'];
    }
}
export class InImportarAlteracaoGrupoMccCartoes {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['arquivo'] = data['arquivo'];
        this['nomeArquivo'] = data['nomeArquivo'];
        this['gruposMcc'] = data['gruposMcc'];
    }
}
export class InImportarEndCorrespondencia {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['organizationUnitId'] = data['organizationUnitId'];
        this['chaveDeCliente'] = data['chaveDeCliente'];
    }
}
export class InInformacoesUsoCreditoClean {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeConta'] = data['chaveDeConta'];
    }
}
export class InLancamentosFuturos {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['dataDe'] = data['dataDe'];
        this['dataAte'] = data['dataAte'];
        this['chaveDeConta'] = data['chaveDeConta'];
        this['tiposAgendamento'] = data['tiposAgendamento'];
        this['tipoOrdenacao'] = data['tipoOrdenacao'];
        this['pagina'] = data['pagina'];
        this['porPagina'] = data['porPagina'];
    }
}
export class InLinkContaEmail {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeConta'] = data['chaveDeConta'];
        this['emailDto'] = data['emailDto'];
    }
}
export class InLinkEmail {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['emailDto'] = data['emailDto'];
        this['chaveDeCliente'] = data['chaveDeCliente'];
    }
}
export class InListaContas {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeCliente'] = data['chaveDeCliente'];
        this['listarSubcontas'] = data['listarSubcontas'];
    }
}
export class InListaProdutosClienteUo {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeCliente'] = data['chaveDeCliente'];
        this['organizationUnitId'] = data['organizationUnitId'];
    }
}
export class InListaProdutosUo {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['organizationUnitId'] = data['organizationUnitId'];
        this['tipoConta'] = data['tipoConta'];
    }
}
export class InListaSubContas {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeContaPai'] = data['chaveDeContaPai'];
        this['statusConta'] = data['statusConta'];
        this['pagina'] = data['pagina'];
        this['porPagina'] = data['porPagina'];
    }
}
export class InListarAgendamentosRecargaCelular {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeConta'] = data['chaveDeConta'];
        this['dataDe'] = data['dataDe'];
        this['dataAte'] = data['dataAte'];
        this['status'] = data['status'];
        this['pagina'] = data['pagina'];
        this['porPagina'] = data['porPagina'];
    }
}
export class InListarAlteracoesGrupoMccCartoes {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['inicio'] = data['inicio'];
        this['fim'] = data['fim'];
        this['status'] = data['status'];
        this['idProduto'] = data['idProduto'];
        this['pagina'] = data['pagina'];
        this['porPagina'] = data['porPagina'];
    }
}
export class InListarAtmsProximos {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeCliente'] = data['chaveDeCliente'];
        this['latitude'] = data['latitude'];
        this['longitude'] = data['longitude'];
        this['limite'] = data['limite'];
    }
}
export class InListarBoletos {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeConta'] = data['chaveDeConta'];
        this['dataInicio'] = data['dataInicio'];
        this['dataFim'] = data['dataFim'];
    }
}
export class InListarClientes {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeCliente'] = data['chaveDeCliente'];
        this['identificacaoFiscal'] = data['identificacaoFiscal'];
        this['nome'] = data['nome'];
        this['organizationUnitId'] = data['organizationUnitId'];
        this['statusCadastroCliente'] = data['statusCadastroCliente'];
        this['tipoIdentificacaoFiscal'] = data['tipoIdentificacaoFiscal'];
        this['pagina'] = data['pagina'];
        this['porPagina'] = data['porPagina'];
    }
}
export class InListarClientesBackoffice {
    constructor(data = {}) {
        this['chaveDeCliente'] = data['chaveDeCliente'];
        this['identificacaoFiscal'] = data['identificacaoFiscal'];
        this['nome'] = data['nome'];
        this['organizationUnitId'] = data['organizationUnitId'];
        this['listaStatusCadastroCliente'] = data['listaStatusCadastroCliente'];
        this['tipoIdentificacaoFiscal'] = data['tipoIdentificacaoFiscal'];
        this['pagina'] = data['pagina'];
        this['porPagina'] = data['porPagina'];
    }
}
export class InListarClientesResumido {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['dataInicial'] = data['dataInicial'];
        this['dataFinal'] = data['dataFinal'];
        this['organizationUnitId'] = data['organizationUnitId'];
        this['statusCliente'] = data['statusCliente'];
        this['pagina'] = data['pagina'];
        this['porPagina'] = data['porPagina'];
    }
}
export class InListarFaturas {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeConta'] = data['chaveDeConta'];
        this['dataInicio'] = data['dataInicio'];
        this['dataFim'] = data['dataFim'];
    }
}
export class InListarFaturasFuturas {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeConta'] = data['chaveDeConta'];
    }
}
export class InListarFeatureFlags {
    constructor(data = {}) {
        this['nome'] = data['nome'];
    }
}
export class InListarImportacoesClientesBS {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['inicio'] = data['inicio'];
        this['fim'] = data['fim'];
        this['status'] = data['status'];
        this['organizationUnitId'] = data['organizationUnitId'];
        this['tipoConta'] = data['tipoConta'];
        this['pagina'] = data['pagina'];
        this['porPagina'] = data['porPagina'];
    }
}
export class InListarPortabilidade {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeConta'] = data['chaveDeConta'];
        this['pagina'] = data['pagina'];
        this['porPagina'] = data['porPagina'];
        this['tipoOrdenacao'] = data['tipoOrdenacao'];
    }
}
export class InListarPortadores {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeCliente'] = data['chaveDeCliente'];
    }
}
export class InListarProcessamentoArquivos {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['inicio'] = data['inicio'];
        this['fim'] = data['fim'];
        this['status'] = data['status'];
        this['organizationUnitId'] = data['organizationUnitId'];
        this['motivoTransacao'] = data['motivoTransacao'];
        this['pagina'] = data['pagina'];
        this['porPagina'] = data['porPagina'];
        this['tipoConta'] = data['tipoConta'];
    }
}
export class InListarSubContasPortador {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['portadorId'] = data['portadorId'];
        this['pagina'] = data['pagina'];
        this['porPagina'] = data['porPagina'];
    }
}
export class InMargemDisponivelAntecipacao {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeConta'] = data['chaveDeConta'];
    }
}
export class InMigrarClienteUo {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeCliente'] = data['chaveDeCliente'];
        this['organizationUnitId'] = data['organizationUnitId'];
        this['motivo'] = data['motivo'];
    }
}
export class InMigrarProduto {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeConta'] = data['chaveDeConta'];
        this['chaveDeProdutoNovo'] = data['chaveDeProdutoNovo'];
    }
}
export class InModificarTEDAgendada {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['idAgendamento'] = data['idAgendamento'];
        this['chaveDeConta'] = data['chaveDeConta'];
        this['autenticacao2FA'] = data['autenticacao2FA'];
        this['novaDataAgendamento'] = data['novaDataAgendamento'];
        this['novoValor'] = data['novoValor'];
    }
}
export class InMovimentarSaldo {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeContaTesouraria'] = data['chaveDeContaTesouraria'];
        this['valor'] = data['valor'];
    }
}
export class InNotificacaoKYC {
    constructor(data = {}) {
        this['inscricao'] = data['inscricao'];
        this['nome'] = data['nome'];
        this['situacao'] = data['situacao'];
        this['idRequisicaoArbi'] = data['idRequisicaoArbi'];
        this['nroConta'] = data['nroConta'];
    }
}
export class InNotificarAvisoFechamentoFatura {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeLimite'] = data['chaveDeLimite'];
        this['identificacaoFiscal'] = data['identificacaoFiscal'];
    }
}
export class InNotificarCompraCartao {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['idCartaoProcessadora'] = data['idCartaoProcessadora'];
        this['idTransacaoProcessadora'] = data['idTransacaoProcessadora'];
        this['dataCompra'] = data['dataCompra'];
        this['codigoMoeda'] = data['codigoMoeda'];
        this['codigoMcc'] = data['codigoMcc'];
        this['descricaoMcc'] = data['descricaoMcc'];
        this['nomeEstabelecimento'] = data['nomeEstabelecimento'];
        this['cidadeEstabelecimento'] = data['cidadeEstabelecimento'];
        this['paisEstabelecimento'] = data['paisEstabelecimento'];
        this['tipoTransacaoReferencia'] = data['tipoTransacaoReferencia'];
        this['numeroReferencia'] = data['numeroReferencia'];
        this['iof'] = data['iof'];
        this['porcentagemTaxa'] = data['porcentagemTaxa'];
        this['totalTaxa'] = data['totalTaxa'];
        this['valorTaxa'] = data['valorTaxa'];
        this['valor'] = data['valor'];
        this['exchangeRate'] = data['exchangeRate'];
        this['valorComTaxa'] = data['valorComTaxa'];
        this['nsu'] = data['nsu'];
        this['codigoAutorizacao'] = data['codigoAutorizacao'];
        this['identificadorTerminal'] = data['identificadorTerminal'];
        this['tipoTransacao'] = data['tipoTransacao'];
        this['statusId'] = data['statusId'];
        this['statusDescricao'] = data['statusDescricao'];
        this['pedidoId'] = data['pedidoId'];
        this['loteId'] = data['loteId'];
    }
}
export class InNotificarFechamentoFatura {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeLimite'] = data['chaveDeLimite'];
        this['identificacaoFiscal'] = data['identificacaoFiscal'];
        this['valor'] = data['valor'];
        this['boleto'] = data['boleto'];
        this['relatorio'] = data['relatorio'];
    }
}
export class InNotificarPagamentoCartao {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['idContaProcessadora'] = data['idContaProcessadora'];
        this['idTransacaoProcessadora'] = data['idTransacaoProcessadora'];
        this['dataPagamento'] = data['dataPagamento'];
        this['nomeEstabelecimento'] = data['nomeEstabelecimento'];
        this['cidadeEstabelecimento'] = data['cidadeEstabelecimento'];
        this['paisEstabelecimento'] = data['paisEstabelecimento'];
        this['numeroReferencia'] = data['numeroReferencia'];
        this['valor'] = data['valor'];
        this['codigoAutorizacao'] = data['codigoAutorizacao'];
        this['statusId'] = data['statusId'];
        this['statusDescricao'] = data['statusDescricao'];
        this['pedidoId'] = data['pedidoId'];
        this['loteId'] = data['loteId'];
    }
}
export class InNovaAssinatura {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['organizationUnitId'] = data['organizationUnitId'];
        this['tipoSubscription'] = data['tipoSubscription'];
        this['url'] = data['url'];
    }
}
export class InNovaSubconta {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeConta'] = data['chaveDeConta'];
        this['descricao'] = data['descricao'];
        this['dataEncerramento'] = data['dataEncerramento'];
        this['portadorId'] = data['portadorId'];
    }
}
export class InNovoAparelhoContratoFinanciamentoMobile {
    constructor(data = {}) {
        this['imei'] = data['imei'];
        this['versaoMotosafe'] = data['versaoMotosafe'];
        this['sku'] = data['sku'];
        this['ean'] = data['ean'];
        this['codigoXt'] = data['codigoXt'];
        this['modelo'] = data['modelo'];
    }
}
export class InNovoOnboard {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['identificacaoFiscal'] = data['identificacaoFiscal'];
        this['organizationUnitId'] = data['organizationUnitId'];
        this['tipoConta'] = data['tipoConta'];
        this['chaveDeProduto'] = data['chaveDeProduto'];
        this['aberturaAutomaticaConta'] = data['aberturaAutomaticaConta'];
    }
}
export class InObterAgendamento {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeConta'] = data['chaveDeConta'];
        this['idAgendamento'] = data['idAgendamento'];
    }
}
export class InObterAssetsMobile {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['nome'] = data['nome'];
    }
}
export class InObterBloqueio {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['bloqueioId'] = data['bloqueioId'];
    }
}
export class InObterBoletoDeposito {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['boletoId'] = data['boletoId'];
        this['gerarImpressao'] = data['gerarImpressao'];
    }
}
export class InObterCodigoIndicacao {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeConta'] = data['chaveDeConta'];
    }
}
export class InObterComprovante {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeConta'] = data['chaveDeConta'];
        this['comprovanteId'] = data['comprovanteId'];
        this['gerarPdf'] = data['gerarPdf'];
    }
}
export class InObterContaPorIdFiscal {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['identificacaoFiscal'] = data['identificacaoFiscal'];
    }
}
export class InObterContaPorNumContaIdFiscal {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['identificacaoFiscal'] = data['identificacaoFiscal'];
        this['numeroConta'] = data['numeroConta'];
    }
}
export class InObterControleConfigCartao {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeCartao'] = data['chaveDeCartao'];
    }
}
export class InObterInformeRendimentos {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeConta'] = data['chaveDeConta'];
        this['anoBase'] = data['anoBase'];
        this['autenticacao2FA'] = data['autenticacao2FA'];
    }
}
export class InObterOnboard {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['identificacaoFiscal'] = data['identificacaoFiscal'];
        this['organizationUnitId'] = data['organizationUnitId'];
    }
}
export class InObterParametroGenerico {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeCliente'] = data['chaveDeCliente'];
        this['parametro'] = data['parametro'];
    }
}
export class InObterTarifa {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['tarifaId'] = data['tarifaId'];
    }
}
export class InObterTermo {
    constructor(data = {}) {
        this['organizacaoId'] = data['organizacaoId'];
        this['tipoDeTermo'] = data['tipoDeTermo'];
        this['extensaoDoArquivo'] = data['extensaoDoArquivo'];
        this['generico'] = data['generico'];
        this['chaveDeConta'] = data['chaveDeConta'];
    }
}
export class InObterTermoAssinado {
    constructor(data = {}) {
        this['chaveDeConta'] = data['chaveDeConta'];
        this['chaveDeCliente'] = data['chaveDeCliente'];
        this['ipCliente'] = data['ipCliente'];
        this['token'] = data['token'];
        this['tipoEnvioToken'] = data['tipoEnvioToken'];
    }
}
export class InObterUltimasTresTransacoes {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeConta'] = data['chaveDeConta'];
    }
}
export class InOperacaoCliente {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeCliente'] = data['chaveDeCliente'];
        this['senha'] = data['senha'];
    }
}
export class InOperacaoReivindicacaoComAutenticacaoPix {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeConta'] = data['chaveDeConta'];
        this['claimId'] = data['claimId'];
        this['autenticacao2FA'] = data['autenticacao2FA'];
    }
}
export class InOperacaoReivindicacaoPix {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeConta'] = data['chaveDeConta'];
        this['claimId'] = data['claimId'];
    }
}
export class InOrdensBloqueiosJudiciaisConsultar {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeConta'] = data['chaveDeConta'];
        this['identificacaoFiscal'] = data['identificacaoFiscal'];
        this['dataInicio'] = data['dataInicio'];
        this['dataFim'] = data['dataFim'];
        this['ordenacao'] = data['ordenacao'];
        this['statusBloqueioJudicial'] = data['statusBloqueioJudicial'];
        this['pagina'] = data['pagina'];
        this['porPagina'] = data['porPagina'];
    }
}
export class InOrdensBloqueiosJudiciaisExcluir {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeConta'] = data['chaveDeConta'];
    }
}
export class InPagamentoCreditoClean {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeConta'] = data['chaveDeConta'];
        this['autenticacao2FA'] = data['autenticacao2FA'];
        this['faturaId'] = data['faturaId'];
        this['metodo'] = data['metodo'];
        this['dataPagamento'] = data['dataPagamento'];
        this['numeroDeParcelas'] = data['numeroDeParcelas'];
        this['valor'] = data['valor'];
    }
}
export class InPreCadastroParceiro {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['organizationUnitId'] = data['organizationUnitId'];
        this['identificacaoFiscal'] = data['identificacaoFiscal'];
    }
}
export class InPreencherUltimos4Digitos {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeCartao'] = data['chaveDeCartao'];
    }
}
export class InPrimeiroAcesso {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeConta'] = data['chaveDeConta'];
    }
}
export class InProcessamentoArquivo {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['organizationUnitId'] = data['organizationUnitId'];
        this['arquivo'] = data['arquivo'];
        this['nomeArquivo'] = data['nomeArquivo'];
    }
}
export class InProposta {
    constructor(data = {}) {
        this['simulacaoAutorizadaId'] = data['simulacaoAutorizadaId'];
        this['identificador'] = data['identificador'];
        this['emitenteProposta'] = data['emitenteProposta'];
    }
}
export class InPropostaFinanciamentoMobile {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['tokenAssinatura'] = data['tokenAssinatura'];
        this['numeroNotaFiscal'] = data['numeroNotaFiscal'];
        this['serieNotaFiscal'] = data['serieNotaFiscal'];
        this['aparelho'] = data['aparelho'];
        this['chaveDeCliente'] = data['chaveDeCliente'];
    }
}
export class InPropostaMobile {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['simulacao'] = data['simulacao'];
        this['cliente'] = data['cliente'];
        this['organizationUnitId'] = data['organizationUnitId'];
        this['lojaId'] = data['lojaId'];
    }
}
export class InPropostaMobileV2 {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['idLoja'] = data['idLoja'];
        this['idAnaliseDeCredito'] = data['idAnaliseDeCredito'];
        this['chaveDeCliente'] = data['chaveDeCliente'];
        this['valorDoCredito'] = data['valorDoCredito'];
        this['valorDeEntrada'] = data['valorDeEntrada'];
        this['prazo'] = data['prazo'];
        this['idOrganizacao'] = data['idOrganizacao'];
        this['diaPrimeiroVencimento'] = data['diaPrimeiroVencimento'];
    }
}
export class InRealizarProposta {
    constructor(data = {}) {
        this['organizationUnitId'] = data['organizationUnitId'];
        this['identificador'] = data['identificador'];
        this['clienteProposta'] = data['clienteProposta'];
        this['valorRequisitado'] = data['valorRequisitado'];
    }
}
export class InRecargaCelular {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeConta'] = data['chaveDeConta'];
        this['valor'] = data['valor'];
        this['provedorId'] = data['provedorId'];
        this['autenticacao2FA'] = data['autenticacao2FA'];
        this['ddi'] = data['ddi'];
        this['ddd'] = data['ddd'];
        this['numero'] = data['numero'];
    }
}
export class InRecargaServico {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeConta'] = data['chaveDeConta'];
        this['valor'] = data['valor'];
        this['provedorId'] = data['provedorId'];
        this['autenticacao2FA'] = data['autenticacao2FA'];
    }
}
export class InReducaoLimitesCliente {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeConta'] = data['chaveDeConta'];
        this['novoValorLimite'] = data['novoValorLimite'];
        this['tipoLimite'] = data['tipoLimite'];
        this['motivoTransacao'] = data['motivoTransacao'];
        this['autenticacao2FA'] = data['autenticacao2FA'];
    }
}
export class InReembolsarPagamentoQrCode {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['paymentId'] = data['paymentId'];
        this['valor'] = data['valor'];
    }
}
export class InRegistroProcessando {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['arquivo'] = data['arquivo'];
        this['tipoDocumento'] = data['tipoDocumento'];
        this['identificacaoFiscal'] = data['identificacaoFiscal'];
        this['chaveDeCliente'] = data['chaveDeCliente'];
        this['organizacaoId'] = data['organizacaoId'];
        this['tipoValidacao'] = data['tipoValidacao'];
    }
}
export class InRegistroProcessandoFaceautenticator {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['arquivo'] = data['arquivo'];
        this['tipoDocumento'] = data['tipoDocumento'];
        this['identificacaoFiscal'] = data['identificacaoFiscal'];
        this['chaveDeCliente'] = data['chaveDeCliente'];
        this['organizacaoId'] = data['organizacaoId'];
        this['templateId'] = data['templateId'];
        this['retornoUrl'] = data['retornoUrl'];
        this['origem'] = data['origem'];
    }
}
export class InReivindicarChavePix {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeConta'] = data['chaveDeConta'];
        this['chaveEnderecamento'] = data['chaveEnderecamento'];
        this['autenticacao2FA'] = data['autenticacao2FA'];
        this['tipoReivindicacao'] = data['tipoReivindicacao'];
    }
}
export class InRelacaoProdutoUO {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['produtos'] = data['produtos'];
        this['organizationUnitId'] = data['organizationUnitId'];
    }
}
export class InResetarDocumentosCliente {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeCliente'] = data['chaveDeCliente'];
    }
}
export class InRetryMigrarProduto {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeConta'] = data['chaveDeConta'];
        this['tipoPacote'] = data['tipoPacote'];
    }
}
export class InSaldoAdquirencia {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeConta'] = data['chaveDeConta'];
    }
}
export class InSaqueATM {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['autenticacao2FA'] = data['autenticacao2FA'];
        this['valorQRCode'] = data['valorQRCode'];
        this['valor'] = data['valor'];
        this['chaveDeConta'] = data['chaveDeConta'];
    }
}
export class InSenha {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['senha'] = data['senha'];
    }
}
export class InServicoProduto {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['produtoId'] = data['produtoId'];
        this['servicos'] = data['servicos'];
    }
}
export class InSimulacaoAutorizada {
    constructor(data = {}) {
        this['cpf'] = data['cpf'];
        this['dataNascimento'] = data['dataNascimento'];
        this['valorSolicitado'] = data['valorSolicitado'];
    }
}
export class InSimularPagamento {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeConta'] = data['chaveDeConta'];
        this['idFatura'] = data['idFatura'];
        this['valor'] = data['valor'];
    }
}
export class InSimularPagamentoParcelado {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeConta'] = data['chaveDeConta'];
        this['idFatura'] = data['idFatura'];
        this['valorEntrada'] = data['valorEntrada'];
        this['parcelas'] = data['parcelas'];
    }
}
export class InSolicitaToken {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeCliente'] = data['chaveDeCliente'];
        this['tipoToken'] = data['tipoToken'];
    }
}
export class InSolicitaTokenConta {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeConta'] = data['chaveDeConta'];
        this['tipoToken'] = data['tipoToken'];
    }
}
export class InSolicitaTokenPin {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeCartao'] = data['chaveDeCartao'];
        this['tipoToken'] = data['tipoToken'];
    }
}
export class InSolicitarAlteracaoLimiteNoturnoPix {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeConta'] = data['chaveDeConta'];
        this['novoValorLimite'] = data['novoValorLimite'];
        this['horaInicio'] = data['horaInicio'];
        this['tipoLimite'] = data['tipoLimite'];
        this['autenticacao2FA'] = data['autenticacao2FA'];
    }
}
export class InSolicitarAntecipacaoParcelada {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeConta'] = data['chaveDeConta'];
        this['valor'] = data['valor'];
        this['prazo'] = data['prazo'];
    }
}
export class InSolicitarAntecipacaoSalarial {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeConta'] = data['chaveDeConta'];
        this['valor'] = data['valor'];
    }
}
export class InSolicitarCartao {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['categoria'] = data['categoria'];
        this['chaveDeConta'] = data['chaveDeConta'];
    }
}
export class InSolicitarCartaoVirtual {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['categoria'] = data['categoria'];
        this['chaveDeConta'] = data['chaveDeConta'];
        this['primeiroPin'] = data['primeiroPin'];
    }
}
export class InSolicitarContratoCreditoClean {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeConta'] = data['chaveDeConta'];
    }
}
export class InSolicitarDepositoAtm {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeConta'] = data['chaveDeConta'];
        this['valorQRCode'] = data['valorQRCode'];
        this['valor'] = data['valor'];
    }
}
export class InSolicitarPortabilidade {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeConta'] = data['chaveDeConta'];
        this['identificacaoFiscalEmpresaOrigem'] = data['identificacaoFiscalEmpresaOrigem'];
        this['razaoSocialEmpresaOrigem'] = data['razaoSocialEmpresaOrigem'];
        this['codigoBancoOrigem'] = data['codigoBancoOrigem'];
        this['autenticacao2FA'] = data['autenticacao2FA'];
    }
}
export class InSolicitarPropostaAntecipacaoSalarial {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['valor'] = data['valor'];
        this['prazo'] = data['prazo'];
    }
}
export class InSolicitarPropostaRenegociacao {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['valor'] = data['valor'];
        this['prazo'] = data['prazo'];
    }
}
export class InSolicitarResetSenhaPorToken {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['identificacaoFiscal'] = data['identificacaoFiscal'];
        this['organizationUnitId'] = data['organizationUnitId'];
    }
}
export class InSolicitarSegundaViaCartaoFisico {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeConta'] = data['chaveDeConta'];
        this['chaveDeCartao'] = data['chaveDeCartao'];
        this['motivo'] = data['motivo'];
    }
}
export class InSolicitarSegundaViaCartaoVirtual {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeConta'] = data['chaveDeConta'];
        this['chaveDeCartao'] = data['chaveDeCartao'];
        this['motivo'] = data['motivo'];
    }
}
export class InSolicitarTokenCartaoInterno {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeConta'] = data['chaveDeConta'];
        this['tipoToken'] = data['tipoToken'];
    }
}
export class InStatusCliente {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeCliente'] = data['chaveDeCliente'];
    }
}
export class InStatusConta {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeConta'] = data['chaveDeConta'];
    }
}
export class InStatusContaCreditoClean {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeConta'] = data['chaveDeConta'];
    }
}
export class InTentativasLogin {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['pagina'] = data['pagina'];
        this['porPagina'] = data['porPagina'];
    }
}
export class InTermoAntecipacao {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeConta'] = data['chaveDeConta'];
    }
}
export class InTermosPrivacidade {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['identificacaoFiscal'] = data['identificacaoFiscal'];
        this['organizationUnitId'] = data['organizationUnitId'];
        this['produtoId'] = data['produtoId'];
        this['tipoTermo'] = data['tipoTermo'];
    }
}
export class InTestarCompletarDadosCAF {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['execucaoId'] = data['execucaoId'];
    }
}
export class InTestarDecryptSenha {
    constructor(data = {}) {
        this['texto'] = data['texto'];
        this['privateKey'] = data['privateKey'];
        this['publicKey'] = data['publicKey'];
    }
}
export class InTesteAnotacao {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['nome'] = data['nome'];
        this['valor'] = data['valor'];
        this['documento'] = data['documento'];
        this['sexo'] = data['sexo'];
        this['email'] = data['email'];
        this['password'] = data['password'];
        this['passwordConfirmation'] = data['passwordConfirmation'];
        this['chavePix'] = data['chavePix'];
    }
}
export class InTesteAssinatura {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['tipoWebhook'] = data['tipoWebhook'];
        this['url'] = data['url'];
    }
}
export class InTesteEmail {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeCliente'] = data['chaveDeCliente'];
        this['email'] = data['email'];
        this['sessoesTeste'] = data['sessoesTeste'];
    }
}
export class InTitulos {
    constructor(data = {}) {
        this['tipo'] = data['tipo'];
        this['nossoNumero'] = data['nossoNumero'];
        this['situacao'] = data['situacao'];
        this['dtpag'] = data['dtpag'];
        this['dtcre'] = data['dtcre'];
        this['dthrsit'] = data['dthrsit'];
        this['vlrpag'] = data['vlrpag'];
        this['bcopag'] = data['bcopag'];
        this['agepag'] = data['agepag'];
        this['numindentcdda'] = data['numindentcdda'];
        this['numidentcbaixa'] = data['numidentcbaixa'];
        this['numseqatbaixa'] = data['numseqatbaixa'];
        this['expirado'] = data['expirado'];
    }
}
export class InTokenCelular {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['telefone'] = data['telefone'];
        this['chaveDeCliente'] = data['chaveDeCliente'];
    }
}
export class InTokenCelularConta {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['telefone'] = data['telefone'];
        this['chaveDeConta'] = data['chaveDeConta'];
    }
}
export class InTokenEmail {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['email'] = data['email'];
        this['chaveDeCliente'] = data['chaveDeCliente'];
    }
}
export class InTokenEmailConta {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['email'] = data['email'];
        this['chaveDeConta'] = data['chaveDeConta'];
    }
}
export class InTransacaoCelcoin {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['numReferencia'] = data['numReferencia'];
    }
}
export class InTransacaoTesouraria {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeConta'] = data['chaveDeConta'];
        this['chaveDeContaTesouraria'] = data['chaveDeContaTesouraria'];
        this['valor'] = data['valor'];
        this['observacoes'] = data['observacoes'];
        this['idsTransacaoContaCorrente'] = data['idsTransacaoContaCorrente'];
    }
}
export class InTransferirSaldoParaCliente {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeContaTesouraria'] = data['chaveDeContaTesouraria'];
        this['chaveDeConta'] = data['chaveDeConta'];
        this['valor'] = data['valor'];
        this['observacoes'] = data['observacoes'];
    }
}
export class InTransferirSaldoParaSubconta {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeContaTesouraria'] = data['chaveDeContaTesouraria'];
        this['chaveDeSubConta'] = data['chaveDeSubConta'];
        this['valor'] = data['valor'];
    }
}
export class InTransferirTesourariaParaTesouraria {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeContaTesourariaOrigem'] = data['chaveDeContaTesourariaOrigem'];
        this['chaveDeContaTesourariaDestino'] = data['chaveDeContaTesourariaDestino'];
        this['valor'] = data['valor'];
    }
}
export class InTrocaAparelho {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['imeiAnterior'] = data['imeiAnterior'];
        this['novoAparelho'] = data['novoAparelho'];
        this['numeroNotaFiscalNova'] = data['numeroNotaFiscalNova'];
        this['serieNotaFiscalNova'] = data['serieNotaFiscalNova'];
        this['observacao'] = data['observacao'];
    }
}
export class InTrocaPin {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeConta'] = data['chaveDeConta'];
        this['pinAtual'] = data['pinAtual'];
        this['pinNovo'] = data['pinNovo'];
    }
}
export class InTrocarPinContaSyncCartao {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeConta'] = data['chaveDeConta'];
        this['autenticacao2FA'] = data['autenticacao2FA'];
        this['pinNovo'] = data['pinNovo'];
    }
}
export class InUploadDocumento {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['arquivo'] = data['arquivo'];
        this['tipoDocumento'] = data['tipoDocumento'];
        this['chaveDeCliente'] = data['chaveDeCliente'];
    }
}
export class InValidarDimoQrCode {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['qrCode'] = data['qrCode'];
        this['chaveDeConta'] = data['chaveDeConta'];
    }
}
export class InValidarPagamentoBoletoNovo {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeConta'] = data['chaveDeConta'];
        this['linhaDigitavel'] = data['linhaDigitavel'];
        this['codigoDeBarras'] = data['codigoDeBarras'];
    }
}
export class InValidarPinConta {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeConta'] = data['chaveDeConta'];
        this['autenticacao2FA'] = data['autenticacao2FA'];
    }
}
export class InValidarTokenAtendimento {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeCliente'] = data['chaveDeCliente'];
        this['token'] = data['token'];
    }
}
export class InValidarTokenFgts {
    constructor(data = {}) {
        this['autenticacao2FA'] = data['autenticacao2FA'];
    }
}
export class InValoresSimulacao {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['prazo'] = data['prazo'];
        this['valorDoCreditoSolicitado'] = data['valorDoCreditoSolicitado'];
        this['valorFinalDaParcela'] = data['valorFinalDaParcela'];
        this['diaPrimeiroVencimento'] = data['diaPrimeiroVencimento'];
    }
}
export class InformeRendimentos {
    constructor(data = {}) {
        this['anoBase'] = data['anoBase'];
        this['tipoPessoa'] = data['tipoPessoa'];
        this['cpfCnpj'] = data['cpfCnpj'];
        this['nomeTitular'] = data['nomeTitular'];
        this['enderecoTitular'] = data['enderecoTitular'];
        this['complementoTitular'] = data['complementoTitular'];
        this['bairroTitular'] = data['bairroTitular'];
        this['cidadeTitular'] = data['cidadeTitular'];
        this['estadoTitular'] = data['estadoTitular'];
        this['cepTitular'] = data['cepTitular'];
        this['agenciaBeneficiario'] = data['agenciaBeneficiario'];
        this['contaBeneficiario'] = data['contaBeneficiario'];
        this['nomeEmpDeclarante'] = data['nomeEmpDeclarante'];
        this['enderecoEmpDeclarante'] = data['enderecoEmpDeclarante'];
        this['complementoEmpDeclarante'] = data['complementoEmpDeclarante'];
        this['bairroEmpDeclarante'] = data['bairroEmpDeclarante'];
        this['cidadeEmpDeclarante'] = data['cidadeEmpDeclarante'];
        this['estadoEmpDeclarante'] = data['estadoEmpDeclarante'];
        this['cepEmpDeclarante'] = data['cepEmpDeclarante'];
        this['cnpjEmpDeclarante'] = data['cnpjEmpDeclarante'];
        this['cnpjPagadora'] = data['cnpjPagadora'];
        this['nomeEmpresaPagadora'] = data['nomeEmpresaPagadora'];
        this['enderecoEmpPagadora'] = data['enderecoEmpPagadora'];
        this['complEnderecoEmpPag'] = data['complEnderecoEmpPag'];
        this['bairroEnderecoEmpPag'] = data['bairroEnderecoEmpPag'];
        this['cidadeEmpPag'] = data['cidadeEmpPag'];
        this['estadoEmpPag'] = data['estadoEmpPag'];
        this['cepEmpPag'] = data['cepEmpPag'];
        this['nomeSistema'] = data['nomeSistema'];
        this['saldoAnterior'] = data['saldoAnterior'];
        this['saldoBaseDezembro'] = data['saldoBaseDezembro'];
        this['informacaoComplementar'] = data['informacaoComplementar'];
    }
}
export class IssuerEnrollmentInfo {
    constructor(data = {}) {
        this['properties'] = data['properties'];
    }
}
export class ItemsDetalheFaturaDto {
    constructor(data = {}) {
        this['transacaoId'] = data['transacaoId'];
        this['dataHoraTransacao'] = data['dataHoraTransacao'];
        this['descricaoAbreviada'] = data['descricaoAbreviada'];
        this['descricaoEstabelecimento'] = data['descricaoEstabelecimento'];
        this['nomeEstabelecimento'] = data['nomeEstabelecimento'];
        this['descricaoTipoTransacao'] = data['descricaoTipoTransacao'];
        this['valorBRL'] = data['valorBRL'];
        this['valorUSD'] = data['valorUSD'];
        this['parcelamento'] = data['parcelamento'];
        this['parcelaNumero'] = data['parcelaNumero'];
    }
}
export class LancamentosFuturosDto {
    constructor(data = {}) {
        this['idAgendamento'] = data['idAgendamento'];
        this['tipoAgendamentoEnum'] = data['tipoAgendamentoEnum'];
        this['dataAgendamento'] = data['dataAgendamento'];
        this['valor'] = data['valor'];
        this['descricao'] = data['descricao'];
    }
}
export class Limites {
    constructor(data = {}) {
        this['total'] = data['total'];
        this['total_mensal'] = data['total_mensal'];
        this['comprometido_mensal'] = data['comprometido_mensal'];
        this['comprometido_total'] = data['comprometido_total'];
        this['comprometido_outros_bancos'] = data['comprometido_outros_bancos'];
        this['financeira_valor_minimo'] = data['financeira_valor_minimo'];
        this['financeira_valor_maximo'] = data['financeira_valor_maximo'];
    }
}
export class ListaContratos {
    constructor(data = {}) {
        this['fatores'] = data['fatores'];
        this['limites'] = data['limites'];
        this['contratos'] = data['contratos'];
    }
}
export class ListarFaturaFuturaItem {
    constructor(data = {}) {
        this['faturaFuturaId'] = data['faturaFuturaId'];
        this['vencimento'] = data['vencimento'];
        this['valor'] = data['valor'];
    }
}
export class ListarFaturaItem {
    constructor(data = {}) {
        this['faturaId'] = data['faturaId'];
        this['vencimento'] = data['vencimento'];
        this['statusFatura'] = data['statusFatura'];
        this['valor'] = data['valor'];
    }
}
export class LojaCompletaDto {
    constructor(data = {}) {
        this['cnpj'] = data['cnpj'];
        this['razaoSocial'] = data['razaoSocial'];
        this['apelido'] = data['apelido'];
        this['cnpjRecebedor'] = data['cnpjRecebedor'];
        this['email'] = data['email'];
        this['endereco'] = data['endereco'];
        this['dadosBancarios'] = data['dadosBancarios'];
        this['id'] = data['id'];
        this['ativo'] = data['ativo'];
    }
}
export class LojaCompletaDtoAddOutputPaginated {
    constructor(data = {}) {
        this['items'] = data['items'];
        this['qtdPaginas'] = data['qtdPaginas'];
        this['qtdPorPagina'] = data['qtdPorPagina'];
        this['totalItems'] = data['totalItems'];
        this['pagina'] = data['pagina'];
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
    }
}
export class LojaDto {
    constructor(data = {}) {
        this['cnpj'] = data['cnpj'];
        this['razaoSocial'] = data['razaoSocial'];
        this['apelido'] = data['apelido'];
        this['cnpjRecebedor'] = data['cnpjRecebedor'];
        this['email'] = data['email'];
        this['ativo'] = data['ativo'];
        this['endereco'] = data['endereco'];
        this['dadosBancarios'] = data['dadosBancarios'];
    }
}
export class Metadado {
    constructor(data = {}) {
        this['tipoMetadado'] = data['tipoMetadado'];
        this['valor'] = data['valor'];
    }
}
export class MotivoSegundaVia {
    constructor(data = {}) {
        this['id'] = data['id'];
        this['descricao'] = data['descricao'];
    }
}
export class MotivoTarifa {
    constructor(data = {}) {
        this['motivoTransacaoId'] = data['motivoTransacaoId'];
        this['motivoTransacao'] = data['motivoTransacao'];
    }
}
export class MotivoTarifaDto {
    constructor(data = {}) {
        this['motivoTransacaoId'] = data['motivoTransacaoId'];
        this['motivoTransacao'] = data['motivoTransacao'];
        this['motivoTransacaoTarifaALancarId'] = data['motivoTransacaoTarifaALancarId'];
        this['motivoTransacaoTarifaALancar'] = data['motivoTransacaoTarifaALancar'];
    }
}
export class NiFiResult {
    constructor(data = {}) {
        this['title'] = data['title'];
        this['status'] = data['status'];
        this['detail'] = data['detail'];
        this['instance'] = data['instance'];
        this['timestamp'] = data['timestamp'];
    }
}
export class OCR {
    constructor(data = {}) {
        this['rg'] = data['rg'];
        this['issueDate'] = data['issueDate'];
        this['name'] = data['name'];
        this['fatherName'] = data['fatherName'];
        this['motherName'] = data['motherName'];
        this['birthPlace'] = data['birthPlace'];
        this['birthDate'] = data['birthDate'];
        this['referenceDocument'] = data['referenceDocument'];
        this['cpf'] = data['cpf'];
        this['issuingAuthority'] = data['issuingAuthority'];
        this['issueState'] = data['issueState'];
        this['processingTime'] = data['processingTime'];
        this['category'] = data['category'];
        this['firstDriverLicenseDate'] = data['firstDriverLicenseDate'];
        this['placeOfIssue'] = data['placeOfIssue'];
        this['registrationNumber'] = data['registrationNumber'];
        this['renachNumber'] = data['renachNumber'];
        this['expirationDate'] = data['expirationDate'];
        this['rgIssuingAuthority'] = data['rgIssuingAuthority'];
        this['rgIssueState'] = data['rgIssueState'];
    }
}
export class ObjListaLimites {
    constructor(data = {}) {
        this['limiteTarifaId'] = data['limiteTarifaId'];
        this['operacaoId'] = data['operacaoId'];
        this['operacao'] = data['operacao'];
        this['periodoTarifaId'] = data['periodoTarifaId'];
        this['periodoTarifaDescricao'] = data['periodoTarifaDescricao'];
        this['maxAtual'] = data['maxAtual'];
        this['tipoContagemId'] = data['tipoContagemId'];
        this['tipoContagemDescricao'] = data['tipoContagemDescricao'];
        this['tipoPessoaTarifaId'] = data['tipoPessoaTarifaId'];
        this['tipoPessoaTarifaDescricao'] = data['tipoPessoaTarifaDescricao'];
    }
}
export class ObterLinkTermoAssinado {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['link'] = data['link'];
        this['tipoDeTermo'] = data['tipoDeTermo'];
        this['extensaoDoArquivo'] = data['extensaoDoArquivo'];
    }
}
export class OfficialData {
    constructor(data = {}) {
        this['code'] = data['code'];
        this['confidence'] = data['confidence'];
        this['probability'] = data['probability'];
    }
}
export class Operation {
    constructor(data = {}) {
        this['op'] = data['op'];
        this['value'] = data['value'];
        this['path'] = data['path'];
    }
}
export class OutAceitarTermoGenerico {
    constructor(data = {}) {
        this['codigoProposta'] = data['codigoProposta'];
    }
}
export class OutAgencia {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['nomeInstituicao'] = data['nomeInstituicao'];
        this['segmento'] = data['segmento'];
        this['codAgencia'] = data['codAgencia'];
        this['nomeAgencia'] = data['nomeAgencia'];
        this['endereco'] = data['endereco'];
        this['numero'] = data['numero'];
        this['complemento'] = data['complemento'];
        this['bairro'] = data['bairro'];
        this['cep'] = data['cep'];
        this['municipio'] = data['municipio'];
        this['uf'] = data['uf'];
        this['ddd'] = data['ddd'];
        this['telefone'] = data['telefone'];
        this['codBanco'] = data['codBanco'];
    }
}
export class OutAgendamentoPix {
    constructor(data = {}) {
        this['idAgendamento'] = data['idAgendamento'];
        this['dataAgendamento'] = data['dataAgendamento'];
        this['horaPagamento'] = data['horaPagamento'];
        this['valor'] = data['valor'];
        this['statusId'] = data['statusId'];
        this['statusDescricao'] = data['statusDescricao'];
        this['numeroDeOcorrencias'] = data['numeroDeOcorrencias'];
        this['agenciaBeneficiario'] = data['agenciaBeneficiario'];
        this['codInstituicaoBeneficiario'] = data['codInstituicaoBeneficiario'];
        this['nomeInstituicaoBeneficiario'] = data['nomeInstituicaoBeneficiario'];
        this['contaBeneficiario'] = data['contaBeneficiario'];
        this['identificacaoFiscalBeneficiario'] = data['identificacaoFiscalBeneficiario'];
        this['nomeBeneficiario'] = data['nomeBeneficiario'];
        this['chaveEnderecamentoBeneficiario'] = data['chaveEnderecamentoBeneficiario'];
        this['descricao'] = data['descricao'];
    }
}
export class OutAgendamentoPixAddOutputPaginated {
    constructor(data = {}) {
        this['items'] = data['items'];
        this['qtdPaginas'] = data['qtdPaginas'];
        this['qtdPorPagina'] = data['qtdPorPagina'];
        this['totalItems'] = data['totalItems'];
        this['pagina'] = data['pagina'];
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
    }
}
export class OutAgendarPix {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['pixAgendado'] = data['pixAgendado'];
    }
}
export class OutAgendarRecargaCelular {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['recargasAgendadas'] = data['recargasAgendadas'];
    }
}
export class OutAjusteFinanceiro {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['chaveDeConta'] = data['chaveDeConta'];
        this['valor'] = data['valor'];
        this['codHistorico'] = data['codHistorico'];
        this['descricao'] = data['descricao'];
        this['idTransacaoAdd'] = data['idTransacaoAdd'];
    }
}
export class OutAlterarAgendamentoPix {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['idAgendamento'] = data['idAgendamento'];
        this['dataAgendamento'] = data['dataAgendamento'];
        this['horaPagamento'] = data['horaPagamento'];
        this['valor'] = data['valor'];
        this['statusId'] = data['statusId'];
        this['statusDescricao'] = data['statusDescricao'];
        this['numeroDeOcorrencias'] = data['numeroDeOcorrencias'];
    }
}
export class OutAlterarAgendamentoTransferenciaInterna {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['statusId'] = data['statusId'];
        this['statusDescricao'] = data['statusDescricao'];
    }
}
export class OutAlterarLimiteContas {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['novoLimite'] = data['novoLimite'];
    }
}
export class OutAnaliseCredito {
    constructor(data = {}) {
        this['statusAnalise'] = data['statusAnalise'];
        this['analysisId'] = data['analysisId'];
        this['valorSolicitacao'] = data['valorSolicitacao'];
        this['valorParcela'] = data['valorParcela'];
        this['percentualEntradaMinimo'] = data['percentualEntradaMinimo'];
        this['valorEntradaMinimo'] = data['valorEntradaMinimo'];
    }
}
export class OutAntecipacaoAutorizadoFgts {
    constructor(data = {}) {
        this['taxaContratoAnual'] = data['taxaContratoAnual'];
        this['taxaContratoMensal'] = data['taxaContratoMensal'];
        this['liberado'] = data['liberado'];
        this['totalRecebiveis'] = data['totalRecebiveis'];
        this['prazo'] = data['prazo'];
        this['taxaIofAnual'] = data['taxaIofAnual'];
        this['taxaIofComp'] = data['taxaIofComp'];
        this['recebiveis'] = data['recebiveis'];
        this['valorMinimoLiberado'] = data['valorMinimoLiberado'];
        this['valorMaximoLiberado'] = data['valorMaximoLiberado'];
    }
}
export class OutAntecipacaoFgts {
    constructor(data = {}) {
        this['taxaContratoAnual'] = data['taxaContratoAnual'];
        this['taxaContratoMensal'] = data['taxaContratoMensal'];
        this['liberado'] = data['liberado'];
        this['totalRecebiveis'] = data['totalRecebiveis'];
        this['prazo'] = data['prazo'];
        this['taxaIofAnual'] = data['taxaIofAnual'];
        this['taxaIofComp'] = data['taxaIofComp'];
        this['recebiveis'] = data['recebiveis'];
    }
}
export class OutAparelhoContrato {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['id'] = data['id'];
        this['imei'] = data['imei'];
        this['modelo'] = data['modelo'];
        this['codigoEAN'] = data['codigoEAN'];
        this['ativo'] = data['ativo'];
    }
}
export class OutAssinarAntecipacao {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['idContrato'] = data['idContrato'];
        this['idProposta'] = data['idProposta'];
        this['localizador'] = data['localizador'];
        this['primeiroVencimento'] = data['primeiroVencimento'];
        this['ultimoVencimento'] = data['ultimoVencimento'];
        this['dataSolicitacao'] = data['dataSolicitacao'];
        this['dataEfetivacao'] = data['dataEfetivacao'];
        this['taxaContrato'] = data['taxaContrato'];
        this['taxaIRR'] = data['taxaIRR'];
        this['taxaCetMes'] = data['taxaCetMes'];
        this['taxaIOF'] = data['taxaIOF'];
        this['taxaIOFComplementar'] = data['taxaIOFComplementar'];
        this['taxaMulta'] = data['taxaMulta'];
        this['diasCobrancaMulta'] = data['diasCobrancaMulta'];
        this['valorLiberado'] = data['valorLiberado'];
        this['valorIOF'] = data['valorIOF'];
        this['valorTarifa'] = data['valorTarifa'];
        this['valorJuros'] = data['valorJuros'];
        this['valorRecebivel'] = data['valorRecebivel'];
        this['valorSeguro'] = data['valorSeguro'];
        this['valorFinanciado'] = data['valorFinanciado'];
        this['prazo'] = data['prazo'];
        this['valorParcela'] = data['valorParcela'];
        this['saldoDevedor'] = data['saldoDevedor'];
        this['taxaPermanencia'] = data['taxaPermanencia'];
        this['dataLimiteParaAverbar'] = data['dataLimiteParaAverbar'];
        this['dataAverbacao'] = data['dataAverbacao'];
        this['dataPagamento'] = data['dataPagamento'];
        this['statusContrato'] = data['statusContrato'];
        this['statusAverbacao'] = data['statusAverbacao'];
        this['idComprovante'] = data['idComprovante'];
    }
}
export class OutAssinarContratoCreditoClean {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['contractId'] = data['contractId'];
        this['bearerId'] = data['bearerId'];
    }
}
export class OutAssinarSegundaViaAntecipacaoPrivada {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['arquivo'] = data['arquivo'];
    }
}
export class OutAssinarTermoNovamente {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['termo'] = data['termo'];
    }
}
export class OutAssinaturaContrato {
    constructor(data = {}) {
        this['codigoContrato'] = data['codigoContrato'];
        this['faceMatchUrl'] = data['faceMatchUrl'];
        this['localizador'] = data['localizador'];
        this['valorLiberado'] = data['valorLiberado'];
        this['taxaCetMes'] = data['taxaCetMes'];
        this['taxaCetAno'] = data['taxaCetAno'];
        this['taxaIof'] = data['taxaIof'];
        this['taxaIofComplementar'] = data['taxaIofComplementar'];
        this['prazo'] = data['prazo'];
        this['valorIof'] = data['valorIof'];
        this['valorTarifas'] = data['valorTarifas'];
        this['valorRecebivel'] = data['valorRecebivel'];
        this['taxaContrato'] = data['taxaContrato'];
        this['taxaIrr'] = data['taxaIrr'];
        this['taxaMulta'] = data['taxaMulta'];
        this['valorJuros'] = data['valorJuros'];
        this['valorSeguro'] = data['valorSeguro'];
        this['valorSolicitacao'] = data['valorSolicitacao'];
        this['liberado'] = data['liberado'];
        this['totalRecebiveis'] = data['totalRecebiveis'];
        this['taxaContratoAnual'] = data['taxaContratoAnual'];
        this['taxaContratoMensal'] = data['taxaContratoMensal'];
        this['saldoDevedor'] = data['saldoDevedor'];
        this['valorFinanciado'] = data['valorFinanciado'];
        this['financeiraValorPagar'] = data['financeiraValorPagar'];
        this['prazoDeOperacao'] = data['prazoDeOperacao'];
        this['ultimoVencimento'] = data['ultimoVencimento'];
        this['primeiroVencimento'] = data['primeiroVencimento'];
        this['recebiveis'] = data['recebiveis'];
        this['faceMatchId'] = data['faceMatchId'];
        this['requerValidacaoFacial'] = data['requerValidacaoFacial'];
    }
}
export class OutAtm {
    constructor(data = {}) {
        this['latitude'] = data['latitude'];
        this['longitude'] = data['longitude'];
        this['logradouro'] = data['logradouro'];
        this['numero'] = data['numero'];
        this['complemento'] = data['complemento'];
        this['bairro'] = data['bairro'];
        this['cidade'] = data['cidade'];
        this['uf'] = data['uf'];
        this['cep'] = data['cep'];
        this['nomeAtm'] = data['nomeAtm'];
        this['segmento'] = data['segmento'];
    }
}
export class OutAuth {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['accessToken'] = data['accessToken'];
        this['encryptedAccessToken'] = data['encryptedAccessToken'];
        this['expireInSeconds'] = data['expireInSeconds'];
        this['userId'] = data['userId'];
        this['autorizar'] = data['autorizar'];
        this['faceMatch'] = data['faceMatch'];
        this['processandoFaceMatch'] = data['processandoFaceMatch'];
    }
}
export class OutAuthCliente {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['accessToken'] = data['accessToken'];
        this['encryptedAccessToken'] = data['encryptedAccessToken'];
        this['expireInSeconds'] = data['expireInSeconds'];
        this['userId'] = data['userId'];
        this['autorizar'] = data['autorizar'];
        this['faceMatch'] = data['faceMatch'];
        this['processandoFaceMatch'] = data['processandoFaceMatch'];
        this['chaveDeCliente'] = data['chaveDeCliente'];
        this['identificacaoFiscal'] = data['identificacaoFiscal'];
    }
}
export class OutBaixaTitulo {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['boletoBaixado'] = data['boletoBaixado'];
    }
}
export class OutBancoList {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['ispb'] = data['ispb'];
        this['codigo'] = data['codigo'];
        this['nomeInstituicao'] = data['nomeInstituicao'];
        this['temPix'] = data['temPix'];
    }
}
export class OutBancoListAddOutputPaginated {
    constructor(data = {}) {
        this['items'] = data['items'];
        this['qtdPaginas'] = data['qtdPaginas'];
        this['qtdPorPagina'] = data['qtdPorPagina'];
        this['totalItems'] = data['totalItems'];
        this['pagina'] = data['pagina'];
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
    }
}
export class OutBoletoGerado {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['id'] = data['id'];
        this['valorNominal'] = data['valorNominal'];
        this['valorTarifa'] = data['valorTarifa'];
        this['valorTotal'] = data['valorTotal'];
        this['dataEmissao'] = data['dataEmissao'];
        this['dataVencimento'] = data['dataVencimento'];
        this['dataFinalizado'] = data['dataFinalizado'];
        this['statusBoletoDescricao'] = data['statusBoletoDescricao'];
        this['statusBoletoId'] = data['statusBoletoId'];
        this['linhaDigitavel'] = data['linhaDigitavel'];
        this['codigoDeBarras'] = data['codigoDeBarras'];
        this['nossoNumero'] = data['nossoNumero'];
        this['arquivo'] = data['arquivo'];
    }
}
export class OutBoletos {
    constructor(data = {}) {
        this['boletoId'] = data['boletoId'];
        this['valorNominal'] = data['valorNominal'];
        this['dataEmissao'] = data['dataEmissao'];
        this['dataVencimento'] = data['dataVencimento'];
        this['statusBoletoDescricao'] = data['statusBoletoDescricao'];
        this['linhaDigitavel'] = data['linhaDigitavel'];
        this['codigoDeBarras'] = data['codigoDeBarras'];
        this['nossoNumero'] = data['nossoNumero'];
    }
}
export class OutCadastrarChavePix {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['chaveEnderecamento'] = data['chaveEnderecamento'];
    }
}
export class OutCadastroDispositivo {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['chaveOtpt'] = data['chaveOtpt'];
    }
}
export class OutCalcularValorParcela {
    constructor(data = {}) {
        this['calculoParcelas'] = data['calculoParcelas'];
        this['valorSolicitado'] = data['valorSolicitado'];
    }
}
export class OutCancelarAgendamentoBoleto {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['dataDeCancelamento'] = data['dataDeCancelamento'];
        this['statusId'] = data['statusId'];
        this['statusDescricao'] = data['statusDescricao'];
        this['motivoCancelamento'] = data['motivoCancelamento'];
    }
}
export class OutCancelarAgendamentoPix {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['dataDeCancelamento'] = data['dataDeCancelamento'];
        this['statusId'] = data['statusId'];
        this['statusDescricao'] = data['statusDescricao'];
        this['motivoCancelamento'] = data['motivoCancelamento'];
    }
}
export class OutCancelarAgendamentoTransferenciaInterna {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['dataDeCancelamento'] = data['dataDeCancelamento'];
        this['statusId'] = data['statusId'];
        this['statusDescricao'] = data['statusDescricao'];
        this['motivoCancelamento'] = data['motivoCancelamento'];
    }
}
export class OutCancelarContaCliente {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['dataSolicitacao'] = data['dataSolicitacao'];
        this['dataCancelamento'] = data['dataCancelamento'];
        this['contaEncerradaBanco'] = data['contaEncerradaBanco'];
        this['statusEncerramento'] = data['statusEncerramento'];
        this['tipoCancelamento'] = data['tipoCancelamento'];
        this['motivoCancelamento'] = data['motivoCancelamento'];
    }
}
export class OutCardComunicacaoConsultar {
    constructor(data = {}) {
        this['organizationUnitId'] = data['organizationUnitId'];
        this['mensagem'] = data['mensagem'];
        this['imagem'] = data['imagem'];
        this['link'] = data['link'];
        this['userName'] = data['userName'];
        this['userId'] = data['userId'];
        this['dataCriacao'] = data['dataCriacao'];
        this['inicioVigencia'] = data['inicioVigencia'];
        this['fimVigencia'] = data['fimVigencia'];
        this['chaveDeCardComunicacao'] = data['chaveDeCardComunicacao'];
        this['idCard'] = data['idCard'];
    }
}
export class OutCardComunicacaoConsultarAddOutputPaginated {
    constructor(data = {}) {
        this['items'] = data['items'];
        this['qtdPaginas'] = data['qtdPaginas'];
        this['qtdPorPagina'] = data['qtdPorPagina'];
        this['totalItems'] = data['totalItems'];
        this['pagina'] = data['pagina'];
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
    }
}
export class OutCardComunicacaoCriar {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['chaveDeCardComunicacao'] = data['chaveDeCardComunicacao'];
    }
}
export class OutCartao {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['chaveDeCartao'] = data['chaveDeCartao'];
        this['nomeImpresso'] = data['nomeImpresso'];
        this['cartaoNumero'] = data['cartaoNumero'];
        this['idStatusCartao'] = data['idStatusCartao'];
        this['descricaoStatusCartao'] = data['descricaoStatusCartao'];
        this['fisico'] = data['fisico'];
        this['cartaoDataExpiracao'] = data['cartaoDataExpiracao'];
        this['bandeira'] = data['bandeira'];
        this['bandeiraNome'] = data['bandeiraNome'];
        this['cvv'] = data['cvv'];
        this['categoria'] = data['categoria'];
        this['descricaoCategoria'] = data['descricaoCategoria'];
        this['dataExpiracaoCvvDinamico'] = data['dataExpiracaoCvvDinamico'];
    }
}
export class OutCartaoGarantiaAssinarContrato {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['contractId'] = data['contractId'];
        this['status'] = data['status'];
    }
}
export class OutCartaoGarantiaDetalheParcela {
    constructor(data = {}) {
        this['mes'] = data['mes'];
        this['valor'] = data['valor'];
        this['parcela'] = data['parcela'];
    }
}
export class OutCartaoGarantiaSimulacaoResgate {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['status'] = data['status'];
        this['result'] = data['result'];
        this['saldoTotalInvestido'] = data['saldoTotalInvestido'];
        this['rendimentos'] = data['rendimentos'];
        this['saldoPassivelDeResgate'] = data['saldoPassivelDeResgate'];
        this['limiteDeCreditoAtual'] = data['limiteDeCreditoAtual'];
        this['limiteDeCreditoAposResgate'] = data['limiteDeCreditoAposResgate'];
        this['saldoTotalInvestidoAposResgate'] = data['saldoTotalInvestidoAposResgate'];
        this['dataPrimeiroInvestimento'] = data['dataPrimeiroInvestimento'];
    }
}
export class OutCartaoGarantiaSimularPagamentoParcelado {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['status'] = data['status'];
        this['result'] = data['result'];
        this['simulacaoId'] = data['simulacaoId'];
        this['dataVencimento'] = data['dataVencimento'];
        this['juros'] = data['juros'];
        this['valorOriginal'] = data['valorOriginal'];
        this['valorTotalFatura'] = data['valorTotalFatura'];
        this['jurosTotais'] = data['jurosTotais'];
        this['parcelas'] = data['parcelas'];
    }
}
export class OutCartaoGarantiaSolicitarContrato {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['contractId'] = data['contractId'];
        this['contractDocumentText'] = data['contractDocumentText'];
    }
}
export class OutCartaoSimplificado {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['chaveDeCartao'] = data['chaveDeCartao'];
        this['nomeImpresso'] = data['nomeImpresso'];
        this['cartaoNumero'] = data['cartaoNumero'];
        this['idStatusCartao'] = data['idStatusCartao'];
        this['descricaoStatusCartao'] = data['descricaoStatusCartao'];
        this['fisico'] = data['fisico'];
        this['cartaoDataExpiracao'] = data['cartaoDataExpiracao'];
        this['bandeira'] = data['bandeira'];
        this['bandeiraNome'] = data['bandeiraNome'];
    }
}
export class OutCartaoSubconta {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['chaveDeCartao'] = data['chaveDeCartao'];
        this['nomeImpresso'] = data['nomeImpresso'];
        this['cartaoNumero'] = data['cartaoNumero'];
        this['idStatusCartao'] = data['idStatusCartao'];
        this['descricaoStatusCartao'] = data['descricaoStatusCartao'];
        this['fisico'] = data['fisico'];
        this['cartaoDataExpiracao'] = data['cartaoDataExpiracao'];
        this['bandeira'] = data['bandeira'];
        this['bandeiraNome'] = data['bandeiraNome'];
        this['dataCriacao'] = data['dataCriacao'];
        this['nomePortador'] = data['nomePortador'];
        this['statusContaId'] = data['statusContaId'];
        this['statusContaDescricao'] = data['statusContaDescricao'];
    }
}
export class OutCepCompleto {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['cep'] = data['cep'];
        this['logradouro'] = data['logradouro'];
        this['complemento'] = data['complemento'];
        this['bairro'] = data['bairro'];
        this['localidade'] = data['localidade'];
        this['uf'] = data['uf'];
        this['ibge'] = data['ibge'];
        this['gia'] = data['gia'];
        this['ddd'] = data['ddd'];
        this['siafi'] = data['siafi'];
    }
}
export class OutChargeBack {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['contestacaoId'] = data['contestacaoId'];
        this['processada'] = data['processada'];
    }
}
export class OutChaveCliente {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['chaveDeCliente'] = data['chaveDeCliente'];
    }
}
export class OutChaveDeConta {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['chaveDeContaConsulta'] = data['chaveDeContaConsulta'];
    }
}
export class OutChavePix {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['chaveEnderecamento'] = data['chaveEnderecamento'];
        this['dataCriacao'] = data['dataCriacao'];
        this['endToEnd'] = data['endToEnd'];
        this['tipoChave'] = data['tipoChave'];
        this['agencia'] = data['agencia'];
        this['codInstituicao'] = data['codInstituicao'];
        this['conta'] = data['conta'];
        this['identificaoFiscal'] = data['identificaoFiscal'];
        this['nome'] = data['nome'];
        this['nomeInstituicao'] = data['nomeInstituicao'];
        this['mesmoDetentor'] = data['mesmoDetentor'];
    }
}
export class OutChaveSubconta {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['chaveDeConta'] = data['chaveDeConta'];
        this['chaveDeContaPai'] = data['chaveDeContaPai'];
    }
}
export class OutChavesDeConta {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['listaContas'] = data['listaContas'];
    }
}
export class OutClienteRelatorioCaf {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['arquivo'] = data['arquivo'];
    }
}
export class OutClienteTermo {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['arquivo'] = data['arquivo'];
    }
}
export class OutClientesLimitesList {
    constructor(data = {}) {
        this['limiteSolicitacaoClienteId'] = data['limiteSolicitacaoClienteId'];
        this['limiteClienteId'] = data['limiteClienteId'];
        this['chaveDeCliente'] = data['chaveDeCliente'];
        this['clienteNome'] = data['clienteNome'];
        this['clienteIdentificacaoFiscal'] = data['clienteIdentificacaoFiscal'];
        this['valorSolicitado'] = data['valorSolicitado'];
        this['valorAtual'] = data['valorAtual'];
        this['valorAprovado'] = data['valorAprovado'];
        this['tipoOperacaoLimiteCliente'] = data['tipoOperacaoLimiteCliente'];
        this['tipoOperacaoLimiteClienteId'] = data['tipoOperacaoLimiteClienteId'];
        this['organizationUnitId'] = data['organizationUnitId'];
        this['statusSolicitacao'] = data['statusSolicitacao'];
        this['dataSolicitacao'] = data['dataSolicitacao'];
        this['parceiro'] = data['parceiro'];
        this['userId'] = data['userId'];
        this['userName'] = data['userName'];
        this['tipoSolicitacao'] = data['tipoSolicitacao'];
    }
}
export class OutClientesLimitesListAddOutputPaginated {
    constructor(data = {}) {
        this['items'] = data['items'];
        this['qtdPaginas'] = data['qtdPaginas'];
        this['qtdPorPagina'] = data['qtdPorPagina'];
        this['totalItems'] = data['totalItems'];
        this['pagina'] = data['pagina'];
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
    }
}
export class OutCompletarDadosCAF {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['executionId'] = data['executionId'];
        this['cafType'] = data['cafType'];
        this['status'] = data['status'];
        this['reportId'] = data['reportId'];
        this['images'] = data['images'];
        this['parameters'] = data['parameters'];
        this['sections'] = data['sections'];
        this['validations'] = data['validations'];
        this['fraud'] = data['fraud'];
        this['ultimaVerificacao'] = data['ultimaVerificacao'];
    }
}
export class OutConfirmacaoRepasseLoteContratos {
    constructor(data = {}) {
        this['success'] = data['success'];
        this['message'] = data['message'];
    }
}
export class OutConsultaContratosFgts {
    constructor(data = {}) {
        this['statusContratoAntecipacao'] = data['statusContratoAntecipacao'];
        this['localizador'] = data['localizador'];
        this['contrato'] = data['contrato'];
        this['data'] = data['data'];
        this['dataConclusao'] = data['dataConclusao'];
        this['prazo'] = data['prazo'];
        this['valorLiberado'] = data['valorLiberado'];
        this['valorFinanciado'] = data['valorFinanciado'];
        this['taxaJuros'] = data['taxaJuros'];
        this['cetAno'] = data['cetAno'];
        this['ativo'] = data['ativo'];
    }
}
export class OutConsultaContratosFgtsAddOutputPaginated {
    constructor(data = {}) {
        this['items'] = data['items'];
        this['qtdPaginas'] = data['qtdPaginas'];
        this['qtdPorPagina'] = data['qtdPorPagina'];
        this['totalItems'] = data['totalItems'];
        this['pagina'] = data['pagina'];
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
    }
}
export class OutConsultaContratosFgtsBackOffice {
    constructor(data = {}) {
        this['paginasTotal'] = data['paginasTotal'];
        this['contratos'] = data['contratos'];
        this['pagina'] = data['pagina'];
        this['porPagina'] = data['porPagina'];
    }
}
export class OutConsultaContratosFgtsParceiro {
    constructor(data = {}) {
        this['contratos'] = data['contratos'];
        this['apartirDoItem'] = data['apartirDoItem'];
        this['itensPorPagina'] = data['itensPorPagina'];
        this['quantidadeItensTotal'] = data['quantidadeItensTotal'];
        this['quantidadeItensPagina'] = data['quantidadeItensPagina'];
        this['quantidadePaginas'] = data['quantidadePaginas'];
        this['pagina'] = data['pagina'];
    }
}
export class OutConsultaFeriados {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['feriados'] = data['feriados'];
    }
}
export class OutConsultaHistoricoContrato {
    constructor(data = {}) {
        this['statusContratoAntecipacao'] = data['statusContratoAntecipacao'];
        this['localizador'] = data['localizador'];
        this['contrato'] = data['contrato'];
        this['financiador'] = data['financiador'];
        this['data'] = data['data'];
        this['dataConclusao'] = data['dataConclusao'];
        this['prazo'] = data['prazo'];
        this['valorLiberado'] = data['valorLiberado'];
        this['valorFinanciado'] = data['valorFinanciado'];
        this['taxaJuros'] = data['taxaJuros'];
        this['recebiveis'] = data['recebiveis'];
    }
}
export class OutConsultaUnidadeOrganizacionalFilha {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['unidadeFilha'] = data['unidadeFilha'];
    }
}
export class OutConsultarAgendamentoBoleto {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['idAgendamento'] = data['idAgendamento'];
        this['linhaDigitavel'] = data['linhaDigitavel'];
        this['codigoDeBarras'] = data['codigoDeBarras'];
        this['dataUltimaAlteracao'] = data['dataUltimaAlteracao'];
        this['dataAgendamento'] = data['dataAgendamento'];
        this['status'] = data['status'];
        this['valor'] = data['valor'];
        this['descricaoExecucao'] = data['descricaoExecucao'];
        this['destinatario'] = data['destinatario'];
        this['identificacaoDestinatario'] = data['identificacaoDestinatario'];
    }
}
export class OutConsultarAgendamentoBoletoAddOutputPaginated {
    constructor(data = {}) {
        this['items'] = data['items'];
        this['qtdPaginas'] = data['qtdPaginas'];
        this['qtdPorPagina'] = data['qtdPorPagina'];
        this['totalItems'] = data['totalItems'];
        this['pagina'] = data['pagina'];
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
    }
}
export class OutConsultarAgendamentoTransferenciaInterna {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['idAgendamento'] = data['idAgendamento'];
        this['chaveDeContaDestino'] = data['chaveDeContaDestino'];
        this['identificacaoFiscalDestino'] = data['identificacaoFiscalDestino'];
        this['nomeDestino'] = data['nomeDestino'];
        this['agenciaDestino'] = data['agenciaDestino'];
        this['contaDestino'] = data['contaDestino'];
        this['dataUltimaAlteracao'] = data['dataUltimaAlteracao'];
        this['dataAgendamento'] = data['dataAgendamento'];
        this['status'] = data['status'];
        this['valor'] = data['valor'];
        this['descricaoExecucao'] = data['descricaoExecucao'];
        this['numeroRecorrencias'] = data['numeroRecorrencias'];
    }
}
export class OutConsultarAgendamentoTransferenciaInternaAddOutputPaginated {
    constructor(data = {}) {
        this['items'] = data['items'];
        this['qtdPaginas'] = data['qtdPaginas'];
        this['qtdPorPagina'] = data['qtdPorPagina'];
        this['totalItems'] = data['totalItems'];
        this['pagina'] = data['pagina'];
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
    }
}
export class OutConsultarBloqueiosConta {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['bloqueiosConta'] = data['bloqueiosConta'];
    }
}
export class OutConsultarChavesFavorito {
    constructor(data = {}) {
        this['id'] = data['id'];
        this['agencia'] = data['agencia'];
        this['conta'] = data['conta'];
        this['codigoOuISPB'] = data['codigoOuISPB'];
        this['identificacaoFiscal'] = data['identificacaoFiscal'];
        this['chavePix'] = data['chavePix'];
        this['nomeInstituicao'] = data['nomeInstituicao'];
        this['nomeCompleto'] = data['nomeCompleto'];
        this['apelido'] = data['apelido'];
        this['tipoChavePix'] = data['tipoChavePix'];
        this['tipoDeConta'] = data['tipoDeConta'];
    }
}
export class OutConsultarChavesFavoritoAddOutputPaginated {
    constructor(data = {}) {
        this['items'] = data['items'];
        this['qtdPaginas'] = data['qtdPaginas'];
        this['qtdPorPagina'] = data['qtdPorPagina'];
        this['totalItems'] = data['totalItems'];
        this['pagina'] = data['pagina'];
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
    }
}
export class OutConsultarComprovante {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['items'] = data['items'];
        this['totalCount'] = data['totalCount'];
    }
}
export class OutConsultarContratoClienteDetalhado {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['totalSaldo'] = data['totalSaldo'];
        this['parcelas'] = data['parcelas'];
    }
}
export class OutConsultarContratosClienteAtivosLiquidados {
    constructor(data = {}) {
        this['localizador'] = data['localizador'];
        this['tipoDeContrato'] = data['tipoDeContrato'];
        this['dataDeEmissao'] = data['dataDeEmissao'];
        this['valorDoContrato'] = data['valorDoContrato'];
        this['parcelasRestantes'] = data['parcelasRestantes'];
        this['periodoEmMeses'] = data['periodoEmMeses'];
        this['situacao'] = data['situacao'];
        this['contratoId'] = data['contratoId'];
    }
}
export class OutConsultarContratosClienteAtivosLiquidadosAddOutputPaginated {
    constructor(data = {}) {
        this['items'] = data['items'];
        this['qtdPaginas'] = data['qtdPaginas'];
        this['qtdPorPagina'] = data['qtdPorPagina'];
        this['totalItems'] = data['totalItems'];
        this['pagina'] = data['pagina'];
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
    }
}
export class OutConsultarDadosGerais {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['organizationUnitId'] = data['organizationUnitId'];
        this['identificacaoFiscal'] = data['identificacaoFiscal'];
        this['chaveDeCliente'] = data['chaveDeCliente'];
        this['chaveDeConta'] = data['chaveDeConta'];
        this['chaveDeCartaoVirtual'] = data['chaveDeCartaoVirtual'];
        this['chaveDeCartaoFisico'] = data['chaveDeCartaoFisico'];
    }
}
export class OutConsultarDetalhesContratoCredito {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['numeroDoContrato'] = data['numeroDoContrato'];
        this['statusContratoAntecipacao'] = data['statusContratoAntecipacao'];
        this['dataDeContratacao'] = data['dataDeContratacao'];
        this['dataFinalContrato'] = data['dataFinalContrato'];
        this['valorContrato'] = data['valorContrato'];
        this['parcelas'] = data['parcelas'];
        this['parcelasEmAberto'] = data['parcelasEmAberto'];
        this['taxaDeJurosMensal'] = data['taxaDeJurosMensal'];
    }
}
export class OutConsultarDiaUtil {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
    }
}
export class OutConsultarEnderecoDeEntrega {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['endereco'] = data['endereco'];
    }
}
export class OutConsultarExtratoBeneficio {
    constructor(data = {}) {
        this['pagina'] = data['pagina'];
        this['valorTotalTransacionado'] = data['valorTotalTransacionado'];
        this['valorTransacionadoPorSegmento'] = data['valorTransacionadoPorSegmento'];
    }
}
export class OutConsultarExtratoCliente {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['contrato'] = data['contrato'];
        this['localizador'] = data['localizador'];
        this['dataDeContratacao'] = data['dataDeContratacao'];
        this['valorFinanciado'] = data['valorFinanciado'];
        this['valorLiberado'] = data['valorLiberado'];
        this['parcelas'] = data['parcelas'];
        this['valorParcela'] = data['valorParcela'];
        this['vencimentoDaParcela'] = data['vencimentoDaParcela'];
        this['statusContratoAntecipacao'] = data['statusContratoAntecipacao'];
        this['idContratoOrigem'] = data['idContratoOrigem'];
        this['mensagemRenegociacao'] = data['mensagemRenegociacao'];
    }
}
export class OutConsultarExtratoClienteAddOutputPaginated {
    constructor(data = {}) {
        this['items'] = data['items'];
        this['qtdPaginas'] = data['qtdPaginas'];
        this['qtdPorPagina'] = data['qtdPorPagina'];
        this['totalItems'] = data['totalItems'];
        this['pagina'] = data['pagina'];
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
    }
}
export class OutConsultarFavoritos {
    constructor(data = {}) {
        this['favoritoId'] = data['favoritoId'];
        this['identificacaoFiscal'] = data['identificacaoFiscal'];
        this['nomeCompleto'] = data['nomeCompleto'];
        this['apelido'] = data['apelido'];
    }
}
export class OutConsultarFavoritosAddOutputPaginated {
    constructor(data = {}) {
        this['items'] = data['items'];
        this['qtdPaginas'] = data['qtdPaginas'];
        this['qtdPorPagina'] = data['qtdPorPagina'];
        this['totalItems'] = data['totalItems'];
        this['pagina'] = data['pagina'];
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
    }
}
export class OutConsultarIconeGrupoMcc {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['grupoMcc'] = data['grupoMcc'];
        this['icone'] = data['icone'];
    }
}
export class OutConsultarInforRenegociacao {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['listaContratos'] = data['listaContratos'];
        this['infoContratos'] = data['infoContratos'];
        this['somaValorContrato'] = data['somaValorContrato'];
        this['valorDisponivel'] = data['valorDisponivel'];
    }
}
export class OutConsultarMccCartao {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['beneficios'] = data['beneficios'];
    }
}
export class OutConsultarMotivosTarifa {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['motivosTransacaoTarifa'] = data['motivosTransacaoTarifa'];
    }
}
export class OutConsultarMotivosTarifas {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['motivosTarifas'] = data['motivosTarifas'];
    }
}
export class OutConsultarPortabilidade {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['chaveDePortabilidade'] = data['chaveDePortabilidade'];
        this['protocoloPortabilidade'] = data['protocoloPortabilidade'];
        this['statusFacematch'] = data['statusFacematch'];
        this['faceMatchId'] = data['faceMatchId'];
        this['faceMatchUrl'] = data['faceMatchUrl'];
        this['requerValidacaoFacial'] = data['requerValidacaoFacial'];
        this['chaveDeConta'] = data['chaveDeConta'];
        this['identificacaoFiscalEmpresaOrigem'] = data['identificacaoFiscalEmpresaOrigem'];
        this['razaoSocialEmpresaOrigem'] = data['razaoSocialEmpresaOrigem'];
        this['codigoBancoOrigem'] = data['codigoBancoOrigem'];
        this['nomeBancoOrigem'] = data['nomeBancoOrigem'];
        this['situacaoPortabilidade'] = data['situacaoPortabilidade'];
        this['situacaoPortabilidadeCip'] = data['situacaoPortabilidadeCip'];
        this['motivoCancelamento'] = data['motivoCancelamento'];
        this['statusPortabilidade'] = data['statusPortabilidade'];
        this['motivoCancelamentoDescricao'] = data['motivoCancelamentoDescricao'];
        this['complementoMotivoCancelamento'] = data['complementoMotivoCancelamento'];
        this['dataCancelamento'] = data['dataCancelamento'];
        this['mensagemRejeicao'] = data['mensagemRejeicao'];
        this['dataEnvioParceiro'] = data['dataEnvioParceiro'];
        this['dataCriacao'] = data['dataCriacao'];
    }
}
export class OutConsultarProduto {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['idProduto'] = data['idProduto'];
        this['descricao'] = data['descricao'];
        this['tipoConta'] = data['tipoConta'];
        this['tipoSegmento'] = data['tipoSegmento'];
        this['idProdutoProcessadora'] = data['idProdutoProcessadora'];
        this['idOrigemComercialProcessadora'] = data['idOrigemComercialProcessadora'];
        this['organizationUnitId'] = data['organizationUnitId'];
        this['autorizacaoInterna'] = data['autorizacaoInterna'];
        this['servicos'] = data['servicos'];
    }
}
export class OutConsultarProdutoAddOutputPaginated {
    constructor(data = {}) {
        this['items'] = data['items'];
        this['qtdPaginas'] = data['qtdPaginas'];
        this['qtdPorPagina'] = data['qtdPorPagina'];
        this['totalItems'] = data['totalItems'];
        this['pagina'] = data['pagina'];
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
    }
}
export class OutConsultarProdutoInvestimento {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['indice'] = data['indice'];
        this['percentualDoIndice'] = data['percentualDoIndice'];
        this['investimentoMinimo'] = data['investimentoMinimo'];
        this['investimentoMaximo'] = data['investimentoMaximo'];
        this['liquidez'] = data['liquidez'];
        this['dataFim'] = data['dataFim'];
    }
}
export class OutConsultarServicosConta {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['servicos'] = data['servicos'];
    }
}
export class OutConsultarSituacaoContratacao {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['statusCadastro'] = data['statusCadastro'];
    }
}
export class OutConsultarTarifaOperacao {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['tarifasOperacao'] = data['tarifasOperacao'];
    }
}
export class OutConsultarTarifas {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['limitesTarifas'] = data['limitesTarifas'];
    }
}
export class OutConsultarTarifasConta {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['tarifasConta'] = data['tarifasConta'];
    }
}
export class OutConsultarUnidadeOrganizacional {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['unidadesOrganizacionais'] = data['unidadesOrganizacionais'];
    }
}
export class OutConsultarUnidadeOrganizacionalComFiltros {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['unidadesOrganizacionais'] = data['unidadesOrganizacionais'];
    }
}
export class OutConsultarUsuariosUnidadeOrganizacional {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['usuarios'] = data['usuarios'];
    }
}
export class OutContaCompleta {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['subconta'] = data['subconta'];
        this['chaveDeConta'] = data['chaveDeConta'];
        this['chaveDeCliente'] = data['chaveDeCliente'];
        this['chaveDeProduto'] = data['chaveDeProduto'];
        this['portadorId'] = data['portadorId'];
        this['descricaoConta'] = data['descricaoConta'];
        this['dataAbertura'] = data['dataAbertura'];
        this['dataEncerramento'] = data['dataEncerramento'];
        this['nomeCliente'] = data['nomeCliente'];
        this['apelido'] = data['apelido'];
        this['identificacaoFiscal'] = data['identificacaoFiscal'];
        this['descricaoProduto'] = data['descricaoProduto'];
        this['contaBancarizada'] = data['contaBancarizada'];
        this['idTipoContaBancaria'] = data['idTipoContaBancaria'];
        this['descricaoTipoContaBancaria'] = data['descricaoTipoContaBancaria'];
        this['idStatusConta'] = data['idStatusConta'];
        this['descricaoStatusConta'] = data['descricaoStatusConta'];
        this['bloqueadaPorPinIncorreto'] = data['bloqueadaPorPinIncorreto'];
        this['idContaDigital'] = data['idContaDigital'];
        this['organizationUnitId'] = data['organizationUnitId'];
        this['organizationUnitDescricao'] = data['organizationUnitDescricao'];
        this['precisaMigracao'] = data['precisaMigracao'];
        this['produtoParaMigracao'] = data['produtoParaMigracao'];
        this['possuiCreditoClean'] = data['possuiCreditoClean'];
    }
}
export class OutContaCompletaIntegradores {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['numContaArbi'] = data['numContaArbi'];
        this['autorizacaoInterna'] = data['autorizacaoInterna'];
    }
}
export class OutContaSaldo {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['saldo'] = data['saldo'];
        this['saldoBloqueado'] = data['saldoBloqueado'];
        this['dataConsulta'] = data['dataConsulta'];
    }
}
export class OutContaTesouraria {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['chaveDeConta'] = data['chaveDeConta'];
        this['nome'] = data['nome'];
        this['saldo'] = data['saldo'];
        this['descricao'] = data['descricao'];
        this['organizationUnitId'] = data['organizationUnitId'];
        this['limiteMaximo'] = data['limiteMaximo'];
        this['limiteDisponivel'] = data['limiteDisponivel'];
    }
}
export class OutContaTesourariaAddOutputPaginated {
    constructor(data = {}) {
        this['items'] = data['items'];
        this['qtdPaginas'] = data['qtdPaginas'];
        this['qtdPorPagina'] = data['qtdPorPagina'];
        this['totalItems'] = data['totalItems'];
        this['pagina'] = data['pagina'];
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
    }
}
export class OutContratoCreditoClean {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['contractId'] = data['contractId'];
        this['contractDocumentText'] = data['contractDocumentText'];
        this['taxId'] = data['taxId'];
        this['companyName'] = data['companyName'];
    }
}
export class OutContratoMobile {
    constructor(data = {}) {
        this['idContrato'] = data['idContrato'];
        this['primeiroVencimento'] = data['primeiroVencimento'];
        this['ultimoVencimento'] = data['ultimoVencimento'];
        this['valorRecebivel'] = data['valorRecebivel'];
        this['statusContrato'] = data['statusContrato'];
    }
}
export class OutContratosFinanciamento {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['contratos'] = data['contratos'];
    }
}
export class OutControleLimitesCartao {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['id'] = data['id'];
        this['idCartao'] = data['idCartao'];
        this['limiteMensal'] = data['limiteMensal'];
        this['saldoDisponivelMensal'] = data['saldoDisponivelMensal'];
        this['limiteSemanal'] = data['limiteSemanal'];
        this['saldoDisponivelSemanal'] = data['saldoDisponivelSemanal'];
        this['limiteDiario'] = data['limiteDiario'];
        this['saldoDisponivelDiario'] = data['saldoDisponivelDiario'];
        this['limiteCiclo'] = data['limiteCiclo'];
        this['saldoDisponivelCiclo'] = data['saldoDisponivelCiclo'];
        this['dataHoraUltimaCompra'] = data['dataHoraUltimaCompra'];
        this['ativo'] = data['ativo'];
    }
}
export class OutConvenioPublicoDto {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['convenios'] = data['convenios'];
        this['totalItens'] = data['totalItens'];
        this['paginaAtual'] = data['paginaAtual'];
        this['totalPaginas'] = data['totalPaginas'];
        this['itemsPorPagina'] = data['itemsPorPagina'];
    }
}
export class OutConvenioPublicoDtoAddOutputPaginated {
    constructor(data = {}) {
        this['items'] = data['items'];
        this['qtdPaginas'] = data['qtdPaginas'];
        this['qtdPorPagina'] = data['qtdPorPagina'];
        this['totalItems'] = data['totalItems'];
        this['pagina'] = data['pagina'];
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
    }
}
export class OutCriarPortador {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['id'] = data['id'];
    }
}
export class OutCriarProduto {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['idProduto'] = data['idProduto'];
        this['descricao'] = data['descricao'];
        this['tipoConta'] = data['tipoConta'];
        this['tipoSegmento'] = data['tipoSegmento'];
        this['idProdutoProcessadora'] = data['idProdutoProcessadora'];
        this['idOrigemComercialProcessadora'] = data['idOrigemComercialProcessadora'];
        this['autorizacaoInterna'] = data['autorizacaoInterna'];
    }
}
export class OutCriarToken {
    constructor(data = {}) {
        this['email'] = data['email'];
    }
}
export class OutCriarUsuario {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['chaveDeCliente'] = data['chaveDeCliente'];
    }
}
export class OutDadosCartoesSubcontas {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['items'] = data['items'];
        this['totalCount'] = data['totalCount'];
    }
}
export class OutDadosCliente {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['chaveDeCliente'] = data['chaveDeCliente'];
        this['statusCadastroClienteDescricao'] = data['statusCadastroClienteDescricao'];
        this['statusCadastroClienteId'] = data['statusCadastroClienteId'];
        this['nome'] = data['nome'];
        this['apelido'] = data['apelido'];
        this['identificacaoFiscal'] = data['identificacaoFiscal'];
        this['organizationUnitDescricao'] = data['organizationUnitDescricao'];
        this['organizationUnitId'] = data['organizationUnitId'];
        this['email'] = data['email'];
        this['emailConfirmado'] = data['emailConfirmado'];
        this['dataNascimento'] = data['dataNascimento'];
        this['nomeMae'] = data['nomeMae'];
        this['nomePai'] = data['nomePai'];
        this['nacionalidade'] = data['nacionalidade'];
        this['sexo'] = data['sexo'];
        this['endereco'] = data['endereco'];
        this['enderecoCorrespondencia'] = data['enderecoCorrespondencia'];
        this['rg'] = data['rg'];
        this['telefoneFixo'] = data['telefoneFixo'];
        this['telefoneMovel'] = data['telefoneMovel'];
        this['pessoaPoliticamenteExposta'] = data['pessoaPoliticamenteExposta'];
        this['rendaMensal'] = data['rendaMensal'];
        this['ultimoTipoDocumentoValido'] = data['ultimoTipoDocumentoValido'];
        this['statusClienteId'] = data['statusClienteId'];
        this['statusClienteDescricao'] = data['statusClienteDescricao'];
        this['detalhesBloqueio'] = data['detalhesBloqueio'];
        this['statusCliente'] = data['statusCliente'];
        this['detalhesCancelamento'] = data['detalhesCancelamento'];
    }
}
export class OutDadosClientePJ {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['nomeFantasia'] = data['nomeFantasia'];
        this['razaoSocial'] = data['razaoSocial'];
        this['identificacaoFiscal'] = data['identificacaoFiscal'];
        this['chaveDeCliente'] = data['chaveDeCliente'];
        this['organizationUnitDescricao'] = data['organizationUnitDescricao'];
        this['organizationUnitId'] = data['organizationUnitId'];
        this['statusCadastroClienteDescricao'] = data['statusCadastroClienteDescricao'];
        this['statusCadastroClienteId'] = data['statusCadastroClienteId'];
        this['email'] = data['email'];
        this['naturezaJuridica'] = data['naturezaJuridica'];
        this['porte'] = data['porte'];
        this['grupo'] = data['grupo'];
        this['cnae'] = data['cnae'];
        this['cnaeDescricao'] = data['cnaeDescricao'];
        this['capitalSocial'] = data['capitalSocial'];
        this['origemCapitalSocial'] = data['origemCapitalSocial'];
        this['dataInicioAtividade'] = data['dataInicioAtividade'];
        this['endereco'] = data['endereco'];
        this['telefoneFixo'] = data['telefoneFixo'];
        this['telefoneMovel'] = data['telefoneMovel'];
        this['socios'] = data['socios'];
        this['statusClienteId'] = data['statusClienteId'];
        this['statusClienteDescricao'] = data['statusClienteDescricao'];
        this['detalhesBloqueio'] = data['detalhesBloqueio'];
        this['statusClienteEnum'] = data['statusClienteEnum'];
        this['detalhesCancelamento'] = data['detalhesCancelamento'];
    }
}
export class OutDadosClientePJAddOutputPaginated {
    constructor(data = {}) {
        this['items'] = data['items'];
        this['qtdPaginas'] = data['qtdPaginas'];
        this['qtdPorPagina'] = data['qtdPorPagina'];
        this['totalItems'] = data['totalItems'];
        this['pagina'] = data['pagina'];
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
    }
}
export class OutDadosComprovante {
    constructor(data = {}) {
        this['transacaoId'] = data['transacaoId'];
        this['tipoOperacao'] = data['tipoOperacao'];
        this['nomePagador'] = data['nomePagador'];
        this['identificacaoFiscalPagador'] = data['identificacaoFiscalPagador'];
        this['bancoDebito'] = data['bancoDebito'];
        this['bancoDebitoDescricao'] = data['bancoDebitoDescricao'];
        this['agenciaDebito'] = data['agenciaDebito'];
        this['contaDebito'] = data['contaDebito'];
        this['valor'] = data['valor'];
        this['tarifa'] = data['tarifa'];
        this['nomeFavorecido'] = data['nomeFavorecido'];
        this['identificacaoFiscalFavorecido'] = data['identificacaoFiscalFavorecido'];
        this['bancoDestino'] = data['bancoDestino'];
        this['bancoDestinoDescricao'] = data['bancoDestinoDescricao'];
        this['instituicaoDestino'] = data['instituicaoDestino'];
        this['instituicaoDestinoDescricao'] = data['instituicaoDestinoDescricao'];
        this['agenciaDestino'] = data['agenciaDestino'];
        this['contaDestino'] = data['contaDestino'];
        this['chavePixDestino'] = data['chavePixDestino'];
        this['endToEndPix'] = data['endToEndPix'];
        this['descricaoPix'] = data['descricaoPix'];
        this['linhaDigitavelBoleto'] = data['linhaDigitavelBoleto'];
        this['dataVencimentoBoleto'] = data['dataVencimentoBoleto'];
        this['operadoraRecarga'] = data['operadoraRecarga'];
        this['operadoraRecargaDescricao'] = data['operadoraRecargaDescricao'];
        this['ddiRecarga'] = data['ddiRecarga'];
        this['dddRecarga'] = data['dddRecarga'];
        this['numeroRecarga'] = data['numeroRecarga'];
        this['identificadorSaque'] = data['identificadorSaque'];
    }
}
export class OutDadosContratoMobile {
    constructor(data = {}) {
        this['idContrato'] = data['idContrato'];
        this['primeiroVencimento'] = data['primeiroVencimento'];
        this['ultimoVencimento'] = data['ultimoVencimento'];
        this['valorRecebivel'] = data['valorRecebivel'];
        this['statusContrato'] = data['statusContrato'];
        this['dataFinanciamento'] = data['dataFinanciamento'];
        this['valorTotalFinanciado'] = data['valorTotalFinanciado'];
        this['valorEmAberto'] = data['valorEmAberto'];
        this['valorPago'] = data['valorPago'];
        this['numeroDeParcelas'] = data['numeroDeParcelas'];
        this['numeroDeParcelasPagas'] = data['numeroDeParcelasPagas'];
        this['numeroDeParcelasAbertas'] = data['numeroDeParcelasAbertas'];
        this['modelo'] = data['modelo'];
        this['statusAparelho'] = data['statusAparelho'];
        this['dataProximoVencimento'] = data['dataProximoVencimento'];
        this['emAtraso'] = data['emAtraso'];
    }
}
export class OutDadosQrCodePix {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['agenciaBeneficiario'] = data['agenciaBeneficiario'];
        this['chaveEnderecamento'] = data['chaveEnderecamento'];
        this['codInstituicaoBeneficiario'] = data['codInstituicaoBeneficiario'];
        this['contaBeneficiario'] = data['contaBeneficiario'];
        this['dataExpiracao'] = data['dataExpiracao'];
        this['dataVencimento'] = data['dataVencimento'];
        this['descricao'] = data['descricao'];
        this['documentoId'] = data['documentoId'];
        this['documentoRevisao'] = data['documentoRevisao'];
        this['endToEnd'] = data['endToEnd'];
        this['identificador'] = data['identificador'];
        this['identificaoFiscalBeneficiario'] = data['identificaoFiscalBeneficiario'];
        this['indPermiteAlteracao'] = data['indPermiteAlteracao'];
        this['indRecebivelAposVencimento'] = data['indRecebivelAposVencimento'];
        this['nomeBeneficiario'] = data['nomeBeneficiario'];
        this['nomeInstituicaoBeneficiario'] = data['nomeInstituicaoBeneficiario'];
        this['referencia'] = data['referencia'];
        this['tipoChaveBeneficiario'] = data['tipoChaveBeneficiario'];
        this['tipoContaBeneficiario'] = data['tipoContaBeneficiario'];
        this['tipoPessoaBeneficiario'] = data['tipoPessoaBeneficiario'];
        this['tipoQRCode'] = data['tipoQRCode'];
        this['valorOperacao'] = data['valorOperacao'];
        this['valorDesconto'] = data['valorDesconto'];
        this['valorFinal'] = data['valorFinal'];
        this['valorJuros'] = data['valorJuros'];
        this['valorMulta'] = data['valorMulta'];
        this['estabelecimento'] = data['estabelecimento'];
        this['cnpj'] = data['cnpj'];
        this['tipoPix'] = data['tipoPix'];
        this['valorSaqueTroco'] = data['valorSaqueTroco'];
        this['modalidadeAlteracao'] = data['modalidadeAlteracao'];
        this['modalidadeAlteracaoTrocoSaque'] = data['modalidadeAlteracaoTrocoSaque'];
        this['prestadorDoServicoDeSaque'] = data['prestadorDoServicoDeSaque'];
        this['modalidadeAgente'] = data['modalidadeAgente'];
    }
}
export class OutDadosSoftCliente {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['chaveDeCliente'] = data['chaveDeCliente'];
    }
}
export class OutDadosUnidadeOrganizacional {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['idContaTesouraria'] = data['idContaTesouraria'];
        this['idUnidadeOrganizacional'] = data['idUnidadeOrganizacional'];
        this['nome'] = data['nome'];
        this['pai'] = data['pai'];
        this['codigoUnidadeOrganizacional'] = data['codigoUnidadeOrganizacional'];
        this['chaveContaTesouraria'] = data['chaveContaTesouraria'];
        this['cnpj'] = data['cnpj'];
        this['razaoSocial'] = data['razaoSocial'];
        this['contatoNome'] = data['contatoNome'];
        this['contatoTelefone'] = data['contatoTelefone'];
        this['contatoEmail'] = data['contatoEmail'];
        this['contaCorrente'] = data['contaCorrente'];
        this['endereco'] = data['endereco'];
        this['autorizarAssinaturas'] = data['autorizarAssinaturas'];
        this['desativarMensageria'] = data['desativarMensageria'];
        this['processoGedId'] = data['processoGedId'];
        this['limiteMaximoDeCredito'] = data['limiteMaximoDeCredito'];
        this['limiteDisponivelDeCredito'] = data['limiteDisponivelDeCredito'];
        this['limiteDeContasPorIF'] = data['limiteDeContasPorIF'];
        this['permitirContaAdicionalPorIF'] = data['permitirContaAdicionalPorIF'];
        this['emailsNotificacoesFraudes'] = data['emailsNotificacoesFraudes'];
        this['possuiCreditoConsignado'] = data['possuiCreditoConsignado'];
        this['parcelaMaximaAntecipacao'] = data['parcelaMaximaAntecipacao'];
        this['parcelaMaximaCreditoConsignado'] = data['parcelaMaximaCreditoConsignado'];
    }
}
export class OutDestinoToken {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['destinos'] = data['destinos'];
    }
}
export class OutDetalheCargaMassiva {
    constructor(data = {}) {
        this['id'] = data['id'];
        this['organizationUnitId'] = data['organizationUnitId'];
        this['organizationUnitName'] = data['organizationUnitName'];
        this['nomeArquivo'] = data['nomeArquivo'];
        this['recusas'] = data['recusas'];
        this['status'] = data['status'];
        this['dataProcessamento'] = data['dataProcessamento'];
        this['totalRegistros'] = data['totalRegistros'];
        this['totalPendentes'] = data['totalPendentes'];
        this['totalProcessados'] = data['totalProcessados'];
        this['totalRecusados'] = data['totalRecusados'];
        this['userId'] = data['userId'];
        this['userName'] = data['userName'];
        this['motivoTransacao'] = data['motivoTransacao'];
        this['motivoTransacaoId'] = data['motivoTransacaoId'];
        this['tipoConta'] = data['tipoConta'];
    }
}
export class OutDetalheCargaMassivaAddOutputPaginated {
    constructor(data = {}) {
        this['items'] = data['items'];
        this['qtdPaginas'] = data['qtdPaginas'];
        this['qtdPorPagina'] = data['qtdPorPagina'];
        this['totalItems'] = data['totalItems'];
        this['pagina'] = data['pagina'];
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
    }
}
export class OutDetalheFaturaCreditoClean {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['items'] = data['items'];
        this['statusFatura'] = data['statusFatura'];
        this['dataVencimento'] = data['dataVencimento'];
        this['dataPagamento'] = data['dataPagamento'];
        this['totalNacional'] = data['totalNacional'];
        this['totalInternacional'] = data['totalInternacional'];
        this['cambio'] = data['cambio'];
        this['taxasTotais'] = data['taxasTotais'];
        this['saldoPendente'] = data['saldoPendente'];
        this['aPagarTotal'] = data['aPagarTotal'];
        this['creditoAnterior'] = data['creditoAnterior'];
        this['pagamentoMinimo'] = data['pagamentoMinimo'];
    }
}
export class OutDetalheFaturaFuturaCreditoClean {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['items'] = data['items'];
        this['dataVencimento'] = data['dataVencimento'];
        this['dataPagamento'] = data['dataPagamento'];
        this['totalNacional'] = data['totalNacional'];
        this['totalInternacional'] = data['totalInternacional'];
        this['aPagarTotal'] = data['aPagarTotal'];
    }
}
export class OutDetalhesChargeBack {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['contestacaoId'] = data['contestacaoId'];
        this['transacaoId'] = data['transacaoId'];
        this['processada'] = data['processada'];
        this['metodo'] = data['metodo'];
        this['detalhamento'] = data['detalhamento'];
        this['descricao'] = data['descricao'];
        this['arquivos'] = data['arquivos'];
    }
}
export class OutDetalhesExtrato {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['beneficiario'] = data['beneficiario'];
        this['descricao'] = data['descricao'];
    }
}
export class OutDetalhesSaldoOpenFinance {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['valorAdicionadoNoDia'] = data['valorAdicionadoNoDia'];
        this['conciliacaoEmExecucao'] = data['conciliacaoEmExecucao'];
        this['valorSaldoConsumido'] = data['valorSaldoConsumido'];
        this['valorCredito'] = data['valorCredito'];
        this['saldoDisponivel'] = data['saldoDisponivel'];
    }
}
export class OutDevolucaoPix {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['endToEnd'] = data['endToEnd'];
        this['idTransacao'] = data['idTransacao'];
        this['infoOrdemPagamento'] = data['infoOrdemPagamento'];
        this['statusOrdem'] = data['statusOrdem'];
        this['idComprovante'] = data['idComprovante'];
    }
}
export class OutDownloadArquivoRetorno {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['arquivo'] = data['arquivo'];
    }
}
export class OutDownloadRetornoImportacaoClientesBS {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['arquivo'] = data['arquivo'];
    }
}
export class OutEfetuarAgendamentoBoleto {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['idAgendamento'] = data['idAgendamento'];
        this['dataPagamento'] = data['dataPagamento'];
        this['statusId'] = data['statusId'];
        this['statusDescricao'] = data['statusDescricao'];
    }
}
export class OutEfetuarAgendamentoTed {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['items'] = data['items'];
        this['totalCount'] = data['totalCount'];
    }
}
export class OutEfetuarAgendamentoTransferenciaInterna {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['agendamentos'] = data['agendamentos'];
        this['statusId'] = data['statusId'];
        this['statusDescricao'] = data['statusDescricao'];
    }
}
export class OutEfetuarPagamentoBoletoNovo {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['statusPagamento'] = data['statusPagamento'];
        this['valorPago'] = data['valorPago'];
        this['idComprovante'] = data['idComprovante'];
        this['idTransacao'] = data['idTransacao'];
    }
}
export class OutEfetuarPagamentoDimoQrCode {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['transacaoId'] = data['transacaoId'];
    }
}
export class OutEfetuarTed {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['identificacaoTransacao'] = data['identificacaoTransacao'];
        this['idTransacaoInstituicaoFinanceira'] = data['idTransacaoInstituicaoFinanceira'];
        this['dataCriacao'] = data['dataCriacao'];
        this['dataEfetivacao'] = data['dataEfetivacao'];
        this['contaDestino'] = data['contaDestino'];
        this['identificacaoFiscal'] = data['identificacaoFiscal'];
        this['statusTransferencia'] = data['statusTransferencia'];
        this['idComprovante'] = data['idComprovante'];
    }
}
export class OutEfetuarTransferenciaArbi2Arbi {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['identificacaoTransacao'] = data['identificacaoTransacao'];
        this['dataCriacao'] = data['dataCriacao'];
        this['dataEfetivacao'] = data['dataEfetivacao'];
        this['statusTransferencia'] = data['statusTransferencia'];
        this['idComprovante'] = data['idComprovante'];
    }
}
export class OutEfetuarTransferenciaInterna {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['identificacaoTransacao'] = data['identificacaoTransacao'];
        this['dataCriacao'] = data['dataCriacao'];
        this['dataEfetivacao'] = data['dataEfetivacao'];
        this['statusTransferencia'] = data['statusTransferencia'];
        this['idComprovante'] = data['idComprovante'];
    }
}
export class OutEloOpaqueJson {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['opaqueJson'] = data['opaqueJson'];
    }
}
export class OutEmailTemplate {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['idUnidadeOrganizacionalTemplate'] = data['idUnidadeOrganizacionalTemplate'];
        this['idUnidadeOrganizacional'] = data['idUnidadeOrganizacional'];
        this['header'] = data['header'];
        this['footer'] = data['footer'];
    }
}
export class OutExtratoPix {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['endToEnd'] = data['endToEnd'];
        this['dataMovimento'] = data['dataMovimento'];
        this['nroMovimento'] = data['nroMovimento'];
        this['descricao'] = data['descricao'];
        this['valorOperacao'] = data['valorOperacao'];
        this['valorDevolucao'] = data['valorDevolucao'];
        this['nomeContraParte'] = data['nomeContraParte'];
        this['identificacaoFiscalContraParte'] = data['identificacaoFiscalContraParte'];
        this['codInstituicaoLancamento'] = data['codInstituicaoLancamento'];
        this['nomeInstituicaoLancamento'] = data['nomeInstituicaoLancamento'];
        this['agenciaLancamento'] = data['agenciaLancamento'];
        this['contaLancamento'] = data['contaLancamento'];
        this['natureza'] = data['natureza'];
        this['indDevolucao'] = data['indDevolucao'];
        this['indElegivelDevolucao'] = data['indElegivelDevolucao'];
        this['endToEndOriginal'] = data['endToEndOriginal'];
        this['chaveEnderecamento'] = data['chaveEnderecamento'];
        this['campoLivre'] = data['campoLivre'];
        this['identificacaoFiscal'] = data['identificacaoFiscal'];
        this['transacaoId'] = data['transacaoId'];
    }
}
export class OutExtratoTesouraria {
    constructor(data = {}) {
        this['tipoTransacaoTesouraria'] = data['tipoTransacaoTesouraria'];
        this['dataOperacao'] = data['dataOperacao'];
        this['valor'] = data['valor'];
        this['contaPortador'] = data['contaPortador'];
    }
}
export class OutExtratoTesourariaAddOutputPaginated {
    constructor(data = {}) {
        this['items'] = data['items'];
        this['qtdPaginas'] = data['qtdPaginas'];
        this['qtdPorPagina'] = data['qtdPorPagina'];
        this['totalItems'] = data['totalItems'];
        this['pagina'] = data['pagina'];
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
    }
}
export class OutFacematch {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['valido'] = data['valido'];
        this['percSimilaridade'] = data['percSimilaridade'];
    }
}
export class OutFeriados {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
    }
}
export class OutGenerico {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['result'] = data['result'];
    }
}
export class OutGerarBoletoFatura {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['nossoNumero'] = data['nossoNumero'];
        this['boleto'] = data['boleto'];
    }
}
export class OutGerarLinkOnboardingCliente {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['idTransacao'] = data['idTransacao'];
        this['idLink'] = data['idLink'];
        this['ativo'] = data['ativo'];
        this['token'] = data['token'];
        this['status'] = data['status'];
        this['templateId'] = data['templateId'];
        this['url'] = data['url'];
    }
}
export class OutImportacaoClientesBs {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['id'] = data['id'];
        this['organizationUnitId'] = data['organizationUnitId'];
        this['descricaoConta'] = data['descricaoConta'];
        this['tipoConta'] = data['tipoConta'];
        this['nomeArquivo'] = data['nomeArquivo'];
        this['status'] = data['status'];
    }
}
export class OutImportarAlteracaoGrupoMccCartoes {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['id'] = data['id'];
    }
}
export class OutImportarClientes {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['id'] = data['id'];
        this['organizationUnitId'] = data['organizationUnitId'];
        this['nomeArquivo'] = data['nomeArquivo'];
        this['status'] = data['status'];
    }
}
export class OutInfoContratoRenegociacao {
    constructor(data = {}) {
        this['contrato'] = data['contrato'];
        this['dataGeracaoContrato'] = data['dataGeracaoContrato'];
        this['valorContrato'] = data['valorContrato'];
    }
}
export class OutInfoRenegociacao {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['valorRecebivel'] = data['valorRecebivel'];
        this['prazo'] = data['prazo'];
        this['financiado'] = data['financiado'];
        this['liberado'] = data['liberado'];
        this['taxaIofAnual'] = data['taxaIofAnual'];
        this['taxaIofComp'] = data['taxaIofComp'];
        this['taxaContratoMensal'] = data['taxaContratoMensal'];
        this['cetMensal'] = data['cetMensal'];
        this['cetAnual'] = data['cetAnual'];
        this['totalIof'] = data['totalIof'];
        this['totalJuros'] = data['totalJuros'];
        this['valorTotalContrato'] = data['valorTotalContrato'];
        this['saldoDevedor'] = data['saldoDevedor'];
    }
}
export class OutInformacoesUsoCreditoClean {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['faturaId'] = data['faturaId'];
        this['limiteDisponivel'] = data['limiteDisponivel'];
        this['limiteUtilizado'] = data['limiteUtilizado'];
        this['limiteTotal'] = data['limiteTotal'];
        this['debitoAutomatico'] = data['debitoAutomatico'];
        this['melhorDataDeCompra'] = data['melhorDataDeCompra'];
        this['statusFatura'] = data['statusFatura'];
        this['valor'] = data['valor'];
    }
}
export class OutLimitesClientesList {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['limitesClientes'] = data['limitesClientes'];
    }
}
export class OutLimitesClientesUltimasTres {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['outClientesLimitesLists'] = data['outClientesLimitesLists'];
    }
}
export class OutListaAssinaturasEventos {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['assinaturas'] = data['assinaturas'];
    }
}
export class OutListaChavesPix {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['chaves'] = data['chaves'];
    }
}
export class OutListaContas {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['contas'] = data['contas'];
    }
}
export class OutListaDispositivos {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['dispositivos'] = data['dispositivos'];
    }
}
export class OutListaEnum {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['listaEnum'] = data['listaEnum'];
    }
}
export class OutListaExtratoCompleto {
    constructor(data = {}) {
        this['processadoraId'] = data['processadoraId'];
        this['transacaoId'] = data['transacaoId'];
        this['dataHoraTransacao'] = data['dataHoraTransacao'];
        this['descricaoAbreviada'] = data['descricaoAbreviada'];
        this['descricaoEstabelecimento'] = data['descricaoEstabelecimento'];
        this['nomeEstabelecimento'] = data['nomeEstabelecimento'];
        this['nomeFantasiaEstabelecimento'] = data['nomeFantasiaEstabelecimento'];
        this['descricaoTipoTransacao'] = data['descricaoTipoTransacao'];
        this['numeroCartaoMascarado'] = data['numeroCartaoMascarado'];
        this['motivoTransacaoId'] = data['motivoTransacaoId'];
        this['motivoTransacao'] = data['motivoTransacao'];
        this['valorBRL'] = data['valorBRL'];
        this['valorUSD'] = data['valorUSD'];
        this['processada'] = data['processada'];
        this['detalhe'] = data['detalhe'];
        this['tarifa'] = data['tarifa'];
        this['temComprovante'] = data['temComprovante'];
    }
}
export class OutListaExtratoCompletoAddOutputPaginated {
    constructor(data = {}) {
        this['items'] = data['items'];
        this['qtdPaginas'] = data['qtdPaginas'];
        this['qtdPorPagina'] = data['qtdPorPagina'];
        this['totalItems'] = data['totalItems'];
        this['pagina'] = data['pagina'];
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
    }
}
export class OutListaExtratosPix {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['extratos'] = data['extratos'];
    }
}
export class OutListaLancamentosFuturos {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['items'] = data['items'];
        this['totalCount'] = data['totalCount'];
    }
}
export class OutListaMotivoSegVia {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['listaMotivoSegundaVia'] = data['listaMotivoSegundaVia'];
    }
}
export class OutListaProdutoOrganizacao {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['listaProdutosParceiros'] = data['listaProdutosParceiros'];
    }
}
export class OutListaProdutosDisponiveis {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['produtos'] = data['produtos'];
    }
}
export class OutListaProdutosProcessadora {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['produtosProcessadora'] = data['produtosProcessadora'];
    }
}
export class OutListaReivindicacoesPix {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['reivindicacoes'] = data['reivindicacoes'];
    }
}
export class OutListaServico {
    constructor(data = {}) {
        this['id'] = data['id'];
        this['descricao'] = data['descricao'];
        this['url'] = data['url'];
    }
}
export class OutListaTipoAdquirencia {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['tipoAdquirencia'] = data['tipoAdquirencia'];
    }
}
export class OutListaTiposWebhook {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['listaTiposWebhook'] = data['listaTiposWebhook'];
    }
}
export class OutListaUsuarios {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['clientes'] = data['clientes'];
        this['exibirPoliticaPrivacidade'] = data['exibirPoliticaPrivacidade'];
    }
}
export class OutListaValoresProvedor {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['valores'] = data['valores'];
    }
}
export class OutListarAgendamentosRecargaCelular {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['idAgendamento'] = data['idAgendamento'];
        this['dataUltimaAlteracao'] = data['dataUltimaAlteracao'];
        this['dataAgendamento'] = data['dataAgendamento'];
        this['status'] = data['status'];
        this['provedorId'] = data['provedorId'];
        this['ddi'] = data['ddi'];
        this['ddd'] = data['ddd'];
        this['numero'] = data['numero'];
        this['valor'] = data['valor'];
        this['descricaoExecucao'] = data['descricaoExecucao'];
    }
}
export class OutListarAgendamentosRecargaCelularAddOutputPaginated {
    constructor(data = {}) {
        this['items'] = data['items'];
        this['qtdPaginas'] = data['qtdPaginas'];
        this['qtdPorPagina'] = data['qtdPorPagina'];
        this['totalItems'] = data['totalItems'];
        this['pagina'] = data['pagina'];
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
    }
}
export class OutListarAlteracoesGrupoMccCartoes {
    constructor(data = {}) {
        this['id'] = data['id'];
        this['chaveDeProduto'] = data['chaveDeProduto'];
        this['gruposMcc'] = data['gruposMcc'];
        this['descricaoProduto'] = data['descricaoProduto'];
        this['nomeArquivo'] = data['nomeArquivo'];
        this['status'] = data['status'];
        this['dataCriacao'] = data['dataCriacao'];
        this['dataProcessamento'] = data['dataProcessamento'];
        this['usuarioResponsavel'] = data['usuarioResponsavel'];
        this['usuarioResponsavelId'] = data['usuarioResponsavelId'];
        this['totalRegistros'] = data['totalRegistros'];
        this['totalPendentes'] = data['totalPendentes'];
        this['totalImportados'] = data['totalImportados'];
        this['totalRecusados'] = data['totalRecusados'];
    }
}
export class OutListarAlteracoesGrupoMccCartoesAddOutputPaginated {
    constructor(data = {}) {
        this['items'] = data['items'];
        this['qtdPaginas'] = data['qtdPaginas'];
        this['qtdPorPagina'] = data['qtdPorPagina'];
        this['totalItems'] = data['totalItems'];
        this['pagina'] = data['pagina'];
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
    }
}
export class OutListarAtmsProximos {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['listaAtms'] = data['listaAtms'];
    }
}
export class OutListarBoletos {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['listaBoletos'] = data['listaBoletos'];
    }
}
export class OutListarCliente {
    constructor(data = {}) {
        this['chaveDeCliente'] = data['chaveDeCliente'];
        this['nome'] = data['nome'];
        this['identificacaoFiscal'] = data['identificacaoFiscal'];
        this['status'] = data['status'];
        this['organizationUnitId'] = data['organizationUnitId'];
        this['organizationUnitDescricao'] = data['organizationUnitDescricao'];
        this['statusClienteId'] = data['statusClienteId'];
        this['statusClienteDescricao'] = data['statusClienteDescricao'];
    }
}
export class OutListarClienteAddOutputPaginated {
    constructor(data = {}) {
        this['items'] = data['items'];
        this['qtdPaginas'] = data['qtdPaginas'];
        this['qtdPorPagina'] = data['qtdPorPagina'];
        this['totalItems'] = data['totalItems'];
        this['pagina'] = data['pagina'];
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
    }
}
export class OutListarClientesResumido {
    constructor(data = {}) {
        this['dataAlteracao'] = data['dataAlteracao'];
        this['chaveDeCliente'] = data['chaveDeCliente'];
        this['identificacaoFiscal'] = data['identificacaoFiscal'];
        this['nome'] = data['nome'];
        this['email'] = data['email'];
        this['telefoneMovel'] = data['telefoneMovel'];
    }
}
export class OutListarClientesResumidoAddOutputPaginated {
    constructor(data = {}) {
        this['items'] = data['items'];
        this['qtdPaginas'] = data['qtdPaginas'];
        this['qtdPorPagina'] = data['qtdPorPagina'];
        this['totalItems'] = data['totalItems'];
        this['pagina'] = data['pagina'];
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
    }
}
export class OutListarFaturas {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['items'] = data['items'];
    }
}
export class OutListarFaturasFuturas {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['items'] = data['items'];
    }
}
export class OutListarPortabilidade {
    constructor(data = {}) {
        this['chaveDePortabilidade'] = data['chaveDePortabilidade'];
        this['identificacaoFiscalEmpresaOrigem'] = data['identificacaoFiscalEmpresaOrigem'];
        this['razaoSocialEmpresaOrigem'] = data['razaoSocialEmpresaOrigem'];
        this['codigoBancoOrigem'] = data['codigoBancoOrigem'];
        this['nomeBancoOrigem'] = data['nomeBancoOrigem'];
        this['situacaoPortabilidade'] = data['situacaoPortabilidade'];
        this['situacaoPortabilidadeCip'] = data['situacaoPortabilidadeCip'];
        this['motivoCancelamento'] = data['motivoCancelamento'];
        this['statusPortabilidade'] = data['statusPortabilidade'];
        this['motivoCancelamentoDescricao'] = data['motivoCancelamentoDescricao'];
        this['complementoMotivoCancelamento'] = data['complementoMotivoCancelamento'];
        this['dataCancelamento'] = data['dataCancelamento'];
        this['dataCriacao'] = data['dataCriacao'];
        this['dataAtualizacao'] = data['dataAtualizacao'];
        this['dataEnvioParceiro'] = data['dataEnvioParceiro'];
    }
}
export class OutListarPortabilidadeAddOutputPaginated {
    constructor(data = {}) {
        this['items'] = data['items'];
        this['qtdPaginas'] = data['qtdPaginas'];
        this['qtdPorPagina'] = data['qtdPorPagina'];
        this['totalItems'] = data['totalItems'];
        this['pagina'] = data['pagina'];
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
    }
}
export class OutListarPortadores {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['portadores'] = data['portadores'];
    }
}
export class OutListarSegmentosBeneficio {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['segmentosBeneficio'] = data['segmentosBeneficio'];
    }
}
export class OutLoteProcessamentoArquivo {
    constructor(data = {}) {
        this['id'] = data['id'];
        this['organizationUnitId'] = data['organizationUnitId'];
        this['organizationUnitName'] = data['organizationUnitName'];
        this['nomeArquivo'] = data['nomeArquivo'];
        this['recusas'] = data['recusas'];
        this['status'] = data['status'];
        this['dataProcessamento'] = data['dataProcessamento'];
        this['totalRegistros'] = data['totalRegistros'];
        this['totalPendentes'] = data['totalPendentes'];
        this['totalProcessados'] = data['totalProcessados'];
        this['totalRecusados'] = data['totalRecusados'];
        this['userId'] = data['userId'];
        this['userName'] = data['userName'];
    }
}
export class OutLoteProcessamentoArquivoAddOutputPaginated {
    constructor(data = {}) {
        this['items'] = data['items'];
        this['qtdPaginas'] = data['qtdPaginas'];
        this['qtdPorPagina'] = data['qtdPorPagina'];
        this['totalItems'] = data['totalItems'];
        this['pagina'] = data['pagina'];
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
    }
}
export class OutMargemAntecipacao {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['parcelas'] = data['parcelas'];
    }
}
export class OutMargemDisponivelAntecipacao {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['margemDisponivelTotal'] = data['margemDisponivelTotal'];
        this['margemDisponivelMinimo'] = data['margemDisponivelMinimo'];
    }
}
export class OutModificarTEDAgendada {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['dataAlterada'] = data['dataAlterada'];
        this['idAgendamento'] = data['idAgendamento'];
        this['novoValor'] = data['novoValor'];
        this['statusTransferencia'] = data['statusTransferencia'];
    }
}
export class OutNecessitaValidacaoFaceMatch {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['validacaoFaceMatch'] = data['validacaoFaceMatch'];
    }
}
export class OutNotificacoesClientes {
    constructor(data = {}) {
        this['notificacaoId'] = data['notificacaoId'];
        this['chaveDeCliente'] = data['chaveDeCliente'];
        this['chaveDeConta'] = data['chaveDeConta'];
        this['mensagem'] = data['mensagem'];
        this['assunto'] = data['assunto'];
        this['dataEnvio'] = data['dataEnvio'];
        this['visualizada'] = data['visualizada'];
    }
}
export class OutNotificacoesClientesAddOutputPaginated {
    constructor(data = {}) {
        this['items'] = data['items'];
        this['qtdPaginas'] = data['qtdPaginas'];
        this['qtdPorPagina'] = data['qtdPorPagina'];
        this['totalItems'] = data['totalItems'];
        this['pagina'] = data['pagina'];
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
    }
}
export class OutObterAsset {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['base64'] = data['base64'];
    }
}
export class OutObterAssetsMobile {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['config'] = data['config'];
    }
}
export class OutObterBloqueio {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['bloqueioId'] = data['bloqueioId'];
        this['organizationUnitId'] = data['organizationUnitId'];
        this['produtoId'] = data['produtoId'];
        this['descricao'] = data['descricao'];
        this['motivoTransacao'] = data['motivoTransacao'];
        this['periodoLimite'] = data['periodoLimite'];
        this['tipoContagemLimite'] = data['tipoContagemLimite'];
        this['limiteInferior'] = data['limiteInferior'];
        this['limiteSuperior'] = data['limiteSuperior'];
        this['tipoPessoaTarifa'] = data['tipoPessoaTarifa'];
        this['aplicarLimiteAFilhos'] = data['aplicarLimiteAFilhos'];
    }
}
export class OutObterCodigoIndicacao {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['codigoIndicacao'] = data['codigoIndicacao'];
    }
}
export class OutObterComprovante {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['comprovanteId'] = data['comprovanteId'];
        this['chaveDeConta'] = data['chaveDeConta'];
        this['organizationUnitId'] = data['organizationUnitId'];
        this['dataOperacao'] = data['dataOperacao'];
        this['textoComprovante'] = data['textoComprovante'];
        this['autenticacao'] = data['autenticacao'];
        this['motivoTransacaoId'] = data['motivoTransacaoId'];
        this['motivoTransacao'] = data['motivoTransacao'];
        this['dadosComprovante'] = data['dadosComprovante'];
        this['arquivo'] = data['arquivo'];
    }
}
export class OutObterConfiguracaoContactless {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['valor'] = data['valor'];
        this['permiteContactless'] = data['permiteContactless'];
    }
}
export class OutObterControleConfig {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['idConfiguracao'] = data['idConfiguracao'];
        this['idCartao'] = data['idCartao'];
        this['permiteEcommerce'] = data['permiteEcommerce'];
        this['permiteSaque'] = data['permiteSaque'];
        this['permiteWallet'] = data['permiteWallet'];
        this['permiteControleMCC'] = data['permiteControleMCC'];
        this['permiteCompraInternacional'] = data['permiteCompraInternacional'];
        this['permiteTarjaMagnetica'] = data['permiteTarjaMagnetica'];
        this['permiteContactless'] = data['permiteContactless'];
        this['limiteContactlessSemSenha'] = data['limiteContactlessSemSenha'];
        this['funcaoAtiva'] = data['funcaoAtiva'];
    }
}
export class OutObterDadosCartao {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['items'] = data['items'];
        this['totalCount'] = data['totalCount'];
    }
}
export class OutObterInformeRendimentos {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['informes'] = data['informes'];
        this['base64Pdf'] = data['base64Pdf'];
    }
}
export class OutObterParametroGenerico {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['parametro'] = data['parametro'];
        this['valor'] = data['valor'];
    }
}
export class OutObterProduto {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['idProduto'] = data['idProduto'];
        this['descricao'] = data['descricao'];
        this['tipoConta'] = data['tipoConta'];
        this['tipoSegmento'] = data['tipoSegmento'];
        this['idProdutoProcessadora'] = data['idProdutoProcessadora'];
        this['idOrigemComercialProcessadora'] = data['idOrigemComercialProcessadora'];
        this['organizationUnitId'] = data['organizationUnitId'];
        this['autorizacaoInterna'] = data['autorizacaoInterna'];
        this['servicos'] = data['servicos'];
        this['descricaoProdutoProcessadora'] = data['descricaoProdutoProcessadora'];
        this['chaveDeProduto'] = data['chaveDeProduto'];
    }
}
export class OutObterTarifa {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['tarifaId'] = data['tarifaId'];
        this['organizationUnitId'] = data['organizationUnitId'];
        this['produtoId'] = data['produtoId'];
        this['descricao'] = data['descricao'];
        this['motivoTransacao'] = data['motivoTransacao'];
        this['motivoTransacaoTarifa'] = data['motivoTransacaoTarifa'];
        this['periodoLimite'] = data['periodoLimite'];
        this['tipoContagemLimite'] = data['tipoContagemLimite'];
        this['limiteInferior'] = data['limiteInferior'];
        this['limiteSuperior'] = data['limiteSuperior'];
        this['tipoPessoaTarifa'] = data['tipoPessoaTarifa'];
        this['aplicarLimiteAFilhos'] = data['aplicarLimiteAFilhos'];
        this['tipoValor'] = data['tipoValor'];
        this['valorTarifa'] = data['valorTarifa'];
        this['localDebitar'] = data['localDebitar'];
    }
}
export class OutObterTermo {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['termo'] = data['termo'];
        this['generico'] = data['generico'];
        this['tipoDeTermo'] = data['tipoDeTermo'];
        this['extensaoDoArquivo'] = data['extensaoDoArquivo'];
    }
}
export class OutObterTermoAssinado {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['link'] = data['link'];
        this['tipoDeTermo'] = data['tipoDeTermo'];
        this['extensaoDoArquivo'] = data['extensaoDoArquivo'];
    }
}
export class OutObterUltimasTresTransacoes {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['items'] = data['items'];
    }
}
export class OutOrdemBloqueioJudicial {
    constructor(data = {}) {
        this['id'] = data['id'];
        this['contaId'] = data['contaId'];
        this['identificacaoFiscal'] = data['identificacaoFiscal'];
        this['nome'] = data['nome'];
        this['tipoBloqueioJudicial'] = data['tipoBloqueioJudicial'];
        this['statusBloqueioJudicial'] = data['statusBloqueioJudicial'];
        this['dataOrdem'] = data['dataOrdem'];
        this['valorSolicitado'] = data['valorSolicitado'];
        this['valorAtendido'] = data['valorAtendido'];
        this['numeroProtocolo'] = data['numeroProtocolo'];
        this['numeroProcesso'] = data['numeroProcesso'];
        this['numeroVara'] = data['numeroVara'];
        this['nomeVara'] = data['nomeVara'];
        this['tribunal'] = data['tribunal'];
    }
}
export class OutOrdemBloqueioJudicialAddOutputPaginated {
    constructor(data = {}) {
        this['items'] = data['items'];
        this['qtdPaginas'] = data['qtdPaginas'];
        this['qtdPorPagina'] = data['qtdPorPagina'];
        this['totalItems'] = data['totalItems'];
        this['pagina'] = data['pagina'];
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
    }
}
export class OutPagamentoCreditoClean {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['dataEmissao'] = data['dataEmissao'];
        this['dataVencimento'] = data['dataVencimento'];
        this['dataFinalizado'] = data['dataFinalizado'];
        this['parcelamentoId'] = data['parcelamentoId'];
        this['statusBoletoDescricao'] = data['statusBoletoDescricao'];
        this['statusBoletoId'] = data['statusBoletoId'];
        this['linhaDigitavel'] = data['linhaDigitavel'];
        this['codigoDeBarras'] = data['codigoDeBarras'];
        this['nossoNumero'] = data['nossoNumero'];
        this['arquivo'] = data['arquivo'];
        this['codigoCopiaCola'] = data['codigoCopiaCola'];
    }
}
export class OutPagamentoPix {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['endToEnd'] = data['endToEnd'];
        this['idTransacao'] = data['idTransacao'];
        this['infoOrdemPagamento'] = data['infoOrdemPagamento'];
        this['statusOrdem'] = data['statusOrdem'];
        this['idComprovante'] = data['idComprovante'];
        this['agenciaBeneficiario'] = data['agenciaBeneficiario'];
        this['codInstituicaoBeneficiario'] = data['codInstituicaoBeneficiario'];
        this['codInstituicaoPagador'] = data['codInstituicaoPagador'];
        this['contaBeneficiario'] = data['contaBeneficiario'];
        this['identificacaoFiscalBeneficiario'] = data['identificacaoFiscalBeneficiario'];
        this['identificacaoFiscalPagador'] = data['identificacaoFiscalPagador'];
        this['nomeBeneficiario'] = data['nomeBeneficiario'];
        this['nomePagador'] = data['nomePagador'];
        this['valorOperacao'] = data['valorOperacao'];
        this['nomeInstituicaoBeneficiario'] = data['nomeInstituicaoBeneficiario'];
        this['nomeInstituicaoPagador'] = data['nomeInstituicaoPagador'];
        this['contaPagador'] = data['contaPagador'];
        this['agenciaPagador'] = data['agenciaPagador'];
        this['dataUltimaAlteracao'] = data['dataUltimaAlteracao'];
        this['tipoPix'] = data['tipoPix'];
        this['valorSaqueTroco'] = data['valorSaqueTroco'];
    }
}
export class OutParametrosOnboard {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['parametrosId'] = data['parametrosId'];
        this['identificacaoFiscal'] = data['identificacaoFiscal'];
        this['organizationUnitId'] = data['organizationUnitId'];
        this['tipoConta'] = data['tipoConta'];
        this['chaveDeCliente'] = data['chaveDeCliente'];
        this['chaveDeProduto'] = data['chaveDeProduto'];
        this['aberturaAutomaticaConta'] = data['aberturaAutomaticaConta'];
    }
}
export class OutParametrosUnidadeOrganizacional {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['organizationUnitId'] = data['organizationUnitId'];
        this['possuiCreditoConsignado'] = data['possuiCreditoConsignado'];
        this['parcelaMaximaAntecipacao'] = data['parcelaMaximaAntecipacao'];
        this['parcelaMaximaCreditoConsignado'] = data['parcelaMaximaCreditoConsignado'];
    }
}
export class OutPosicaoClienteFilaOnboard {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['mensagemAmigavel'] = data['mensagemAmigavel'];
        this['posicaoFilaOnboard'] = data['posicaoFilaOnboard'];
        this['chaveDeFilaOnboarding'] = data['chaveDeFilaOnboarding'];
        this['statusFilaOnboard'] = data['statusFilaOnboard'];
        this['numeroDiasRestantesFila'] = data['numeroDiasRestantesFila'];
    }
}
export class OutPreCadastroParceiro {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['chaveDeCliente'] = data['chaveDeCliente'];
    }
}
export class OutPrimeiroAcesso {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['arquivoRetorno'] = data['arquivoRetorno'];
    }
}
export class OutPrincipaisParticipantesPix {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['principaisParticipantesPix'] = data['principaisParticipantesPix'];
    }
}
export class OutProcessamentoArquivo {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['id'] = data['id'];
        this['organizationUnitId'] = data['organizationUnitId'];
        this['nomeArquivo'] = data['nomeArquivo'];
        this['status'] = data['status'];
    }
}
export class OutProduto {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['id'] = data['id'];
        this['descricao'] = data['descricao'];
        this['servicos'] = data['servicos'];
    }
}
export class OutProposta {
    constructor(data = {}) {
        this['id'] = data['id'];
        this['simulacaoId'] = data['simulacaoId'];
        this['identificador'] = data['identificador'];
        this['emitenteProposta'] = data['emitenteProposta'];
    }
}
export class OutPropostaSemAssinatura {
    constructor(data = {}) {
        this['idContratoCreditora'] = data['idContratoCreditora'];
        this['recorrencia'] = data['recorrencia'];
        this['dataFinanciamento'] = data['dataFinanciamento'];
        this['dataPrimeiroVencimento'] = data['dataPrimeiroVencimento'];
        this['dataUltimoVencimento'] = data['dataUltimoVencimento'];
        this['taxaContrato'] = data['taxaContrato'];
        this['taxaIrr'] = data['taxaIrr'];
        this['taxaCetMes'] = data['taxaCetMes'];
        this['taxaIof'] = data['taxaIof'];
        this['taxaIofComplementar'] = data['taxaIofComplementar'];
        this['taxaMulta'] = data['taxaMulta'];
        this['diasCobrancaMulta'] = data['diasCobrancaMulta'];
        this['valorLiberado'] = data['valorLiberado'];
        this['valorIof'] = data['valorIof'];
        this['valorTarifas'] = data['valorTarifas'];
        this['valorJuros'] = data['valorJuros'];
        this['valorAparelho'] = data['valorAparelho'];
        this['valorRecebivel'] = data['valorRecebivel'];
        this['valorFinanciado'] = data['valorFinanciado'];
        this['valorSeguro'] = data['valorSeguro'];
        this['quantidadeParcelas'] = data['quantidadeParcelas'];
        this['valorEntrada'] = data['valorEntrada'];
        this['lojaId'] = data['lojaId'];
        this['pagamentos'] = data['pagamentos'];
    }
}
export class OutPropostaSimulada {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['valorRecebivel'] = data['valorRecebivel'];
        this['prazo'] = data['prazo'];
        this['financiado'] = data['financiado'];
        this['liberado'] = data['liberado'];
        this['taxaIofAnual'] = data['taxaIofAnual'];
        this['taxaIofComp'] = data['taxaIofComp'];
        this['taxaContratoMensal'] = data['taxaContratoMensal'];
        this['cetMensal'] = data['cetMensal'];
        this['cetAnual'] = data['cetAnual'];
        this['totalIof'] = data['totalIof'];
        this['totalJuros'] = data['totalJuros'];
        this['valorTotalContrato'] = data['valorTotalContrato'];
    }
}
export class OutProvedoresRecarga {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['provedores'] = data['provedores'];
    }
}
export class OutQrCodePix {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['arquivo'] = data['arquivo'];
        this['codigoCopiaCola'] = data['codigoCopiaCola'];
    }
}
export class OutQuadroSocietarioAdministrador {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['dadosSocios'] = data['dadosSocios'];
    }
}
export class OutQuantidadeNotificacoesNaoLidasCliente {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['qtdMensagensNaoLidas'] = data['qtdMensagensNaoLidas'];
    }
}
export class OutRealizarProposta {
    constructor(data = {}) {
        this['codigoProposta'] = data['codigoProposta'];
    }
}
export class OutRecarga {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['provedor'] = data['provedor'];
        this['comprovante'] = data['comprovante'];
        this['idComprovante'] = data['idComprovante'];
        this['idTransacao'] = data['idTransacao'];
    }
}
export class OutRecebiveisEmRenegociacao {
    constructor(data = {}) {
        this['cancelado'] = data['cancelado'];
        this['contrato'] = data['contrato'];
        this['desconto_aplicavel'] = data['desconto_aplicavel'];
        this['dias_cobranca_multa'] = data['dias_cobranca_multa'];
        this['gerado_por'] = data['gerado_por'];
        this['id'] = data['id'];
        this['juros'] = data['juros'];
        this['mes_referencia'] = data['mes_referencia'];
        this['principal'] = data['principal'];
        this['seguro'] = data['seguro'];
        this['valor_parcela'] = data['valor_parcela'];
        this['valor_presente'] = data['valor_presente'];
        this['vencimento_em'] = data['vencimento_em'];
    }
}
export class OutRecebiveisFinanciamento {
    constructor(data = {}) {
        this['recebiveis'] = data['recebiveis'];
    }
}
export class OutRecebivelItem {
    constructor(data = {}) {
        this['valor'] = data['valor'];
        this['dataVencimento'] = data['dataVencimento'];
    }
}
export class OutReembolsarPagamentoQrCode {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['sucesso'] = data['sucesso'];
    }
}
export class OutRegistroProcessando {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['execucaoId'] = data['execucaoId'];
        this['status'] = data['status'];
    }
}
export class OutRelacaoMotivos {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['arbiTransactionType'] = data['arbiTransactionType'];
        this['equalizaBanco'] = data['equalizaBanco'];
    }
}
export class OutRelatorioCAF {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['relatorioCAF'] = data['relatorioCAF'];
        this['relatorioTrust'] = data['relatorioTrust'];
    }
}
export class OutResultadoOcr {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['dadosOcr'] = data['dadosOcr'];
    }
}
export class OutSaldoAdquirencia {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['saldo'] = data['saldo'];
    }
}
export class OutSaldoTesouraria {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['saldo'] = data['saldo'];
        this['limiteMaximo'] = data['limiteMaximo'];
        this['limiteDisponivel'] = data['limiteDisponivel'];
    }
}
export class OutSaqueATM {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['idTransacaoSaque'] = data['idTransacaoSaque'];
        this['idComprovante'] = data['idComprovante'];
        this['idTransacao'] = data['idTransacao'];
    }
}
export class OutSimulacaoAutorizada {
    constructor(data = {}) {
        this['id'] = data['id'];
        this['validadeSimulacao'] = data['validadeSimulacao'];
        this['valorMinimoLiberado'] = data['valorMinimoLiberado'];
        this['valorMaximoLiberado'] = data['valorMaximoLiberado'];
        this['taxaContratoAnual'] = data['taxaContratoAnual'];
        this['taxaContratoMensal'] = data['taxaContratoMensal'];
        this['taxaCetAnual'] = data['taxaCetAnual'];
        this['taxaCetMensal'] = data['taxaCetMensal'];
        this['liberado'] = data['liberado'];
        this['totalRecebiveis'] = data['totalRecebiveis'];
        this['prazo'] = data['prazo'];
        this['taxaIofAnual'] = data['taxaIofAnual'];
        this['taxaIofComp'] = data['taxaIofComp'];
        this['valorIof'] = data['valorIof'];
        this['valorTotalContratado'] = data['valorTotalContratado'];
        this['recebiveis'] = data['recebiveis'];
    }
}
export class OutSimularPagamento {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['dataVencimento'] = data['dataVencimento'];
        this['pagarTotal'] = data['pagarTotal'];
        this['restante'] = data['restante'];
        this['taxasTotais'] = data['taxasTotais'];
        this['valorInicialProximaFatura'] = data['valorInicialProximaFatura'];
    }
}
export class OutSimularPagamentoParcelado {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['apagarOriginal'] = data['apagarOriginal'];
        this['valorParcela'] = data['valorParcela'];
        this['taxa'] = data['taxa'];
        this['numeroParcelas'] = data['numeroParcelas'];
        this['parcelas'] = data['parcelas'];
    }
}
export class OutSolicitarAntecipacaoParcelada {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['idProposta'] = data['idProposta'];
        this['localizador'] = data['localizador'];
        this['primeiroVencimento'] = data['primeiroVencimento'];
        this['ultimoVencimento'] = data['ultimoVencimento'];
        this['dataSolicitacao'] = data['dataSolicitacao'];
        this['taxaContrato'] = data['taxaContrato'];
        this['taxaIRR'] = data['taxaIRR'];
        this['taxaCetMes'] = data['taxaCetMes'];
        this['taxaIOF'] = data['taxaIOF'];
        this['taxaIOFComplementar'] = data['taxaIOFComplementar'];
        this['taxaMulta'] = data['taxaMulta'];
        this['diasCobrancaMulta'] = data['diasCobrancaMulta'];
        this['valorLiberado'] = data['valorLiberado'];
        this['valorIOF'] = data['valorIOF'];
        this['valorTarifa'] = data['valorTarifa'];
        this['valorJuros'] = data['valorJuros'];
        this['valorRecebivel'] = data['valorRecebivel'];
        this['valorSeguro'] = data['valorSeguro'];
        this['valorFinanciado'] = data['valorFinanciado'];
        this['prazo'] = data['prazo'];
        this['valorParcela'] = data['valorParcela'];
        this['saldoDevedor'] = data['saldoDevedor'];
        this['taxaPermanencia'] = data['taxaPermanencia'];
        this['statusContrato'] = data['statusContrato'];
        this['taxaCetAnual'] = data['taxaCetAnual'];
        this['modalidade'] = data['modalidade'];
        this['idContrato'] = data['idContrato'];
        this['statusAverbacao'] = data['statusAverbacao'];
        this['taxaContratoMensal'] = data['taxaContratoMensal'];
        this['taxaContratoAnual'] = data['taxaContratoAnual'];
    }
}
export class OutSolicitarAntecipacaoSalarial {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['idProposta'] = data['idProposta'];
        this['localizador'] = data['localizador'];
        this['primeiroVencimento'] = data['primeiroVencimento'];
        this['ultimoVencimento'] = data['ultimoVencimento'];
        this['dataSolicitacao'] = data['dataSolicitacao'];
        this['taxaContrato'] = data['taxaContrato'];
        this['taxaIRR'] = data['taxaIRR'];
        this['taxaCetMes'] = data['taxaCetMes'];
        this['taxaIOF'] = data['taxaIOF'];
        this['taxaIOFComplementar'] = data['taxaIOFComplementar'];
        this['taxaMulta'] = data['taxaMulta'];
        this['diasCobrancaMulta'] = data['diasCobrancaMulta'];
        this['valorLiberado'] = data['valorLiberado'];
        this['valorIOF'] = data['valorIOF'];
        this['valorTarifa'] = data['valorTarifa'];
        this['valorJuros'] = data['valorJuros'];
        this['valorRecebivel'] = data['valorRecebivel'];
        this['valorSeguro'] = data['valorSeguro'];
        this['valorFinanciado'] = data['valorFinanciado'];
        this['prazo'] = data['prazo'];
        this['valorParcela'] = data['valorParcela'];
        this['saldoDevedor'] = data['saldoDevedor'];
        this['taxaPermanencia'] = data['taxaPermanencia'];
        this['statusContrato'] = data['statusContrato'];
        this['taxaCetAnual'] = data['taxaCetAnual'];
        this['modalidade'] = data['modalidade'];
    }
}
export class OutSolicitarDepositoAtm {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['idTransacaoDeposito'] = data['idTransacaoDeposito'];
        this['idTransacao'] = data['idTransacao'];
    }
}
export class OutSolicitarPortabilidade {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['chaveDePortabilidade'] = data['chaveDePortabilidade'];
        this['protocoloPortabilidade'] = data['protocoloPortabilidade'];
        this['statusFacematch'] = data['statusFacematch'];
        this['faceMatchId'] = data['faceMatchId'];
        this['faceMatchUrl'] = data['faceMatchUrl'];
        this['requerValidacaoFacial'] = data['requerValidacaoFacial'];
    }
}
export class OutSolicitarPropostaAntecipacaoSalarial {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['idProposta'] = data['idProposta'];
        this['localizador'] = data['localizador'];
        this['primeiroVencimento'] = data['primeiroVencimento'];
        this['ultimoVencimento'] = data['ultimoVencimento'];
        this['dataSolicitacao'] = data['dataSolicitacao'];
        this['taxaContrato'] = data['taxaContrato'];
        this['taxaIRR'] = data['taxaIRR'];
        this['taxaCetMes'] = data['taxaCetMes'];
        this['taxaIOF'] = data['taxaIOF'];
        this['taxaIOFComplementar'] = data['taxaIOFComplementar'];
        this['taxaMulta'] = data['taxaMulta'];
        this['diasCobrancaMulta'] = data['diasCobrancaMulta'];
        this['valorLiberado'] = data['valorLiberado'];
        this['valorIOF'] = data['valorIOF'];
        this['valorTarifa'] = data['valorTarifa'];
        this['valorJuros'] = data['valorJuros'];
        this['valorRecebivel'] = data['valorRecebivel'];
        this['valorSeguro'] = data['valorSeguro'];
        this['valorFinanciado'] = data['valorFinanciado'];
        this['prazo'] = data['prazo'];
        this['valorParcela'] = data['valorParcela'];
        this['saldoDevedor'] = data['saldoDevedor'];
        this['taxaPermanencia'] = data['taxaPermanencia'];
        this['statusContrato'] = data['statusContrato'];
        this['taxaCetAnual'] = data['taxaCetAnual'];
        this['modalidade'] = data['modalidade'];
    }
}
export class OutSolicitarPropostaRenegociacao {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['idProposta'] = data['idProposta'];
        this['localizador'] = data['localizador'];
        this['primeiroVencimento'] = data['primeiroVencimento'];
        this['ultimoVencimento'] = data['ultimoVencimento'];
        this['dataSolicitacao'] = data['dataSolicitacao'];
        this['taxaContrato'] = data['taxaContrato'];
        this['taxaIRR'] = data['taxaIRR'];
        this['taxaCetMes'] = data['taxaCetMes'];
        this['taxaIOF'] = data['taxaIOF'];
        this['taxaIOFComplementar'] = data['taxaIOFComplementar'];
        this['taxaMulta'] = data['taxaMulta'];
        this['diasCobrancaMulta'] = data['diasCobrancaMulta'];
        this['valorLiberado'] = data['valorLiberado'];
        this['valorIOF'] = data['valorIOF'];
        this['valorTarifa'] = data['valorTarifa'];
        this['valorJuros'] = data['valorJuros'];
        this['valorRecebivel'] = data['valorRecebivel'];
        this['valorSeguro'] = data['valorSeguro'];
        this['valorFinanciado'] = data['valorFinanciado'];
        this['prazo'] = data['prazo'];
        this['valorParcela'] = data['valorParcela'];
        this['saldoDevedor'] = data['saldoDevedor'];
        this['taxaPermanencia'] = data['taxaPermanencia'];
        this['statusContrato'] = data['statusContrato'];
        this['taxaCetAnual'] = data['taxaCetAnual'];
        this['modalidade'] = data['modalidade'];
        this['idContrato'] = data['idContrato'];
        this['statusAverbacao'] = data['statusAverbacao'];
        this['taxaContratoMensal'] = data['taxaContratoMensal'];
        this['taxaContratoAnual'] = data['taxaContratoAnual'];
    }
}
export class OutStatusCartao {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['descricaoStatusCartao'] = data['descricaoStatusCartao'];
        this['idStatusCartao'] = data['idStatusCartao'];
    }
}
export class OutStatusCliente {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['chaveDeCliente'] = data['chaveDeCliente'];
        this['statusCadastroClienteDescricao'] = data['statusCadastroClienteDescricao'];
        this['statusCadastroClienteId'] = data['statusCadastroClienteId'];
        this['pendencias'] = data['pendencias'];
        this['erros'] = data['erros'];
        this['pendentesOnboard'] = data['pendentesOnboard'];
    }
}
export class OutStatusContaCreditoClean {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['chaveDeConta'] = data['chaveDeConta'];
        this['statusCadastro'] = data['statusCadastro'];
        this['valorLiberado'] = data['valorLiberado'];
        this['valorEscolhido'] = data['valorEscolhido'];
        this['datadeVencimentoFatura'] = data['datadeVencimentoFatura'];
    }
}
export class OutTentativaLogin {
    constructor(data = {}) {
        this['usuario'] = data['usuario'];
        this['enderecoIp'] = data['enderecoIp'];
        this['client'] = data['client'];
        this['browser'] = data['browser'];
        this['resultado'] = data['resultado'];
        this['data'] = data['data'];
    }
}
export class OutTentativaLoginAddOutputPaginated {
    constructor(data = {}) {
        this['items'] = data['items'];
        this['qtdPaginas'] = data['qtdPaginas'];
        this['qtdPorPagina'] = data['qtdPorPagina'];
        this['totalItems'] = data['totalItems'];
        this['pagina'] = data['pagina'];
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
    }
}
export class OutTermoAntecipacao {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['termo'] = data['termo'];
        this['arquivo'] = data['arquivo'];
    }
}
export class OutTermoAntecipacaoAssinadoFgts {
    constructor(data = {}) {
        this['termo'] = data['termo'];
        this['arquivo'] = data['arquivo'];
    }
}
export class OutTermoAntecipacaoFgts {
    constructor(data = {}) {
        this['termo'] = data['termo'];
    }
}
export class OutTermosPrivacidade {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['termoPrivacidadeID'] = data['termoPrivacidadeID'];
    }
}
export class OutTipoAdquirenciaProduto {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['idTipoAdquirencia'] = data['idTipoAdquirencia'];
        this['tipoAdquirencia'] = data['tipoAdquirencia'];
    }
}
export class OutTipoTransacaoTesouraria {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['id'] = data['id'];
        this['descricao'] = data['descricao'];
    }
}
export class OutTransacaoCreditoClean {
    constructor(data = {}) {
        this['transacaoId'] = data['transacaoId'];
        this['dataHoraTransacao'] = data['dataHoraTransacao'];
        this['descricaoAbreviada'] = data['descricaoAbreviada'];
        this['descricaoEstabelecimento'] = data['descricaoEstabelecimento'];
        this['nomeEstabelecimento'] = data['nomeEstabelecimento'];
        this['descricaoTipoTransacao'] = data['descricaoTipoTransacao'];
        this['valorBRL'] = data['valorBRL'];
        this['valorUSD'] = data['valorUSD'];
        this['parcelamento'] = data['parcelamento'];
        this['parcelaNumero'] = data['parcelaNumero'];
        this['contestacaoId'] = data['contestacaoId'];
        this['processada'] = data['processada'];
    }
}
export class OutTransacaoExtrato {
    constructor(data = {}) {
        this['idTransacaoAddHub'] = data['idTransacaoAddHub'];
        this['idTransacaoProcessadora'] = data['idTransacaoProcessadora'];
        this['dataTransacao'] = data['dataTransacao'];
        this['valor'] = data['valor'];
        this['valorDolar'] = data['valorDolar'];
        this['cotacaoDolar'] = data['cotacaoDolar'];
        this['valorCompraMoedaEstrangeira'] = data['valorCompraMoedaEstrangeira'];
        this['moedaEstrangeira'] = data['moedaEstrangeira'];
        this['quantidadeDeParcelas'] = data['quantidadeDeParcelas'];
        this['tipoEstabelecimento'] = data['tipoEstabelecimento'];
        this['mcc'] = data['mcc'];
        this['solicitouContestacao'] = data['solicitouContestacao'];
        this['numeroCartao'] = data['numeroCartao'];
        this['tipoOperacao'] = data['tipoOperacao'];
        this['idTipoTransacao'] = data['idTipoTransacao'];
        this['descricaoTipoTransacao'] = data['descricaoTipoTransacao'];
        this['idMotivoTransacao'] = data['idMotivoTransacao'];
        this['descricaoMotivoTransacao'] = data['descricaoMotivoTransacao'];
        this['processada'] = data['processada'];
    }
}
export class OutTransferirSaldoParaCliente {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['transacaoTesouraria'] = data['transacaoTesouraria'];
    }
}
export class OutTransferirTesourariaParaTesouraria {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['chaveDeContaTesourariaOrigem'] = data['chaveDeContaTesourariaOrigem'];
        this['chaveDeContaTesourariaDestino'] = data['chaveDeContaTesourariaDestino'];
        this['valor'] = data['valor'];
        this['idTransacao'] = data['idTransacao'];
    }
}
export class OutVagasFilaOnboard {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['possuiVagas'] = data['possuiVagas'];
    }
}
export class OutValidarDimoQrCode {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['pagamentoId'] = data['pagamentoId'];
        this['nomeComerciante'] = data['nomeComerciante'];
        this['valor'] = data['valor'];
        this['moeda'] = data['moeda'];
        this['qrStatusValidacao'] = data['qrStatusValidacao'];
    }
}
export class OutValidarPagamentoBoletoNovo {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['linhaDigitavel'] = data['linhaDigitavel'];
        this['codigoDeBarras'] = data['codigoDeBarras'];
        this['cedente'] = data['cedente'];
        this['identificacaoFiscalBeneficiario'] = data['identificacaoFiscalBeneficiario'];
        this['nomeBeneficiario'] = data['nomeBeneficiario'];
        this['identificacaoFiscalPagador'] = data['identificacaoFiscalPagador'];
        this['nomePagador'] = data['nomePagador'];
        this['dataVencimento'] = data['dataVencimento'];
        this['desconto'] = data['desconto'];
        this['juros'] = data['juros'];
        this['multa'] = data['multa'];
        this['valor'] = data['valor'];
        this['valorAtualizado'] = data['valorAtualizado'];
        this['totalDesconto'] = data['totalDesconto'];
        this['totalJurosMulta'] = data['totalJurosMulta'];
        this['liquidacaoProximoDiaUtil'] = data['liquidacaoProximoDiaUtil'];
        this['permiteAlterarValor'] = data['permiteAlterarValor'];
        this['horarioInicioPagamento'] = data['horarioInicioPagamento'];
        this['horarioLimitePagamento'] = data['horarioLimitePagamento'];
    }
}
export class OutValorLiberadoFgts {
    constructor(data = {}) {
        this['valorMinimoLiberado'] = data['valorMinimoLiberado'];
        this['valorMaximoLiberado'] = data['valorMaximoLiberado'];
    }
}
export class OutValoresPropostaMobile {
    constructor(data = {}) {
        this['taxaContrato'] = data['taxaContrato'];
        this['taxaIrr'] = data['taxaIrr'];
        this['taxaCetMes'] = data['taxaCetMes'];
        this['taxaIof'] = data['taxaIof'];
        this['taxaIofComplementar'] = data['taxaIofComplementar'];
        this['taxaMulta'] = data['taxaMulta'];
        this['valorSolicitado'] = data['valorSolicitado'];
        this['valorLiberado'] = data['valorLiberado'];
        this['valorIof'] = data['valorIof'];
        this['valorTarifas'] = data['valorTarifas'];
        this['valorJuros'] = data['valorJuros'];
        this['valorRecebivel'] = data['valorRecebivel'];
        this['valorSeguro'] = data['valorSeguro'];
        this['valorEntrada'] = data['valorEntrada'];
        this['diasCobrancaMulta'] = data['diasCobrancaMulta'];
        this['prazo'] = data['prazo'];
        this['idProposta'] = data['idProposta'];
        this['chaveDeCliente'] = data['chaveDeCliente'];
        this['recebiveis'] = data['recebiveis'];
    }
}
export class OutputPadrao {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['data'] = data['data'];
        this['mensagem'] = data['mensagem'];
    }
}
export class PPECDto {
    constructor(data = {}) {
        this['cargo'] = data['cargo'];
        this['inicioExposicao'] = data['inicioExposicao'];
        this['fimExposicao'] = data['fimExposicao'];
    }
}
export class PagamentoPorAgenciaContaPixDto {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeConta'] = data['chaveDeConta'];
        this['descricao'] = data['descricao'];
        this['autenticacao2FA'] = data['autenticacao2FA'];
        this['valorOperacao'] = data['valorOperacao'];
        this['tipoPix'] = data['tipoPix'];
        this['valorSaqueTroco'] = data['valorSaqueTroco'];
        this['codInstituicaoBeneficiario'] = data['codInstituicaoBeneficiario'];
        this['agenciaBeneficiario'] = data['agenciaBeneficiario'];
        this['contaBeneficiario'] = data['contaBeneficiario'];
        this['tipoContaBeneficiario'] = data['tipoContaBeneficiario'];
        this['identificacaoFiscalBeneficiario'] = data['identificacaoFiscalBeneficiario'];
        this['nomeBeneficiario'] = data['nomeBeneficiario'];
    }
}
export class PagamentoPorChavePixDto {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeConta'] = data['chaveDeConta'];
        this['descricao'] = data['descricao'];
        this['autenticacao2FA'] = data['autenticacao2FA'];
        this['valorOperacao'] = data['valorOperacao'];
        this['tipoPix'] = data['tipoPix'];
        this['valorSaqueTroco'] = data['valorSaqueTroco'];
        this['chaveEnderecamento'] = data['chaveEnderecamento'];
    }
}
export class PagamentoPorQrPixDto {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeConta'] = data['chaveDeConta'];
        this['descricao'] = data['descricao'];
        this['autenticacao2FA'] = data['autenticacao2FA'];
        this['valorOperacao'] = data['valorOperacao'];
        this['tipoPix'] = data['tipoPix'];
        this['valorSaqueTroco'] = data['valorSaqueTroco'];
        this['chaveEnderecamento'] = data['chaveEnderecamento'];
        this['endToEnd'] = data['endToEnd'];
        this['referenciaInterna'] = data['referenciaInterna'];
        this['tipoQRCode'] = data['tipoQRCode'];
        this['prestadorDoServicoDeSaque'] = data['prestadorDoServicoDeSaque'];
        this['modalidadeAgente'] = data['modalidadeAgente'];
    }
}
export class Parameters {
    constructor(data = {}) {
        this['cpf'] = data['cpf'];
        this['birthDate'] = data['birthDate'];
        this['fatherName'] = data['fatherName'];
        this['motherName'] = data['motherName'];
        this['name'] = data['name'];
        this['issueState'] = data['issueState'];
        this['issuingAuthority'] = data['issuingAuthority'];
        this['issueDate'] = data['issueDate'];
        this['rg'] = data['rg'];
    }
}
export class ParametroDimoDto {
    constructor(data = {}) {
        this['banco'] = data['banco'];
        this['nomeBanco'] = data['nomeBanco'];
        this['agencia'] = data['agencia'];
        this['conta'] = data['conta'];
    }
}
export class ParcelaItem {
    constructor(data = {}) {
        this['mes'] = data['mes'];
        this['valor'] = data['valor'];
        this['parcela'] = data['parcela'];
    }
}
export class ParcelaRecebivelOnidataDto {
    constructor(data = {}) {
        this['numParcela'] = data['numParcela'];
        this['valorMinimo'] = data['valorMinimo'];
        this['valorMaximo'] = data['valorMaximo'];
        this['taxaJurosNominal'] = data['taxaJurosNominal'];
    }
}
export class PessoaFisicaCDto {
    constructor(data = {}) {
        this['organizationUnitId'] = data['organizationUnitId'];
        this['id'] = data['id'];
        this['primeiroNome'] = data['primeiroNome'];
        this['sobrenome'] = data['sobrenome'];
        this['identificacaoFiscal'] = data['identificacaoFiscal'];
        this['email'] = data['email'];
        this['dataNascimento'] = data['dataNascimento'];
        this['nomeCompletoMae'] = data['nomeCompletoMae'];
        this['nomeCompletoPai'] = data['nomeCompletoPai'];
        this['nacionalidade'] = data['nacionalidade'];
        this['naturalidade'] = data['naturalidade'];
        this['sexo'] = data['sexo'];
        this['estadoCivil'] = data['estadoCivil'];
        this['endereco'] = data['endereco'];
        this['documento'] = data['documento'];
        this['telefoneCelular'] = data['telefoneCelular'];
        this['pessoaPoliticamenteExposta'] = data['pessoaPoliticamenteExposta'];
        this['rendaMensal'] = data['rendaMensal'];
        this['cargo'] = data['cargo'];
        this['tempoCargoEmMeses'] = data['tempoCargoEmMeses'];
        this['dependentes'] = data['dependentes'];
    }
}
export class PessoaPoliticamenteExpostaDto {
    constructor(data = {}) {
        this['cargo'] = data['cargo'];
        this['inicioExposicao'] = data['inicioExposicao'];
        this['fimExposicao'] = data['fimExposicao'];
    }
}
export class PfEmail {
    constructor(data = {}) {
        this['email'] = data['email'];
        this['type'] = data['type'];
        this['firstPassageDate'] = data['firstPassageDate'];
        this['lastPassageDate'] = data['lastPassageDate'];
    }
}
export class PfEmails {
    constructor(data = {}) {
        this['available'] = data['available'];
        this['statusCode'] = data['statusCode'];
        this['data'] = data['data'];
    }
}
export class PfPhone {
    constructor(data = {}) {
        this['number'] = data['number'];
        this['areaCode'] = data['areaCode'];
        this['countryCode'] = data['countryCode'];
        this['type'] = data['type'];
        this['lastPassageDate'] = data['lastPassageDate'];
        this['hasOptIn'] = data['hasOptIn'];
    }
}
export class PfPhones {
    constructor(data = {}) {
        this['available'] = data['available'];
        this['statusCode'] = data['statusCode'];
        this['data'] = data['data'];
    }
}
export class PortadorDto {
    constructor(data = {}) {
        this['id'] = data['id'];
        this['vinculoCliente'] = data['vinculoCliente'];
        this['identificacaoFiscal'] = data['identificacaoFiscal'];
        this['nome'] = data['nome'];
        this['nomeMae'] = data['nomeMae'];
        this['email'] = data['email'];
        this['dataNascimento'] = data['dataNascimento'];
        this['telefoneMovel'] = data['telefoneMovel'];
        this['endereco'] = data['endereco'];
        this['nomeEmpresa'] = data['nomeEmpresa'];
        this['statusCadastroPortador'] = data['statusCadastroPortador'];
        this['statusCadastroPortadorId'] = data['statusCadastroPortadorId'];
    }
}
export class PrevencaoAtributos {
    constructor(data = {}) {
        this['cpf'] = data['cpf'];
        this['birthDate'] = data['birthDate'];
        this['fatherName'] = data['fatherName'];
        this['motherName'] = data['motherName'];
        this['name'] = data['name'];
        this['issueState'] = data['issueState'];
        this['issuingAuthority'] = data['issuingAuthority'];
        this['issueDate'] = data['issueDate'];
        this['rg'] = data['rg'];
    }
}
export class PrevencaoDocumentoCopia {
    constructor(data = {}) {
        this['status'] = data['status'];
        this['fraud'] = data['fraud'];
        this['reviewDate'] = data['reviewDate'];
    }
}
export class PrevencaoFaceMatch {
    constructor(data = {}) {
        this['statusCode'] = data['statusCode'];
        this['confidence'] = data['confidence'];
        this['identical'] = data['identical'];
    }
}
export class PrevencaoPontuacaoFraude {
    constructor(data = {}) {
        this['total'] = data['total'];
    }
}
export class PrevencaoSections {
    constructor(data = {}) {
        this['ocr'] = data['ocr'];
        this['cpf'] = data['cpf'];
        this['faceMatch'] = data['faceMatch'];
        this['officialData'] = data['officialData'];
        this['documentoCopia'] = data['documentoCopia'];
    }
}
export class PrevencaoValidacoes {
    constructor(data = {}) {
        this['category'] = data['category'];
        this['code'] = data['code'];
        this['status'] = data['status'];
        this['resultStatus'] = data['resultStatus'];
    }
}
export class ProdutoDto {
    constructor(data = {}) {
        this['produtoId'] = data['produtoId'];
        this['chaveDeProduto'] = data['chaveDeProduto'];
        this['descricao'] = data['descricao'];
        this['tipoConta'] = data['tipoConta'];
        this['codTipoConta'] = data['codTipoConta'];
        this['servicos'] = data['servicos'];
    }
}
export class ProdutoParaMigracaoDto {
    constructor(data = {}) {
        this['produtoId'] = data['produtoId'];
        this['chaveDeProduto'] = data['chaveDeProduto'];
        this['descricao'] = data['descricao'];
    }
}
export class ProdutoProcessadoraDto {
    constructor(data = {}) {
        this['idProdutoProcessadora'] = data['idProdutoProcessadora'];
        this['nomeProduto'] = data['nomeProduto'];
        this['bin'] = data['bin'];
        this['status'] = data['status'];
        this['idOrigemComercialProcessadora'] = data['idOrigemComercialProcessadora'];
    }
}
export class ProvedorDto {
    constructor(data = {}) {
        this['id'] = data['id'];
        this['nome'] = data['nome'];
    }
}
export class QrCodeDinamicoPixDto {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeConta'] = data['chaveDeConta'];
        this['chaveEnderecamento'] = data['chaveEnderecamento'];
        this['valorOperacao'] = data['valorOperacao'];
        this['dataVencimento'] = data['dataVencimento'];
        this['diasAposVencimento'] = data['diasAposVencimento'];
        this['reutilizavel'] = data['reutilizavel'];
        this['solicitacaoPagador'] = data['solicitacaoPagador'];
        this['valorDesconto'] = data['valorDesconto'];
        this['valorAbatimento'] = data['valorAbatimento'];
        this['valorMulta'] = data['valorMulta'];
        this['pagadorNome'] = data['pagadorNome'];
        this['pagadorIdentificaoFiscal'] = data['pagadorIdentificaoFiscal'];
    }
}
export class QrCodeEstaticoPixDto {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeConta'] = data['chaveDeConta'];
        this['chaveEnderecamento'] = data['chaveEnderecamento'];
        this['descricao'] = data['descricao'];
        this['valorOperacao'] = data['valorOperacao'];
    }
}
export class RGDto {
    constructor(data = {}) {
        this['numero'] = data['numero'];
        this['dataEmissao'] = data['dataEmissao'];
        this['orgaoEmissor'] = data['orgaoEmissor'];
        this['ufOrgaoEmissor'] = data['ufOrgaoEmissor'];
    }
}
export class RecebiveisPropostaVo {
    constructor(data = {}) {
        this['dataVencimento'] = data['dataVencimento'];
        this['valorParcela'] = data['valorParcela'];
        this['valorPrincipal'] = data['valorPrincipal'];
        this['valorJuros'] = data['valorJuros'];
        this['valorDesconto'] = data['valorDesconto'];
        this['numeroParcela'] = data['numeroParcela'];
    }
}
export class RecebiveisSimulacaoVo {
    constructor(data = {}) {
        this['numero'] = data['numero'];
        this['juros'] = data['juros'];
        this['valor'] = data['valor'];
        this['dataVencimento'] = data['dataVencimento'];
        this['valorPrincipal'] = data['valorPrincipal'];
    }
}
export class RecebivelCDto {
    constructor(data = {}) {
        this['valor'] = data['valor'];
        this['numParcela'] = data['numParcela'];
        this['vencimento'] = data['vencimento'];
        this['valorAtual'] = data['valorAtual'];
        this['dataPagamento'] = data['dataPagamento'];
    }
}
export class RecebivelCompletoDto {
    constructor(data = {}) {
        this['valor'] = data['valor'];
        this['numParcela'] = data['numParcela'];
        this['vencimento'] = data['vencimento'];
        this['valorAtual'] = data['valorAtual'];
        this['dataPagamento'] = data['dataPagamento'];
        this['statusPagamento'] = data['statusPagamento'];
    }
}
export class RecebivelDetalheContrato {
    constructor(data = {}) {
        this['valor'] = data['valor'];
        this['dataVencimento'] = data['dataVencimento'];
        this['diasParaVencimento'] = data['diasParaVencimento'];
        this['numeroParcela'] = data['numeroParcela'];
    }
}
export class RecebivelFgtsDto {
    constructor(data = {}) {
        this['valor'] = data['valor'];
        this['dataVencimento'] = data['dataVencimento'];
    }
}
export class ReivindicacaoPix {
    constructor(data = {}) {
        this['chaveEnderecamento'] = data['chaveEnderecamento'];
        this['id'] = data['id'];
        this['tipoChave'] = data['tipoChave'];
        this['tipoReivindicacao'] = data['tipoReivindicacao'];
        this['contaRequisitante'] = data['contaRequisitante'];
        this['requisitante'] = data['requisitante'];
        this['contaDoador'] = data['contaDoador'];
        this['doador'] = data['doador'];
        this['statusReivindicacao'] = data['statusReivindicacao'];
        this['tipoSolicitacao'] = data['tipoSolicitacao'];
        this['canceladoPor'] = data['canceladoPor'];
        this['dataInicio'] = data['dataInicio'];
        this['ultimaModificacao'] = data['ultimaModificacao'];
        this['limiteResolucao'] = data['limiteResolucao'];
        this['limiteConclusao'] = data['limiteConclusao'];
        this['isDonorParticipant'] = data['isDonorParticipant'];
        this['isClaimerParticipant'] = data['isClaimerParticipant'];
        this['isDonorPerson'] = data['isDonorPerson'];
        this['isClaimerPerson'] = data['isClaimerPerson'];
        this['flagRequisitante'] = data['flagRequisitante'];
        this['bloqueada'] = data['bloqueada'];
    }
}
export class RelatorioCAF {
    constructor(data = {}) {
        this['_id'] = data['_id'];
        this['type'] = data['type'];
        this['status'] = data['status'];
        this['report'] = data['report'];
        this['fraud'] = data['fraud'];
        this['createdAt'] = data['createdAt'];
        this['updatedAt'] = data['updatedAt'];
        this['data'] = data['data'];
        this['images'] = data['images'];
        this['parameters'] = data['parameters'];
        this['sections'] = data['sections'];
        this['validations'] = data['validations'];
        this['reprovalReasons'] = data['reprovalReasons'];
    }
}
export class RelatorioPrevencao {
    constructor(data = {}) {
        this['requestId'] = data['requestId'];
        this['type'] = data['type'];
        this['status'] = data['status'];
        this['images'] = data['images'];
        this['sections'] = data['sections'];
        this['templateId'] = data['templateId'];
        this['createdAt'] = data['createdAt'];
        this['updatedAt'] = data['updatedAt'];
        this['fraud'] = data['fraud'];
        this['fraudScore'] = data['fraudScore'];
        this['id'] = data['id'];
        this['statusReasons'] = data['statusReasons'];
        this['atributos'] = data['atributos'];
        this['reprovalReasons'] = data['reprovalReasons'];
    }
}
export class RequestInfo {
    constructor(data = {}) {
        this['id'] = data['id'];
        this['recipient'] = data['recipient'];
        this['sender'] = data['sender'];
        this['ts'] = data['ts'];
        this['exchangeId'] = data['exchangeId'];
    }
}
export class ResultadoSimulacaoDto {
    constructor(data = {}) {
        this['valorRecebivel'] = data['valorRecebivel'];
        this['prazo'] = data['prazo'];
        this['dataSolicitacao'] = data['dataSolicitacao'];
        this['modalidade'] = data['modalidade'];
        this['valorTarifas'] = data['valorTarifas'];
        this['financiado'] = data['financiado'];
        this['valorLiberado'] = data['valorLiberado'];
        this['taxaIof'] = data['taxaIof'];
        this['taxaJuros'] = data['taxaJuros'];
        this['dataPrimeiroVencimento'] = data['dataPrimeiroVencimento'];
        this['taxaIofAnual'] = data['taxaIofAnual'];
        this['taxaIofCompl'] = data['taxaIofCompl'];
        this['cetMensal'] = data['cetMensal'];
        this['cetAnual'] = data['cetAnual'];
        this['taxaContratoMensal'] = data['taxaContratoMensal'];
        this['taxaContratoAnual'] = data['taxaContratoAnual'];
        this['irrMensal'] = data['irrMensal'];
        this['irrAnual'] = data['irrAnual'];
        this['recebiveis'] = data['recebiveis'];
    }
}
export class Sections {
    constructor(data = {}) {
        this['ocr'] = data['ocr'];
        this['cpf'] = data['cpf'];
        this['faceMatch'] = data['faceMatch'];
        this['officialData'] = data['officialData'];
        this['pfEmails'] = data['pfEmails'];
        this['pfPhones'] = data['pfPhones'];
    }
}
export class ServicoDto {
    constructor(data = {}) {
        this['descricao'] = data['descricao'];
    }
}
export class SolicitacaoTokenDto {
    constructor(data = {}) {
        this['tipoEnvioToken'] = data['tipoEnvioToken'];
        this['destinoEmail'] = data['destinoEmail'];
        this['destinoCelular'] = data['destinoCelular'];
    }
}
export class StatusTransacaoDto {
    constructor(data = {}) {
        this['id'] = data['id'];
        this['chave'] = data['chave'];
    }
}
export class StringEntityDto {
    constructor(data = {}) {
        this['id'] = data['id'];
    }
}
export class SubcontaDto {
    constructor(data = {}) {
        this['chaveDeConta'] = data['chaveDeConta'];
        this['idStatusConta'] = data['idStatusConta'];
        this['portadorId'] = data['portadorId'];
        this['descricaoStatusConta'] = data['descricaoStatusConta'];
        this['descricao'] = data['descricao'];
        this['dataAbertura'] = data['dataAbertura'];
        this['dataEncerramento'] = data['dataEncerramento'];
    }
}
export class SubcontaDtoAddOutputPaginated {
    constructor(data = {}) {
        this['items'] = data['items'];
        this['qtdPaginas'] = data['qtdPaginas'];
        this['qtdPorPagina'] = data['qtdPorPagina'];
        this['totalItems'] = data['totalItems'];
        this['pagina'] = data['pagina'];
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
    }
}
export class TarifaOperacao {
    constructor(data = {}) {
        this['descricaoTarifa'] = data['descricaoTarifa'];
        this['valorTarifa'] = data['valorTarifa'];
        this['motivoTarifaALancar'] = data['motivoTarifaALancar'];
        this['localADebitar'] = data['localADebitar'];
    }
}
export class TedAgendadaDTO {
    constructor(data = {}) {
        this['idAgendamento'] = data['idAgendamento'];
        this['dataUltimaAlteracao'] = data['dataUltimaAlteracao'];
        this['statusTed'] = data['statusTed'];
        this['chaveDeConta'] = data['chaveDeConta'];
        this['nome'] = data['nome'];
        this['identificacaoFiscal'] = data['identificacaoFiscal'];
        this['banco'] = data['banco'];
        this['bancoNome'] = data['bancoNome'];
        this['agencia'] = data['agencia'];
        this['numero'] = data['numero'];
        this['valor'] = data['valor'];
        this['tipoConta'] = data['tipoConta'];
        this['dataAgendamento'] = data['dataAgendamento'];
        this['numeroRecorrencias'] = data['numeroRecorrencias'];
        this['descricaoExecucao'] = data['descricaoExecucao'];
    }
}
export class TedAgendadaDTOAddOutputPaginated {
    constructor(data = {}) {
        this['items'] = data['items'];
        this['qtdPaginas'] = data['qtdPaginas'];
        this['qtdPorPagina'] = data['qtdPorPagina'];
        this['totalItems'] = data['totalItems'];
        this['pagina'] = data['pagina'];
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
    }
}
export class TedAgendamentoRecorrenteDTO {
    constructor(data = {}) {
        this['idAgendamento'] = data['idAgendamento'];
        this['dataCriacao'] = data['dataCriacao'];
        this['dataAgendamento'] = data['dataAgendamento'];
        this['contaDestino'] = data['contaDestino'];
        this['identificacaoFiscal'] = data['identificacaoFiscal'];
    }
}
export class Telefone {
    constructor(data = {}) {
        this['ddd'] = data['ddd'];
        this['numero'] = data['numero'];
    }
}
export class TelefoneCDto {
    constructor(data = {}) {
        this['ddd'] = data['ddd'];
        this['numero'] = data['numero'];
    }
}
export class TelefoneDto {
    constructor(data = {}) {
        this['ddd'] = data['ddd'];
        this['numero'] = data['numero'];
    }
}
export class TipoAdquirenciaDto {
    constructor(data = {}) {
        this['id'] = data['id'];
        this['descricao'] = data['descricao'];
    }
}
export class TipoWebhook {
    constructor(data = {}) {
        this['id'] = data['id'];
        this['descricao'] = data['descricao'];
    }
}
export class TokenDto {
    constructor(data = {}) {
        this['token'] = data['token'];
        this['agente'] = data['agente'];
    }
}
export class TotalTransacionadoSegmento {
    constructor(data = {}) {
        this['segmento'] = data['segmento'];
        this['valorTransacionado'] = data['valorTransacionado'];
    }
}
export class TransacaoResponseDto {
    constructor(data = {}) {
        this['uuid'] = data['uuid'];
        this['dataTransacao'] = data['dataTransacao'];
        this['horaTransacao'] = data['horaTransacao'];
        this['fusoHorario'] = data['fusoHorario'];
        this['nsu'] = data['nsu'];
        this['valor'] = data['valor'];
        this['dataEnvio'] = data['dataEnvio'];
        this['dataResposta'] = data['dataResposta'];
        this['tid'] = data['tid'];
        this['codigoResposta'] = data['codigoResposta'];
        this['dataSaqueTecban'] = data['dataSaqueTecban'];
        this['dataConfirmacao'] = data['dataConfirmacao'];
        this['status'] = data['status'];
        this['codigoRespostaConsulta'] = data['codigoRespostaConsulta'];
        this['dataTecban'] = data['dataTecban'];
    }
}
export class TransactionInfo {
    constructor(data = {}) {
        this['id'] = data['id'];
        this['type'] = data['type'];
        this['referenceLabel'] = data['referenceLabel'];
        this['approvalTimestamp'] = data['approvalTimestamp'];
        this['authorizationCode'] = data['authorizationCode'];
        this['authenticationCode'] = data['authenticationCode'];
        this['hostNsu'] = data['hostNsu'];
        this['terminalNsu'] = data['terminalNsu'];
        this['merchantId'] = data['merchantId'];
        this['terminalId'] = data['terminalId'];
        this['amount'] = data['amount'];
        this['status'] = data['status'];
    }
}
export class TransferenciaAgendadaDTO {
    constructor(data = {}) {
        this['idAgendamento'] = data['idAgendamento'];
        this['ocorrencia'] = data['ocorrencia'];
        this['dataAgendamento'] = data['dataAgendamento'];
    }
}
export class TsmEnrollmentInfo {
    constructor(data = {}) {
        this['enrollmentId'] = data['enrollmentId'];
        this['ts'] = data['ts'];
        this['expiredTs'] = data['expiredTs'];
        this['deviceId'] = data['deviceId'];
        this['properties'] = data['properties'];
    }
}
export class Validation {
    constructor(data = {}) {
        this['rule'] = data['rule'];
        this['title'] = data['title'];
        this['status'] = data['status'];
        this['description'] = data['description'];
    }
}
export class ValidationErrorInfo {
    constructor(data = {}) {
        this['message'] = data['message'];
        this['members'] = data['members'];
    }
}
export class ValorChavePixDto {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeConta'] = data['chaveDeConta'];
        this['chaveEnderecamento'] = data['chaveEnderecamento'];
    }
}
export class ValorProvedorDto {
    constructor(data = {}) {
        this['nomeProduto'] = data['nomeProduto'];
        this['validade'] = data['validade'];
        this['bonus'] = data['bonus'];
        this['valorMax'] = data['valorMax'];
        this['valorMin'] = data['valorMin'];
    }
}
export class ValoresSimuladosCDto {
    constructor(data = {}) {
        this['valorSolicitado'] = data['valorSolicitado'];
        this['valorSeguro'] = data['valorSeguro'];
        this['valorEntrada'] = data['valorEntrada'];
        this['prazo'] = data['prazo'];
    }
}
export class WalletProviderEnrollmentInfo {
    constructor(data = {}) {
        this['properties'] = data['properties'];
    }
}
