<template>
  <v-form v-if="formRef && fields" class="form-fields fields" :ref="formRef">
    <template v-for="field in fields">
      <component
        v-if="!field.removeInput"
        :data-cy="!field.isButton ? dataCyField(field) : dataCyButton(field)"
        :maskMoney="field.maskMoney"
        :title="field.title"
        :subtitle="field.subtitle"
        :maskFormat="field.maskFormat"
        v-show="!field.hideInput"
        :disabled="disabled ? disabled : field.disabled"
        :valueField="field.valueField"
        :keyValue="field.keyValue"
        :keyName="field.keyName"
        :items="field.items"
        :rules="field.dinamicRules"
        :label="field.label"
        :maxLength="field.maxLength"
        :placeholder="field.placeholder"
        :cols="isMobile ? '12' : field.cols"
        :hideTitleBorder="field.hideTitleBorder"
        :prependInnerIcon="field.prependInnerIcon"
        :is="field.field"
        :key="field.variableName"
        :type="field.type"
        :appendIcon="field.appendIcon"
        :hint="field.hint"
        :errorText="field.errorText"
        :helpText="field.helpText"
        :dateType="field.dateType"
        :tooltipText="field.tooltipText"
        :inline="field.inline"
        :typeItem="field.typeItem"
        :loading="field.loading"
        :darkStyle="field.darkStyle"
        :backgroundColor="field.backgroundColor"
        :text="field.text"
        :formIsValid="formIsValid"
        :watchValidation="watchValidation"
        :color="field.color"
        :rows="field.rows"
        :selectAll="field.selectAll"
        @onEnter="onEnter"
        @update:value="updateValueField($event, field)"
        @onClick="onClickButton(field)"
        @onBlur="
          field.onBlur
            ? (field.onBlur($event), $emit('update:values', inputsValues))
            : null
        "
        :helpTextLabel="field.helpTextLabel"
        :icon="field.icon"
        :event="field.event"
        @internalButton="$emit(field.event)"
        :clickableHint="field.clickableHint"
        @hintClicked="$emit('hintClicked')"
        :urlImage="field.urlImage"
        :imageText="field.imageText"
        :colorStyle="field.colorStyle"
      />
    </template>
    <v-col
      v-if="withButton && containsFields"
      :cols="colsButtonSearch"
      class="cleanComponent"
    >
      <SearchButton
        :data-cy="withButton + '-button'"
        :text="withButton"
        :disabled="loading"
        @click.native.prevent="validateForms"
        :style="{ marginTop: `${withLabel ? '29' : '5'}px` }"
      />
    </v-col>
    <v-col
      v-if="withButtons || withSaveButton"
      class="row-action-buttons"
      cols="12"
    >
      <div class="action-button" v-if="!withSaveButton">
        <SearchButton
          background-color="grey"
          data-cy="Cancelar-button"
          text="Cancelar"
          :disabled="loading"
          @click.native.prevent="$emit('cancel')"
        />
      </div>
      <div class="action-button">
        <SearchButton
          data-cy="Salvar-button"
          text="Salvar"
          :disabled="loading"
          @click.native.prevent="validateForms"
        />
      </div>
    </v-col>
  </v-form>
</template>

<script>
import JazzFieldMoney from "@/components/fields/JazzFieldMoney";
import FormFieldsHeader from "../../components/FormFieldsHeader";
import TextFieldMask from "../../components/inputs/TextFieldMask";
import TextField from "../../components/inputs/TextField";
import TextFieldCount from "../../components/inputs/TextFieldCount";
import TextAreaField from "../../components/inputs/TextAreaField";
import TextAreaFieldCount from "../../components/inputs/TextAreaFieldCount";
import ButtonLarge from "../../components/button/ButtonLarge";
import SearchButton from "../../components/button/SearchButton";
import InternalButton from "../../components/button/InternalButton";
import SelectField from "../../components/inputs/SelectField";
import CheckBox from "../../components/inputs/CheckBox";
import SimpleFileUpload from "../../components/inputs/SimpleFileUpload";
import SwitchField from "../../components/inputs/SwitchField";
import RadioButtonField from "../../components/inputs/RadioButtonField";
import DateField from "../../components/inputs/DateField";
import CopyTextField from "../../components/inputs/CopyTextField";
import JazzAutoCompleteField from "../fields/JazzAutoCompleteField";
import JazzDataTable from "../../components/table/JazzDataTable";
import JazzDateField from "../fields/JazzDateField";
import Vue from "vue";
import JazzUploadFileField from "../../components/fields/JazzUploadFileField";
import JazzUploadImageField from "../../components/fields/JazzUploadImageField";

export default {
  name: "JazzFormFields",
  components: {
    SelectField,
    SearchButton,
    InternalButton,
    ButtonLarge,
    TextField,
    TextFieldCount,
    CheckBox,
    TextFieldMask,
    TextAreaField,
    TextAreaFieldCount,
    FormFieldsHeader,
    SimpleFileUpload,
    SwitchField,
    RadioButtonField,
    DateField,
    JazzFieldMoney,
    CopyTextField,
    JazzAutoCompleteField,
    JazzDataTable,
    JazzDateField,
    JazzUploadFileField,
    JazzUploadImageField,
  },
  props: {
    removeInput: Boolean,
    withButton: {
      type: String,
      default: undefined,
    },
    withButtons: Boolean,
    withSaveButton: [Boolean, String],
    fields: {
      required: true,
      default: () => {},
      type: Object,
    },
    codRef: {
      type: String,
    },
    disabled: {
      type: Boolean,
    },
    validate: {
      type: Number,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    colsButtonSearch: {
      type: Number,
    },
    focusOnError: {
      type: Boolean,
      default: true,
    },
    watchValidation: Boolean,
    withSaveText: Boolean,
  },
  data() {
    return {
      windowWidth: window.innerWidth,
      inputsValues: {},
      formRef: false,
      formIsValid: false,
    };
  },
  methods: {
    async onClickButton(field) {
      field.onClick(this.fields);
    },
    onEnter() {
      this.validateForms();
    },
    async updateValueField(event, field) {
      if (field && field.variableName) {
        this.inputsValues[field.variableName] = event;
        this.fields[field.variableName].valueField = event;
        if (field.changeValue && !event?.mounted) {
          field.changeValue(this.fields);
        }
        this.verifyRules(field);
        this.$emit("update:values", this.inputsValues);
      }
      if (this.watchValidation && !event?.mounted) {
        await Object.entries(this.fields).forEach((field) => {
          this.verifyRules(field[1]);
        });
        this.formIsValid = this.$refs[this.formRef].validate();
      }
    },
    verifyRules(field) {
      if (this.fields[field.variableName]) {
        const rules = this.fields[field.variableName].rules;
        if (rules && Array.isArray(rules) && rules.length > 0) {
          this.fields[field.variableName].dinamicRules = [];
          rules.forEach((rule) => {
            this.fields[field.variableName].dinamicRules.push(
              rule(this.fields)
            );
          });
        }
      }
      this.$forceUpdate();
    },
    async validateForms() {
      if (!this.loading) {
        await Object.entries(this.fields).forEach((field) => {
          this.verifyRules(field[1]);
        });
        const isValid = this.$refs[this.formRef].validate();
        this.$emit("checkValidity", {
          isValid: isValid,
          values: this.inputsValues,
        });
        if (!isValid && this.focusOnError) {
          this.$nextTick(() => {
            let domRect = document
              .querySelector(".v-input.error--text")
              ?.getBoundingClientRect();
            if (domRect) {
              window.scrollTo(
                domRect.left + document.documentElement.scrollLeft,
                domRect.top + document.documentElement.scrollTop
              );
            }
          });
        }
        return { isValid: isValid, values: this.inputsValues };
      }
    },
    dataCyField(field) {
      const name = `${field?.label || field?.placeholder || field?.title}`;
      return `${name.normalize("NFD").replace(/[^a-zA-Zs]/g, "")}-input`;
    },
    validateProp(value) {
      if (typeof value != "boolean") {
        return true;
      } else {
        return false;
      }
    },
    dataCyButton(field) {
      const name = field?.text;
      return `${name.normalize("NFD").replace(/[^a-zA-Zs]/g, "")}-button`;
    },
  },
  watch: {
    fields: {
      immediate: true,
      handler() {
        Object.entries(this.fields).forEach((field) => {
          Vue.set(this.fields[field[0]], "variableName", field[0]);
          Vue.set(this.inputsValues, field[1].variableName, "");
        });
      },
    },
    validate() {
      this.validateForms();
    },
  },
  mounted() {
    window.addEventListener("resize", () => {
      this.windowWidth = window.innerWidth;
    });
    this.formRef = this.codRef ? this.codRef : "form";
  },
  computed: {
    isMobile() {
      return this.windowWidth <= 768;
    },
    withLabel() {
      if (Object.values(this.fields)[0]) {
        return (
          // eslint-disable-next-line no-prototype-builtins
          Object.values(this.fields)[0].hasOwnProperty("label") ||
          // eslint-disable-next-line no-prototype-builtins
          Object.values(this.fields)[0].hasOwnProperty("title")
        );
      }
      return false;
    },
    containsFields() {
      return (
        this.fields &&
        Object.keys(this.fields).length !== 0 &&
        Object.getPrototypeOf(this.fields) === Object.prototype
      );
    },
  },
};
</script>

<style scoped>
.fields {
  display: flex;
  flex-wrap: wrap;
  align-content: stretch;
  align-items: flex-start;
  flex: 1;
}

.form-fields {
  width: 100% !important;
  padding: 0 !important;
  margin: 0 !important;
}

.cleanComponent {
  margin: 0 !important;
  padding: 0 !important;
}

.row-action-buttons {
  display: flex;
  justify-content: flex-end;
  padding: 15px 0 !important;
}

div.action-button {
  width: 160px;
}
</style>
