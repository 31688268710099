import CustomerBenefitRegistration from "../../views/Benefit/Registration/CustomerBenefitRegistration";

export default [
  {
    path: "/beneficio/cadastro",
    name: "CustomerBenefitRegistration",
    component: CustomerBenefitRegistration,
    meta: {
      permission: ["Pages.CadastroBeneficio"],
      permissionName: "CadastroBeneficio",
    },
  },
];
