<template>
  <div class="w-100">
    <JazzListHeader title="Consultar Clientes" hide-button-header />
    <div class="content-page">
      <v-row>
        <JazzSkeletonLoader v-if="loadingIntegrators" type="field" />
        <SelectField
          v-else
          label="Integrador"
          :items="[
            { nome: 'Selecione', organizationUnitId: null },
            ...integradores,
          ]"
          keyName="nome"
          keyValue="organizationUnitId"
          :valueField="params.organizationUnitId || null"
          @update:value="onIntegratorSelected"
          data-cy="integrador-select"
        />
      </v-row>

      <JazzTable
        :loading="loadingTable"
        :reportTable="true"
        :rows="clientes"
        :pagination="pagination"
        @inputNewPage="loadClients($event)"
        emptyMessage="Nenhuma informação encontrada"
      >
        <template slot="reportRow" slot-scope="props">
          <ClientDetailsRow
            @click.native="onClientSelected(props.paramsItem)"
            :client="props.paramsItem"
          />
        </template>
      </JazzTable>
    </div>
  </div>
</template>

<script>
import JazzSkeletonLoader from "../../../components/template/JazzSkeletonLoader";
import SelectField from "../../../components/inputs/SelectField";
import JazzTable from "../../../components/table/JazzTable";
import ClientDetailsRow from "./components/rowsTable/ClientDetailsRow";
import JazzListHeader from "../../../components/template/JazzListHeader";
import {
  TravelUtilidadesService,
  ClientesTravelService,
} from "../../../services/apitravel";

export default {
  name: "TravelClientsIndex",
  components: {
    JazzSkeletonLoader,
    JazzListHeader,
    SelectField,
    JazzTable,
    ClientDetailsRow,
  },
  data() {
    return {
      integradores: [],
      clientes: [],
      pagination: {},
      selectedIntegrator: {
        organizationUnitId: null,
      },
      loadingIntegrators: true,
      loadingTable: false,
      params: {},
      onLoad: false,
    };
  },

  mounted() {
    this.params = this.$route.params;

    if (this.params?.organizationUnitId) {
      this.onLoad = true;
      this.integradores = this.params?.integradores;
      this.loadingIntegrators = false;
      this.selectedIntegrator = {};
      this.selectedIntegrator.organizationUnitId =
        this.params?.organizationUnitId;
      this.selectedIntegrator.nome = this.params?.integrador;
      this.pagination = this.params?.pagination;
      this.clientes = this.params?.clientes;
      this.onLoad = false;
    } else {
      this.loadIntegradores();
    }
  },

  methods: {
    onClientSelected(client) {
      this.$router.push({
        name: "TravelClientDetails",
        params: {
          integrador: this.selectedIntegrator.nome || this.params?.integrador,
          chaveDeCliente: client?.chaveDeCliente || this.params?.chaveDeCliente,
          organizationUnitId:
            this.selectedIntegrator.organizationUnitId ||
            this.params?.organizationUnitId,
          pagination: this.pagination,
          integradores: this.integradores,
          clientes: this.clientes,
        },
      });
    },

    onIntegratorSelected(integrator) {
      this.selectedIntegrator = integrator;

      let uoId = this.selectedIntegrator.organizationUnitId;

      if (uoId && !this.onLoad) {
        this.loadClients();
      } else {
        if (!this.params?.clientes) {
          this.clientes = [];
        }
      }
    },

    loadIntegradores() {
      this.loadingIntegrators = true;

      TravelUtilidadesService.listarIntegradoresDisponiveis({})
        .then((response) => {
          this.integradores = response.result.integradores;
          if (this.integradores.length === 1) {
            this.params.organizationUnitId =
              this.integradores[0].organizationUnitId;
            this.params.integrador = this.integradores[0].nome;
            this.onIntegratorSelected(this.integradores[0]);
          }
        })
        .finally(() => {
          this.loadingIntegrators = false;
        });
    },

    loadClients(page = 1) {
      this.loadingTable = true;

      let payload = {
        organizationUnitId: this.selectedIntegrator.organizationUnitId,
        pagina: page,
        porPagina: 25,
      };

      ClientesTravelService.filtrar({
        body: payload,
      })
        .then((response) => {
          this.pagination.page = response.result.pagina;
          this.pagination.perPage = response.result.qtdPorPagina;
          this.pagination.total = response.result.totalItems;
          this.pagination.pages = response.result.qtdPaginas;
          this.clientes = response.result.items;
        })
        .finally(() => {
          this.loadingTable = false;
        });
    },
  },
};
</script>

<style lang="scss">
.content-page {
  padding: 0 16px;
}
</style>
