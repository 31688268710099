<template>
  <router-view :client="client" :balances="balances" :account="account" />
</template>

<script>
export default {
  name: "CreditAccount",
  props: {
    account: Object,
    balances: Object,
    client: Object,
  },
};
</script>

<style scoped></style>
