<template>
  <v-card outlined class="jazz-card-header">
    <v-icon class="icon-page" color="var(--primaryColor)" :size="72">
      {{ iconName }}
    </v-icon>
    <div class="row-group">
      <template v-if="!loading">
        <JazzTextGroup
          v-for="(group, index) in groupsText"
          :key="index"
          :label="group.label"
          :text="group.text"
          isHeader
        />
      </template>
      <JazzSkeletonLoader v-if="loading" type="cardHeader" />
    </div>
    <div data-cy="back-button" class="rounded-circle circle-icon" @click="$emit('back')">
      <v-icon color="white" :size="22">
        fas fa-arrow-left
      </v-icon>
    </div>
  </v-card>
</template>

<script>
import JazzTextGroup from "../../components/typography/JazzTextGroup";
import JazzSkeletonLoader from "./JazzSkeletonLoader";

export default {
  name: "JazzCardHeader",
  components: { JazzSkeletonLoader, JazzTextGroup },
  props: {
    iconName: {
      type: String,
      default: "fas fa-user-circle",
    },
    groupsText: Array,
    loading: Boolean,
  },
};
</script>

<style lang="scss" scoped>
.jazz-card-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: $whiteColor;
  padding: 30px;
  margin: 0 0 15px 0;
  flex-wrap: wrap;
  @media only screen and (max-width: 1200px) {
    padding: 30px;
  }
}

.row-group {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex: 1;
  min-height: 60px;
  margin: 0 30px;
  @media only screen and (max-width: 1200px) {
    justify-content: space-between;
    margin: 0 30px 0 0;
  }
  @media only screen and (max-width: 700px) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.icon-page {
  @media only screen and (max-width: 1200px) {
    display: none;
  }
}

.circle-icon {
  height: 72px;
  width: 72px;
  background: $primaryColor;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin: 0 10px;
}

.fa-arrow-left {
  cursor: pointer;
  color: $whiteColor;
  font-size: 20px;
}
</style>
