<template>
  <div class="benefit-file">
    <v-icon size="40" color="primary">fas fa-file</v-icon>
    <JazzTextGroup
      class="ml-5"
      :label="paramsItem.nomeArquivo || '---'"
      reverse
      :text="paramsItem.organizationUnitName || '---'"
    />
  </div>
</template>

<script>
import JazzTextGroup from "../../../../../components/typography/JazzTextGroup";

export default {
  name: "BenefitProcessingFileColumn",
  props: {
    paramsItem: Object,
  },
  components: { JazzTextGroup },
};
</script>

<style lang="scss" scoped>
.benefit-file {
  display: flex;
  border: solid 1px $primaryColor;
  width: 350px;
  margin-bottom: 25px;
  background-color: $whiteColor;
  border-radius: 10px;
  height: 70px;
  padding: 20px;
  align-items: center;
}
</style>
