<template>
  <div class="w-100">
    <JazzText class="mb-5" weight="bold" size="description">
      Passo 2. Edite o arquivo modelo com os dados dos colaboradores.
    </JazzText>
    <img
      class="w-100"
      src="../../images/Excel_1.png"
      alt="Exemplo de arquivo"
    />
    <DefaultTooltip class="mt-5 mb-5" with-slot>
      <JazzText size="description">
        Não utilize acentos ou caracteres especiais.
        <br />
        Informe os dados de um colaborador por linha.
      </JazzText>
      <div class="instructions">
        <div class="w-50">
          <JazzText
            size="description"
            :key="index"
            v-for="(item, index) in leftColumn"
          >
            <strong>{{ item.title }} = </strong>{{ item.content }}
          </JazzText>
        </div>
        <div class="w-50 ml-10">
          <JazzText
            size="description"
            :key="index"
            v-for="(item, index) in rightColumn"
          >
            <strong>{{ item.title }} = </strong>{{ item.content }}
          </JazzText>
        </div>
      </div>
    </DefaultTooltip>
  </div>
</template>

<script>
import globalMethods from "../../../../../../mixins/globalMethods";
import JazzText from "../../../../../../components/typography/JazzText";
import DefaultTooltip from "../../../../../../components/tooltip/DefaultTooltip";

export default {
  name: "Step2",
  mixins: [globalMethods],
  components: { JazzText, DefaultTooltip },
  data() {
    return {
      leftColumn: [
        {
          title: "Identificação fiscal",
          content: "CPF ou CNPJ - apenas números",
        },
        {
          title: "Nome",
          content: "Nome Completo sem abreviações",
        },
        {
          title: "DataNascimento",
          content: "Data no formato DD/MM/YYYY",
        },
        {
          title: "NomeMae",
          content: "Nome Completo sem abreviações",
        },
        {
          title: "DDDMovel",
          content: "DDD de 2 dígitos",
        },
        {
          title: "TelefoneMovel",
          content: "Número de celular sem hífen ou espaço",
        },
        {
          title: "Email",
          content: "E-mail no formato 'email@empresa.com",
        },
      ],
      rightColumn: [
        {
          title: "Rua",
          content: "Endereço sem o número ou complementos",
        },
        {
          title: "Número",
          content: "Caso não tenha número, informar 'S/N'",
        },
        {
          title: "Complemento",
          content: "Apartamento, bloco, etc",
        },
        {
          title: "Bairro",
          content: "Bairro sem abreviações ",
        },
        {
          title: "CEP",
          content: "CEP de 8 dígitos, sem hífen ou espaço",
        },
        {
          title: "Cidade",
          content: "Cidade sem abreviações",
        },
        {
          title: "UF",
          content: "Estado no formato 'SP', 'RJ', 'PE', etc",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.instructions {
  display: flex;
  flex-direction: row;
}
</style>
