<template>
  <div
    v-if="message && message.length > 0"
    class="snackbar"
    data-cy="snackbar-div"
  >
    <v-icon :size="18" color="white">
      fas fa-check-circle
    </v-icon>
    {{ message }}
  </div>
</template>

<script>
export default {
  name: "MessageSnackbar",
  props: ["message"],
};
</script>

<style scoped lang="scss">
.snackbar {
  margin: 10px;
  top: 0;
  right: 0;
  position: fixed;
  padding: 20px 30px;
  background: $primaryColor;
  color: $whiteColor;
  font-weight: bold;
  font-size: 14px;
  z-index: 999;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.snackbar i {
  margin-right: 7px;
  font-size: 18px;
}

.snackbar:hover {
  cursor: pointer;
  background: $primaryColor;
}
</style>
