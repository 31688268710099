<template>
  <div id="app" :class="currentTheme">
    <v-app v-if="publicKey">
      <JazzConfirmationModal
      v-if="showConfirmModal"
        :title="titleConfirmModal"
        :message="messageConfirmModal"
        :cancel-text="cancelTextConfirmModal"
        :confirm-text="confirmTextConfirmModal"
        :direct-button="directButtonConfirmModal"
        :icon="confirmModalIcon"
        :secondaryMessages="confirmModalSecondaryMessages"
        :alignSecondaryMessages="confirmModalAlignSecondaryMessages"
      />
      <MessageSnackbar :message="messageSnackbar" v-if="showMessageSnackbar" />
      <MasterLoading v-if="masterLoading" />
      <JazzMessageModal
        v-if="showJazzMessageModal"
        :title="titleJazzMessageModal"
        :message="messageJazzMessageModal"
        :style-message="styleJazzMessageModal"
        :button-text="buttonTextJazzMessageModal"
      />
      <MessageModal
        v-if="showMessageModal"
        :styleMessage="styleMessageModal"
        :title="titleMessageModal"
        :message="messageModal"
      />
      <transition name="fade" mode="out-in">
        <router-view />
      </transition>
    </v-app>
  </div>
</template>
<script>
import MasterLoading from "./components/template/MasterLoading";
import MessageModal from "./components/modal/JazzMessageModal";
import { mapActions, mapState } from "vuex";
import JazzConfirmationModal from "./components/modal/JazzConfirmationModal";
import JazzMessageModal from "./components/modal/JazzMessageModal";

export default {
  name: "App",
  components: {
    JazzMessageModal,
    JazzConfirmationModal,
    MessageModal,
    MasterLoading,
  },
  async mounted() {
    await this.action_secureConfig();
  },
  methods: mapActions(["action_secureConfig"]),
  computed: mapState({
    publicKey: (state) => state.auth.secure.publicKey,
    currentTheme: (state) => state.theme.currentTheme,
    masterLoading: (state) => state.template.loading,
    showMessageSnackbar: (state) => state.template.messageSnackbar.show,
    messageSnackbar: (state) => state.template.messageSnackbar.message,
    showMessageModal: (state) => state.template.messageModal.show,
    styleMessageModal: (state) => state.template.messageModal.styleMessage,
    titleMessageModal: (state) => state.template.messageModal.title,
    messageModal: (state) => state.template.messageModal.message,
    showConfirmModal: (state) => state.template.confirmModal.show,
    titleConfirmModal: (state) => state.template.confirmModal.title,
    messageConfirmModal: (state) => state.template.confirmModal.message,
    cancelTextConfirmModal: (state) => state.template.confirmModal.cancelText,
    confirmTextConfirmModal: (state) => state.template.confirmModal.confirmText,
    directButtonConfirmModal: (state) =>
      state.template.confirmModal.directButton,
    confirmModalSecondaryMessages: (state) =>
      state.template.confirmModal.secondaryMessages,
    confirmModalAlignSecondaryMessages: (state) =>
      state.template.confirmModal.alignSecondaryMessages,
    confirmModalIcon: (state) => state.template.confirmModal.icon,
    showJazzMessageModal: (state) => state.template.jazzMessageModal.show,
    styleJazzMessageModal: (state) =>
      state.template.jazzMessageModal.styleMessage,
    titleJazzMessageModal: (state) => state.template.jazzMessageModal.title,
    messageJazzMessageModal: (state) => state.template.jazzMessageModal.message,
    buttonTextJazzMessageModal: (state) =>
      state.template.jazzMessageModal.buttonText,
  }),
};
</script>
