<template>
  <div class="modification-history-row">
    <JazzExpansiveRow data-cy="data-responsavel">
      <template #Header>
        <div class="modification-history-row">
          <v-col cols="4">
            <JazzTextGroup
              label="Data da Modificação"
              :text="dateTimeFormat(paramsItem.data) || '--/--/--'"
            />
          </v-col>
          <v-col cols="3">
            <JazzTextGroup
              label="Responsável"
              :text="paramsItem.responsavel || '-'"
            />
          </v-col>
          <v-col cols="5">
            <JazzTextGroup
              label="Campos Alterados"
              :text="formatChangedFields(paramsItem.alteracoes) || '-'"
            />
          </v-col>
        </div>
      </template>
      <template #Content>
        <div
          v-if="!paramsItem.motivo == '' || !paramsItem.motivo == null"
          class="modification-history-row"
        >
          <v-col cols="12">
            <JazzTextGroup label="Observação" :text="paramsItem.motivo" />
          </v-col>
        </div>
        <div v-for="(change, index) in paramsItem.alteracoes" :key="index">
          <div class="modification-history-row">
            <v-col cols="4">
              <JazzTextGroup
                label="Campo Alterado"
                :text="fieldName(change.campo) || '-'"
              />
            </v-col>
            <v-col cols="3">
              <JazzTextGroup
                :label="
                  fieldName(change.campo) +
                    (change.campo === 'LastModificationTime' ||
                    change.campo === 'Senha'
                      ? ' Antiga'
                      : ' Antigo') || '-'
                "
                :text="changedValue(change.campo, change.valorAntigo) || '-'"
                textColor="error"
              />
            </v-col>
            <v-col cols="5">
              <JazzTextGroup
                :label="
                  fieldName(change.campo) +
                    (change.campo === 'LastModificationTime' ||
                    change.campo === 'Senha'
                      ? ' Nova'
                      : ' Novo') || '-'
                "
                :text="changedValue(change.campo, change.valorNovo) || '-'"
                textColor="primary"
              />
            </v-col>
          </div>
        </div>
      </template>
    </JazzExpansiveRow>
  </div>
</template>

<script>
import globalMethods from "../../../../../mixins/globalMethods";
import JazzExpansiveRow from "../../../../../components/table/rows/JazzExpansiveRow";
import JazzTextGroup from "../../../../../components/typography/JazzTextGroup";

export default {
  name: "ModificationHistoryTableRow",
  mixins: [globalMethods],
  props: {
    paramsItem: {
      type: Object,
    },
  },
  components: {
    JazzExpansiveRow,
    JazzTextGroup,
  },
  methods: {
    formatChangedFields(changes) {
      let changedFields = [];

      if (changes.length > 0) {
        changes.forEach((change) => {
          changedFields.push(this.fieldName(change.campo));
        });
        return changedFields.toString().replaceAll(",", "; ");
      } else {
        return "-";
      }
    },
    fieldName(field) {
      switch (field) {
        case "LimiteDisponivelDeCredito":
          return "Limite Disponível de Crédito";
        case "LimiteMaximoDeCredito":
          return "Limite Máximo de Crédito";
        case "LastModificationTime":
          return "Data de Alteração";
        case "LastModifierUserId":
          return "Id do Usuário Responsável";
        case "NumeroTelefoneMovel":
          return "Número Telefone Móvel";
        case "NumeroTelefoneFixo":
          return "Número Telefone Fixo";
        case "DddTelefoneFixo":
          return "DDD - Telefone Fixo";
        case "DddTelefoneMovel":
          return "DDD - Telefone Móvel";
        case "EmailAutenticacao":
          return "Email de Autenticação";
        default:
          return field;
      }
    },
    changedValue(type, value) {
      if (value) {
        let formattedValue = value.replace(/^"|"$/g, "");

        switch (type) {
          case "LimiteDisponivelDeCredito":
            return "R$ " + this.money2(value);
          case "LimiteMaximoDeCredito":
            return "R$ " + this.money2(value);
          case "LastModificationTime":
            return this.dateTimeFormat(formattedValue);
          default:
            return formattedValue;
        }
      }
    },
  },
};
</script>

<style scoped lang="scss">
.modification-history-row {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
</style>
