<template>
  <div class="w-100">
    <div class="form">
      <JazzFormFields
        :withButton="'Pesquisar'"
        :fields="fieldsconsultHistoryForDate"
        @checkValidity="consultHistory"
      />
    </div>
    <DefaultLoading v-if="loading" />
    <JazzTable
      v-else
      :rows="items"
      :pagination="pagination"
      @inputNewPage="consultHistory"
    >
      <template slot="reportRow" slot-scope="props">
        <v-expansion-panels>
          <ModificationHistoryTableRow :paramsItem="props.paramsItem" />
        </v-expansion-panels>
      </template>
    </JazzTable>
  </div>
</template>

<script>
import JazzTable from "../../../../components/table/JazzTable";
import ModificationHistoryTableRow from "./rowsTable/ModificationHistoryTableRow";
import JazzFormFields from "../../../../components/form/JazzFormFields";
import { HistoricoAlteracoesService } from "../../../../services/apibackoffice";
import globalMethods from "../../../../mixins/globalMethods";
import DefaultLoading from "../../../../components/template/DefaultLoading";

export default {
  name: "ModificationHistoryTable",
  props: {
    entityId: Array,
    entityTypes: Array,
  },
  components: {
    JazzTable,
    ModificationHistoryTableRow,
    JazzFormFields,
    DefaultLoading,
  },
  mixins: [globalMethods],
  data: function () {
    return {
      fieldsconsultHistoryForDate: {},
      pagination: {
        perPage: 5,
        page: 1,
        total: 0,
        pages: 0,
      },
      items: [],
      loading: false,
    };
  },
  mounted() {
    this.fieldsconsultHistoryForDate = {
      initialDate: {
        field: "DateField",
        label: "Data Inicio",
        cols: 5,
        valueField: this.dateSubtract30Days(),
        dateType: "date",
        rules: [
          (fields) =>
            !this.compareDateAfterAndCurrent(
              fields.initialDate.valueField,
              fields.finalDate.valueField
            ) || "Data inicial deve ser menor que data final",
        ],
      },
      finalDate: {
        field: "DateField",
        label: "Data Fim",
        cols: 5,
        valueField: this.dateEnFormat(),
        dateType: "date",
        rules: [
          (fields) =>
            !this.compareDateAfterAndCurrent(
              fields.initialDate.valueField,
              fields.finalDate.valueField
            ) || "Data final deve ser maior que data inicial",
        ],
      },
    };
    this.consultHistory();
  },
  methods: {
    consultHistory(event) {
      if (event?.isValid ?? true) {
        this.loading = true;
        let payload = {
          entidadeId: this.entityId,
          tipoEntidade: this.entityTypes,
          dataInicio: this.dateISOFormat(
            this.fieldsconsultHistoryForDate.initialDate.valueField +
              " 00:00:00 +0000",
            "YYYY-MM-DD HH:mm:ss Z"
          ),
          dataFim: this.dateISOFormat(
            this.fieldsconsultHistoryForDate.finalDate.valueField +
              " 23:59:59 +0000",
            "YYYY-MM-DD HH:mm:ss Z"
          ),
          pagina: Number.isInteger(event) ? event : this.pagination.page,
          porPagina: this.pagination.perPage,
        };
        HistoricoAlteracoesService.buscarPaginado({
          body: payload,
        })
          .then((resp) => {
            this.pagination.page = resp.result.pagina;
            this.pagination.perPage = resp.result.qtdPorPagina;
            this.pagination.total = resp.result.totalItems;
            this.pagination.pages = resp.result.qtdPaginas;
            this.items = resp.result.items;
          })
          .finally(() => (this.loading = false));
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.form {
  padding-bottom: 20px;
}
</style>
