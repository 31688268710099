<template>
  <v-col :cols="cols">
    <div class="label-box">
      <v-label>{{ label }}</v-label>
    </div>
    <v-autocomplete :data-cy="dataCy" :key="keyField" :disabled="loading || disabled"
      :item-text="keyName ? keyName : 'name'" :item-value="keyValue ? keyValue : 'value'" v-model="select" :items="items"
      outlined :class="[
        rules ? 'has-rules' : '',
        hint ? 'hint' : 'no-hint',
        borderDanger ? 'borderDanger' : '',
      ]" :rules="rules ? rules : []" :prepend-inner-icon="prependInnerIcon" :type="type" :placeholder="placeholder"
      class="text-field" return-object :loading="loading">
      <template slot="no-data">
        <div class="message-box">Nenhuma informação encontrada.</div>
      </template>
      <template slot="loading">
        <div class="message-box">Carregando</div>
      </template>
    </v-autocomplete>
  </v-col>
</template>

<script>
export default {
  name: "JazzAutoCompleteField",
  props: {
    dataCy: String,
    label: String,
    placeholder: String,
    type: String,
    cols: Number,
    prependInnerIcon: String,
    rules: Array,
    items: Array,
    keyName: String,
    keyValue: String,
    disabled: Boolean,
    valueField: Object,
    hint: Array || String,
    loading: Boolean,
    isFormFields: Boolean,
    borderDanger: String,
  },
  data() {
    return {
      select: {},
      keyField: 0,
    };
  },
  mounted() {
    if (!this.valueField && this.items.length > 0) {
      this.select = this.items[0];
    } else {
      this.select = this.valueField;
    }
    if (!this.rules || this.isFormFields) {
      this.$emit("update:value", this.select);
    }
    this.keyField++;
  },
  watch: {
    valueField: {
      immediate: true,
      handler(newText, oldText) {
        if (
          newText &&
          (newText[this.keyValue] || newText[this.keyValue] === null) &&
          newText[this.keyValue] !==
          (oldText ? oldText[this.keyValue] : oldText)
        ) {
          this.select = newText;
        }
      },
    },
    select: {
      immediate: true,
      handler() {
        this.$emit("update:value", this.select);
      },
    },
  },
};
</script>


<style scoped>
.container-input {
  display: flex;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

label {
  color: var(--grayColor) !important;
  font-size: 12px !important;
}

.label-box {
  margin-bottom: 5px;
}

.v-input ::v-deep .v-input__slot {
  background: var(--opacityGrayColor) !important;
  border: solid #dee4f0 1px !important;
  color: var(--opacityGrayColor);
  min-height: 48px !important;
  max-height: 48px !important;
}

.v-input ::v-deep input {
  color: var(--primaryColor) !important;
  font-weight: bold;
  font-size: 14px;
}

.v-input ::v-deep fieldset {
  color: transparent !important;
}

.v-input.no-hint ::v-deep .v-text-field__details {
  display: none;
}

.v-input.error--text ::v-deep .v-text-field__details {
  display: block;
}

.v-input.hint ::v-deep .v-messages__message {
  color: var(--blackColor);
  font-size: 12px;
}

.v-input ::v-deep .v-messages__message {
  color: var(--dangerColor);
}

.v-input.error--text ::v-deep .v-input__slot {
  border: solid var(--dangerColor) 1px !important;
}

.v-input ::v-deep .v-input__slot {
  background: #ffffff !important;
  border-radius: 10px;
  border: solid var(--fieldBox) 1px !important;
}

.v-input ::v-deep i {
  color: var(--grayColor);
  font-size: 30px;
  margin-bottom: 10px;
}

.v-input ::v-deep .v-input__prepend-inner {
  margin: 10px 5px 0 0;
}

.v-input ::v-deep input::placeholder {
  color: var(--opacityGrayColor) !important;
  opacity: 1;
}

.v-input ::v-deep input {
  color: var(--blackColor) !important;
}

.v-input ::v-deep input:focus {
  color: var(--blackColor) !important;
}

.v-input ::v-deep .v-select__selection {
  color: var(--blackColor) !important;
  font-weight: bold;
  font-size: 14px;
  width: 100%;
}

.message-box {
  padding: 10px;
  color: var(--blackColor);
  font-size: 14px;
}

.col-1 {
  max-width: 100%;
}

.col-2 {
  max-width: 100%;
}

.col-3 {
  max-width: 100%;
}

.col-4 {
  max-width: 100%;
}

.col-5 {
  max-width: 100%;
}

.col-6 {
  max-width: 100%;
}

.col-7 {
  max-width: 100%;
}

.col-8 {
  max-width: 100%;
}

.col-9 {
  max-width: 100%;
}

.col-10 {
  max-width: 100%;
}

.col-11 {
  max-width: 100%;
}

.col-12 {
  max-width: 100%;
}

.v-input.disabledField ::v-deep .v-input__slot {
  background: var(--disabledColor) !important;
  font-style: italic;
}

.v-input.disabledField ::v-deep .v-select__selection {
  color: var(--grayColor) !important;
  font-weight: 500;
}
</style>