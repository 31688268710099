var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"header",class:{ 'title-border': _vm.borderBottom }},[_c('div',{on:{"click":function($event){return _vm.$emit('backBreadcrumb', false)}}},[_c('div',{staticClass:"row-titles",class:{ clickable: _vm.breadcrumb }},[_c('h2',[_vm._v(_vm._s(_vm.title))]),(_vm.breadcrumb)?_c('h3',{staticClass:"subtitle"},[_c('v-icon',{staticClass:"mx-3",attrs:{"size":"15","color":"white"}},[_vm._v(" fas fa-arrow-right ")]),_c('a',[_vm._v(_vm._s(_vm.breadcrumb))])],1):_vm._e()]),(_vm.totalItems && _vm.totalItems > 0)?_c('h6',{staticClass:"tag-title"},[(!_vm.hideArrow)?_c('v-icon',{attrs:{"color":"white"}},[_vm._v(" fas fa-long-arrow-alt-right ")]):_vm._e(),_vm._v(" "+_vm._s(_vm.totalItems)+_vm._s(_vm.totalItems > 1 ? " resultados" : " resultado")+" ")],1):_vm._e()]),(!_vm.hideButtonHeader)?_c('div',{staticClass:"buttons-box"},[(!_vm.hideFilterButton)?_c('CircleButton',{attrs:{"data-cy":"filter-button","title":_vm.showFiltersFields ? 'Ocultar Filtros' : 'Exibir Filtros',"featured":!_vm.showFiltersFields,"icon":'fas fa-filter'},nativeOn:{"click":function($event){_vm.showFiltersFields = !_vm.showFiltersFields;
        _vm.$emit('update:changeButtonFilters', _vm.showFiltersFields);}}}):_vm._e(),(_vm.helpRoute && !_vm.loading)?_c('DefaultButton',{staticClass:"mr-5",attrs:{"data-cy":"help-button","title":_vm.helpRoute.title,"featured":true,"icon":_vm.helpRoute.icon,"colorStyle":"white-green ultra-radius reverse","text":_vm.helpRoute.text},nativeOn:{"click":function($event){_vm.$router && _vm.helpRoute.route
          ? _vm.$router.push({ name: _vm.helpRoute.route })
          : _vm.$emit('helpButton')}}}):_vm._e(),(_vm.creationRoute && !_vm.loading && !_vm.buttonWithLabel)?_c('CircleButton',{attrs:{"data-cy":"creation-button","title":_vm.creationRoute.title,"featured":true,"icon":"fas fa-plus"},nativeOn:{"click":function($event){_vm.$router && _vm.creationRoute.route
          ? _vm.$router.push({ name: _vm.creationRoute.route })
          : _vm.$emit('creationButton');
        _vm.$router && _vm.creationRoute.route
          ? _vm.$router.push({ name: _vm.creationRoute.route })
          : _vm.$emit('creationButton');}}}):_vm._e(),(_vm.creationRoute && !_vm.loading && _vm.buttonWithLabel)?_c('DefaultButton',{attrs:{"data-cy":"creation-label-button","title":_vm.creationRoute.title,"featured":true,"icon":"fas fa-plus","colorStyle":"ultra-radius reverse","text":_vm.creationRoute.text},nativeOn:{"click":function($event){_vm.$router && _vm.creationRoute.route
          ? _vm.$router.push({ name: _vm.creationRoute.route })
          : _vm.$emit('creationButton')}}}):_vm._e()],1):_vm._e(),(_vm.withBackButton)?_c('div',{staticClass:"buttons-box"},[_c('CircleButton',{attrs:{"data-cy":"back-button","title":"Voltar","featured":true,"icon":'fas fa-arrow-left'},nativeOn:{"click":function($event){return _vm.$router.back()}}}),(_vm.creationRoute && !_vm.loading && _vm.buttonWithLabel)?_c('DefaultButton',{attrs:{"data-cy":"creation-label-button","title":_vm.creationRoute.title,"featured":true,"icon":"fas fa-plus","colorStyle":"ultra-radius reverse","text":_vm.creationRoute.text},nativeOn:{"click":function($event){_vm.$router && _vm.creationRoute.route
          ? _vm.$router.push({ name: _vm.creationRoute.route })
          : _vm.$emit('creationButton')}}}):_vm._e()],1):_vm._e(),(_vm.withBackButton)?_c('div',{staticClass:"buttons-box"},[_c('CircleButton',{attrs:{"data-cy":"back-button","title":"Voltar","featured":true,"icon":'fas fa-arrow-left'},nativeOn:{"click":function($event){return _vm.$router.back()}}})],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }