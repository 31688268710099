<template>
  <v-card min-height="100" outlined>
    <div class="margem">
      <slot></slot>
    </div>
  </v-card>
</template>
<script>
export default {
  name: "DefaultCard",
};
</script>

<style>
.margem {
  margin: 10px;
}
</style>
