<template>
  <div>
    <JazzFormFields disabled :fields="fields" />
    <JazzSkeletonLoader v-if="!client || loading" type="form" />
  </div>
</template>

<script>
import JazzFormFields from "../../../components/form/JazzFormFields";
import JazzSkeletonLoader from "../../../components/template/JazzSkeletonLoader";
import estadosBrasileiros from "../../../dbjson/estadosBrasileiros.json";
import globalMethods from "../../../mixins/globalMethods";
export default {
  name: "ClientIdentification",
  mixins: [globalMethods],
  components: { JazzSkeletonLoader, JazzFormFields },
  props: {
    client: Object,
  },
  data() {
    return {
      fields: {},
      loading: true,
    };
  },
  mounted() {
    if (this.client) {
      this.createFields();
    }
  },
  methods: {
    createFields() {
      this.fields = {
        infoHeader: {
          field: "FormFieldsHeader",
          title: "Identificação",
        },
        identificacaoTextField: {
          field: "TextFieldMask",
          type: "text",
          label: "CPF",
          cols: 12,
          maskFormat: "###.###.###-##",
          valueField: this.client?.identificacaoFiscal,
        },
        registrationGeneralTextField: {
          field: "TextFieldMask",
          type: "text",
          label: "RG",
          cols: 9,
          valueField: this.client?.rg?.numero,
          maskFormat: "###########",
        },
        registrationGeneralUfSelectField: {
          field: "SelectField",
          type: "text",
          label: "UF de expedição",
          cols: 3,
          valueField: this.client
            ? this.findObjectFromArray(
                estadosBrasileiros,
                "key",
                this.client?.rg?.ufOrgaoEmissor
              )
            : "",
          items: [{ key: "", value: "Selecione" }, ...estadosBrasileiros],
          keyName: "value",
          keyValue: "key",
        },
        registrationGeneralAgencySelectField: {
          field: "SelectField",
          type: "text",
          label: "Orgão Emissor",
          cols: 8,
          valueField:
            this.client && this.client?.rg?.orgaoEmissor
              ? this.findObjectFromArray(
                  this.arrayEmittingOrgan(),
                  "value",
                  this.client?.rg?.orgaoEmissor.toUpperCase()
                )
              : "",
          items: [
            { label: "Selecione", value: "" },
            ...this.arrayEmittingOrgan(),
          ],
          keyName: "label",
          keyValue: "value",
        },
        registrationGeneralDateTextField: {
          field: "TextFieldMask",
          type: "text",
          label: "Data Emissão",
          cols: 4,
          valueField:
            this.client && this.client?.rg?.dataEmissao
              ? this.dateFormat(this.client?.rg?.dataEmissao)
              : "",
          maskFormat: "##/##/####",
        },
        FormFieldsPolityHeader: {
          field: "FormFieldsHeader",
          title: "Pessoa Politicamente Exposta",
        },
        roleTextField: {
          field: "TextField",
          type: "text",
          label: "Cargo",
          valueField:
            this.client && this.client?.pessoaPoliticamenteExposta?.cargo
              ? this.client?.pessoaPoliticamenteExposta?.cargo
              : "",
          cols: 12,
        },
        initRoleTextField: {
          field: "TextFieldMask",
          type: "text",
          label: "Início Exposição",
          cols: 6,
          valueField:
            this.client &&
            this.client?.pessoaPoliticamenteExposta?.inicioExposicao
              ? this.dateFormat(
                  this.client?.pessoaPoliticamenteExposta?.inicioExposicao
                )
              : "",
          maskFormat: "##/##/####",
        },
        endRoleTextField: {
          field: "TextFieldMask",
          type: "text",
          label: "Fim Exposição",
          cols: 6,
          valueField:
            this.client && this.client?.pessoaPoliticamenteExposta?.fimExposicao
              ? this.dateFormat(
                  this.client?.pessoaPoliticamenteExposta?.fimExposicao
                )
              : "",
          maskFormat: "##/##/####",
        },
      };
      this.loading = false;
    },
  },
  watch: {
    client() {
      this.createFields();
    },
  },
};
</script>

<style scoped></style>
