export const templateStore = {
  state: () => ({
    confirmModal: {
      show: false,
      title: "",
      message: undefined,
      cancelText: undefined,
      directButton: undefined,
      confirmText: null,
      value: null,
      icon: null,
      secondaryMessages: [],
      alignSecondaryMessages: "",
    },
    validateCreate: 0,
    modalCreate: false,
    messageModal: {
      show: false,
      title: "",
      message: "",
      styleMessage: "danger",
    },
    jazzMessageModal: {
      show: false,
      title: "",
      message: undefined,
      styleMessage: undefined,
      buttonText: undefined,
      value: null,
    },
    messageSnackbar: {
      show: false,
      message: "",
    },
    loading: false,
    contentModalLoading: false,
    filtersButton: true,
    hideButtonsContentModal: false,
    itemsPerPageTables: 45,
    filtersPage: {},
  }),
  mutations: {
    CHANGE_VALIDATE_CREATE(state) {
      state.validateCreate++;
    },
    CHANGE_MODAL_CREATE(state) {
      state.modalCreate = !state.modalCreate;
    },
    CHANGE_MESSAGE_MODAL(state, payload) {
      state.messageModal = payload;
    },
    CHANGE_LOADING(state, payload) {
      state.loading = payload;
    },
    CHANGE_CONTENT_MODAL_LOADING(state, payload) {
      state.contentModalLoading = payload;
    },
    CHANGE_MESSAGE_SNACKBAR(state, payload) {
      state.messageSnackbar.show = !state.messageSnackbar.show;
      state.messageSnackbar.message = payload;
    },
    SET_CONFIRM_MODAL(state, payload) {
      state.confirmModal.show =
        payload.show === undefined ? true : payload.show;
      state.confirmModal.title = payload.title || "";
      state.confirmModal.message = payload.message;
      state.confirmModal.cancelText = payload.cancelText;
      state.confirmModal.confirmText = payload.confirmText;
      state.confirmModal.directButton = payload.directButton;
      state.confirmModal.icon = payload.icon;
      state.confirmModal.secondaryMessages = payload.secondaryMessages;
      state.confirmModal.alignSecondaryMessages =
        payload.alignSecondaryMessages || "";
    },
    CHANGE_CONFIRM_MODAL(state, payload) {
      state.confirmModal.value(payload);
    },
    SET_JAZZ_MESSAGE_MODAL(state, payload) {
      state.jazzMessageModal.title = payload.title || "";
      state.jazzMessageModal.message = payload.message;
      state.jazzMessageModal.styleMessage = payload.styleMessage;
      state.jazzMessageModal.buttonText = payload.buttonText;
      state.jazzMessageModal.show =
        payload.show === undefined ? true : payload.show;
    },
    CONFIRM_JAZZ_MESSAGE_MODAL(state) {
      state.jazzMessageModal.show = false;
      state.messageModal.show = false;
    },
    CHANGE_FILTERS_BUTTON(state) {
      state.filtersButton = !state.filtersButton;
    },
    CHANGE_HIDE_BUTTONS_CONTENT_MODAL(state, payload) {
      state.hideButtonsContentModal = payload;
    },
    SET_PAGE_GLOBAL_FILTERS(state, payload) {
      state.globalFiltersPage = payload;
    },
  },
  actions: {
    async action_confirmationModal(context, payload) {
      context.commit("SET_CONFIRM_MODAL", payload);
      return await new Promise((resolve) => {
        context.state.confirmModal.value = resolve;
      }).finally(() => {
        context.commit("SET_CONFIRM_MODAL", { show: false });
        context.commit("CHANGE_CONFIRM_MODAL", null);
      });
    },
    async action_messageModal(context, payload) {
      context.commit("SET_JAZZ_MESSAGE_MODAL", payload);
      return await new Promise((resolve) => {
        context.state.jazzMessageModal.value = resolve;
      }).finally(() => {
        context.commit("SET_JAZZ_MESSAGE_MODAL", { show: false });
        context.commit("CONFIRM_JAZZ_MESSAGE_MODAL", null);
      });
    },
    action_changeMessageSnackBar(context, payload) {
      context.commit("CHANGE_MESSAGE_SNACKBAR", payload);
      setTimeout(() => context.commit("CHANGE_MESSAGE_SNACKBAR", ""), 5000);
    },
    action_openMessageModal(context, payload) {
      const msg = {
        show: true,
        styleMessage: payload.styleMessage,
        title: payload.title,
        message: payload.message,
      };
      if (payload.title === undefined && payload.message === undefined) {
        msg.title = "Ocorreu um erro!";
        msg.message =
          "Verifique a sua conexão com a internet e tente novamente mais tarde.";
      } else if (
        (payload.message === undefined || payload.message === null) &&
        payload.title !== undefined
      ) {
        msg.title = "Atenção !";
        msg.message = payload.title;
      }
      context.commit("CHANGE_MESSAGE_MODAL", msg);
    },
  },
};

export default templateStore;
