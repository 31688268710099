<template>
  <div class="template">
    <section class="container-page">
      <div class="container-menu">
        <Menu class="menu"></Menu>
      </div>
      <div class="content">
        <router-view></router-view>
      </div>
    </section>
  </div>
</template>

<script>
import Menu from "./Menu";
import { mapActions, mapMutations } from "vuex";
import signalrHub from "../services/signalrHub";

export default {
  name: "Template",
  components: { Menu },
  mounted() {
    try {
      this.CHANGE_LOADING(true);
      if (
        !localStorage.getItem("user_id") ||
        !localStorage.getItem("access_token") ||
        localStorage.getItem("access_token") === "undefined" ||
        localStorage.getItem("access_token") === "" ||
        localStorage.getItem("access_token") === null ||
        localStorage.getItem("access_token").length < 20
      ) {
        this.CHANGE_LOADING(false);
        this.$router.push("/login");
      } else {
        this.action_setUserAuth()
          .then(() => {
            signalrHub.start();
            this.CHANGE_LOADING(false);
          })
          .catch(() => {
            this.CHANGE_LOADING(false);
            this.$router.push("/login");
          });
      }
    } catch (e) {
      this.CHANGE_LOADING(false);
      this.$router.push("/login");
    }
  },
  methods: {
    ...mapMutations(["CHANGE_LOADING"]),
    ...mapActions(["action_setUserAuth"]),
  },
};
</script>

<style scoped>
.template {
  width: 100%;
  min-height: 100vh;
  background: var(--backgroundColor) !important;
}

.header {
  z-index: 1;
}

.container-page {
  z-index: 7;
  position: absolute;
  top: 0;
  display: flex;
  width: 100%;
}

.container-menu {
  min-width: 290px;
  width: 290px;
  max-width: 290px;
}

.menu {
  width: 290px;
}

.content {
  padding: 20px;
  flex: 1;
}

@media only screen and (max-width: 900px) {
  .menu,
  .container-menu {
    display: none;
  }
}
</style>
