import { render, staticRenderFns } from "./CollaboratorsAccounts.vue?vue&type=template&id=28583457&scoped=true&"
import script from "./CollaboratorsAccounts.vue?vue&type=script&lang=js&"
export * from "./CollaboratorsAccounts.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "28583457",
  null
  
)

export default component.exports