<template>
  <div class="files-list mt-10 mb-5">
    <JazzListHeader title="Arquivos em processamento" hide-button-header />
    <div class="table-list">
      <div v-for="(item, index) in result" :key="index">
        <PRegistrationProcessingFileColumn :paramsItem="item" />
      </div>
    </div>
  </div>
</template>

<script>
import PRegistrationProcessingFileColumn from "./columnsTable/PRegistrationProcessingFileColumn";
import JazzListHeader from "../../../../components/template/JazzListHeader";

export default {
  name: "ProcessingFilesList",
  components: {
    PRegistrationProcessingFileColumn,
    JazzListHeader,
  },
  props: {
    result: Array,
  },
};
</script>

<style lang="scss" scoped>
.table-list {
  display: flex;
  width: auto;
  height: auto;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.files-list {
  background-color: $lightPrimaryColor;
  padding: 0 25px 0 25px;
}
</style>
