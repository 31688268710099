<template>
  <ContentModal
    title="Passo a passo"
    @close="$emit('showModal', false)"
    @save="lastStep ? $emit('showModal', false) : key++"
    @secondButton="key--"
    hide-cancel-button
    :textButton="lastStep ? 'Finalizar' : 'Próximo'"
    :show-second-button="key > 0"
  >
    <Step1 v-if="key === 0" />
    <Step2 v-if="key === 1" />
    <Step3 v-if="key === 2" />
    <Step4 v-if="key === 3" />
  </ContentModal>
</template>

<script>
import ContentModal from "../../../../../components/modal/ContentModal";
import Step1 from "./steps/Step1";
import Step2 from "./steps/Step2";
import Step3 from "./steps/Step3";
import Step4 from "./steps/Step4";

export default {
  name: "BenefitHelpModalFlow",
  components: { ContentModal, Step1, Step2, Step3, Step4 },
  data() {
    return {
      key: 0,
    };
  },
  computed: {
    lastStep() {
      return this.key === 3;
    },
  },
};
</script>
<style scoped lang="scss"></style>
