<template>
  <div class="background">
    <div
      class="dialog-box"
      data-cy="content-modal"
      :style="{ width: size + '%' }"
    >
      <div class="modal-header">
        <h4>{{ title }}</h4>
        <h4 v-if="!contentModalLoading" @click="closeModal" title="Fechar">
          <v-icon color="white">
            fas fa-times
          </v-icon>
        </h4>
      </div>
      <div
        class="modal-content"
        :class="{
          'border-radius-bottom':
            contentModalLoading || hideButtonsContentModal || hideButtons,
          'no-padding': noPadding,
        }"
      >
        <div v-show="!contentModalLoading">
          <slot />
        </div>
        <DefaultLoading v-if="contentModalLoading" />
      </div>
      <div
        class="modal-footer"
        v-if="!contentModalLoading && !hideButtonsContentModal && !hideButtons"
      >
        <DefaultButton
          v-if="showDeleteButton"
          class="delete-button"
          data-cy="modal-delete-button"
          colorStyle="danger"
          @click.native="$emit('delete')"
          :text="textDeleteButton || 'Excluir'"
        />

        <div class="lastButton">
          <DefaultButton
            class="btn-text"
            v-if="!hideCancelButton"
            data-cy="modal-cancel-button"
            colorStyle="gray"
            @click.native="closeModal()"
            text="Cancelar"
          />
          <DefaultButton
            class="btn-text"
            v-if="showSecondButton"
            data-cy="modal-second-button"
            colorStyle="white-green"
            @click.native="$emit('secondButton')"
            :text="secondButtonText || 'Anterior'"
          />
          <DefaultButton
            :colorStyle="colorStyleButton"
            data-cy="modal-save-button"
            :class="disableButton ? 'disabled' : ''"
            @click.native="$emit('save')"
            :text="textButton || 'Salvar'"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DefaultButton from "../button/DefaultButton";
import { mapState, mapMutations } from "vuex";
import DefaultLoading from "../template/DefaultLoading";

export default {
  name: "ContentModal",
  components: { DefaultLoading, DefaultButton },
  data() {
    return {
      modal: false,
    };
  },
  props: {
    title: {},
    noPadding: {},
    hideButtons: {
      default: false,
      type: Boolean,
    },
    size: {
      type: Number,
      default: 60,
    },
    colorStyleButton: {
      default: "default-button",
    },
    disableButton: Boolean,
    textButton: String,
    hideCancelButton: {
      type: Boolean,
      default: false,
    },
    showSecondButton: {
      type: Boolean,
      default: false,
    },
    secondButtonText: String,
    showDeleteButton: {
      type: Boolean,
      default: false,
    },
    textDeleteButton: String,
  },
  methods: {
    ...mapMutations(["CHANGE_HIDE_BUTTONS_CONTENT_MODAL"]),
    closeModal() {
      this.CHANGE_HIDE_BUTTONS_CONTENT_MODAL(false);
      this.$emit("close");
    },
  },
  computed: {
    ...mapState({
      contentModalLoading: (state) => state.template.contentModalLoading,
      hideButtonsContentModal: (state) =>
        state.template.hideButtonsContentModal,
    }),
  },
};
</script>

<style scoped lang="scss">
.background {
  background-color: rgba(0, 0, 0, 0.9);
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 500;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dialog-box {
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  @media only screen and (max-width: 1024px) {
    width: 100%;
    height: 100vh;
  }
}

.modal-header {
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  background: $primaryColor;
  width: 100%;
  height: 35px;
  padding: 35px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media only screen and (max-width: 1024px) {
    height: auto;
    padding: 15px 35px;
  }
}

.modal-header h4 {
  color: $whiteColor;
  font-size: 24px;
  font-weight: bold;  margin: 0;
}

.modal-content {
  background: var(--backgroundColorModal);
  max-height: 70vh;
  width: 100%;
  padding: 40px;
  font-size: 14px;
  color: var(--blackColor);
  overflow-y: scroll;
  overflow-x: hidden;
  @media only screen and (max-width: 1024px) {
    max-height: 100vh;
  }
}

.modal-content::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

.modal-content::-webkit-scrollbar-thumb {
  background: var(--grayColor);
}

.modal-header h4 i:hover {
  cursor: pointer;
}

.modal-footer {
  background: var(--backgroundColorModal);
  width: 100%;
  height: 40px;
  padding: 40px;
  display: flex;
  align-items: center;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
}

.btn-text {
  margin-right: 20px;
}

.lastButton {
  background: var(--backgroundColorModal);
  width: 100%;
  height: 40px;
  padding: 40px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
}

.delete-button {
  display: flex;
  justify-content: flex-start !important;
}

.border-radius-bottom {
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

.modal-content.no-padding {
  padding: 0;
}
</style>