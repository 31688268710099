<template>
  <v-col
    style="margin: 0 !important; padding: 0 !important"
    class="container-input"
    :cols="cols"
  >
    <v-col :cols="secondaryKeyName ? 6 : 12">
      <div class="label-box">
        <v-label>{{ label }}</v-label>
      </div>
      <div class="container-input">
        <v-select
          :data-cy="dataCy"
          :key="keyField"
          :disabled="disabled"
          :item-text="keyName ? keyName : 'name'"
          :item-value="keyValue ? keyValue : 'value'"
          v-model="select"
          :items="items"
          outlined
          :class="[
            rules ? 'has-rules' : '',
            hint ? 'hint' : 'no-hint',
            disabled ? 'disabledField' : '',
          ]"
          :rules="rules ? rules : []"
          :prepend-inner-icon="prependInnerIcon"
          :type="type"
          :placeholder="placeholder"
          class="text-field"
          @keyup.enter="$emit('onEnter', select)"
          return-object
          :hint="hint"
          :persistent-hint="!!hint"
        >
          <template slot="no-data">
            <div class="message-box">Nenhuma informação encontrada.</div>
          </template>
          <template slot="loading">
            <div class="message-box">Carregando</div>
          </template>
        </v-select>
        <DefaultInformation
          :size="14"
          v-if="helpText !== undefined"
          :text="helpText"
        />
      </div>
    </v-col>
    <v-col v-if="secondaryKeyName" :cols="6">
      <v-label>{{ secondaryLabel }}</v-label>
      <v-text-field
        style="margin-top: 7px"
        :disabled="true"
        :prepend-inner-icon="prependInnerIcon"
        type="text"
        :value="select.motivoTransacaoTarifaALancar"
        class="text-field"
        outlined
      >
      </v-text-field>
    </v-col>
  </v-col>
</template>

<script>
import DefaultInformation from "../tooltip/DefaultInformation";

export default {
  name: "SelectField",
  components: { DefaultInformation },
  props: [
    "dataCy",
    "label",
    "placeholder",
    "type",
    "cols",
    "prependInnerIcon",
    "rules",
    "items",
    "keyName",
    "keyValue",
    "disabled",
    "valueField",
    "hint",
    "helpText",
    "secondaryKeyName",
    "secondaryKeyValue",
    "secondaryLabel",
    "isFormFields",
  ],
  data() {
    return {
      select: {},
      keyField: 0,
    };
  },
  mounted() {
    if (!this.valueField && this.items.length > 0) {
      this.select = this.items[0];
    } else {
      this.select = this.valueField;
    }
    if (!this.rules || this.isFormFields) {
      this.$emit("update:value", this.select);
    }
    this.keyField++;
  },
  watch: {
    valueField: {
      immediate: true,
      handler(newText, oldText) {
        if (
          newText &&
          (newText[this.keyValue] || newText[this.keyValue] === "") &&
          newText[this.keyValue] !==
            (oldText ? oldText[this.keyValue] : oldText)
        ) {
          this.select = newText;
        }
      },
    },
    select: {
      immediate: true,
      handler() {
        this.$emit("update:value", this.select);
      },
    },
  },
  computed: {
    hintLenged() {
      if (this.hint) {
        let string = "";
        this.hint.forEach((legend, index) => {
          string += index % 2 ? ` ${this.select[legend]} | ` : legend;
        });
        return string;
      }
      return "";
    },
  },
};
</script>

<style scoped>
.container-input {
  display: flex;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

label {
  color: var(--grayColor) !important;
  font-size: 12px !important;
}

.label-box {
  margin-bottom: 5px;
}

.v-input >>> .v-input__slot {
  background: var(--opacityGrayColor) !important;
  border: solid #dee4f0 1px !important;
  color: var(--opacityGrayColor);
  min-height: 48px !important;
  max-height: 48px !important;
}

.v-input >>> input {
  color: var(--primaryColor) !important;
  font-weight: bold;
  font-size: 14px;
}

.v-input >>> fieldset {
  color: transparent !important;
}

.v-input.no-hint >>> .v-text-field__details {
  display: none;
}

.v-input.error--text >>> .v-text-field__details {
  display: block;
}

.v-input.hint >>> .v-messages__message {
  color: var(--blackColor);
  font-size: 12px;
}

.v-input >>> .v-messages__message {
  color: var(--dangerColor);
}

.v-input.error--text >>> .v-input__slot {
  border: solid var(--dangerColor) 1px !important;
}

.v-input >>> .v-input__slot {
  background: #ffffff !important;
  border-radius: 10px;
  border: solid var(--fieldBox) 1px !important;
}

.v-input >>> i {
  color: var(--grayColor);
  font-size: 30px;
  margin-bottom: 10px;
}

.v-input >>> .v-input__prepend-inner {
  margin: 10px 5px 0 0;
}

.v-input >>> input::placeholder {
  color: var(--opacityGrayColor) !important;
  opacity: 1;
}

.v-input >>> input {
  color: var(--blackColor) !important;
}

.v-input >>> input:focus {
  color: var(--blackColor) !important;
}

.v-input >>> .v-select__selection {
  color: var(--blackColor) !important;
  font-weight: bold;
  font-size: 14px;
  width: 100%;
}

.message-box {
  padding: 10px;
  color: var(--blackColor);
  font-size: 14px;
}

.col-1 {
  max-width: 100%;
}

.col-2 {
  max-width: 100%;
}

.col-3 {
  max-width: 100%;
}

.col-4 {
  max-width: 100%;
}

.col-5 {
  max-width: 100%;
}

.col-6 {
  max-width: 100%;
}

.col-7 {
  max-width: 100%;
}

.col-8 {
  max-width: 100%;
}

.col-9 {
  max-width: 100%;
}

.col-10 {
  max-width: 100%;
}

.col-11 {
  max-width: 100%;
}

.col-12 {
  max-width: 100%;
}

.v-input.disabledField >>> .v-input__slot {
  background: var(--disabledColor) !important;
  font-style: italic;
  font-weight: bold;
}

.v-input.disabledField >>> .v-select__selection {
  color: var(--grayColor) !important;
  font-weight: bold;
}
</style>
