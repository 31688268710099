<template>
  <div class="w-100">
    <JazzCardHeader
      :groupsText="groupsText"
      @back="
        $emit(
          'back',
          $router.push({ name: 'TravelInvoicesIndex', params: params })
        )
      "
      iconName="fas fa-lg fa-building"
    />
    <v-tabs
      class="mb-5"
      background-color="transparent"
      centered
      slider-color="var(--primaryColor)"
    >
      <v-tab
        :to="{ name: 'GenerateReportTab', params: params }"
        data-cy="relatorio-tab"
      >
        Relatório
      </v-tab>
      <v-tab
        :to="{ name: 'GenerateTicketTab', params: params }"
        data-cy="boleto-tab"
      >
        Boleto
      </v-tab>
    </v-tabs>
    <div class="content-tabs">
      <transition name="fade" mode="out-in">
        <keep-alive> <router-view /></keep-alive>
      </transition>
    </div>
  </div>
</template>

<script>
import JazzCardHeader from "../../../../components/template/JazzCardHeader";
import globalMethods from "../../../../mixins/globalMethods";

export default {
  name: "ReportDetails",
  components: {
    JazzCardHeader,
  },
  mixins: [globalMethods],
  data() {
    return {
      groupsText: [],
      params: {},
      tab: 0,
    };
  },

  mounted() {
    this.params = this.$route.params;
    this.createHeaders();
  },
  methods: {
    createHeaders() {
      this.groupsText = [
        {
          label: "Responsável Financeiro",
          text: this.params.razaoSocial || "-",
          color: "primary",
        },
        {
          label: "Identificação Fiscal",
          text: this.identificationType || "-",
          color: "primary",
        },
        {
          label: "Vencimento",
          text: this.dateFormat(this.params.dataVencimento) || "--/--/----",
          color: "primary",
        },
        {
          label: "Status",
          text: this.statusText,
          color: this.statusColor || "-",
        },
      ];
    },
  },
  computed: {
    identificationType() {
      if (this.params.identificacaoFiscal?.length === 14) {
        return this.cnpjMask(this.params.identificacaoFiscal);
      }
      return this.cpfMask(this.params.identificacaoFiscal);
    },
    statusColor() {
      switch (this.params.statusLote) {
        case "Aberto":
          return "green-deep";
        case "Faturado":
          return "orange";
        case "Pago":
          return "blueColor";
        case "Atrasado":
          return "error";
        default:
          return "black";
      }
    },

    statusText() {
      switch (this.params.statusLote) {
        case "Aberto":
          return "Em aberto";
        case "Faturado":
          return "Aguardando Pagamento";
        case "Pago":
          return "Pagos";
        case "Atrasado":
          return "Vencidas";
        default:
          return "Aguardando faturamento";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.v-tab {
  font-size: 14px;
  text-transform: none;
  font-weight: normal;
  color: $blackColor !important;
  letter-spacing: 0;
}

.v-tab.v-tab--active {
  font-size: 14px;
  color: $primaryColor !important;
}
</style>
