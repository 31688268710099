<template>
  <div :title="title" data-cy="table-button" class="table-button.primary">
    <h2 v-if="text">{{ text }}</h2>
    <v-icon>
      {{ icon }}
    </v-icon>
  </div>
</template>

<script>
export default {
  name: "TableButton",
  props: ["title", "icon", "text"],
};
</script>

<style scoped>
.table-button {
  width: 30px;
  height: 30px;
  background: var(--opacityGrayColor);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 7px;
  font-size: 12px;
  margin: 0 5px;
  color: var(--blackColor);
  border: solid var(--opacityGrayColor) 1px;
}

.table-button:hover {
  cursor: pointer;
  color: var(--blackColor);
}

.table-button.primary {
  width: 48px;
  height: 48px;
  margin-bottom: 3px;
  background: var(--primaryColor);
  font-size: 20px;
  color: #ffffff;
  border: solid var(--primaryColor) 1px;
}
</style>
