<template>
  <div class="w-100">
    <JazzListHeader
      title="Histórico do Cartão"
      :total-items="this.pagination.total"
      hide-button-header
      class="history-title"
    />
    <div v-if="card.statusPedido === 'Fechado' && !loadingTable">
      <TravelCardsClientBlocking :paramsItem="params" :card="card" />
    </div>
    <JazzTable
      :loading="loadingTable"
      :pagination="pagination"
      :header="headers"
      emptyMessage="Nenhuma alteração encontrada"
      :style-header="{
        background: 'var(--opacityGrayColor)',
        width: '95%',
        marginLeft: '30px',
      }"
      :rows="listaHistorico"
      @inputNewPage="history($event)"
    >
      <template slot="reportRow" slot-scope="props">
        <TravelCardsHistoryRow :paramsItem="props.paramsItem" />
      </template>
    </JazzTable>
  </div>
</template>

<script>
import globalMethods from "../../../../../mixins/globalMethods";
import JazzListHeader from "../../../../../components/template/JazzListHeader";
import JazzTable from "../../../../../components/table/JazzTable";
import TravelCardsHistoryRow from "../rowsTable/TravelCardsHistoryRow";
import { HistoricoAlteracoesService } from "../../../../../services/apibackoffice";
import TravelCardsClientBlocking from "../rowsTable/TravelCardsClientBlocking";

export default {
  name: "HistoryTab",
  mixins: [globalMethods],
  components: {
    JazzListHeader,
    JazzTable,
    TravelCardsHistoryRow,
    TravelCardsClientBlocking
  },
  props: {
    card: Object,
  },
  data() {
    return {
      loadingTable: true,
      params: {},
      chaveDeCliente: {},
      entidadeId: [],
      listaHistorico: [],
      pagination: {
        page: 1,
        perPage: 25,
        total: 0,
        pages: 0,
      },
      headers: [
        {
          title: "Responsável",
          align: "left",
          justifyContent: "center",
        },
        {
          title: "Data e Hora",
          align: "left",
          justifyContent: "center",
        },
        {
          title: "Valor",
          align: "left",
          justifyContent: "center",
        },
      ],
    };
  },
  mounted() {
    this.params = this.$route.params;
    this.history();
  },
  methods: {
    history(page = 1) {
      this.loadingTable = true;

      HistoricoAlteracoesService.buscarPaginado({
        body: {
          entidadeId: [this.params.idPedido || this.card.idPedido],
          tipoEntidade: [1004],
          propriedades: ["Valor"],
          pagina: page,
          porPagina: this.pagination.perPage,
        },
      })
        .then((response) => {
          this.listaHistorico = response.result.items;
          this.pagination.page = response.result.pagina;
          this.pagination.perPage = response.result.qtdPorPagina;
          this.pagination.total = response.result.totalItems;
          this.pagination.pages = response.result.qtdPaginas;
          this.alterationText();
        })
        .finally(() => {
          this.loadingTable = false;
        });
    },
  },
};
</script>

<style scoped lang="scss">
.history-title {
  margin-left: 20px;
}
</style>
