<template>
  <div class="w-100">
    <JazzCardHeader
      :loading="loading"
      @back="$router.push({ name: 'TravelCardsIndex', params: params })"
      :groups-text="groupsText"
      v-on="$listeners"
      iconName="fas fa-dollar-sign"
      v-if="!internalScreen"
    />
    <div v-if="card" class="tabs-and-button">
      <v-tabs
        class="tabs-card"
        background-color="transparent"
        centered
        slider-color="var(--primaryColor)"
      >
        <v-tab
          data-cy="card-data-tab"
          :to="{ name: tabsRedirect[0], params: params }"
          >Dados do Cartão</v-tab
        >
        <v-tab
          data-cy="transactions-tab"
          :to="{ name: tabsRedirect[1], params: params }"
          >Transações
        </v-tab>
        <v-tab
          data-cy="history-tab"
          v-if="showHistoryTab()"
          :to="{ name: tabsRedirect[2], params: params }"
          >Histórico
        </v-tab>
      </v-tabs>
      <BackButton
        class="mt-5"
        v-if="internalScreen"
        title="Voltar à lista de cartões"
        @click.native="$emit('back')"
      />
    </div>
    <div :class="!internalScreen ? 'with-border' : ''">
      <div v-if="card">
        <transition name="fade" mode="out-in">
          <keep-alive>
            <router-view
              :card="
                internalScreen
                  ? { ...card, idPedido: selectedCard.idPedido }
                  : card
              "
              @reload="search()"
            />
          </keep-alive>
        </transition>
      </div>
      <JazzSkeletonLoader type="form" v-else />
    </div>
  </div>
</template>

<script>
import globalMethods from "../../../mixins/globalMethods";
import JazzCardHeader from "../../../components/template/JazzCardHeader";
import JazzSkeletonLoader from "../../../components/template/JazzSkeletonLoader";
import BackButton from "../../../components/button/BackButton";
import { PedidosCartaoService } from "../../../services/apitravel";

export default {
  name: "TravelCardsDetails",
  mixins: [globalMethods],
  components: {
    JazzCardHeader,
    JazzSkeletonLoader,
    BackButton,
  },
  props: {
    internalScreen: {
      type: Boolean,
      default: false,
    },
    tabsRedirect: {
      type: Array,
      default: () => ["CardDataTab", "TransactionsTab", "HistoryTab"],
    },
    selectedCard: Object,
  },
  data() {
    return {
      card: null,
      params: {},
      loading: false,
      groupsText: [],
    };
  },
  mounted() {
    this.params = this.$route.params;
    this.search();
  },
  methods: {
    async search() {
      this.loading = true;
      this.card = null;

      let payload = {
        chaveDePedidoCartao: this.internalScreen
          ? this.selectedCard.chaveDePedidoCartao
          : this.params.chaveDePedidoCartao,
      };

      await PedidosCartaoService.obterDados({
        body: payload,
      })
        .then((response) => {
          this.card = response.result;
          this.createHeaders();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    createHeaders() {
      this.groupsText = [
        {
          label: "Cliente Travel",
          text: this.params.nomeCliente || "-",
        },
        {
          label: "Nome Cartão",
          text: this.card.nomeImpresso || "-",
        },
        {
          label: "Data e Hora de Criação",
          text: this.dateTimeFormat(this.card.dataCriacao) || "--/--/----",
        },
      ];
    },
    showHistoryTab() {
      if (this.params?.idPedido || this.selectedCard?.idPedido) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.tabs-and-button {
  display: flex;
  padding: 0 20px 0 20px;
}

.with-border {
  border-radius: 4px;
  border: solid 1px $lightGrayBorderColor;
  background-color: $whiteColor;
  padding: 0px 15px 15px 15px;
}

.v-tabs {
  width: 100% !important;
  overflow-x: hidden;
  padding-bottom: 30px;
}

.v-tab {
  font-size: 14px;
  text-transform: none;
  font-weight: normal;
  color: $blackColor !important;
  letter-spacing: 0;
}

.v-tab.v-tab--active {
  font-size: 14px;
  color: $primaryColor !important;
}
</style>
