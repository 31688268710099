<template>
  <v-expansion-panel>
    <v-expansion-panel-header
      :data-cy="!title || dataCyHeader(title)"
      :expand-icon="icon"
    >
      <JazzText v-if="title" size="subtitle" color="primary" weight="bold">
        {{ title }}
      </JazzText>
      <slot name="header" v-else />
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <div v-show="!loading">
        <slot name="content" />
      </div>
      <JazzSkeletonLoader v-if="loading" type="form" />
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import JazzText from "../typography/JazzText.vue";
import JazzSkeletonLoader from "../template/JazzSkeletonLoader";
export default {
  name: "JazzExpansionPanel",
  components: { JazzSkeletonLoader, JazzText },
  props: {
    title: String,
    loading: Boolean,
    icon: {
      type: String,
      default: "fa-chevron-down",
    },
    showHeader: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    dataCyHeader(title) {
      return `${title
        .normalize("NFD")
        .replace(/[^a-zA-Zs]/g, "")}-expansion-panel-header`;
    },
  },
};
</script>

<style scoped>
.v-expansion-panel-content {
  padding-bottom: 15px;
}
.slot-header {
  margin: 15px 0 0 0;
  padding: 0;
}
</style>
