<template>
  <div class="w-100">
    <JazzText class="mb-5" weight="bold" size="description">
      Passo 4. Importe o arquivo no portal
    </JazzText>
    <JazzText
      size="description"
      :key="index"
      v-for="(item, index) in instructions"
    >
      {{ index + 1 }}. <span v-html="item" />
    </JazzText>
    <img
      class="w-100 mt-5"
      src="../../images/Portal.png"
      alt="Importar Arquivo"
    />
  </div>
</template>

<script>
import globalMethods from "../../../../../../mixins/globalMethods";
import JazzText from "../../../../../../components/typography/JazzText";

export default {
  name: "Step4",
  mixins: [globalMethods],
  components: { JazzText },
  data() {
    return {
      instructions: [
        "Abra o Portal e faça login com sua conta",
        "Na área logada, clique em <strong>Benefício</strong> e em <strong>Colaboradores</strong>",
        "Clique em <strong>Importar arquivo</strong>",
        "Carregue o arquivo na área indicada",
        "Aguarde o processamento e confira os resultados",
        "Se necessário, faça a correção dos colaboradores com cadastros negados.",
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
img {
  border: solid 3px $primaryColor;
  border-radius: 5px;
}
</style>
