<template>
  <div data-cy="back-button" :title="title" class="rounded-circle circle-icon">
    <v-icon color="white">
      fas fa-arrow-left
    </v-icon>
  </div>
</template>

<script>
export default {
  name: "BackButton",
  props: {
    title: String,
  },
};
</script>

<style lang="scss" scoped>
.circle-icon {
  height: 72px;
  width: 72px;
  background: $primaryColor;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin: 0 10px;
}

.fa-arrow-left {
  cursor: pointer;
  color: $whiteColor;
  font-size: 20px;
}
</style>
