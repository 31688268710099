<template>
  <div class="header" :class="{ 'title-border': borderBottom }">
    <div @click="$emit('backBreadcrumb', false)">
      <div :class="{ clickable: breadcrumb }" class="row-titles">
        <h2>{{ title }}</h2>
        <h3 class="subtitle" v-if="breadcrumb">
          <v-icon class="mx-3" size="15" color="white">
            fas fa-arrow-right
          </v-icon>
          <a>{{ breadcrumb }}</a>
        </h3>
      </div>
      <h6 v-if="totalItems && totalItems > 0" class="tag-title">
        <v-icon v-if="!hideArrow" color="white">
          fas fa-long-arrow-alt-right
        </v-icon>
        {{ totalItems }}{{ totalItems > 1 ? " resultados" : " resultado" }}
      </h6>
    </div>
    <div class="buttons-box" v-if="!hideButtonHeader">
      <CircleButton
        data-cy="filter-button"
        @click.native="
          showFiltersFields = !showFiltersFields;
          $emit('update:changeButtonFilters', showFiltersFields);
        "
        v-if="!hideFilterButton"
        :title="showFiltersFields ? 'Ocultar Filtros' : 'Exibir Filtros'"
        :featured="!showFiltersFields"
        :icon="'fas fa-filter'"
      />
      <DefaultButton
        v-if="helpRoute && !loading"
        data-cy="help-button"
        @click.native="
          $router && helpRoute.route
            ? $router.push({ name: helpRoute.route })
            : $emit('helpButton')
        "
        :title="helpRoute.title"
        :featured="true"
        :icon="helpRoute.icon"
        colorStyle="white-green ultra-radius reverse"
        :text="helpRoute.text"
        class="mr-5"
      />
      <CircleButton
        v-if="creationRoute && !loading && !buttonWithLabel"
        data-cy="creation-button"
        @click.native="
          $router && creationRoute.route
            ? $router.push({ name: creationRoute.route })
            : $emit('creationButton');
          $router && creationRoute.route
            ? $router.push({ name: creationRoute.route })
            : $emit('creationButton');
        "
        :title="creationRoute.title"
        :featured="true"
        icon="fas fa-plus"
      />
      <DefaultButton
        v-if="creationRoute && !loading && buttonWithLabel"
        data-cy="creation-label-button"
        @click.native="
          $router && creationRoute.route
            ? $router.push({ name: creationRoute.route })
            : $emit('creationButton')
        "
        :title="creationRoute.title"
        :featured="true"
        icon="fas fa-plus"
        colorStyle="ultra-radius reverse"
        :text="creationRoute.text"
      />
    </div>
    <div class="buttons-box" v-if="withBackButton">
      <CircleButton
        data-cy="back-button"
        @click.native="$router.back()"
        title="Voltar"
        :featured="true"
        :icon="'fas fa-arrow-left'"
      />
      <DefaultButton
        v-if="creationRoute && !loading && buttonWithLabel"
        data-cy="creation-label-button"
        @click.native="
          $router && creationRoute.route
            ? $router.push({ name: creationRoute.route })
            : $emit('creationButton')
        "
        :title="creationRoute.title"
        :featured="true"
        icon="fas fa-plus"
        colorStyle="ultra-radius reverse"
        :text="creationRoute.text"
      />
    </div>
    <div class="buttons-box" v-if="withBackButton">
      <CircleButton
        data-cy="back-button"
        @click.native="$router.back()"
        title="Voltar"
        :featured="true"
        :icon="'fas fa-arrow-left'"
      />
    </div>
  </div>
</template>

<script>
import CircleButton from "../button/CircleButton";
import DefaultButton from "../button/DefaultButton";

export default {
  name: "JazzListHeader",
  components: { CircleButton, DefaultButton },
  props: {
    borderBottom: {
      type: Boolean,
      default: false,
    },
    hideButtonHeader: {
      type: Boolean,
      default: false,
    },
    title: String,
    changeShowFilters: Boolean,
    totalItems: Number,
    creationRoute: {
      type: Object,
      default: undefined,
    },
    hideFilterButton: {
      type: Boolean,
      default: false,
    },
    breadcrumb: String,
    withBackButton: {
      type: Boolean,
      default: false,
    },
    hideArrow: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
    },
    buttonWithLabel: {
      type: Boolean,
      default: false,
    },
    helpRoute: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      showFiltersFields: true,
    };
  },
  watch: {
    changeShowFilters() {
      this.showFiltersFields = this.changeShowFilters;
    },
  },
};
</script>

<style scoped lang="scss">
.header {
  padding: 0 6px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  min-height: 70px;
}

h2 {
  color: $primaryColor;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
}

.title-border {
  border-bottom: thin solid #f3eef1;
}

.buttons-box {
  display: flex;
  align-items: center;
}

h6 .v-icon {
  font-size: 16px;
  margin-right: 5px;
}

h6.tag-title {
  font-size: 12px;
  color: var(--grayColor);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-left: 5px;
}

.subtitle {
  color: #aeaeae;
  font-size: 20px;

  a {
    cursor: text;
    font-weight: 600;
    font-size: 24px;
    text-decoration: underline;
    color: #9a9a9a;
  }
}

.row-titles {
  display: flex;
  align-items: center;
}

.clickable:hover {
  text-decoration: underline;
  color: $primaryColor;
  cursor: pointer;
}

/* 
/* 
.v-input.error--text >>> .v-input__slot {
  border: solid var(--dangerColor) 1px !important;
}
 */
</style>
