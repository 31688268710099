import Vue from "vue";
import Vuex from "vuex";
import templateStore from "./modules/template";
import authStore from "./modules/auth";
import treasuryAccountStore from "./modules/treasuryAccount";
import pRegistrationStore from "./modules/pRegistration";
import themeStore from "./modules/theme";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth: authStore,
    template: templateStore,
    treasuryAccount: treasuryAccountStore,
    pRegistration: pRegistrationStore,
    theme: themeStore,
  },
  state: {
    packageVersion: "1.0.0",
  },
  getters: {
    appVersion: (state) => {
      return state.packageVersion;
    },
  },
});
