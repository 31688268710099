<template>
  <v-expansion-panels accordion v-model="panel">
    <v-expansion-panel>
      <v-expansion-panel-header expand-icon="fa-chevron-down">
        <div class="expansive-header">
          <FormFieldsHeader
            v-if="title"
            data-cy="expansion-panel"
            :title="title"
          />
          <div class="slot-header" v-show="panel !== 0">
            <slot name="header" />
          </div>
        </div>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <div v-show="!loading">
          <slot name="content" />
        </div>
        <JazzSkeletonLoader v-if="loading" type="form" />
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import JazzSkeletonLoader from "./template/JazzSkeletonLoader";
import FormFieldsHeader from "./FormFieldsHeader";

export default {
  name: "JazzExpansionPanel",
  components: { JazzSkeletonLoader, FormFieldsHeader },
  data() {
    return {
      panel: undefined,
    };
  },
  props: {
    title: String,
    loading: Boolean,
  },
};
</script>

<style scoped>
.v-expansion-panel {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  background: transparent !important;
  padding-right: 8px;
}
.slot-header {
  margin: 15px 0 0 0;
  padding: 0;
}

.v-expansion-panel:before,
.v-expansion-panel::before {
  box-shadow: none;
}

.v-expansion-panel-header {
  padding: 0 !important;
  display: flex;
  width: 100%;
  align-items: flex-start;
}

.v-expansion-panel-content {
  width: 100%;
}

.v-expansion-panel >>> .v-expansion-panel-header__icon {
  margin-top: 15px;
  position: absolute;
  right: 0;
}

.v-expansion-panel-content {
  padding-right: 0 !important;
}

.v-expansion-panel-content::v-deep .v-expansion-panel-content__wrap {
  padding: 0 !important;
}
</style>
