<template>
  <div :class="styleBoxColor" class="style-box">
    <div class="size-row mb-2">
      <v-icon class="mr-2" :color="styleColor" size="20">
        fas fa-exclamation-circle</v-icon
      >
      <JazzText weight="bold" size="description" :color="styleColor">
        {{ title }}
      </JazzText>
    </div>
    <div v-if="!withSlot">
      <div v-if="simpleList">
        <div class="ml-7" v-for="(item, index) in content" :key="index">
          <JazzText :color="styleColor" weight="bold" size="description">
            {{ item }}
          </JazzText>
        </div>
      </div>
      <div v-else>
        <ul class="ml-5" v-for="(item, index) in content" :key="index">
          <li>
            <JazzText :color="styleColor" weight="bold" size="description">
              {{ item }}
            </JazzText>
          </li>
        </ul>
      </div>
    </div>
    <div v-else class="size-row">
      <JazzText size="description">
        <slot />
      </JazzText>
    </div>
  </div>
</template>

<script>
import JazzText from "../typography/JazzText";

export default {
  name: "JazzInformationTooltip",
  components: { JazzText },
  props: {
    title: { type: String, default: "Instruções" },
    content: {
      type: Array,
      default: () => [
        "Em algumas versões do Excel, o arquivo salvo em CSV pode desconfigurar. Para ajustar, abra o arquivo usando o bloco de notas e salve novamente.",
        "Limite de 10.000 linhas por arquivo ou 10mb.",
      ],
    },
    withSlot: {
      type: Boolean,
      default: false,
    },
    simpleList: {
      type: Boolean,
      default: false,
    },
    styleBoxColor: {
      type: String,
      default: "primary",
      validator: (propStyle) => ["primary", "secondary"].includes(propStyle),
    },
    styleColor: {
      type: String,
      default: "black",
      validator: (propColor) => ["black", "primary"].includes(propColor),
    },
  },
};
</script>

<style lang="scss" scoped>
.style-box.primary {
  background-color: $lightGrayBorderColor;
  border-radius: 15px;
  padding: 30px;
  color: $blackColor;
}

.style-box.secondary {
  background: $backgroundColorModal;
  border: 1px solid $primaryColor;
  border-radius: 15px;
  padding: 20px;
  color: $primaryColor
}

.size-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}
</style>
