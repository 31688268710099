<template>
  <div class="login-container">
    <LoginBox></LoginBox>
  </div>
</template>

<script>
import LoginBox from "../components/LoginBox";

export default {
  name: "Login",
  components: {
    LoginBox,
  },
  mounted() {
    if (localStorage.getItem("access_token")) {
      localStorage.removeItem("access_token");
      localStorage.clear();
      window.location.reload();
    }
  },
};
</script>

<style scoped lang="scss">
.login-container {
  width: 100vw;
  height: 100vh;
  min-height: 98vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  background: $backgroundColor;
}
</style>
