<template>
  <JazzDetailsPage
    title="Dados do colaborador"
    :loading="loading"
    :groupsText="groupsText"
    @back="
      $emit(
        'back',
        $router.push({ name: 'CollaboratorsIndex', params: params })
      )
    "
  >
    <v-tabs class="tabs-collaborator" centered v-if="collaborator">
      <v-tab
        :to="{ name: 'CollaboratorInformation', params: params }"
        data-cy="information-tab"
        >Informações</v-tab
      >
      <v-tab
        data-cy="identification-tab"
        v-if="showIdentificacaoTab"
        :to="{ name: 'CollaboratorIdentification', params: params }"
        >Identificação
      </v-tab>
      <v-tab
        data-cy="address-contact-tab"
        :to="{ name: 'CollaboratorAddress', params: params }"
        >Endereço e Contato
      </v-tab>
      <v-tab
        data-cy="accounts-tab"
        :to="{ name: 'CollaboratorAccounts', params: params }"
        >Contas vinculadas
      </v-tab>
    </v-tabs>
    <div class="content-tabs" v-if="collaborator && !loading">
      <CollaboratorBlocking
        data-cy="block-account-info"
        v-if="!isPjClient"
        :collaborator="collaborator"
      />
      <CustomerCancellation
        data-cy="cancellation-account-info"
        v-if="collaborator.detalhesCancelamento"
        :collaborator="collaborator"
      />
      <transition name="fade" mode="out-in">
        <keep-alive>
          <router-view :collaborator="collaborator" />
        </keep-alive>
      </transition>
    </div>
    <JazzSkeletonLoader type="form" v-else />
  </JazzDetailsPage>
</template>

<script>
import globalMethods from "../../../mixins/globalMethods";
import JazzDetailsPage from "../../../components/template/JazzDetailsPage";
import JazzSkeletonLoader from "../../../components/template/JazzSkeletonLoader";
import CollaboratorBlocking from "./components/CollaboratorBlocking";
import CustomerCancellation from "./components/CustomerCancellation";
import { ClientesService } from "../../../services/api";

export default {
  name: "CollaboratorShow",
  components: {
    CustomerCancellation,
    CollaboratorBlocking,
    JazzSkeletonLoader,
    JazzDetailsPage,
  },
  mixins: [globalMethods],
  data() {
    return {
      collaborator: null,
      groupsText: [],
      loading: true,
      params: null,
    };
  },
  async mounted() {
    this.params = this.$route.params;
    await this.getCollaborator();
  },
  methods: {
    async getCollaborator() {
      const { chaveDeCliente } = this.params;
      this.collaborator = await ClientesService[
        this.isPjClient
          ? "obterDadosPessoaJuridicaPorChave"
          : "obterDadosPessoaFisicaPorChave"
      ]({
        body: {
          chaveDeCliente: chaveDeCliente,
        },
      }).then((res) => res?.result);

      if (this.collaborator) {
        const { nome, organizationUnitDescricao, nomeFantasia } =
          this.collaborator;
        this.groupsText = [
          {
            label: this.isPjClient ? "Nome Fantasia" : "Nome",
            text: nome || nomeFantasia,
          },
          {
            label: this.isPjClient ? "CNPJ" : "CPF",
            text: this.identificationType,
          },
          {
            label: "Empresa",
            text: organizationUnitDescricao,
          },
        ];
      }
      this.loading = false;
    },
  },
  computed: {
    showRhTab() {
      return !this.isPjClient;
    },
    showIdentificacaoTab() {
      return !this.isPjClient;
    },
    isPjClient() {
      return this.params?.tipo === "pj";
    },
    identificationType() {
      const { identificacaoFiscal } = this.collaborator;
      if (identificacaoFiscal?.length === 14) {
        return this.cnpjMask(identificacaoFiscal);
      }
      return this.cpfMask(identificacaoFiscal);
    },
  },
};
</script>

<style lang="scss" scoped>
.content-tabs {
  padding: 30px 0 60px 0;
}

.v-tabs {
  width: 100% !important;
  overflow-x: hidden;
}

.v-tab {
  font-size: 14px;
  text-transform: none;
  font-weight: normal;
  color: $blackColor !important;
  letter-spacing: 0;
}

.v-tab.v-tab--active {
  font-size: 14px;
  color: $primaryColor !important;
}
</style>
