<template>
  <div class="w-100">
    <div class="communication-form">
      <JazzFormFields
        class="w-60"
        @update:values="
          inputsValues = $event;
          validate++;
        "
        :fields="fields"
        :validate="validate"
        @checkValidity="form = $event"
      />
      <div class="image-upload">
        <JazzFormFields
          class="w-100"
          @update:values="
            inputsValuesImage = $event;
            validate++;
          "
          :fields="fieldsImage"
          :validate="validate"
          @checkValidity="file = $event"
        />
      </div>
    </div>
    <HistoryChanges
      v-if="selectedCard"
      :idCard="selectedCard ? selectedCard.idCard : null"
    />
  </div>
</template>

<script>
import globalMethods from "../../../../mixins/globalMethods";
import intervaloHorario from "../../../../dbjson/intervaloHorario";
import JazzFormFields from "../../../../components/form/JazzFormFields";
import HistoryChanges from "./HistoryChanges";

export default {
  name: "CommunicationForm",
  components: { JazzFormFields, HistoryChanges },
  mixins: [globalMethods],
  props: {
    tesouraryAccounts: {
      type: Array,
      required: true,
    },
    selectedCard: Object,
  },
  data() {
    return {
      inputsValues: {},
      inputsValuesImage: {},
      fields: {
        partnerSelectField: {
          valueField: null,
        },
      },
      fieldsImage: {},
      validate: 0,
      file: null,
      form: {},
    };
  },
  mounted() {
    this.fields = {
      exibitionData: {
        field: "FormFieldsHeader",
        title: "Dados de Exibição",
        hideTitleBorder: true,
        color: "black",
      },
      partnerSelectField: {
        field: "JazzAutoCompleteField",
        label: "Parceiro",
        cols: 12,
        items: [
          { organizationUnitId: null, nome: "Selecione" },
          ...this.tesouraryAccounts,
        ],
        keyName: "nome",
        keyValue: "organizationUnitId",
        valueField: this.selectedCard?.organizationUnitId
          ? this.findObjectFromArray(
              this.tesouraryAccounts,
              "organizationUnitId",
              this.selectedCard?.organizationUnitId
            )
          : null,
        rules: [
          (fields) =>
            !!fields.partnerSelectField.valueField?.organizationUnitId ||
            "O campo Parceiro é obrigatório",
        ],
      },
      titleTextFieldCount: {
        field: "TextFieldCount",
        type: "text",
        label: "Título da Mensagem",
        valueField: this.selectedCard?.mensagem || "",
        maxLength: "50",
        cols: 12,
        placeholder: "Exemplo: Conheça nosso app!",
        rules: [
          (fields) =>
            !!fields.titleTextFieldCount.valueField ||
            "O campo Título é obrigatório",
          (fields) =>
            fields.titleTextFieldCount.valueField?.length <= 50 ||
            "Quantidade máxima de caracteres atingida",
        ],
        changeValue: (fields) => this.updateImageText(fields),
      },
      linkTextField: {
        field: "TextFieldCount",
        type: "text",
        label: "Link",
        valueField: this.selectedCard?.link || "",
        maxLength: "500",
        cols: 12,
        placeholder:
          "Exemplo: https://blog.suaempresa.com.br/Conheca-nosso-app",
      },
      exibitionPeriod: {
        field: "FormFieldsHeader",
        title: "Período de Exibição",
        hideTitleBorder: true,
        color: "black",
      },
      initialDateJazzDateField: {
        field: "JazzDateField",
        label: "Data Inicial",
        cols: 6,
        valueField: this.selectedCard?.inicioVigencia
          ? this.selectedCard?.inicioVigencia.split("T").shift()
          : this.dateEnFormat(),
        rules: [
          (fields) =>
            this.validateDate(fields) ||
            "Data inicial deve ser anterior ou igual a data final",
          (fields) =>
            this.compareDateAfterAndCurrent(
              fields.initialDateJazzDateField.valueField,
              this.dateEnFormat()
            ) || "Data inicial deve ser posterior ou igual a data atual",
        ],
        appendIcon: "mdi-calendar",
        changeValue: (fields) => this.filterHours(fields),
      },
      finalDateJazzDateField: {
        field: "JazzDateField",
        label: "Data Final",
        cols: 6,
        valueField: this.selectedCard?.fimVigencia
          ? this.selectedCard?.fimVigencia.split("T").shift()
          : this.dateEnFormat(),
        rules: [
          (fields) =>
            this.validateDate(fields) ||
            "Data final deve ser posterior ou igual a data inicial",
          (fields) =>
            this.compareDateAfterAndCurrent(
              fields.finalDateJazzDateField.valueField,
              this.dateEnFormat()
            ) || "Data final deve ser posterior ou igual a data atual",
        ],
        appendIcon: "mdi-calendar",
        changeValue: (fields) => this.filterHours(fields),
      },
      initialHourSelectField: {
        field: "SelectField",
        cols: 6,
        items: intervaloHorario,
        valueField: null,
        keyName: "value",
        keyValue: "key",
        rules: [
          (fields) =>
            this.validateHour(fields) ||
            "Hora inicial deve ser anterior a hora final",
        ],
      },
      finalHourSelectField: {
        field: "SelectField",
        cols: 6,
        items: intervaloHorario,
        valueField: null,
        keyName: "value",
        keyValue: "key",
        rules: [
          (fields) =>
            this.validateHour(fields) ||
            "Hora final deve ser posterior a hora inicial",
        ],
      },
    };
    this.fieldsImage = {
      imageUploadImageField: {
        field: "JazzUploadImageField",
        cols: 12,
        valueField: null,
        imageText: "",
        urlImage: this.selectedCard ? this.selectedCard.imagem : null,
        rules: [
          (fields) =>
            !!fields.imageUploadImageField.valueField ||
            "O campo Imagem é obrigatório",
        ],
      },
    };
    if (this.tesouraryAccounts?.length === 1) {
      this.fields.partnerSelectField.valueField = this.tesouraryAccounts[0];
    }
    this.filterHours(this.fields);
  },
  methods: {
    async configCommunication() {
      const {
        partnerSelectField,
        titleTextFieldCount,
        linkTextField,
        initialDateJazzDateField,
        finalDateJazzDateField,
        initialHourSelectField,
        finalHourSelectField,
      } = this.inputsValues;
      const { imageUploadImageField } = this.inputsValuesImage;
      const payload = {
        organizationUnitId: partnerSelectField?.organizationUnitId,
        mensagem: titleTextFieldCount,
        imagem: imageUploadImageField?.arquivo,
        link: linkTextField,
        inicioVigencia: `${initialDateJazzDateField}T${initialHourSelectField?.value}:00`,
        fimVigencia: `${finalDateJazzDateField}T${finalHourSelectField?.value}:59`,
      };
      if (imageUploadImageField?.arquivo && this.selectedCard?.imagem) {
        const base64 = await this.convertUrlToBase64(
          this.selectedCard.imagem + "?not-from-cache-please"
        );
        if (base64.split(",")[1] === imageUploadImageField?.arquivo?.base64) {
          payload.imagem = null;
        }
      }
      if (this.form?.isValid && imageUploadImageField?.arquivo) {
        this.$emit("enableButton", { enable: true, payload: payload });
      } else {
        this.$emit("enableButton", { enable: false, payload: {} });
      }
    },
    validateHour(fields) {
      const initialDate = fields.initialDateJazzDateField.valueField;
      const finalDate = fields.finalDateJazzDateField.valueField;
      const initialHour = fields.initialHourSelectField.valueField?.key;
      const finalHour = fields.finalHourSelectField.valueField?.key;

      if (this.isTheSameDate(initialDate, finalDate)) {
        if (this.compareHours(initialHour, finalHour, true)) {
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    },
    validateDate(fields) {
      const initialDate = fields.initialDateJazzDateField.valueField;
      const finalDate = fields.finalDateJazzDateField.valueField;
      if (
        this.isTheSameDate(initialDate, finalDate) ||
        !this.compareDateAfterAndCurrent(initialDate, finalDate)
      ) {
        return true;
      } else {
        return false;
      }
    },
    filterHours(fields) {
      var initialDate = fields.initialDateJazzDateField.valueField;
      var finalDate = fields.finalDateJazzDateField.valueField;
      var today = this.dateEnFormat();
      const nextHour = this.timeAddOneHour(true);
      const filteredArray = intervaloHorario.filter((item) =>
        this.compareHours(item.key, nextHour, true)
      );

      let { initialHourSelectField, finalHourSelectField } = this.fields;
      const firstRule = initialDate === today;
      const secondRule = finalDate === today;

      initialHourSelectField.items = firstRule
        ? filteredArray
        : intervaloHorario;
      finalHourSelectField.items = secondRule
        ? filteredArray
        : intervaloHorario;
      initialHourSelectField.valueField = firstRule
        ? filteredArray[0]
        : this.getHourObject(nextHour);
      finalHourSelectField.valueField = secondRule
        ? filteredArray[0]
        : this.getHourObject(nextHour);

      if (this.selectedCard) {
        const { inicioVigencia, fimVigencia } = this.selectedCard;
        const initialHour = this.getHourFromDate(inicioVigencia)
          .split(":")
          .shift();
        const finalHour = this.getHourFromDate(fimVigencia).split(":").shift();
        initialHourSelectField.items = intervaloHorario;
        initialHourSelectField.valueField = this.getHourObject(
          initialHour + ":00"
        );
        finalHourSelectField.valueField = this.getHourObject(finalHour + ":00");
        finalHourSelectField.items = intervaloHorario;
      }
    },
    updateImageText(fields) {
      this.fieldsImage.imageUploadImageField.imageText =
        fields?.titleTextFieldCount?.valueField;
    },
    getHourObject(hour) {
      return { key: hour, value: hour };
    },
  },
  watch: {
    file() {
      this.configCommunication();
    },
    form() {
      this.configCommunication();
    },
  },
};
</script>

<style lang="scss" scoped>
.communication-form {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.image-upload {
  width: 40%;
  margin-left: 50px;
}
</style>
