var render = function render(){var _vm=this,_c=_vm._self._c;return _c('menu',{attrs:{"data-cy":"menu"}},[_c('div',[_c('a',{staticClass:"img-box",on:{"click":function($event){return _vm.$router.push({ name: 'Dashboard' })}}},[_c('img',{attrs:{"width":"130","alt":"logo","src":_vm.imgLogo}}),_c('span',{staticClass:"version"},[_vm._v("v "+_vm._s(_vm.$store.getters.appVersion))])]),_c('div',{staticClass:"menu-scroll"},[_c('ul',[(_vm.hasAnyPermission([
          'Pages.Beneficio',
          'Pages.CadastroBeneficio',
          'Pages.Colaboradores',
          'Pages.Comunicados',
        ])
          )?_c('ItemMenu',{class:_vm.childrensActive([
  'CustomerBenefitRegistration',
  'CustomerBenefitLoad',
  'CollaboratorsIndex',
  'CommunicationIndex',
]),attrs:{"name":'Benefício',"icon":'fas fa-hand-holding-usd',"childrens":[
  {
    name: 'Cadastro',
    icon: 'fas fa-file',
    route_name: 'CustomerBenefitRegistration',
    show: _vm.hasPermission(['Pages.CadastroBeneficio']),
  },
  {
    name: 'Carga',
    icon: 'fas fa-list-alt',
    route_name: 'CustomerBenefitLoad',
    show: _vm.hasPermission(['Pages.Beneficio']),
  },
  {
    name: 'Colaboradores',
    icon: 'fas fa-users',
    route_name: 'CollaboratorsIndex',
    show: _vm.hasPermission(['Pages.Colaboradores']),
  },
  {
    name: 'Comunicados',
    icon: 'fas fa-sharp fa-suitcase',
    route_name: 'BenefitCommunicationIndex',
    show: _vm.hasPermission(['Pages.Comunicados']),
  },
]}}):_vm._e(),(_vm.hasAnyPermission(['Pages.Clientes', 'Pages.FilaDeEspera']))?_c('ItemMenu',{class:_vm.activeMenu('ClientsIndex'),attrs:{"name":'Antecipação',"icon":'fas fa-users',"childrens":[
            {
              name: 'Consulta',
              icon: 'fas fa-users',
              route_name: 'ClientsIndex',
              show: _vm.hasPermission(['Pages.Clientes']),
            },
            {
              name: 'Pré-cadastro',
              icon: 'fas fa-list-alt',
              route_name: 'PreviousRegistration',
              show: _vm.hasPermission(['Pages.Clientes']),
            },
          ]}}):_vm._e(),(_vm.hasAnyPermission([
          'Pages.TravelEfetivacaoClientes',
          'Pages.TravelCadastroClientes',
          'Pages.TravelClientes',
          'TravelCustomerRegistration',
          'Pages.TravelTransacoes',
          'Pages.TravelFaturas',
          'Pages.TravelCartoes',
        ])
          )?_c('ItemMenu',{class:_vm.childrensActive([
  'TravelClientsIndex',
  'TravelTransactionsIndex',
  'TravelCardsIndex',
  'TravelInvoicesIndex',
]),attrs:{"name":'Travel',"icon":'fas fa-plane',"childrens":[
  {
    name: 'Clientes',
    icon: 'fas fa-building',
    route_name: 'TravelClientsIndex',
    show: _vm.hasAnyPermission(['Pages.TravelClientes']),
  },
  {
    name: 'Transações',
    icon: 'fas fa-exchange-alt',
    route_name: 'TravelTransactionsIndex',
    show: _vm.hasAnyPermission(['Pages.TravelTransacoes']),
  },
  {
    name: 'Faturas',
    icon: 'fas fa-file-invoice-dollar',
    route_name: 'TravelInvoicesIndex',
    show: _vm.hasAnyPermission(['Pages.TravelFaturas']),
  },
  {
    name: 'Cartões',
    icon: 'fas fa-credit-card',
    route_name: 'TravelCardsIndex',
    show: _vm.hasAnyPermission(['Pages.TravelCartoes']),
  },
]}}):_vm._e(),_c('ItemMenu',{class:_vm.activeMenu('PasswordUpdate'),attrs:{"name":'Alterar Senha',"route_name":'PasswordUpdate',"icon":'fas fa-lock'}})],1)])]),_c('router-link',{key:_vm.$route.fullPath,staticClass:"menu-item-float",attrs:{"to":{ name: 'Logout' },"tag":"li"}},[_c('div',[_c('v-icon',[_vm._v(" fas fa-times ")]),_vm._v(" Sair ")],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }