<template>
  <v-col :cols="cols">
    <button
      :disabled="loading"
      @click="$emit('clicked')"
      :class="styleColor"
      class="button-large"
    >
      {{ text }}
    </button>
  </v-col>
</template>

<script>
export default {
  name: "ButtonLarge",
  props: ["text", "cols", "styleColor", "loading"],
};
</script>

<style scoped lang="scss">
.button-large {
  width: 100%;
  height: 48px;
  background: $primaryColor;
  color: #ffffff;
  font-weight: bold;
  border-radius: 5px;
  font-size: 14px;
  text-transform: capitalize;
}

.button-large.disabled {
  background: $lightGrayColor;
  border: $lightGrayColor;
  pointer-events: none !important;
  cursor: default !important;
}

.button-large.danger {
  background: $dangerColor;
  border: 3px solid $dangerColor;
  color: #ffffff;
}

.button-large.primary {
  background: $primaryColor !important;
  border: 3px solid $primaryColor !important;
  color: #ffffff;
}

.button-large.green {
  background: $primaryColor !important;
  border: 3px solid $primaryColor !important;
  color: #ffffff;
}
</style>
