<template>
  <div>
    <div
      @click="getContractDetails(paramsItem.numeroDoContrato)"
      class="box-row"
    >
      <div class="item-row item-status">
        <v-icon size="20" color="blue-grey darken-4"> far fa-file</v-icon>
        <JazzText
          :color="contractStatusColor(paramsItem.statusContratoAntecipacao)"
          text-align="left"
          size="label"
        >
          {{
            capitalizeFirstLetter(
              removeMaskCharactersChangeToSpace(
                paramsItem.statusContratoAntecipacao.toLowerCase() ===
                  "encerrado"
                  ? "Liquidado"
                  : paramsItem.statusContratoAntecipacao
              )
            )
          }}
        </JazzText>
      </div>
      <div class="item-row">
        <JazzText text-align="center" size="label"
          >{{ paramsItem.tipoDeContrato }}
        </JazzText>
      </div>
      <div class="item-row item-contract">
        <JazzText text-align="center" size="label"
          >{{ paramsItem.numeroDoContrato || "-" }}
        </JazzText>
      </div>
      <div class="item-row">
        <JazzText text-align="center" size="label">
          {{
            dateFormat(paramsItem.dataDeContratacao) ||
            dateFormat(paramsItem.data)
          }}
        </JazzText>
      </div>
      <div class="item-row" v-if="paramsItem.valorLiberado">
        <JazzText text-align="center" size="label">
          R$ {{ money2(paramsItem.valorContrato) }}
        </JazzText>
      </div>
      <div class="item-row">
        <JazzText text-align="center" size="label">
          {{
            paramsItem.parcelas
              ? paramsItem.parcelas + "x"
              : paramsItem.prazo + "x"
          }}
        </JazzText>
      </div>
      <div class="item-row">
        <JazzText
          v-if="paramsItem.valorParcela"
          text-align="center"
          size="label"
        >
          R$ {{ money2(paramsItem.valorParcela) }}
        </JazzText>
        <JazzText v-else text-align="center" size="label"> --</JazzText>
      </div>
    </div>
    <div v-if="showDetailsParcel" class="mb-8">
      <div class="isRenegotiation">{{ paramsItem.mensagemRenegociacao }}</div>
      <JazzTable
        :header="header"
        :rows="parcels"
        :pagination="pagination"
        :hidePagination="true"
        :emptyMessage="emptyMessage"
        :styleHeader="{
          background: 'var(--grayColor)',
          color: 'white',
          'text-align': 'center',
        }"
        :style-header-cel="{
          display: 'flex',
          padding: '5px 10px',
          'font-family': 'Roboto Black',
          'font-size': '13px',
          width: '90%',
          'justify-content': 'space-around',
        }"
      >
        <template slot="reportRow" slot-scope="props">
          <AnticipationParcelRow :paramsItem="props.paramsItem" />
        </template>
      </JazzTable>
    </div>
  </div>
</template>

<script>
import globalMethods from "../../mixins/globalMethods";
import { AntecipacaoFgtsService } from "@/services/api";
import JazzText from "../../components/typography/JazzText";
import JazzTable from "../../components/table/JazzTable";
import AnticipationParcelRow from "./AnticipationParcelRow";

export default {
  name: "AnticipationRow",
  components: { AnticipationParcelRow, JazzTable, JazzText },
  props: {
    chaveDeConta: String,
    paramsItem: Object,
    anticipation: [Number, String],
    anticipations: Array,
    filters: Object,
  },
  mixins: [globalMethods],
  data() {
    return {
      showDetailsParcel: false,
      hideDetailsParcel: false,
      pagination: {
        page: 1,
        perPage: 45,
        total: 0,
        pages: 1,
      },
      header: [
        {
          title: "Parcela",
          align: "center",
          justifyContent: "center",
        },
        {
          title: "Vencimento da Parcela",
          align: "center",
          justifyContent: "center",
        },
        {
          title: "Status da Parcela",
          align: "center",
          justifyContent: "center",
        },
        {
          title: "Data de Pagamento",
          align: "center",
          justifyContent: "center",
        },
      ],
      parcels: [],
    };
  },
  methods: {
    contractStatusColor(status) {
      switch (status.toLowerCase()) {
        case "efetivado":
          return "primary";
        case "encerrado":
          return "green";
        case "renegociado":
          return "primary";
        case "analise credito":
          return "purple";
        case "pendente analise":
          return "orange";
        case "em andamento":
          return "warning";
        case "cancelado":
          return "error";
        default:
          return "black";
      }
    },
    getContractDetails(idContract) {
      if (this.anticipation === 1) {
        this.$store.commit("CHANGE_LOADING", true, { module: "template" });
        AntecipacaoFgtsService.contratos1({
          chaveDeConta: this.chaveDeConta,
          contratoId: this.paramsItem.contrato,
        })
          .then((response) => {
            this.$store.commit("CHANGE_LOADING", false, { module: "template" });
            this.$store.commit(
              "SET_ANTECIPACOES_DETALHES",
              response.result.items
            );
            this.$emit("detailsFGTS", {
              show: true,
              response: response.result,
            });
          })
          .catch(() => {
            this.$store.commit("CHANGE_LOADING", false, { module: "template" });
          });
      } else if (this.anticipation === 2) {
        let idContrato = idContract.toString();
        let anticipations = this.anticipations;
        let filters = this.filters;
        let pagination = this.pagination;

        this.$router.push({
          name: "AccountContractDetails",
          params: { idContrato, anticipations, filters, pagination },
        });
      }
    },
  },
  computed: {
    emptyMessage() {
      return {
        renegociado: "Contratos renegociados não possuem parcelas.",
        "analise-credito":
          "Contratos em análise de crédito não possuem parcelas.",
        "pendente-analise":
          "Contratos pendentes de análise não possuem parcelas.",
        default: "Este contrato não possui parcelas.",
      }[this.paramsItem.statusContratoAntecipacao || "default"];
    },
  },
};
</script>

<style scoped lang="scss">
.box-row {
  width: 100%;
  height: 70px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-content: center;
}

.box-row:hover {
  background-color: $grayContrast;
  cursor: pointer;
}

.item-status {
  justify-content: flex-start;
}

.item-status i {
  margin-right: 5px;
}

.item-row {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 14%;
  text-align: center;
}

.isRenegotiation {
  font-weight: bold;
  font-size: 14px;
  padding-bottom: 3px;
  color: $grayColor;
}
</style>
