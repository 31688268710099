<template>
  <div class="jazz-account-balance">
    <div class="container-balance">
      <JazzText> {{ title }}</JazzText>
      <v-tooltip v-if="information" top>
        <template v-slot:activator="{ on, attrs }">
          <v-icon class="ml-2" :size="18" color="black" v-on="on" v-bind="attrs">
            fas fa-info-circle
          </v-icon>
        </template>
        <span>{{ information }}</span>
      </v-tooltip>
    </div>
    <JazzText size="big" weight="bold" :color="textColor">
      R$ {{ block ? "---" : money2(balance) }}</JazzText
    >
  </div>
</template>
<script>
import JazzText from "./typography/JazzText";
import globalMethods from "../mixins/globalMethods";
export default {
  name: "JazzAccountBalance",
  components: { JazzText },
  mixins: [globalMethods],
  props: {
    title: {
      type: String,
      default: () => "Saldo em conta",
    },
    balance: {
      type: Number || String,
      default: () => 0,
    },
    block: {
      type: Boolean,
      default: () => false,
    },
    information: String,
    textColor: {
      type: String,
      default: () => "primary",
      validator: (propColor) =>
        [
          "primary",
          "secondary",
          "error",
          "white",
          "black",
          "grey",
          "success",
        ].includes(propColor),
    },
  },
};
</script>
<style lang="scss" scoped>
.jazz-account-balance {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 10px;
}

.container-balance {
  display: flex;
  align-items: center;
}
</style>
