import { render, staticRenderFns } from "./TravelClientConfigurationTab.vue?vue&type=template&id=49b2e37b&scoped=true&"
import script from "./TravelClientConfigurationTab.vue?vue&type=script&lang=js&"
export * from "./TravelClientConfigurationTab.vue?vue&type=script&lang=js&"
import style0 from "./TravelClientConfigurationTab.vue?vue&type=style&index=0&id=49b2e37b&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "49b2e37b",
  null
  
)

export default component.exports