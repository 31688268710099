<template>
  <div
    class="collaborator-blocking"
    v-if="collaborator && collaborator.detalhesBloqueio"
  >
    <FormFieldsHeader title="Detalhes da Restrição" color="error" />
    <div
      class="row-infos"
      v-for="collaborator in collaborator.detalhesBloqueio"
      :key="collaborator.tipoBloqueio"
    >
      <div class="info-text">
        <JazzTextGroup
          label="Data da Restrição"
          :text="
            collaborator.dataBloqueio
              ? collaborator.dataBloqueio
              : '--/--/----'
          "
        ></JazzTextGroup>
      </div>
      <div class="info-text">
        <JazzTextGroup
          label="Tipo de Dado"
          :text="
            collaborator.tipoBloqueio === 1 ? 'CPF/CNPJ' : 'Número de Celular'
          "
        ></JazzTextGroup>
      </div>
      <div class="info-text">
        <JazzTextGroup
          label="Motivo da Restrição"
          :text="collaborator.motivo"
        ></JazzTextGroup>
      </div>
      <div class="info-text">
        <JazzTextGroup
          label="Usuário Restrição"
          :text="collaborator.usuarioBloqueio"
        ></JazzTextGroup>
      </div>
    </div>
  </div>
</template>

<script>
import globalMethods from "../../../../mixins/globalMethods";
import JazzTextGroup from "../../../../components/typography/JazzTextGroup";
import FormFieldsHeader from "../../../../components/FormFieldsHeader";

export default {
  name: "CollaboratorBlocking",
  components: { FormFieldsHeader, JazzTextGroup },
  mixins: [globalMethods],
  props: {
    collaborator: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped>
.row-infos {
  display: flex;
  justify-content: center;
  flex-flow: row wrap;
}

.info-text {
  margin: 0 10px;
  flex: 1;
  font-size: 14px;
}

.collaborator-blocking {
  margin-bottom: 30px;
}
</style>
