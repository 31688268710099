var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-col',{attrs:{"cols":_vm.cols}},[_c('div',{staticClass:"label-box"},[_c('label',{class:{ darkStyle: _vm.darkStyle }},[_vm._v(_vm._s(_vm.label))])]),_c('div',{staticClass:"container-input"},[_c('v-text-field',{directives:[{name:"money",rawName:"v-money",value:(_vm.maskMoney ? _vm.money : ''),expression:"maskMoney ? money : ''"}],staticClass:"text-field",class:{
        'has-rules': _vm.rules,
        withButton: _vm.withButton,
        borderSuccess: _vm.borderSuccess,
        borderDanger: _vm.borderDanger,
        darkStyle: _vm.darkStyle,
      },attrs:{"data-cy":_vm.dataCy,"autocomplete":"new-password","append-icon":_vm.appendIcon ? (_vm.showAppend ? _vm.appendIcon[0] : _vm.appendIcon[1]) : '',"disabled":_vm.disabled,"rules":_vm.rules ? _vm.rules : [],"prepend-inner-icon":_vm.prependInnerIcon,"type":_vm.type,"placeholder":_vm.placeholder,"outlined":""},on:{"input":function($event){return _vm.$emit('update:value', _vm.text)},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.$emit('onEnter', _vm.text)},"keydown":function($event){_vm.maskMoney && $event.key === '-' ? $event.preventDefault() : null}},model:{value:(_vm.text),callback:function ($$v) {_vm.text=$$v},expression:"text"}}),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('TableButton',{staticClass:"spacingButton",attrs:{"icon":"fas fa-copy"},nativeOn:{"click":function($event){return _vm.copyValue.apply(null, arguments)}}})],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.tooltipText))])])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }