<template>
  <div class="background">
    <div data-cy="confirmation-modal" class="dialog-box">
      <div data-cy="message-modal-div" class="message-text">
        <div v-if="icon">
          <v-icon :size="80" color="var(--warningColor)" class="mb-5">
            {{ icon }}
          </v-icon>
        </div>
        <JazzText
          :style-text="{ marginBottom: '20px' }"
          weight="bold"
          text-align="center"
          size="title"
        >
          {{ title }}
        </JazzText>
        <JazzText text-align="center">
          <span v-html="message" />
        </JazzText>
        <div class="mt-5" v-if="secondaryMessages">
          <JazzText
            v-for="(message, index) in secondaryMessages"
            :text-align="alignSecondaryMessages || 'left'"
            :key="index"
          >
            <span v-html="message" />
          </JazzText>
        </div>
      </div>
      <div class="buttons-box" :class="directButton">
        <button
          @click="CHANGE_CONFIRM_MODAL(false)"
          data-cy="modal-cancel-button"
          class="btn-modal cancel"
        >
          {{ cancelText }}
        </button>
        <button
          @click="CHANGE_CONFIRM_MODAL(true)"
          data-cy="modal-confirm-button"
          class="btn-modal confirm"
        >
          {{ confirmText }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import JazzText from "../typography/JazzText";

export default {
  name: "JazzConfirmationModal",
  components: { JazzText },
  props: {
    title: {
      type: String,
      required: true,
    },
    message: String,
    secondaryMessages: Array,
    alignSecondaryMessages: String,
    directButton: String,
    cancelText: {
      type: String,
      default: () => "Não",
    },
    confirmText: {
      type: String,
      default: () => "Sim",
    },
    icon: {
      type: String,
    },
  },
  methods: mapMutations(["CHANGE_CONFIRM_MODAL"]),
};
</script>

<style lang="scss" scoped>
.background {
  background-color: rgba(0, 0, 0, 0.9);
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 500;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dialog-box {
  background: $whiteColor;
  width: 400px;
  padding: 40px;
  border-radius: 20px;
  text-align: center;
}

.btn-modal {
  height: 48px;
  padding: 0 38px;
  font-weight: bold;  font-size: 18px;
  border-radius: 20px;
  margin: 0 5px;
}

.buttons-box {
  margin-top: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-modal.confirm {
  background: $successColor;
  color: $whiteColor;
  &:hover {
    background: $primaryColor;
  }
}

.btn-modal.cancel {
  background: $opacityGrayColor;
  color: $blackColor;
  &:hover {
    background: $grayContrast;
  }
}

.icon-modal {
  font-size: 4rem;
  color: $warningColor;
}

.buttons-box.line {
  display: flex;
  flex-direction: column-reverse;
  gap: 20px;
}

.buttons-box.line .btn-modal.cancel {
  background: transparent !important;
  
}
</style>
