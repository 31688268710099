<template>
  <div class="w-100">
    <div class="load-row">
      <div class="item-column-name break">
        <JazzTextGroup
          :label="paramsItem.nomeArquivo || 'lote_00001.csv'"
          :text="'Parceiro: ' + paramsItem.organizationUnitName"
          reverse
        />
      </div>
      <div class="default-items">
        <div class="item-column small">
          <JazzText weight="bold" size="description" textAlign="left">
            {{
              paramsItem.userName
                ? capitalizeFirstLetter(paramsItem.userName.toLowerCase())
                : "--"
            }}
          </JazzText>
        </div>
        <div class="item-column small">
          <JazzText weight="bold" size="description">
            {{
              !!paramsItem.dataProcessamento
                ? dateTimeFormat(paramsItem.dataProcessamento)
                : paramsItem.status
            }}
          </JazzText>
        </div>
        <div class="item-column small">
          <JazzText
            v-for="(item, index) in quantities"
            :key="index"
            weight="bold"
            size="description"
          >
            {{ item.value + " " + item.text }}
          </JazzText>
        </div>
        <div class="small status-register">
          <v-icon :size="8" :color="status.color || 'black'">
            fas fa-circle
          </v-icon>
          <JazzText
            weight="bold"
            size="description"
            textAlign="left"
            class="ml-5"
          >
            {{ `Arquivo ${status.text || "--"}` }}
          </JazzText>
        </div>
        <div class="item-column last">
          <JazzText
            data-cy="download-result"
            v-if="!disableDownloadButton"
            @click.native="
              $emit('actionButton', {
                id: paramsItem.id,
                nome: paramsItem.nomeArquivo,
                uo: paramsItem.organizationUnitId,
              })
            "
            weight="bold"
            size="description"
            textAlign="left"
            class="last-item"
            color="blueColor"
            textDecoration="underline"
          >
            Baixar Resultados
          </JazzText>
          <JazzText
            v-else
            weight="bold"
            size="description"
            textAlign="left"
            class="last-item disabled"
            color="grey"
            textDecoration="underline"
          >
            Sem arquivo de retorno
          </JazzText>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import globalMethods from "../../../../../mixins/globalMethods";
import JazzTextGroup from "../../../../../components/typography/JazzTextGroup";
import JazzText from "../../../../../components/typography/JazzText";

export default {
  name: "PreviousRegistrationRow",
  components: { JazzTextGroup, JazzText },
  mixins: [globalMethods],
  props: {
    paramsItem: {
      type: Object,
      default: undefined,
    },
  },
  computed: {
    disableDownloadButton() {
      return !(
        this.paramsItem.status === "Processado" ||
        this.paramsItem.status === "Recusado"
      );
    },
    quantities() {
      const { totalProcessados, totalRecusados, totalRegistros } =
        this.paramsItem;

      const quantities = [
        {
          value: totalRegistros || "0",
          text: `registro${totalRegistros > 1 ? "s" : ""}`,
        },
        {
          value: totalProcessados || "0",
          text: `aprovado${totalProcessados > 1 ? "s" : ""}`,
        },
        {
          value: totalRecusados || "0",
          text: `negado${totalRecusados > 1 ? "s" : ""}`,
        },
      ];
      return quantities;
    },
    status() {
      const { status } = this.paramsItem;
      const statusList = {
        Pendente: { text: "importado", color: "black" },
        Processando: { text: "em processamento", color: "orange" },
        Recusado: { text: status?.toLowerCase(), color: "error" },
        Processado: { text: status?.toLowerCase(), color: "green" },
      };
      return statusList[status] || { text: null, color: null };
    },
  },
};
</script>

<style scoped lang="scss">
.load-row {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
  align-items: start;
  flex-direction: row;
  padding: 15px 0 15px 0;
  position: relative;
}

.load-row:hover {
  background: $grayContrast;
  cursor: pointer;
}

.default-items {
  margin: 0;
  padding: 0;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
}

.item-column {
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: space-between;
}

.last {
  width: 14%;
  margin: 0 55px 0 0;
}

.last-item:hover {
  cursor: pointer;
}

.disabled:hover {
  cursor: default;
  pointer-events: none;
}

.small {
  width: 12%;
  margin: 0 15px 0 15px;
}

.item-column-name {
  width: 18%;
}

.status-register {
  margin: 4px 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.break {
  word-break: break-all;
}

@media only screen and (max-width: 768px) {
  .default-items {
    width: 100%;
    justify-content: flex-start;
    margin: 15px 0;
  }
}
</style>
