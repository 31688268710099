<template>
  <div class="w-100">
    <JazzCardHeader
      @back="$emit('voltar')"
      :groups-text="groupsText"
      v-on="$listeners"
      :iconName="'fas fa-dollar-sign'"
      v-if="!hideHeader"
    />
    <div :class="!hideHeader ? 'with-border' : ''">
      <div>
        <FormFieldsHeader title="Dados da Transação" />
        <div class="data-row">
          <JazzTextGroup
            class="transaction-data-column"
            v-for="(group, index) in transactionData"
            :key="index"
            :label="group.label"
            :text="group.text"
          />
        </div>
      </div>
      <div>
        <FormFieldsHeader title="Dados do Cliente" />
        <div class="data-row">
          <JazzTextGroup
            class="client-data-column"
            v-for="(group, index) in clientData"
            :key="index"
            :label="group.label"
            :text="group.text"
          />
        </div>
      </div>
      <div class="button-row" v-if="hideHeader">
        <DefaultButton
          data-cy="cancel-button"
          @click.native="$emit('voltar')"
          colorStyle="gray"
          class="cancel-button"
          text="Voltar"
        />
      </div>
    </div>
  </div>
</template>

<script>
import globalMethods from "../../../mixins/globalMethods";
import JazzCardHeader from "../../../components/template/JazzCardHeader";
import FormFieldsHeader from "../../../components/FormFieldsHeader";
import JazzTextGroup from "../../../components/typography/JazzTextGroup";
import DefaultButton from "../../../components/button/DefaultButton";

export default {
  name: "TransactionDetails",
  mixins: [globalMethods],
  props: {
    transaction: Object,
    hideHeader: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    JazzCardHeader,
    FormFieldsHeader,
    JazzTextGroup,
    DefaultButton,
  },
  data() {
    return {
      groupsText: [],
      transactionData: [],
      clientData: [],
    };
  },
  mounted() {
    this.groupsText = [
      {
        label: "Nome do Cliente",
        text: this.transaction.nomeImpressoCartao,
      },
      {
        label: "Cliente Travel",
        text: this.transaction.nomeCliente,
      },
      {
        label: "Data e Hora da Transação",
        text: this.dateTimeFormat(this.transaction.dataTransacao),
      },
    ];

    this.transactionData = [
      {
        label: "Valor da Transação",
        text: "R$ " + this.money2(this.transaction.valor) || "-",
      },
      {
        label: "IOF",
        text: "R$ " + this.money2(this.transaction.iof) || "-",
      },
      {
        label: "NSU",
        text: this.transaction.nsu || "-",
      },
      {
        label: "Código de Autorização",
        text: this.transaction.codigoAutorizacao || "-",
      },
      {
        label: "Nome do Estabelecimento",
        text: this.transaction.nomeEstabelecimento || "-",
      },
    ];

    this.clientData = [
      {
        label: "Nome do Cliente",
        text: this.transaction.nomeImpressoCartao || "-",
      },
      {
        label: "Número do Cartão",
        text: this.cardNumber || "-",
      },
      {
        label: "Consultor",
        text: this.transaction.consultor || "-",
      },
      {
        label: "Código da Moeda",
        text: this.transaction.codigoMoeda || "-",
      },
      {
        label: "Código MCC",
        text: this.transaction.codigoMcc || "-",
      },
      {
        label: "Descrição MCC",
        text: this.transaction.descricaoMcc || "-",
      },
      {
        label: "Cidade do Estabelecimento",
        text: this.transaction.cidadeEstabelecimento || "-",
      },
      {
        label: "País do Estabelecimento",
        text: this.transaction.paisEstabelecimento || "-",
      },
      {
        label: "Chave de Cliente",
        text: this.transaction.chaveDeCliente || "-",
      },
      {
        label: "Chave de Pedido",
        text: this.transaction.chaveDePedidoCartao || "-",
      },
    ];
  },
  computed: {
    cardNumber() {
      let number = this.transaction.numeroCartao;
      return (
        number.slice(0, 4) +
        " " +
        number.slice(4, 8) +
        " " +
        number.slice(8, 12) +
        " " +
        number.slice(12, 16)
      );
    },
  },
};
</script>

<style scoped lang="scss">
.with-border {
  border-radius: 4px;
  border: solid 1px $lightGrayBorderColor;
  background-color: $whiteColor;
  padding: 0px 15px 15px 15px;
}

.data-row {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  margin: 0 5px;
}

.transaction-data-column {
  width: 20%;
  margin-bottom: 30px;
}

.transaction-data-column:last-child {
  width: 100%;
}

.client-data-column {
  width: 20%;
  margin-bottom: 30px;
}

.button-row {
  display: flex;
  flex-direction: row-reverse;
}
</style>
