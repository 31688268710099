<template>
  <v-col :cols="cols">
    <div class="label-box">
      <label :class="{ darkStyle: darkStyle }">{{ label }}</label>
    </div>
    <div class="px-0">
      <div v-for="item in items" :key="item.id" class="radio-gaga">
        <input
          type="radio"
          id="one"
          :data-cy="dataCy"
          :value="item.id"
          v-model="value"
          @change="$emit('update:value', item.id)"
        />
        <label>{{ item.title }}</label>
      </div>
    </div>
  </v-col>
</template>

<script>
export default {
  name: "RadioButtonField",
  props: {
    dataCy: String,
    label: {},
    valueField: {},
    disabled: {
      default: false,
    },
    cols: {},
    darkStyle: {},
    items: {
      default: [],
    },
  },
  data() {
    return {
      value: this.items !== [] ? this.items[0].id : 0,
      radioGroup: 1,
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.value = this.valueField;
      this.$emit("update:value", this.value);
    });
  },

  watch: {
    valueField(value) {
      this.value = value;
      this.$emit("update:value", this.value);
    },
  },
};
</script>

<style scoped>
.title-style {
  margin-left: 5px;
}

label {
  color: var(--grayColor) !important;
  font-family: "Roboto", sans-serif;
  font-size: 12px !important;
}

label.darkStyle {
  font-size: 14px !important;
  color: #ffffff !important;
  font-family: "Roboto Light", sans-serif !important;
}

html {
  font-family: sans-serif;
}

div:first-of-type {
  display: flex;
  align-items: flex-start;
  margin-bottom: 5px;
}

label {
  margin-right: 15px;
  line-height: 32px;
}

.radio-gaga {
  display: flex;
  flex-direction: row;
  align-content: center;
}

input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  border-radius: 50%;
  width: 16px;
  height: 16px;

  border: 2px solid #999;
  transition: 0.2s all linear;
  margin-right: 5px;

  position: relative;
  top: 7px;
}

input:checked {
  border: 6px solid var(--primaryColor);
}

input {
  border: 2px solid rgba(0, 46, 55, 0.5);
}

button,
legend {
  color: white;
  background-color: black;
  padding: 5px 10px;
  border-radius: 0;
  border: 0;
  font-size: 14px;
}
</style>
