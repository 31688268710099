import * as ursa from "ursa-purejs";

export class AsymmetricCrypto {
  constructor(publicKey) {
    this.publicKey = publicKey;
    this.publicCrypto = ursa.createPublicKey(this.publicKey);
  }

  encrypt(input) {
    return this.publicCrypto
      .encrypt(input, "utf8", "base64", true)
      .toString("base64");
  }
}
