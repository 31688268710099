<template>
  <h6
    :class="[
      color,
      weight,
      textAlign,
      styleText,
      textTransform,
      textDecoration,
      size,
    ]"
    :style="styleText"
    class="jazz-text"
  >
    <slot />
  </h6>
</template>

<script>
export default {
  name: "JazzText",
  props: {
    size: {
      type: String,
      default: "description",
      validator: (propSize) =>
        [
          "giant",
          "big",
          "title",
          "subtitle",
          "description",
          "label",
          "small",
        ].includes(propSize),
    },
    color: {
      type: String,
      default: "black",
      validator: (propColor) =>
        [
          "primary",
          "secondary",
          "error",
          "white",
          "black",
          "grey",
          "success",
          "green",
          "purple",
          "orange",
          "warning",
          "blueColor",
          "green-deep",
          "dark-red",
        ].includes(propColor),
    },
    weight: {
      type: String,
      default: "normal",
      validator: (propsWeight) => ["normal", "bold"].includes(propsWeight),
    },
    textAlign: {
      type: String,
      default: "left",
      validator: (propsTextAlign) =>
        ["left", "center", "right"].includes(propsTextAlign),
    },
    textTransform: {
      type: String,
      default: "none",
      validator: (propsTextTransform) =>
        ["uppercase", "lowercase", "capitalize", "none"].includes(
          propsTextTransform
        ),
    },
    textDecoration: {
      type: String,
      default: "none",
      validator: (propsTextDecoration) =>
        ["underline", "none"].includes(propsTextDecoration),
    },
    styleText: Object,
  },
};
</script>

<style scoped lang="scss">
.jazz-text {
  margin: 0;
  padding: 0;
}

.v-application .jazz-text {
  background-color: transparent !important;
  border-color: transparent !important;
}

.jazz-text.giant {
  font-size: 100px;
}

.jazz-text.big {
  font-size: 42px;
}

.jazz-text.title {
  font-size: 24px;
}

.jazz-text.subtitle {
  font-size: 18px;
}

.jazz-text.description {
  font-size: 14px;
}

.jazz-text.label {
  font-size: 12px;
}

.jazz-text.small {
  font-size: 9px;
}

.primary {
  color: $primaryColor;
}

.secondary {
  color: $secondaryColor;
}

.error {
  color: $dangerColor;
}

.white {
  color: $whiteColor;
}

.black {
  color: $blackColor;
}

.grey {
  color: $grayColor;
}

.success {
  color: $successColor;
}

.green {
  color: $greenColor;
}

.orange {
  color: $orangeColor;
}

.warning {
  color: $warningColor;
}
.blueColor {
  color: $blueColor;
}

.dark-red {
  color: $darkRedColor;
}
.green-deep {
  color: $greenDeep;
}

.normal {
  font-weight: normal;
}

.bold {
  font-weight: bold !important;
}

.left {
  text-align: left;
}

.v-application .center,
.center {
  text-align: center !important;
}

.right {
  text-align: right;
}

.uppercase {
  text-transform: uppercase;
}

.lowercase {
  text-transform: lowercase;
}

.capitalize {
  text-transform: capitalize;
}

.none {
  text-transform: none;
}
.underline {
  text-decoration: underline;
}

.title.bold {
  font-weight: bold !important;
}
</style>
