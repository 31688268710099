<template>
  <v-col :cols="cols">
    <button class="circle-button" :title="text" :class="{ featured: featured, 'not-featured': !featured, small: small }">
      <v-icon :size="15">
        {{ icon }}
      </v-icon>
    </button>
  </v-col>
</template>

<script>
export default {
  name: "CircleButton",
  props: ["text", "icon", "featured", "cols", "small"],
};
</script>

<style scoped lang="scss">
.circle-button {
  width: 58px;
  height: 58px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
}

.featured  {
  background: $primaryColor;
  border: 1px solid $primaryColor;
  color: #ffffff;
}

.featured .v-icon {
  background: $primaryColor;
  border: 1px solid $primaryColor;
  color: #ffffff;
}

.featured:hover {
  cursor: pointer;
  background: $primaryColor;
  border: 1px solid $primaryColor;
}

.not-featured {
  background: $opacityGrayColor;
  border: 2px $opacityGrayColor;
  color: $grayColor;
}

.not-featured:hover {
  cursor: pointer;
  color: $grayColor;
}

.circle-button.small {
  width: 35px;
  height: 35px;
  font-size: 14px;
}
</style>
