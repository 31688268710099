<template>
    <div class="box-cancellation">
      <JazzText :color="'dark-red'" size="title" weight="bold">
        Detalhamento do Cancelamento
      </JazzText>
      <div class="align-cancellation-details">
        <JazzTextGroup label="Responsável" :text="valueText(cancelledList)" />
        <JazzTextGroup
          label="Data e hora da finalização"
          :text="dateTimeFormat(cancelledList[0].data)"
        />
        <JazzTextGroup
          label="Valor do cartão finalizado"
          :text="`R$ ${this.money2(card.saldo)}`"
        />
        <JazzTextGroup
          label="Tipo"
          :text="card.statusPedidoDescricao"
          :textColor="'dark-red'"
        />
      </div>
    </div>
  </template>
  
  <script>
  import globalMethods from "../../../../../mixins/globalMethods";
  import JazzText from "../../../../../components/typography/JazzText.vue";
  import JazzTextGroup from "../../../../../components/typography/JazzTextGroup.vue";
  import { HistoricoAlteracoesService } from "../../../../../services/apibackoffice";
  
  export default {
    name: "TravelCardsClientBlocking",
    mixins: [globalMethods],
    components: {
      JazzText,
      JazzTextGroup,
    },
    props: {
      card: Object,
      paramsItem: Object,
    },
    data() {
      return {
        cancelledList: [],
        pagination: {
          page: 1,
        },
      };
    },
    mounted() {
      this.historyCancel();
    },
    methods: {
      historyCancel(page = 1) {
        HistoricoAlteracoesService.buscarPaginado({
          body: {
            entidadeId: [this.paramsItem.idPedido || this.card.idPedido],
            tipoEntidade: [1004],
            propriedades: ["Valor", "StatusPedido"],
            pagina: page,
            porPagina: this.pagination.perPage,
          },
        })
          .then((response) => {
            this.cancelledList = response.result.items;
          });
      },
      valueText(text) {
        const responsavel = text[0].responsavel;
        if (responsavel === null) return "Plataforma";
        else {
          if (responsavel === "API_USER") {
            return "Usuário API";
          } else {
            return responsavel;
          }
        }
      },
    },
  };
  </script>
  
  <style scoped lang="scss">
  .box-cancellation {
    display: flex;
    padding: 0 30px 30px 30px;
    flex-direction: column;
  }
  
  .align-cancellation-details {
    display: flex;
    width: 90%;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  </style>
  