<template>
  <div class="card" id="card">
    <div class="band" />
    <div class="info-card">
      <div>
        <p>{{ cardNumber }}</p>
      </div>
      <div class="line-validity-cvv">
        <div>
          <label>Válido até</label>
          <p>{{ this.card.dataExpiracaoCartao }}</p>
        </div>
        <div>
          <label>CVV</label>
          <p>{{ this.card.cvv }}</p>
        </div>
      </div>
      <div class="line-name">
        <p>{{ this.card.nomeImpresso }}</p>
        <div id="line-button">
          <TableLabelButton
            data-cy="download-card-image-button"
            textTransform="capitalize"
            textDecoration="underline"
            label="Baixar Imagem do Cartão"
            title=""
            iconColor="primary"
            icon="fas fa-cloud-download-alt"
            @click.native="$emit('downloadButton')"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TableLabelButton from "../../../../components/button/TableLabelButton";
export default {
  name: "CustomerCard",
  props: {
    card: Object,
  },
  components: {
    TableLabelButton,
  },
  computed: {
    cardNumber() {
      let number = this.card.numeroCartao;
      return (
        number.slice(0, 4) +
        " " +
        number.slice(4, 8) +
        " " +
        number.slice(8, 12) +
        " " +
        number.slice(12, 16)
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  padding-top: 30px;
  background: $blackColor;
  border-radius: 25px;
  display: flex;
  flex-direction: column;
  width: 400px;
  height: 230px;
}

.band {
  background-color: $whiteColor;
  height: 40px;
}

.info-card {
  width: 100%;
  min-height: fit-content;
  height: 90%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 10px 15px;
}

.card label {
  font-size: 15px !important;
  color: $grayColor;
  margin: 0 10px 0 0;
  padding: 0;
}

.card p {
  font-size: 16px !important;
  color: $whiteColor;
  margin: 0;
  padding: 0;
}

.line-validity-cvv {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.line-validity-cvv div {
  display: flex;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.line-name {
  display: flex;
  justify-content: space-between;
  width: 90%;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.hide-button {
  display: none;
}

@media screen and (min-width: 800px) {
  .card {
    width: 500px;
    max-width: 550px;
    height: 280px;
    max-height: 370px;
  }
}

@media screen and (min-width: 1380px) {
  .card {
    width: 650px;
    height: 280px;
  }
}
</style>
