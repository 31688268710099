<template>
  <div>
    <JazzListHeader title="Alterar Senha de Acesso" hide-button-header />
    <div class="password-box">
      <JazzText color="grey" class="box-align">
        {{ ruleText }}
      </JazzText>
      <JazzFormFields
        :fields="fields"
        @update:values="
          inputsValues = $event;
          validate++;
        "
        :validate="validate"
        ref="form"
        @checkValidity="enableButton"
      />
      <v-col class="cleanComponent">
        <DefaultButton
          data-cy="salvar-button"
          text="Salvar"
          class="size-button"
          :class="{ disabled: this.isActive === false }"
          @click.native="updatePassword($event)"
        />
      </v-col>
    </div>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import JazzListHeader from "../../components/template/JazzListHeader";
import JazzFormFields from "../../components/form/JazzFormFields";
import DefaultButton from "../../components/button/DefaultButton";
import JazzText from "../../components/typography/JazzText";
import { UsuariosBackofficeService } from "../../services/apibackoffice";

export default {
  name: "PasswordUpdate",
  components: {
    JazzText,
    JazzFormFields,
    JazzListHeader,
    DefaultButton,
  },
  data() {
    return {
      fields: {},
      ruleText:
        "Crie uma nova senha contendo no mínimo 8 caracteres, " +
        "1 letra maiúscula, 1 letra minúscula, 1 número e 1 caractere especial ( !@#$%^&*. )" /* '[]()!?@#$%&^_+-*\/;:,.' */,
      isActive: false,
      validate: 0,
      inputsValues: {},
    };
  },
  mounted() {
    this.createForm();
  },
  methods: {
    ...mapMutations(["CHANGE_LOADING"]),
    createForm() {
      this.fields = {
        currentlyPassword: {
          field: "TextField",
          type: "password",
          placeholder: "ex.: Exemplo@321",
          label: "Senha Atual",
          valueField: "",
          cols: 12,
          rules: [
            (fields) =>
              fields.currentlyPassword.valueField.length > 0 ||
              "Campo Obrigatório",
          ],
        },
        newPassword: {
          field: "TextField",
          type: "password",
          placeholder: "ex.: Exemplo@123",
          label: "Nova Senha",
          valueField: "",
          cols: 12,
          rules: [
            (fields) =>
              fields.newPassword.valueField.length > 0 || "Campo Obrigatório",
            (fields) => this.passwordRules(fields.newPassword.valueField),
          ],
        },
        repeatNewPassword: {
          field: "TextField",
          type: "password",
          placeholder: "ex.: Exemplo@123",
          label: "Confirmar Nova Senha",
          valueField: "",
          cols: 12,
          rules: [
            (fields) =>
              fields.repeatNewPassword.valueField.length > 0 ||
              "Campo Obrigatório",
            (fields) =>
              this.validRepeatPassword(
                fields.repeatNewPassword.valueField,
                fields.newPassword.valueField
              ),
          ],
        },
      };
    },
    async updatePassword() {
      this.CHANGE_LOADING(true);

      const { currentlyPassword, newPassword, repeatNewPassword } =
        this.inputsValues;

      await UsuariosBackofficeService.alterarSenhaAcesso({
        body: {
          autenticacao2FA: {
            tipo: 5,
            token: currentlyPassword,
          },
          novaSenha: newPassword,
          confirmarNovaSenha: repeatNewPassword,
        },
      })
        .then(() => {
          this.$store.dispatch(
            "action_changeMessageSnackBar",
            "Senha alterada com sucesso!",
            { module: "template" }
          );
        })
        .catch(() => {
          this.CHANGE_LOADING(false);
        })
        .finally(() => {
          this.CHANGE_LOADING(false);
          this.createForm();
        });
    },
    passwordRules(password) {
      let error = true;

      let regex =
        /(?=.*\d)(?=.*[!@#$%^&*.]+)(?![.\n])(?=.*[A-Z])(?=.*[a-z]).{8,}/g;

      if (!regex.exec(password)) {
        error =
          "A senha deve conter no mínimo 8 caracteres, 1 letra maiúscula, 1 letra minúscula, 1 número e 1 caractere especial";
      }

      return error;
    },
    validRepeatPassword(repeatedNewPassword, newPassword) {
      return repeatedNewPassword === newPassword || "As senhas não coincidem";
    },
    enableButton(e) {
      const { currentlyPassword, newPassword, repeatNewPassword } = e.values;

      if (
        e.isValid &&
        currentlyPassword.length > 0 &&
        this.passwordRules(newPassword) === true &&
        this.validRepeatPassword(newPassword, repeatNewPassword) === true
      ) {
        this.isActive = true;
      } else {
        this.isActive = false;
      }
    },
  },
};
</script>

<style scoped>
.password-box {
  padding-top: 10px;
  width: 100%;
}

.box-align {
  padding: 6px;
  margin-bottom: 10px;
}

.sucess-box {
  padding: 6px;
  margin-top: 30px;
}

.cleanComponent {
  margin: 0 !important;
  padding: 6px !important;
}

.size-button {
  width: 100%;
}
</style>
