export const themeStore = {
  state: () => ({
    currentTheme: "stuo",
  }),
  mutations: {
    SET_THEME(state, payload) {
      switch (true) {
        case payload.includes("TRAVEL"):
          state.currentTheme = "stuo-travel";
          break;
        case payload.includes("ANTECIPACAO"):
          state.currentTheme = "stuo-antecipacao";
          break;
        case payload.includes("BENEFICIO"):
          state.currentTheme = "stuo-beneficio";
          break;
        default:
          state.currentTheme = "stuo";
      }
    },
  },
  getters: {
    imgLogo(state) {
      return require(`../../themes/${state.currentTheme}/logo.png`);
    },
  },
};

export default themeStore;
