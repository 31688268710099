<template>
  <div class="w-100">
    <DefaultCard>
      <DefaultLoading v-if="loadingPdfPreview" />
      <div data-cy="pdf-preview" class="preview" v-else>
        <JazzPdfPreview v-if="pdf2Base64" :base64="pdf2Base64" hide-toolbar />
        <JazzNotFoundMessage
          message="Boleto não emitido"
          v-else
          textColor="black"
        />
      </div>
      <div class="button-row">
        <DefaultButton
          data-cy="download-button"
          text="Download"
          @click.native="downloadTicket"
          :class="{ disabled: isActive === false }"
        />
      </div>
    </DefaultCard>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import globalMethods from "../../../../../mixins/globalMethods";
import DefaultCard from "../../../../../components/cards/DefaultCard";
import DefaultButton from "../../../../../components/button/DefaultButton";
import DefaultLoading from "../../../../../components/template/DefaultLoading";
import JazzPdfPreview from "../../../../../components/template/JazzPdfPreview";
import JazzNotFoundMessage from "../../../../../components/typography/JazzNotFoundMessage";
import { FaturamentosService } from "../../../../../services/apitravel";

export default {
  name: "GenerateTicketTab",
  components: {
    DefaultCard,
    DefaultButton,
    DefaultLoading,
    JazzPdfPreview,
    JazzNotFoundMessage,
  },
  mixins: [globalMethods],
  data() {
    return {
      loadingPdfPreview: true,
      pdf2Base64: "",
      isActive: false,
    };
  },
  mounted() {
    this.params = this.$route.params;

    this.convertBase64ToPdf();
  },
  methods: {
    ...mapMutations(["CHANGE_LOADING"]),
    convertBase64ToPdf() {
      this.loadingPdfPreview = true;
      FaturamentosService.obterBoletoLoteFaturado({
        body: {
          chaveDeLote: this.params.chaveDeLote,
          gerarPdfBoleto: true,
        },
      })
        .then((response) => {
          if (response) {
            this.pdf2Base64 =
              "data:application/pdf;base64," + response?.result?.boleto?.base64;
          }
          this.isActive = true;
        })
        .catch(() => {
          this.isActive = false;
        })
        .finally(() => {
          this.loadingPdfPreview = false;
        });
    },
    downloadTicket() {
      if (this.pdf2Base64) {
        this.CHANGE_LOADING(true);
        this.downloadBase64(this.pdf2Base64, "BOLETO");
        this.$store.dispatch(
          "action_changeMessageSnackBar",
          `Download realizado com sucesso!`,
          { module: "template" }
        );
        this.CHANGE_LOADING(false);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.button-row {
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
  padding: 15px;
  border-top: thin solid $grayContrast;
}

.preview {
  width: 100%;
  display: flex;
  justify-content: space-around;
  padding: 15px 0 15px 0;
}
</style>
