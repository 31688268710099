<template>
  <div class="invoices-row">
    <JazzTextGroup
      class="first-column"
      label="Data de Emissão do boleto"
      :text="
        paramsItem.dataEmissao
          ? this.dateFormat(paramsItem.dataEmissao)
          : '--/--/----'
      "
    />

    <JazzTextGroup
      class="small"
      label="Período do Lote"
      :text="`${
        paramsItem.dataInicio
          ? this.dateFormat(paramsItem.dataInicio)
          : '--/--/----'
      } a 
        ${
          paramsItem.dataFim
            ? this.dateFormat(paramsItem.dataFim)
            : '--/--/----'
        }`"
    />

    <JazzTextGroup
      class="small"
      label="Vencimento"
      :text="
        paramsItem.dataVencimento
          ? this.dateFormat(paramsItem.dataVencimento)
          : '--/--/----'
      "
    />
    <JazzTextGroup
      class="small"
      label="Status"
      :text="statusText"
      :textColor="statusColor"
    />
  </div>
</template>

<script>
import JazzTextGroup from "../../../../../components/typography/JazzTextGroup";
import globalMethods from "../../../../../mixins/globalMethods";
export default {
  name: "InvoicesRow",
  mixins: [globalMethods],
  props: {
    paramsItem: Object,
  },
  components: {
    JazzTextGroup,
  },
  computed: {
    statusColor() {
      const statusColorList = {
        Aberto: "green-deep",
        Faturado: "orange",
        Pago: "blueColor",
        Atrasado: "error",
      };

      return statusColorList[this.paramsItem.statusLote] || "black";
    },
    statusText() {
      const statusTextList = {
        Aberto: "Em aberto",
        Faturado: "Aguardando Pagamento",
        Pago: "Pagos",
        Atrasado: "Vencidas",
      };

      return (
        statusTextList[this.paramsItem.statusLote] || "Aguardando faturamento"
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.invoices-row {
  width: 100%;
  padding: 10px 15px 10px 15px;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  align-content: stretch;
  background-color: $whiteColor;
  @media only screen and (max-width: 1160px) {
    padding: 10px;
  }
  margin-bottom: 5px;
}

.invoices-row:hover {
  background-color: $grayContrast;
  cursor: pointer;
}

.invoices-row i {
  font-size: 24px;
  color: $primaryColor;
  margin-right: 20px;
  width: 30px;
  text-align: center;
}

.first-column {
  width: 280px;
  @media only screen and (min-width: 1920px) {
    width: 250px;
  }
}

.small {
  width: 180px;
  @media only screen and (min-width: 1920px) {
    width: 280px;
  }
}
</style>
