<template>
  <v-col :cols="parseInt(cols)">
    <JazzText class="mb-5 mt-5" weight="bold" size="subtitle">
      {{ label }}
    </JazzText>
    <v-input
      class="image-field"
      :rules="rules && !url ? rules : []"
      v-model="uploadedFile"
    >
      <label
        @drop.prevent="onDrop($event)"
        @dragover.prevent="dragover = true"
        @dragenter.prevent="dragover = true"
        @dragleave.prevent="dragover = false"
        class="marked"
        for="uploadedFile"
        name="uploadedFile"
        value="uploadedFile"
        :data-cy="dataCy != 'undefined-input' ? dataCy : 'upload-image-input'"
      >
        <div v-if="!url && !loadingImage" class="box">
          <v-icon
            :color="`var(--grayColor)`"
            size="60"
            :class="[dragover ? 'mt-2, mb-6' : 'mt-5']"
          >
            fas fa-camera
          </v-icon>
          <JazzText
            weight="bold"
            size="subtitle"
            color="grey"
            textAlign="center"
          >
            Arraste uma imagem aqui ou clique para selecioná-la
          </JazzText>
          <input
            name="uploadedFile"
            value="uploadedFile"
            id="uploadedFile"
            type="file"
            @change="toBase64($event)"
            accept="image/png, image/jpg, image/jpeg"
          />
        </div>
      </label>
      <div class="box" v-if="url">
        <DefaultLoading v-if="loadingImage" />
        <div class="preview-content" v-else>
          <JazzImagePreview :url="url" :imageText="imageText" size="medium" />
          <TableLabelButton
            class="mt-5"
            @click.native="cleanSelection()"
            icon="fas fa-times"
            label="Limpar Seleção"
            buttonColor="primary"
            iconColor="white"
            size="small"
            weight="bold"
            textTransform="capitalize"
          />
        </div>
      </div>
    </v-input>
    <JazzRecommendedSize :height="height" :width="width" :title="title" />
  </v-col>
</template>

<script>
import globalMethods from "../../mixins/globalMethods";
import JazzText from "../typography/JazzText";
import TableLabelButton from "../../components/button/TableLabelButton";
import DefaultLoading from "../../components/template/DefaultLoading";
import JazzImagePreview from "../template/JazzImagePreview";
import JazzRecommendedSize from "../tooltip/JazzRecommendedSize";

export default {
  name: "JazzUploadImageField",
  mixins: [globalMethods],
  components: {
    JazzText,
    TableLabelButton,
    DefaultLoading,
    JazzImagePreview,
    JazzRecommendedSize,
  },
  props: {
    label: { type: String, default: "Carregar Imagem (JPG, PNG)" },
    dataCy: String,
    height: { type: String, default: "150px" },
    width: { type: String, default: "150px" },
    title: String,
    imageText: String,
    urlImage: String,
    rules: Array,
    cols: Number,
  },
  data() {
    return {
      uploadedFile: null,
      url: null,
      dragover: false,
      loadingImage: false,
      showBox: false,
    };
  },
  async mounted() {
    this.url = this.urlImage;
    if (this.urlImage) {
      this.loadingImage = true;
      const base64 = await this.convertUrlToBase64(
        this.url + "?not-from-cache-please"
      );
      this.uploadedFile = {
        tamanho: new Buffer(this.removeHeaderBase64(base64), "base64").length,
        nomeArquivo: null,
        arquivo: {
          base64: this.removeHeaderBase64(base64),
          extensao: this.convertFileExtension(this.url.split(".").pop()),
          url: null,
        },
      };
      this.loadingImage = false;
    }
  },
  methods: {
    removeHeaderBase64(base64) {
      let res = base64.split(",");
      return res[1];
    },
    async fileBase64(file) {
      return await new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    async toBase64(e) {
      const file = e.target.files[0];

      if (this.validExtensions(file)) {
        this.url = URL.createObjectURL(file);
        this.uploadedFile = {
          tamanho: file.size,
          nomeArquivo: file.name,
          arquivo: {
            base64: this.removeHeaderBase64(await this.fileBase64(file)),
            extensao: this.convertFileExtension(file.name.split(".").pop()),
            url: null,
          },
        };
      } else {
        this.openMessageModal("danger", "Extensão do arquivo inválida");
      }
    },
    validExtensions(file) {
      if (file === undefined || file === null) return null;
      let type = file.name.split(".").pop();
      return type === "jpg" || type === "png" || type === "jpeg";
    },
    cleanSelection() {
      this.uploadedFile = null;
      this.url = null;
    },
    async onDrop(e) {
      this.dragover = false;
      if (this.uploadedFile) this.uploadedFile = {};
      if (e.dataTransfer.files.length > 1) {
        this.openMessageModal(
          "danger",
          "Atenção",
          "Somente um arquivo por vez"
        );
      } else {
        const file = e.dataTransfer.files[0];
        if (this.validExtensions(file)) {
          this.url = URL.createObjectURL(file);
          this.uploadedFile = {
            tamanho: file.size,
            nomeArquivo: file.name,
            arquivo: {
              base64: this.removeHeaderBase64(await this.fileBase64(file)),
              extensao: this.convertFileExtension(file.name.split(".").pop()),
              url: null,
            },
          };
          this.$emit("update:value", this.uploadedFile);
        } else {
          this.openMessageModal("danger", "Extensão do arquivo inválida");
        }
      }
    },
  },
  watch: {
    valueField: {
      immediate: true,
      handler(uploadedFile) {
        this.uploadedFile = uploadedFile;
      },
    },
    uploadedFile: {
      immediate: true,
      handler() {
        this.$emit("update:value", this.uploadedFile);
      },
    },
  },
};
</script>

<style scoped lang="scss">
.image-field {
  width: 100%;
  border-radius: 15px;
  display: flex;
  justify-content: space-around;
}

input[type="file"] {
  display: none;
}

.marked {
  cursor: pointer;
}

.box {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding: 50px;
  max-height: auto;
  width: 100%;
}

.v-input ::v-deep .v-input__slot {
  width: 100%;
  min-height: 250px;
  max-height: 350px;
  border: dashed 1px $lightGrayColor;
  border-radius: 15px;
  justify-content: space-around;
}

.v-input.error--text ::v-deep .v-input__slot {
  border-radius: 15px;
  border: dashed 2px $dangerColor;
}
</style>
