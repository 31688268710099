<template>
  <div>
    <JazzFormFields v-if="rh && !loading" disabled :fields="fields" />
    <JazzNotFoundMessage
      message="Usuário não possui cadastro na Onidata, não é possivel editar dados."
      v-if="!rh && !loading"
    />
    <JazzSkeletonLoader v-if="loading" type="form" />
    <div v-else-if="rh !== null" class="button-area">
      <DefaultButton
        @click.native="envioDadosPrimeiroAcesso"
        text="Reenviar Dados Primeiro Acesso"
        class="button-first-access"
      />
    </div>
  </div>
</template>

<script>
import JazzFormFields from "../../../components/form/JazzFormFields";
import { AntecipacaoSalarialBackofficeService } from "../../../services/apibackoffice";
import JazzSkeletonLoader from "../../../components/template/JazzSkeletonLoader";
import globalMethods from "../../../mixins/globalMethods";
import JazzNotFoundMessage from "../../../components/typography/JazzNotFoundMessage";
import DefaultButton from "../../../components/button/DefaultButton";

export default {
  name: "ClientRH",
  components: {
    JazzNotFoundMessage,
    JazzSkeletonLoader,
    JazzFormFields,
    DefaultButton,
  },
  mixins: [globalMethods],
  data() {
    return {
      fields: {},
      rh: null,
      loading: true,
      editFields: false,
    };
  },
  mounted() {
    this.createFields();
  },
  methods: {
    async createFields() {
      const { chaveDeCliente } = this.$route.params;
      this.rh =
        await AntecipacaoSalarialBackofficeService.obterDadosClienteOnidata({
          body: {
            chaveDeCliente: chaveDeCliente,
          },
        })
          .then((res) => res?.result)
          .finally(() => (this.loading = false));
      if (this.rh) {
        this.fields = {
          title: {
            title: "Dados RH",
            field: "FormFieldsHeader",
          },
          matricula: {
            field: "TextFieldMask",
            type: "text",
            label: "Matrícula",
            cols: 6,
            maskFormat: "#################################",
            valueField: this.rh?.matricula,
            rules: [
              (fields) =>
                !!fields.matricula.valueField ||
                "É necessário inserir um valor de matrícula.",
            ],
          },
          cargo: {
            field: "TextField",
            type: "text",
            label: "Cargo",
            cols: 6,
            valueField: this.rh?.cargo,
            rules: [
              (fields) =>
                !!fields.cargo.valueField ||
                "É necessário inserir um valor de cargo.",
            ],
          },
          admissaoData: {
            field: "TextFieldMask",
            label: "Admitido em",
            cols: 4,
            valueField: this.rh?.admissaoData
              ? this.dateFormat(this.rh?.admissaoData)
              : "",
            maskFormat: "##/##/####",
          },
          genero: {
            field: "SelectField",
            type: "text",
            label: "Sexo",
            items: [
              { name: "Não informado", value: 0 },
              { name: "Masculino", value: "M" },
              { name: "Feminino", value: "F" },
            ],
            cols: 4,
            valueField: this.rh?.genero,
            rules: [
              (fields) =>
                fields.genero.valueField.value !== 0 ||
                "É necessário inserir um gênero.",
            ],
          },
          estadoCivil: {
            field: "SelectField",
            label: "Estado Civil",
            items: [
              { name: "Não informado", value: 0 },
              { name: "Solteiro (a)", value: "solteiro" },
              { name: "Casado (a)", value: "casado" },
              { name: "União Estável", value: "uniaoestavel" },
              { name: "Divorciado (a)", value: "divorciado" },
              { name: "Viúvo (a)", value: "viuvo" },
            ],
            cols: 4,
            valueField: this.rh?.estadoCivil,
            rules: [
              (fields) =>
                fields.estadoCivil.valueField.value !== 0 ||
                "É necessário inserir um estado civil.",
            ],
          },
        };
      }
    },
    envioDadosPrimeiroAcesso() {
      this.loading = true;
      const { chaveDeCliente } = this.$route.params;
      AntecipacaoSalarialBackofficeService.ajustarDadosPrimeiroAcesso({
        body: {
          chaveDeCliente: chaveDeCliente,
        },
      })
        .then(() => {
          this.loading = false;
          this.$store.dispatch(
            "action_changeMessageSnackBar",
            "Dados do Primeiro Acesso enviados com sucesso!",
            { module: "template" }
          );
        })
        .catch(() => {
          this.loadingDetails = false;
        });
    },
  },
};
</script>

<style scoped lang="scss">
.button-area {
  display: flex;
  width: 100%;
  justify-content: end;
}

.button-first-access {
  display: flex;
  margin-top: 1em;
}
</style>
