<template>
  <div>
    <JazzSkeletonLoader v-if="loading" type="form" />
    <JazzSkeletonLoader v-if="loading" type="table" />
    <JazzArrowTab
      :tabs="tabs"
      @arrowClick="overallAnticipation"
      v-if="!loading"
    >
      <template #OverallData>
        <AccountContractOverallData :details="detailsTabData" />
      </template>
      <template #Parcels>
        <AccountContractParcels :parcels="parcelsTabData" />
      </template>
    </JazzArrowTab>
  </div>
</template>

<script>
import JazzArrowTab from "@/components/template/JazzArrowTab";
import AccountContractParcels from "@/views/Client/Client/Account/CreditAccount/AccountContractParcels";
import AccountContractOverallData from "@/views/Client/Client/Account/CreditAccount/AccountContractOverallData";
import { AntecipacaoSalarialBackofficeService } from "@/services/apibackoffice.js";
import JazzSkeletonLoader from "@/components/template/JazzSkeletonLoader";

export default {
  components: {
    JazzSkeletonLoader,
    AccountContractOverallData,
    AccountContractParcels,
    JazzArrowTab,
  },
  data() {
    return {
      idContrato: this.params?.idContrato || this.$route.params.idContrato,
      chaveDeConta:
        this.params?.chaveDeConta || this.$route.params?.chaveDeConta,
      tabs: [
        { tabName: "Dados Gerais", template: "OverallData" },
        { tabName: "Parcelas", template: "Parcels" },
      ],
      detailsTabData: {},
      parcelsTabData: [],
      loading: true,
    };
  },
  methods: {
    overallAnticipation() {
      let anticipations = this.$route.params.anticipations;
      let filters = this.$route.params.filters;
      let pagination = this.$route.params.pagination;
      this.$router
        .push({
          name: "CreditAccount",
          params: { filters, anticipations, pagination },
        })
        .catch(() => this.$forceUpdate());
    },
    async getContractDetails() {
      let payload = {
        localizador: this.idContrato,
        chaveDeConta: this.chaveDeConta,
      };
      await AntecipacaoSalarialBackofficeService.contratos1({
        ...payload,
      })
        .then((res) => {
          let data = res.result;
          this.parcelsTabData = data.parcelasContrato;
          this.detailsTabData = {
            ...data,
            valorParcela: data.parcelasContrato[0].valorParcela,
            dataDeTermino:
              this.parcelsTabData[data.parcelas - 1].dataDeVencimento,
            modalidade: data.modalidade.toString().replace("-", " "),
          };
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  mounted() {
    this.getContractDetails();
  },
};
</script>

<style lang="scss" scoped>
.edit-arrow {
  color: $primaryColor;
}
</style>
