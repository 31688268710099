<template>
  <div class="w-100">
    <JazzListHeader title="Geração de Cartão Virtual" hide-button-header />
    <JazzSkeletonLoader v-if="mainloading" type="form" />
    <div v-else>
      <JazzFormFields
        :fields="fieldsCards"
        @update:values="
          inputsValues = $event;
          validate++;
        "
        :validate="validate"
        @checkValidity="enableButton"
      />
      <div class="btn">
        <DefaultButton
          data-cy="generation-card-button"
          :class="{ disabled: this.isActive === false }"
          @click.native="generatedCardVirtual"
          text="Gerar Cartão"
        />
        <DefaultButton
          data-cy="cancel-button"
          colorStyle="gray"
          @click.native="cancel()"
          text="Cancelar"
        />
      </div>
      <DefaultLoading v-if="loadingDetails" />
    </div>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import globalMethods from "../../../mixins/globalMethods";
import JazzFormFields from "../../../components/form/JazzFormFields";
import JazzListHeader from "../../../components/template/JazzListHeader";
import DefaultButton from "../../../components/button/DefaultButton";
import JazzSkeletonLoader from "../../../components/template/JazzSkeletonLoader";
import {
  ClientesTravelService,
  PedidosCartaoService,
} from "../../../services/apitravel";

export default {
  name: "TravelGenerationCardsForm",
  components: {
    JazzFormFields,
    JazzListHeader,
    DefaultButton,
    JazzSkeletonLoader,
  },
  mixins: [globalMethods],
  data() {
    return {
      fieldsCards: {},
      clientsFilter: [],
      inputsValues: {},
      isActive: false,
      loadingDetails: false,
      mainloading: false,
      validate: 0,
    };
  },

  mounted() {
    this.mainloading = true;
    ClientesTravelService.consultarDisponiveis()
      .then((response) => {
        this.clientsFilter = response.result.clientes;
        this.createForm();
      })
      .finally(() => {
        this.mainloading = false;
      });
  },
  methods: {
    ...mapMutations(["CHANGE_LOADING"]),
    createForm() {
      this.fieldsCards = {
        cliente: {
          field: "SelectField",
          label: "Cliente",
          cols: 6,
          valueField: "",
          items: [
            { chaveDeCliente: null, razaoSocial: "Selecione" },
            ...this.clientsFilter,
          ],
          keyName: "razaoSocial",
          keyValue: "chaveDeCliente",
          rules: [
            (fields) =>
              !!fields.cliente.valueField.chaveDeCliente ||
              "Campo Cliente é obrigatório",
          ],
        },
        senha: {
          field: "TextField",
          label: "Senha",
          type: "password",
          cols: 6,
          valueField: "",
          maxLength: "20",
          rules: [
            (fields) =>
              !!fields.senha.valueField || "Campo Senha é obrigatório",
            (fields) =>
              fields.senha.valueField.length <
                parseInt(fields.senha.maxLength) ||
              "Quantidade máxima de caracteres atingida",
          ],
        },
        nomeCartao: {
          field: "TextField",
          label: "Nome Cartão",
          cols: 6,
          valueField: "",
          helpText: "Nome apresentado no cartão",
        },
        valor: {
          field: "TextField",
          label: "Valor",
          maskMoney: true,
          cols: 6,
          valueField: "",
          rules: [
            (fields) =>
              this.removeMaskMoney(fields.valor.valueField) > 0 ||
              "Campo Valor é obrigatório",
          ],
        },
        dataInicio: {
          field: "JazzDateField",
          label: "Data Início",
          cols: 6,
          valueField: this.dateEnFormat(),
          rules: [
            (fields) =>
              !this.compareDateAfterAndCurrent(
                fields.dataInicio.valueField,
                fields.dataFim.valueField
              ) || "Data inicial deve ser menor que data final",
          ],
        },
        dataFim: {
          field: "JazzDateField",
          label: "Data Fim",
          cols: 6,
          valueField: this.dateAddOneDay(),
          rules: [
            (fields) =>
              !this.compareDateAfterAndCurrent(
                fields.dataInicio.valueField,
                fields.dataFim.valueField
              ) || "Data final deve ser maior que data inicial",
          ],
        },
        descricaoPedido: {
          field: "TextAreaField",
          label: "Descrição do Pedido",
          cols: 12,
          valueField: "",
        },
      };
      if (this.clientsFilter.length === 1) {
        this.fieldsCards.cliente.valueField = this.clientsFilter[0];
      }
    },
    generatedCardVirtual() {
      this.CHANGE_LOADING(true);

      const {
        cliente,
        valor,
        dataInicio,
        dataFim,
        senha,
        nomeCartao,
        descricaoPedido,
      } = this.inputsValues;

      let payload = {
        chaveDeCliente: cliente?.chaveDeCliente,
        valor: this.maskInputMoney(this.removeMaskMoney(valor)),
        identificadorExterno: this.generateGuid(),
        inicioVigencia: this.dateISOFormat(
          dataInicio + " 00:00:00 +0000",
          "YYYY-MM-DD HH:mm:ss Z"
        ),
        fimVigencia: this.dateISOFormat(
          dataFim + " 23:59:59 +0000",
          "YYYY-MM-DD HH:mm:ss Z"
        ),
        senha: senha,
        nomeImpresso: nomeCartao,
        descricao: descricaoPedido,
      };

      PedidosCartaoService.cadastrar({
        body: payload,
      })
        .then((response) => {
          this.CHANGE_LOADING(false);
          this.$store.dispatch(
            "action_changeMessageSnackBar",
            `Cartão gerado com sucesso!`,
            { module: "template" }
          );
          this.$router.push({
            name: "TravelCardsDetails",
            params: {
              chaveDePedidoCartao: response.result.chaveDePedidoCartao,
              nomeCliente: this.inputsValues.cliente.razaoSocial,
            },
          });
        })
        .catch(() => {
          this.CHANGE_LOADING(false);
        });
    },
    cancel() {
      this.$router.back();
    },
    enableButton(e) {
      if (e.isValid) {
        this.isActive = true;
      } else {
        this.isActive = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.btn {
  display: flex;
  flex-direction: row-reverse;
  margin: 20px 0px 20px 0px;
}

.btn > button {
  margin-right: 20px;
}
</style>
