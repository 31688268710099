<template>
  <v-col :cols="cols">
    <div class="label-box">
      <label>{{ label }}</label>
    </div>
    <v-file-input
      prepend-icon=""
      outlined
      ref="inputFile"
      :rules="rules"
      @change="toBase64"
      :key="resetInput"
      accept=".csv"
      :data-cy="dataCy"
    >
      <template #append-outer>
        <div class="botao-agregado">
          <DefaultButton
            text="Selecionar arquivo"
            @click.native="inputClick()"
          />
        </div>
      </template>
    </v-file-input>
  </v-col>
</template>

<script>
import globalMethods from "../../mixins/globalMethods";
import DefaultButton from "../button/DefaultButton";

export default {
  name: "SimpleFileUpload",
  components: { DefaultButton },
  mixins: [globalMethods],
  props: {
    dataCy: String,
    cols: {
      default: 12,
    },
    label: {
      default: "InputFile",
    },
    rules: {
      default: () => [],
    },
  },
  data() {
    return {
      resetInput: 0,
    };
  },
  methods: {
    inputClick() {
      this.$refs.inputFile.$refs.input.click();
    },
    async fileBase64(file) {
      return await new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    extesaoValida(value) {
      if (value === undefined || value === null) return null;
      let type = value.name.split(".").pop();
      return type === "csv";
    },
    async toBase64(value) {
      let file = null;
      if (this.isFormatSizeValid(value)) {
        file = {
          tamanho: value.size,
          nomeArquivo: value.name,
          arquivo: {
            base64: this.removeHeaderBase64(await this.fileBase64(value)),
            extensao: 7, //csv
            url: null,
          },
        };
      }
      this.$emit("update:value", file);
    },
    removeHeaderBase64(base64) {
      let res = base64.split(",");
      return res[1];
    },
    isFormatSizeValid(file) {
      let res = true;
      let msg = "";
      const tamMax = 10000000;
      if (!this.extesaoValida(file)) {
        msg += "Extensão do arquivo não permitida, arquivo removido.";
        res = false;
      }

      if (!(file.size <= tamMax)) {
        msg += "Arquivo maior do que o permitido, arquivo removido.";
        res = false;
      }
      if (msg !== "") {
        this.openMessageModal("danger", "Arquivo inválido", msg);
      }
      if (!res) {
        this.resetInput++;
      }
      return res;
    },
  },
};
</script>

<style scoped>
label {
  color: var(--grayColor) !important;
  font-family: "Roboto", sans-serif;
  font-size: 12px !important;
}

.botao-agregado {
  margin-top: -18px;
}

.label-box {
  margin-bottom: 5px;
}

.v-input >>> .v-input__slot {
  background: var(--opacityGrayColor) !important;
  border: solid #dee4f0 1px !important;
  color: var(--opacityGrayColor);
  min-height: 48px !important;
}

.v-input >>> input {
  color: var(--primaryColor) !important;
  font-family: "Roboto Medium", sans-serif;
  font-size: 14px;
}

.v-input >>> fieldset {
  color: transparent !important;
}

.v-input.no-hint >>> .v-text-field__details {
  display: none;
}

.v-input.error--text >>> .v-text-field__details {
  display: block;
}

.v-input.hint >>> .v-messages__message {
  color: var(--blackColor);
  font-family: "Roboto", sans-serif;
  font-size: 12px;
}

.v-input >>> .v-messages__message {
  color: var(--dangerColor);
  font-family: "Roboto", sans-serif;
}

.v-input.error--text >>> .v-input__slot {
  border: solid var(--dangerColor) 1px !important;
}

.v-input >>> .v-input__slot {
  background: #ffffff !important;
  border-radius: 10px;
  border: solid var(--fieldBox) 1px !important;
}

.v-input >>> i {
  color: var(--opacityGrayColor);
  font-size: 18px;
}

.v-input >>> .v-input__prepend-inner {
  margin: 10px 5px 0 0;
}

.v-input >>> input::placeholder {
  color: var(--opacityGrayColor) !important;
  opacity: 1;
}

.v-input >>> input {
  color: var(--blackColor) !important;
  font-family: "Roboto Medium", sans-serif !important;
}

.v-input >>> input:focus {
  color: var(--blackColor) !important;
}

.v-input >>> .v-select__selection {
  color: var(--blackColor) !important;
  font-weight: bold;  font-size: 14px;
}
</style>
