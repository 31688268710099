<template>
  <div>
    <JazzFormFields v-if="limits && !loading" :fields="fields" disabled />
    <JazzSkeletonLoader v-if="loading" type="form" />
  </div>
</template>

<script>
import JazzFormFields from "../../../components/form/JazzFormFields";
import { LimitesTarifasService } from "../../../services/api";
import JazzSkeletonLoader from "../../../components/template/JazzSkeletonLoader";
import globalMethods from "../../../mixins/globalMethods";
export default {
  name: "ClientLimits",
  components: { JazzSkeletonLoader, JazzFormFields },
  mixins: [globalMethods],
  data() {
    return {
      fields: {},
      limits: {},
      loading: true,
    };
  },
  mounted() {
    this.getLimits();
  },
  methods: {
    async getLimits() {
      const { chaveDeCliente } = this.$route.params;
      const arrayLimits = await LimitesTarifasService.consultarLimites({
        body: {
          chaveDeCliente: chaveDeCliente,
        },
      })
        .then((res) => res?.result?.limitesClientes)
        .finally(() => (this.loading = false));
      if (arrayLimits) {
        this.limits = {};
        arrayLimits.forEach((limit) => {
          if (limit?.operacaoId) {
            this.limits[limit?.periodoTarifaDescricao] = {
              ...this.limits[limit?.periodoTarifaDescricao],
              [limit?.operacao + limit?.limiteTarifaId]: {
                field: "TextField",
                valueField:
                  (limit?.tipoContagemId === 2
                    ? this.money2(limit?.maxAtual)
                    : limit?.maxAtual) || 0,
                label: limit?.operacao.split("_").join(" "),
                cols: 6,
                maskMoney: limit?.tipoContagemId === 2,
              },
            };
          }
        });
        this.fields = {};
        await Object.entries(this.limits).forEach((lim) => {
          this.fields = {
            ...this.fields,
            [lim[0]]: {
              title: `Limite ${lim[0]}`,
              field: "FormFieldsHeader",
            },
            ...lim[1],
          };
        });
      }
    },
  },
};
</script>

<style scoped></style>
