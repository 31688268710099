<template>
  <div class="client-blocking" v-if="client && client.detalhesBloqueio">
    <FormFieldsHeader title="Detalhes da Restrição" color="error" />
    <div
      class="row-infos"
      v-for="client in client.detalhesBloqueio"
      :key="client.tipoBloqueio"
    >
      <div class="info-text">
        <JazzTextGroup
          label="Data da Restrição"
          :text="
            client.dataBloqueio
              ? client.dataBloqueio
              : '--/--/----'
          "
        ></JazzTextGroup>
      </div>
      <div class="info-text">
        <JazzTextGroup
          label="Tipo de Dado"
          :text="client.tipoBloqueio === 1 ? 'CPF/CNPJ' : 'Número de Celular'"
        ></JazzTextGroup>
      </div>
      <div class="info-text">
        <JazzTextGroup
          label="Motivo da Restrição"
          :text="client.motivo"
        ></JazzTextGroup>
      </div>
      <div class="info-text">
        <JazzTextGroup
          label="Usuário Restrição"
          :text="client.usuarioBloqueio"
        ></JazzTextGroup>
      </div>
    </div>
  </div>
</template>

<script>
import globalMethods from "../../../../mixins/globalMethods";
import JazzTextGroup from "../../../../components/typography/JazzTextGroup";
import FormFieldsHeader from "../../../../components/FormFieldsHeader";

export default {
  name: "ClientBlocking",
  components: { FormFieldsHeader, JazzTextGroup },
  mixins: [globalMethods],
  props: {
    client: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped>
.row-infos {
  display: flex;
  justify-content: center;
  flex-flow: row wrap;
}

.info-text {
  margin: 0 10px;
  flex: 1;
  font-size: 14px;
}

.client-blocking {
  margin-bottom: 30px;
}
</style>
