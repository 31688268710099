<template>
  <div>
    <template v-if="!loading && collaborator">
      <JazzFormFields disabled :fields="fieldsContact" />
      <JazzExpansionPanel title="Endereço do Cadastro">
        <template v-slot:header>
          <TextField
            disabled
            label="Endereço Resumido"
            :value-field="addressString"
          />
        </template>
        <template v-slot:content>
          <JazzFormFields disabled :fields="fieldsAddress" />
        </template>
      </JazzExpansionPanel>
    </template>
    <JazzSkeletonLoader v-if="loading" type="form" />
  </div>
</template>

<script>
import JazzFormFields from "../../../../../components/form/JazzFormFields";
import estadosBrasileiros from "../../../../../dbjson/estadosBrasileiros.json";
import JazzSkeletonLoader from "../../../../../components/template/JazzSkeletonLoader";
import JazzExpansionPanel from "../../../../../components/JazzExpansionPanel";
import TextField from "../../../../../components/inputs/TextField";
import globalMethods from "../../../../../mixins/globalMethods";
import { ClientesBackofficeService } from "../../../../../services/apibackoffice";

export default {
  name: "CollaboratorAddress",
  mixins: [globalMethods],
  components: {
    TextField,
    JazzExpansionPanel,
    JazzSkeletonLoader,
    JazzFormFields,
  },
  props: {
    collaborator: Object,
  },
  data() {
    return {
      fieldsContact: {},
      fieldsAddress: {},
      labelContactButton: "Editar",
      loading: true,
      addressString: "",
      chaveDeCliente: this.$route.params.chaveDeCliente,
      textContactButton: false,
    };
  },
  mounted() {
    if (this.collaborator) {
      this.createContactFields(false);
      this.createAddressFields();
    }
  },
  methods: {
    createContactFields() {
      this.fieldsContact = {
        contactHeader: {
          field: "FormFieldsHeader",
          title: "Contato",
        },
        emailTextField: {
          field: "TextField",
          label: "E-mail",
          cols: 6,
          valueField: this.collaborator?.email,
        },
        celTextField: {
          field: "TextFieldMask",
          label: "Celular",
          cols: 6,
          maskFormat: ["(##) #####-####"],
          valueField:
            this.collaborator && this.collaborator?.telefoneMovel?.ddd
              ? this.collaborator?.telefoneMovel?.ddd.replace("0", "") +
                this.collaborator?.telefoneMovel?.numero
              : "",
        },
      };
      this.loading = false;
    },
    createAddressFields() {
      this.addressString = `${this.collaborator?.endereco?.rua}, ${this.collaborator?.endereco?.bairro} - ${this.collaborator?.endereco?.cidade}`;
      this.fieldsAddress = {
        addressZipCodeTextField: {
          field: "TextFieldMask",
          label: "CEP",
          maskFormat: ["#####-###"],
          cols: 5,
          valueField: this.collaborator?.endereco?.cep,
        },
        addressStateTextField: {
          field: "SelectField",
          label: "Estado",
          cols: 7,
          valueField:
            this.collaborator &&
            this.collaborator.endereco &&
            this.collaborator?.endereco?.uf
              ? this.collaborator?.endereco?.uf.toUpperCase()
              : "",
          items: [{ key: "", value: "Selecione" }, ...estadosBrasileiros],
          keyName: "value",
          keyValue: "key",
        },
        addressCityTextField: {
          variableName: "addressCityTextField",
          field: "TextField",
          label: "Cidade",
          cols: 6,
          valueField: this.collaborator?.endereco?.cidade,
        },
        addressNeighborhoodTextField: {
          field: "TextField",
          label: "Bairro",
          cols: 6,
          valueField: this.collaborator?.endereco?.bairro,
        },
        addressStreetTextField: {
          field: "TextField",
          label: "Logradouro",
          cols: 9,
          valueField: this.collaborator?.endereco?.rua,
        },
        addressNumberStreetTextField: {
          field: "TextFieldMask",
          label: "Número",
          maskFormat: ["######"],
          cols: 3,
          valueField: this.collaborator?.endereco?.numero,
        },
        addressComplementTextField: {
          field: "TextField",
          label: "Complemento",
          cols: 12,
          valueField: this.collaborator?.endereco?.complemento,
        },
      };
      this.loading = false;
    },
    async updateEmail(email) {
      if (!email || email === "") return true;
      return await ClientesBackofficeService.alterarDadosEmail({
        body: {
          chaveDeCliente: this.chaveDeCliente,
          emailDto: {
            email: email,
            confirmacaoEmail: email,
          },
        },
      });
    },
    async updatePhone(phone) {
      if (!phone || phone === "") return true;
      phone = this.removeMaskCharacters(phone);
      let objPhone = {
        ddd: `0${phone.substring(0, 2)}`,
        numero: phone.substring(2, 11),
      };
      return await ClientesBackofficeService.alterarDadosCelular({
        body: {
          chaveDeCliente: this.chaveDeCliente,
          telefoneMovel: objPhone,
        },
      });
    },
  },
  watch: {
    collaborator() {
      this.createContactFields();
      this.createAddressFields();
    },
  },
};
</script>

<style scoped>
.container-button {
  display: flex;
  justify-content: flex-end;
}

.editable-button {
  width: 160px;
}
</style>
