var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-col',{attrs:{"cols":_vm.cols}},[_c('div',{staticClass:"label-box"},[_c('label',{class:{ darkStyle: _vm.darkStyle }},[_vm._v(_vm._s(_vm.label))])]),_c('div',{staticClass:"container-input"},[_c('v-textarea',{staticClass:"text-field",class:{
        'has-rules': _vm.rules,
        borderSuccess: _vm.borderSuccess,
        borderDanger: _vm.borderDanger,
        darkStyle: _vm.darkStyle,
        disabledField: _vm.disabled,
      },attrs:{"data-cy":_vm.dataCy,"rows":"2","no-resize":true,"autocomplete":"new-password","disabled":_vm.disabled,"rules":_vm.rules ? _vm.rules : [],"placeholder":_vm.placeholder,"outlined":"","maxlength":_vm.maxLength,"counter":_vm.maxLength},on:{"input":function($event){return _vm.$emit('update:value', _vm.text)},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.$emit('onEnter', _vm.text)}},model:{value:(_vm.text),callback:function ($$v) {_vm.text=$$v},expression:"text"}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }