<template>
    <v-expansion-panels>
      <JazzExpansionPanel
        class="w-100"
        title="Histórico de Alterações"
        colorHeader="black"
        hideTitleBorder
      >
        <template v-slot:content>
          <JazzTable
            class="w-100"
            :loading="loadingTable"
            :rows="history"
            :pagination="pagination"
            :header="headers"
            emptyMessage="Nenhuma alteração encontrada"
            hide-pagination
          >
            <template slot="reportRow" slot-scope="props">
              <HistoryChangesRow :paramsItem="props.paramsItem" />
            </template>
          </JazzTable>
        </template>
      </JazzExpansionPanel>
    </v-expansion-panels>
  </template>
  
  <script>
  import globalMethods from "../../../../mixins/globalMethods";
  import JazzExpansionPanel from "../../../../components/JazzExpansionPanel";
  import JazzTable from "../../../../components/table/JazzTable";
  import HistoryChangesRow from "./rowsTable/HistoryChangesRow";
  import { HistoricoAlteracoesService } from "../../../../services/apibackoffice";
  
  export default {
    name: "HistoryChanges",
    components: { JazzExpansionPanel, JazzTable, HistoryChangesRow },
    mixins: [globalMethods],
    props: {
      idCard: Number,
    },
    data() {
      return {
        loadingTable: false,
        history: [],
        pagination: {
          page: 1,
          perPage: 25,
          total: 0,
          pages: 0,
        },
        headers: [
          {
            title: "Responsável",
            align: "left",
            justifyContent: "center",
          },
          {
            title: "Data",
            align: "left",
            justifyContent: "center",
          },
          {
            title: "Ação",
            align: "left",
            justifyContent: "center",
          },
          {
            title: "Alteração",
            align: "left",
            justifyContent: "center",
          },
        ],
      };
    },
    mounted() {
      this.searchHistory();
    },
    methods: {
      searchHistory() {
        this.loadingTable = true;
        HistoricoAlteracoesService.buscarPaginado({
          body: {
            tipoEntidade: [7001],
            entidadeId: [this.idCard],
            propriedades: [
              "InicioVigencia",
              "Imagem",
              "FimVigencia",
              "OrganizationUnitId",
              "Mensagem",
              "Link",
              "IsDeleted",
            ],
          },
        })
          .then((response) => {
            this.history = response.result.items;
          })
          .finally(() => {
            this.loadingTable = false;
          });
      },
    },
  };
  </script>
  
  <style lang="scss" scoped></style>
  