import PRegistration from "../../views/Client/PreviousRegistration/PRegistration";

export default [
  {
    path: "/pre-cadastro",
    name: "PreviousRegistration",
    component: PRegistration,
    meta: {
      permission: ["Pages.Clientes"],
      permissionName: "Clientes",
    },
  },
];
