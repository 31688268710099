<template>
  <div class="w-100">
    <JazzFormFields
      @update:values="limitesValues = $event"
      :fields="fieldsLimites"
    />
    <div
      class="footer-btn"
      v-if="hasPermission(['Api.Travel.AlterarLimitesCliente'])"
    >
      <DefaultButton
        data-cy="save-limit-button"
        @click.native="salvarLimites"
        text="Salvar Limites"
      />
    </div>
    <ObservationDialog ref="observationDialog" />
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import globalMethods from "../../../../mixins/globalMethods";
import JazzFormFields from "../../../../components/form/JazzFormFields.vue";
import DefaultButton from "../../../../components/button/DefaultButton";
import ObservationDialog from "./ObservationDialog";
import { ClientesTravelService } from "../../../../services/apitravel";

export default {
  components: {
    JazzFormFields,
    DefaultButton,
    ObservationDialog,
  },
  name: "TravelClientDataTab",
  mixins: [globalMethods],
  props: {
    client: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      limitesValues: {},
      fieldsLimites: {},
    };
  },
  mounted() {
    this.createForm();
  },
  methods: {
    ...mapMutations(["CHANGE_LOADING"]),
    createForm() {
      this.fieldsLimites = {
        title: {
          field: "FormFieldsHeader",
          title: "Limites e Saldos",
        },
        limiteCreditoTextField: {
          field: "TextField",
          type: "text",
          placeholder: "",
          maskMoney: true,
          label: "Limite máximo de crédito",
          cols: 6,
          disabled: !this.hasPermission(["Api.Travel.AlterarLimitesCliente"]),
          valueField: this.money2(this.client.limiteMaximoDeCredito || 0),
          helpText: "Limite que o banco concedeu ao cliente.",
          rules: [
            (value) =>
              !!value || 'O campo "Limite Máximo de Crédito" é obrigatório',
          ],
        },
        limiteDisponivelTextField: {
          field: "TextField",
          type: "text",
          placeholder: "",
          maskMoney: true,
          label: "Limite disponível de crédito",
          cols: 6,
          disabled: !this.hasPermission(["Api.Travel.AlterarLimitesCliente"]),
          valueField: this.money2(this.client.limiteDisponivelDeCredito || 0),
          helpText: "Limite disponível para a emissão de VCNs.",
          rules: [
            (value) =>
              !!value || 'O campo "Limite Disponível de Crédito" é obrigatório',
          ],
        },
        saldoContaTextField: {
          field: "TextField",
          maskMoney: true,
          label: "Saldo em conta",
          helpText: "Valor disponível em conta corrente.",
          cols: 6,
          disabled: true,
          valueField: this.money2(this.client.saldoDisponivelPrePago || 0),
        },
        saldoAlocadoTextField: {
          field: "TextField",
          maskMoney: true,
          label: "Saldo alocado disponível",
          helpText:
            "Valor de VCNs finalizados, disponível para a emissão de novos VCNs.",
          cols: 6,
          disabled: true,
          valueField: this.money2(this.client.saldoDisponivelAlocado || 0),
        },
        saldoCartoesTextField: {
          field: "TextField",
          maskMoney: true,
          label: "Saldo em cartões",
          helpText: "Valor disponível em todos os VCNs ativos.",
          cols: 6,
          disabled: true,
          valueField: this.money2(this.client.saldoEmCartoes || 0),
        },
        saldoTotalTextField: {
          field: "TextField",
          maskMoney: true,
          label: "Saldo disponível total",
          helpText: "Saldo em conta + Saldo alocado disponível",
          cols: 6,
          disabled: true,
          valueField: this.money2(this.client.saldoDisponivelTotal || 0),
        },
      };
    },
    salvarLimites() {
      this.$refs.observationDialog
        .getInput(
          "O limite máximo de crédito é o limite que o banco concedeu ao cliente " +
            "e o limite disponível de crédito é o valor disponível para a emissão de " +
            "cartões virtuais do cliente. Deseja alterá-los?"
        )
        .then((output) => {
          const [succeeded, text] = output;

          if (succeeded) {
            this.CHANGE_LOADING(true);

            const obj = {
              chaveDeCliente: this.client.chaveDeCliente,
              limiteMaximoDeCredito: this.maskInputMoney(
                this.removeMaskMoney(this.limitesValues.limiteCreditoTextField)
              ),
              limiteDisponivelDeCredito: this.maskInputMoney(
                this.removeMaskMoney(
                  this.limitesValues.limiteDisponivelTextField
                )
              ),
              observacao: text,
            };

            ClientesTravelService.alterarLimites({
              body: obj,
            })
              .then((response) => {
                this.$store.dispatch(
                  "action_changeMessageSnackBar",
                  response.result.mensagem,
                  { module: "template" }
                );
              })
              .catch((e) => {
                this.openMessageModal(
                  "danger",
                  "Erro ao realizar alteração. Tente novamente.",
                  e.message
                );
              })
              .finally(() => {
                this.CHANGE_LOADING(false);
              });
          }
        });
    },
  },
};
</script>

<style scoped>
.footer-btn {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  padding-top: 10px;
}
</style>
