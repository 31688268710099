<template>
  <div class="container">
    <v-expansion-panels v-model="activePanel">
      <JazzExpansionPanelCard title="Senha">
        <template v-slot:content>
          <div class="password-panel">
            <JazzFormFields :fields="fieldsEmail" />
            <DefaultButton
              class="mt-5"
              v-if="hasPermission(['Api.Travel.EnviarNovaSenhaCliente'])"
              data-cy="save-password"
              colorStyle="dark"
              text="Enviar senha"
              @click.native="onResetPasswordClicked"
            />
          </div>
        </template>
      </JazzExpansionPanelCard>
      <JazzExpansionPanelCard title="Histórico">
        <template v-slot:content>
          <ModificationHistoryTable
            :entityId="[client.travelClienteId, client.chaveDeCliente]"
            :entityTypes="[1001, 1002, 1003]"
          />
        </template>
      </JazzExpansionPanelCard>
    </v-expansion-panels>
    <ObservationDialog ref="observationDialog" />
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import globalMethods from "../../../../../mixins/globalMethods";
import DefaultButton from "../../../../../components/button/DefaultButton";
import ObservationDialog from "../ObservationDialog";
import JazzFormFields from "../../../../../components/form/JazzFormFields";
import ModificationHistoryTable from "../ModificationHistoryTable";
import JazzExpansionPanelCard from "../../../../../components/panels/JazzExpansionPanelCard";
import { ClientesTravelService } from "../../../../../services/apitravel";

export default {
  components: {
    JazzFormFields,
    DefaultButton,
    ObservationDialog,
    ModificationHistoryTable,
    JazzExpansionPanelCard,
  },
  props: {
    client: {
      type: Object,
      default: undefined,
    },
  },
  mixins: [globalMethods],
  data() {
    return {
      activePanel: 0,
      fieldsLimites: {},
      fieldsEmail: {},
      limitesValues: {},
      params: null,
    };
  },
  mounted() {
    this.params = this.$route.params;
    this.createEmailForm();
  },
  methods: {
    ...mapMutations(["CHANGE_LOADING"]),
    createEmailForm() {
      this.fieldsEmail = {
        saldoContaTextField: {
          field: "TextField",
          label: "E-mail de autenticação",
          cols: 4,
          disabled: true,
          valueField: this.client.emailAutenticacao || "",
        },
      };
    },
    onResetPasswordClicked() {
      this.$refs.observationDialog
        .getInput(
          "Ao enviar a nova senha, o cliente precisará informá-la ao integrador para a geração de cartões"
        )
        .then((output) => {
          const [succeeded, text] = output;

          if (succeeded) {
            this.CHANGE_LOADING(true);

            ClientesTravelService.novaSenha({
              body: {
                chaveDeCliente: this.client.chaveDeCliente,
                observacao: text,
              },
            })
              .then(() => {
                this.$store.dispatch(
                  "action_changeMessageSnackBar",
                  "Senha alterada com sucesso",
                  { module: "template" }
                );
              })
              .catch((e) => {
                this.openMessageModal(
                  "danger",
                  "Ocorreu um erro durante o reset da senha",
                  e.message
                );
              })
              .finally(() => {
                this.CHANGE_LOADING(false);
              });
          }
        });
    },
  },
};
</script>

<style scoped>
.password-panel {
  display: flex;
  align-items: center;
}
</style>
