import Login from "../views/Login";

export default [
  {
    path: "*",
    component: Login,
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: {
      permission: [],
      permissionName: "",
    },
  },
  {
    path: "/login",
    name: "Logout",
    component: Login,
    meta: {
      permission: [],
      permissionName: "",
    },
  },
];
