<template>
  <div class="w-100">
    <JazzSkeletonLoader v-if="loading" type="form" />
    <div v-show="!loading">
      <JazzFormFields
        :fields="fieldsInformation"
        @checkValidity="validateData"
        :validate="validate"
      />
      <div
        class="button"
        v-if="hasPermission(['Api.Travel.AlterarDadosCliente'])"
      >
        <DefaultButton
          data-cy="save-button"
          @click.native="validate++"
          text="Salvar informações"
        />
      </div>
    </div>
    <ObservationDialog ref="observationDialog" />
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import globalMethods from "../../../../mixins/globalMethods";
import JazzFormFields from "../../../../components/form/JazzFormFields.vue";
import DefaultButton from "../../../../components/button/DefaultButton";
import JazzSkeletonLoader from "../../../../components/template/JazzSkeletonLoader";
import ObservationDialog from "./ObservationDialog";
import { ProdutosService } from "../../../../services/api";
import { ClientesTravelService } from "../../../../services/apitravel";

export default {
  components: {
    JazzFormFields,
    DefaultButton,
    JazzSkeletonLoader,
    ObservationDialog,
  },
  name: "ChangeClientData",
  mixins: [globalMethods],
  props: {
    client: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      uoProducts: null,
      loading: false,
      validate: 0,
      fieldsInformation: {},
      fieldsLimits: {},
      listNaturezasJuridicas: [
        { id: 1, displayName: "ME - Microempresa", key: "ME" },
        { id: 2, displayName: "MEI - Microemprendedor Individual", key: "MEI" },
        { id: 3, displayName: "SA - Sociedade Anônima", key: "SA" },
        { id: 4, displayName: "LTDA - Limitada", key: "LTDA" },
        {
          id: 5,
          displayName:
            "EIRELI - Empresa Individual de Responsabilidade Limitada",
          key: "EIRELI",
        },
      ],
      listOrigensCapitalSocial: [
        { id: 1, displayName: "Nacional" },
        { id: 2, displayName: "Estrangeiro" },
      ],
      params: null,
    };
  },
  async mounted() {
    if (this.client) {
      this.params = this.$route.params;

      this.loading = true;

      const payload = {
        chaveDeCliente: this.client.chaveDeCliente,
        organizationUnitId: this.client.organizationUnitId,
      };

      await ProdutosService.consultarProdutosDisponiveisCliente({
        body: payload,
      })
        .then((response) => {
          this.uoProducts = response?.result?.produtos || [];
          this.createForm();
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
          this.uoProducts = [];
        });
    }
  },
  methods: {
    ...mapMutations(["CHANGE_LOADING"]),
    createForm() {
      this.fieldsInformation = {
        title1: {
          field: "FormFieldsHeader",
          title: "Dados do Cliente",
        },
        razaoSocial: {
          field: "TextField",
          label: "Razão Social",
          cols: 4,
          disabled: true,
          valueField: this.client?.razaoSocial || "",
          rules: [
            (fields) =>
              !!fields.razaoSocial.valueField ||
              "O campo Nome fantasia é obrigatório",
          ],
        },
        nomeFantasia: {
          field: "TextField",
          label: "Nome Fantasia",
          cols: 5,
          disabled: true,
          valueField: this.client?.nomeFantasia || "",
          rules: [
            (fields) =>
              !!fields.nomeFantasia.valueField ||
              "O campo Nome fantasia é obrigatório",
          ],
        },
        identificacaoFiscal: {
          field: "TextFieldMask",
          label: "CNPJ",
          cols: 3,
          maskFormat: "##.###.###/####-##",
          disabled: true,
          valueField: this.client?.identificacaoFiscal || "",
          rules: [
            (fields) =>
              !!fields.identificacaoFiscal.valueField ||
              "O campo CNPJ é obrigatório",
            (fields) =>
              this.validateCnpj(fields.identificacaoFiscal.valueField) ||
              "Digite um CNPJ válido",
          ],
        },
        chaveDeCliente: {
          field: "TextField",
          label: "Chave de Cliente",
          cols: 3,
          disabled: true,
          valueField: this.client?.chaveDeCliente || "",
        },
        integrador: {
          field: "TextField",
          label: "Integrador",
          cols: 6,
          placeholder: "",
          disabled: true,
          valueField: this.client.organizationUnitDescricao,
        },
        naturezaJuridica: {
          field: "SelectField",
          label: "Natureza Jurídica",
          cols: 3,
          disabled: true,
          items: [
            { id: null, displayName: "Selecione", key: "" },
            ...this.listNaturezasJuridicas,
          ],
          valueField: this.client?.naturezaJuridica
            ? this.findObjectFromArray(
                this.listNaturezasJuridicas,
                "key",
                this.client.naturezaJuridica.toUpperCase()
              )
            : "",
          keyName: "displayName",
          keyValue: "key",
        },
        porte: {
          field: "TextField",
          label: "Porte",
          placeholder: "",
          cols: 3,
          disabled: true,
          valueField: this.client?.porte || "",
        },
        grupo: {
          field: "TextField",
          label: "Grupo",
          cols: 6,
          disabled: true,
          valueField: this.client?.grupo || "",
        },
        cnae: {
          field: "TextFieldMask",
          label: "CNAE",
          type: "text",
          maskFormat: ["####-#/##"],
          placeholder: "####-#/##",
          cols: 3,
          disabled: true,
          valueField: this.client.cnae,
          rules: [
            (fields) =>
              !!fields.cnae.valueField || "O campo CNAE é obrigatório",
          ],
        },
        cnaeDescricao: {
          field: "TextField",
          placeholder: "",
          label: "Descrição CNAE",
          cols: 5,
          disabled: true,
          valueField: this.client?.cnaeDescricao || "",
        },
        capitalSocial: {
          field: "TextField",
          type: "text",
          label: "Capital Social",
          placeholder: "",
          maskMoney: true,
          cols: 4,
          disabled: true,
          valueField: this.client.capitalSocial
            ? this.money2(this.client.capitalSocial)
            : "",
        },
        origemCapitalSocial: {
          field: "SelectField",
          label: "Origem Capital Social",
          cols: 3,
          disabled: true,
          items: [...this.listOrigensCapitalSocial],
          valueField: this.client.origemCapitalSocial
            ? this.findObjectFromArray(
                this.listOrigensCapitalSocial,
                "displayName",
                this.client.origemCapitalSocial
              )
            : undefined,
          keyName: "displayName",
          keyValue: "id",
          rules: [],
        },
        dataInicioAtividade: {
          field: "TextFieldMask",
          label: "Início Atividades",
          cols: 4,
          disabled: true,
          maskFormat: "##/##/####",
          valueField:
            this.client && this.client?.dataInicioAtividade
              ? this.dateFormat(this.client?.dataInicioAtividade)
              : this.dateEnFormat(),
          rules: [
            (fields) =>
              !!fields.dataInicioAtividade.valueField ||
              "O campo Data Início Atividade é obrigatório",
            (fields) =>
              this.validateDate(fields.dataInicioAtividade.valueField) ||
              "Digite uma data válida",
          ],
        },
        produto: {
          field: "SelectField",
          label: "Produto",
          cols: 8,
          disabled: true,
          items: [...this.uoProducts],
          keyName: "descricao",
          keyValue: "chaveDeProduto",
          valueField: this.client?.chaveDeProduto,
        },
        emailAutenticacao: {
          field: "TextField",
          label: "E-mail de Autenticação",
          cols: 6,
          disabled: !this.hasPermission(["Api.Travel.AlterarDadosCliente"]),
          valueField: this.client?.emailAutenticacao || "",
          rules: [
            (fields) =>
              !!fields.emailAutenticacao.valueField ||
              "O campo e-mail é obrigatório",
            (fields) =>
              /.+@.+\..+/.test(fields.emailAutenticacao.valueField) ||
              "Digite um e-mail válido",
          ],
        },
        title2: {
          field: "FormFieldsHeader",
          title: "Contato Comercial",
        },
        nomeComercial: {
          field: "TextField",
          label: "Nome Comercial",
          cols: 6,
          disabled: !this.hasPermission(["Api.Travel.AlterarDadosCliente"]),
          valueField: this.client?.nomeComercial || "",
        },
        emailComercial: {
          field: "TextField",
          label: "E-mail",
          cols: 4,
          disabled: !this.hasPermission(["Api.Travel.AlterarDadosCliente"]),
          valueField: this.client?.emailComercial || "",
        },
        telefoneMovelComercial: {
          field: "TextFieldMask",
          label: "Celular",
          cols: 3,
          disabled: !this.hasPermission(["Api.Travel.AlterarDadosCliente"]),
          maskFormat: "(###) #####-####",
          valueField:
            this.client?.telefoneMovelComercial?.ddd +
              this.client?.telefoneMovelComercial?.numero || "",
        },
        telefoneFixoComercial: {
          field: "TextFieldMask",
          label: "Telefone Fixo",
          cols: 3,
          disabled: !this.hasPermission(["Api.Travel.AlterarDadosCliente"]),
          maskFormat: "(###) ####-####",
          valueField:
            this.client?.telefoneFixoComercial?.ddd +
              this.client?.telefoneFixoComercial?.numero || "",
        },
        title3: {
          field: "FormFieldsHeader",
          title: "Contato Faturamento",
        },
        nomeFaturamento: {
          field: "TextField",
          label: "Nome",
          cols: 6,
          disabled: !this.hasPermission(["Api.Travel.AlterarDadosCliente"]),
          valueField: this.client?.nomeFaturamento || "",
          rules: [
            (fields) =>
              !!fields.nomeFaturamento.valueField ||
              "O campo Nome é obrigatório",
          ],
        },
        emailFaturamento: {
          field: "TextField",
          label: "E-mail",
          cols: 4,
          disabled: !this.hasPermission(["Api.Travel.AlterarDadosCliente"]),
          valueField: this.client?.emailFaturamento || "",
          rules: [
            (fields) =>
              !!fields.emailFaturamento.valueField ||
              "O campo e-mail é obrigatório",
            (fields) =>
              /.+@.+\..+/.test(fields.emailFaturamento.valueField) ||
              "Digite um e-mail válido",
          ],
        },
        telefoneMovelFaturamento: {
          field: "TextFieldMask",
          label: "Celular",
          cols: 3,
          disabled: !this.hasPermission(["Api.Travel.AlterarDadosCliente"]),
          maskFormat: "(###) #####-####",
          valueField:
            this.client?.telefoneMovelFaturamento?.ddd +
              this.client?.telefoneMovelFaturamento?.numero || "",
        },
        telefoneFixoFaturamento: {
          field: "TextFieldMask",
          label: "Telefone Fixo",
          cols: 3,
          disabled: !this.hasPermission(["Api.Travel.AlterarDadosCliente"]),
          maskFormat: "(###) ####-####",
          valueField:
            this.client?.telefoneFixoFaturamento?.ddd +
              this.client?.telefoneFixoFaturamento?.numero || "",
        },
        title4: {
          field: "FormFieldsHeader",
          title: "Endereço",
        },
        rua: {
          field: "TextField",
          label: "Rua",
          disabled: true,
          cols: 6,
          valueField: this.client?.endereco?.rua || "",
          rules: [
            (fields) =>
              !!fields.rua.valueField || "O campo Logradouro é obrigatório",
          ],
        },
        numero: {
          field: "TextField",
          label: "Número",
          cols: 2,
          disabled: true,
          maskFormat: "######",
          valueField: this.client?.endereco?.numero || "",
          rules: [
            (fields) =>
              !!fields.numero.valueField ||
              "O campo número do endereço é obrigatório",
          ],
        },
        complemento: {
          field: "TextField",
          label: "Complemento",
          cols: 4,
          disabled: true,
          valueField: this.client?.endereco?.complemento || "",
        },
        bairro: {
          field: "TextField",
          label: "Bairro",
          cols: 3,
          disabled: true,
          valueField: this.client?.endereco?.bairro || "",
        },
        cep: {
          field: "TextFieldMask",
          label: "CEP",
          cols: 2,
          disabled: true,
          maskFormat: "#####-###",
          valueField: this.client?.endereco?.cep || "",
          rules: [
            (fields) => !!fields.cep.valueField || "O campo CEP é obrigatório",
          ],
        },
        cidade: {
          field: "TextField",
          label: "Cidade",
          cols: 4,
          disabled: true,
          valueField: this.client?.endereco?.cidade || "",
          rules: [
            (fields) =>
              !!fields.cidade.valueField || "O campo Cidade é obrigatório",
          ],
        },
        uf: {
          field: "SelectField",
          label: "UF",
          cols: 3,
          disabled: true,
          valueField: this.client?.endereco?.uf.toUpperCase() || "",
          items: [{ key: "", value: "Selecione" }, ...this.arrayBrStates()],
          keyName: "value",
          keyValue: "key",
          rules: [
            (fields) =>
              !!fields.uf.valueField || "O campo Estado é obrigatório",
          ],
        },
        title5: {
          field: "FormFieldsHeader",
          title: "Conta Pagamento",
        },
        agenciaPagamento: {
          field: "TextField",
          label: "Agência",
          cols: 3,
          type: "text",
          disabled: true,
          valueField: this.client?.contaPagamento?.agencia || "",
        },
        contaPagamento: {
          field: "TextField",
          label: "Conta",
          cols: 3,
          type: "text",
          disabled: true,
          valueField: this.client?.contaPagamento?.numero || "",
        },
        title6: {
          field: "FormFieldsHeader",
          title: "Conta Corrente",
        },
        agenciaCorrente: {
          field: "TextField",
          label: "Agência",
          cols: 3,
          type: "text",
          disabled: true,
          valueField: this.client?.contaCorrente?.agencia || "",
        },
        contaCorrente: {
          field: "TextField",
          label: "Conta",
          cols: 3,
          type: "text",
          disabled: true,
          valueField: this.client?.contaCorrente?.numero || "",
        },
      };
    },
    validateData(form) {
      if (form.isValid) {
        this.$refs.observationDialog
          .getInput(
            "Você está solicitando a alteração de informações importantes do cliente," +
              " utilizados para contato, envio de boletos, entre outros. Deseja alterá-las?”"
          )
          .then((output) => {
            const [succeeded, text] = output;

            if (succeeded) {
              this.CHANGE_LOADING(true);
              const payload = {
                chaveDeCliente: form.values.chaveDeCliente,
                emailAutenticacao: form.values.emailAutenticacao,
                nomeComercial: form.values.nomeComercial,
                emailComercial: form.values.emailComercial,
                telefoneMovelComercial: {
                  ddd: this.convertStringFilter(
                    this.removeMaskCharacters(
                      form.values.telefoneMovelComercial.split(" ")[0]
                    )
                  ),
                  numero: this.convertStringFilter(
                    this.removeMaskCharacters(
                      form.values.telefoneMovelComercial.split(" ")[1]
                    )
                  ),
                },
                telefoneFixoComercial: {
                  ddd: this.convertStringFilter(
                    this.removeMaskCharacters(
                      form.values.telefoneFixoComercial.split(" ")[0]
                    )
                  ),
                  numero: this.convertStringFilter(
                    this.removeMaskCharacters(
                      form.values.telefoneFixoComercial.split(" ")[1]
                    )
                  ),
                },
                nomeFaturamento: form.values.nomeFaturamento,
                emailFaturamento: form.values.emailFaturamento,
                telefoneMovelFaturamento: {
                  ddd: this.convertStringFilter(
                    this.removeMaskCharacters(
                      form.values.telefoneMovelFaturamento.split(" ")[0]
                    )
                  ),
                  numero: this.convertStringFilter(
                    this.removeMaskCharacters(
                      form.values.telefoneMovelFaturamento.split(" ")[1]
                    )
                  ),
                },
                telefoneFixoFaturamento: {
                  ddd: this.convertStringFilter(
                    this.removeMaskCharacters(
                      form.values.telefoneFixoFaturamento.split(" ")[0]
                    )
                  ),
                  numero: this.convertStringFilter(
                    this.removeMaskCharacters(
                      form.values.telefoneFixoFaturamento.split(" ")[1]
                    )
                  ),
                },
                motivo: text,
              };
              ClientesTravelService.alterarDadosContato({
                body: payload,
              })
                .then((response) => {
                  this.$store.dispatch(
                    "action_changeMessageSnackBar",
                    response.result.mensagem,
                    { module: "template" }
                  );
                })
                .finally(() => {
                  this.CHANGE_LOADING(false);
                });
            }
          });
      }
    },
  },
};
</script>

<style scoped>
.button {
  float: right;
}
</style>
