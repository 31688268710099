<template>
  <v-col :cols="cols">
    <div :class="{ inline: inline }">
      <div class="label-box">
        <label :class="{ darkStyle: darkStyle }">{{ label }}</label>
      </div>
      <div class="container-input">
        <v-switch
          :data-cy="dataCy"
          v-model="value"
          inset
          color="#002e37"
          :disabled="disabled"
        ></v-switch>
        <DefaultInformation
          :size="8"
          v-if="helpText !== undefined"
          :text="helpText"
        />
      </div>
    </div>
  </v-col>
</template>

<script>
import DefaultInformation from "../tooltip/DefaultInformation";

export default {
  name: "SwitchField",
  components: { DefaultInformation },
  props: {
    inline: Boolean,
    dataCy: String,
    label: {},
    valueField: {},
    disabled: {
      default: false,
    },
    valueFieldSensivity: {
      default: true,
    },
    cols: {},
    darkStyle: {},
    helpText: {},
  },
  data() {
    return {
      value: false,
    };
  },
  watch: {
    valueField: {
      immediate: true,
      handler(value) {
        this.value = value;
      },
    },
    value: {
      immediate: true,
      handler() {
        this.$emit("update:value", this.value);
      },
    },
  },
};
</script>

<style scoped>
label {
  color: var(--grayColor) !important;
  font-family: "Roboto", sans-serif;
  font-size: 12px !important;
}

label.darkStyle {
  font-size: 14px !important;
  color: #ffffff !important;
  font-family: "Roboto Light", sans-serif !important;
}

.label-box {
  margin-bottom: 5px;
}

.v-input >>> input {
  color: var(--blackColor) !important;
  font-family: "Roboto Medium", sans-serif;
  font-size: 14px;
}

.v-input >>> fieldset {
  color: transparent !important;
}

.v-input >>> .v-text-field__details {
  display: none;
}

.v-input.error--text >>> .v-text-field__details {
  display: block;
}

/*.v-input.has-rules >>> .v-text-field__details{*/
/*    display: block;*/
/*}*/

.v-input >>> .v-messages__message {
  color: var(--dangerColor);
  font-family: "Roboto", sans-serif;
}

.v-input.error--text >>> .v-input__slot {
  border: solid var(--dangerColor) 1px !important;
}

.v-input.borderSuccess >>> .v-input__slot {
  border: solid var(--successColor) 2px !important;
}

.v-input.borderDanger >>> .v-input__slot {
  border: solid var(--dangerColor) 2px !important;
}

.v-input >>> i {
  color: var(--lightGrayColor);
  font-size: 18px;
}

.v-input >>> .v-input__prepend-inner {
  margin: 10px 5px 0 0;
}

.v-input >>> input::placeholder {
  color: var(--lightGrayColor) !important;
  font-size: 14px;
  font-family: "Roboto", sans-serif !important;
}

.v-input >>> input {
  color: var(--blackColor) !important;
  caret-color: var(--blackColor) !important;
  font-family: "Roboto Black", sans-serif !important;
}

.v-input >>> input:focus {
  color: var(--blackColor) !important;
}

.v-input--is-focused >>> i {
  color: var(--blackColor) !important;
}

.container-input {
  display: flex;
}

.v-input.withButton >>> .v-input__slot {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

button {
  border: 0;
  height: 48px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  padding: 0 20px;
  font-size: 14px;
  font-weight: bold;  color: #ffffff;
}

.inline {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: row-reverse;
}

.inline .label-box {
  margin-right: 20px;
}
</style>
