<template>
  <div class="page-container">
    <div class="page-header">
      <JazzListHeader
        v-if="title"
        :creationRoute="creationRoute"
        :total-items="totalItems"
        :changeShowFilters="showFiltersFields"
        :title="title"
        :subtitle="subtitle"
        @update:changeButtonFilters="showFiltersFields = $event"
        v-on="$listeners"
        :hideButtonHeader="hideButtonHeader"
        @creationButton="$emit(creationRoute.eventName)"
        :loading="disableSearchButton"
        :buttonWithLabel="buttonWithLabel"
        :hideFilterButton="hideFilterButton"
        :helpRoute="helpRoute"
        @helpButton="$emit(helpRoute.eventName)"
      />
      <JazzFormFields
        v-if="!loading"
        :loading="disableSearchButton"
        :colsButtonSearch="colsButtonSearch"
        v-show="showFiltersFields"
        :withButton="labelButton"
        @updateEvent="$emit('updateEvent', $event)"
        :fields="fields"
        :hideButtonSearch="hideButtonSearch"
        @checkValidity="$emit('search', $event)"
      />
      <JazzSkeletonLoader type="filters" v-else />
    </div>
    <div class="page-content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import JazzFormFields from "../form/JazzFormFields";
import JazzListHeader from "./JazzListHeader";
import JazzSkeletonLoader from "./JazzSkeletonLoader";

export default {
  name: "JazzListPage",
  components: { JazzSkeletonLoader, JazzListHeader, JazzFormFields },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    disableSearchButton: {
      type: Boolean,
      default: false,
    },
    changeShowFilters: {
      type: Boolean,
      default: true,
    },
    title: String,
    fields: Object,
    totalItems: Number,
    creationRoute: {
      type: Object,
      default: undefined,
    },
    hideButtonHeader: {
      type: Boolean,
      default: false,
    },
    hideFilterButton: {
      type: Boolean,
      default: false,
    },
    labelButton: {
      type: String,
      default: "Pesquisar",
    },
    colsButtonSearch: {
      type: Number,
    },
    subtitle: {
      type: String,
    },
    hideButtonSearch: {
      type: Boolean,
      default: false,
    },
    buttonWithLabel: {
      type: Boolean,
      default: false,
    },
    helpRoute: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      showFiltersFields: true,
    };
  },
  watch: {
    changeShowFilters() {
      this.showFiltersFields = this.changeShowFilters;
    },
  },
};
</script>

<style scoped>
.page-container {
  flex: 1;
  height: fit-content;
}

.page-header {
  width: 100%;
}

.page-content {
  width: 100%;
  display: block;
}
</style>
