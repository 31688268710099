<template>
  <div :class="{ solo: !childrens || childrens.length === 0 }">
    <router-link
      :key="name"
      tag="li"
      :to="route_name ? { name: route_name } : ''"
      @click.native="
        childrens && childrens.length > 0 && (showChildrens = !showChildrens)
      "
    >
      <div>        
        <v-icon color="white" size="18">
          {{ icon }}
        </v-icon>
        {{ name }}
      </div>
    </router-link>
    <ul v-show="showChildrens">
      <router-link
        :key="children.name + '-' + children.route_name"
        :to="{ name: children.route_name }"
        tag="li"
        :class="
          $router.currentRoute.name === children.route_name && activeMenu()
        "
        v-show="children.show"
        v-for="children in childrens"
      >
        <div>
          <i :class="children.icon"></i>
          {{ children.name }}
        </div>
      </router-link>
    </ul>
  </div>
</template>

<script>
export default {
  name: "ItemMenu",
  props: ["name", "route_name", "icon", "childrens"],
  data() {
    return {
      showChildrens: false,
    };
  },
  methods: {
    activeMenu() {
      this.showChildrens = true;
      return "active";
    },
  },
};
</script>

<style scoped lang="scss">
li {
  padding: 0 20px;
  display: flex;
  align-items: center;
  height: 48px;
  border-bottom-left-radius: 30px;
  border-top-left-radius: 30px;
  font-size: 14px;
  color: $whiteColor;
  justify-content: space-between;
  cursor: pointer;
  margin: 1px 0 0 10px;
}

li div {
  display: flex;
  align-items: center;
  padding-right: 10px;
  line-height: 18px;
}

li div i {
  margin-right: 10px;
  font-size: 18px;
  width: 28px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

div.active > li div,
li.active div {
  font-weight: bold;
}

ul > li.active {
  background: $backgroundColorSelectedMenu;
  color: $primaryColor;
}

div.solo.active > li {
  background: $backgroundColorSelectedMenu;
  color: $primaryColor;
}

div.active li:hover,
.menu-item-float:hover {
  cursor: pointer;
}

ul ul {
  margin-left: 30px !important;
}
</style>
