<template>
  <DefaultCard class="benefit-registration">
    <JazzListPage
      :disableSearchButton="loadingTable || loadingFilters"
      :fields="fields"
      :loading="loadingFilters"
      title="Cadastro de Colaboradores"
      @search="searchHistory"
      hide-filter-button
      :creationRoute="creationRoute"
      button-with-label
      @importFile="showImportModal = true"
      :helpRoute="helpRoute"
      @stepBystep="showHelpModal = true"
    >
      <ProcessingFilesList
        class="mt-10 mb-5"
        v-if="processingFiles"
        :result="processingFiles"
      />
      <JazzTable
        class="mt-5"
        :header="header"
        :loading="loadingTable"
        :rows="history"
        :pagination="pagination"
        @inputNewPage="searchHistory(null, $event)"
      >
        <template slot="reportRow" slot-scope="props">
          <BenefitRegistrationRow
            @actionButton="downloadFile($event)"
            :paramsItem="props.paramsItem"
          />
        </template>
      </JazzTable>
    </JazzListPage>
    <BenefitImportModal
      v-if="showImportModal && !loadingFilters"
      @showModal="showImportModal = $event"
      :tesouraryAccounts="tesouraryAccounts"
      @getProcessingFiles="searchFiles($event, true)"
      @showHelpModal="
        showImportModal = false;
        showHelpModal = true;
      "
    />
    <CustomerBenefitHelpModalFlow
      v-if="showHelpModal && !loadingFilters"
      @showModal="showHelpModal = $event"
    />
  </DefaultCard>
</template>

<script>
import { mapMutations } from "vuex";
import globalMethods from "../../../mixins/globalMethods";
import DefaultCard from "../../../components/cards/DefaultCard";
import JazzListPage from "../../../components/template/JazzListPage";
import JazzTable from "../../../components/table/JazzTable";
import BenefitRegistrationRow from "./components/rowsTable/BenefitRegistrationRow";
import BenefitImportModal from "./components/modals/BenefitImportModal";
import ProcessingFilesList from "./components/ProcessingFilesList";
import { ClientesService, TesourariasService } from "../../../services/api";
import CustomerBenefitHelpModalFlow from "./components/modals/CustomerBenefitHelpModalFlow";

export default {
  name: "CustomerBenefitRegistration",
  mixins: [globalMethods],
  components: {
    DefaultCard,
    JazzListPage,
    JazzTable,
    BenefitRegistrationRow,
    BenefitImportModal,
    ProcessingFilesList,
    CustomerBenefitHelpModalFlow,
  },
  data() {
    return {
      tesouraryAccounts: [],
      loadingFilters: true,
      history: [],
      pagination: {
        page: 1,
      },
      loadingTable: false,
      fields: {
        uoAutoCompleteField: {
          valueField: null,
        },
      },
      filters: {},
      header: [
        { title: "Arquivo", align: "left", percentWidth: "20" },
        { title: "Usuário responsável", align: "left", percentWidth: "18" },
        { title: "Cadastros", align: "left", percentWidth: "15" },
        { title: "Status", align: "left", percentWidth: "15" },
        { title: "Processado", align: "left", percentWidth: "15" },
        { title: "Ação", align: "left", percentWidth: "15" },
      ],
      creationRoute: {
        text: "Importar arquivo",
        title: null,
        route: null,
        eventName: "importFile",
      },
      showImportModal: false,
      processingFiles: null,
      helpRoute: {
        text: "Dúvidas? Como Importar Colaboradores",
        title: null,
        route: null,
        eventName: "stepBystep",
      },
      showHelpModal: false,
    };
  },
  async mounted() {
    this.loadingFilters = true;
    await this.getTesouraryAccounts();
    this.createForm();
    this.loadingFilters = false;
  },
  methods: {
    ...mapMutations(["CHANGE_LOADING"]),
    async searchFiles(uo, reloadHistory = false) {
      this.showImportModal = false;
      setTimeout(
        await ClientesService.listarImportacoesClientesBeneficioSimplificada({
          body: {
            organizationUnitId: uo,
            status: 2,
            inicio: this.dateISOFormat(
              this.dateEnFormat() + " 00:00:00 +0000",
              "YYYY-MM-DD HH:mm:ss Z"
            ),
            fim: this.dateISOFormat(
              this.dateEnFormat() + " 23:59:59 +0000",
              "YYYY-MM-DD HH:mm:ss Z"
            ),
          },
        }).then((response) => {
          const { result } = response;
          this.processingFiles =
            result?.items.length > 0 ? result?.items : null;
        }),
        2000
      );
      if (reloadHistory) {
        await this.searchHistory({
          isValid: true,
          values: {
            partnerAutoCompleteField: this.findObjectFromArray(
              this.tesouraryAccounts,
              "organizationUnitId",
              uo
            ),
          },
        });
      }
    },
    async searchHistory(fields = null, page = 1) {
      if ((fields && fields?.isValid) || !fields) {
        this.loadingTable = true;
        if (fields) {
          const {
            uoAutoCompleteField,
            statusSelectField,
            initialDateJazzDateField,
            finalDateJazzDateField,
          } = fields.values;
          this.filters = {
            organizationUnitId:
              this.convertArrayObjectFilter(
                uoAutoCompleteField,
                "organizationUnitId"
              ) || null,
            status:
              this.convertArrayObjectFilter(statusSelectField, "value") || null,
            inicio: this.dateISOFormat(
              initialDateJazzDateField + " 00:00:00 +0000",
              "YYYY-MM-DD HH:mm:ss Z"
            ),
            fim: this.dateISOFormat(
              finalDateJazzDateField + " 23:59:59 +0000",
              "YYYY-MM-DD HH:mm:ss Z"
            ),
          };
        }
        const result =
          await ClientesService.listarImportacoesClientesBeneficioSimplificada({
            body: {
              ...this.filters,
              porPagina: 15,
              pagina: page,
            },
          }).then((response) => response?.result || []);

        await this.searchFiles(this.filters?.organizationUnitId, false);

        this.history = result?.items;
        this.pagination.page = result?.pagina;
        this.pagination.perPage = result?.qtdPorPagina;
        this.pagination.total = result?.totalItems;
        this.pagination.pages = result?.qtdPaginas;
        this.loadingTable = false;
      }
    },
    createForm() {
      this.fields = {
        uoAutoCompleteField: {
          field: "JazzAutoCompleteField",
          label: "Empresa",
          cols: 6,
          items: [
            { organizationUnitId: null, nome: "Selecione" },
            ...this.tesouraryAccounts,
          ],
          valueField: null,
          keyName: "nome",
          keyValue: "organizationUnitId",
        },
        statusSelectField: {
          field: "SelectField",
          label: "Status",
          cols: 6,
          items: [
            { name: "Selecione", value: null },
            { name: "Importado", value: 1 },
            { name: "Em Processamento", value: 2 },
            { name: "Processado", value: 4 },
          ],
          valueField: null,
          keyName: "name",
          keyValue: "value",
        },
        initialDateJazzDateField: {
          field: "JazzDateField",
          label: "Data Inicial",
          cols: 5,
          valueField: this.dateSubtract30Days(),
          rules: [
            (fields) =>
              !this.compareDateAfterAndCurrent(
                fields.initialDateJazzDateField.valueField,
                fields.finalDateJazzDateField.valueField
              ) || "Data inicial deve ser menor que data final",
          ],
          appendIcon: "mdi-calendar",
        },
        finalDateJazzDateField: {
          field: "JazzDateField",
          label: "Data Final",
          cols: 5,
          valueField: this.dateEnFormat(),
          rules: [
            (fields) =>
              !this.compareDateAfterAndCurrent(
                fields.initialDateJazzDateField.valueField,
                fields.finalDateJazzDateField.valueField
              ) || "Data final deve ser maior que data inicial",
          ],
          appendIcon: "mdi-calendar",
        },
      };
      if (this.tesouraryAccounts?.length === 1) {
        this.fields.uoAutoCompleteField.valueField = this.tesouraryAccounts[0];
      }
    },
    async downloadFile({ id, nome, uo }) {
      this.CHANGE_LOADING(true);
      await ClientesService.baixarRetornoImportacaoBeneficioSimplificada({
        body: {
          importacaoId: id,
          organizationUnitId: uo,
        },
      })
        .then((response) => {
          if (response.result?.arquivo) {
            let csvContent =
              "data:text/csv;charset=utf-8," +
              encodeURI(response.result.arquivo);
            this.downloadCsvString(csvContent, "resultado_" + nome);
            this.$store.dispatch(
              "action_changeMessageSnackBar",
              "Arquivo baixado com sucesso!",
              { module: "template" }
            );
          }
        })
        .finally(() => {
          this.CHANGE_LOADING(false);
        });
    },
    async getTesouraryAccounts() {
      this.tesouraryAccounts =
        await TesourariasService.consultarContaTesouraria().then(
          (response) => response?.result.items || []
        );
    },
  },
};
</script>

<style lang="scss" scoped>
.benefit-registration {
  padding: 0 15px 0 15px;
}
</style>
