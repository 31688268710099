/** Generate by swagger-axios-codegen */
// @ts-nocheck
/* eslint-disable */
// Add default options
export const serviceOptions = {};
// Instance selector
export function axios(configs, resolve, reject) {
    if (serviceOptions.axios) {
        return serviceOptions.axios
            .request(configs)
            .then(res => {
            resolve(res.data);
        })
            .catch(err => {
            reject(err);
        });
    }
    else {
        throw new Error('please inject yourself instance like axios  ');
    }
}
export function getConfigs(method, contentType, url, options) {
    const configs = Object.assign(Object.assign({}, options), { method, url });
    configs.headers = Object.assign(Object.assign({}, options.headers), { 'Content-Type': contentType });
    return configs;
}
export const basePath = '';
export class ListResultDto {
}
export class PagedResultDto {
}
// customer definition
// empty
export class ClientesTravelService {
    /**
     *
     */
    static cadastrar(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/travel/cliente/cadastrar';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static cadastrar1(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/travel/backoffice/cliente/cadastrar';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static cadastrarConta(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/travel/backoffice/cliente/cadastrar-conta';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static obterDados(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/travel/cliente/obter-dados';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static obterDados1(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/travel/backoffice/cliente/obter-dados';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static consultar(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/travel/cliente/consultar';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static consultarDisponiveis(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/travel/backoffice/cliente/consultar-disponiveis';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static novaSenha(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/travel/backoffice/cliente/nova-senha';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static listarClientesPendentesConta(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/travel/backoffice/cliente/listar-clientes-pendentes-conta';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static filtrar(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/travel/backoffice/cliente/filtrar';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static alterarDadosContato(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/travel/backoffice/cliente/alterar-dados-contato';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static alterarLimites(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/travel/backoffice/cliente/alterar-limites';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
}
export class ContratosService {
    /**
     *
     */
    static cadastrar(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/travel/backoffice/contrato/cadastrar';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static consultarPorCliente(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/travel/contrato/consultar-por-cliente';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static consultarPorCliente1(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/travel/backoffice/contrato/consultar-por-cliente';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static obterDados(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/travel/contrato/obter-dados';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
}
export class FaturamentosService {
    /**
     *
     */
    static incluirTaxaLote(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/travel/debug/faturamento/incluir-taxa-lote';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static taxarPedido(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/travel/debug/faturamento/taxar-pedido';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static consultarTaxasLote(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/travel/debug/faturamento/consultar-taxas-lote';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static retirarTaxaLote(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/travel/debug/faturamento/retirar-taxa-lote';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static faturarLote(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/travel/debug/faturamento/faturar-lote';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static obterBoletoLoteFaturado(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/travel/faturamento/obter-boleto-lote-faturado';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
}
export class LotesService {
    /**
     *
     */
    static consultar(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/travel/lote/consultar';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static consultar1(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/travel/backoffice/lote/consultar';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
}
export class PedidosCartaoService {
    /**
     *
     */
    static cadastrar(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/travel/pedido-cartao/cadastrar';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static consultarPorCliente(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/travel/pedido-cartao/consultar-por-cliente';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static consultarPorCliente1(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/travel/backoffice/pedido-cartao/consultar-por-cliente';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static consultarCartoes(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/travel/backoffice/pedido-cartao/consultar-cartoes';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static gerarRelatorioCartoes(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/travel/backoffice/pedido-cartao/gerar-relatorio-cartoes';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static obterDadosSemMascara(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/travel/backoffice/pedido-cartao/obter-dados-sem-mascara';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static atualizarDadosPedidoConsulta(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/travel/backoffice/pedido-cartao/atualizar-dados-pedido-consulta';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static buscarHistoricoDeConsulta(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/travel/backoffice/pedido-cartao/buscar-historico-de-consulta';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static finalizar(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/travel/backoffice/pedido-cartao/finalizar';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static obterDetalhesCancelamento(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/travel/backoffice/pedido-cartao/obter-detalhes-cancelamento';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static consultarCartoes1(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/travel/pedido-cartao/consultar-cartoes';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static obterDados(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/travel/pedido-cartao/obter-dados';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static ajustarSaldo(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/travel/pedido-cartao/ajustar-saldo';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static finalizar1(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/travel/pedido-cartao/finalizar';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static cadastrar1(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/travel/pedido-cartao/campos-gerenciais/cadastrar';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static obterDados1(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/travel/pedido-cartao/campos-gerenciais/obter-dados';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
}
export class TransacoesService {
    /**
     *
     */
    static consultar(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/travel/transacoes/consultar';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static gerarRelatorioTransacoesPorCartao(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/travel/transacoes/gerar-relatorio-transacoes-por-cartao';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static consultarPorCliente(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/travel/backoffice/transacoes/consultar-por-cliente';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static gerarRelatorioPorCliente(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/travel/backoffice/transacoes/gerar-relatorio-por-cliente';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static gerarRelatorioValorTotalPorCliente(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/travel/backoffice/transacoes/gerar-relatorio-valor-total-por-cliente';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static gerarRelatorioPorLote(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/travel/backoffice/transacoes/gerar-relatorio-por-lote';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static negadas(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/travel/transacoes/negadas';
            const configs = getConfigs('get', 'application/json', url, options);
            configs.params = {
                chaveDePedidoCartao: params['chaveDePedidoCartao'],
                dataInicial: params['dataInicial'],
                dataFinal: params['dataFinal'],
                pagina: params['pagina'],
                porPagina: params['porPagina']
            };
            let data = null;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
}
export class TravelUtilidadesService {
    /**
     *
     */
    static cadastrarIntegrador(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/utilidades/debug/cadastrar-integrador';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static listarIntegradoresDisponiveis(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/travel/backoffice/listar-integradores-disponiveis';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static buscarPedidoLotePorCartaoProcessadora(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/utilidades/debug/buscar-pedido-lote-por-cartao-processadora';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
}
export class AddInputDto {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
    }
}
export class AddOutputDto {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
    }
}
export class Arquivo {
    constructor(data = {}) {
        this['base64'] = data['base64'];
        this['extensao'] = data['extensao'];
        this['url'] = data['url'];
    }
}
export class ConsultaClientes {
    constructor(data = {}) {
        this['chaveDeCliente'] = data['chaveDeCliente'];
        this['razaoSocial'] = data['razaoSocial'];
        this['identificacaoFiscal'] = data['identificacaoFiscal'];
        this['limiteMaximoDeCredito'] = data['limiteMaximoDeCredito'];
        this['limiteDisponivelDeCredito'] = data['limiteDisponivelDeCredito'];
        this['modoFaturamento'] = data['modoFaturamento'];
        this['saldo'] = data['saldo'];
    }
}
export class ConsultaClientesAddOutputPaginated {
    constructor(data = {}) {
        this['items'] = data['items'];
        this['qtdPaginas'] = data['qtdPaginas'];
        this['qtdPorPagina'] = data['qtdPorPagina'];
        this['totalItems'] = data['totalItems'];
        this['pagina'] = data['pagina'];
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
    }
}
export class ConsultaClientesDisponiveis {
    constructor(data = {}) {
        this['chaveDeCliente'] = data['chaveDeCliente'];
        this['razaoSocial'] = data['razaoSocial'];
        this['identificacaoFiscal'] = data['identificacaoFiscal'];
        this['limiteMaximoDeCredito'] = data['limiteMaximoDeCredito'];
        this['limiteDisponivelDeCredito'] = data['limiteDisponivelDeCredito'];
    }
}
export class ContaBancariaDto {
    constructor(data = {}) {
        this['banco'] = data['banco'];
        this['agencia'] = data['agencia'];
        this['numero'] = data['numero'];
    }
}
export class ContratosClienteBackoffice {
    constructor(data = {}) {
        this['integrador'] = data['integrador'];
        this['chaveDeContrato'] = data['chaveDeContrato'];
        this['modalidadeCiclo'] = data['modalidadeCiclo'];
        this['diaReferencia'] = data['diaReferencia'];
        this['contaCorrenteBoleto'] = data['contaCorrenteBoleto'];
        this['tipoCredito'] = data['tipoCredito'];
        this['rav'] = data['rav'];
        this['ativo'] = data['ativo'];
        this['dataCriacao'] = data['dataCriacao'];
        this['dataDesativacao'] = data['dataDesativacao'];
    }
}
export class DadosTaxa {
    constructor(data = {}) {
        this['chaveDeTaxa'] = data['chaveDeTaxa'];
        this['valor'] = data['valor'];
        this['descricao'] = data['descricao'];
        this['chaveDePedido'] = data['chaveDePedido'];
        this['dataOcorrencia'] = data['dataOcorrencia'];
    }
}
export class EnderecoDto {
    constructor(data = {}) {
        this['rua'] = data['rua'];
        this['numero'] = data['numero'];
        this['complemento'] = data['complemento'];
        this['bairro'] = data['bairro'];
        this['cep'] = data['cep'];
        this['cidade'] = data['cidade'];
        this['uf'] = data['uf'];
    }
}
export class InAjustarSaldoPedido {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDePedidoCartao'] = data['chaveDePedidoCartao'];
        this['senha'] = data['senha'];
        this['tipoTransacao'] = data['tipoTransacao'];
        this['valor'] = data['valor'];
    }
}
export class InAlterarDadosContatoCliente {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeCliente'] = data['chaveDeCliente'];
        this['emailAutenticacao'] = data['emailAutenticacao'];
        this['nomeComercial'] = data['nomeComercial'];
        this['emailComercial'] = data['emailComercial'];
        this['telefoneFixoComercial'] = data['telefoneFixoComercial'];
        this['telefoneMovelComercial'] = data['telefoneMovelComercial'];
        this['nomeFaturamento'] = data['nomeFaturamento'];
        this['emailFaturamento'] = data['emailFaturamento'];
        this['telefoneFixoFaturamento'] = data['telefoneFixoFaturamento'];
        this['telefoneMovelFaturamento'] = data['telefoneMovelFaturamento'];
        this['nomeFaturamentoAdicional'] = data['nomeFaturamentoAdicional'];
        this['emailFaturamentoAdicional'] = data['emailFaturamentoAdicional'];
        this['telefoneFixoFaturamentoAdicional'] = data['telefoneFixoFaturamentoAdicional'];
        this['telefoneMovelFaturamentoAdicional'] = data['telefoneMovelFaturamentoAdicional'];
        this['motivo'] = data['motivo'];
    }
}
export class InAlterarLimitesCliente {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeCliente'] = data['chaveDeCliente'];
        this['limiteMaximoDeCredito'] = data['limiteMaximoDeCredito'];
        this['limiteDisponivelDeCredito'] = data['limiteDisponivelDeCredito'];
        this['observacao'] = data['observacao'];
    }
}
export class InAtualizarPedidoConsulta {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['idConsulta'] = data['idConsulta'];
        this['imagemBaixada'] = data['imagemBaixada'];
    }
}
export class InBuscarHistoricoPedidoConsulta {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDePedidoCartao'] = data['chaveDePedidoCartao'];
        this['pagina'] = data['pagina'];
        this['porPagina'] = data['porPagina'];
    }
}
export class InBuscarPedidoLotePorCartaoProcessadora {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['contaProcessadoraId'] = data['contaProcessadoraId'];
        this['cartaoProcessadoraId'] = data['cartaoProcessadoraId'];
    }
}
export class InCadastrarCamposGerenciais {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDePedidoCartao'] = data['chaveDePedidoCartao'];
        this['numeroDePedido'] = data['numeroDePedido'];
        this['centroDeCusto'] = data['centroDeCusto'];
        this['aprovador'] = data['aprovador'];
        this['solicitante'] = data['solicitante'];
        this['checkIn'] = data['checkIn'];
        this['checkOut'] = data['checkOut'];
        this['trechoDeIda'] = data['trechoDeIda'];
        this['trechoDeVolta'] = data['trechoDeVolta'];
        this['localizador'] = data['localizador'];
        this['numeroDoTicket'] = data['numeroDoTicket'];
        this['cpf'] = data['cpf'];
        this['descricaoCentroDeCusto'] = data['descricaoCentroDeCusto'];
    }
}
export class InCadastrarClienteTravel {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['razaoSocial'] = data['razaoSocial'];
        this['nomeFantasia'] = data['nomeFantasia'];
        this['identificacaoFiscal'] = data['identificacaoFiscal'];
        this['naturezaJuridica'] = data['naturezaJuridica'];
        this['porte'] = data['porte'];
        this['grupo'] = data['grupo'];
        this['cnae'] = data['cnae'];
        this['cnaeDescricao'] = data['cnaeDescricao'];
        this['capitalSocial'] = data['capitalSocial'];
        this['origemCapitalSocial'] = data['origemCapitalSocial'];
        this['dataInicioAtividade'] = data['dataInicioAtividade'];
        this['endereco'] = data['endereco'];
        this['nomeComercial'] = data['nomeComercial'];
        this['emailComercial'] = data['emailComercial'];
        this['telefoneFixoComercial'] = data['telefoneFixoComercial'];
        this['telefoneMovelComercial'] = data['telefoneMovelComercial'];
        this['nomeFaturamento'] = data['nomeFaturamento'];
        this['emailFaturamento'] = data['emailFaturamento'];
        this['telefoneFixoFaturamento'] = data['telefoneFixoFaturamento'];
        this['telefoneMovelFaturamento'] = data['telefoneMovelFaturamento'];
        this['nomeFaturamentoAdicional'] = data['nomeFaturamentoAdicional'];
        this['emailFaturamentoAdicional'] = data['emailFaturamentoAdicional'];
        this['telefoneFixoFaturamentoAdicional'] = data['telefoneFixoFaturamentoAdicional'];
        this['telefoneMovelFaturamentoAdicional'] = data['telefoneMovelFaturamentoAdicional'];
    }
}
export class InCadastrarClienteTravelBackoffice {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['razaoSocial'] = data['razaoSocial'];
        this['nomeFantasia'] = data['nomeFantasia'];
        this['identificacaoFiscal'] = data['identificacaoFiscal'];
        this['naturezaJuridica'] = data['naturezaJuridica'];
        this['porte'] = data['porte'];
        this['grupo'] = data['grupo'];
        this['cnae'] = data['cnae'];
        this['cnaeDescricao'] = data['cnaeDescricao'];
        this['capitalSocial'] = data['capitalSocial'];
        this['origemCapitalSocial'] = data['origemCapitalSocial'];
        this['dataInicioAtividade'] = data['dataInicioAtividade'];
        this['endereco'] = data['endereco'];
        this['nomeComercial'] = data['nomeComercial'];
        this['emailComercial'] = data['emailComercial'];
        this['telefoneFixoComercial'] = data['telefoneFixoComercial'];
        this['telefoneMovelComercial'] = data['telefoneMovelComercial'];
        this['nomeFaturamento'] = data['nomeFaturamento'];
        this['emailFaturamento'] = data['emailFaturamento'];
        this['telefoneFixoFaturamento'] = data['telefoneFixoFaturamento'];
        this['telefoneMovelFaturamento'] = data['telefoneMovelFaturamento'];
        this['nomeFaturamentoAdicional'] = data['nomeFaturamentoAdicional'];
        this['emailFaturamentoAdicional'] = data['emailFaturamentoAdicional'];
        this['telefoneFixoFaturamentoAdicional'] = data['telefoneFixoFaturamentoAdicional'];
        this['telefoneMovelFaturamentoAdicional'] = data['telefoneMovelFaturamentoAdicional'];
        this['organizationUnitId'] = data['organizationUnitId'];
    }
}
export class InCadastrarContaClienteTravel {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeCliente'] = data['chaveDeCliente'];
        this['razaoSocial'] = data['razaoSocial'];
        this['nomeFantasia'] = data['nomeFantasia'];
        this['identificacaoFiscal'] = data['identificacaoFiscal'];
        this['naturezaJuridica'] = data['naturezaJuridica'];
        this['porte'] = data['porte'];
        this['grupo'] = data['grupo'];
        this['cnae'] = data['cnae'];
        this['cnaeDescricao'] = data['cnaeDescricao'];
        this['capitalSocial'] = data['capitalSocial'];
        this['origemCapitalSocial'] = data['origemCapitalSocial'];
        this['dataInicioAtividade'] = data['dataInicioAtividade'];
        this['endereco'] = data['endereco'];
        this['limiteDeCredito'] = data['limiteDeCredito'];
        this['contaCorrente'] = data['contaCorrente'];
        this['contaPagamento'] = data['contaPagamento'];
        this['emailAutenticacao'] = data['emailAutenticacao'];
        this['nomeComercial'] = data['nomeComercial'];
        this['emailComercial'] = data['emailComercial'];
        this['telefoneFixoComercial'] = data['telefoneFixoComercial'];
        this['telefoneMovelComercial'] = data['telefoneMovelComercial'];
        this['nomeFaturamento'] = data['nomeFaturamento'];
        this['emailFaturamento'] = data['emailFaturamento'];
        this['telefoneFixoFaturamento'] = data['telefoneFixoFaturamento'];
        this['telefoneMovelFaturamento'] = data['telefoneMovelFaturamento'];
        this['nomeFaturamentoAdicional'] = data['nomeFaturamentoAdicional'];
        this['emailFaturamentoAdicional'] = data['emailFaturamentoAdicional'];
        this['telefoneFixoFaturamentoAdicional'] = data['telefoneFixoFaturamentoAdicional'];
        this['telefoneMovelFaturamentoAdicional'] = data['telefoneMovelFaturamentoAdicional'];
        this['modoFaturamento'] = data['modoFaturamento'];
        this['modalidadeCiclo'] = data['modalidadeCiclo'];
        this['diaReferencia'] = data['diaReferencia'];
        this['cobrancaRav'] = data['cobrancaRav'];
    }
}
export class InCadastrarContrato {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeCliente'] = data['chaveDeCliente'];
        this['modalidadeCiclo'] = data['modalidadeCiclo'];
        this['diaReferencia'] = data['diaReferencia'];
        this['descricao'] = data['descricao'];
        this['cobrancaRav'] = data['cobrancaRav'];
    }
}
export class InCadastrarIntegrador {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['nome'] = data['nome'];
        this['identificacaoFiscal'] = data['identificacaoFiscal'];
    }
}
export class InCadastrarPedidoCartao {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeCliente'] = data['chaveDeCliente'];
        this['consultor'] = data['consultor'];
        this['descricao'] = data['descricao'];
        this['valor'] = data['valor'];
        this['identificadorExterno'] = data['identificadorExterno'];
        this['inicioVigencia'] = data['inicioVigencia'];
        this['fimVigencia'] = data['fimVigencia'];
        this['senha'] = data['senha'];
        this['nomeImpresso'] = data['nomeImpresso'];
    }
}
export class InConsultarCamposGerenciais {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDePedidoCartao'] = data['chaveDePedidoCartao'];
    }
}
export class InConsultarCartoes {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeCliente'] = data['chaveDeCliente'];
        this['dataInicio'] = data['dataInicio'];
        this['dataFim'] = data['dataFim'];
        this['status'] = data['status'];
        this['nomeImpresso'] = data['nomeImpresso'];
        this['ultimos4Digitos'] = data['ultimos4Digitos'];
        this['tipoOrdenacao'] = data['tipoOrdenacao'];
        this['pagina'] = data['pagina'];
        this['porPagina'] = data['porPagina'];
    }
}
export class InConsultarCartoesBackoffice {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeCliente'] = data['chaveDeCliente'];
        this['dataInicio'] = data['dataInicio'];
        this['dataFim'] = data['dataFim'];
        this['status'] = data['status'];
        this['nomeImpresso'] = data['nomeImpresso'];
        this['ultimos4Digitos'] = data['ultimos4Digitos'];
        this['pagina'] = data['pagina'];
        this['porPagina'] = data['porPagina'];
    }
}
export class InConsultarClientesTravel {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['pagina'] = data['pagina'];
        this['porPagina'] = data['porPagina'];
    }
}
export class InConsultarContrato {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeContrato'] = data['chaveDeContrato'];
    }
}
export class InConsultarLotes {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeContrato'] = data['chaveDeContrato'];
        this['chaveDeCliente'] = data['chaveDeCliente'];
        this['statusLote'] = data['statusLote'];
        this['dataInicial'] = data['dataInicial'];
        this['dataFinal'] = data['dataFinal'];
        this['pagina'] = data['pagina'];
        this['porPagina'] = data['porPagina'];
    }
}
export class InConsultarLotesBackoffice {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeContrato'] = data['chaveDeContrato'];
        this['chaveDeCliente'] = data['chaveDeCliente'];
        this['statusLote'] = data['statusLote'];
        this['dataInicial'] = data['dataInicial'];
        this['dataFinal'] = data['dataFinal'];
        this['pagina'] = data['pagina'];
        this['porPagina'] = data['porPagina'];
    }
}
export class InConsultarPedidoCartao {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDePedidoCartao'] = data['chaveDePedidoCartao'];
        this['identificadorExterno'] = data['identificadorExterno'];
    }
}
export class InConsultarPedidoCartaoSemMascara {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDePedidoCartao'] = data['chaveDePedidoCartao'];
        this['senha'] = data['senha'];
        this['enderecoIP'] = data['enderecoIP'];
    }
}
export class InConsultarTaxas {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeLote'] = data['chaveDeLote'];
    }
}
export class InFaturarLote {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeLote'] = data['chaveDeLote'];
        this['gerarPdfBoleto'] = data['gerarPdfBoleto'];
    }
}
export class InFinalizarPedidoCartao {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDePedidoCartao'] = data['chaveDePedidoCartao'];
    }
}
export class InFinalizarPedidoCartaoViaBackoffice {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDePedidoCartao'] = data['chaveDePedidoCartao'];
        this['senha'] = data['senha'];
        this['enderecoIP'] = data['enderecoIP'];
    }
}
export class InGerarNovaSenhaClienteTravel {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeCliente'] = data['chaveDeCliente'];
        this['observacao'] = data['observacao'];
    }
}
export class InGerarRelatorioPorLote {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeLote'] = data['chaveDeLote'];
    }
}
export class InListarClientesPendenteDeConta {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['identificacaoFiscal'] = data['identificacaoFiscal'];
        this['nome'] = data['nome'];
        this['organizationUnitId'] = data['organizationUnitId'];
        this['pagina'] = data['pagina'];
        this['porPagina'] = data['porPagina'];
    }
}
export class InListarContratosCliente {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeCliente'] = data['chaveDeCliente'];
        this['modalidadeCiclo'] = data['modalidadeCiclo'];
        this['ativo'] = data['ativo'];
        this['pagina'] = data['pagina'];
        this['porPagina'] = data['porPagina'];
    }
}
export class InListarContratosClienteBackoffice {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeCliente'] = data['chaveDeCliente'];
        this['ativo'] = data['ativo'];
    }
}
export class InListarPedidosCartaoCliente {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeCliente'] = data['chaveDeCliente'];
        this['dataInicio'] = data['dataInicio'];
        this['dataFim'] = data['dataFim'];
        this['tipoOrdenacao'] = data['tipoOrdenacao'];
        this['pagina'] = data['pagina'];
        this['porPagina'] = data['porPagina'];
    }
}
export class InListarTransacoes {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeLote'] = data['chaveDeLote'];
        this['chaveDePedidoCartao'] = data['chaveDePedidoCartao'];
        this['tipoTransacao'] = data['tipoTransacao'];
        this['dataInicio'] = data['dataInicio'];
        this['dataFim'] = data['dataFim'];
        this['tipoOrdenacao'] = data['tipoOrdenacao'];
        this['pagina'] = data['pagina'];
        this['porPagina'] = data['porPagina'];
    }
}
export class InListarTransacoesPorCliente {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeCliente'] = data['chaveDeCliente'];
        this['dataInicio'] = data['dataInicio'];
        this['dataFim'] = data['dataFim'];
        this['tipoOrdenacao'] = data['tipoOrdenacao'];
        this['pagina'] = data['pagina'];
        this['porPagina'] = data['porPagina'];
    }
}
export class InObterDadosClienteTravel {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeCliente'] = data['chaveDeCliente'];
    }
}
export class InObterDetalhesCancelamentoCartao {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDePedidoCartao'] = data['chaveDePedidoCartao'];
    }
}
export class InRelatorioTransacoes {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeCliente'] = data['chaveDeCliente'];
        this['dataInicio'] = data['dataInicio'];
        this['dataFim'] = data['dataFim'];
    }
}
export class InRetirarTaxaLote {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeLote'] = data['chaveDeLote'];
        this['chaveDeTaxa'] = data['chaveDeTaxa'];
    }
}
export class InTaxarLote {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeLote'] = data['chaveDeLote'];
        this['valor'] = data['valor'];
        this['descricao'] = data['descricao'];
    }
}
export class InTaxarPedido {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeLote'] = data['chaveDeLote'];
        this['valor'] = data['valor'];
        this['descricao'] = data['descricao'];
        this['chaveDePedido'] = data['chaveDePedido'];
        this['dataOcorrencia'] = data['dataOcorrencia'];
    }
}
export class Metadado {
    constructor(data = {}) {
        this['tipoMetadado'] = data['tipoMetadado'];
        this['valor'] = data['valor'];
    }
}
export class Operation {
    constructor(data = {}) {
        this['op'] = data['op'];
        this['value'] = data['value'];
        this['path'] = data['path'];
    }
}
export class OutBuscarHistoricoPedidoConsulta {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['responsavelCriacao'] = data['responsavelCriacao'];
        this['userId'] = data['userId'];
        this['chaveDePedido'] = data['chaveDePedido'];
        this['quantidadeTentativasLogin'] = data['quantidadeTentativasLogin'];
        this['dataConsulta'] = data['dataConsulta'];
        this['enderecoIP'] = data['enderecoIP'];
        this['sucessoConsulta'] = data['sucessoConsulta'];
        this['imagemBaixada'] = data['imagemBaixada'];
    }
}
export class OutBuscarHistoricoPedidoConsultaAddOutputPaginated {
    constructor(data = {}) {
        this['items'] = data['items'];
        this['qtdPaginas'] = data['qtdPaginas'];
        this['qtdPorPagina'] = data['qtdPorPagina'];
        this['totalItems'] = data['totalItems'];
        this['pagina'] = data['pagina'];
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
    }
}
export class OutBuscarPedidoLotePorCartaoProcessadora {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['pedidoId'] = data['pedidoId'];
        this['loteId'] = data['loteId'];
        this['modoFaturamento'] = data['modoFaturamento'];
        this['chavedeLimite'] = data['chavedeLimite'];
    }
}
export class OutCadastrarClienteTravel {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['chaveDeCliente'] = data['chaveDeCliente'];
    }
}
export class OutCadastrarContrato {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['chaveDeContrato'] = data['chaveDeContrato'];
    }
}
export class OutCadastrarIntegrador {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['api_Key'] = data['api_Key'];
        this['api_Secret'] = data['api_Secret'];
    }
}
export class OutCadastrarPedidoCartao {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['chaveDePedidoCartao'] = data['chaveDePedidoCartao'];
        this['numeroCartao'] = data['numeroCartao'];
        this['dataExpiracaoCartao'] = data['dataExpiracaoCartao'];
        this['cvv'] = data['cvv'];
        this['nomeImpresso'] = data['nomeImpresso'];
        this['idPedido'] = data['idPedido'];
    }
}
export class OutConsultarCamposGerenciais {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['numeroDePedido'] = data['numeroDePedido'];
        this['centroDeCusto'] = data['centroDeCusto'];
        this['aprovador'] = data['aprovador'];
        this['solicitante'] = data['solicitante'];
        this['checkIn'] = data['checkIn'];
        this['checkOut'] = data['checkOut'];
        this['trechoDeIda'] = data['trechoDeIda'];
        this['trechoDeVolta'] = data['trechoDeVolta'];
        this['localizador'] = data['localizador'];
        this['numeroDoTicket'] = data['numeroDoTicket'];
        this['cpf'] = data['cpf'];
        this['descricaoCentroDeCusto'] = data['descricaoCentroDeCusto'];
    }
}
export class OutConsultarCartoes {
    constructor(data = {}) {
        this['dataCriacao'] = data['dataCriacao'];
        this['responsavelCriacao'] = data['responsavelCriacao'];
        this['saldoTotal'] = data['saldoTotal'];
        this['saldoDisponivel'] = data['saldoDisponivel'];
        this['nomeImpresso'] = data['nomeImpresso'];
        this['numeroCartao'] = data['numeroCartao'];
        this['statusPedido'] = data['statusPedido'];
        this['statusPedidoDescricao'] = data['statusPedidoDescricao'];
        this['chaveDePedidoCartao'] = data['chaveDePedidoCartao'];
    }
}
export class OutConsultarCartoesAddOutputPaginated {
    constructor(data = {}) {
        this['items'] = data['items'];
        this['qtdPaginas'] = data['qtdPaginas'];
        this['qtdPorPagina'] = data['qtdPorPagina'];
        this['totalItems'] = data['totalItems'];
        this['pagina'] = data['pagina'];
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
    }
}
export class OutConsultarCartoesBackoffice {
    constructor(data = {}) {
        this['responsavelCriacao'] = data['responsavelCriacao'];
        this['dataCriacao'] = data['dataCriacao'];
        this['saldoTotal'] = data['saldoTotal'];
        this['saldoDisponivel'] = data['saldoDisponivel'];
        this['nomeImpresso'] = data['nomeImpresso'];
        this['numeroCartao'] = data['numeroCartao'];
        this['statusPedido'] = data['statusPedido'];
        this['responsavelId'] = data['responsavelId'];
        this['idPedido'] = data['idPedido'];
        this['chaveDePedidoCartao'] = data['chaveDePedidoCartao'];
        this['idUltimoUsuario'] = data['idUltimoUsuario'];
        this['statusPedidoDescricao'] = data['statusPedidoDescricao'];
    }
}
export class OutConsultarCartoesBackofficeAddOutputPaginated {
    constructor(data = {}) {
        this['items'] = data['items'];
        this['qtdPaginas'] = data['qtdPaginas'];
        this['qtdPorPagina'] = data['qtdPorPagina'];
        this['totalItems'] = data['totalItems'];
        this['pagina'] = data['pagina'];
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
    }
}
export class OutConsultarClientesDisponiveis {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['clientes'] = data['clientes'];
    }
}
export class OutConsultarClientesTravelBackoffice {
    constructor(data = {}) {
        this['chaveDeCliente'] = data['chaveDeCliente'];
        this['razaoSocial'] = data['razaoSocial'];
        this['identificacaoFiscal'] = data['identificacaoFiscal'];
        this['limiteMaximoDeCredito'] = data['limiteMaximoDeCredito'];
        this['limiteDisponivelDeCredito'] = data['limiteDisponivelDeCredito'];
        this['modoFaturamento'] = data['modoFaturamento'];
        this['saldo'] = data['saldo'];
        this['limiteAtivo'] = data['limiteAtivo'];
    }
}
export class OutConsultarClientesTravelBackofficeAddOutputPaginated {
    constructor(data = {}) {
        this['items'] = data['items'];
        this['qtdPaginas'] = data['qtdPaginas'];
        this['qtdPorPagina'] = data['qtdPorPagina'];
        this['totalItems'] = data['totalItems'];
        this['pagina'] = data['pagina'];
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
    }
}
export class OutConsultarContrato {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['chaveDeCliente'] = data['chaveDeCliente'];
        this['modalidadeCiclo'] = data['modalidadeCiclo'];
        this['diaReferencia'] = data['diaReferencia'];
        this['descricao'] = data['descricao'];
        this['ativo'] = data['ativo'];
    }
}
export class OutConsultarLotes {
    constructor(data = {}) {
        this['chaveDeLote'] = data['chaveDeLote'];
        this['statusLote'] = data['statusLote'];
        this['dataInicio'] = data['dataInicio'];
        this['dataFim'] = data['dataFim'];
        this['dataVencimento'] = data['dataVencimento'];
        this['dataEmissao'] = data['dataEmissao'];
        this['valorTotal'] = data['valorTotal'];
    }
}
export class OutConsultarLotesAddOutputPaginated {
    constructor(data = {}) {
        this['items'] = data['items'];
        this['qtdPaginas'] = data['qtdPaginas'];
        this['qtdPorPagina'] = data['qtdPorPagina'];
        this['totalItems'] = data['totalItems'];
        this['pagina'] = data['pagina'];
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
    }
}
export class OutConsultarPedidoCartao {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['chaveDeCliente'] = data['chaveDeCliente'];
        this['chaveDePedidoCartao'] = data['chaveDePedidoCartao'];
        this['statusPedido'] = data['statusPedido'];
        this['consultor'] = data['consultor'];
        this['descricao'] = data['descricao'];
        this['saldo'] = data['saldo'];
        this['identificadorExterno'] = data['identificadorExterno'];
        this['inicioVigencia'] = data['inicioVigencia'];
        this['fimVigencia'] = data['fimVigencia'];
        this['numeroCartao'] = data['numeroCartao'];
        this['dataExpiracaoCartao'] = data['dataExpiracaoCartao'];
        this['cvv'] = data['cvv'];
        this['nomeImpresso'] = data['nomeImpresso'];
        this['responsavelCriacao'] = data['responsavelCriacao'];
        this['valorPedido'] = data['valorPedido'];
        this['dataCriacao'] = data['dataCriacao'];
        this['valorOriginal'] = data['valorOriginal'];
        this['idConta'] = data['idConta'];
        this['idCartao'] = data['idCartao'];
        this['idUltimoUsuario'] = data['idUltimoUsuario'];
        this['statusPedidoDescricao'] = data['statusPedidoDescricao'];
    }
}
export class OutConsultarPedidoCartaoSemMascara {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['chaveDeCliente'] = data['chaveDeCliente'];
        this['chaveDePedidoCartao'] = data['chaveDePedidoCartao'];
        this['statusPedido'] = data['statusPedido'];
        this['consultor'] = data['consultor'];
        this['descricao'] = data['descricao'];
        this['saldo'] = data['saldo'];
        this['identificadorExterno'] = data['identificadorExterno'];
        this['inicioVigencia'] = data['inicioVigencia'];
        this['fimVigencia'] = data['fimVigencia'];
        this['numeroCartao'] = data['numeroCartao'];
        this['dataExpiracaoCartao'] = data['dataExpiracaoCartao'];
        this['cvv'] = data['cvv'];
        this['nomeImpresso'] = data['nomeImpresso'];
        this['responsavelCriacao'] = data['responsavelCriacao'];
        this['valorPedido'] = data['valorPedido'];
        this['dataCriacao'] = data['dataCriacao'];
        this['valorOriginal'] = data['valorOriginal'];
        this['idConsulta'] = data['idConsulta'];
    }
}
export class OutConsultarTaxas {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['taxas'] = data['taxas'];
    }
}
export class OutConsultarTransacoesNegadas {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['dataHoraEntrada'] = data['dataHoraEntrada'];
        this['nomeEstabelecimento'] = data['nomeEstabelecimento'];
        this['ultimos4DigitosCartao'] = data['ultimos4DigitosCartao'];
        this['valor'] = data['valor'];
        this['descricao'] = data['descricao'];
        this['codigoResposta'] = data['codigoResposta'];
        this['contaId'] = data['contaId'];
        this['cartaoId'] = data['cartaoId'];
    }
}
export class OutConsultarTransacoesNegadasAddOutputPaginated {
    constructor(data = {}) {
        this['items'] = data['items'];
        this['qtdPaginas'] = data['qtdPaginas'];
        this['qtdPorPagina'] = data['qtdPorPagina'];
        this['totalItems'] = data['totalItems'];
        this['pagina'] = data['pagina'];
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
    }
}
export class OutDownloadArquivoRetorno {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['arquivo'] = data['arquivo'];
    }
}
export class OutFaturarLote {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['chaveDeLote'] = data['chaveDeLote'];
        this['valorTotal'] = data['valorTotal'];
        this['dataEmissao'] = data['dataEmissao'];
        this['dataVencimento'] = data['dataVencimento'];
        this['status'] = data['status'];
        this['linhaDigitavel'] = data['linhaDigitavel'];
        this['codigoDeBarras'] = data['codigoDeBarras'];
        this['boleto'] = data['boleto'];
    }
}
export class OutIntegradores {
    constructor(data = {}) {
        this['nome'] = data['nome'];
        this['organizationUnitId'] = data['organizationUnitId'];
    }
}
export class OutListarClientesPendenteDeConta {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['chaveDeCliente'] = data['chaveDeCliente'];
        this['razaoSocial'] = data['razaoSocial'];
        this['nomeFantasia'] = data['nomeFantasia'];
        this['identificacaoFiscal'] = data['identificacaoFiscal'];
        this['naturezaJuridica'] = data['naturezaJuridica'];
        this['porte'] = data['porte'];
        this['grupo'] = data['grupo'];
        this['cnae'] = data['cnae'];
        this['cnaeDescricao'] = data['cnaeDescricao'];
        this['capitalSocial'] = data['capitalSocial'];
        this['origemCapitalSocial'] = data['origemCapitalSocial'];
        this['dataInicioAtividade'] = data['dataInicioAtividade'];
        this['endereco'] = data['endereco'];
        this['limiteMaximoDeCredito'] = data['limiteMaximoDeCredito'];
        this['limiteDisponivelDeCredito'] = data['limiteDisponivelDeCredito'];
        this['contaCorrente'] = data['contaCorrente'];
        this['contaPagamento'] = data['contaPagamento'];
        this['emailAutenticacao'] = data['emailAutenticacao'];
        this['nomeComercial'] = data['nomeComercial'];
        this['emailComercial'] = data['emailComercial'];
        this['telefoneFixoComercial'] = data['telefoneFixoComercial'];
        this['telefoneMovelComercial'] = data['telefoneMovelComercial'];
        this['nomeFaturamento'] = data['nomeFaturamento'];
        this['emailFaturamento'] = data['emailFaturamento'];
        this['telefoneFixoFaturamento'] = data['telefoneFixoFaturamento'];
        this['telefoneMovelFaturamento'] = data['telefoneMovelFaturamento'];
        this['nomeFaturamentoAdicional'] = data['nomeFaturamentoAdicional'];
        this['emailFaturamentoAdicional'] = data['emailFaturamentoAdicional'];
        this['telefoneFixoFaturamentoAdicional'] = data['telefoneFixoFaturamentoAdicional'];
        this['telefoneMovelFaturamentoAdicional'] = data['telefoneMovelFaturamentoAdicional'];
        this['modoFaturamento'] = data['modoFaturamento'];
        this['saldoDisponivelPrePago'] = data['saldoDisponivelPrePago'];
        this['saldoDisponivelAlocado'] = data['saldoDisponivelAlocado'];
        this['saldoDisponivelTotal'] = data['saldoDisponivelTotal'];
        this['saldoEmCartoes'] = data['saldoEmCartoes'];
        this['organizationUnitDescricao'] = data['organizationUnitDescricao'];
        this['organizationUnitId'] = data['organizationUnitId'];
    }
}
export class OutListarClientesPendenteDeContaAddOutputPaginated {
    constructor(data = {}) {
        this['items'] = data['items'];
        this['qtdPaginas'] = data['qtdPaginas'];
        this['qtdPorPagina'] = data['qtdPorPagina'];
        this['totalItems'] = data['totalItems'];
        this['pagina'] = data['pagina'];
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
    }
}
export class OutListarContratos {
    constructor(data = {}) {
        this['chaveDeCliente'] = data['chaveDeCliente'];
        this['chaveDeContrato'] = data['chaveDeContrato'];
        this['modalidadeCiclo'] = data['modalidadeCiclo'];
        this['diaReferencia'] = data['diaReferencia'];
        this['descricao'] = data['descricao'];
        this['ativo'] = data['ativo'];
    }
}
export class OutListarContratosAddOutputPaginated {
    constructor(data = {}) {
        this['items'] = data['items'];
        this['qtdPaginas'] = data['qtdPaginas'];
        this['qtdPorPagina'] = data['qtdPorPagina'];
        this['totalItems'] = data['totalItems'];
        this['pagina'] = data['pagina'];
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
    }
}
export class OutListarContratosClienteBackoffice {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['contratos'] = data['contratos'];
    }
}
export class OutListarIntegradores {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['integradores'] = data['integradores'];
    }
}
export class OutListarPedidosCartaoCliente {
    constructor(data = {}) {
        this['chaveDeCliente'] = data['chaveDeCliente'];
        this['chaveDePedidoCartao'] = data['chaveDePedidoCartao'];
        this['statusPedido'] = data['statusPedido'];
        this['consultor'] = data['consultor'];
        this['descricao'] = data['descricao'];
        this['inicioVigencia'] = data['inicioVigencia'];
        this['fimVigencia'] = data['fimVigencia'];
        this['identificadorExterno'] = data['identificadorExterno'];
    }
}
export class OutListarPedidosCartaoClienteAddOutputPaginated {
    constructor(data = {}) {
        this['items'] = data['items'];
        this['qtdPaginas'] = data['qtdPaginas'];
        this['qtdPorPagina'] = data['qtdPorPagina'];
        this['totalItems'] = data['totalItems'];
        this['pagina'] = data['pagina'];
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
    }
}
export class OutListarPedidosCartaoClienteBackoffice {
    constructor(data = {}) {
        this['chaveDeCliente'] = data['chaveDeCliente'];
        this['chaveDePedidoCartao'] = data['chaveDePedidoCartao'];
        this['statusPedido'] = data['statusPedido'];
        this['consultor'] = data['consultor'];
        this['descricao'] = data['descricao'];
        this['inicioVigencia'] = data['inicioVigencia'];
        this['fimVigencia'] = data['fimVigencia'];
        this['identificadorExterno'] = data['identificadorExterno'];
        this['idPedido'] = data['idPedido'];
        this['dataCriacao'] = data['dataCriacao'];
        this['valorPedido'] = data['valorPedido'];
        this['nomeImpresso'] = data['nomeImpresso'];
        this['numeroCartaoMascarado'] = data['numeroCartaoMascarado'];
    }
}
export class OutListarPedidosCartaoClienteBackofficeAddOutputPaginated {
    constructor(data = {}) {
        this['items'] = data['items'];
        this['qtdPaginas'] = data['qtdPaginas'];
        this['qtdPorPagina'] = data['qtdPorPagina'];
        this['totalItems'] = data['totalItems'];
        this['pagina'] = data['pagina'];
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
    }
}
export class OutListarTransacoes {
    constructor(data = {}) {
        this['transacaoTravelId'] = data['transacaoTravelId'];
        this['chaveDeCliente'] = data['chaveDeCliente'];
        this['nomeCliente'] = data['nomeCliente'];
        this['chaveDePedidoCartao'] = data['chaveDePedidoCartao'];
        this['descricaoPedido'] = data['descricaoPedido'];
        this['consultor'] = data['consultor'];
        this['tipoTransacao'] = data['tipoTransacao'];
        this['dataTransacao'] = data['dataTransacao'];
        this['nomeImpressoCartao'] = data['nomeImpressoCartao'];
        this['numeroCartao'] = data['numeroCartao'];
        this['nsu'] = data['nsu'];
        this['codigoAutorizacao'] = data['codigoAutorizacao'];
        this['identificadorTerminal'] = data['identificadorTerminal'];
        this['valor'] = data['valor'];
        this['codigoMoeda'] = data['codigoMoeda'];
        this['codigoMcc'] = data['codigoMcc'];
        this['descricaoMcc'] = data['descricaoMcc'];
        this['iof'] = data['iof'];
        this['nomeEstabelecimento'] = data['nomeEstabelecimento'];
        this['cidadeEstabelecimento'] = data['cidadeEstabelecimento'];
        this['paisEstabelecimento'] = data['paisEstabelecimento'];
        this['porcentagemTaxa'] = data['porcentagemTaxa'];
        this['valorTaxa'] = data['valorTaxa'];
        this['totalTaxa'] = data['totalTaxa'];
        this['exchangeRate'] = data['exchangeRate'];
        this['valorComTaxa'] = data['valorComTaxa'];
    }
}
export class OutListarTransacoesAddOutputPaginated {
    constructor(data = {}) {
        this['items'] = data['items'];
        this['qtdPaginas'] = data['qtdPaginas'];
        this['qtdPorPagina'] = data['qtdPorPagina'];
        this['totalItems'] = data['totalItems'];
        this['pagina'] = data['pagina'];
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
    }
}
export class OutListarTransacoesPorCliente {
    constructor(data = {}) {
        this['transacaoTravelId'] = data['transacaoTravelId'];
        this['chaveDeCliente'] = data['chaveDeCliente'];
        this['nomeCliente'] = data['nomeCliente'];
        this['chaveDePedidoCartao'] = data['chaveDePedidoCartao'];
        this['descricaoPedido'] = data['descricaoPedido'];
        this['consultor'] = data['consultor'];
        this['tipoTransacao'] = data['tipoTransacao'];
        this['dataTransacao'] = data['dataTransacao'];
        this['nomeImpressoCartao'] = data['nomeImpressoCartao'];
        this['numeroCartao'] = data['numeroCartao'];
        this['nsu'] = data['nsu'];
        this['codigoAutorizacao'] = data['codigoAutorizacao'];
        this['identificadorTerminal'] = data['identificadorTerminal'];
        this['valor'] = data['valor'];
        this['codigoMoeda'] = data['codigoMoeda'];
        this['codigoMcc'] = data['codigoMcc'];
        this['descricaoMcc'] = data['descricaoMcc'];
        this['iof'] = data['iof'];
        this['nomeEstabelecimento'] = data['nomeEstabelecimento'];
        this['cidadeEstabelecimento'] = data['cidadeEstabelecimento'];
        this['paisEstabelecimento'] = data['paisEstabelecimento'];
        this['porcentagemTaxa'] = data['porcentagemTaxa'];
        this['valorTaxa'] = data['valorTaxa'];
        this['totalTaxa'] = data['totalTaxa'];
        this['exchangeRate'] = data['exchangeRate'];
        this['valorComTaxa'] = data['valorComTaxa'];
        this['numeroCartaoMascarado'] = data['numeroCartaoMascarado'];
    }
}
export class OutListarTransacoesPorClienteAddOutputPaginated {
    constructor(data = {}) {
        this['items'] = data['items'];
        this['qtdPaginas'] = data['qtdPaginas'];
        this['qtdPorPagina'] = data['qtdPorPagina'];
        this['totalItems'] = data['totalItems'];
        this['pagina'] = data['pagina'];
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
    }
}
export class OutObterDadosClienteTravel {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['chaveDeCliente'] = data['chaveDeCliente'];
        this['razaoSocial'] = data['razaoSocial'];
        this['nomeFantasia'] = data['nomeFantasia'];
        this['identificacaoFiscal'] = data['identificacaoFiscal'];
        this['naturezaJuridica'] = data['naturezaJuridica'];
        this['porte'] = data['porte'];
        this['grupo'] = data['grupo'];
        this['cnae'] = data['cnae'];
        this['cnaeDescricao'] = data['cnaeDescricao'];
        this['capitalSocial'] = data['capitalSocial'];
        this['origemCapitalSocial'] = data['origemCapitalSocial'];
        this['dataInicioAtividade'] = data['dataInicioAtividade'];
        this['endereco'] = data['endereco'];
        this['limiteMaximoDeCredito'] = data['limiteMaximoDeCredito'];
        this['limiteDisponivelDeCredito'] = data['limiteDisponivelDeCredito'];
        this['contaCorrente'] = data['contaCorrente'];
        this['contaPagamento'] = data['contaPagamento'];
        this['emailAutenticacao'] = data['emailAutenticacao'];
        this['nomeComercial'] = data['nomeComercial'];
        this['emailComercial'] = data['emailComercial'];
        this['telefoneFixoComercial'] = data['telefoneFixoComercial'];
        this['telefoneMovelComercial'] = data['telefoneMovelComercial'];
        this['nomeFaturamento'] = data['nomeFaturamento'];
        this['emailFaturamento'] = data['emailFaturamento'];
        this['telefoneFixoFaturamento'] = data['telefoneFixoFaturamento'];
        this['telefoneMovelFaturamento'] = data['telefoneMovelFaturamento'];
        this['nomeFaturamentoAdicional'] = data['nomeFaturamentoAdicional'];
        this['emailFaturamentoAdicional'] = data['emailFaturamentoAdicional'];
        this['telefoneFixoFaturamentoAdicional'] = data['telefoneFixoFaturamentoAdicional'];
        this['telefoneMovelFaturamentoAdicional'] = data['telefoneMovelFaturamentoAdicional'];
        this['modoFaturamento'] = data['modoFaturamento'];
        this['saldoDisponivelPrePago'] = data['saldoDisponivelPrePago'];
        this['saldoDisponivelAlocado'] = data['saldoDisponivelAlocado'];
        this['saldoDisponivelTotal'] = data['saldoDisponivelTotal'];
        this['saldoEmCartoes'] = data['saldoEmCartoes'];
    }
}
export class OutObterDadosClienteTravelBackoffice {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['chaveDeCliente'] = data['chaveDeCliente'];
        this['razaoSocial'] = data['razaoSocial'];
        this['nomeFantasia'] = data['nomeFantasia'];
        this['identificacaoFiscal'] = data['identificacaoFiscal'];
        this['naturezaJuridica'] = data['naturezaJuridica'];
        this['porte'] = data['porte'];
        this['grupo'] = data['grupo'];
        this['cnae'] = data['cnae'];
        this['cnaeDescricao'] = data['cnaeDescricao'];
        this['capitalSocial'] = data['capitalSocial'];
        this['origemCapitalSocial'] = data['origemCapitalSocial'];
        this['dataInicioAtividade'] = data['dataInicioAtividade'];
        this['endereco'] = data['endereco'];
        this['limiteMaximoDeCredito'] = data['limiteMaximoDeCredito'];
        this['limiteDisponivelDeCredito'] = data['limiteDisponivelDeCredito'];
        this['contaCorrente'] = data['contaCorrente'];
        this['contaPagamento'] = data['contaPagamento'];
        this['emailAutenticacao'] = data['emailAutenticacao'];
        this['nomeComercial'] = data['nomeComercial'];
        this['emailComercial'] = data['emailComercial'];
        this['telefoneFixoComercial'] = data['telefoneFixoComercial'];
        this['telefoneMovelComercial'] = data['telefoneMovelComercial'];
        this['nomeFaturamento'] = data['nomeFaturamento'];
        this['emailFaturamento'] = data['emailFaturamento'];
        this['telefoneFixoFaturamento'] = data['telefoneFixoFaturamento'];
        this['telefoneMovelFaturamento'] = data['telefoneMovelFaturamento'];
        this['nomeFaturamentoAdicional'] = data['nomeFaturamentoAdicional'];
        this['emailFaturamentoAdicional'] = data['emailFaturamentoAdicional'];
        this['telefoneFixoFaturamentoAdicional'] = data['telefoneFixoFaturamentoAdicional'];
        this['telefoneMovelFaturamentoAdicional'] = data['telefoneMovelFaturamentoAdicional'];
        this['modoFaturamento'] = data['modoFaturamento'];
        this['saldoDisponivelPrePago'] = data['saldoDisponivelPrePago'];
        this['saldoDisponivelAlocado'] = data['saldoDisponivelAlocado'];
        this['saldoDisponivelTotal'] = data['saldoDisponivelTotal'];
        this['saldoEmCartoes'] = data['saldoEmCartoes'];
        this['organizationUnitId'] = data['organizationUnitId'];
        this['organizationUnitDescricao'] = data['organizationUnitDescricao'];
        this['chaveDeProduto'] = data['chaveDeProduto'];
        this['travelClienteId'] = data['travelClienteId'];
    }
}
export class OutObterDetalhesCancelamentoCartao {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['saldo'] = data['saldo'];
        this['responsavel'] = data['responsavel'];
        this['userId'] = data['userId'];
        this['dataCancelamento'] = data['dataCancelamento'];
        this['enderecoIP'] = data['enderecoIP'];
        this['tipoCancelamento'] = data['tipoCancelamento'];
    }
}
export class OutRelatorioTransacoes {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['urlRelatorio'] = data['urlRelatorio'];
    }
}
export class OutTaxarLote {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['chaveDeTaxa'] = data['chaveDeTaxa'];
    }
}
export class TelefoneDto {
    constructor(data = {}) {
        this['ddd'] = data['ddd'];
        this['numero'] = data['numero'];
    }
}
