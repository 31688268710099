<template>
  <div class="client-status-box">
    <v-icon class="mr-5" color="var(--dangerColor)" :size="42">
      fas fa-exclamation-circle
    </v-icon>
    <div class="box-infos-status">
      <h4>
        {{ status.pendencias.length }}
        Pendências no cadastro de cliente |
        {{ status.statusCadastroClienteDescricao }}
      </h4>
      <div>
        <p v-for="(pendencia, index) in status.pendencias" :key="index">
          - {{ pendencia }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ClientStatusBox",
  props: ["status"],
};
</script>

<style scoped>
.client-status-box {
  width: 100%;
  padding: 15px 20px;
  display: flex;
  background: #ffffff;
  justify-content: flex-start;
  align-items: flex-start;
}
.client-status-box div h4 {
  font-size: 18px;
  font-weight: bold;
  margin: 0 0 10px 0;
  color: var(--dangerColor);
}

.client-status-box div p {
  font-size: 12px;
  margin: 0 0 3px 0;
}

.box-infos-status {
  flex: 1;
  height: auto;
  padding: 0;
  margin: 0;
}
</style>
