<template>
  <v-dialog v-model="isOpen" persistent max-width="600px">
    <v-card class="pa-4">
      <h6 class="titulo">{{ title }}</h6>

      <div class="px-4" data-cy="input-dialog">
        <v-textarea
          placeholder="Observação"
          filled
          solo
          v-model="text"
        ></v-textarea>
      </div>

      <v-card-actions class="d-flex justify-space-between">
        <DefaultButton
          text="Cancelar"
          @click.native="onCancelClicked"
          data-cy="cancel-button"
        />

        <DefaultButton
          text="Confirmar"
          @click.native="onConfirmClicked"
          data-cy="confirm-button"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import DefaultButton from "../../../../components/button/DefaultButton";

export default {
  components: {
    DefaultButton,
  },

  data() {
    return {
      isOpen: false,
      title: "",
      text: "",
      resolveFunction: null,
      rejectFunction: null,
    };
  },

  methods: {
    getInput(title) {
      this.title = title;
      this.isOpen = true;

      return new Promise((resolve, reject) => {
        this.resolveFunction = resolve;
        this.rejectFunction = reject;
      });
    },

    onCancelClicked() {
      this.resolveFunction([false, this.text]);
      this.clearInternalData();
    },

    onConfirmClicked() {
      this.resolveFunction([true, this.text]);

      this.clearInternalData();
    },

    clearInternalData() {
      this.isOpen = false;
      this.text = "";
      this.title = "";
      this.resolveFunction = null;
      this.rejectFunction = null;
    },
  },
};
</script>

<style scoped>
.titulo {
  font-weight: bold;
  font-size: 22px;
  padding: 15px;
  text-align: justify;
}
</style>
