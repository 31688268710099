<template>
  <div class="w-100">
    <div v-if="transactionSelected">
      <TransactionDetails
        :transaction="transactionSelected"
        @voltar="transactionSelected = null"
        :hideHeader="true"
      />
    </div>
    <JazzListPage
      v-else
      title="Transações"
      :fields="fields"
      @search="searchTransactions"
      hide-button-header
    >
      <div class="buttons-row" v-if="transactions.length > 0">
        <div class="buttons">
          <TableLabelButton
            data-cy="report-button"
            title=""
            label="Relatório de Transações"
            textTransform="capitalize"
            buttonColor="primary"
            iconColor="white"
            @click.native="generateReport()"
            icon="fas fa-file-download fa-lg"
            size="large"
            weight="bold"
          />
        </div>
      </div>
      <JazzTable
        :loading="loading"
        :rows="transactions"
        :pagination="pagination"
        @inputNewPage="searchTransactions(null, $event)"
      >
        <template slot="reportRow" slot-scope="props">
          <TravelTransactionRow
            :itemTransaction="{
              ...props.paramsItem,
              numeroCartaoMascarado: props.paramsItem.numeroCartao,
            }"
            @click.native="transactionSelected = props.paramsItem"
          />
        </template>
      </JazzTable>
    </JazzListPage>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import globalMethods from "../../../../../mixins/globalMethods";
import JazzListPage from "../../../../../components/template/JazzListPage";
import JazzTable from "../../../../../components/table/JazzTable";
import TravelTransactionRow from "../../../Transactions/components/rowsTable/TravelTransactionRow";
import TableLabelButton from "../../../../../components/button/TableLabelButton";
import TransactionDetails from "../../../../../views/Travel/Transactions/TransactionDetails";
import { TransacoesService } from "../../../../../services/apitravel";

export default {
  name: "TravelClientTransactionsTab",
  mixins: [globalMethods],
  components: {
    JazzListPage,
    JazzTable,
    TravelTransactionRow,
    TableLabelButton,
    TransactionDetails,
  },
  props: {
    client: Object,
  },
  data() {
    return {
      pagination: {
        page: 1,
        perPage: 15,
        total: 0,
        pages: 0,
      },
      showTable: false,
      transactionSelected: null,
      fields: {},
      loading: false,
      transactions: [],
      params: {},
    };
  },
  mounted() {
    this.params = this.$route.params;
    this.createForms();
  },
  methods: {
    ...mapMutations(["CHANGE_LOADING"]),
    async searchTransactions(data = null, page = 1) {
      this.loading = true;

      const {
        initialDateJazzDateField,
        finalDateJazzDateField,
        ordenationTypeSelectField,
      } = data?.values;

      this.filters = {
        chaveDeCliente: this.client?.chaveDeCliente,
        dataInicio: this.dateISOFormat(
          initialDateJazzDateField + " 00:00:00 +0000",
          "YYYY-MM-DD HH:mm:ss Z"
        ),
        dataFim: this.dateISOFormat(
          finalDateJazzDateField + " 23:59:59 +0000",
          "YYYY-MM-DD HH:mm:ss Z"
        ),
        tipoOrdenacao: ordenationTypeSelectField?.id || 0,
      };

      await TransacoesService.consultarPorCliente({
        body: {
          ...this.filters,
          pagina: page,
          porPagina: this.pagination.perPage,
        },
      })
        .then((response) => {
          const { pagina, qtdPorPagina, totalItems, qtdPaginas, items } =
            response?.result;
          this.pagination.page = pagina;
          this.pagination.perPage = qtdPorPagina;
          this.pagination.total = totalItems;
          this.pagination.pages = qtdPaginas;
          this.transactions = items;
        })
        .catch(() => {
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async generateReport() {
      this.CHANGE_LOADING(true);

      let url = "";

      await TransacoesService.gerarRelatorioPorCliente({
        body: this.filters,
      })
        .then((response) => {
          url = response.result.urlRelatorio;
        })
        .catch(() => {
          this.CHANGE_LOADING(false);
        });

      if (url) {
        window.open(url);
        this.$store.dispatch(
          "action_changeMessageSnackBar",
          "Arquivo baixado com sucesso!",
          { module: "template" }
        );
      }

      this.CHANGE_LOADING(false);
    },
    createForms() {
      this.fields = {
        initialDateJazzDateField: {
          field: "JazzDateField",
          label: "Data inicial",
          cols: 3,
          valueField: this.dateSubtract30Days(),
          rules: [
            (fields) =>
              !this.compareDateAfterAndCurrent(
                fields.initialDateJazzDateField.valueField,
                fields.finalDateJazzDateField.valueField
              ) || "Data inicial deve ser menor que data final",
          ],
        },
        finalDateJazzDateField: {
          field: "JazzDateField",
          label: "Data final",
          cols: 3,
          valueField: this.dateAddOneDay(),
          rules: [
            (fields) =>
              !this.compareDateAfterAndCurrent(
                fields.initialDateJazzDateField.valueField,
                fields.finalDateJazzDateField.valueField
              ) || "Data final deve ser maior que data inicial",
          ],
        },
        ordenationTypeSelectField: {
          label: "Ordenação",
          field: "SelectField",
          cols: 3,
          items: [
            { id: null, descricao: "Selecione" },
            { id: 0, descricao: "Decrescente" },
            { id: 1, descricao: "Crescente" },
          ],
          keyName: "descricao",
          keyValue: "id",
        },
      };
    },
  },
};
</script>

<style scoped>
.buttons {
  display: flex;
  width: 25%;
}

.buttons-row {
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
}
</style>
