<template>
  <div>
    <JazzCardHeader
      :icon-name="iconName"
      :loading="loading"
      :groups-text="groupsText"
      v-on="$listeners"
    />
    <v-card elevation="1" rounded class="jazz-card-body">
      <JazzText
        v-if="title"
        :style-text="{ marginBottom: '30px' }"
        weight="bold"
        color="primary"
        text-align="center"
        size="title"
      >
        {{ title }}
      </JazzText>
      <slot />
    </v-card>
  </div>
</template>

<script>
import JazzCardHeader from "./JazzCardHeader";
import JazzText from "../typography/JazzText";

export default {
  name: "JazzDetailsPage",
  components: { JazzText, JazzCardHeader },
  props: {
    groupsText: Array,
    loading: Boolean,
    title: String,
    iconName: String,
  },
};
</script>

<style scoped>
.jazz-card-body {
  margin-top: 30px;
  width: 100%;
  padding: 30px;
}
</style>
