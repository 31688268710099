<template>
  <div class="client-details-row">
    <div class="first-column">
      <div>
        <v-icon>
          fas fa-lg fa-building
        </v-icon>
      </div>
      <div class="item-column">
        <JazzTextGroup label="Razão Social" :text="client.razaoSocial || '-'" />
      </div>
    </div>
    <div class="small">
      <JazzTextGroup
        label="Identificação Fiscal"
        :text="identificacaoFiscal || '-'"
      />
    </div>

    <div class="small">
      <JazzTextGroup label="Modalidade" :text="modalidade || '-'" />
    </div>
    <div class="small">
      <div v-if="!client.limiteAtivo">
        <JazzTextGroup
          label="Observação"
          text="Fatura Vencida"
          backgroundColor="lightRedColor"
          textAlignLabel="centerLabel"
        />
      </div>
      <div v-else>
        <JazzTextGroup
          label="Observação"
          text="-"
          textAlign="center"
          textAlignLabel="centerLabel"
        />
      </div>
    </div>
  </div>
</template>

<script>
import globalMethods from "../../../../../mixins/globalMethods";
import JazzTextGroup from "../../../../../components/typography/JazzTextGroup";

export default {
  name: "ClientDetailsRow",
  mixins: [globalMethods],
  props: {
    client: {
      required: true,
    },
  },
  components: {
    JazzTextGroup,
  },
  computed: {
    identificacaoFiscal() {
      const { identificacaoFiscal } = this.client;
      if (identificacaoFiscal?.length === 14) {
        return this.cnpjMask(identificacaoFiscal);
      }
      return this.cpfMask(identificacaoFiscal);
    },
    modalidade() {
      const modalityList = {
        Limite: "Pós-Pago",
        PrePago: "Pré-Pago",
      };

      return modalityList[this.client.modoFaturamento] || "-";
    },
  },
};
</script>

<style lang="scss" scoped>
.client-details-row {
  width: 100%;
  padding: 10px;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  align-content: stretch;
  @media only screen and (max-width: 1160px) {
    padding: 10px;
  }
  background-color: $whiteColor;
  margin-bottom: 5px;
}

.client-details-row:hover {
  background-color: $grayContrast;
  cursor: pointer;
}

.client-details-row .v-icon {
  font-size: 24px;
  color: $blackColor;
  margin-right: 20px;
  width: 30px;
  text-align: center;
}

.first-column {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 250px;
  @media only screen and (min-width: 1920px) {
    width: 500px;
  }
}

.item-column {
  width: 250px;
  @media only screen and (min-width: 1920px) {
    width: 350px;
  }
}

.small {
  width: 200px;
  @media only screen and (min-width: 1920px) {
    width: 350px;
  }
}
</style>
