<template>
  <div class="extract-account-row">
    <div class="item-first-column medium">
      <v-icon class="mr-5" size="25" color="var(--successColor)">
        fas fa-long-arrow-alt-up
      </v-icon>
      <JazzTextGroup
        label="Data e Hora"
        :text="
          this.dateTimeFormat(paramsItem.dataHoraTransacao) || '--/--/----'
        "
      />
    </div>
    <JazzTextGroup
      class="medium"
      label="Carga"
      :text="
        paramsItem.motivoTransacao
          ? paramsItem.motivoTransacao.replaceAll('_', ' ')
          : '-'
      "
    />
    <JazzTextGroup
      class="small"
      label="Cartão"
      :text="
        paramsItem.numeroCartaoMascarado
          ? '**** **** **** ' + paramsItem.numeroCartaoMascarado.slice(-4)
          : '----'
      "
    />
    <JazzTextGroup
      class="small"
      label="Valor"
      :text="'R$ ' + money2(paramsItem.valorBRL || 0) || 'R$ -,--'"
    />
  </div>
</template>

<script>
import globalMethods from "../../../../../mixins/globalMethods";
import JazzTextGroup from "../../../../../components/typography/JazzTextGroup";

export default {
  name: "ExtractAccountRow",
  mixins: [globalMethods],
  props: { paramsItem: Object },
  components: {
    JazzTextGroup,
  },
};
</script>

<style scoped lang="scss">
.extract-account-row {
  width: 100%;
  min-height: 70px;
  max-height: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  padding: 12px;
  border-bottom: thin solid $opacityGrayColor;
  position: relative;
  margin: 15px 0 5px 0;
}

.extract-account-row:hover {
  background: $grayContrast;
  cursor: pointer;
}

.item-first-column {
  display: flex;
  flex: 1;
  justify-content: flex-start;
  align-items: center;
}

.small {
  width: 20%;
}

.medium {
  width: 30%;
}
</style>
