import ClientsIndex from "../../views/Client/Client/ClientsIndex";
import ClientShow from "../../views/Client/Client/ClientShow";
import ClientDetails from "../../views/Client/Client/ClientInformation";
import ClientRH from "../../views/Client/Client/ClientRH";
import ClientIdentification from "../../views/Client/Client/ClientIdentification";
import ClientAddress from "../../views/Client/Client/ClientAddress";
import ClientLimits from "../../views/Client/Client/ClientLimits";
import ClientAccounts from "../../views/Client/Client/ClientAccounts";
import AccountShow from "../../views/Client/Client/Account/AccountShow";
import AccountAnticipation from "../../views/Client/Client/Account/CreditAccount/AccountAnticipation";
import AccountContractDetails from "../../views/Client/Client/Account/CreditAccount/AccountContractDetails";
import CreditAccount from "../../views/Client/Client/Account/CreditAccount/CreditAccount";

export default [
  {
    path: "/consultar_clientes",
    name: "ClientsIndex",
    component: ClientsIndex,
    meta: {
      permission: ["Pages.Clientes"],
      permissionName: "Clientes",
    },
  },
  {
    path: "/cliente/:tipo?/:chaveDeCliente",
    name: "ClientForm",
    component: ClientShow,
    redirect: { name: "ClientDetails" },
    meta: {
      permission: ["Pages.Clientes"],
      permissionName: "Clientes",
    },
    children: [
      {
        path: "informacoes",
        name: "ClientDetails",
        component: ClientDetails,
      },
      {
        path: "rh",
        name: "ClientRH",
        component: ClientRH,
      },
      {
        path: "identificacao",
        name: "ClientIdentification",
        component: ClientIdentification,
      },
      {
        path: "endereco",
        name: "ClientAddress",
        component: ClientAddress,
      },
      {
        path: "limites",
        name: "ClientLimits",
        component: ClientLimits,
      },
      {
        path: "contas",
        name: "ClientAccounts",
        component: ClientAccounts,
      },
      {
        path: "conta/:chaveDeConta",
        name: "AccountShow",
        component: AccountShow,
        redirect: { name: "CreditAccount" },
        children: [
          {
            path: "credito",
            name: "CreditAccount",
            component: CreditAccount,
            redirect: { name: "AccountAnticipation" },
            children: [
              {
                path: "contratos",
                name: "AccountAnticipation",
                component: AccountAnticipation,
              },
              {
                path: ":idContrato/detalhes",
                name: "AccountContractDetails",
                component: AccountContractDetails,
              },
            ],
          },
        ],
      },
    ],
  },
];
