<template>
  <div class="DefaultInformation" id="DefaultInformation">
    <div class="alignInput">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-icon color="black" v-on="on" v-bind="attrs">
            fas fa-info-circle
          </v-icon>
          <!--          <v-icon-->
          <!--              large-->
          <!--              :color="color"-->
          <!--              v-bind="attrs"-->
          <!--              :size="size"-->
          <!--              v-on="on"-->
          <!--              @click.native="redirectInformation"-->
          <!--          >-->
          <!--            fas fa-info-circle-->
          <!--          </v-icon>-->
        </template>
        <span>{{ text }}</span>
      </v-tooltip>
    </div>
  </div>
</template>

<script>
export default {
  name: "DefaultInformation",
  props: {
    text: {
      type: String,
      default: "Clique em mim para maiores informações",
    },
    color: {
      type: String,
      default: "primary",
    },
    link: {
      type: String,
    },
    size: {
      default: 14,
    },
  },
  data() {
    return {};
  },
  methods: {
    redirectInformation() {
      if (this.link) {
        window.open(this.link, "_blank");
      }
    },
  },
};
</script>

<style scoped>
.DefaultInformation {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  margin-left: 5px;
}
</style>
