<template>
  <div class="csv-preview">
    <table cellspacing="0" id="table">
      <caption class="table-cap">CSV Preview</caption>
      <thead>
        <th v-for="(header, k) in convertedCsv[0]" :key="k" :id="header">
          {{ header }}
        </th>
      </thead>
      <tr class="csv-line" v-for="(line, i) in convertedCsv" :key="i">
        <td class="csv-column" v-for="(column, j) in convertedCsv[i + 1]" :key="j" :id="column">
          {{ column }}
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import globalMethods from "../../mixins/globalMethods";

export default {
  name: "JazzCsvPreview",
  props: {
    base64: {
      type: String,
      required: true,
    },
    removeLastLine: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [globalMethods],
  data() {
    return {
      convertedCsv: [],
    };
  },
  async mounted() {
    this.convertedBase64 = await this.decodeBase64(this.base64.split(",")[1]);
    this.convertedCsv = this.convertCsvToJson(this.convertedBase64);

    if (this.removeLastLine) {
      this.convertedCsv.pop();
    }
  },
};
</script>

<style lang="scss" scoped>
.csv-preview {
  width: 900px;
  overflow: scroll;
  white-space: nowrap;
  background-color: $whiteColor;
  padding: 10px;
}

td {
  border: solid 1px $lightGrayColor;
}

th {
  border-top: solid 3px $blackColor;
  border-right: solid 1px $lightGrayColor;
  border-bottom: solid 3px $blackColor;
  border-left: solid 1px $lightGrayColor;
}

th,
td {
  padding: 2px;
}

@media screen and (min-width: 1800px) {
  .csv-preview {
    width: 1500px;
  }
}

.table-cap {
  display: none;
}
</style>
