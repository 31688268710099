<template>
  <v-col :cols="cols">
    <template>
      <v-data-table
        :headers="header"
        :items="items"
        :items-per-page="5"
        hide-default-footer
        class="elevation-1"
      >
        <template slot="no-data">
          <div class="message-box">Nenhuma informação encontrada.</div>
        </template>
      </v-data-table>
    </template>
  </v-col>
</template>

<script>
export default {
  props: ["header", "items", "cols"],
};
</script>

<style scoped lang="scss">
.message-box {
  padding: 10px;
  color: $blackColor;
  font-size: 14px;
}
</style>
