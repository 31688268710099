<template>
  <div class="container">
    <v-expansion-panels v-model="activePanel">
      <JazzExpansionPanelCard title="Ativo">
        <template v-slot:content>
          <DefaultLoading v-if="mainloading" />
          <JazzFormFields v-else :fields="fieldsContractActive" />
        </template>
      </JazzExpansionPanelCard>
      <JazzExpansionPanelCard title="Inativos">
        <template v-slot:content>
          <v-expansion-panels>
            <v-expansion-panel
              v-for="(item, index) in inativeContracts"
              :key="index"
              :data-cy="'contract' + index + '-data'"
            >
              <v-expansion-panel-header>
                <JazzTextGroup
                  label="Data de Criação"
                  :text="item.dataCriacao"
                />
                <JazzTextGroup
                  label="Data de Desativação"
                  :text="item.dataDesativacao"
                />
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <JazzFormFields :fields="mountInativeContract(item)" />
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </template>
      </JazzExpansionPanelCard>
    </v-expansion-panels>
  </div>
</template>

<script>
import { mapState } from "vuex";
import JazzFormFields from "../../../../../components/form/JazzFormFields";
import JazzTextGroup from "../../../../../components/typography/JazzTextGroup";
import DefaultLoading from "../../../../../components/template/DefaultLoading";
import JazzExpansionPanelCard from "../../../../../components/panels/JazzExpansionPanelCard";
import { ContratosService } from "../../../../../services/apitravel";

export default {
  components: {
    JazzFormFields,
    JazzTextGroup,
    DefaultLoading,
    JazzExpansionPanelCard,
  },
  name: "TravelClientContractTab",
  props: {
    client: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      inativeContracts: [],
      activePanel: 0,
      fieldsContractActive: {},
      fieldsContractInative: {},
      mainloading: false,
      tiposCiclo: [
        { id: 1, displayName: "7 + 21" },
        { id: 2, displayName: "15 + 15" },
        { id: 3, displayName: "30 + 10" },
      ],
      tiposDiaReferencia: [
        { id: 0, displayName: "Domingo" },
        { id: 1, displayName: "Segunda-feira" },
        { id: 2, displayName: "Terça-feira" },
        { id: 3, displayName: "Quarta-feira" },
        { id: 4, displayName: "Quinta-feira" },
        { id: 5, displayName: "Sexta-feira" },
        { id: 6, displayName: "Sábado" },
      ],
      params: null,
    };
  },

  methods: {
    checkReferenceDayRule(value) {
      if (this.inputsValues?.modalidadeCicloSelectField?.id !== 1) {
        return true;
      } else {
        return value.id !== null;
      }
    },
    mountInativeContract(contract) {
      return {
        integrador: {
          field: "TextField",
          label: "Integrador",
          cols: 12,
          placeholder: "",
          valueField: contract.integrador,
          type: "text",
          disabled: true,
        },
        ciclo: {
          field: "SelectField",
          label: "Ciclo",
          cols: 6,
          valueField: contract.modalidadeCiclo,
          type: "text",
          disabled: true,
          items: [{ id: null, displayName: "Selecione" }, ...this.tiposCiclo],
          keyName: "displayName",
          keyValue: "id",
        },
        diaDeReferencia: {
          hideInput: contract.modalidadeCiclo !== 1,
          field: "SelectField",
          label: "Dia de Referência",
          valueField: contract ? contract.diaReferencia : "",
          cols: 6,
          disabled: true,
          items: [
            { id: null, displayName: "Selecione" },
            ...this.tiposDiaReferencia,
          ],
          keyName: "displayName",
          keyValue: "id",
        },
        diaDeReferenciaNumeric: {
          hideInput: contract.modalidadeCiclo === 1,
          field: "TextField",
          label: "Dia de Finalização do Ciclo",
          valueField: contract.diaReferencia,
          disabled: true,
          cols: 6,
        },
        contaCorrenteBoleto: {
          field: "TextField",
          label: "Conta Corrente Boleto",
          cols: 12,
          valueField: contract.contaCorrenteBoleto,
          type: "text",
          disabled: true,
        },
        tipoCredito: {
          field: "TextField",
          label: "Tipo de Crédito",
          cols: 6,
          valueField: contract.tipoCredito,
          type: "text",
          disabled: true,
        },
        rav: {
          field: "TextField",
          label: "RAV",
          cols: 6,
          valueField: contract.rav ? "Ativo" : "Inativo",
          type: "text",
          disabled: true,
        },
      };
    },
  },
  mounted() {
    if (this.client) {
      this.params = this.$route.params;

      this.mainloading = true;

      let payload = {
        chaveDeCliente: this.client.chaveDeCliente,
        ativo: true,
      };

      ContratosService.consultarPorCliente1({
        body: payload,
      }).then((response) => {
        const contrato = response.result.contratos[0];
        this.fieldsContractActive = {
          integrador: {
            field: "TextField",
            label: "Integrador",
            cols: 12,
            placeholder: "",
            valueField: contrato.integrador,
            type: "text",
            disabled: true,
          },
          ciclo: {
            field: "SelectField",
            label: "Ciclo",
            cols: 6,
            valueField: contrato.modalidadeCiclo,
            type: "text",
            disabled: true,
            items: [{ id: null, displayName: "Selecione" }, ...this.tiposCiclo],
            keyName: "displayName",
            keyValue: "id",
          },
          diaDeReferencia: {
            hideInput: contrato.modalidadeCiclo !== 1,
            field: "SelectField",
            label: "Dia de Referência",
            valueField: contrato ? contrato.diaReferencia : "",
            cols: 6,
            disabled: true,
            items: [
              { id: null, displayName: "Selecione" },
              ...this.tiposDiaReferencia,
            ],
            keyName: "displayName",
            keyValue: "id",
          },
          diaDeReferenciaNumeric: {
            hideInput: contrato.modalidadeCiclo === 1,
            field: "TextField",
            label: "Dia de Finalização do Ciclo",
            valueField: contrato.diaReferencia,
            disabled: true,
            cols: 6,
          },
          contaCorrenteBoleto: {
            field: "TextField",
            label: "Conta Corrente Boleto",
            cols: 12,
            valueField: contrato.contaCorrenteBoleto,
            type: "text",
            disabled: true,
          },
          tipoCredito: {
            field: "TextField",
            label: "Tipo de Crédito",
            cols: 6,
            valueField: contrato.tipoCredito,
            type: "text",
            disabled: true,
          },
          rav: {
            field: "TextField",
            label: "RAV",
            cols: 6,
            valueField: contrato.rav ? "Ativo" : "Inativo",
            type: "text",
            disabled: true,
          },
        };
      });

      payload = {
        chaveDeCliente: this.client.chaveDeCliente,
        ativo: false,
      };
      ContratosService.consultarPorCliente1({
        body: payload,
      })
        .then((response) => {
          this.inativeContracts = response.result.contratos;
        })
        .finally(() => (this.mainloading = false));
    }
  },
  computed: {
    ...mapState({
      listBanks: (state) => state.account.banks,
    }),
  },
};
</script>
<style scoped></style>
