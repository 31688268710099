/** Generate by swagger-axios-codegen */
// @ts-nocheck
/* eslint-disable */
// Add default options
export const serviceOptions = {};
// Instance selector
export function axios(configs, resolve, reject) {
    if (serviceOptions.axios) {
        return serviceOptions.axios
            .request(configs)
            .then(res => {
            resolve(res.data);
        })
            .catch(err => {
            reject(err);
        });
    }
    else {
        throw new Error('please inject yourself instance like axios  ');
    }
}
export function getConfigs(method, contentType, url, options) {
    const configs = Object.assign(Object.assign({}, options), { method, url });
    configs.headers = Object.assign(Object.assign({}, options.headers), { 'Content-Type': contentType });
    return configs;
}
export const basePath = '';
export class ListResultDto {
}
export class PagedResultDto {
}
// customer definition
// empty
export class AntecipacaoSalarialBackofficeService {
    /**
     *
     */
    static consultarConvenios(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/antecipacao-salarial/backoffice/consultar-convenios';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static obterRecebiveisContrato(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/antecipacao-salarial/backoffice/obter-recebiveis-contrato';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static obterDadosClienteOnidata(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/antecipacao-salarial/backoffice/obter-dados-cliente-onidata';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static alterarDadosClienteOnidata(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/antecipacao-salarial/backoffice/alterar-dados-cliente-onidata';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static ajustarDadosPrimeiroAcesso(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/antecipacao-salarial/backoffice/ajustar-dados-primeiro-acesso';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static consultarContratosConvenios(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/antecipacao-salarial/backoffice/consultar-contratos-convenios';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static contratos(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/backoffice/contas/{chaveDeConta}/antecipacoes/salariais/contratos';
            url = url.replace('{chaveDeConta}', params['chaveDeConta'] + '');
            const configs = getConfigs('get', 'application/json', url, options);
            configs.params = {
                NumeroDoContrato: params['numeroDoContrato'],
                DataInicial: params['dataInicial'],
                DataFinal: params['dataFinal'],
                StatusContratoAntecipacao: params['statusContratoAntecipacao'],
                Pagina: params['pagina'],
                porPagina: params['porPagina']
            };
            let data = null;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static contratos1(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/backoffice/contas/{chaveDeConta}/antecipacoes/salariais/contratos/{localizador}';
            url = url.replace('{chaveDeConta}', params['chaveDeConta'] + '');
            url = url.replace('{localizador}', params['localizador'] + '');
            const configs = getConfigs('get', 'application/json', url, options);
            let data = null;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static aprovacoes(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/backoffice/contas/antecipacoes/salariais/contratos/aprovacoes';
            const configs = getConfigs('get', 'application/json', url, options);
            configs.params = {
                Pagina: params['pagina'],
                porPagina: params['porPagina'],
                StatusAvaliacaoUO: params['statusAvaliacaoUo'],
                Localizador: params['localizador'],
                DataSolicitacaoInicio: params['dataSolicitacaoInicio'],
                DataSolicitacaoTermino: params['dataSolicitacaoTermino'],
                ChaveDeCliente: params['chaveDeCliente'],
                UnidadeOrganizacionalId: params['unidadeOrganizacionalId'],
                Nome: params['nome'],
                IdentificacaoFiscal: params['identificacaoFiscal'],
                Matricula: params['matricula']
            };
            let data = null;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static aprovacoes1(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/backoffice/contas/antecipacoes/salariais/contratos/aprovacoes';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
}
export class AtendimentosService {
    /**
     *
     */
    static consultarStatusPossivel(options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/atendimento/backoffice/consultar-status-possivel';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = null;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static consultarTodos(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/atendimento/backoffice/consultar-todos';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static obterChamadoPeloId(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/atendimento/backoffice/obter-chamado-pelo-id';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static criarChamado(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/atendimento/backoffice/criar-chamado';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static atualizarChamado(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/atendimento/backoffice/atualizar-chamado';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static excluirChamado(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/atendimento/backoffice/excluir-chamado';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static criarOcorrencia(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/atendimento/backoffice/criar-ocorrencia';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static uploadAnexoOcorrencia(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/atendimento/backoffice/upload-anexo-ocorrencia';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static uploadAnexosOcorrencia(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/atendimento/backoffice/upload-anexos-ocorrencia';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static consultarOcorrenciasChamado(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/atendimento/backoffice/consultar-ocorrencias-chamado';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static excluirOcorrencia(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/atendimento/backoffice/excluir-ocorrencia';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static listarOcorrencia(options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/atendimento/backoffice/listar-ocorrencia';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = null;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static consultarCategoriasAtendimento(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/atendimento/backoffice/consultar-categorias-atendimento';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static obterDadosConta(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/atendimento/backoffice/obter-dados-conta';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
}
export class CarteiraDigitalBackofficeService {
    /**
     *
     */
    static ativarTokenVisa(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/carteira-digital/backoffice/ativar-token-visa';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
}
export class CartoesBackofficeService {
    /**
     *
     */
    static listarLotesEmissaoCartaoFisico(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/cartao/backoffice/listar-lotes-emissao-cartao-fisico';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static reprocessarLoteEmissaoCartaoFisicoComErro(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/cartao/backoffice/reprocessar-lote-emissao-cartao-fisico-com-erro';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static consultarHistorico(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/cartao/backoffice/consultar-historico';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static consultarHistoricoMcc(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/cartao/backoffice/consultar-historico-mcc';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static consultarCartoesPorChaveConta(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/cartao/backoffice/consultar-cartoes-por-chave-conta';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static validarSenha(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/cartao/backoffice/validar-senha';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static gerarNovaSenha(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/cartao/backoffice/gerar-nova-senha';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static getTiposPlastico(options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/cartao/backoffice/get-tipos-plastico';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = null;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static criarTipoDeCartoes(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/cartao/backoffice/criar-tipo-de-cartoes';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static buscarTipoDeCartoes(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/cartao/backoffice/buscar-tipo-de-cartoes';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static atualizarTipoDeCartoes(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/cartao/backoffice/atualizar-tipo-de-cartoes/{id}';
            url = url.replace('{id}', params['id'] + '');
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static deletarTipoDeCartoes(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/cartao/backoffice/deletar-tipo-de-cartoes/{id}';
            url = url.replace('{id}', params['id'] + '');
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static listarTodosCartoes(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/cartao/backoffice/listar-todos-cartoes';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static listarBandeiras(options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/cartao/backoffice/listar-bandeiras';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = null;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static motivosBloqueio(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/cartao/backoffice/motivos-bloqueio';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static motivosDesbloqueio(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/cartao/backoffice/motivos-desbloqueio';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Realiza o cadastro de parâmetro de CVV dinâmico
     */
    static cadastrarParametroCvvDinamico(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/cartao/backoffice/cadastrar-parametro-cvv-dinamico';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Realiza a alteração de parâmetro de CVV dinâmico
     */
    static alterarParametroCvvDinamico(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/cartao/backoffice/alterar-parametro-cvv-dinamico';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Realiza a exclusão de parâmetro de CVV dinâmico
     */
    static excluirParametroCvvDinamico(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/cartao/backoffice/excluir-parametro-cvv-dinamico';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Realiza a consulta dos parâmetros de CVV dinâmico
     */
    static consultarParametroCvvDinamico(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/cartao/backoffice/consultar-parametro-cvv-dinamico';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static listarMotivosSegundaVia(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/cartao/backoffice/listar-motivos-segunda-via';
            const configs = getConfigs('get', 'application/json', url, options);
            configs.params = { ChaveDeIdempotencia: params['chaveDeIdempotencia'], Metadados: params['metadados'] };
            let data = null;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static motivosSegundaViaVirtual(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/cartao/backoffice/motivos-segunda-via-virtual';
            const configs = getConfigs('get', 'application/json', url, options);
            configs.params = { ChaveDeIdempotencia: params['chaveDeIdempotencia'], Metadados: params['metadados'] };
            let data = null;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
}
export class ClientesBackofficeService {
    /**
     * Alterar o email do cliente/usuário/conta pelo backoffice
     */
    static alterarDadosEmail(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/clientes/backoffice/alterar-dados-email';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Alterar o número de celular do cliente/conta pelo backoffice
     */
    static alterarDadosCelular(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/clientes/backoffice/alterar-dados-celular';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Alterar o número de telefone fixo do cliente pelo backoffice
     */
    static alterarDadosTelefone(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/clientes/backoffice/alterar-dados-telefone';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Cadastra um endereço de correspondencia do cliente pelo backoffice
     */
    static cadastrarEnderecoCorrespondencia(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/clientes/backoffice/cadastrar-endereco-correspondencia';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static obterSelfie(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/clientes/backoffice/obter-selfie';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Lista os clientes cadastrados.
     */
    static listarClientesGeraisBackoffice(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/clientes/backoffice/listar-clientes-gerais-backoffice';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Gera um relatório dos colaboradores cadastrados.
     */
    static gerarRelatorioColaboradores(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/clientes/backoffice/gerar-relatorio-colaboradores';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static obterFrenteDoc(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/clientes/backoffice/obter-frente-doc';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static obterVersoDoc(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/clientes/backoffice/obter-verso-doc';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Verifica Status Cliente
     */
    static verificarStatusCliente(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/clientes/backoffice/cliente/verificar-status-cliente';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static listarReprovadosParaAnaliseMesa(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/clientes/backoffice/listar-reprovados-para-analise-mesa';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static obterDadosClienteParaAnaliseMesa(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/clientes/backoffice/obter-dados-cliente-para-analise-mesa';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static submeterAnaliseMesa(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/clientes/backoffice/submeter-analise-mesa';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static buscarHistoricoAnaliseMesa(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/clientes/backoffice/buscar-historico-analise-mesa';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static obterDetalhesHistoricoAnaliseMesa(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/clientes/backoffice/obter-detalhes-historico-analise-mesa';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Solicitar alteração de dados cadatrais do cliente/conta pelo backoffice
     */
    static solicitarAlteracaoDeDados(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/clientes/backoffice/solicitar-alteracao-de-dados';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Lista as solicitações de alteração cadastral do cliente.
     */
    static listarSolicitacoesAlteracaoCadastralCliente(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/clientes/backoffice/listar-solicitacoes-alteracao-cadastral-cliente';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
}
export class ConsignadoPublicoBackofficeService {
    /**
     *
     */
    static obterConvenio(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/backoffice/consignado-publico/obter-convenio';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static atualizarConvenio(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/backoffice/consignado-publico/atualizar-convenio';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static inserirConvenio(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/backoffice/consignado-publico/inserir-convenio';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
}
export class ContasBackofficeService {
    /**
     *
     */
    static portabilidadeProduto(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/conta/backoffice/portabilidade-produto';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static desbloquearContaPinIncorreto(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/conta/backoffice/desbloquear-conta-pin-incorreto';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static cancelarConta(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/conta/backoffice/cancelar-conta';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static bloquear(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/conta/backoffice/bloquear';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static desbloquear(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/conta/backoffice/desbloquear';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static bloquearPrevencaoFraude(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/conta/backoffice/bloquear-prevencao-fraude';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static bloquearContaFraude(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/conta/backoffice/bloquear-conta-fraude';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static desbloquearPrevencaoFraude(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/conta/backoffice/desbloquear-prevencao-fraude';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static filtrarContas(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/conta/backoffice/filtrar-contas';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static consultarContas(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/conta/backoffice/consultar-contas';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static obterInfoConta(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/conta/backoffice/obter-info-conta';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static obterDadosParciaisInformeRendimento(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/conta/backoffice/obter-dados-parciais-informe-rendimento';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static enviarInformeRendimentoPorEmail(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/conta/backoffice/enviar-informe-rendimento-por-email';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static cadastrarParametrosCashin(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/conta/backoffice/cadastrar-parametros-cashin';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static alterarParametrosCashin(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/conta/backoffice/alterar-parametros-cashin';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static removerParametrosCashin(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/conta/backoffice/remover-parametros-cashin';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static consultarParametrosCashin(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/conta/backoffice/consultar-parametros-cashin';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static transacoesNegadas(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/conta/backoffice/transacoes-negadas';
            const configs = getConfigs('get', 'application/json', url, options);
            configs.params = {
                chaveDeCartao: params['chaveDeCartao'],
                dataInicial: params['dataInicial'],
                dataFinal: params['dataFinal'],
                pagina: params['pagina'],
                porPagina: params['porPagina']
            };
            let data = null;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
}
export class DimoPayConfiguracaoLojaService {
    /**
     * Lista as configurações das lojas cadastradas.
     */
    static listarConfiguracaoLoja(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/dimopay/backoffice/listar-configuracao-loja';
            const configs = getConfigs('get', 'application/json', url, options);
            configs.params = { NomeLoja: params['nomeLoja'], Pagina: params['pagina'], porPagina: params['porPagina'] };
            let data = null;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Cadastrar configuração da loja.
     */
    static configuracaoLoja(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/dimopay/backoffice/configuracao-loja';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Excluir configuração da loja.
     */
    static configuracaoLoja1(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/dimopay/backoffice/configuracao-loja/{chaveConfiguracaoLoja}';
            url = url.replace('{chaveConfiguracaoLoja}', params['chaveConfiguracaoLoja'] + '');
            const configs = getConfigs('delete', 'application/json', url, options);
            let data = null;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Obter configuração da loja.
     */
    static configuracaoLoja2(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/dimopay/backoffice/configuracao-loja/{chaveConfiguracaoLoja}';
            url = url.replace('{chaveConfiguracaoLoja}', params['chaveConfiguracaoLoja'] + '');
            const configs = getConfigs('get', 'application/json', url, options);
            let data = null;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Alterar configuração da loja.
     */
    static configuracaoLoja3(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/dimopay/backoffice/configuracao-loja/{chaveConfiguracaoLoja}';
            url = url.replace('{chaveConfiguracaoLoja}', params['chaveConfiguracaoLoja'] + '');
            const configs = getConfigs('put', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
}
export class FinanciamentoMobileBackofficeService {
    /**
     *
     */
    static contratos(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/financiamento/backoffice/mobile/contas/{chaveDeConta}/contratos';
            url = url.replace('{chaveDeConta}', params['chaveDeConta'] + '');
            const configs = getConfigs('get', 'application/json', url, options);
            let data = null;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static contratos1(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/financiamento/backoffice/mobile/contas/{chaveDeConta}/contratos/{idContrato}';
            url = url.replace('{chaveDeConta}', params['chaveDeConta'] + '');
            url = url.replace('{idContrato}', params['idContrato'] + '');
            const configs = getConfigs('get', 'application/json', url, options);
            let data = null;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static parcelas(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/financiamento/backoffice/mobile/contas/{chaveDeConta}/contratos/{idContrato}/parcelas';
            url = url.replace('{chaveDeConta}', params['chaveDeConta'] + '');
            url = url.replace('{idContrato}', params['idContrato'] + '');
            const configs = getConfigs('get', 'application/json', url, options);
            let data = null;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static pagamento(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/financiamento/backoffice/mobile/contrato/{codigoContrato}/pagamento';
            url = url.replace('{codigoContrato}', params['codigoContrato'] + '');
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static contratosDeCredito(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/financiamento/backoffice/mobile/contratos-de-credito';
            const configs = getConfigs('get', 'application/json', url, options);
            configs.params = {
                situacao: params['situacao'],
                cpfDoCliente: params['cpfDoCliente'],
                numeroDoContrato: params['numeroDoContrato'],
                dataInicial: params['dataInicial'],
                dataFinal: params['dataFinal'],
                pagina: params['pagina'],
                porPagina: params['porPagina']
            };
            let data = null;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static bloqueio(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/financiamento/backoffice/mobile/contrato/{codigoContrato}/aparelho/bloqueio';
            url = url.replace('{codigoContrato}', params['codigoContrato'] + '');
            const configs = getConfigs('put', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static desbloqueio(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/financiamento/backoffice/mobile/contrato/{codigoContrato}/aparelho/desbloqueio';
            url = url.replace('{codigoContrato}', params['codigoContrato'] + '');
            const configs = getConfigs('put', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static aparelho(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/financiamento/backoffice/mobile/aparelho/{imei}';
            url = url.replace('{imei}', params['imei'] + '');
            const configs = getConfigs('get', 'application/json', url, options);
            let data = null;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
}
export class HangfireService {
    /**
     *
     */
    static jobsPendentes(options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/hangfire/debug/jobs-pendentes';
            const configs = getConfigs('get', 'application/json', url, options);
            let data = null;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
}
export class HistoricoAlteracoesService {
    /**
     *
     */
    static buscar(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/historico-alteracao/backoffice/buscar';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static buscarPaginado(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/historico-alteracao/backoffice/buscar-paginado';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
}
export class IntegradoresLogConfiguracaoBackofficeService {
    /**
     *
     */
    static listarConfiguracoes(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/integradores-logs/backoffice/listar-configuracoes';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static inserirAtualizarConfiguracao(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/integradores-logs/backoffice/inserir-atualizar-configuracao';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static excluirConfiguracao(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/integradores-logs/backoffice/excluir-configuracao';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static buscarCombos(options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/integradores-logs/backoffice/buscar-combos';
            const configs = getConfigs('get', 'application/json', url, options);
            let data = null;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static testarConfiguracao(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/integradores-logs/backoffice/testar-configuracao';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
}
export class LimiteGlobalBackofficeService {
    /**
     *
     */
    static pessoa(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/limite-global/backoffice/pessoa';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static pessoa1(options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/limite-global/backoffice/pessoa';
            const configs = getConfigs('get', 'application/json', url, options);
            let data = null;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static pessoa2(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/limite-global/backoffice/pessoa/{identificacaoFiscal}';
            url = url.replace('{identificacaoFiscal}', params['identificacaoFiscal'] + '');
            const configs = getConfigs('get', 'application/json', url, options);
            let data = null;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static cicloFaturamento(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/limite-global/backoffice/ciclo-faturamento';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static cicloFaturamento1(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/limite-global/backoffice/ciclo-faturamento';
            const configs = getConfigs('put', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static cicloFaturamento2(options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/limite-global/backoffice/ciclo-faturamento';
            const configs = getConfigs('get', 'application/json', url, options);
            let data = null;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static cicloFaturamento3(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/limite-global/backoffice/ciclo-faturamento/{chaveDeCiclo}';
            url = url.replace('{chaveDeCiclo}', params['chaveDeCiclo'] + '');
            const configs = getConfigs('get', 'application/json', url, options);
            let data = null;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static cicloFaturamento4(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/limite-global/backoffice/ciclo-faturamento/{chaveDeCiclo}';
            url = url.replace('{chaveDeCiclo}', params['chaveDeCiclo'] + '');
            const configs = getConfigs('delete', 'application/json', url, options);
            let data = null;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static pagina(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/limite-global/backoffice/ciclo-faturamento/por-pagina/{porPagina}/pagina/{pagina}';
            url = url.replace('{porPagina}', params['porPagina'] + '');
            url = url.replace('{pagina}', params['pagina'] + '');
            const configs = getConfigs('get', 'application/json', url, options);
            let data = null;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static clean(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/limite-global/backoffice/limite/clean';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static clean1(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/limite-global/backoffice/limite/clean';
            const configs = getConfigs('put', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static recebiveis(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/limite-global/backoffice/limite/recebiveis';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static recebiveis1(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/limite-global/backoffice/limite/recebiveis';
            const configs = getConfigs('put', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static compartilhado(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/limite-global/backoffice/limite/compartilhado';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static compartilhado1(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/limite-global/backoffice/limite/compartilhado';
            const configs = getConfigs('put', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static compartilhado2(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/limite-global/backoffice/limite/compartilhado/{chaveDeLimite}';
            url = url.replace('{chaveDeLimite}', params['chaveDeLimite'] + '');
            const configs = getConfigs('delete', 'application/json', url, options);
            let data = null;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static alocado(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/limite-global/backoffice/limite/alocado';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static alocado1(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/limite-global/backoffice/limite/alocado';
            const configs = getConfigs('put', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static alocado2(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/limite-global/backoffice/limite/alocado/{chaveDeLimite}';
            url = url.replace('{chaveDeLimite}', params['chaveDeLimite'] + '');
            const configs = getConfigs('delete', 'application/json', url, options);
            let data = null;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static limite(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/limite-global/backoffice/limite/{identificacaoFiscal}';
            url = url.replace('{identificacaoFiscal}', params['identificacaoFiscal'] + '');
            const configs = getConfigs('get', 'application/json', url, options);
            let data = null;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static limite1(options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/limite-global/backoffice/limite';
            const configs = getConfigs('get', 'application/json', url, options);
            let data = null;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static detalhes(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/limite-global/backoffice/limite/{chaveDeLimite}/detalhes';
            url = url.replace('{chaveDeLimite}', params['chaveDeLimite'] + '');
            const configs = getConfigs('get', 'application/json', url, options);
            let data = null;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static fatura(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/limite-global/backoffice/fatura';
            const configs = getConfigs('get', 'application/json', url, options);
            configs.params = {
                status: params['status'],
                nome: params['nome'],
                identificacaoFiscal: params['identificacaoFiscal'],
                dataInicio: params['dataInicio'],
                dataFim: params['dataFim'],
                chaveDeLimite: params['chaveDeLimite'],
                porPagina: params['porPagina'],
                pagina: params['pagina']
            };
            let data = null;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static relatorio(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/limite-global/backoffice/fatura/relatorio';
            const configs = getConfigs('get', 'application/json', url, options);
            configs.params = {
                status: params['status'],
                nome: params['nome'],
                identificacaoFiscal: params['identificacaoFiscal'],
                dataInicio: params['dataInicio'],
                dataFim: params['dataFim']
            };
            let data = null;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static reenvio(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/limite-global/backoffice/fatura/reenvio';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static arquivos(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/limite-global/backoffice/fatura/arquivos';
            const configs = getConfigs('get', 'application/json', url, options);
            configs.params = { chavesDeFatura: params['chavesDeFatura'] };
            let data = null;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
}
export class LimiteOperacaoCartaoProcessadoraBackofficeService {
    /**
     *
     */
    static incluirAlterarLimiteOperacao(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/limite-operacao-cartao-processadora/backoffice/incluir-alterar-limite-operacao';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static consultarLimiteOperacao(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/limite-operacao-cartao-processadora/backoffice/consultar-limite-operacao';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static consultarLimiteOperacaoValores(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/limite-operacao-cartao-processadora/backoffice/consultar-limite-operacao-valores';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
}
export class LimitesClientesBackofficeService {
    /**
     *
     */
    static finalizarSocilitacao(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/limites-clientes/backoffice/finalizar-socilitacao';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static consultarSolicitacoesPendentes(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/limites-clientes/backoffice/consultar-solicitacoes-pendentes';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static consultarUltimasTresSolicitacoes(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/limites-clientes/backoffice/consultar-ultimas-tres-solicitacoes';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static cancelarSolicitacoes(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/limites-clientes/backoffice/cancelar-solicitacoes';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
}
export class ListaRestritivaBackofficeService {
    /**
     *
     */
    static incluirRegistro(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/lista-restritiva/backoffice/incluir-registro';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static consultarRegistro(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/lista-restritiva/backoffice/consultar-registro';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static deletarRegistro(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/lista-restritiva/backoffice/deletar-registro';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
}
export class NotificacoesBackofficeService {
    /**
     *
     */
    static dispararPush(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/notificacoes/backoffice/disparar-push';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static dispararPushIndividual(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/notificacoes/backoffice/disparar-push-individual';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static dispararPushDirecionado(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/notificacoes/backoffice/disparar-push-direcionado';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static listarImportacoesPushDirecionado(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/notificacoes/backoffice/listar-importacoes-push-direcionado';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static baixarRetornoImportacoesPushDirecionado(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/notificacoes/backoffice/baixar-retorno-importacoes-push-direcionado';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
}
export class ParametroDeValidacaoTransacionalBackofficeService {
    /**
     *
     */
    static facematch(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/parametros-validacao-transacional/backoffice/{organizationUnitId}/facematch';
            url = url.replace('{organizationUnitId}', params['organizationUnitId'] + '');
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static facematch1(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/parametros-validacao-transacional/backoffice/{organizationUnitId}/facematch';
            url = url.replace('{organizationUnitId}', params['organizationUnitId'] + '');
            const configs = getConfigs('put', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static facematch2(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/parametros-validacao-transacional/backoffice/facematch';
            const configs = getConfigs('delete', 'application/json', url, options);
            configs.params = {
                ParametroValidacaoFacematchId: params['parametroValidacaoFacematchId'],
                ChaveDeIdempotencia: params['chaveDeIdempotencia'],
                Metadados: params['metadados']
            };
            let data = null;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static facematch3(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/parametros-validacao-transacional/backoffice/facematch';
            const configs = getConfigs('get', 'application/json', url, options);
            configs.params = {
                OrganizationUnitId: params['organizationUnitId'],
                TipoValidacaoFacematch: params['tipoValidacaoFacematch'],
                Valor: params['valor'],
                ValorQuantitativoValidacaoFacematch: params['valorQuantitativoValidacaoFacematch'],
                Ativo: params['ativo'],
                Ordenacao: params['ordenacao'],
                Pagina: params['pagina'],
                porPagina: params['porPagina'],
                ChaveDeIdempotencia: params['chaveDeIdempotencia'],
                Metadados: params['metadados']
            };
            let data = null;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static facematchTransacoes(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/parametros-validacao-transacional/backoffice/facematch-transacoes';
            const configs = getConfigs('get', 'application/json', url, options);
            configs.params = {
                TransacaoId: params['transacaoId'],
                TiposValidacao: params['tiposValidacao'],
                DataInicio: params['dataInicio'],
                DataFim: params['dataFim'],
                Pagina: params['pagina'],
                porPagina: params['porPagina'],
                ChaveDeIdempotencia: params['chaveDeIdempotencia'],
                Metadados: params['metadados']
            };
            let data = null;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
}
export class ParametrosGlobaisBackofficeService {
    /**
     *
     */
    static alterarRegistroLimiteNorturnoPix(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/parametros-globais/backoffice/alterar-registro-limite-norturno-pix';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static alterarRegistroLimitePixSaqueTroco(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/parametros-globais/backoffice/alterar-registro-limite-pix-saque-troco';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static consultarRegistroLimiteNorturnoPix(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/parametros-globais/backoffice/consultar-registro-limite-norturno-pix';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static consultarRegistroLimiteNorturnoSaqueTrocoPix(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/parametros-globais/backoffice/consultar-registro-limite-norturno-saque-troco-pix';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static consultarRegistroLimiteDiurnoSaqueTrocoPix(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/parametros-globais/backoffice/consultar-registro-limite-diurno-saque-troco-pix';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
}
export class ParametroSimilaridadeFaceMatchBackofficeService {
    /**
     * Cadastrar ou alterar o parametro
     */
    static parametroSimilaridadeFacematch(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/parametro-similaridade-facematch';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     * Consultar o parametro similaridade facematch
     */
    static parametroSimilaridadeFacematch1(options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/parametro-similaridade-facematch';
            const configs = getConfigs('get', 'application/json', url, options);
            let data = null;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
}
export class ParametrosOnboardBackofficeService {
    /**
     *
     */
    static consultaDadosCliente(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/onboarding/backoffice/consulta-dados-cliente';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static enviarEmailConviteFila(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/onboarding/backoffice/enviar-email-convite-fila';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static alterarEmailCliente(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/onboarding/backoffice/alterar-email-cliente';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static consultarNumerosClientes(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/onboarding/backoffice/consultar-numeros-clientes';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
}
export class ParametrosUnidadeOrganizacionalBackofficeService {
    /**
     *
     */
    static alterarParametrosUnidadeOrganizacional(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/parametros-uo/backoffice/alterar-parametros-unidade-organizacional';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static obterParametrosUnidadeOrganizacional(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/parametros-uo/backoffice/obter-parametros-unidade-organizacional';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static enviarLogoParceiro(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/parametros-uo/backoffice/enviar-logo-parceiro';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
}
export class PortabilidadeSalarialBackofficeService {
    /**
     *
     */
    static listar(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/portabilidade-salarial/backoffice/listar';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
}
export class PromocoesBackofficeService {
    /**
     *
     */
    static criarCodigoPromocionalUo(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/promocao/backoffice/criar-codigo-promocional-uo';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static alterarCodigoPromocionalUo(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/promocao/backoffice/alterar-codigo-promocional-uo';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static excluirCodigoPromocionalUo(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/promocao/backoffice/excluir-codigo-promocional-uo';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static obterCodigoPromocionalUo(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/promocao/backoffice/obter-codigo-promocional-uo';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static consultarCodigoPromocionalUo(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/promocao/backoffice/consultar-codigo-promocional-uo';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static ativarCashBack(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/promocao/backoffice/ativar-cash-back';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static atualizarChavePublicaArquivoCashbackSmartphone(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/promocao/backoffice/atualizar-chave-publica-arquivo-cashback-smartphone';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static enviarArquivoCashbackSmartphone(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/promocao/backoffice/enviar-arquivo-cashback-smartphone';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static listarArquivosCashbackSmartphone(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/promocao/backoffice/listar-arquivos-cashback-smartphone';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static baixarRetornoImportacaoCashbackSmartphone(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/promocao/backoffice/baixar-retorno-importacao-cashback-smartphone';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static enviarArquivoCashbackDimo(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/promocao/backoffice/enviar-arquivo-cashback-dimo';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static listarArquivosCashbackDimo(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/promocao/backoffice/listar-arquivos-cashback-dimo';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static baixarRetornoImportacaoCashbackDimo(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/promocao/backoffice/baixar-retorno-importacao-cashback-dimo';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
}
export class RolesService {
    /**
     *
     */
    static criar(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/role/backoffice/criar';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static buscarPermissoes(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/role/backoffice/buscar-permissoes';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static consultarPermissoes(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/role/backoffice/consultar-permissoes';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static pesquisarPermissoes(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/role/backoffice/pesquisar-permissoes';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static atualizar(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/role/backoffice/atualizar';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static excluir(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/role/backoffice/excluir';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
}
export class SegmentosBeneficioBackofficeService {
    /**
     *
     */
    static criar(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/segmento-beneficio/backoffice/criar';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static alterar(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/segmento-beneficio/backoffice/alterar';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static listar(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/segmento-beneficio/backoffice/listar';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static excluir(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/segmento-beneficio/backoffice/excluir';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static configurarServicoComSegmentoBeneficio(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/segmento-beneficio/backoffice/configurar-servico-com-segmento-beneficio';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
}
export class TermosOrganizacoesBackofficeService {
    /**
     *
     */
    static termos(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/backoffice/organizacoes/{organizacaoId}/termos';
            url = url.replace('{organizacaoId}', params['organizacaoId'] + '');
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
}
export class UnidadeOrganizacionalBackOfficeService {
    /**
     *
     */
    static consultarUnidades(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/unidades-organizacionais/backoffice/consultar-unidades';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static obterConfiguracaoUo(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/unidades-organizacionais/backoffice/obter-configuracao-uo';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static buscarUsuarioApi(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/unidades-organizacionais/backoffice/buscar-usuario-api';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static criarUsuarioApi(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/unidades-organizacionais/backoffice/criar-usuario-api';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static resetSenhaUsuarioApi(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/unidades-organizacionais/backoffice/reset-senha-usuario-api';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
}
export class UsuariosBackofficeService {
    /**
     * Realiza a inserção de um usuário.
     */
    static cadastrarUsuarioSimples(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/usuarios/backoffice/cadastrar-usuario-simples';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static atualizarUsuario(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/usuarios/backoffice/atualizar-usuario';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static solicitarAlteracaoSenha(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/usuarios/backoffice/solicitar-alteracao-senha';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static alterarSenhaAcesso(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/usuarios/backoffice/alterar-senha-acesso';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static alterarSenhaUsuario(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/usuarios/backoffice/alterar-senha-usuario';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static excluirUsuario(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/usuarios/backoffice/excluir-usuario';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static listarUsuarios(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/usuarios/backoffice/listar-usuarios';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static buscarDadosUsuarioLogado(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/usuarios/backoffice/buscar-dados-usuario-logado';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static buscarUsuario(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/usuarios/backoffice/buscar-usuario';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static bloquear(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/usuarios/backoffice/bloquear';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static desbloquear(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/usuarios/backoffice/desbloquear';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static exportarArquivo(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/usuarios/backoffice/exportar-arquivo';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
}
export class UtilidadesBackofficeService {
    /**
     *
     */
    static buscarMotivosTransacoes(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/utilidades-backoffice/buscar-motivos-transacoes';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
    /**
     *
     */
    static buscarBancos(params = {}, options = {}) {
        return new Promise((resolve, reject) => {
            let url = basePath + '/utilidades-backoffice/buscar-bancos';
            const configs = getConfigs('post', 'application/json', url, options);
            let data = params.body;
            configs.data = data;
            axios(configs, resolve, reject);
        });
    }
}
export class AddDefaultOutput {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
    }
}
export class AddInputDto {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
    }
}
export class AddOutputDto {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
    }
}
export class AjaxResponseBase {
    constructor(data = {}) {
        this['targetUrl'] = data['targetUrl'];
        this['success'] = data['success'];
        this['error'] = data['error'];
        this['unAuthorizedRequest'] = data['unAuthorizedRequest'];
        this['__abp'] = data['__abp'];
    }
}
export class AnexoEntrada {
    constructor(data = {}) {
        this['nomeArquivo'] = data['nomeArquivo'];
        this['arquivo'] = data['arquivo'];
    }
}
export class AnexoRetorno {
    constructor(data = {}) {
        this['anexoId'] = data['anexoId'];
        this['nomeAnexo'] = data['nomeAnexo'];
        this['urlAnexo'] = data['urlAnexo'];
        this['extensao'] = data['extensao'];
    }
}
export class Arquivo {
    constructor(data = {}) {
        this['base64'] = data['base64'];
        this['extensao'] = data['extensao'];
        this['url'] = data['url'];
    }
}
export class ArquivoDto {
    constructor(data = {}) {
        this['nome'] = data['nome'];
        this['arquivo'] = data['arquivo'];
    }
}
export class ArquivoFatura {
    constructor(data = {}) {
        this['nome'] = data['nome'];
        this['url'] = data['url'];
    }
}
export class Autenticacao2FADto {
    constructor(data = {}) {
        this['tipo'] = data['tipo'];
        this['token'] = data['token'];
        this['agente'] = data['agente'];
        this['faceMatch'] = data['faceMatch'];
    }
}
export class BloquearInput {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['chaveDaConta'] = data['chaveDaConta'];
    }
}
export class BloqueioDesbloqueioGenericoOut {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['contasOut'] = data['contasOut'];
    }
}
export class CartaoOut {
    constructor(data = {}) {
        this['chaveDeCartao'] = data['chaveDeCartao'];
        this['status'] = data['status'];
        this['erro'] = data['erro'];
    }
}
export class CartoesDto {
    constructor(data = {}) {
        this['chaveDeCartao'] = data['chaveDeCartao'];
        this['nomePortador'] = data['nomePortador'];
        this['cartaoNumero'] = data['cartaoNumero'];
        this['cartaoDataExpiracao'] = data['cartaoDataExpiracao'];
        this['cvv'] = data['cvv'];
        this['idStatusCartao'] = data['idStatusCartao'];
        this['descricaoStatusCartao'] = data['descricaoStatusCartao'];
        this['fisico'] = data['fisico'];
        this['bandeira'] = data['bandeira'];
        this['bandeiraNome'] = data['bandeiraNome'];
        this['programa'] = data['programa'];
        this['dataEmissao'] = data['dataEmissao'];
        this['tipoPortador'] = data['tipoPortador'];
        this['isSegundaVia'] = data['isSegundaVia'];
        this['codigoRastreamento'] = data['codigoRastreamento'];
        this['linkDeRastreio'] = data['linkDeRastreio'];
        this['gruposMccs'] = data['gruposMccs'];
    }
}
export class CodigoPromocionalUoDto {
    constructor(data = {}) {
        this['codigoPromocionalId'] = data['codigoPromocionalId'];
        this['organizationUnitId'] = data['organizationUnitId'];
        this['motivoTransacaoId'] = data['motivoTransacaoId'];
        this['codigoArbi'] = data['codigoArbi'];
        this['codigoConductor'] = data['codigoConductor'];
        this['valorPromocao'] = data['valorPromocao'];
        this['valorPorcentagemCashBack'] = data['valorPorcentagemCashBack'];
        this['dataInicio'] = data['dataInicio'];
        this['dataFim'] = data['dataFim'];
        this['limiteCashbackPorCliente'] = data['limiteCashbackPorCliente'];
    }
}
export class ComboOutputDto {
    constructor(data = {}) {
        this['id'] = data['id'];
        this['descricao'] = data['descricao'];
    }
}
export class ConsultarDadosClienteFilaOnboardDto {
    constructor(data = {}) {
        this['chaveDeFilaOnboarding'] = data['chaveDeFilaOnboarding'];
        this['nomeCompleto'] = data['nomeCompleto'];
        this['posicaoFila'] = data['posicaoFila'];
        this['statusFilaOnboard'] = data['statusFilaOnboard'];
        this['numeroDiasRestantesFila'] = data['numeroDiasRestantesFila'];
        this['dataInsercaoFila'] = data['dataInsercaoFila'];
        this['email'] = data['email'];
        this['dataCadastroLiberado'] = data['dataCadastroLiberado'];
        this['telefoneMovel'] = data['telefoneMovel'];
    }
}
export class ContaOut {
    constructor(data = {}) {
        this['chaveDeConta'] = data['chaveDeConta'];
        this['subConta'] = data['subConta'];
        this['erro'] = data['erro'];
        this['status'] = data['status'];
        this['cartoes'] = data['cartoes'];
    }
}
export class DadosClienteAnaliseMesa {
    constructor(data = {}) {
        this['nome'] = data['nome'];
        this['rg'] = data['rg'];
        this['nomeMae'] = data['nomeMae'];
        this['nomePai'] = data['nomePai'];
        this['nacionalidade'] = data['nacionalidade'];
    }
}
export class DadosContaAtendimento {
    constructor(data = {}) {
        this['chaveDeConta'] = data['chaveDeConta'];
        this['parceiro'] = data['parceiro'];
        this['produto'] = data['produto'];
        this['identificacaoFiscal'] = data['identificacaoFiscal'];
        this['nome'] = data['nome'];
        this['statusConta'] = data['statusConta'];
        this['statusCadastroConta'] = data['statusCadastroConta'];
        this['descricaoStatusCadastroConta'] = data['descricaoStatusCadastroConta'];
    }
}
export class DadosSimplificadosContratoDto {
    constructor(data = {}) {
        this['idContrato'] = data['idContrato'];
        this['valorRecebivel'] = data['valorRecebivel'];
        this['statusContrato'] = data['statusContrato'];
        this['dataFinanciamento'] = data['dataFinanciamento'];
        this['numeroNotaFiscal'] = data['numeroNotaFiscal'];
        this['numeroDeParcelas'] = data['numeroDeParcelas'];
        this['valorTotalFinanciado'] = data['valorTotalFinanciado'];
        this['valorEntrada'] = data['valorEntrada'];
        this['emAtraso'] = data['emAtraso'];
        this['dataProximoVencimento'] = data['dataProximoVencimento'];
    }
}
export class DesbloquearInput {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['chaveDaConta'] = data['chaveDaConta'];
    }
}
export class EmailDto {
    constructor(data = {}) {
        this['email'] = data['email'];
        this['confirmacaoEmail'] = data['confirmacaoEmail'];
    }
}
export class EnderecoCorrespondenciaDto {
    constructor(data = {}) {
        this['rua'] = data['rua'];
        this['numero'] = data['numero'];
        this['complemento'] = data['complemento'];
        this['bairro'] = data['bairro'];
        this['cep'] = data['cep'];
        this['cidade'] = data['cidade'];
        this['uf'] = data['uf'];
    }
}
export class EnderecoDto {
    constructor(data = {}) {
        this['rua'] = data['rua'];
        this['numero'] = data['numero'];
        this['complemento'] = data['complemento'];
        this['bairro'] = data['bairro'];
        this['cep'] = data['cep'];
        this['cidade'] = data['cidade'];
        this['uf'] = data['uf'];
    }
}
export class EntradaHistoricoCartao {
    constructor(data = {}) {
        this['data'] = data['data'];
        this['descricaoMotivo'] = data['descricaoMotivo'];
        this['codigoMotivo'] = data['codigoMotivo'];
        this['responsavelAlteracao'] = data['responsavelAlteracao'];
    }
}
export class ErrorInfo {
    constructor(data = {}) {
        this['code'] = data['code'];
        this['message'] = data['message'];
        this['details'] = data['details'];
        this['validationErrors'] = data['validationErrors'];
    }
}
export class ErrosCafDto {
    constructor(data = {}) {
        this['retornoAntiFraudeId'] = data['retornoAntiFraudeId'];
        this['retornoAntiFraudeDescricao'] = data['retornoAntiFraudeDescricao'];
        this['sessaoId'] = data['sessaoId'];
        this['sessaoDescricao'] = data['sessaoDescricao'];
    }
}
export class Fila {
    constructor(data = {}) {
        this['nome'] = data['nome'];
        this['quantidade'] = data['quantidade'];
    }
}
export class HistoricoAnaliseMesa {
    constructor(data = {}) {
        this['nome'] = data['nome'];
        this['identificacaoFiscal'] = data['identificacaoFiscal'];
        this['organizationUnitId'] = data['organizationUnitId'];
        this['organizationUnitDescricao'] = data['organizationUnitDescricao'];
        this['status'] = data['status'];
        this['dataCadastro'] = data['dataCadastro'];
        this['dataAnalise'] = data['dataAnalise'];
        this['usuarioAnalise'] = data['usuarioAnalise'];
        this['usuarioId'] = data['usuarioId'];
        this['clienteId'] = data['clienteId'];
    }
}
export class HistoricoAnaliseMesaAddOutputPaginated {
    constructor(data = {}) {
        this['items'] = data['items'];
        this['qtdPaginas'] = data['qtdPaginas'];
        this['qtdPorPagina'] = data['qtdPorPagina'];
        this['totalItems'] = data['totalItems'];
        this['pagina'] = data['pagina'];
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
    }
}
export class InAjustarDadosPrimeiroAcesso {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeCliente'] = data['chaveDeCliente'];
    }
}
export class InAlterarCicloFaturamento {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['tipo'] = data['tipo'];
        this['diasCiclo'] = data['diasCiclo'];
        this['diasVencimento'] = data['diasVencimento'];
        this['chaveDeCiclo'] = data['chaveDeCiclo'];
    }
}
export class InAlterarConvenioPublicoDto {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['convenioId'] = data['convenioId'];
        this['orgaoPublico'] = data['orgaoPublico'];
        this['nivelOrgaoPublico'] = data['nivelOrgaoPublico'];
        this['codigoDesconto'] = data['codigoDesconto'];
        this['usuario'] = data['usuario'];
        this['senha'] = data['senha'];
        this['ativo'] = data['ativo'];
    }
}
export class InAlterarDadosFuncionario {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeCliente'] = data['chaveDeCliente'];
        this['genero'] = data['genero'];
        this['matricula'] = data['matricula'];
        this['admissaoData'] = data['admissaoData'];
        this['salario'] = data['salario'];
        this['inss'] = data['inss'];
        this['irrf'] = data['irrf'];
        this['cargo'] = data['cargo'];
        this['estadoCivil'] = data['estadoCivil'];
    }
}
export class InAlterarDimoPayConfiguracaoLoja {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['agencia'] = data['agencia'];
        this['conta'] = data['conta'];
    }
}
export class InAlterarEmailClienteFilaOnboard {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeFilaOnboarding'] = data['chaveDeFilaOnboarding'];
        this['email'] = data['email'];
    }
}
export class InAlterarLimiteFilhoGlobal {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeLimite'] = data['chaveDeLimite'];
        this['valor'] = data['valor'];
        this['descricao'] = data['descricao'];
    }
}
export class InAlterarLimiteGlobal {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeLimite'] = data['chaveDeLimite'];
        this['valor'] = data['valor'];
        this['descricao'] = data['descricao'];
        this['travaDeLimite'] = data['travaDeLimite'];
        this['diaReferencia'] = data['diaReferencia'];
    }
}
export class InAlterarParametroGlobalPixNoturno {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['horaInicio'] = data['horaInicio'];
        this['horaFim'] = data['horaFim'];
        this['valor'] = data['valor'];
    }
}
export class InAlterarParametroValidacaoFacematch {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['parametroValidacaoFacematchId'] = data['parametroValidacaoFacematchId'];
        this['valor'] = data['valor'];
        this['valorQuantitativoValidacaoFacematch'] = data['valorQuantitativoValidacaoFacematch'];
        this['ativo'] = data['ativo'];
    }
}
export class InAlterarParametrosCashin {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['tipoConta'] = data['tipoConta'];
        this['valor'] = data['valor'];
        this['emails'] = data['emails'];
    }
}
export class InAlterarParametrosUnidadeOrganizacional {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['organizationUnitId'] = data['organizationUnitId'];
        this['sms'] = data['sms'];
        this['email'] = data['email'];
        this['push'] = data['push'];
        this['abrirContaAutomaticamente'] = data['abrirContaAutomaticamente'];
        this['webhook'] = data['webhook'];
        this['tipoConta'] = data['tipoConta'];
        this['chaveDeProduto'] = data['chaveDeProduto'];
        this['login2fa'] = data['login2fa'];
        this['validarDispositivoNoLogin'] = data['validarDispositivoNoLogin'];
        this['somenteUmDispositivo'] = data['somenteUmDispositivo'];
        this['exclusaoPorTempoInatividade'] = data['exclusaoPorTempoInatividade'];
        this['exclusaoPorCAFNegado'] = data['exclusaoPorCAFNegado'];
        this['geraCartaoVirtual'] = data['geraCartaoVirtual'];
        this['geraCartaoFisico'] = data['geraCartaoFisico'];
        this['tipoLoteCartaoFisico'] = data['tipoLoteCartaoFisico'];
        this['diaEnvioLoteCartaoFisico'] = data['diaEnvioLoteCartaoFisico'];
        this['senhaAdm'] = data['senhaAdm'];
        this['dominioDeEmail'] = data['dominioDeEmail'];
        this['chaveCapturaSmsAndroid'] = data['chaveCapturaSmsAndroid'];
        this['avisoTentativasLogin'] = data['avisoTentativasLogin'];
        this['aceitaNovosCadastros'] = data['aceitaNovosCadastros'];
        this['analisarTransacoes'] = data['analisarTransacoes'];
        this['chaveAssinaturaWebhook'] = data['chaveAssinaturaWebhook'];
        this['empresaIdAntecipacao'] = data['empresaIdAntecipacao'];
        this['requerValidacaoEmailParaCadastro'] = data['requerValidacaoEmailParaCadastro'];
        this['uoConfiavel'] = data['uoConfiavel'];
        this['notificaEmailCompraCartao'] = data['notificaEmailCompraCartao'];
        this['limiteDeContasPorIF'] = data['limiteDeContasPorIF'];
        this['permitirContaAdicionalPorIF'] = data['permitirContaAdicionalPorIF'];
        this['emailsNotificacoesFraudes'] = data['emailsNotificacoesFraudes'];
        this['aceitaNovosCadastrosSemPreCadastro'] = data['aceitaNovosCadastrosSemPreCadastro'];
        this['possuiCreditoConsignado'] = data['possuiCreditoConsignado'];
        this['parcelaMaximaAntecipacao'] = data['parcelaMaximaAntecipacao'];
        this['parcelaMaximaCreditoConsignado'] = data['parcelaMaximaCreditoConsignado'];
        this['termoPrivacidadeObrigatorio'] = data['termoPrivacidadeObrigatorio'];
        this['subcontasPorPortador'] = data['subcontasPorPortador'];
        this['requerValidacaoFacialAtualizacaoCadastral'] = data['requerValidacaoFacialAtualizacaoCadastral'];
        this['requerValidacaoFacialAntecipacaoFgts'] = data['requerValidacaoFacialAntecipacaoFgts'];
        this['requerValidacaoFacialPortabilidadeSalarial'] = data['requerValidacaoFacialPortabilidadeSalarial'];
        this['validarDispositivoPorFaceMatch'] = data['validarDispositivoPorFaceMatch'];
    }
}
export class InAlterarRegistroLimiteSaqueTrocoPix {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['valorDiurno'] = data['valorDiurno'];
        this['valorNoturno'] = data['valorNoturno'];
    }
}
export class InAlterarSegmentoBeneficio {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['segmentoBeneficioId'] = data['segmentoBeneficioId'];
        this['nome'] = data['nome'];
        this['gruposMcc'] = data['gruposMcc'];
    }
}
export class InAlterarSenhaAcessoUserBackoffice {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['autenticacao2FA'] = data['autenticacao2FA'];
        this['novaSenha'] = data['novaSenha'];
        this['confirmarNovaSenha'] = data['confirmarNovaSenha'];
    }
}
export class InAlterarSenhaUsuario {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['identificacaoFiscal'] = data['identificacaoFiscal'];
        this['autenticacao2FA'] = data['autenticacao2FA'];
        this['novaSenha'] = data['novaSenha'];
        this['confirmarNovaSenha'] = data['confirmarNovaSenha'];
    }
}
export class InAlterarStatusAparelho {
    constructor(data = {}) {
        this['observacao'] = data['observacao'];
    }
}
export class InAlterarTipoCartoes {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['descricao'] = data['descricao'];
        this['package'] = data['package'];
        this['subPackage'] = data['subPackage'];
        this['bandeira'] = data['bandeira'];
        this['possuiPlastico'] = data['possuiPlastico'];
        this['modalidade'] = data['modalidade'];
        this['recarregavel'] = data['recarregavel'];
        this['fisico'] = data['fisico'];
        this['produtoId'] = data['produtoId'];
        this['idImagem'] = data['idImagem'];
        this['idTipoPlastico'] = data['idTipoPlastico'];
        this['isContactless'] = data['isContactless'];
        this['limiteContactlessSemSenha'] = data['limiteContactlessSemSenha'];
    }
}
export class InAlterarUsuario {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['usuarioId'] = data['usuarioId'];
        this['userName'] = data['userName'];
        this['name'] = data['name'];
        this['surname'] = data['surname'];
        this['emailAddress'] = data['emailAddress'];
        this['isActive'] = data['isActive'];
        this['fullName'] = data['fullName'];
        this['lastLoginTime'] = data['lastLoginTime'];
        this['creationTime'] = data['creationTime'];
        this['roleNames'] = data['roleNames'];
        this['newPassword'] = data['newPassword'];
        this['idOrganizationUnit'] = data['idOrganizationUnit'];
        this['ipWhiteList'] = data['ipWhiteList'];
    }
}
export class InArquivoCashback {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['organizationUnitId'] = data['organizationUnitId'];
        this['arquivo'] = data['arquivo'];
        this['nomeArquivo'] = data['nomeArquivo'];
    }
}
export class InArquivoCashbackDimo {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['organizationUnitId'] = data['organizationUnitId'];
        this['arquivo'] = data['arquivo'];
        this['nomeArquivo'] = data['nomeArquivo'];
        this['codHistorico'] = data['codHistorico'];
    }
}
export class InAtivarCashBack {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeConta'] = data['chaveDeConta'];
        this['valorCalculoCashBack'] = data['valorCalculoCashBack'];
        this['codHistorico'] = data['codHistorico'];
    }
}
export class InAtivarTokenVisaBackoffice {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeCartao'] = data['chaveDeCartao'];
    }
}
export class InAtualizarChamado {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['descricao'] = data['descricao'];
        this['status'] = data['status'];
        this['id'] = data['id'];
        this['categorizacaoNivel0'] = data['categorizacaoNivel0'];
        this['categorizacaoNivel1'] = data['categorizacaoNivel1'];
        this['categorizacaoNivel2'] = data['categorizacaoNivel2'];
    }
}
export class InAtualizarChavePublicaArquivo {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chavePublica'] = data['chavePublica'];
    }
}
export class InAtualizarLogoParceiro {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['organizationUnitId'] = data['organizationUnitId'];
        this['nomeArquivo'] = data['nomeArquivo'];
        this['arquivo'] = data['arquivo'];
    }
}
export class InAtualizarRole {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['roleId'] = data['roleId'];
        this['name'] = data['name'];
        this['displayName'] = data['displayName'];
        this['description'] = data['description'];
        this['grantedPermissions'] = data['grantedPermissions'];
    }
}
export class InAvaliarContratosPendentes {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['listaPropostas'] = data['listaPropostas'];
        this['aprova'] = data['aprova'];
    }
}
export class InBackofficeConsultarHistoricoCartao {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeCartao'] = data['chaveDeCartao'];
    }
}
export class InBaixarRetornoArquivoCashbackDimo {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['importacaoId'] = data['importacaoId'];
    }
}
export class InBaixarRetornoImportacaoCashbackSmartphone {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['importacaoId'] = data['importacaoId'];
        this['organizationUnitId'] = data['organizationUnitId'];
    }
}
export class InBaixarRetornoImportacaoNotificacao {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['importacaoId'] = data['importacaoId'];
        this['organizationUnitId'] = data['organizationUnitId'];
    }
}
export class InBloquearUsuarioBackoffice {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['userId'] = data['userId'];
    }
}
export class InBuscarCategoriasAtendimento {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['nivel0'] = data['nivel0'];
        this['nivel1'] = data['nivel1'];
    }
}
export class InBuscarHistoricoAnaliseMesa {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['identificacaoFiscal'] = data['identificacaoFiscal'];
        this['organizationUnitId'] = data['organizationUnitId'];
        this['dataInicio'] = data['dataInicio'];
        this['dataFim'] = data['dataFim'];
        this['pagina'] = data['pagina'];
        this['porPagina'] = data['porPagina'];
    }
}
export class InBuscarHistoricoEntidade {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['tipoEntidade'] = data['tipoEntidade'];
        this['entidadeId'] = data['entidadeId'];
        this['dataInicio'] = data['dataInicio'];
        this['dataFim'] = data['dataFim'];
    }
}
export class InBuscarHistoricoEntidadePaginado {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['tipoEntidade'] = data['tipoEntidade'];
        this['entidadeId'] = data['entidadeId'];
        this['propriedades'] = data['propriedades'];
        this['dataInicio'] = data['dataInicio'];
        this['dataFim'] = data['dataFim'];
        this['pagina'] = data['pagina'];
        this['porPagina'] = data['porPagina'];
    }
}
export class InBuscarParametrosUnidadeOrganizacional {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['organizationUnitId'] = data['organizationUnitId'];
    }
}
export class InBuscarRolesPorUsuario {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['userId'] = data['userId'];
    }
}
export class InBuscarTipoCartoes {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['pagina'] = data['pagina'];
        this['porPagina'] = data['porPagina'];
        this['bandeira'] = data['bandeira'];
        this['descricao'] = data['descricao'];
        this['id'] = data['id'];
        this['fisico'] = data['fisico'];
        this['modalidade'] = data['modalidade'];
    }
}
export class InBuscarUsuarioApi {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['organizationUnitId'] = data['organizationUnitId'];
    }
}
export class InBuscarUsuarioPorId {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['usuarioId'] = data['usuarioId'];
    }
}
export class InCadastrarDimoPayConfiguracaoLoja {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['agencia'] = data['agencia'];
        this['conta'] = data['conta'];
        this['nomeLoja'] = data['nomeLoja'];
    }
}
export class InCadastrarParametroValidacaoFacematch {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['tiposValidacoes'] = data['tiposValidacoes'];
    }
}
export class InCadastrarParametrosCashin {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['tipoConta'] = data['tipoConta'];
        this['valor'] = data['valor'];
        this['emails'] = data['emails'];
    }
}
export class InCadastrarUsuario {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['userName'] = data['userName'];
        this['name'] = data['name'];
        this['surname'] = data['surname'];
        this['idOrganizationUnit'] = data['idOrganizationUnit'];
        this['emailAddress'] = data['emailAddress'];
        this['isActive'] = data['isActive'];
        this['roleNames'] = data['roleNames'];
        this['password'] = data['password'];
        this['ipWhiteList'] = data['ipWhiteList'];
    }
}
export class InCadastroAlterarParametro {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['valor'] = data['valor'];
    }
}
export class InCancelarConta {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeConta'] = data['chaveDeConta'];
        this['tipoCancelamentoConta'] = data['tipoCancelamentoConta'];
        this['motivoCancelamento'] = data['motivoCancelamento'];
    }
}
export class InCancelarSolicitacoesLimitesCliente {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeConta'] = data['chaveDeConta'];
        this['idSolicitacoesLimitesCliente'] = data['idSolicitacoesLimitesCliente'];
    }
}
export class InChamado {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['protocolo'] = data['protocolo'];
        this['chaveDeConta'] = data['chaveDeConta'];
        this['status'] = data['status'];
        this['nivel0'] = data['nivel0'];
        this['nivel1'] = data['nivel1'];
        this['nivel2'] = data['nivel2'];
        this['identificacaoFiscal'] = data['identificacaoFiscal'];
        this['nome'] = data['nome'];
        this['conta'] = data['conta'];
        this['organizationUnitId'] = data['organizationUnitId'];
        this['ordenacao'] = data['ordenacao'];
        this['pagina'] = data['pagina'];
        this['porPagina'] = data['porPagina'];
    }
}
export class InConfigurarServicoComSegmentoBeneficio {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['segmentoBeneficioId'] = data['segmentoBeneficioId'];
        this['servicoId'] = data['servicoId'];
    }
}
export class InConsultaParametrosCVVDinamico {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['pagina'] = data['pagina'];
        this['porPagina'] = data['porPagina'];
    }
}
export class InConsultaRelatorioOnidata {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['fornecedor'] = data['fornecedor'];
        this['periodo'] = data['periodo'];
        this['data'] = data['data'];
        this['organizationUnitId'] = data['organizationUnitId'];
    }
}
export class InConsultarAprovacaoContratos {
    constructor(data = {}) {
        this['pagina'] = data['pagina'];
        this['porPagina'] = data['porPagina'];
        this['statusAvaliacaoUO'] = data['statusAvaliacaoUO'];
        this['localizador'] = data['localizador'];
        this['dataSolicitacaoInicio'] = data['dataSolicitacaoInicio'];
        this['dataSolicitacaoTermino'] = data['dataSolicitacaoTermino'];
        this['chaveDeCliente'] = data['chaveDeCliente'];
        this['unidadeOrganizacionalId'] = data['unidadeOrganizacionalId'];
        this['nome'] = data['nome'];
        this['identificacaoFiscal'] = data['identificacaoFiscal'];
        this['matricula'] = data['matricula'];
    }
}
export class InConsultarConvenios {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['cnpj'] = data['cnpj'];
        this['pagina'] = data['pagina'];
        this['porPagina'] = data['porPagina'];
    }
}
export class InConsultarDadosCartoes {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeConta'] = data['chaveDeConta'];
    }
}
export class InConsultarDadosClienteFilaOnboard {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['organizationUnitId'] = data['organizationUnitId'];
        this['identificacaoFiscal'] = data['identificacaoFiscal'];
    }
}
export class InConsultarExtratoContratos {
    constructor(data = {}) {
        this['numeroDoContrato'] = data['numeroDoContrato'];
        this['dataInicial'] = data['dataInicial'];
        this['dataFinal'] = data['dataFinal'];
        this['statusContratoAntecipacao'] = data['statusContratoAntecipacao'];
        this['pagina'] = data['pagina'];
        this['porPagina'] = data['porPagina'];
    }
}
export class InConsultarLimiteOperacaoCartaoProcessadora {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['idProduto'] = data['idProduto'];
        this['idOperacao'] = data['idOperacao'];
        this['pagina'] = data['pagina'];
        this['porPagina'] = data['porPagina'];
    }
}
export class InConsultarLimiteOperacaoValores {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['idProduto'] = data['idProduto'];
        this['idOperacao'] = data['idOperacao'];
        this['periodoLimite'] = data['periodoLimite'];
    }
}
export class InConsultarNumeroClientesFila {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['organizationUnitId'] = data['organizationUnitId'];
    }
}
export class InConsultarParametrosCashin {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['pagina'] = data['pagina'];
        this['porPagina'] = data['porPagina'];
    }
}
export class InConsultarPortabilidadeBackoffice {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeConta'] = data['chaveDeConta'];
        this['pagina'] = data['pagina'];
        this['porPagina'] = data['porPagina'];
        this['tipoOrdenacao'] = data['tipoOrdenacao'];
    }
}
export class InConsultarSolicitacoesClientesUltimasTres {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['organizationUnitId'] = data['organizationUnitId'];
        this['identificacaoFiscal'] = data['identificacaoFiscal'];
        this['tipoOperacaoLimiteClienteEnum'] = data['tipoOperacaoLimiteClienteEnum'];
    }
}
export class InConsultarSolicitacoesLimites {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['organizationUnitId'] = data['organizationUnitId'];
        this['chaveDeCliente'] = data['chaveDeCliente'];
        this['identificacaoFiscal'] = data['identificacaoFiscal'];
        this['nome'] = data['nome'];
        this['tipoOperacaoLimiteClienteEnum'] = data['tipoOperacaoLimiteClienteEnum'];
        this['pagina'] = data['pagina'];
        this['porPagina'] = data['porPagina'];
    }
}
export class InConsultarTransacaoFaceMatch {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['transacaoId'] = data['transacaoId'];
        this['tiposValidacao'] = data['tiposValidacao'];
        this['dataInicio'] = data['dataInicio'];
        this['dataFim'] = data['dataFim'];
        this['pagina'] = data['pagina'];
        this['porPagina'] = data['porPagina'];
    }
}
export class InConsultarUnidadeBackoffice {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['organizationUnitId'] = data['organizationUnitId'];
        this['tiposDeConta'] = data['tiposDeConta'];
        this['nome'] = data['nome'];
        this['arvore'] = data['arvore'];
        this['pagina'] = data['pagina'];
        this['porPagina'] = data['porPagina'];
    }
}
export class InCriarChamado {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['descricao'] = data['descricao'];
        this['chaveDeConta'] = data['chaveDeConta'];
        this['identificacaoFiscal'] = data['identificacaoFiscal'];
        this['categorizacaoNivel0'] = data['categorizacaoNivel0'];
        this['categorizacaoNivel1'] = data['categorizacaoNivel1'];
        this['categorizacaoNivel2'] = data['categorizacaoNivel2'];
    }
}
export class InCriarCicloFaturamento {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['tipo'] = data['tipo'];
        this['diasCiclo'] = data['diasCiclo'];
        this['diasVencimento'] = data['diasVencimento'];
    }
}
export class InCriarCodigoPromocionalUo {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['motivoTransacao'] = data['motivoTransacao'];
        this['unidadeOrganizacionalId'] = data['unidadeOrganizacionalId'];
        this['codigoArbi'] = data['codigoArbi'];
        this['codigoConductor'] = data['codigoConductor'];
        this['valorPromocao'] = data['valorPromocao'];
        this['valorPorcentagemCashBack'] = data['valorPorcentagemCashBack'];
        this['dataInicio'] = data['dataInicio'];
        this['dataFim'] = data['dataFim'];
        this['limiteCashBackCliente'] = data['limiteCashBackCliente'];
    }
}
export class InCriarLimiteAlocado {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDePessoa'] = data['chaveDePessoa'];
        this['valor'] = data['valor'];
        this['descricao'] = data['descricao'];
        this['chaveDeLimitePai'] = data['chaveDeLimitePai'];
    }
}
export class InCriarLimiteClean {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDePessoa'] = data['chaveDePessoa'];
        this['valor'] = data['valor'];
        this['descricao'] = data['descricao'];
        this['chaveDeCiclo'] = data['chaveDeCiclo'];
        this['diaReferencia'] = data['diaReferencia'];
        this['diasTravaAposVencimento'] = data['diasTravaAposVencimento'];
    }
}
export class InCriarOcorrencia {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['anotacao'] = data['anotacao'];
        this['chamadoId'] = data['chamadoId'];
    }
}
export class InCriarOuAtualizarPessoa {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['identificacaoFiscal'] = data['identificacaoFiscal'];
        this['nome'] = data['nome'];
    }
}
export class InCriarRole {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['name'] = data['name'];
        this['displayName'] = data['displayName'];
        this['organizationUnitId'] = data['organizationUnitId'];
        this['description'] = data['description'];
        this['grantedPermissions'] = data['grantedPermissions'];
    }
}
export class InCriarSegmentoBeneficio {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['nome'] = data['nome'];
        this['gruposMcc'] = data['gruposMcc'];
    }
}
export class InCriarTipoCartao {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['descricao'] = data['descricao'];
        this['package'] = data['package'];
        this['subPackage'] = data['subPackage'];
        this['bandeira'] = data['bandeira'];
        this['possuiPlastico'] = data['possuiPlastico'];
        this['modalidade'] = data['modalidade'];
        this['recarregavel'] = data['recarregavel'];
        this['fisico'] = data['fisico'];
        this['produtoId'] = data['produtoId'];
        this['idImagem'] = data['idImagem'];
        this['idTipoPlastico'] = data['idTipoPlastico'];
        this['isContactless'] = data['isContactless'];
        this['limiteContactlessSemSenha'] = data['limiteContactlessSemSenha'];
    }
}
export class InDadosCelularBackOffice {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeCliente'] = data['chaveDeCliente'];
        this['telefoneMovel'] = data['telefoneMovel'];
    }
}
export class InDadosEmailBackOffice {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeCliente'] = data['chaveDeCliente'];
        this['emailDto'] = data['emailDto'];
    }
}
export class InDadosEndCorrespondencia {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeCliente'] = data['chaveDeCliente'];
        this['enderecoCorrespondencia'] = data['enderecoCorrespondencia'];
    }
}
export class InDadosParametroCVVDinamico {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['minutos'] = data['minutos'];
    }
}
export class InDadosTelefoneBackOffice {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeCliente'] = data['chaveDeCliente'];
        this['telefoneFixo'] = data['telefoneFixo'];
    }
}
export class InDeletarParametroValidacaoFacematch {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['parametroValidacaoFacematchId'] = data['parametroValidacaoFacematchId'];
    }
}
export class InDeletarTipoCartoes {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['id'] = data['id'];
    }
}
export class InDesbloquearPinIncorreto {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeConta'] = data['chaveDeConta'];
    }
}
export class InDispararPushDirecionadoBackoffice {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['organizationUnitId'] = data['organizationUnitId'];
        this['arquivo'] = data['arquivo'];
        this['nomeArquivo'] = data['nomeArquivo'];
        this['titulo'] = data['titulo'];
        this['mensagem'] = data['mensagem'];
    }
}
export class InDispararPushIndividualBackoffice {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeCliente'] = data['chaveDeCliente'];
        this['mensagem'] = data['mensagem'];
        this['titulo'] = data['titulo'];
    }
}
export class InDispararPushPromocionalBackoffice {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['organizationUnitId'] = data['organizationUnitId'];
        this['mensagem'] = data['mensagem'];
        this['titulo'] = data['titulo'];
        this['baseDisparoPush'] = data['baseDisparoPush'];
    }
}
export class InEnviarEmailConviteFila {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeFilaOnboarding'] = data['chaveDeFilaOnboarding'];
    }
}
export class InExcluirCodigoPromocionalUo {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['motivoTransacao'] = data['motivoTransacao'];
        this['unidadeOrganizacionalId'] = data['unidadeOrganizacionalId'];
    }
}
export class InExcluirConfiguracao {
    constructor(data = {}) {
        this['id'] = data['id'];
    }
}
export class InExcluirRole {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['roleId'] = data['roleId'];
    }
}
export class InExcluirSegmentoBeneficio {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['segmentoBeneficioId'] = data['segmentoBeneficioId'];
    }
}
export class InExcluirUsuario {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['userId'] = data['userId'];
    }
}
export class InExportarArquivoUsuario {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['organizationUnitId'] = data['organizationUnitId'];
        this['palavraChave'] = data['palavraChave'];
        this['tipoUsuario'] = data['tipoUsuario'];
        this['grupoDePermissao'] = data['grupoDePermissao'];
    }
}
export class InFiltrarContas {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeConta'] = data['chaveDeConta'];
        this['identificacaoFiscal'] = data['identificacaoFiscal'];
        this['numeroConta'] = data['numeroConta'];
    }
}
export class InFinalizarSolicitacaoLimiteCliente {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeCliente'] = data['chaveDeCliente'];
        this['limiteSolicitacaoClienteId'] = data['limiteSolicitacaoClienteId'];
        this['situacaoSolicitacao'] = data['situacaoSolicitacao'];
        this['valorAprovacao'] = data['valorAprovacao'];
        this['tipoSolicitacao'] = data['tipoSolicitacao'];
    }
}
export class InGerarNovaSenha {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeCartao'] = data['chaveDeCartao'];
        this['cvv'] = data['cvv'];
        this['dataValidade'] = data['dataValidade'];
        this['identificacaoFiscal'] = data['identificacaoFiscal'];
    }
}
export class InHistoricoMccCartao {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeCartao'] = data['chaveDeCartao'];
    }
}
export class InIdUnidadeOrganizacional {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['idUnidadeOrganizacional'] = data['idUnidadeOrganizacional'];
    }
}
export class InIncluirLimiteOperacaoCartaoProcessadora {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['idProduto'] = data['idProduto'];
        this['idOperacao'] = data['idOperacao'];
        this['periodoLimite'] = data['periodoLimite'];
        this['valor'] = data['valor'];
    }
}
export class InIncluirRegistroListaRestritiva {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chavesDeRestricao'] = data['chavesDeRestricao'];
        this['motivo'] = data['motivo'];
    }
}
export class InInformarPagamento {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['valorInformado'] = data['valorInformado'];
        this['valorDesconto'] = data['valorDesconto'];
        this['numerosDasParcelas'] = data['numerosDasParcelas'];
        this['observacao'] = data['observacao'];
    }
}
export class InInserirAtualizarConfiguracao {
    constructor(data = {}) {
        this['id'] = data['id'];
        this['nomeConfiguracao'] = data['nomeConfiguracao'];
        this['idIntegracao'] = data['idIntegracao'];
        this['motivoTransacao'] = data['motivoTransacao'];
        this['codigoResposta'] = data['codigoResposta'];
        this['padrao'] = data['padrao'];
        this['sucesso'] = data['sucesso'];
        this['descricaoErro'] = data['descricaoErro'];
        this['tipoException'] = data['tipoException'];
        this['mensagemException'] = data['mensagemException'];
    }
}
export class InInserirConvenioPublicoDto {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['orgaoPublico'] = data['orgaoPublico'];
        this['nivelOrgaoPublico'] = data['nivelOrgaoPublico'];
        this['codigoDesconto'] = data['codigoDesconto'];
        this['usuario'] = data['usuario'];
        this['senha'] = data['senha'];
        this['ativo'] = data['ativo'];
    }
}
export class InInserirTermo {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['termo'] = data['termo'];
        this['generico'] = data['generico'];
        this['tipoDeTermo'] = data['tipoDeTermo'];
    }
}
export class InListarArquivosCashbackDimo {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['inicio'] = data['inicio'];
        this['fim'] = data['fim'];
        this['status'] = data['status'];
        this['organizationUnitId'] = data['organizationUnitId'];
        this['pagina'] = data['pagina'];
        this['porPagina'] = data['porPagina'];
    }
}
export class InListarArquivosCashbackSmartphone {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['inicio'] = data['inicio'];
        this['fim'] = data['fim'];
        this['status'] = data['status'];
        this['organizationUnitId'] = data['organizationUnitId'];
        this['pagina'] = data['pagina'];
        this['porPagina'] = data['porPagina'];
    }
}
export class InListarClientesGeraisBackoffice {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeCliente'] = data['chaveDeCliente'];
        this['identificacaoFiscal'] = data['identificacaoFiscal'];
        this['nome'] = data['nome'];
        this['organizationUnitId'] = data['organizationUnitId'];
        this['statusCadastroCliente'] = data['statusCadastroCliente'];
        this['tipoIdentificacaoFiscal'] = data['tipoIdentificacaoFiscal'];
        this['pagina'] = data['pagina'];
        this['porPagina'] = data['porPagina'];
    }
}
export class InListarConfiguracoesDto {
    constructor(data = {}) {
        this['idIntegracao'] = data['idIntegracao'];
        this['pagina'] = data['pagina'];
        this['porPagina'] = data['porPagina'];
    }
}
export class InListarDimoPayConfiguracaoLoja {
    constructor(data = {}) {
        this['nomeLoja'] = data['nomeLoja'];
        this['pagina'] = data['pagina'];
        this['porPagina'] = data['porPagina'];
    }
}
export class InListarLotesEmissaoCartaoFisico {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['organizationUnitId'] = data['organizationUnitId'];
        this['dataInicio'] = data['dataInicio'];
        this['dataFim'] = data['dataFim'];
        this['status'] = data['status'];
        this['pagina'] = data['pagina'];
        this['porPagina'] = data['porPagina'];
    }
}
export class InListarProcessamentoArquivosNoficacao {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['dataInicio'] = data['dataInicio'];
        this['dataFim'] = data['dataFim'];
        this['status'] = data['status'];
        this['organizationUnitId'] = data['organizationUnitId'];
        this['pagina'] = data['pagina'];
        this['porPagina'] = data['porPagina'];
    }
}
export class InListarReprovadosCafParaAnaliseMesa {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['identificacaoFiscal'] = data['identificacaoFiscal'];
        this['organizationUnitId'] = data['organizationUnitId'];
        this['pagina'] = data['pagina'];
        this['porPagina'] = data['porPagina'];
    }
}
export class InListarSolicitacaoCadastral {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeCliente'] = data['chaveDeCliente'];
        this['pagina'] = data['pagina'];
        this['porPagina'] = data['porPagina'];
    }
}
export class InListarTodosOsCartoes {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['pagina'] = data['pagina'];
        this['porPagina'] = data['porPagina'];
    }
}
export class InListarUsuarios {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['palavraChave'] = data['palavraChave'];
        this['tipoUsuario'] = data['tipoUsuario'];
        this['organizationUnitId'] = data['organizationUnitId'];
        this['grupoDePermissao'] = data['grupoDePermissao'];
        this['pagina'] = data['pagina'];
        this['porPagina'] = data['porPagina'];
    }
}
export class InMigrarProdutoContaMassivo {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['organizationUnitId'] = data['organizationUnitId'];
        this['chaveDeProdutoDestino'] = data['chaveDeProdutoDestino'];
        this['motivo'] = data['motivo'];
    }
}
export class InObterChamadoPeloId {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['id'] = data['id'];
    }
}
export class InObterCodigoPromocionalUo {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['codigoPromocionalId'] = data['codigoPromocionalId'];
    }
}
export class InObterConta {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDaConta'] = data['chaveDaConta'];
        this['identificacaoFiscal'] = data['identificacaoFiscal'];
    }
}
export class InObterConvenioPublicoDto {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['convenioId'] = data['convenioId'];
    }
}
export class InObterDadosClienteParaAnaliseMesa {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeCliente'] = data['chaveDeCliente'];
    }
}
export class InObterDadosFuncionario {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeCliente'] = data['chaveDeCliente'];
    }
}
export class InObterDadosInformeRendimento {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeConta'] = data['chaveDeConta'];
        this['anoBase'] = data['anoBase'];
    }
}
export class InObterDetalhesHistoricoAnaliseMesa {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['clienteId'] = data['clienteId'];
        this['dataAnalise'] = data['dataAnalise'];
        this['usuarioId'] = data['usuarioId'];
    }
}
export class InObterInfoContaBackoffice {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['identificacaoFiscal'] = data['identificacaoFiscal'];
        this['numeroConta'] = data['numeroConta'];
        this['celular'] = data['celular'];
    }
}
export class InObterListaContas {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeCliente'] = data['chaveDeCliente'];
        this['listarSubcontas'] = data['listarSubcontas'];
        this['pagina'] = data['pagina'];
        this['porPagina'] = data['porPagina'];
    }
}
export class InObterParametroValidacaoFacematch {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['organizationUnitId'] = data['organizationUnitId'];
        this['tipoValidacaoFacematch'] = data['tipoValidacaoFacematch'];
        this['valor'] = data['valor'];
        this['valorQuantitativoValidacaoFacematch'] = data['valorQuantitativoValidacaoFacematch'];
        this['ativo'] = data['ativo'];
        this['ordenacao'] = data['ordenacao'];
        this['pagina'] = data['pagina'];
        this['porPagina'] = data['porPagina'];
    }
}
export class InPesquisarPermissoes {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['permission'] = data['permission'];
        this['keyword'] = data['keyword'];
        this['pagina'] = data['pagina'];
        this['porPagina'] = data['porPagina'];
    }
}
export class InRecebiveisContrato {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['idContrato'] = data['idContrato'];
        this['chaveDeConta'] = data['chaveDeConta'];
    }
}
export class InReenviarFatura {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeLimite'] = data['chaveDeLimite'];
        this['categoriaChamado'] = data['categoriaChamado'];
        this['assunto'] = data['assunto'];
        this['emails'] = data['emails'];
        this['corpoEmail'] = data['corpoEmail'];
        this['descricaoAtendimento'] = data['descricaoAtendimento'];
        this['arquivos'] = data['arquivos'];
    }
}
export class InRegistroListaRestritiva {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chavesDeRestricao'] = data['chavesDeRestricao'];
    }
}
export class InRemoverParametrosCashin {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['tipoConta'] = data['tipoConta'];
    }
}
export class InReprocessarLoteEmissaoCartaoFisicoComErro {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['loteId'] = data['loteId'];
    }
}
export class InSolicitarAlteracaoCadastral {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeCliente'] = data['chaveDeCliente'];
        this['tipoDeSolicitacao'] = data['tipoDeSolicitacao'];
        this['notificarPorEmail'] = data['notificarPorEmail'];
        this['notificarPorSms'] = data['notificarPorSms'];
        this['observacao'] = data['observacao'];
        this['email'] = data['email'];
        this['telefoneMovel'] = data['telefoneMovel'];
        this['enderecoCorrespondencia'] = data['enderecoCorrespondencia'];
    }
}
export class InSolicitarResetSenhaBackoffice {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['userName'] = data['userName'];
    }
}
export class InStatusCliente {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeCliente'] = data['chaveDeCliente'];
    }
}
export class InStatusConta {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeConta'] = data['chaveDeConta'];
    }
}
export class InSubmeterAnaliseMesa {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['chaveDeCliente'] = data['chaveDeCliente'];
        this['aprovar'] = data['aprovar'];
        this['nome'] = data['nome'];
        this['rg'] = data['rg'];
        this['nomeMae'] = data['nomeMae'];
        this['nomePai'] = data['nomePai'];
        this['nacionalidade'] = data['nacionalidade'];
    }
}
export class InTestarConfiguracao {
    constructor(data = {}) {
        this['idIntegracao'] = data['idIntegracao'];
        this['motivoTransacao'] = data['motivoTransacao'];
        this['codigoResposta'] = data['codigoResposta'];
        this['padrao'] = data['padrao'];
        this['sucesso'] = data['sucesso'];
        this['descricaoErro'] = data['descricaoErro'];
        this['tipoException'] = data['tipoException'];
        this['mensagemException'] = data['mensagemException'];
    }
}
export class InUploadAnexoOcorrencia {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['ocorrenciaId'] = data['ocorrenciaId'];
        this['nomeArquivo'] = data['nomeArquivo'];
        this['arquivo'] = data['arquivo'];
    }
}
export class InUploadAnexosOcorrencia {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['ocorrenciaId'] = data['ocorrenciaId'];
        this['anexos'] = data['anexos'];
    }
}
export class InValidarSenha {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['metadados'] = data['metadados'];
        this['autenticacao2FA'] = data['autenticacao2FA'];
        this['chaveDeCartao'] = data['chaveDeCartao'];
    }
}
export class InformeRendimentos {
    constructor(data = {}) {
        this['anoBase'] = data['anoBase'];
        this['tipoPessoa'] = data['tipoPessoa'];
        this['cpfCnpj'] = data['cpfCnpj'];
        this['nomeTitular'] = data['nomeTitular'];
        this['enderecoTitular'] = data['enderecoTitular'];
        this['complementoTitular'] = data['complementoTitular'];
        this['bairroTitular'] = data['bairroTitular'];
        this['cidadeTitular'] = data['cidadeTitular'];
        this['estadoTitular'] = data['estadoTitular'];
        this['cepTitular'] = data['cepTitular'];
        this['agenciaBeneficiario'] = data['agenciaBeneficiario'];
        this['contaBeneficiario'] = data['contaBeneficiario'];
        this['nomeEmpDeclarante'] = data['nomeEmpDeclarante'];
        this['enderecoEmpDeclarante'] = data['enderecoEmpDeclarante'];
        this['complementoEmpDeclarante'] = data['complementoEmpDeclarante'];
        this['bairroEmpDeclarante'] = data['bairroEmpDeclarante'];
        this['cidadeEmpDeclarante'] = data['cidadeEmpDeclarante'];
        this['estadoEmpDeclarante'] = data['estadoEmpDeclarante'];
        this['cepEmpDeclarante'] = data['cepEmpDeclarante'];
        this['cnpjEmpDeclarante'] = data['cnpjEmpDeclarante'];
        this['cnpjPagadora'] = data['cnpjPagadora'];
        this['nomeEmpresaPagadora'] = data['nomeEmpresaPagadora'];
        this['enderecoEmpPagadora'] = data['enderecoEmpPagadora'];
        this['complEnderecoEmpPag'] = data['complEnderecoEmpPag'];
        this['bairroEnderecoEmpPag'] = data['bairroEnderecoEmpPag'];
        this['cidadeEmpPag'] = data['cidadeEmpPag'];
        this['estadoEmpPag'] = data['estadoEmpPag'];
        this['cepEmpPag'] = data['cepEmpPag'];
        this['nomeSistema'] = data['nomeSistema'];
        this['saldoAnterior'] = data['saldoAnterior'];
        this['saldoBaseDezembro'] = data['saldoBaseDezembro'];
        this['informacaoComplementar'] = data['informacaoComplementar'];
    }
}
export class IntegradoresLogConfiguracaoDto {
    constructor(data = {}) {
        this['id'] = data['id'];
        this['nomeConfiguracao'] = data['nomeConfiguracao'];
        this['idIntegracao'] = data['idIntegracao'];
        this['motivoTransacao'] = data['motivoTransacao'];
        this['codigoResposta'] = data['codigoResposta'];
        this['padrao'] = data['padrao'];
        this['sucesso'] = data['sucesso'];
        this['descricaoErro'] = data['descricaoErro'];
        this['tipoException'] = data['tipoException'];
        this['mensagemException'] = data['mensagemException'];
    }
}
export class IntegradoresLogConfiguracaoDtoAddOutputPaginated {
    constructor(data = {}) {
        this['items'] = data['items'];
        this['qtdPaginas'] = data['qtdPaginas'];
        this['qtdPorPagina'] = data['qtdPorPagina'];
        this['totalItems'] = data['totalItems'];
        this['pagina'] = data['pagina'];
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
    }
}
export class ItensLoteEmissao {
    constructor(data = {}) {
        this['chaveDeConta'] = data['chaveDeConta'];
        this['status'] = data['status'];
        this['tipoDeCartaoId'] = data['tipoDeCartaoId'];
        this['chaveDeCartao'] = data['chaveDeCartao'];
    }
}
export class LimiteGlobalDto {
    constructor(data = {}) {
        this['chaveDePessoa'] = data['chaveDePessoa'];
        this['chaveDeCiclo'] = data['chaveDeCiclo'];
        this['chaveDeLimite'] = data['chaveDeLimite'];
        this['diaReferencia'] = data['diaReferencia'];
        this['diasTravaAposVencimento'] = data['diasTravaAposVencimento'];
        this['valor'] = data['valor'];
        this['descricao'] = data['descricao'];
        this['chaveDeLimitePai'] = data['chaveDeLimitePai'];
        this['tipo'] = data['tipo'];
        this['disponivel'] = data['disponivel'];
        this['identificacaoFiscal'] = data['identificacaoFiscal'];
        this['nome'] = data['nome'];
        this['chaveDePessoaPai'] = data['chaveDePessoaPai'];
        this['identificacaoFiscalPai'] = data['identificacaoFiscalPai'];
        this['nomePai'] = data['nomePai'];
    }
}
export class ListaOperacaoLimiteCartaoProcessadora {
    constructor(data = {}) { }
}
export class ListaReprovadosCafParaAnaliseMesa {
    constructor(data = {}) {
        this['chaveDeCliente'] = data['chaveDeCliente'];
        this['nome'] = data['nome'];
        this['identificacaoFiscal'] = data['identificacaoFiscal'];
        this['organizationUnitId'] = data['organizationUnitId'];
        this['organizationUnitDescricao'] = data['organizationUnitDescricao'];
        this['statusCaf'] = data['statusCaf'];
        this['statusCafId'] = data['statusCafId'];
        this['dataCadastro'] = data['dataCadastro'];
        this['dataAlteracaoStatus'] = data['dataAlteracaoStatus'];
    }
}
export class ListaReprovadosCafParaAnaliseMesaAddOutputPaginated {
    constructor(data = {}) {
        this['items'] = data['items'];
        this['qtdPaginas'] = data['qtdPaginas'];
        this['qtdPorPagina'] = data['qtdPorPagina'];
        this['totalItems'] = data['totalItems'];
        this['pagina'] = data['pagina'];
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
    }
}
export class ListaRestricao {
    constructor(data = {}) {
        this['tipoChaveRestricao'] = data['tipoChaveRestricao'];
        this['chaveDeRestricao'] = data['chaveDeRestricao'];
    }
}
export class ListarCicloFaturamento {
    constructor(data = {}) {
        this['chaveDeCiclo'] = data['chaveDeCiclo'];
        this['tipo'] = data['tipo'];
        this['diasCiclo'] = data['diasCiclo'];
        this['diasVencimento'] = data['diasVencimento'];
    }
}
export class Metadado {
    constructor(data = {}) {
        this['tipoMetadado'] = data['tipoMetadado'];
        this['valor'] = data['valor'];
    }
}
export class ModuloComPermissoes {
    constructor(data = {}) {
        this['modulo'] = data['modulo'];
        this['permissions'] = data['permissions'];
    }
}
export class MotivoSegundaVia {
    constructor(data = {}) {
        this['id'] = data['id'];
        this['descricao'] = data['descricao'];
    }
}
export class ObservacaoDto {
    constructor(data = {}) {
        this['descricao'] = data['descricao'];
        this['dataObsevacao'] = data['dataObsevacao'];
        this['agente'] = data['agente'];
    }
}
export class Operation {
    constructor(data = {}) {
        this['op'] = data['op'];
        this['value'] = data['value'];
        this['path'] = data['path'];
    }
}
export class OutAlteracaoCampo {
    constructor(data = {}) {
        this['data'] = data['data'];
        this['campo'] = data['campo'];
        this['valorAntigo'] = data['valorAntigo'];
        this['valorNovo'] = data['valorNovo'];
        this['motivoAlteracao'] = data['motivoAlteracao'];
        this['fullName'] = data['fullName'];
    }
}
export class OutAlteracaoMcc {
    constructor(data = {}) {
        this['data'] = data['data'];
        this['gruposMcc'] = data['gruposMcc'];
        this['userName'] = data['userName'];
        this['userId'] = data['userId'];
    }
}
export class OutAlterarTipoCartoes {
    constructor(data = {}) {
        this['id'] = data['id'];
        this['descricao'] = data['descricao'];
        this['package'] = data['package'];
        this['subPackage'] = data['subPackage'];
        this['bandeira'] = data['bandeira'];
        this['possuiPlastico'] = data['possuiPlastico'];
        this['modalidade'] = data['modalidade'];
        this['recarregavel'] = data['recarregavel'];
        this['fisico'] = data['fisico'];
        this['idImagem'] = data['idImagem'];
        this['idTipoPlastico'] = data['idTipoPlastico'];
        this['isContactless'] = data['isContactless'];
        this['limiteContactlessSemSenha'] = data['limiteContactlessSemSenha'];
    }
}
export class OutAparelhoMotoSafe {
    constructor(data = {}) {
        this['numeroParcelas'] = data['numeroParcelas'];
        this['valorTotal'] = data['valorTotal'];
        this['recorrencia'] = data['recorrencia'];
        this['ativo'] = data['ativo'];
        this['aceiteTermos'] = data['aceiteTermos'];
        this['moeda'] = data['moeda'];
        this['bloqueado'] = data['bloqueado'];
    }
}
export class OutArquivoCashbackDimo {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['id'] = data['id'];
    }
}
export class OutArquivoCliente {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['data'] = data['data'];
        this['mensagem'] = data['mensagem'];
        this['arquivo'] = data['arquivo'];
    }
}
export class OutAtivarCashBack {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['chaveDeConta'] = data['chaveDeConta'];
        this['valorCashBack'] = data['valorCashBack'];
        this['codHistorico'] = data['codHistorico'];
        this['idTransacaoAdd'] = data['idTransacaoAdd'];
    }
}
export class OutAvaliarContratosPendentes {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
    }
}
export class OutBaixarRetornoImportacaoNotificacao {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['arquivo'] = data['arquivo'];
    }
}
export class OutBandeira {
    constructor(data = {}) {
        this['id'] = data['id'];
        this['nome'] = data['nome'];
    }
}
export class OutBuscarBancos {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
    }
}
export class OutBuscarCategoriaChamado {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['categorias'] = data['categorias'];
    }
}
export class OutBuscarDadosUsuarioLogado {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['usuario'] = data['usuario'];
        this['tipoUsuario'] = data['tipoUsuario'];
        this['tipoUsuarioId'] = data['tipoUsuarioId'];
    }
}
export class OutBuscarHistoricoAnaliseMesa {
    constructor(data = {}) {
        this['analises'] = data['analises'];
    }
}
export class OutBuscarHistoricoEntidade {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['tipoEntidade'] = data['tipoEntidade'];
        this['entidadeId'] = data['entidadeId'];
        this['alteracoes'] = data['alteracoes'];
    }
}
export class OutBuscarMotivosTransacoes {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
    }
}
export class OutBuscarRoles {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['roles'] = data['roles'];
    }
}
export class OutBuscarTipoCartoes {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['id'] = data['id'];
        this['descricao'] = data['descricao'];
        this['bandeira'] = data['bandeira'];
        this['modalidade'] = data['modalidade'];
        this['recarregavel'] = data['recarregavel'];
        this['fisico'] = data['fisico'];
        this['idTipoPlastico'] = data['idTipoPlastico'];
        this['idImagem'] = data['idImagem'];
        this['isContactless'] = data['isContactless'];
        this['limiteContactlessSemSenha'] = data['limiteContactlessSemSenha'];
    }
}
export class OutBuscarTipoCartoesAddOutputPaginated {
    constructor(data = {}) {
        this['items'] = data['items'];
        this['qtdPaginas'] = data['qtdPaginas'];
        this['qtdPorPagina'] = data['qtdPorPagina'];
        this['totalItems'] = data['totalItems'];
        this['pagina'] = data['pagina'];
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
    }
}
export class OutBuscarUsuarioApi {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['api_Key'] = data['api_Key'];
    }
}
export class OutBuscarUsuarioPorId {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['id'] = data['id'];
        this['userName'] = data['userName'];
        this['name'] = data['name'];
        this['surname'] = data['surname'];
        this['emailAddress'] = data['emailAddress'];
        this['fullName'] = data['fullName'];
        this['roleNames'] = data['roleNames'];
        this['idOrganizationUnit'] = data['idOrganizationUnit'];
        this['ipWhiteList'] = data['ipWhiteList'];
    }
}
export class OutCadastrarUsuario {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['usuarioId'] = data['usuarioId'];
    }
}
export class OutCampoAlteracao {
    constructor(data = {}) {
        this['campo'] = data['campo'];
        this['valorAntigo'] = data['valorAntigo'];
        this['valorNovo'] = data['valorNovo'];
    }
}
export class OutCancelarConta {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['dataSolicitacao'] = data['dataSolicitacao'];
        this['dataCancelamento'] = data['dataCancelamento'];
        this['contaEncerradaProcessadora'] = data['contaEncerradaProcessadora'];
        this['contaEncerradaBanco'] = data['contaEncerradaBanco'];
        this['statusEncerramento'] = data['statusEncerramento'];
        this['tipoCancelamento'] = data['tipoCancelamento'];
        this['motivoCancelamento'] = data['motivoCancelamento'];
        this['erros'] = data['erros'];
    }
}
export class OutCancelarSolicitacoesLimitesCliente {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['dataDeCancelamento'] = data['dataDeCancelamento'];
        this['statusId'] = data['statusId'];
        this['statusDescricao'] = data['statusDescricao'];
    }
}
export class OutCategoriaChamado {
    constructor(data = {}) {
        this['id'] = data['id'];
        this['descricao'] = data['descricao'];
    }
}
export class OutChamado {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['id'] = data['id'];
        this['protocolo'] = data['protocolo'];
        this['descricao'] = data['descricao'];
        this['statusId'] = data['statusId'];
        this['statusDescricao'] = data['statusDescricao'];
        this['dataHoraCriacao'] = data['dataHoraCriacao'];
        this['chaveDeConta'] = data['chaveDeConta'];
        this['nomeAtendido'] = data['nomeAtendido'];
        this['numeroConta'] = data['numeroConta'];
        this['identificacaoFiscal'] = data['identificacaoFiscal'];
        this['organizationUnitId'] = data['organizationUnitId'];
        this['nomeUsuarioCriacao'] = data['nomeUsuarioCriacao'];
        this['ocorrencias'] = data['ocorrencias'];
        this['tipoOcorrencia'] = data['tipoOcorrencia'];
        this['categorizacaoNivel0'] = data['categorizacaoNivel0'];
        this['descricaoCategorizacaoNivel0'] = data['descricaoCategorizacaoNivel0'];
        this['categorizacaoNivel1'] = data['categorizacaoNivel1'];
        this['descricaoCategorizacaoNivel1'] = data['descricaoCategorizacaoNivel1'];
        this['categorizacaoNivel2'] = data['categorizacaoNivel2'];
        this['descricaoCategorizacaoNivel2'] = data['descricaoCategorizacaoNivel2'];
    }
}
export class OutChamadoAddOutputPaginated {
    constructor(data = {}) {
        this['items'] = data['items'];
        this['qtdPaginas'] = data['qtdPaginas'];
        this['qtdPorPagina'] = data['qtdPorPagina'];
        this['totalItems'] = data['totalItems'];
        this['pagina'] = data['pagina'];
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
    }
}
export class OutChamadoStatus {
    constructor(data = {}) {
        this['chamadoStatus'] = data['chamadoStatus'];
    }
}
export class OutClientesLimitesList {
    constructor(data = {}) {
        this['limiteSolicitacaoClienteId'] = data['limiteSolicitacaoClienteId'];
        this['limiteClienteId'] = data['limiteClienteId'];
        this['chaveDeCliente'] = data['chaveDeCliente'];
        this['clienteNome'] = data['clienteNome'];
        this['clienteIdentificacaoFiscal'] = data['clienteIdentificacaoFiscal'];
        this['valorSolicitado'] = data['valorSolicitado'];
        this['valorAtual'] = data['valorAtual'];
        this['valorAprovado'] = data['valorAprovado'];
        this['tipoOperacaoLimiteCliente'] = data['tipoOperacaoLimiteCliente'];
        this['tipoOperacaoLimiteClienteId'] = data['tipoOperacaoLimiteClienteId'];
        this['organizationUnitId'] = data['organizationUnitId'];
        this['statusSolicitacao'] = data['statusSolicitacao'];
        this['dataSolicitacao'] = data['dataSolicitacao'];
        this['parceiro'] = data['parceiro'];
        this['userId'] = data['userId'];
        this['userName'] = data['userName'];
        this['tipoSolicitacao'] = data['tipoSolicitacao'];
    }
}
export class OutClientesLimitesListAddOutputPaginated {
    constructor(data = {}) {
        this['items'] = data['items'];
        this['qtdPaginas'] = data['qtdPaginas'];
        this['qtdPorPagina'] = data['qtdPorPagina'];
        this['totalItems'] = data['totalItems'];
        this['pagina'] = data['pagina'];
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
    }
}
export class OutConfiguracaoUnidadeOrganizacional {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['quantidadeTotalContas'] = data['quantidadeTotalContas'];
        this['limiteTotalContas'] = data['limiteTotalContas'];
        this['quantidadeTotalCartoes'] = data['quantidadeTotalCartoes'];
        this['limiteTotalCartoes'] = data['limiteTotalCartoes'];
    }
}
export class OutConsultaLimiteValorOperacao {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['listaLimitadoresOperacaoCartaoProcessadoras'] = data['listaLimitadoresOperacaoCartaoProcessadoras'];
    }
}
export class OutConsultaParametroGlobalLimiteNoturnoPix {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['horaInicio'] = data['horaInicio'];
        this['horaFim'] = data['horaFim'];
        this['valorLimite'] = data['valorLimite'];
        this['creatorUserId'] = data['creatorUserId'];
    }
}
export class OutConsultaRelatorioOnidata {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['data'] = data['data'];
        this['mensagem'] = data['mensagem'];
        this['nomeFantasia'] = data['nomeFantasia'];
        this['quantidadePropostasAprovadas'] = data['quantidadePropostasAprovadas'];
        this['valorFinanciadoPropostasAprovadas'] = data['valorFinanciadoPropostasAprovadas'];
        this['quantidadePropostasNegadas'] = data['quantidadePropostasNegadas'];
        this['valorFinanciadoPropostasNegadas'] = data['valorFinanciadoPropostasNegadas'];
        this['quantidadeContratosAprovados'] = data['quantidadeContratosAprovados'];
        this['valorFinanciadoContratosAprovados'] = data['valorFinanciadoContratosAprovados'];
        this['valorLiberadoContratosAprovados'] = data['valorLiberadoContratosAprovados'];
        this['quantidadeContratosNegados'] = data['quantidadeContratosNegados'];
        this['valorFinanciadoContratosNegados'] = data['valorFinanciadoContratosNegados'];
        this['taxaCetMediaContratosAprovados'] = data['taxaCetMediaContratosAprovados'];
        this['taxaContratoMediaContratosAprovados'] = data['taxaContratoMediaContratosAprovados'];
        this['prazoMedioContratosAprovados'] = data['prazoMedioContratosAprovados'];
        this['taxaCetMediaContratosNegados'] = data['taxaCetMediaContratosNegados'];
        this['taxaContratoMediaContratosNegados'] = data['taxaContratoMediaContratosNegados'];
        this['prazoMedioContratosNegados'] = data['prazoMedioContratosNegados'];
    }
}
export class OutConsultarAprovacaoContratos {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['idContrato'] = data['idContrato'];
        this['idProposta'] = data['idProposta'];
        this['localizador'] = data['localizador'];
        this['nome'] = data['nome'];
        this['identificacaoFiscal'] = data['identificacaoFiscal'];
        this['parcelas'] = data['parcelas'];
        this['valorParcela'] = data['valorParcela'];
        this['valorContrato'] = data['valorContrato'];
        this['tipoDoContrato'] = data['tipoDoContrato'];
        this['dataSolicacao'] = data['dataSolicacao'];
        this['dataEnvioAvaliacaoUO'] = data['dataEnvioAvaliacaoUO'];
        this['statusAvaliacaoUO'] = data['statusAvaliacaoUO'];
        this['motivoAvaliacaoUO'] = data['motivoAvaliacaoUO'];
        this['usuarioAvaliacaoUO'] = data['usuarioAvaliacaoUO'];
        this['dataAvaliacaoUO'] = data['dataAvaliacaoUO'];
        this['descricaoTipoContratoAntecipacao'] = data['descricaoTipoContratoAntecipacao'];
        this['matricula'] = data['matricula'];
        this['unidadeOrganizacionalId'] = data['unidadeOrganizacionalId'];
        this['unidadeOrganizacionalDescricao'] = data['unidadeOrganizacionalDescricao'];
        this['cnpj'] = data['cnpj'];
        this['chaveDeCliente'] = data['chaveDeCliente'];
    }
}
export class OutConsultarAprovacaoContratosAddOutputPaginated {
    constructor(data = {}) {
        this['items'] = data['items'];
        this['qtdPaginas'] = data['qtdPaginas'];
        this['qtdPorPagina'] = data['qtdPorPagina'];
        this['totalItems'] = data['totalItems'];
        this['pagina'] = data['pagina'];
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
    }
}
export class OutConsultarCodigoPromocionalUo {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['codigosPromocionaisUos'] = data['codigosPromocionaisUos'];
    }
}
export class OutConsultarDadosCartoes {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['items'] = data['items'];
        this['totalCount'] = data['totalCount'];
    }
}
export class OutConsultarExtratoContratos {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['statusContratoAntecipacao'] = data['statusContratoAntecipacao'];
        this['tipoDeContrato'] = data['tipoDeContrato'];
        this['numeroDoContrato'] = data['numeroDoContrato'];
        this['dataDeContratacao'] = data['dataDeContratacao'];
        this['valorLiberado'] = data['valorLiberado'];
        this['parcelas'] = data['parcelas'];
        this['valorParcela'] = data['valorParcela'];
        this['valorContrato'] = data['valorContrato'];
        this['statusAvaliacaoUO'] = data['statusAvaliacaoUO'];
        this['dataAvaliacaoUO'] = data['dataAvaliacaoUO'];
        this['usuarioAvaliacaoUO'] = data['usuarioAvaliacaoUO'];
        this['motivoAvaliacaoUO'] = data['motivoAvaliacaoUO'];
    }
}
export class OutConsultarExtratoContratosAddOutputPaginated {
    constructor(data = {}) {
        this['items'] = data['items'];
        this['qtdPaginas'] = data['qtdPaginas'];
        this['qtdPorPagina'] = data['qtdPorPagina'];
        this['totalItems'] = data['totalItems'];
        this['pagina'] = data['pagina'];
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
    }
}
export class OutConsultarExtratoContratosDetalhado {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['tipoDeContrato'] = data['tipoDeContrato'];
        this['modalidade'] = data['modalidade'];
        this['numeroDoContrato'] = data['numeroDoContrato'];
        this['statusContratoAntecipacao'] = data['statusContratoAntecipacao'];
        this['dataDeContratacao'] = data['dataDeContratacao'];
        this['dataFinalContrato'] = data['dataFinalContrato'];
        this['valorContrato'] = data['valorContrato'];
        this['valorLiberado'] = data['valorLiberado'];
        this['valorFinanciado'] = data['valorFinanciado'];
        this['valorParcela'] = data['valorParcela'];
        this['parcelas'] = data['parcelas'];
        this['parcelasEmAberto'] = data['parcelasEmAberto'];
        this['valorParcelasEmAberto'] = data['valorParcelasEmAberto'];
        this['parcelasPago'] = data['parcelasPago'];
        this['valorParcelasPago'] = data['valorParcelasPago'];
        this['taxaDeJurosMensal'] = data['taxaDeJurosMensal'];
        this['taxaDeJurosAnual'] = data['taxaDeJurosAnual'];
    }
}
export class OutConsultarLimiteOperacaoCartaoProcessadora {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['limiteOperacaoCartaoProcessadora'] = data['limiteOperacaoCartaoProcessadora'];
    }
}
export class OutConsultarPermissoes {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['items'] = data['items'];
    }
}
export class OutConsultarPortabilidadeBackoffice {
    constructor(data = {}) {
        this['chaveDePortabilidade'] = data['chaveDePortabilidade'];
        this['identificacaoFiscalEmpresaOrigem'] = data['identificacaoFiscalEmpresaOrigem'];
        this['razaoSocialEmpresaOrigem'] = data['razaoSocialEmpresaOrigem'];
        this['codigoBancoOrigem'] = data['codigoBancoOrigem'];
        this['nomeBancoOrigem'] = data['nomeBancoOrigem'];
        this['agenciaDestino'] = data['agenciaDestino'];
        this['contaDestino'] = data['contaDestino'];
        this['protocoloPortabilidade'] = data['protocoloPortabilidade'];
        this['situacaoPortabilidade'] = data['situacaoPortabilidade'];
        this['situacaoPortabilidadeCip'] = data['situacaoPortabilidadeCip'];
        this['statusPortabilidade'] = data['statusPortabilidade'];
        this['motivoCancelamento'] = data['motivoCancelamento'];
        this['motivoCancelamentoDescricao'] = data['motivoCancelamentoDescricao'];
        this['complementoMotivoCancelamento'] = data['complementoMotivoCancelamento'];
        this['dataCancelamento'] = data['dataCancelamento'];
        this['dataCriacao'] = data['dataCriacao'];
        this['dataAtualizacao'] = data['dataAtualizacao'];
        this['dataEnvioParceiro'] = data['dataEnvioParceiro'];
    }
}
export class OutConsultarPortabilidadeBackofficeAddOutputPaginated {
    constructor(data = {}) {
        this['items'] = data['items'];
        this['qtdPaginas'] = data['qtdPaginas'];
        this['qtdPorPagina'] = data['qtdPorPagina'];
        this['totalItems'] = data['totalItems'];
        this['pagina'] = data['pagina'];
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
    }
}
export class OutConsultarRegistroListaRestritiva {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
    }
}
export class OutConsultarTransacoesNegadas {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['dataHoraEntrada'] = data['dataHoraEntrada'];
        this['nomeEstabelecimento'] = data['nomeEstabelecimento'];
        this['ultimos4DigitosCartao'] = data['ultimos4DigitosCartao'];
        this['valor'] = data['valor'];
        this['descricao'] = data['descricao'];
        this['codigoResposta'] = data['codigoResposta'];
        this['contaId'] = data['contaId'];
        this['cartaoId'] = data['cartaoId'];
    }
}
export class OutConsultarTransacoesNegadasAddOutputPaginated {
    constructor(data = {}) {
        this['items'] = data['items'];
        this['qtdPaginas'] = data['qtdPaginas'];
        this['qtdPorPagina'] = data['qtdPorPagina'];
        this['totalItems'] = data['totalItems'];
        this['pagina'] = data['pagina'];
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
    }
}
export class OutConsultarUnidadesBackoffice {
    constructor(data = {}) {
        this['codigo'] = data['codigo'];
        this['organizationUnitId'] = data['organizationUnitId'];
        this['nome'] = data['nome'];
        this['tipoConta'] = data['tipoConta'];
        this['unidadePaiId'] = data['unidadePaiId'];
    }
}
export class OutConsultarUnidadesBackofficeAddOutputPaginated {
    constructor(data = {}) {
        this['items'] = data['items'];
        this['qtdPaginas'] = data['qtdPaginas'];
        this['qtdPorPagina'] = data['qtdPorPagina'];
        this['totalItems'] = data['totalItems'];
        this['pagina'] = data['pagina'];
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
    }
}
export class OutContratosDeCredito {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['chaveDeConta'] = data['chaveDeConta'];
        this['chaveDoCliente'] = data['chaveDoCliente'];
        this['situacao'] = data['situacao'];
        this['nomeCompleto'] = data['nomeCompleto'];
        this['cpf'] = data['cpf'];
        this['tipoDeContrato'] = data['tipoDeContrato'];
        this['numeroDoContrato'] = data['numeroDoContrato'];
        this['dataDoContrato'] = data['dataDoContrato'];
        this['valorDoContrato'] = data['valorDoContrato'];
        this['parcelas'] = data['parcelas'];
        this['valorDaParcela'] = data['valorDaParcela'];
    }
}
export class OutContratosDeCreditoAddOutputPaginated {
    constructor(data = {}) {
        this['items'] = data['items'];
        this['qtdPaginas'] = data['qtdPaginas'];
        this['qtdPorPagina'] = data['qtdPorPagina'];
        this['totalItems'] = data['totalItems'];
        this['pagina'] = data['pagina'];
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
    }
}
export class OutContratosFinanciamento {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['contratos'] = data['contratos'];
    }
}
export class OutConvenioPublicoCompletoDto {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['codigoDesconto'] = data['codigoDesconto'];
        this['usuario'] = data['usuario'];
        this['convenioId'] = data['convenioId'];
        this['orgaoPublico'] = data['orgaoPublico'];
        this['nivelOrgaoPublico'] = data['nivelOrgaoPublico'];
        this['ativo'] = data['ativo'];
    }
}
export class OutConveniosAntecipacao {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['idConvenio'] = data['idConvenio'];
        this['idEmpresa'] = data['idEmpresa'];
        this['nomeFantasia'] = data['nomeFantasia'];
        this['razaoSocial'] = data['razaoSocial'];
        this['cnpj'] = data['cnpj'];
        this['ramoAtividade'] = data['ramoAtividade'];
        this['diaCorte'] = data['diaCorte'];
        this['limiteFinanciamento'] = data['limiteFinanciamento'];
        this['limiteContratos'] = data['limiteContratos'];
        this['limiteRenegociacoes'] = data['limiteRenegociacoes'];
    }
}
export class OutConveniosAntecipacaoAddOutputPaginated {
    constructor(data = {}) {
        this['items'] = data['items'];
        this['qtdPaginas'] = data['qtdPaginas'];
        this['qtdPorPagina'] = data['qtdPorPagina'];
        this['totalItems'] = data['totalItems'];
        this['pagina'] = data['pagina'];
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
    }
}
export class OutCriarCicloFaturamento {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['chaveDeCiclo'] = data['chaveDeCiclo'];
    }
}
export class OutCriarCodigoPromocionalUo {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['codigoPromocionalUoId'] = data['codigoPromocionalUoId'];
    }
}
export class OutCriarLimiteGlobal {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['chaveDeLimite'] = data['chaveDeLimite'];
    }
}
export class OutCriarOuAtualizarPessoa {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['chaveDePessoa'] = data['chaveDePessoa'];
    }
}
export class OutCriarSegmentoBeneficio {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['segmentoBeneficioId'] = data['segmentoBeneficioId'];
    }
}
export class OutDadosContratoMobileBackoffice {
    constructor(data = {}) {
        this['idContrato'] = data['idContrato'];
        this['primeiroVencimento'] = data['primeiroVencimento'];
        this['ultimoVencimento'] = data['ultimoVencimento'];
        this['valorRecebivel'] = data['valorRecebivel'];
        this['statusContrato'] = data['statusContrato'];
        this['dataFinanciamento'] = data['dataFinanciamento'];
        this['valorTotalFinanciado'] = data['valorTotalFinanciado'];
        this['valorEmAberto'] = data['valorEmAberto'];
        this['valorPago'] = data['valorPago'];
        this['valorEntrada'] = data['valorEntrada'];
        this['numeroDeParcelas'] = data['numeroDeParcelas'];
        this['numeroDeParcelasPagas'] = data['numeroDeParcelasPagas'];
        this['numeroDeParcelasAbertas'] = data['numeroDeParcelasAbertas'];
        this['numeroNotaFiscal'] = data['numeroNotaFiscal'];
        this['serieNotaFiscal'] = data['serieNotaFiscal'];
        this['imei'] = data['imei'];
        this['ean'] = data['ean'];
        this['modelo'] = data['modelo'];
        this['codigoXt'] = data['codigoXt'];
        this['bancoFinanciador'] = data['bancoFinanciador'];
        this['observacoes'] = data['observacoes'];
        this['statusAparelho'] = data['statusAparelho'];
    }
}
export class OutDadosFuncionario {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['genero'] = data['genero'];
        this['matricula'] = data['matricula'];
        this['admissaoData'] = data['admissaoData'];
        this['cargo'] = data['cargo'];
        this['estadoCivil'] = data['estadoCivil'];
    }
}
export class OutDimoPayConfiguracaoLoja {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['chaveConfiguracaoLoja'] = data['chaveConfiguracaoLoja'];
        this['nomeLoja'] = data['nomeLoja'];
        this['banco'] = data['banco'];
        this['agencia'] = data['agencia'];
        this['conta'] = data['conta'];
    }
}
export class OutDimoPayConfiguracaoLojaAddOutputPaginated {
    constructor(data = {}) {
        this['items'] = data['items'];
        this['qtdPaginas'] = data['qtdPaginas'];
        this['qtdPorPagina'] = data['qtdPorPagina'];
        this['totalItems'] = data['totalItems'];
        this['pagina'] = data['pagina'];
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
    }
}
export class OutDispararPushIndividualBackoffice {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['id'] = data['id'];
        this['organizationUnitId'] = data['organizationUnitId'];
        this['nomeArquivo'] = data['nomeArquivo'];
        this['status'] = data['status'];
    }
}
export class OutDownloadArquivoRetorno {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['arquivo'] = data['arquivo'];
    }
}
export class OutErroCancelarConta {
    constructor(data = {}) {
        this['erro'] = data['erro'];
        this['mensagem'] = data['mensagem'];
    }
}
export class OutExportarArquivoUsuario {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['csv'] = data['csv'];
    }
}
export class OutFiltrarContas {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['nome'] = data['nome'];
        this['identificacaoFiscal'] = data['identificacaoFiscal'];
        this['produto'] = data['produto'];
        this['parceiro'] = data['parceiro'];
        this['nacionalidade'] = data['nacionalidade'];
        this['chaveDeConta'] = data['chaveDeConta'];
        this['statusCadastroConta'] = data['statusCadastroConta'];
        this['descricaoStatusCadastroConta'] = data['descricaoStatusCadastroConta'];
        this['statusConta'] = data['statusConta'];
    }
}
export class OutFiltrarLimites {
    constructor(data = {}) {
        this['chaveDePessoa'] = data['chaveDePessoa'];
        this['chaveDeCiclo'] = data['chaveDeCiclo'];
        this['chaveDeLimite'] = data['chaveDeLimite'];
        this['diaReferencia'] = data['diaReferencia'];
        this['diasTravaAposVencimento'] = data['diasTravaAposVencimento'];
        this['valor'] = data['valor'];
        this['descricao'] = data['descricao'];
        this['tipo'] = data['tipo'];
        this['disponivel'] = data['disponivel'];
        this['identificacaoFiscal'] = data['identificacaoFiscal'];
        this['nome'] = data['nome'];
    }
}
export class OutFiltrarLimitesAddOutputPaginated {
    constructor(data = {}) {
        this['items'] = data['items'];
        this['qtdPaginas'] = data['qtdPaginas'];
        this['qtdPorPagina'] = data['qtdPorPagina'];
        this['totalItems'] = data['totalItems'];
        this['pagina'] = data['pagina'];
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
    }
}
export class OutFiltrarPessoas {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['pessoas'] = data['pessoas'];
    }
}
export class OutGerarNovaSenha {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['senha'] = data['senha'];
    }
}
export class OutGerarRelatorioColaboradores {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['urlRelatorio'] = data['urlRelatorio'];
    }
}
export class OutGetDetalhesLimite {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['nome'] = data['nome'];
        this['identificacaoFiscal'] = data['identificacaoFiscal'];
        this['qtdeFaturasVencidas'] = data['qtdeFaturasVencidas'];
        this['qtdeFaturasAguardandoPagamento'] = data['qtdeFaturasAguardandoPagamento'];
        this['qtdeFaturasEmAberto'] = data['qtdeFaturasEmAberto'];
        this['qtdeFaturasPagas'] = data['qtdeFaturasPagas'];
        this['limiteAprovado'] = data['limiteAprovado'];
        this['limiteDisponivel'] = data['limiteDisponivel'];
        this['limiteFaturamento'] = data['limiteFaturamento'];
        this['limiteFaturado'] = data['limiteFaturado'];
        this['nomeFaturamento'] = data['nomeFaturamento'];
        this['emailFaturamento'] = data['emailFaturamento'];
        this['telefoneFixoFaturamento'] = data['telefoneFixoFaturamento'];
        this['telefoneMovelFaturamento'] = data['telefoneMovelFaturamento'];
        this['nomeFaturamentoAdicional'] = data['nomeFaturamentoAdicional'];
        this['emailFaturamentoAdicional'] = data['emailFaturamentoAdicional'];
        this['telefoneFixoFaturamentoAdicional'] = data['telefoneFixoFaturamentoAdicional'];
        this['telefoneMovelFaturamentoAdicional'] = data['telefoneMovelFaturamentoAdicional'];
    }
}
export class OutHistoricoCartao {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['historico'] = data['historico'];
    }
}
export class OutHistoricoMccCartao {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['alteracoes'] = data['alteracoes'];
    }
}
export class OutIntegradoresLogConfiguracaoCombos {
    constructor(data = {}) {
        this['integradores'] = data['integradores'];
        this['motivoTransacoes'] = data['motivoTransacoes'];
        this['exceptionLogs'] = data['exceptionLogs'];
        this['addExceptions'] = data['addExceptions'];
    }
}
export class OutLimitesClientesUltimasTres {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['outClientesLimitesLists'] = data['outClientesLimitesLists'];
    }
}
export class OutLinhaHistoricoEntidade {
    constructor(data = {}) {
        this['data'] = data['data'];
        this['responsavel'] = data['responsavel'];
        this['alteracoes'] = data['alteracoes'];
        this['motivo'] = data['motivo'];
        this['tipoEntidade'] = data['tipoEntidade'];
        this['entidadeId'] = data['entidadeId'];
    }
}
export class OutLinhaHistoricoEntidadeAddOutputPaginated {
    constructor(data = {}) {
        this['items'] = data['items'];
        this['qtdPaginas'] = data['qtdPaginas'];
        this['qtdPorPagina'] = data['qtdPorPagina'];
        this['totalItems'] = data['totalItems'];
        this['pagina'] = data['pagina'];
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
    }
}
export class OutListaConsultarDadosClientesFilaOnboard {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['dadosClientesFila'] = data['dadosClientesFila'];
    }
}
export class OutListaMotivoSegVia {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['listaMotivoSegundaVia'] = data['listaMotivoSegundaVia'];
    }
}
export class OutListarArquivosCashbackDimo {
    constructor(data = {}) {
        this['id'] = data['id'];
        this['nomeArquivo'] = data['nomeArquivo'];
        this['status'] = data['status'];
        this['dataCriacao'] = data['dataCriacao'];
        this['dataProcessamento'] = data['dataProcessamento'];
        this['usuarioResponsavel'] = data['usuarioResponsavel'];
        this['usuarioResponsavelId'] = data['usuarioResponsavelId'];
        this['totalRegistros'] = data['totalRegistros'];
        this['totalPendentes'] = data['totalPendentes'];
        this['totalImportados'] = data['totalImportados'];
        this['totalRecusados'] = data['totalRecusados'];
        this['organizationUnitId'] = data['organizationUnitId'];
    }
}
export class OutListarArquivosCashbackDimoAddOutputPaginated {
    constructor(data = {}) {
        this['items'] = data['items'];
        this['qtdPaginas'] = data['qtdPaginas'];
        this['qtdPorPagina'] = data['qtdPorPagina'];
        this['totalItems'] = data['totalItems'];
        this['pagina'] = data['pagina'];
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
    }
}
export class OutListarCicloFaturamento {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['ciclos'] = data['ciclos'];
    }
}
export class OutListarCicloFaturamentoPaginado {
    constructor(data = {}) {
        this['chaveDeCiclo'] = data['chaveDeCiclo'];
        this['tipo'] = data['tipo'];
        this['diasCiclo'] = data['diasCiclo'];
        this['diasVencimento'] = data['diasVencimento'];
    }
}
export class OutListarCicloFaturamentoPaginadoAddOutputPaginated {
    constructor(data = {}) {
        this['items'] = data['items'];
        this['qtdPaginas'] = data['qtdPaginas'];
        this['qtdPorPagina'] = data['qtdPorPagina'];
        this['totalItems'] = data['totalItems'];
        this['pagina'] = data['pagina'];
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
    }
}
export class OutListarClienteGeraisBackoffice {
    constructor(data = {}) {
        this['chaveDeCliente'] = data['chaveDeCliente'];
        this['nome'] = data['nome'];
        this['identificacaoFiscal'] = data['identificacaoFiscal'];
        this['status'] = data['status'];
        this['organizationUnitId'] = data['organizationUnitId'];
        this['organizationUnitDescricao'] = data['organizationUnitDescricao'];
        this['statusClienteId'] = data['statusClienteId'];
        this['statusClienteDescricao'] = data['statusClienteDescricao'];
    }
}
export class OutListarClienteGeraisBackofficeAddOutputPaginated {
    constructor(data = {}) {
        this['items'] = data['items'];
        this['qtdPaginas'] = data['qtdPaginas'];
        this['qtdPorPagina'] = data['qtdPorPagina'];
        this['totalItems'] = data['totalItems'];
        this['pagina'] = data['pagina'];
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
    }
}
export class OutListarConta {
    constructor(data = {}) {
        this['chaveDeConta'] = data['chaveDeConta'];
        this['nome'] = data['nome'];
        this['cidade'] = data['cidade'];
        this['identificacaoFiscal'] = data['identificacaoFiscal'];
        this['estado'] = data['estado'];
        this['parceiro'] = data['parceiro'];
        this['produto'] = data['produto'];
        this['statusCadastroConta'] = data['statusCadastroConta'];
        this['statusCadastroContaId'] = data['statusCadastroContaId'];
        this['idParceiro'] = data['idParceiro'];
        this['idProduto'] = data['idProduto'];
        this['possuiUsuario'] = data['possuiUsuario'];
        this['email'] = data['email'];
        this['statusConta'] = data['statusConta'];
        this['idContaPai'] = data['idContaPai'];
        this['bloqueadaPorPinIncorreto'] = data['bloqueadaPorPinIncorreto'];
    }
}
export class OutListarContaAddOutputPaginated {
    constructor(data = {}) {
        this['items'] = data['items'];
        this['qtdPaginas'] = data['qtdPaginas'];
        this['qtdPorPagina'] = data['qtdPorPagina'];
        this['totalItems'] = data['totalItems'];
        this['pagina'] = data['pagina'];
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
    }
}
export class OutListarFaturas {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['nome'] = data['nome'];
        this['identificacaoFiscal'] = data['identificacaoFiscal'];
        this['valorFatura'] = data['valorFatura'];
        this['dataVencimento'] = data['dataVencimento'];
        this['dataPagamento'] = data['dataPagamento'];
        this['dataEmissao'] = data['dataEmissao'];
        this['status'] = data['status'];
        this['chaveDeLimite'] = data['chaveDeLimite'];
        this['dataInicio'] = data['dataInicio'];
        this['dataFim'] = data['dataFim'];
        this['chaveDeFatura'] = data['chaveDeFatura'];
    }
}
export class OutListarFaturasAddOutputPaginated {
    constructor(data = {}) {
        this['items'] = data['items'];
        this['qtdPaginas'] = data['qtdPaginas'];
        this['qtdPorPagina'] = data['qtdPorPagina'];
        this['totalItems'] = data['totalItems'];
        this['pagina'] = data['pagina'];
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
    }
}
export class OutListarLimitesPorIdentificacaoFiscal {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['limitesProprios'] = data['limitesProprios'];
        this['limitesCompartilhados'] = data['limitesCompartilhados'];
        this['identificacaoFiscal'] = data['identificacaoFiscal'];
        this['razaoSocial'] = data['razaoSocial'];
        this['nomeContato'] = data['nomeContato'];
        this['telefone'] = data['telefone'];
        this['email'] = data['email'];
        this['endereco'] = data['endereco'];
    }
}
export class OutListarLotesEmissaoCartaoFisico {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['loteId'] = data['loteId'];
        this['organizationUnitId'] = data['organizationUnitId'];
        this['status'] = data['status'];
        this['dataCriacao'] = data['dataCriacao'];
        this['itens'] = data['itens'];
    }
}
export class OutListarLotesEmissaoCartaoFisicoAddOutputPaginated {
    constructor(data = {}) {
        this['items'] = data['items'];
        this['qtdPaginas'] = data['qtdPaginas'];
        this['qtdPorPagina'] = data['qtdPorPagina'];
        this['totalItems'] = data['totalItems'];
        this['pagina'] = data['pagina'];
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
    }
}
export class OutListarProcessamentoArquivosNoficacao {
    constructor(data = {}) {
        this['id'] = data['id'];
        this['organizationUnitId'] = data['organizationUnitId'];
        this['organizationUnitName'] = data['organizationUnitName'];
        this['nomeArquivo'] = data['nomeArquivo'];
        this['recusas'] = data['recusas'];
        this['status'] = data['status'];
        this['dataProcessamento'] = data['dataProcessamento'];
        this['totalRegistros'] = data['totalRegistros'];
        this['totalPendentes'] = data['totalPendentes'];
        this['totalProcessados'] = data['totalProcessados'];
        this['totalRecusados'] = data['totalRecusados'];
    }
}
export class OutListarProcessamentoArquivosNoficacaoAddOutputPaginated {
    constructor(data = {}) {
        this['items'] = data['items'];
        this['qtdPaginas'] = data['qtdPaginas'];
        this['qtdPorPagina'] = data['qtdPorPagina'];
        this['totalItems'] = data['totalItems'];
        this['pagina'] = data['pagina'];
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
    }
}
export class OutListarReprovadosCafParaAnaliseMesa {
    constructor(data = {}) {
        this['reprovacoes'] = data['reprovacoes'];
    }
}
export class OutListarSegmentosBeneficioBackoffice {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['segmentosBeneficio'] = data['segmentosBeneficio'];
    }
}
export class OutListarSolicitacaoCadastral {
    constructor(data = {}) {
        this['tipoDeSolicitacao'] = data['tipoDeSolicitacao'];
        this['status'] = data['status'];
        this['notificaPorEmail'] = data['notificaPorEmail'];
        this['notificaPorSms'] = data['notificaPorSms'];
        this['email'] = data['email'];
        this['telefoneMovel'] = data['telefoneMovel'];
        this['rua'] = data['rua'];
        this['numero'] = data['numero'];
        this['complemento'] = data['complemento'];
        this['bairro'] = data['bairro'];
        this['cep'] = data['cep'];
        this['cidade'] = data['cidade'];
        this['uf'] = data['uf'];
        this['observacao'] = data['observacao'];
        this['resultadoProcessamento'] = data['resultadoProcessamento'];
        this['usuarioDaSolicitacao'] = data['usuarioDaSolicitacao'];
        this['dataDaSolicitacao'] = data['dataDaSolicitacao'];
    }
}
export class OutListarSolicitacaoCadastralAddOutputPaginated {
    constructor(data = {}) {
        this['items'] = data['items'];
        this['qtdPaginas'] = data['qtdPaginas'];
        this['qtdPorPagina'] = data['qtdPorPagina'];
        this['totalItems'] = data['totalItems'];
        this['pagina'] = data['pagina'];
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
    }
}
export class OutListarUsuarios {
    constructor(data = {}) {
        this['id'] = data['id'];
        this['usuario'] = data['usuario'];
        this['nomeCompleto'] = data['nomeCompleto'];
        this['grupoDePermissao'] = data['grupoDePermissao'];
        this['unidadeOrganizacional'] = data['unidadeOrganizacional'];
    }
}
export class OutListarUsuariosAddOutputPaginated {
    constructor(data = {}) {
        this['items'] = data['items'];
        this['qtdPaginas'] = data['qtdPaginas'];
        this['qtdPorPagina'] = data['qtdPorPagina'];
        this['totalItems'] = data['totalItems'];
        this['pagina'] = data['pagina'];
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
    }
}
export class OutLoteProcessamentoArquivo {
    constructor(data = {}) {
        this['id'] = data['id'];
        this['organizationUnitId'] = data['organizationUnitId'];
        this['organizationUnitName'] = data['organizationUnitName'];
        this['nomeArquivo'] = data['nomeArquivo'];
        this['recusas'] = data['recusas'];
        this['status'] = data['status'];
        this['dataProcessamento'] = data['dataProcessamento'];
        this['totalRegistros'] = data['totalRegistros'];
        this['totalPendentes'] = data['totalPendentes'];
        this['totalProcessados'] = data['totalProcessados'];
        this['totalRecusados'] = data['totalRecusados'];
        this['userId'] = data['userId'];
        this['userName'] = data['userName'];
    }
}
export class OutLoteProcessamentoArquivoAddOutputPaginated {
    constructor(data = {}) {
        this['items'] = data['items'];
        this['qtdPaginas'] = data['qtdPaginas'];
        this['qtdPorPagina'] = data['qtdPorPagina'];
        this['totalItems'] = data['totalItems'];
        this['pagina'] = data['pagina'];
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
    }
}
export class OutMotivosBloqDesbloqCartao {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
    }
}
export class OutMotivosSegundaViaCartao {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
    }
}
export class OutNumeroClientesFila {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['numeroVagasDisponiveis'] = data['numeroVagasDisponiveis'];
        this['numeroMaximoPossivelContas'] = data['numeroMaximoPossivelContas'];
        this['numeroClientesAguardandoVagaNaFila'] = data['numeroClientesAguardandoVagaNaFila'];
        this['numeroClientesPodeCadastrarNaFila'] = data['numeroClientesPodeCadastrarNaFila'];
        this['numeroContasAtivas'] = data['numeroContasAtivas'];
        this['numeroClientesEmOnboard'] = data['numeroClientesEmOnboard'];
        this['porcentagemDeSeguranca'] = data['porcentagemDeSeguranca'];
    }
}
export class OutObterArquivosFaturas {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['arquivos'] = data['arquivos'];
    }
}
export class OutObterCicloFaturamento {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['chaveDeCiclo'] = data['chaveDeCiclo'];
        this['tipo'] = data['tipo'];
        this['diasCiclo'] = data['diasCiclo'];
        this['diasVencimento'] = data['diasVencimento'];
    }
}
export class OutObterCodigoPromocionalUo {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['codigoPromocionalUoId'] = data['codigoPromocionalUoId'];
        this['organizationUnitId'] = data['organizationUnitId'];
        this['motivoTransacaoId'] = data['motivoTransacaoId'];
        this['codigoArbi'] = data['codigoArbi'];
        this['codigoConductor'] = data['codigoConductor'];
        this['valorPromocao'] = data['valorPromocao'];
        this['valorPorcentagemCashBack'] = data['valorPorcentagemCashBack'];
        this['dataInicio'] = data['dataInicio'];
        this['dataFim'] = data['dataFim'];
        this['limiteCashbackPorCliente'] = data['limiteCashbackPorCliente'];
    }
}
export class OutObterDadosClienteParaAnaliseMesa {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['nome'] = data['nome'];
        this['identificacaoFiscal'] = data['identificacaoFiscal'];
        this['rg'] = data['rg'];
        this['nomeMae'] = data['nomeMae'];
        this['nomePai'] = data['nomePai'];
        this['nacionalidade'] = data['nacionalidade'];
        this['organizationUnitId'] = data['organizationUnitId'];
        this['organizacionUnitDescricao'] = data['organizacionUnitDescricao'];
        this['pendencias'] = data['pendencias'];
        this['documentoFrente'] = data['documentoFrente'];
        this['documentoVerso'] = data['documentoVerso'];
    }
}
export class OutObterDadosConta {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['contas'] = data['contas'];
    }
}
export class OutObterDadosParciaisInformeRendimento {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['informes'] = data['informes'];
    }
}
export class OutObterDetalhesHistoricoAnaliseMesa {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['nome'] = data['nome'];
        this['identificacaoFiscal'] = data['identificacaoFiscal'];
        this['organizationUnitId'] = data['organizationUnitId'];
        this['organizationUnitDescricao'] = data['organizationUnitDescricao'];
        this['dadosAntesAnalise'] = data['dadosAntesAnalise'];
        this['dadosDepoisAnalise'] = data['dadosDepoisAnalise'];
    }
}
export class OutObterInfoContaBackoffice {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
    }
}
export class OutObterParametroValidacaoFacematch {
    constructor(data = {}) {
        this['parametroValidacaoFacematchId'] = data['parametroValidacaoFacematchId'];
        this['organizationUnitId'] = data['organizationUnitId'];
        this['tipoValidacaoFacematch'] = data['tipoValidacaoFacematch'];
        this['valor'] = data['valor'];
        this['valorQuantitativoValidacaoFacematch'] = data['valorQuantitativoValidacaoFacematch'];
        this['ativo'] = data['ativo'];
    }
}
export class OutObterParametroValidacaoFacematchAddOutputPaginated {
    constructor(data = {}) {
        this['items'] = data['items'];
        this['qtdPaginas'] = data['qtdPaginas'];
        this['qtdPorPagina'] = data['qtdPorPagina'];
        this['totalItems'] = data['totalItems'];
        this['pagina'] = data['pagina'];
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
    }
}
export class OutObterPessoaPorIdentificacaoFiscal {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['chaveDePessoa'] = data['chaveDePessoa'];
        this['identificacaoFiscal'] = data['identificacaoFiscal'];
        this['nome'] = data['nome'];
    }
}
export class OutObterRelatorioFatura {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['urlRelatorio'] = data['urlRelatorio'];
    }
}
export class OutOcorrencia {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['id'] = data['id'];
        this['anotacao'] = data['anotacao'];
        this['chamadoId'] = data['chamadoId'];
        this['usuarioId'] = data['usuarioId'];
        this['usuarioNome'] = data['usuarioNome'];
        this['dataCriacao'] = data['dataCriacao'];
        this['anexos'] = data['anexos'];
    }
}
export class OutParametroSimilaridade {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['id'] = data['id'];
        this['nomeUsuario'] = data['nomeUsuario'];
        this['valor'] = data['valor'];
        this['valorString'] = data['valorString'];
        this['ultimaAlteracao'] = data['ultimaAlteracao'];
    }
}
export class OutParametrosCashin {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['tipoConta'] = data['tipoConta'];
        this['valor'] = data['valor'];
        this['emails'] = data['emails'];
        this['isDeleted'] = data['isDeleted'];
    }
}
export class OutParametrosUnidadeOrganizacional {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['organizationUnitId'] = data['organizationUnitId'];
        this['sms'] = data['sms'];
        this['email'] = data['email'];
        this['push'] = data['push'];
        this['abrirContaAutomaticamente'] = data['abrirContaAutomaticamente'];
        this['webhook'] = data['webhook'];
        this['chaveDeProduto'] = data['chaveDeProduto'];
        this['tipoContaDefault'] = data['tipoContaDefault'];
        this['login2fa'] = data['login2fa'];
        this['validarDispositivoNoLogin'] = data['validarDispositivoNoLogin'];
        this['somenteUmDispositivo'] = data['somenteUmDispositivo'];
        this['exclusaoPorTempoInatividade'] = data['exclusaoPorTempoInatividade'];
        this['exclusaoPorCAFNegado'] = data['exclusaoPorCAFNegado'];
        this['geraCartaoFisico'] = data['geraCartaoFisico'];
        this['geraCartaoVirtual'] = data['geraCartaoVirtual'];
        this['tipoLoteCartaoFisico'] = data['tipoLoteCartaoFisico'];
        this['tipoLoteCartaoFisicoId'] = data['tipoLoteCartaoFisicoId'];
        this['diaEnvioLoteCartaoFisico'] = data['diaEnvioLoteCartaoFisico'];
        this['dominioDeEmail'] = data['dominioDeEmail'];
        this['chaveCapturaSmsAndroid'] = data['chaveCapturaSmsAndroid'];
        this['avisoTentativasLogin'] = data['avisoTentativasLogin'];
        this['aceitaNovosCadastros'] = data['aceitaNovosCadastros'];
        this['analisarTransacoes'] = data['analisarTransacoes'];
        this['chaveAssinaturaWebhook'] = data['chaveAssinaturaWebhook'];
        this['empresaIdAntecipacao'] = data['empresaIdAntecipacao'];
        this['requerValidacaoEmailParaCadastro'] = data['requerValidacaoEmailParaCadastro'];
        this['uoConfiavel'] = data['uoConfiavel'];
        this['limiteDeContasPorIF'] = data['limiteDeContasPorIF'];
        this['permitirContaAdicionalPorIF'] = data['permitirContaAdicionalPorIF'];
        this['emailsNotificacoesFraudes'] = data['emailsNotificacoesFraudes'];
        this['aceitaNovosCadastrosSemPreCadastro'] = data['aceitaNovosCadastrosSemPreCadastro'];
        this['possuiCreditoConsignado'] = data['possuiCreditoConsignado'];
        this['parcelaMaximaAntecipacao'] = data['parcelaMaximaAntecipacao'];
        this['parcelaMaximaCreditoConsignado'] = data['parcelaMaximaCreditoConsignado'];
        this['notificaEmailCompraCartao'] = data['notificaEmailCompraCartao'];
        this['termoPrivacidadeObrigatorio'] = data['termoPrivacidadeObrigatorio'];
        this['subcontasPorPortador'] = data['subcontasPorPortador'];
        this['requerValidacaoFacialAtualizacaoCadastral'] = data['requerValidacaoFacialAtualizacaoCadastral'];
        this['requerValidacaoFacialAntecipacaoFgts'] = data['requerValidacaoFacialAntecipacaoFgts'];
        this['requerValidacaoFacialPortabilidadeSalarial'] = data['requerValidacaoFacialPortabilidadeSalarial'];
        this['validarDispositivoPorFaceMatch'] = data['validarDispositivoPorFaceMatch'];
    }
}
export class OutPessoa {
    constructor(data = {}) {
        this['chaveDePessoa'] = data['chaveDePessoa'];
        this['identificacaoFiscal'] = data['identificacaoFiscal'];
        this['nome'] = data['nome'];
    }
}
export class OutProcessamentoArquivo {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['id'] = data['id'];
        this['organizationUnitId'] = data['organizationUnitId'];
        this['nomeArquivo'] = data['nomeArquivo'];
        this['status'] = data['status'];
    }
}
export class OutRecebiveisContrato {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
    }
}
export class OutRecebiveisFinanciamento {
    constructor(data = {}) {
        this['recebiveis'] = data['recebiveis'];
    }
}
export class OutRole {
    constructor(data = {}) {
        this['id'] = data['id'];
        this['name'] = data['name'];
        this['displayName'] = data['displayName'];
        this['organizationUnitId'] = data['organizationUnitId'];
        this['description'] = data['description'];
        this['grantedPermissions'] = data['grantedPermissions'];
    }
}
export class OutRoleAddOutputPaginated {
    constructor(data = {}) {
        this['items'] = data['items'];
        this['qtdPaginas'] = data['qtdPaginas'];
        this['qtdPorPagina'] = data['qtdPorPagina'];
        this['totalItems'] = data['totalItems'];
        this['pagina'] = data['pagina'];
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
    }
}
export class OutSegmentoBeneficio {
    constructor(data = {}) {
        this['id'] = data['id'];
        this['nome'] = data['nome'];
        this['gruposMcc'] = data['gruposMcc'];
    }
}
export class OutStatusCliente {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['chaveDeCliente'] = data['chaveDeCliente'];
        this['statusCadastroClienteDescricao'] = data['statusCadastroClienteDescricao'];
        this['statusCadastroClienteId'] = data['statusCadastroClienteId'];
        this['pendencias'] = data['pendencias'];
        this['erros'] = data['erros'];
        this['pendentesOnboard'] = data['pendentesOnboard'];
    }
}
export class OutTipoOcorrencia {
    constructor(data = {}) {
        this['id'] = data['id'];
        this['descricao'] = data['descricao'];
    }
}
export class OutTransacoesFaceMatch {
    constructor(data = {}) {
        this['transacaoId'] = data['transacaoId'];
        this['motivoTransacao'] = data['motivoTransacao'];
        this['tiposFaceMatch'] = data['tiposFaceMatch'];
        this['aprovado'] = data['aprovado'];
        this['imagemFaceMatch'] = data['imagemFaceMatch'];
    }
}
export class OutTransacoesFaceMatchAddOutputPaginated {
    constructor(data = {}) {
        this['items'] = data['items'];
        this['qtdPaginas'] = data['qtdPaginas'];
        this['qtdPorPagina'] = data['qtdPorPagina'];
        this['totalItems'] = data['totalItems'];
        this['pagina'] = data['pagina'];
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
    }
}
export class OutUsuario {
    constructor(data = {}) {
        this['userName'] = data['userName'];
        this['name'] = data['name'];
        this['surname'] = data['surname'];
        this['emailAddress'] = data['emailAddress'];
        this['isActive'] = data['isActive'];
        this['fullName'] = data['fullName'];
        this['lastLoginTime'] = data['lastLoginTime'];
        this['creationTime'] = data['creationTime'];
        this['roleNames'] = data['roleNames'];
        this['permissions'] = data['permissions'];
    }
}
export class OutUsuarioApi {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['api_Key'] = data['api_Key'];
        this['api_Secret'] = data['api_Secret'];
    }
}
export class OutValidarSenha {
    constructor(data = {}) {
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
        this['data'] = data['data'];
        this['senhasConferem'] = data['senhasConferem'];
    }
}
export class OutputPadrao {
    constructor(data = {}) {
        this['chaveDeIdempotencia'] = data['chaveDeIdempotencia'];
        this['data'] = data['data'];
        this['mensagem'] = data['mensagem'];
    }
}
export class ParametrosCashinDto {
    constructor(data = {}) {
        this['tipoConta'] = data['tipoConta'];
        this['valor'] = data['valor'];
        this['emails'] = data['emails'];
        this['isDeleted'] = data['isDeleted'];
    }
}
export class ParametrosCashinDtoAddOutputPaginated {
    constructor(data = {}) {
        this['items'] = data['items'];
        this['qtdPaginas'] = data['qtdPaginas'];
        this['qtdPorPagina'] = data['qtdPorPagina'];
        this['totalItems'] = data['totalItems'];
        this['pagina'] = data['pagina'];
        this['idExecucao'] = data['idExecucao'];
        this['idStatusExecucao'] = data['idStatusExecucao'];
        this['descricaoStatusExecucao'] = data['descricaoStatusExecucao'];
        this['mensagem'] = data['mensagem'];
    }
}
export class PermissionDto {
    constructor(data = {}) {
        this['id'] = data['id'];
        this['name'] = data['name'];
        this['displayName'] = data['displayName'];
        this['description'] = data['description'];
    }
}
export class RGDto {
    constructor(data = {}) {
        this['numero'] = data['numero'];
        this['dataEmissao'] = data['dataEmissao'];
        this['orgaoEmissor'] = data['orgaoEmissor'];
        this['ufOrgaoEmissor'] = data['ufOrgaoEmissor'];
    }
}
export class RecebivelCompletoDto {
    constructor(data = {}) {
        this['valor'] = data['valor'];
        this['numParcela'] = data['numParcela'];
        this['vencimento'] = data['vencimento'];
        this['valorAtual'] = data['valorAtual'];
        this['dataPagamento'] = data['dataPagamento'];
        this['statusPagamento'] = data['statusPagamento'];
    }
}
export class Telefone {
    constructor(data = {}) {
        this['ddd'] = data['ddd'];
        this['numero'] = data['numero'];
    }
}
export class TelefoneDto {
    constructor(data = {}) {
        this['ddd'] = data['ddd'];
        this['numero'] = data['numero'];
    }
}
export class TelefoneFixo {
    constructor(data = {}) {
        this['ddd'] = data['ddd'];
        this['numero'] = data['numero'];
    }
}
export class TipoValidacaoFacematchObj {
    constructor(data = {}) {
        this['tipoValidacaoFacematch'] = data['tipoValidacaoFacematch'];
        this['valor'] = data['valor'];
        this['valorQuantitativoValidacaoFacematch'] = data['valorQuantitativoValidacaoFacematch'];
        this['ativo'] = data['ativo'];
    }
}
export class TransacaoLogDto {
    constructor(data = {}) {
        this['idIntegracao'] = data['idIntegracao'];
        this['sucesso'] = data['sucesso'];
        this['entrada'] = data['entrada'];
        this['saida'] = data['saida'];
        this['descricaoDoErro'] = data['descricaoDoErro'];
        this['codigoResposta'] = data['codigoResposta'];
    }
}
export class ValidationErrorInfo {
    constructor(data = {}) {
        this['message'] = data['message'];
        this['members'] = data['members'];
    }
}
