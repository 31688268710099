var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"jazz-table"},[(!_vm.loading && _vm.rows)?[(_vm.header && Array.isArray(_vm.header) && _vm.header.length > 0)?_c('div',{staticClass:"report-header",class:_vm.styleHeader ? null : 'report-header-custom',style:(_vm.styleHeader)},_vm._l((_vm.header),function(reportHeader,index){return _c('div',{key:index,class:_vm.styleHeaderCel ? null : 'report-header-cel',style:(_vm.styleHeaderCel || {
            width: reportHeader.pixelWidth
              ? `${reportHeader.pixelWidth}px`
              : `${reportHeader.percentWidth || 100 / _vm.header.length}%`,
            justifyContent: reportHeader.align || 'center',
            background: reportHeader.background || 'transparent',
          })},[_vm._v(" "+_vm._s(reportHeader.title || "")+" ")])}),0):_vm._e(),_c('v-data-table',{directives:[{name:"show",rawName:"v-show",value:(_vm.pagination.page),expression:"pagination.page"}],staticClass:"report-table",attrs:{"data-cy":"table","items":_vm.rows,"hide-default-footer":"","hide-default-header":"","page":_vm.pagination.page,"items-per-page":_vm.pagination.perPage},on:{"update:page":function($event){return _vm.$set(_vm.pagination, "page", $event)}},scopedSlots:_vm._u([{key:"item",fn:function({ item }){return [_vm._t("reportRow",null,{"paramsItem":item})]}}],null,true)},[_c('template',{slot:"no-data"},[_c('NotFoundMessage',{attrs:{"message":_vm.emptyMessage}})],1),_c('template',{slot:"loading"})],2),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.rows && _vm.rows.length > 0 && _vm.pagination.page && !_vm.hidePagination),expression:"rows && rows.length > 0 && pagination.page && !hidePagination"}],staticClass:"pagination-container"},[_c('v-pagination',{attrs:{"data-cy":"pagination","prev-icon":"fas fa-angle-left","color":"var(--primaryColor)","next-icon":"fas fa-angle-right","circle":"","value":_vm.pagination.page,"length":_vm.pagination.pages,"total-visible":6},on:{"input":function($event){return _vm.$emit('inputNewPage', $event)}}})],1)]:_vm._e(),(_vm.loading)?_c('JazzSkeletonLoader',{attrs:{"type":"table"}}):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }