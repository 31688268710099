<template>
  <div>
    <JazzFormFields disabled :fields="fields" />
    <JazzSkeletonLoader v-if="!collaborator || loading" type="form" />
  </div>
</template>

<script>
import JazzFormFields from "../../../../../components/form/JazzFormFields";
import JazzSkeletonLoader from "../../../../../components/template/JazzSkeletonLoader";
import estadosBrasileiros from "../../../../../dbjson/estadosBrasileiros.json";
import globalMethods from "../../../../../mixins/globalMethods";

export default {
  name: "CollaboratorIdentification",
  mixins: [globalMethods],
  components: { JazzSkeletonLoader, JazzFormFields },
  props: {
    collaborator: Object,
  },
  data() {
    return {
      fields: {},
      loading: true,
    };
  },
  mounted() {
    if (this.collaborator) {
      this.createFields();
    }
  },
  methods: {
    createFields() {
      this.fields = {
        infoHeader: {
          field: "FormFieldsHeader",
          title: "Identificação",
        },
        identificacaoTextField: {
          field: "TextFieldMask",
          type: "text",
          label: "CPF",
          cols: 12,
          maskFormat: ["###.###.###-##"],
          valueField: this.collaborator?.identificacaoFiscal,
        },
        registrationGeneralTextField: {
          field: "TextFieldMask",
          type: "text",
          label: "RG",
          cols: 9,
          valueField: this.collaborator?.rg?.numero,
          maskFormat: ["##.###.###.-#"],
        },
        registrationGeneralUfSelectField: {
          field: "SelectField",
          type: "text",
          label: "UF de expedição",
          cols: 3,
          valueField: this.collaborator
            ? this.findObjectFromArray(
                estadosBrasileiros,
                "key",
                this.collaborator?.rg?.ufOrgaoEmissor
              )
            : "",
          items: [{ key: "", value: "Selecione" }, ...estadosBrasileiros],
          keyName: "value",
          keyValue: "key",
        },
        registrationGeneralAgencySelectField: {
          field: "SelectField",
          type: "text",
          label: "Orgão Emissor",
          cols: 8,
          valueField:
            this.collaborator && this.collaborator?.rg?.orgaoEmissor
              ? this.findObjectFromArray(
                  this.arrayEmittingOrgan(),
                  "value",
                  this.collaborator?.rg?.orgaoEmissor.toUpperCase()
                )
              : "",
          items: [
            { label: "Selecione", value: "" },
            ...this.arrayEmittingOrgan(),
          ],
          keyName: "label",
          keyValue: "value",
        },
        registrationGeneralDateTextField: {
          field: "TextFieldMask",
          type: "text",
          label: "Data Emissão",
          cols: 4,
          valueField:
            this.collaborator && this.collaborator?.rg?.dataEmissao
              ? this.dateFormat(this.collaborator?.rg?.dataEmissao)
              : "",
          maskFormat: ["##/##/####"],
        },
      };
      this.loading = false;
    },
  },
  watch: {
    collaborator() {
      this.createFields();
    },
  },
};
</script>

<style scoped></style>
