<template>
  <div class="background">
    <div
      data-cy="message-modal-div"
      class="dialog-box"
      :class="`${styleMessage}-color`"
    >
      <v-icon>
        {{ returnIcon(styleMessage) }}
      </v-icon>
      <JazzText
        :style-text="{ marginBottom: '20px' }"
        text-align="center"
        weight="bold"
        size="title"
        >{{ title }}
      </JazzText>
      <JazzText text-align="center">{{ message }}</JazzText>
      <button
        @click="CONFIRM_JAZZ_MESSAGE_MODAL(true)"
        data-cy="modal-understand-button"
        class="btn-modal"
      >
        {{ buttonText }}
      </button>
    </div>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import JazzText from "../typography/JazzText";

export default {
  name: "JazzMessageModal",
  components: { JazzText },
  props: {
    styleMessage: {
      type: String,
      default: () => "danger",
      validator: (propsWeight) => ["danger", "success"].includes(propsWeight),
    },
    title: {
      type: String,
      required: true,
    },
    message: String,
    buttonText: {
      type: String,
      default: () => "Entendi",
    },
  },
  methods: {
    ...mapMutations(["CONFIRM_JAZZ_MESSAGE_MODAL"]),
    returnIcon(value) {
      switch (value) {
        case "danger":
          return "fas fa-times-circle";
        case "success":
          return "fas fa-check-circle";
        default:
          return "fas fa-exclamation-circle";
      }
    },
  },
};
</script>

<style scoped lang="scss">
.background {
  background-color: rgba(0, 0, 0, 0.9);
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 500;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dialog-box {
  background: $whiteColor;
  width: 333px;
  padding: 40px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}

.dialog-box i {
  font-size: 100px;
  margin: 0 0 40px 0;
}

.dialog-box.danger-color i {
  color: $dangerColor;
}

.dialog-box.success-color i {
  color: $successColor;
}

.dialog-box.alert-color i {
  color: $dangerColor;
}

.btn-modal {
  height: 48px;
  padding: 0 48px;
  color: $whiteColor;
  font-weight: bold;  font-size: 18px;
  border-radius: 20px;
  margin-top: 40px;
}

.dialog-box.danger-color .btn-modal {
  background: $dangerColor;
}
.dialog-box.alert-color .btn-modal {
  background: $dangerColor;
}

.dialog-box.success-color .btn-modal {
  background: $successColor;

  &:hover {
    background: $primaryColor;
  }
}
</style>
