import { AutenticacoesService } from "../../services/api";
import { UsuariosBackofficeService } from "../../services/apibackoffice";
import { SymmetricCryptoForge } from "../../services/secure-codes/SymmetricCryptoForge";
import { AsymmetricCrypto } from "../../services/secure-codes/AsymmetricCrypto";

export const authStore = {
  state: () => ({
    user: {
      id: null,
      idOrganizationUnit: 1,
      name: null,
      userName: null,
      emailAddress: null,
      fullName: null,
      roleNames: [],
      permissions: [],
    },
    secure: {
      publicKey: null,
      symmetric: null,
      asymmetric: null,
    },
  }),
  mutations: {
    SET_AUTH(state, payload) {
      state.user.id = payload.id;
      state.user.name = payload.name;
      state.user.userName = payload.userName;
      state.user.emailAddress = payload.emailAddress;
      state.user.fullName = payload.fullName;
      state.user.roleNames = payload.roleNames;
      state.user.permissions = payload.permissions;
      state.user.idOrganizationUnit = payload?.idOrganizationUnit || 1;
    },
    SET_SECURE_CONFIG(state, payload) {
      state.secure.publicKey = payload.publicKey;
      state.secure.symmetric = payload.symmetric;
      state.secure.asymmetric = payload.asymmetric;
    },
  },
  actions: {
    async action_secureConfig(context) {
      try {
        console.log(
          "process?.env?.VUE_APP_BASE_URL",
          process?.env?.VUE_APP_BASE_URL
        );
        const publicKeyFetch = await fetch(process?.env?.VUE_APP_BASE_URL);
        const publicKey = (await publicKeyFetch.json()).publicKey;
        const symmetric = new SymmetricCryptoForge();
        const asymmetric = new AsymmetricCrypto(publicKey);
        context.commit("SET_SECURE_CONFIG", {
          publicKey: publicKey,
          asymmetric: asymmetric,
          symmetric: symmetric,
        });
      } catch (e) {
        await context.dispatch("action_openMessageModal", {
          styleMessage: "danger",
          message: `Ocorreu um erro durante a requisição. Por favor, tente novamente.\n (Código interno: CPT000)`,
          title: "Atenção",
        });
      }
    },
    async action_setUserAuth(context, payload) {
      return await UsuariosBackofficeService.buscarDadosUsuarioLogado({
        body: payload,
      })
        .then((response) => {
          context.commit("SET_AUTH", response.result.usuario);
          context.commit("SET_THEME", response.result.usuario.roleNames[0]);
          return response?.result?.tipoUsuarioId === 0;
        })
        .catch(() => {
          localStorage.clear();
          Location.reload();
        });
    },

    async action_signIn(context, payload) {
      return await AutenticacoesService.usuario({ body: payload })
        .then((response) => {
          return response;
        })
        .catch((e) => e);
    },
  },
};

export default authStore;
