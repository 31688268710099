<template>
  <div class="w-100">
    <JazzText class="mb-5" weight="bold" size="description">
      Passo 3. Salve o arquivo no formato .CSV
    </JazzText>
    <div class="files">
      <div class="w-50">
        <JazzText class="mb-5" weight="bold" size="description">
          No Google Sheets
        </JazzText>
        <JazzText
          size="description"
          :key="index"
          v-for="(item, index) in googleSheets"
        >
          {{ index + 1 }}. Clique em <strong>{{ item }}</strong>
        </JazzText>
        <img
          class="w-100 mt-5"
          src="../../images/GoogleSheets.png"
          alt="Captura de tela Google Sheets"
        />
      </div>
      <div class="w-50 ml-10">
        <JazzText class="mb-5" weight="bold" size="description">
          No Excel
        </JazzText>
        <JazzText
          size="description"
          :key="index"
          v-for="(item, index) in excel"
        >
          {{ index + 1 }}. Clique em <strong>{{ item }}</strong>
        </JazzText>
        <JazzText size="description">
          Observação: Esse fluxo pode variar de acordo com a versão utilizada no
          Excel.
        </JazzText>
        <img
          class="w-100 mt-5"
          src="../../images/Excel_2.png"
          alt="Captura de tela Excel"
        />
      </div>
    </div>
  </div>
</template>

<script>
import globalMethods from "../../../../../../mixins/globalMethods";
import JazzText from "../../../../../../components/typography/JazzText";

export default {
  name: "Step3",
  mixins: [globalMethods],
  components: { JazzText },
  data() {
    return {
      googleSheets: [
        "Arquivo",
        "Fazer Download",
        "Valores separados por vírgulas (.csv)",
      ],
      excel: [
        "Arquivo",
        "Exportar",
        "Alterar Tipo de Arquivo",
        "CSV (separado por vírgulas)(*.csv)",
        "Salvar como",
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.files {
  display: flex;
  flex-direction: row;
}
</style>
