<template>
  <div>
    <DefaultButton
      data-cy="value-edit-button"
      class="value-edit-button"
      :class="{ disabled: this.card.statusPedido === 'Fechado' }"
      text="Editar Valor"
      @click.native="modalCreate = true"
    />
    <ContentModal
      v-if="modalCreate"
      title="Editar Valores do Cartão"
      @close="modalCreate = false"
      @save="openConfirmationModal"
      :disableButton="!isActive"
    >
      <JazzFormFields
        :fields="fields"
        @update:values="
          inputsValues = $event;
          validate++;
        "
        :validate="validate"
        @checkValidity="enableButton"
      />
    </ContentModal>
  </div>
</template>

<script>
import JazzFormFields from "../../../../components/form/JazzFormFields";
import ContentModal from "../../../../components/modal/ContentModal";
import DefaultButton from "../../../../components/button/DefaultButton";
import globalMethods from "../../../../mixins/globalMethods";
import { PedidosCartaoService } from "../../../../services/apitravel";
import { mapActions, mapMutations } from "vuex";

export default {
  mixins: [globalMethods],
  name: "EditCardValues",
  props: { card: Object },
  components: {
    JazzFormFields,
    ContentModal,
    DefaultButton,
  },
  data() {
    return {
      fields: {},
      tipoTransacao: [],
      validate: 0,
      inputsValues: {
        valor: "R$ 0,00",
        tipoDeOperacao: {
          id: null,
        },
      },
      modalCreate: false,
      isActive: true,
    };
  },

  mounted() {
    this.createForm();
  },

  methods: {
    ...mapActions(["action_confirmationModal"]),
    ...mapMutations(["CHANGE_LOADING"]),

    createForm() {
      this.fields = {
        tipoDeOperacao: {
          label: "Tipo de Operação",
          field: "SelectField",
          cols: 12,
          valueField: null,
          items: [
            { id: null, displayName: "Selecione" },
            { id: 1, displayName: "Adicionar Valor" },
            { id: 2, displayName: "Remover Valor" },
          ],
          keyName: "displayName",
          keyValue: "id",
          changeValue: () => this.calculateValue(),
          rules: [
            (fields) =>
              !!fields.tipoDeOperacao.valueField.id ||
              "O campo Tipo de Operação é obrigatório",
          ],
        },
        valor: {
          label: "Valor",
          field: "TextField",
          cols: 12,
          valueField: "",
          maskMoney: true,
          onBlur: () => this.calculateValue(),
          rules: [
            (fields) =>
              (!!fields.valor.valueField &&
                this.moneyInput2MoneyFloat(fields.valor.valueField) > 0) ||
              "O campo Valor é obrigatório",
            (fields) =>
              this.inputsValues.tipoDeOperacao.id === 2 &&
              this.moneyInput2MoneyFloat(fields.valor.valueField) >
                this.card.saldo
                ? "O valor descontado do cartão não pode ser maior do que o saldo disponível"
                : true,
          ],
        },
        saldoTotal: {
          label: "Saldo Total",
          field: "TextField",
          type: "text",
          valueField: "R$ " + this.money2(this.card.valorPedido),
          cols: 12,
          disabled: true,
        },
        saldoDisponivel: {
          label: "Saldo Disponível",
          field: "TextField",
          type: "text",
          valueField: "R$ " + this.money2(this.card.saldo),
          cols: 12,
          disabled: true,
        },
        senha: {
          label: "Senha",
          field: "TextField",
          valueField: "",
          type: "password",
          maxLength: "20",
          cols: 12,
          rules: [
            (fields) =>
              !!fields.senha.valueField || "O campo Senha é obrigatório",
          ],
        },
      };
    },
    calculateValue() {
      let valorConvertido = this.moneyInput2MoneyFloat(this.inputsValues.valor);

      switch (this.inputsValues.tipoDeOperacao.id) {
        case null:
          this.fields.saldoTotal.valueField =
            "R$ " + this.money2(this.card.valorPedido);
          this.fields.saldoDisponivel.valueField =
            "R$ " + this.money2(this.card.saldo);
          break;
        case 1:
          this.fields.saldoTotal.valueField =
            "R$ " + this.money2(this.card.valorPedido + valorConvertido);
          this.fields.saldoDisponivel.valueField =
            "R$ " + this.money2(this.card.saldo + valorConvertido);
          break;
        case 2:
          if (valorConvertido <= this.card.saldo) {
            this.fields.saldoTotal.valueField =
              "R$ " + this.money2(this.card.valorPedido - valorConvertido);
            this.fields.saldoDisponivel.valueField =
              "R$ " + this.money2(this.card.saldo - valorConvertido);
          }
          break;
        default:
          break;
      }
    },

    openConfirmationModal() {
      this.action_confirmationModal({
        icon: "fas fa-exclamation-circle",
        title: "Confirmar Edição de Valores do Cartão",
        message: `Tem certeza que deseja alterar o valor do cartão de
           R$ ${this.money2(this.card.valorPedido)} para ${
          this.fields.saldoTotal.valueField
        }?   Ao confirmar, o novo saldo disponível será de ${
          this.fields.saldoDisponivel.valueField
        }.`,
        cancelText: "Cancelar",
        confirmText: "Confirmar",
      }).then((confirm) => {
        if (confirm) {
          this.saveCardValues();
        }
      });
    },

    saveCardValues() {
      this.modalCreate = false;
      this.CHANGE_LOADING(true);
      const { tipoDeOperacao, valor, senha } = this.inputsValues;

      let payload = {
        chaveDePedidoCartao: this.card.chaveDePedidoCartao,
        senha: senha,
        tipoTransacao: tipoDeOperacao.id,
        valor: this.moneyInput2MoneyFloat(valor),
      };

      PedidosCartaoService.ajustarSaldo({
        body: payload,
      })
        .then(() => {
          this.CHANGE_LOADING(false);
          this.$store.dispatch(
            "action_changeMessageSnackBar",
            `Valor atualizado com sucesso!`,
            { module: "template" }
          );
          this.$emit("reload");
          this.createForm();
        })
        .catch(() => {
          this.CHANGE_LOADING(false);
          this.createForm();
        });
    },
    enableButton(form) {
      if (form.isValid) {
        this.isActive = true;
      } else {
        this.isActive = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.value-edit-button {
  width: 170px;
  align-self: flex-end;
}

@media screen and (min-width: 800px) {
  .value-edit-button {
    align-self: center;
  }
}

@media screen and (min-width: 1380px) {
  .value-edit-button {
    width: 160px;
  }
}
</style>
