<template>
  <DefaultCard class="PRegistration">
    <JazzListPage
      title="Pré-Cadastro por Arquivo"
      :fields="fields"
      :disableSearchButton="loadingTable || loadingFilter"
      :loading="loadingFilter"
      @search="getHistory"
      hide-filter-button
      button-with-label
      :creationRoute="creationRoute"
      :helpRoute="helpRoute"
      @importFile="showImportModal = true"
      @stepByStep="showHelpModal = true"
    >
      <ProcessingFilesList
        class="mt-10 mb-5"
        v-if="processingFiles"
        :result="processingFiles"
      />

      <JazzTable
        class="mt-5"
        :header="header"
        :rows="history"
        :pagination="pagination"
        :loading="loadingTable"
        @inputNewPage="getHistory(null, $event)"
      >
        <template slot="reportRow" slot-scope="props">
          <PreviousRegistrationRow
            @actionButton="downloadFile($event)"
            :paramsItem="props.paramsItem"
          />
        </template>
      </JazzTable>
    </JazzListPage>
    <PRegistrationHelpModalFlow
      v-if="showHelpModal && !loadingFilter"
      @showModal="showHelpModal = $event"
    />
    <PRegistrationLoadImportModal
      v-if="showImportModal && !loadingFilter"
      @showModal="showImportModal = $event"
      :tesouraryAccounts="tesouraryAccounts"
      @getProcessingFiles="searchFiles($event, true)"
      @showHelpModal="
        showImportModal = false;
        showHelpModal = true;
      "
    />
  </DefaultCard>
</template>

<script>
import globalMethods from "../../../mixins/globalMethods";
import DefaultCard from "../../../components/cards/DefaultCard";
import JazzListPage from "../../../components/template/JazzListPage";
import JazzTable from "../../../components/table/JazzTable";
import PreviousRegistrationRow from "./components/rowsTable/PreviousRegistrationRow";
import PRegistrationLoadImportModal from "./components/modals/PRegistrationImportModal";
import PRegistrationHelpModalFlow from "./components/modals/PRegistrationHelpModal";
import ProcessingFilesList from "./components/ProcessingFilesList";
import { ClientesService, TesourariasService } from "../../../services/api";
import { mapMutations } from "vuex";

export default {
  name: "PRegistration",
  components: {
    DefaultCard,
    JazzListPage,
    JazzTable,
    PreviousRegistrationRow,
    PRegistrationLoadImportModal,
    PRegistrationHelpModalFlow,
    ProcessingFilesList,
  },
  mixins: [globalMethods],
  data() {
    return {
      tesouraryAccounts: [],
      history: [],
      loadingFilter: true,
      loadingTable: false,
      fields: {},
      filters: {},
      pagination: {
        page: 1,
        perPage: 15,
        total: 1,
        pages: 1,
      },
      header: [
        { title: "Arquivo", align: "left", percentWidth: "20" },
        { title: "Usuário responsável", align: "left", percentWidth: "17" },
        { title: "Processado", align: "left", percentWidth: "15" },
        { title: "Registros", align: "left", percentWidth: "18" },
        { title: "Status", align: "left", percentWidth: "14" },
        { title: "Ação", align: "left", percentWidth: "14" },
      ],
      helpRoute: {
        text: "Dúvidas? Como importar arquivos.",
        title: null,
        route: null,
        eventName: "stepByStep",
      },
      creationRoute: {
        text: "Importar arquivo",
        title: null,
        route: null,
        eventName: "importFile",
      },
      showImportModal: false,
      showHelpModal: false,
      processingFiles: null,
    };
  },
  async mounted() {
    this.loadingFilter = true;
    await this.getTesouraryAccounts();
    this.createForm();
    this.loadingFilter = false;
  },
  methods: {
    ...mapMutations(["CHANGE_LOADING"]),
    async searchFiles(uo, reloadHistory = false) {
      this.showImportModal = false;
      await this.ProcessingFiles(uo);
      if (reloadHistory) {
        await this.getHistory({
          isValid: true,
          values: {
            uoAutoCompleteField: this.findObjectFromArray(
              this.tesouraryAccounts,
              "organizationUnitId",
              uo
            ),
          },
        });
      }
    },
    async ProcessingFiles(uo) {
      setTimeout(
        await ClientesService.listarImportacoesClientes({
          body: {
            organizationUnitId: uo,
            status: 2,
            inicio: this.dateISOFormat(
              this.dateEnFormat() + " 00:00:00 +0000",
              "YYYY-MM-DD HH:mm:ss Z"
            ),
            fim: this.dateISOFormat(
              this.dateEnFormat() + " 23:59:59 +0000",
              "YYYY-MM-DD HH:mm:ss Z"
            ),
          },
        }).then((response) => {
          const { result } = response;
          this.processingFiles =
            result?.items.length > 0 ? result?.items : null;
        }),
        2000
      );
    },
    createForm() {
      this.fields = {
        uoAutoCompleteField: {
          field: "JazzAutoCompleteField",
          label: "Empresa",
          cols: 6,
          items: [
            { organizationUnitId: null, nome: "Selecione" },
            ...this.tesouraryAccounts,
          ],
          valueField: null,
          keyName: "nome",
          keyValue: "organizationUnitId",
        },
        statusSelectField: {
          field: "SelectField",
          label: "Status",
          cols: 6,
          items: [
            { name: "Selecione", value: null },
            { name: "Importado", value: 1 },
            { name: "Em processamento", value: 2 },
            { name: "Recusado", value: 3 },
            { name: "Processado", value: 4 },
          ],
          valueField: null,
          keyName: "name",
          keyValue: "value",
        },
        initialDateJazzDateField: {
          field: "JazzDateField",
          label: "Data Inicial",
          cols: 5,
          valueField: this.dateSubtract30Days(),
          rules: [
            (fields) =>
              !this.compareDateAfterAndCurrent(
                fields.initialDateJazzDateField.valueField,
                fields.finalDateJazzDateField.valueField
              ) || "Data inicial deve ser menor que data final",
          ],
          appendIcon: "mdi-calendar",
        },
        finalDateJazzDateField: {
          field: "JazzDateField",
          label: "Data Final",
          cols: 5,
          valueField: this.dateEnFormat(),
          rules: [
            (fields) =>
              !this.compareDateAfterAndCurrent(
                fields.initialDateJazzDateField.valueField,
                fields.finalDateJazzDateField.valueField
              ) || "Data final deve ser maior que data inicial",
          ],
          appendIcon: "mdi-calendar",
        },
      };
    },

    async getTesouraryAccounts() {
      this.tesouraryAccounts =
        await TesourariasService.consultarContaTesouraria({
          body: {
            tipoOrdenacao: 1,
          },
        }).then((response) => response?.result.items || []);
    },

    async getHistory(fields = null, page = 1) {
      if ((fields && fields?.isValid) || !fields) {
        this.loadingTable = true;
        if (fields) {
          const {
            uoAutoCompleteField,
            statusSelectField,
            initialDateJazzDateField,
            finalDateJazzDateField,
          } = fields.values;
          await this.ProcessingFiles(uoAutoCompleteField.organizationUnitId);
          this.filters = {
            organizationUnitId:
              this.convertArrayObjectFilter(
                uoAutoCompleteField,
                "organizationUnitId"
              ) || null,
            status:
              this.convertArrayObjectFilter(statusSelectField, "value") || null,
            inicio: this.dateISOFormat(
              initialDateJazzDateField + " 00:00:00 +0000",
              "YYYY-MM-DD HH:mm:ss Z"
            ),
            fim: this.dateISOFormat(
              finalDateJazzDateField + " 23:59:59 +0000",
              "YYYY-MM-DD HH:mm:ss Z"
            ),
          };
        }
        const result = await ClientesService.listarImportacoesClientes({
          body: {
            ...this.filters,
            porPagina: this.pagination.perPage,
            pagina: page,
          },
        }).then((response) => response?.result || []);
        this.history = result?.items;
        this.pagination.page = result?.pagina;
        this.pagination.perPage = result?.qtdPorPagina;
        this.pagination.total = result?.totalItems;
        this.pagination.pages = result?.qtdPaginas;
        this.loadingTable = false;
      }
    },
    async downloadFile({ id, nome, uo }) {
      this.CHANGE_LOADING(true);
      await ClientesService.baixarRetornoImportacao({
        body: {
          importacaoId: id,
          organizationUnitId: uo,
        },
      })
        .then((response) => {
          if (response.result?.arquivo) {
            let csvContent =
              "data:text/csv;charset=utf-8," +
              encodeURI(response.result.arquivo);
            this.downloadCsvString(csvContent, "resultado_" + nome);
            this.$store.dispatch(
              "action_changeMessageSnackBar",
              "Arquivo baixado com sucesso!",
              { module: "template" }
            );
          }
        })
        .finally(() => {
          this.CHANGE_LOADING(false);
        });
    },
  },
};
</script>

<style scoped>
.PRegistration {
  min-width: 100%;
}

.space {
  margin-top: 20px;
}

.align-button {
  display: flex;
  flex-direction: row-reverse;
  align-content: center;
  justify-content: space-between;
}
</style>
