<template>
  <div>
    <template v-if="!loading">
      <div v-if="accountRow" class="account-container">
        <AccountRow v-if="account" :params-item="accountRow" />
      </div>
      <div>
        <v-tabs
          class="tabs-collaborator"
          centered
          v-if="account || params.chaveDeConta"
        >
          <v-tab
            v-if="showAccountDetails"
            :to="{
              name: 'CollaboratorAccountLoad',
              params: params,
            }"
            data-cy="load-tab"
            >Carga
          </v-tab>
        </v-tabs>
        <div class="content-tabs">
          <transition name="fade" mode="out-in">
            <keep-alive>
              <router-view
                @updateAccount="getAccount"
                :collaborator="collaborator"
                :account="account"
              />
            </keep-alive>
          </transition>
        </div>
      </div>
    </template>
    <JazzSkeletonLoader v-if="loading" type="form" />
  </div>
</template>

<script>
import globalMethods from "../../../mixins/globalMethods";
import AccountRow from "./components/rowsTable/AccountRow";
import JazzSkeletonLoader from "../../../components/template/JazzSkeletonLoader";
import { ContasService } from "../../../services/api";

export default {
  name: "CollaboratorAccountShow",
  mixins: [globalMethods],
  props: {
    collaborator: Object,
  },
  components: { JazzSkeletonLoader, AccountRow },
  data() {
    return {
      account: null,
      balances: null,
      accountRow: null,
      loading: true,
      params: this.$route.params,
      isCarrier: false,
    };
  },
  async mounted() {
    await this.getAccount();
  },
  methods: {
    async getAccount() {
      const { chaveDeConta } = this.params;
      if (chaveDeConta) {
        this.account = null;
        this.loading = true;
        this.account = await ContasService.obterDadosConta({
          body: {
            chaveDeConta: chaveDeConta,
          },
        })
          .then((res) => {
            if (res?.result) {
              const {
                descricaoStatusConta,
                identificacaoFiscal,
                nomeCliente,
                descricaoProduto,
                bloqueadaPorPinIncorreto,
                organizationUnitDescricao,
              } = res?.result;
              this.accountRow = {
                parceiro: organizationUnitDescricao,
                statusCadastroContaId: undefined,
                statusConta: descricaoStatusConta,
                identificacaoFiscal: identificacaoFiscal,
                nome: nomeCliente,
                produto: descricaoProduto,
                bloqueadaPorPinIncorreto: bloqueadaPorPinIncorreto,
              };
            }
            return res?.result;
          })
          .finally(() => (this.loading = false));
      }
    },
  },
  computed: {
    showAccountDetails() {
      return this.hasPermission(["Api.Conta.ConsultarDados"]);
    },
  },
};
</script>

<style lang="scss" scoped>
.content-tabs {
  padding: 20px 20px 0 20px;
}

.v-tabs {
  width: 100% !important;
  overflow-x: hidden;
}

.v-tab {
  font-size: 14px;
  text-transform: none;
  font-weight: normal;
  color: $blackColor !important;
  letter-spacing: 0;
}

.v-tab.v-tab--active {
  font-size: 14px;
  color: $primaryColor !important;
}

.account-container {
  margin: 20px 0;
  padding-bottom: 0;
  border-bottom: 1px solid $grayContrast;
}
</style>
