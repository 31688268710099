<template>
  <ContentModal
    title="Importar novo arquivo"
    @close="$emit('showModal', false)"
    @save="sendFile()"
    :disable-button="disabled"
    hide-cancel-button
    @secondButton="$emit('showHelpModal')"
    show-second-button
    secondButtonText="Dúvidas? Como importar arquivo"
  >
    <JazzFormFields
      :fields="fields"
      @update:values="
        inputsValues = $event;
        validate++;
      "
      :validate="validate"
      @checkValidity="enableButton"
      @downloadModel="downloadModel()"
    />
    <JazzInformationTooltip
      class="mt-5"
      v-if="!hideFileInput"
      :content="content"
    />
    <JazzInformationTooltip
      class="mt-5"
      v-if="!hideFileInput"
      simpleList
      styleBoxColor="secondary"
      styleColor="primary"
      :title="`${'Saldo atual: R$' + money2(valueAccount)}`"
      :content="contentAccount"
    />
  </ContentModal>
</template>

<script>
import { mapMutations } from "vuex";
import globalMethods from "../../../../../mixins/globalMethods";
import ContentModal from "../../../../../components/modal/ContentModal";
import JazzFormFields from "../../../../../components/form/JazzFormFields";
import motivosBeneficio from "../../../../../dbjson/motivosBeneficio";
import JazzInformationTooltip from "../../../../../components/tooltip/JazzInformationTooltip";
import { TesourariasService } from "../../../../../services/api";

export default {
  name: "BenefitImportModal",
  mixins: [globalMethods],
  components: { ContentModal, JazzFormFields, JazzInformationTooltip },
  props: {
    tesouraryAccounts: Array,
  },
  data() {
    return {
      disabled: true,
      fields: {
        partnerAutoCompleteField: {
          valueField: null,
        },
      },
      validate: 0,
      inputsValues: {},
      content: [
        "Informe o CPF e o valor desejado para carga.",
        "A última linha do arquivo deverá ser sem CPF, e contendo o valor total da recarga do arquivo processado.",
        "Em algumas versões do Excel, o arquivo salvo em CSV pode desconfigurar. Para ajustar, abra o arquivo usando o bloco de notas e salve novamente.",
        "Limite de 10.000 linhas por arquivo ou 10mb.",
      ],
      contentAccount: [
        "Para que a recarga seja efetivada, o valor total deve ser menor ou igual ao saldo atual",
      ],
      valueAccount: null,
      hideFileInput: true,
    };
  },
  mounted() {
    this.createForm();
  },
  methods: {
    ...mapMutations(["CHANGE_LOADING"]),
    async sendFile() {
      this.CHANGE_LOADING(true);
      const { partnerAutoCompleteField, benefitSelectField, csvFileUpload } =
        this.inputsValues;
      let payload = {
        organizationUnitId: this.convertArrayObjectFilter(
          partnerAutoCompleteField,
          "organizationUnitId"
        ),
        arquivo: csvFileUpload.arquivo,
        nomeArquivo: csvFileUpload.nomeArquivo,
        motivo: this.convertArrayObjectFilter(benefitSelectField, "value"),
      };
      await TesourariasService.creditoMassivoPorMotivo({
        body: payload,
      })
        .then(() => {
          this.CHANGE_LOADING(false);
          this.$store.dispatch(
            "action_changeMessageSnackBar",
            "Arquivo enviado com sucesso!",
            { module: "template" }
          );
          this.$emit("getProcessingFiles", {
            uo: payload.organizationUnitId,
            reason: payload.motivo,
          });
        })
        .catch(() => {
          this.CHANGE_LOADING(false);
        });
    },
    createForm() {
      this.fields = {
        title: {
          field: "FormFieldsHeader",
          title: "Selecione a empresa que deseja adicionar carga de benefício",
          hideTitleBorder: true,
          color: "black",
        },
        partnerAutoCompleteField: {
          label: "Empresa",
          field: "JazzAutoCompleteField",
          cols: 12,
          items: [
            { organizationUnitId: null, nome: "Selecione", saldo: null },
            ...this.tesouraryAccounts,
          ],
          valueField: null,
          keyName: "nome",
          keyValue: "organizationUnitId",
          rules: [
            (fields) =>
              !!fields.partnerAutoCompleteField.valueField
                ?.organizationUnitId || "O campo Empresa é obrigatório",
          ],
          changeValue: (fields) => this.showFields(fields),
        },
        benefitSelectField: {
          field: "SelectField",
          label: "Benefício",
          cols: 12,
          hideInput: true,
          items: motivosBeneficio,
          valueField: null,
          keyName: "key",
          keyValue: "value",
          rules: [
            (fields) =>
              !!fields.benefitSelectField?.valueField?.value ||
              "Selecione um benefício antes de importar",
          ],
        },
        downloadModelButton: {
          isButton: true,
          hideInput: true,
          field: "InternalButton",
          cols: 12,
          text: "Baixar arquivo modelo (CSV)",
          colorStyle: "white-green ultra-radius large",
          event: "downloadModel",
        },
        csvFileUpload: {
          field: "JazzUploadFileField",
          label: "Carregar Arquivo (CSV)",
          cols: 12,
          valueField: null,
          hideInput: true,
          rules: [
            (field) =>
              !!field.csvFileUpload?.valueField ||
              "O campo Arquivo é obrigatório",
          ],
        },
      };
      if (this.tesouraryAccounts?.length === 1) {
        this.fields.partnerAutoCompleteField.valueField =
          this.tesouraryAccounts[0];
      }
    },
    downloadModel() {
      let csvContent =
        "data:text/csv;charset=utf-8," +
        encodeURI(
          "IdentificacaoFiscal;" +
            "ValorCarga\n" +
            "81990315062;" +
            "500\n" +
            "30030030030;" +
            "600\n;" +
            "1100"
        );

      this.downloadCsvString(csvContent, "modelo_importacao.csv");
      this.$store.dispatch(
        "action_changeMessageSnackBar",
        "Arquivo baixado com sucesso!",
        { module: "template" }
      );
    },
    showFields(fields) {
      const {
        partnerAutoCompleteField,
        benefitSelectField,
        csvFileUpload,
        downloadModelButton,
      } = fields;
      if (!partnerAutoCompleteField?.valueField?.organizationUnitId) {
        this.hideFileInput = true;
        csvFileUpload.hideInput = true;
        benefitSelectField.hideInput = true;
        downloadModelButton.hideInput = true;
      } else {
        this.hideFileInput = false;
        csvFileUpload.hideInput = false;
        benefitSelectField.hideInput = false;
        downloadModelButton.hideInput = false;

        let valorAntigo = null;
        let valorNovo = partnerAutoCompleteField?.valueField?.saldo;
        if (valorAntigo !== valorNovo) {
          valorAntigo = valorNovo;
          this.valueAccount = valorNovo;
        }
      }
    },
    enableButton(e) {
      if (!e.isValid) {
        this.disabled = true;
      } else {
        this.disabled = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
