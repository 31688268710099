<template>
  <v-col :cols="parseInt(cols)">
    <JazzText class="mb-5 mt-5" weight="bold" size="subtitle">
      {{ label }}
    </JazzText>
    <v-input
      class="file-field"
      :rules="rules ? rules : []"
      v-model="uploadedFile"
    >
      <label
        @drop.prevent="onDrop($event)"
        @dragover.prevent="dragover = true"
        @dragenter.prevent="dragover = true"
        @dragleave.prevent="dragover = false"
        class="marked"
        for="uploadedFile"
        name="uploadedFile"
        value="uploadedFile"
        :data-cy="dataCy || 'upload-file-input'"
      >
        <div v-if="!uploadedFile" class="box">
          <v-icon
            :color="`var(--grayColor)`"
            size="60"
            :class="[dragover ? 'mt-2, mb-6' : 'mt-5']"
          >
            fas fa-file
          </v-icon>
          <JazzText
            class="mt-5"
            weight="bold"
            size="subtitle"
            color="grey"
            textAlign="center"
          >
            Arraste um arquivo aqui ou clique para selecioná-lo
          </JazzText>
          <input
            name="uploadedFile"
            value="uploadedFile"
            id="uploadedFile"
            type="file"
            @change="toBase64($event)"
            accept=".csv"
          />
        </div>
      </label>
      <div class="box" v-if="uploadedFile">
        <v-icon
          color="primary"
          size="60"
          :class="[dragover ? 'mt-2, mb-15' : 'mt-5 mb-5']"
        >
          fas fa-file
        </v-icon>
        <JazzText class="mt-5" weight="bold" size="subtitle" textAlign="center">
          Arquivo anexado : {{ uploadedFile.nomeArquivo }}
        </JazzText>
        <TableLabelButton
          class="mt-5 w-30"
          @click.native="cleanSelection()"
          icon="fas fa-times"
          label="Limpar Seleção"
          buttonColor="primary"
          iconColor="white"
          size="small"
          weight="bold"
          textTransform="capitalize"
        />
      </div>
    </v-input>
  </v-col>
</template>

<script>
import globalMethods from "../../mixins/globalMethods";
import JazzText from "../typography/JazzText";
import TableLabelButton from "../../components/button/TableLabelButton";

export default {
  name: "JazzUploadFileField",
  mixins: [globalMethods],
  components: {
    JazzText,
    TableLabelButton,
  },
  props: {
    label: { type: String, default: "Carregar Arquivo (CSV)" },
    dataCy: String,
    cols: Number,
    rules: Array,
  },
  data() {
    return {
      uploadedFile: null,
      dragover: false,
    };
  },
  async mounted() {
    this.url = this.urlImage;
    if (this.urlImage) {
      const base64 = await this.convertImageToCanvas(this.url).then(
        (dataURL) => dataURL
      );
      this.uploadedFile = {
        tamanho: new Buffer(base64, "base64").length,
        nomeArquivo: null,
        arquivo: {
          base64: base64,
          extensao: this.convertFileExtension(this.url.split(".").pop()),
          url: null,
        },
      };
      this.$emit("update:value", this.uploadedFile);
    }
  },
  methods: {
    removeHeaderBase64(base64) {
      let res = base64.split(",");
      return res[1];
    },
    async fileBase64(file) {
      return await new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    async toBase64(e) {
      const file = e.target.files[0];

      if (this.validExtensions(file)) {
        this.url = URL.createObjectURL(file);
        this.uploadedFile = {
          tamanho: file.size,
          nomeArquivo: file.name,
          arquivo: {
            base64: this.removeHeaderBase64(await this.fileBase64(file)),
            extensao: this.convertFileExtension(file.name.split(".").pop()),
            url: null,
          },
        };
        this.$emit("update:value", this.uploadedFile);
      } else {
        this.openMessageModal("danger", "Extensão do arquivo inválida");
      }
    },
    validExtensions(file) {
      if (file === undefined || file === null) return null;
      let type = file.name.split(".").pop();
      return type === "csv";
    },
    cleanSelection() {
      this.uploadedFile = null;
      this.$emit("update:value", null);
    },
    async onDrop(e) {
      this.dragover = false;
      if (this.uploadedFile) this.uploadedFile = {};
      if (e.dataTransfer.files.length > 1) {
        this.openMessageModal(
          "danger",
          "Atenção",
          "Somente um arquivo por vez"
        );
      } else {
        const file = e.dataTransfer.files[0];
        if (this.validExtensions(file)) {
          this.uploadedFile = {
            tamanho: file.size,
            nomeArquivo: file.name,
            arquivo: {
              base64: this.removeHeaderBase64(await this.fileBase64(file)),
              extensao: this.convertFileExtension(file.name.split(".").pop()),
              url: null,
            },
          };
          this.$emit("update:value", this.uploadedFile);
        } else {
          this.openMessageModal("danger", "Extensão do arquivo inválida");
        }
      }
    },
  },
  watch: {
    valueField: {
      immediate: true,
      handler(uploadedFile) {
        this.uploadedFile = uploadedFile;
      },
    },
    uploadedFile: {
      immediate: true,
      handler() {
        this.$emit("update:value", this.uploadedFile);
      },
    },
  },
};
</script>

<style scoped lang="scss">
.file-field {
  width: 100%;
  border-radius: 15px;
  display: flex;
  justify-content: space-around;
}

input[type="file"] {
  display: none;
}

.marked {
  cursor: pointer;
}

.box {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding: 50px;
  max-height: auto;
  width: 100%;
}

.v-input ::v-deep .v-input__slot {
  width: 100%;
  min-height: 250px;
  max-height: 350px;
  border: dashed 1px $lightGrayColor;
  border-radius: 15px;
  justify-content: space-around;
}

.v-input.error--text ::v-deep .v-input__slot {
  border-radius: 15px;
  border: dashed 2px $dangerColor;
}

.v-input ::v-deep .v-messages__message {
  color: $dangerColor;
  font-family: "Roboto", sans-serif;
}
</style>
