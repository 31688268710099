<template>
  <JazzListPage
    :loading="loadingFilters"
    :disableSearchButton="loadingFilters || loadingTable"
    :fields="fields"
    title="Colaboradores"
    @search="searchCollaborators"
  >
    <div class="buttons-row" v-if="!loadingTable && collaborators.length > 0">
      <v-col cols="4">
        <TableLabelButton
          data-cy="report-collaborators-button"
          title="Download da Lista de Colaboradores"
          label="Baixar resultados"
          @click.native="downloadReport()"
          icon="fas fa-file-download fa-lg"
          textTransform="capitalize"
          buttonColor="primary"
          iconColor="white"
          size="large"
          weight="bold"
        />
      </v-col>
    </div>
    <JazzTable
      @inputNewPage="searchCollaborators(null, $event)"
      :loading="loadingTable"
      :pagination="pagination"
      :rows="collaborators"
    >
      <template slot="reportRow" slot-scope="props">
        <CollaboratorsRow
          data-cy="collaborators-row"
          :params-item="props.paramsItem"
          @click.native="
            openCollaborator(
              props.paramsItem.identificacaoFiscal,
              props.paramsItem.chaveDeCliente
            )
          "
        />
      </template>
    </JazzTable>
  </JazzListPage>
</template>

<script>
import { mapMutations } from "vuex";
import globalMethods from "../../../mixins/globalMethods";
import JazzListPage from "../../../components/template/JazzListPage";
import statusCadastroClienteEmpresa from "../../../dbjson/statusCadastroClienteEmpresa.json";
import JazzTable from "../../../components/table/JazzTable";
import CollaboratorsRow from "./components/rowsTable/CollaboratorsRow";
import TableLabelButton from "../../../components/button/TableLabelButton";
import { TesourariasService } from "../../../services/api";
import { ClientesBackofficeService } from "../../../services/apibackoffice";

export default {
  name: "CollaboratorsIndex",
  components: {
    JazzListPage,
    JazzTable,
    CollaboratorsRow,
    TableLabelButton,
  },
  mixins: [globalMethods],
  data() {
    return {
      loadingFilters: true,
      loadingTable: false,
      fields: {},
      pagination: {},
      collaborators: [],
      tesouraryAccounts: [],
    };
  },
  mounted() {
    this.createFilters();
  },
  methods: {
    ...mapMutations(["CHANGE_LOADING"]),
    async createFilters() {
      const params = this.$route.params;
      if (params?.collaborators) {
        this.filters = params.filters;
        this.pagination = params.pagination;
        this.collaborators = params.collaborators;
        this.tesouraryAccounts = params.tesouraryAccounts;
      } else {
        const params = {
          body: {
            tipoOrdenacao: 1,
          },
        };
        this.tesouraryAccounts =
          await TesourariasService.consultarContaTesouraria(params).then(
            (contas) => contas?.result.items || []
          );
      }
      this.fields = {
        nameTextField: {
          field: "TextField",
          type: "text",
          label: "Nome do funcionário",
          placeholder: "Buscar colaborador pelo nome",
          prependInnerIcon: "fas fa-search",
          cols: 8,
          valueField: this.filters?.nome,
        },
        identificationTextFieldMask: {
          field: "TextFieldMask",
          type: "text",
          label: "CPF/CNPJ",
          cols: 4,
          maskFormat: ["###.###.###-##", "##.###.###/####-##"],
          valueField: this.filters?.identificacaoFiscal,
        },
        companySelectField: {
          label: "Empresa",
          field: "SelectField",
          cols: 4,
          items: [
            { organizationUnitId: 0, nome: "Selecione" },
            ...this.tesouraryAccounts,
          ],
          keyName: "nome",
          keyValue: "organizationUnitId",
          valueField: this.findObjectFromArray(
            this.tesouraryAccounts,
            "organizationUnitId",
            this.filters?.organizationUnitId
          ),
          rules: [
            (fields) =>
              !!fields.companySelectField.valueField?.organizationUnitId ||
              "O campo Empresa é obrigatório",
          ],
        },
        statusSelectField: {
          label: "Status cadastro",
          field: "SelectField",
          cols: 4,
          items: [
            { value: "", key: "Selecione" },
            ...statusCadastroClienteEmpresa,
          ],
          keyName: "key",
          value: "value",
          valueField: this.findObjectFromArray(
            statusCadastroClienteEmpresa,
            "key",
            this.filters?.statusCadastroClienteEmpresa
          ),
        },
      };
      if (this.tesouraryAccounts.length === 1) {
        this.fields.companySelectField.valueField = this.tesouraryAccounts[0];
      }
      this.loadingFilters = false;
    },
    async searchCollaborators(fields = null, page = 1) {
      if ((fields && fields?.isValid) || !fields) {
        this.loadingTable = true;
        if (fields) {
          const {
            nameTextField,
            identificationTextFieldMask,
            companySelectField,
            statusSelectField,
          } = fields.values;
          this.filters = {
            nome: nameTextField,
            identificacaoFiscal: this.removeMaskCharacters(
              identificationTextFieldMask
            ),
            organizationUnitId: this.convertArrayObjectFilter(
              companySelectField,
              "organizationUnitId"
            ),
            statusCadastroCliente: this.convertArrayObjectFilter(
              statusSelectField,
              "value"
            ),
          };
        }
        const result =
          await ClientesBackofficeService.listarClientesGeraisBackoffice({
            body: {
              ...this.filters,
              porPagina: 45,
              pagina: page,
            },
          });
        this.collaborators = result?.items;
        this.pagination.page = result?.pagina;
        this.pagination.perPage = result?.qtdPorPagina;
        this.pagination.total = result?.totalItems;
        this.pagination.pages = result?.qtdPaginas;
        this.loadingTable = false;
      }
    },
    async downloadReport() {
      this.CHANGE_LOADING(true);
      await ClientesBackofficeService.gerarRelatorioColaboradores({
        body: this.filters,
      })
        .then((response) => {
          window.open(response.urlRelatorio);
          this.$store.dispatch(
            "action_changeMessageSnackBar",
            "Arquivo baixado com sucesso!",
            { module: "template" }
          );
        })
        .finally(() => {
          this.CHANGE_LOADING(false);
        });
    },
    openCollaborator(identificacaoFiscal, chaveDeCliente) {
      this.$router.push({
        name: "CollaboratorInformation",
        params: {
          tipo: identificacaoFiscal.length === 14 ? "pj" : undefined,
          chaveDeCliente: chaveDeCliente,
          filters: this.filters,
          pagination: this.pagination,
          collaborators: this.collaborators,
          tesouraryAccounts: this.tesouraryAccounts,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.buttons-row {
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
  margin-bottom: 15px;
}
</style>
