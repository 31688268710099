<template>
  <v-col :cols="cols">
    <div class="label-box">
      <label :class="{ darkStyle: darkStyle }">{{ label }}</label>
    </div>
    <div class="container-input">
      <v-textarea
        :data-cy="dataCy"
        rows="2"
        :no-resize="true"
        autocomplete="new-password"
        :disabled="disabled"
        :class="{
          'has-rules': rules,
          borderSuccess: borderSuccess,
          borderDanger: borderDanger,
          darkStyle: darkStyle,
          disabledField: disabled,
        }"
        :rules="rules ? rules : []"
        v-model="text"
        :placeholder="placeholder"
        class="text-field"
        @input="$emit('update:value', text)"
        @keyup.enter="$emit('onEnter', text)"
        outlined
        :maxlength="maxLength"
        :counter="maxLength"
      >
      </v-textarea>
    </div>
  </v-col>
</template>

<script>
export default {
  name: "TextAreaField",
  props: [
    "dataCy",
    "label",
    "placeholder",
    "cols",
    "rules",
    "clearValue",
    "valueField",
    "disabled",
    "buttonColor",
    "borderSuccess",
    "borderDanger",
    "darkStyle",
    "maxLength",
  ],
  data() {
    return {
      text: "",
    };
  },
  watch: {
    clearValue() {
      this.text = "";
    },
    valueField() {
      this.text = this.valueField;
      this.$emit("update:value", this.text);
    },
  },
  mounted() {
    if (this.valueField) {
      this.text = this.valueField;
      this.$emit("update:value", this.text);
    }
  },
};
</script>

<style scoped>
label {
  color: var(--grayColor) !important;
  font-family: "Roboto", sans-serif;
  font-size: 12px !important;
}

label.darkStyle {
  font-size: 14px !important;
  color: #ffffff !important;
  font-family: "Roboto Light", sans-serif !important;
}

.label-box {
  margin-bottom: 5px;
}

.v-input >>> textarea {
  color: var(--blackColor) !important;
  font-family: "Roboto Medium", sans-serif;
  font-size: 14px;
}

.v-input >>> fieldset {
  color: transparent !important;
}

.v-input >>> .v-text-field__details {
  display: none;
}

.v-input.error--text >>> .v-text-field__details {
  display: block;
}

/*.v-input.has-rules >>> .v-text-field__details{*/
/*    display: block;*/
/*}*/

.v-input >>> .v-messages__message {
  color: var(--dangerColor);
  font-family: "Roboto", sans-serif;
}

.v-input.error--text >>> .v-input__slot {
  border: solid var(--dangerColor) 1px !important;
}

.v-input >>> .v-input__slot {
  background: #ffffff !important;
  border-radius: 10px;
  border: 1px solid var(--fieldBox) !important;
  min-height: 48px !important;
}

.v-input.borderSuccess >>> .v-input__slot {
  border: solid var(--successColor) 2px !important;
}

.v-input.borderDanger >>> .v-input__slot {
  border: solid var(--dangerColor) 2px !important;
}

.v-input >>> i {
  color: var(--lightGrayColor);
  font-size: 18px;
}

.v-input >>> .v-input__prepend-inner {
  margin: 10px 5px 0 0;
}

.v-input >>> input::placeholder {
  color: var(--lightGrayColor) !important;
  font-size: 14px;
  font-family: "Roboto", sans-serif !important;
}

.v-input >>> textarea {
  color: var(--blackColor) !important;
  caret-color: var(--blackColor) !important;
  font-family: "Roboto Black", sans-serif !important;
}

.v-input >>> textarea:focus {
  color: var(--blackColor) !important;
}

.v-input--is-focused >>> i {
  color: var(--blackColor) !important;
}

.container-input {
  display: flex;
}

.v-input.withButton >>> .v-input__slot {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

button {
  border: 0;
  height: 48px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  padding: 0 20px;
  font-size: 14px;
  font-weight: bold;  color: #ffffff;
}

.v-input.disabledField >>> .v-input__slot {
  background: var(--disabledColor) !important;
  font-style: italic;
}

.v-input.disabledField >>> textarea {
  color: var(--grayColor) !important;
  font-family: "Roboto Medium", sans-serif !important;
}
</style>
