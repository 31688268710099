var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"modification-history-row"},[_c('JazzExpansiveRow',{attrs:{"data-cy":"data-responsavel"},scopedSlots:_vm._u([{key:"Header",fn:function(){return [_c('div',{staticClass:"modification-history-row"},[_c('v-col',{attrs:{"cols":"4"}},[_c('JazzTextGroup',{attrs:{"label":"Data da Modificação","text":_vm.dateTimeFormat(_vm.paramsItem.data) || '--/--/--'}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('JazzTextGroup',{attrs:{"label":"Responsável","text":_vm.paramsItem.responsavel || '-'}})],1),_c('v-col',{attrs:{"cols":"5"}},[_c('JazzTextGroup',{attrs:{"label":"Campos Alterados","text":_vm.formatChangedFields(_vm.paramsItem.alteracoes) || '-'}})],1)],1)]},proxy:true},{key:"Content",fn:function(){return [(!_vm.paramsItem.motivo == '' || !_vm.paramsItem.motivo == null)?_c('div',{staticClass:"modification-history-row"},[_c('v-col',{attrs:{"cols":"12"}},[_c('JazzTextGroup',{attrs:{"label":"Observação","text":_vm.paramsItem.motivo}})],1)],1):_vm._e(),_vm._l((_vm.paramsItem.alteracoes),function(change,index){return _c('div',{key:index},[_c('div',{staticClass:"modification-history-row"},[_c('v-col',{attrs:{"cols":"4"}},[_c('JazzTextGroup',{attrs:{"label":"Campo Alterado","text":_vm.fieldName(change.campo) || '-'}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('JazzTextGroup',{attrs:{"label":_vm.fieldName(change.campo) +
                  (change.campo === 'LastModificationTime' ||
                  change.campo === 'Senha'
                    ? ' Antiga'
                    : ' Antigo') || '-',"text":_vm.changedValue(change.campo, change.valorAntigo) || '-',"textColor":"error"}})],1),_c('v-col',{attrs:{"cols":"5"}},[_c('JazzTextGroup',{attrs:{"label":_vm.fieldName(change.campo) +
                  (change.campo === 'LastModificationTime' ||
                  change.campo === 'Senha'
                    ? ' Nova'
                    : ' Novo') || '-',"text":_vm.changedValue(change.campo, change.valorNovo) || '-',"textColor":"primary"}})],1)],1)])})]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }