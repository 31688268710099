<template>
  <div class="collaborators-row">
    <div class="first-column large">
      <v-icon>
        {{ `fas ${collaboratorIcon}` }}
      </v-icon>
      <div class="ml-5">
        <JazzText
          textTransform="capitalize"
          color="black"
          size="description"
          weight="bold"
          >{{ paramsItem.nome || "Nome não definido" }}</JazzText
        >
        <JazzText color="grey" size="label">{{ identificationType }}</JazzText>
      </div>
    </div>
    <div class="medium">
      <JazzTextGroup
        label="Empresa"
        :text="paramsItem.organizationUnitDescricao"
      />
    </div>
    <div class="medium">
      <JazzText color="grey" size="label"> Status Cadastro </JazzText>
      <div class="status-register">
        <v-icon :size="8" :color="statusRegister.color || 'black'">
          fas fa-circle
        </v-icon>
        <JazzText weight="bold" size="description">
          {{ statusRegister.text || paramsItem.statusClientDescricao }}
        </JazzText>
      </div>
    </div>
  </div>
</template>

<script>
import globalMethods from "../../../../../mixins/globalMethods";
import JazzText from "../../../../../components/typography/JazzText";
import JazzTextGroup from "../../../../../components/typography/JazzTextGroup";

export default {
  name: "CollaboratorsRow",
  components: { JazzText, JazzTextGroup },
  mixins: [globalMethods],
  props: {
    paramsItem: Object,
  },
  computed: {
    identificationType() {
      const { identificacaoFiscal } = this.paramsItem;
      if (identificacaoFiscal?.length === 14) {
        return `CNPJ: ${this.cnpjMask(identificacaoFiscal)}`;
      }
      return `CPF: ${this.cpfMask(identificacaoFiscal)}`;
    },
    collaboratorIcon() {
      return this.paramsItem?.identificacaoFiscal?.length === 14
        ? "fa-store"
        : "fa-user";
    },
    statusRegister() {
      const { status } = this.paramsItem;
      const statusList = {
        PreCadastro: { text: "Pré-Cadastro", color: "black" },
        CadastroCompleto: { text: "Cadastro aprovado", color: "green" },
        KYCAnalise: { text: "Cadastro em análise", color: "orange" },
        KYCNegado: { text: "Cadastro negado", color: "error" },
        CAFAnalise: { text: "Cadastro em análise", color: "orange" },
        CAFNegado: { text: "Necessário nova tentativa", color: "yellow" },
        AguardandoAssinatura: {
          text: "Aguardando Assinatura ",
          color: "black",
        },
        AguardandoSolicitacaoLink: {
          text: "Aguardando Solicitação Link ",
          color: "black",
        },
        AguardandoConfirmacaoDocumentos: {
          text: "Aguardando Confirmação Documentos ",
          color: "black",
        },
        Cancelado: { text: "Cancelado", color: "error" },
        AguardandoConfirmacaoEmail: {
          text: "Aguardando Confirmação Email ",
          color: "black",
        },
      };
      return statusList[status] || { text: null, color: null };
    },
  },
};
</script>

<style scoped lang="scss">
.collaborators-row {
  width: 100%;
  padding: 0 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  align-content: stretch;
  min-height: 70px;
  max-height: auto;
  @media only screen and (max-width: 1160px) {
    padding: 10px;
  }
}

.collaborators-row:hover {
  background-color: $grayContrast;
  cursor: pointer;
}

.first-column {
  display: flex;
  align-items: center;
  width: 40%;
  text-transform: capitalize !important;
}

.first-column i {
  font-size: 25px;
  margin-right: 10px;
  color: $primaryColor;
}
.status-register .v-icon {
  font-size: 8px;
  margin-right: 10px;
}

.status-register {
  margin: 4px 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.medium {
  width: 30%;
}

.large {
  width: 40%;
}
</style>
