<template>
  <div class="client-blocking" v-if="client && client.detalhesCancelamento">
    <FormFieldsHeader title="Detalhes Cancelamento da Conta" color="error" />
    <div class="row-infos">
      <div class="info-text">
        <JazzTextGroup
          label="Data do Cancelamento"
          :text="dateTimeFormat(client.detalhesCancelamento.dataCancelamento)"
        ></JazzTextGroup>
      </div>
      <div class="info-text">
        <JazzTextGroup
          label="Motivo do Cancelamento"
          :text="client.detalhesCancelamento.motivoCancelamento"
        >
        </JazzTextGroup>
      </div>
      <div class="info-text">
        <JazzTextGroup
          label="Usuário Cancelamento"
          :text="client.detalhesCancelamento.usuarioCancelamento"
        >
        </JazzTextGroup>
      </div>
    </div>
  </div>
</template>

<script>
import JazzTextGroup from "../../../../components/typography/JazzTextGroup";
import FormFieldsHeader from "../../../../components/FormFieldsHeader";
import globalMethods from "../../../../mixins/globalMethods";
export default {
  name: "CustomerCancellation",
  mixins: [globalMethods],
  components: { FormFieldsHeader, JazzTextGroup },
  props: {
    client: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped>
.row-infos {
  display: flex;
  justify-content: center;
  flex-flow: row wrap;
}

.info-text {
  margin: 0 10px;
  flex-grow: 2;
  font-size: 14px;
}

.client-blocking {
  margin-bottom: 30px;
}
</style>
