<template>
  <div class="loading">
    <v-progress-circular
      :size="30"
      color="$secondaryColor"
      indeterminate
    ></v-progress-circular>
  </div>
</template>

<script>
export default {
  name: "DefaultLoading",
};
</script>

<style scoped>
.loading {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px 0;
}
</style>
