import store from "../store";
import Vue from "vue";
import VueRouter from "vue-router";
import Template from "../views/Template";
import authRouter from "./auth";
import dashboardRouter from "./dashboard";
import travelCardsRouter from "./Travel/cards";
import clientsRouter from "./Travel/clients";
import invoicesRouter from "./Travel/invoices";
import transactionsRouter from "./Travel/transactions";
import passwordUpdateRouter from "./PasswordUpdate/passwordUpdate";
import customerBenefitLoadRouter from "./Benefit/customerBenefitLoad";
import customerBenefitRegistrationRouter from "./Benefit/customerBenefitRegistration";
import collaboratorsRouter from "./Benefit/collaborators";
import communicationRouter from "./Benefit/communication";
import previousRegistrationRouter from "./Clients/previousRegistration";
import clientRouter from "./Clients/client";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: { name: "Dashboard" },
    name: "Template",
    component: Template,
    meta: {
      permission: [],
      permissionName: "",
    },
    children: [
      ...dashboardRouter,
      ...travelCardsRouter,
      ...clientsRouter,
      ...invoicesRouter,
      ...transactionsRouter,
      ...passwordUpdateRouter,
      ...customerBenefitLoadRouter,
      ...customerBenefitRegistrationRouter,
      ...collaboratorsRouter,
      ...communicationRouter,
      ...previousRegistrationRouter,
      ...clientRouter,
    ],
  },
  ...authRouter,
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior: () => ({ y: 0 }),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.name !== "Login") {
    if (
      localStorage.getItem("access_token") &&
      localStorage.getItem("access_token") !== "undefined" &&
      localStorage.getItem("access_token") !== ""
    ) {
      if (to.meta && to.meta.permission && hasPermission(to.meta.permission)) {
        next();
      } else {
        next();
      }
    } else {
      next("/login");
    }
  } else {
    next();
  }
});

function hasPermission(requiredPermissions) {
  let n = requiredPermissions.length;
  requiredPermissions.forEach((perm) => {
    if (store.state.auth.user.permissions.includes(perm)) {
      n--;
    }
  });
  return n === 0;
}

export default router;
