<template>
    <div class="history-changes-row">
      <div>
        <JazzText weight="bold" size="description">
          {{ paramsItem.responsavel || "-" }}
        </JazzText>
      </div>
      <div>
        <JazzText size="description">
          {{ paramsItem.data ? dateTimeFormat(paramsItem.data) : "--/--/---" }}
        </JazzText>
      </div>
      <div>
        <JazzText size="description">
          {{ eventType || "Alteração" }}
        </JazzText>
      </div>
      <div>
        <JazzText
          v-for="(change, index) in formatChangedFields(
            eventType === 'Criação' ? [] : paramsItem.alteracoes
          )"
          :key="index"
          size="description"
        >
          {{ change }}
        </JazzText>
      </div>
    </div>
  </template>
  
  <script>
  import globalMethods from "../../../../../mixins/globalMethods";
  import JazzText from "../../../../../components/typography/JazzText";
  
  export default {
    name: "HistoryChangesRow",
    components: { JazzText },
    mixins: [globalMethods],
    props: {
      paramsItem: Object,
    },
    data() {
      return {
        eventType: null,
      };
    },
    methods: {
      formatChangedFields(changes) {
        let changedFields = [];
  
        if (changes.length > 0) {
          changes.forEach((change) => {
            if (change.campo === "IsDeleted") {
              let oldValue = change.valorAntigo;
              let newValue = change.valorNovo;
              if (oldValue === "" && newValue != "") {
                this.eventType = "Criação";
              } else if (oldValue === "" && newValue === "") {
                this.eventType = "Exclusão";
              }
            } else {
              changedFields.push(this.fieldName(change.campo));
            }
          });
          return changedFields;
        } else {
          return '-';
        }
      },
      fieldName(field) {
        const namesList = {
          InicioVigencia: "Período Inicial",
          FimVigencia: "Período Final",
          Mensagem: "Título",
          OrganizationUnitId: "Parceiro",
        };
        return namesList[field] || field;
      },
    },
  };
  </script>
  
  <style lang="scss" scoped>
  .history-changes-row {
    width: 100%;
    display: flex;
    flex-direction: row;
    height: auto;
    justify-content: space-between;
    padding: 15px 0 15px 10px;
    border-bottom: 1px solid $lightGrayBorderColor;
  }
  
  .history-changes-row div {
    width: 25%;
  }
  </style>
  