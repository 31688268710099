import { ClientesService, TesourariasService } from "../../services/api";

export const pRegistrationStore = {
  state: () => ({
    imports: [],
    filters: {
      organizationUnitId: null,
      status: null,
      inicio: null,
      fim: null,
      pagina: null,
      porPagina: null,
    },
    loadingHistory: false,
    pagination: {
      page: 1,
      perPage: 15,
      total: 0,
      pages: 0,
    },
  }),
  mutations: {
    SET_ALL_IMPORTS(state, payload) {
      state.imports = payload;
    },
    SET_PREGISTRATION_FILTER(state, payload) {
      state.filters = payload;
    },
    TOGGLE_HISTORY_LOADING(state) {
      state.loadingHistory = !state.loadingHistory;
    },
    SET_PAGINATION(state, payload) {
      state.pagination = payload;
    },
  },
  actions: {
    async action_importClients(context, payload) {
      return await ClientesService.importarClientes({ body: payload })
        .then((response) => {
          if (response)
            context.dispatch(
              "action_changeMessageSnackBar",
              "Arquivo enviado com sucesso!",
              { module: "template" }
            );
          return response;
        })
        .catch((err) => err);
    },
    async action_massiveCreditForBenefits(context, payload) {
      return await TesourariasService.creditoMassivoPorMotivo({ body: payload })
        .then((response) => {
          if (response)
            context.dispatch(
              "action_changeMessageSnackBar",
              "Arquivo enviado com sucesso!",
              { module: "template" }
            );
          return response;
        })
        .catch((err) => err);
    },
    async action_importClientsBenefits(context, payload) {
      return await ClientesService.importarClientesBeneficioSimplificada({
        body: payload,
      })
        .then((response) => {
          if (response)
            context.dispatch(
              "action_changeMessageSnackBar",
              "Arquivo enviado com sucesso!",
              { module: "template" }
            );
          return response;
        })
        .catch((err) => err);
    },
    async action_getAllImports(context, payload) {
      context.commit("TOGGLE_HISTORY_LOADING");
      context.commit("SET_ALL_IMPORTS", []);
      return await ClientesService.listarImportacoesClientes({ body: payload })
        .then((response) => {
          context.commit("SET_ALL_IMPORTS", response.result.items);
          context.commit("SET_PAGINATION", {
            page: response.result.pagina,
            perPage: response.result.qtdPorPagina,
            total: response.result.totalItems,
            pages: response.result.qtdPaginas,
          });
          context.commit("TOGGLE_HISTORY_LOADING");
          return response.result;
        })
        .catch((err) => err);
    },
    async action_listMassiveLoads(context, payload) {
      context.commit("TOGGLE_HISTORY_LOADING");
      context.commit("SET_ALL_IMPORTS", []);
      return await TesourariasService.listarCargasMassivas({ body: payload })
        .then((response) => {
          context.commit("SET_ALL_IMPORTS", response.result.items);
          context.commit("SET_PAGINATION", {
            page: response.result.pagina,
            perPage: response.result.qtdPorPagina,
            total: response.result.totalItems,
            pages: response.result.qtdPaginas,
          });
          context.commit("TOGGLE_HISTORY_LOADING");
          return response.result;
        })
        .catch((err) => err);
    },
    async action_getInformationBenefitSimplifieds(context, payload) {
      context.commit("TOGGLE_HISTORY_LOADING");
      context.commit("SET_ALL_IMPORTS", []);
      return await ClientesService.listarImportacoesClientesBeneficioSimplificada(
        { body: payload }
      )
        .then((response) => {
          context.commit("SET_ALL_IMPORTS", response.result.items);
          context.commit("SET_PAGINATION", {
            page: response.result.pagina,
            perPage: response.result.qtdPorPagina,
            total: response.result.totalItems,
            pages: response.result.qtdPaginas,
          });
          context.commit("TOGGLE_HISTORY_LOADING");
          return response.result;
        })
        .catch((err) => err);
    },
    async action_getImportReturn(context, payload) {
      return await ClientesService.baixarRetornoImportacao({ body: payload })
        .then((response) => {
          return response;
        })
        .catch((err) => err);
    },
    async action_getImportReturnBenefitSimplifieds(context, payload) {
      return await ClientesService.baixarRetornoImportacaoBeneficioSimplificada(
        { body: payload }
      )
        .then((response) => {
          return response;
        })
        .catch((err) => err);
    },
    async action_getImportReturnMassiveLoads(context, payload) {
      return await TesourariasService.baixarRetornoCargaMassiva({
        body: payload,
      })
        .then((response) => {
          return response;
        })
        .catch((err) => err);
    },
  },
  getters: {},
};

export default pRegistrationStore;
