<template>
  <div class="box">
    <div class="size-row mb-2">
      <v-icon class="mr-2" color="black" size="20">
        fas fa-exclamation-circle</v-icon
      >
      <JazzText weight="bold" size="description"> {{ title }} </JazzText>
    </div>
    <div v-if="!withSlot">
      <ul class="ml-5" v-for="(item, index) in content" :key="index">
        <li>
          <JazzText weight="bold" size="description"> {{ item }} </JazzText>
        </li>
      </ul>
    </div>
    <div v-else class="size-row">
      <JazzText size="description">
        <slot />
      </JazzText>
    </div>
  </div>
</template>

<script>
import JazzText from "../typography/JazzText";

export default {
  name: "DefaultTooltip",
  components: { JazzText },
  props: {
    title: { type: String, default: "Instruções" },
    content: {
      type: Array,
      default: () => [
        "Em algumas versões do Excel, o arquivo salvo em CSV pode desconfigurar. Para ajustar, abra o arquivo usando o bloco de notas e salve novamente.",
        "Limite de 10.000 linhas por arquivo ou 10mb.",
      ],
    },
    withSlot: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.box {
  background-color: $lightGrayBorderColor;
  border-radius: 15px;
  padding: 30px;
}
.size-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}
</style>
