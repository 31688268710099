<template>
  <div class="image-box">
    <img :src="url" :height="imageSize.height" :width="imageSize.width" alt="preview"/>
    <div :class="`image-text ${size}`">
      <JazzText weight="bold" :size="textSize" color="white" textAlign="left">
        {{ imageText }}
      </JazzText>
    </div>
  </div>
</template>

<script>
import globalMethods from "../../mixins/globalMethods";
import JazzText from "../typography/JazzText";

export default {
  name: "JazzImagePreview",
  mixins: [globalMethods],
  components: { JazzText },
  props: {
    imageText: { type: String },
    url: { type: String },
    size: {
      type: String,
      validator: (propsSize) =>
        ["small", "medium", "large"].includes(propsSize),
      default: "small",
    },
  },
  computed: {
    imageSize() {
      const sizeList = {
        medium: { height: "250px", width: "250px" },
        large: { height: "350px", width: "350px" },
      };
      return sizeList[this.size] || { height: "150px", width: "150px" };
    },
    textSize() {
      const textSizeList = {
        medium: "big",
        large: "big",
      };
      return textSizeList[this.size] || "title";
    },
  },
};
</script>

<style lang="scss" scoped>
.image-box {
  float: center;
  position: relative;
}

.image-box img {
  border-radius: 25px;
}

.image-text {
  position: absolute;
  top: 0;
  left: 0;
  overflow-wrap: break-word;
  overflow-y: hidden;
  overflow-x: hidden;
  padding: 15px;
  display: flex;
  align-items: center;
}

.small {
  width: 150px;
  height: 150px;
  max-width: 150px;
  max-height: 150px;
}

.medium {
  width: 250px;
  height: 250px;
  max-width: 250px;
  max-height: 250px;
}

.large {
  width: 350px;
  height: 350px;
  max-width: 350px;
  max-height: 350px;
}
</style>
