<template>
  <div
    :title="title"
    :class="[
      !disable ? 'tableButton' : '',
      disable ? 'disableTableButton' : '',
      buttonColor,
      size,
    ]"
  >
    <v-icon
      :size="size === 'large' ? 14 : 12"
      :color="`var(--${iconColor}Color)`"
    >
      {{ icon }}
    </v-icon>
    <p :class="[textTransform, textDecoration, textWeight]">{{ label }}</p>
  </div>
</template>

<script>
export default {
  name: "TableLabelButton",
  props: {
    title: {},
    icon: {},
    label: {},
    disable: {
      default: false,
    },
    textTransform: {
      type: String,
      default: "uppercase",
      validator: (propsTextTransform) =>
        ["uppercase", "capitalize"].includes(propsTextTransform),
    },
    textDecoration: {
      type: String,
      default: "none",
      validator: (propsTextDecoration) =>
        ["none", "underline"].includes(propsTextDecoration),
    },
    textWeight: {
      type: String,
      default: "",
      validator: (propsTextWeight) => ["", "bold"].includes(propsTextWeight),
    },
    iconColor: {
      type: String,
      default: "black",
      validator: (propsIconColor) =>
        ["black", "primary", "white"].includes(propsIconColor),
    },
    buttonColor: {
      type: String,
      default: "",
      validator: (propsButtonColor) =>
        ["", "primary"].includes(propsButtonColor),
    },
    size: {
      type: String,
      default: "",
      validator: (propsButtonSize) =>
        ["", "large", "small"].includes(propsButtonSize),
    },
  },
};
</script>

<style scoped>
.tableButton {
  width: 120%;
  height: 30px;
  background: var(--opacityGrayColor);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 7px;
  font-size: 12px;
  margin: 0 5px;
  color: var(--blackColor);
  border: solid var(--opacityGrayColor) 1px;
}

.uppercase {
  text-transform: uppercase;
}

.capitalize {
  text-transform: capitalize;
}

.none {
  text-decoration: none;
}

.underline {
  text-decoration: underline;
}

.bold {
  font-weight: bold;
}

.disableTableButton {
  width: 120%;
  height: 30px;
  background: #efefef30;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 7px;
  font-size: 12px;
  margin: 0 5px;
  color: #00000030;
  border: solid var(--opacityGrayColor) 1px;
  cursor: default !important;
}

.tableButton p {
  margin: 1px;
  padding-left: 4px;
}

.tableButton:hover {
  cursor: pointer;
  color: var(--blackColor);
}

.disableTableButton p {
  margin: 1px;
  padding-left: 4px;
}

.primary {
  background: var(--primaryColor);
  color: var(--whiteColor) !important;
  border: solid var(--primaryColor) 1px;
}

.large {
  width: 100%;
  height: 46px;
  border-radius: 10px;
  font-size: 14px;
}

.small {
  width: 100%;
  height: 30px;
  border-radius: 10px;
  font-size: 14px;
}
</style>
