<template>
  <button type="button" :class="colorStyle" class="default-button">
    <i v-if="icon" :class="icon" />
    {{ text }}
  </button>
</template>
<script>
export default {
  name: "DefaultButton",
  props: {
    text: String,
    colorStyle: String,
    icon: String,
  },
};
</script>

<style scoped lang="scss">
.default-button {
  padding: 0 30px;
  height: 46px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  background: $primaryColor;
  color: $whiteColor;
  font-weight: bold;
  border: 1px solid $primaryColor;
}

.default-button i {
  margin-right: 15px;
}

.default-button i {
  margin-right: 15px;
}

.default-button:hover {
  background: $primaryColor;
  cursor: pointer;
}

.default-button.secondary-color {
  background: transparent;
  border: 2px solid $primaryColor;
  color: $primaryColor;
}

.default-button.secondary-color:hover {
  background: $primaryColor;
  border: 2px solid $primaryColor;
  color: $whiteColor;
}

.default-button.gray-green {
  background: $opacityGrayColor;
  border: 2px solid $primaryColor;
  color: $primaryColor;
}

.default-button.gray-green:hover {
  background: $primaryColor;
  border: 2px solid $primaryColor;
  color: $whiteColor;
}

.default-button.success {
  background: $successColor;
  border: 2px solid $successColor;
  color: $whiteColor;
  font-weight: bold;
}

.default-button.danger {
  background: var(--dangerColor) !important;
  border: 2px solid var(--dangerColor) !important;
  color: #ffffff;
  font-weight: bold;
}

.default-button.disabled {
  background: $lightGrayColor;
  border: $lightGrayColor;
  pointer-events: none !important;
  cursor: default !important;
}
.default-button.gray {
  background: $opacityGrayColor;
  border: 2px solid $lightGrayColor;
  color: $lightGrayColor;
  font-weight: bold;
}
.default-button.gray:hover {
  background: $dangerColor;
  border: 2px solid $dangerColor;
  color: $whiteColor;
}

.default-button.intern {
  margin-top: 30px;
  text-align: center;
}

.ultra-radius {
  border-radius: 100px;
}

.reverse {
  flex-direction: row-reverse;
}

.reverse i {
  margin: 0 0 0 15px;
}

.default-button.white-green {
  background: $whiteColor;
  border: 2px solid $primaryColor;
  color: $primaryColor;
}

.default-button.white-green:hover {
  background: $primaryColor;
  border: 2px solid $primaryColor;
  color: $whiteColor;
}

.default-button.large {
  width: 100%;
}
</style>
