<template>
  <div class="not-found-message">
    <p>
      <v-icon color="var(--lightGrayColor)">
        fas fa-bell
      </v-icon>
      {{ message ? message : "Nenhuma informação encontrada." }}
    </p>
  </div>
</template>

<script>
export default {
  name: "NotFoundMessage",
  props: ["message"],
};
</script>

<style scoped>
.not-found-message {
  width: 100%;
  padding: 30px 0;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.not-found-message p {
  color: var(--lightGrayColor);
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.not-found-message p i {
  margin-right: 5px;
  font-size: 16px;
}
</style>
