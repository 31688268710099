<template>
  <div class="w-100">
    <JazzSkeletonLoader class="mt-15" v-if="loading" type="cards" />
    <div v-else>
      <div
        v-if="filteredCommunications.length > 0"
        class="cards-box"
        data-cy="table"
      >
        <div
          v-for="(communication, index) in filteredCommunications"
          :key="index"
        >
          <CardCommunicationColumn
            :paramsItem="communication"
            @edit="$emit('edit', $event)"
            tab="inactive"
          />
        </div>
      </div>
      <JazzNotFoundMessage
        v-else
        with-icon
        :message="'Nenhuma comunicado inativo foi encontrado'"
      />
    </div>
  </div>
</template>

<script>
import globalMethods from "../../../../../mixins/globalMethods";
import CardCommunicationColumn from "../CardCommunicationColumn";
import JazzSkeletonLoader from "../../../../../components/template/JazzSkeletonLoader";
import JazzNotFoundMessage from "../../../../../components/typography/JazzNotFoundMessage";

export default {
  name: "InactiveTab",
  mixins: [globalMethods],
  components: {
    JazzSkeletonLoader,
    CardCommunicationColumn,
    JazzNotFoundMessage,
  },
  props: {
    communications: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      loading: false,
      filteredCommunications: [],
    };
  },
  async mounted() {
    this.loading = true;
    await this.filterCommunications();
    this.loading = false;
  },
  methods: {
    async filterCommunications() {
      if (this.communications?.length > 0) {
        const today = this.dateEnFormat();
        const hour = this.getCurrentTime() + ":00 +0000";
        const now = this.dateISOFormat(
          today + " " + hour,
          "YYYY-MM-DD HH:mm:ss"
        );
        this.filteredCommunications = await this.communications.filter((c) => {
          return (
            !this.compareDateAfter(c.inicioVigencia, now) &&
            !this.compareDateAfter(c.fimVigencia, now)
          );
        });
        this.filteredCommunications.sort((a, b) =>
          a.fimVigencia < b.fimVigencia ? 1 : -1
        );
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.cards-box {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
</style>
