<template>
  <div data-cy="login-div" class="login-box">
    <div class="logo">
      <img :src="imgLogo" alt="Stuo"/>
    </div>
    <DefaultLoading v-if="modalLoading" />
    <ConfirmationModal
      v-if="configModal.show && !modalLoading"
      title="Redefinir Senha de Acesso"
      @confirm="
        configModal.show = false;
        reset = !reset;
      "
      @close="resetSenha()"
      :messages="[configModal.message, configModal.textModal]"
      cancel-text="Reenviar"
      confirm-text="Entendi"
    >
    </ConfirmationModal>
    <div v-show="!configModal.show && !modalLoading">
      <JazzFormFields
        :fields="fields"
        @update:values="
          userName = $event.userName;
          password = $event.password;
          validate++;
        "
        :validate="validate"
        @checkValidity="enableButton"
      >
      </JazzFormFields>
      <ButtonLarge
        data-cy="Acessarconta-button"
        class="btn"
        :style-color="{ disabled: this.isActive === false }"
        @clicked="reset ? resetSenha() : login()"
        :text="textButton"
      />
      <div class="reset-password">
        <a data-cy="forget-password-link" @click="reset = !reset">{{
          textReset
        }}</a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from "vuex";
import globalMethods from "../mixins/globalMethods";
import ButtonLarge from "./button/ButtonLarge";
import JazzFormFields from "../components/form/JazzFormFields";
import ConfirmationModal from "./modal/ConfirmationModal";
import DefaultLoading from "./template/DefaultLoading";
import { UsuariosBackofficeService } from "../services/apibackoffice";

export default {
  name: "LoginBox",
  components: {
    JazzFormFields,
    ButtonLarge,
    ConfirmationModal,
    DefaultLoading,
  },
  mixins: [globalMethods],
  data() {
    return {
      userName: "",
      password: "",
      fields: {},
      validate: 0,
      modalLoading: false,
      isActive: false,
      reset: false,
      configModal: {
        show: false,
        textModal:
          "Caso não tenha recebido, verifique a sua caixa de lixo eletrônico ou clique no botão 'Reenviar'.",
      },
    };
  },
  methods: {
    ...mapMutations(["CHANGE_LOADING"]),
    ...mapActions(["action_setUserAuth", "action_signIn"]),
    login() {
      this.CHANGE_LOADING(true);
      this.action_signIn({
        usuario: this.userName,
        senha: this.password,
      })
        .then((response) => {
          const auth = response.result;
          localStorage.setItem("access_token", auth.accessToken);
          localStorage.setItem("encrypted_token", auth.encryptedAccessToken);
          localStorage.setItem("user_id", auth.userId);
          this.action_setUserAuth({
            usuario: this.userName,
            senha: this.password,
          })
            .then((res) => {
              if (!res) {
                localStorage.clear();
                this.$router.push("/login");
                this.openMessageModal(
                  "danger",
                  "Atenção",
                  "Login não autorizado, usuario sem acesso ao Backoffice."
                );
              } else {
                this.$router.push("/");
              }
            })
            .catch(() => {
              this.CHANGE_LOADING(false);
            });
        })
        .catch(() => {
          this.CHANGE_LOADING(false);
        });
    },
    async resetSenha() {
      this.modalLoading = true;

      await UsuariosBackofficeService.solicitarAlteracaoSenha({
        body: {
          userName: this.userName,
        },
      })
        .then((response) => {
          this.configModal.message = response?.result?.mensagem;
          this.configModal.show = true;
        })
        .catch((e) => e)
        .finally(() => {
          this.modalLoading = false;
        });
    },
    enableButton(e) {
      if (e.isValid) {
        this.isActive = true;
      } else {
        this.isActive = false;
      }
    },
  },
  mounted() {
    this.fields = {
      userName: {
        field: "TextField",
        type: "text",
        label: "Usuário",
        cols: 12,
        darkStyle: true,
        rules: [
          (fields) => !!fields.userName.valueField || "Campo obrigatório",
        ],
      },
      password: {
        field: "TextField",
        type: "password",
        label: "Senha",
        removeInput: false,
        cols: 12,
        darkStyle: true,
        rules: [
          (fields) =>
            (!!fields.password.valueField && !this.reset) ||
            "Campo Obrigatório",
        ],
      },
    };
  },
  computed: {
    ...mapGetters(["imgLogo"]),
    textReset() {
      return this.reset ? "Voltar para o login" : "Esqueceu a senha?";
    },
    textButton() {
      return this.reset ? "Recuperar Senha" : "Acessar conta";
    },
  },
  watch: {
    reset(oldValue, newValue) {
      newValue
        ? (this.fields.password.removeInput = false)
        : (this.fields.password.removeInput = true);
    },
  },
};
</script>

<style scoped lang="scss">
.login-box {
  width: 400px;
  padding: 40px;
  border-radius: 15px;
  background-color: $backgroundColorModal;
  box-shadow: 0 0 24px 0 $lightGrayColor;
}
.logo {
  width: 100%;
  text-align: center;
}
.logo img {
  width: 250px;
  @media only screen and (max-width: 1024px) {
    width: 200px;
  }
}

.login-box h3 {
  font-weight: bold;  
  color: $primaryColor;
  text-align: center;
}

label {
  font-size: 14px;
  color: $primaryColor;
}

.btn {
  margin-top: 7px;
  width: 100% !important;
}

.reset-password {
  display: flex;
  justify-content: center;
  margin-top: 5px;
  font-size: 14px;
}

.reset-password a {
  color: $blackColor;
  text-decoration: underline;
  font-weight: bold;
}
</style>
