<template>
  <div class="w-100">
    <JazzListPage
      :loading="loading"
      :fields="fields"
      :title="'Consultar Faturas'"
      @search="searchInvoices"
      :filterButton="filterButton"
      @changeFilterButton="filterButton = !filterButton"
    >
      <div>
        <JazzTable
          :loading="loadingTable"
          :rows="batchsList"
          :pagination="pagination"
          @inputNewPage="searchInvoices(null, $event)"
        >
          <template slot="reportRow" slot-scope="props">
            <InvoicesRow
              data-cy="customer-invoices"
              :params-item="props.paramsItem"
              @click.native="openInvoices(props.paramsItem)"
            />
          </template>
        </JazzTable>
      </div>
    </JazzListPage>
  </div>
</template>

<script>
import globalMethods from "../../../mixins/globalMethods";
import JazzTable from "../../../components/table/JazzTable";
import InvoicesRow from "./components/rowsTable/InvoicesRow";
import JazzListPage from "../../../components/template/JazzListPage";
import {
  TravelUtilidadesService,
  ClientesTravelService,
  ContratosService,
  LotesService,
} from "../../../services/apitravel";

export default {
  name: "TravelInvoicesIndex",
  mixins: [globalMethods],
  components: {
    JazzTable,
    JazzListPage,
    InvoicesRow,
  },
  data() {
    return {
      pagination: {
        page: 1,
        perPage: 45,
        total: 1,
        pages: 1,
      },
      filters: {
        pagina: 1,
      },
      batchsList: [],
      showTable: false,
      fields: {},
      integratorsList: [],
      clientsList: [],
      filterButton: true,
      organizationUnitId: null,
      loading: false,
      loadingTable: false,
      selectedFilters: {},
      onLoad: false,
      invoicesStatus: [
        { id: 1, descricao: "Em aberto" },
        { id: 2, descricao: "Aguardando Faturamento" },
        { id: 3, descricao: "Aguardando Pagamento" },
        { id: 4, descricao: "Pagos" },
        { id: 5, descricao: "Vencidas" },
      ],
    };
  },
  async mounted() {
    const params = this.$route.params;

    if (params?.batchsList) {
      this.onLoad = true;
      this.pagination = params.pagination;
      this.batchsList = params.batchsList;
      this.selectedFilters = params.selectedFilters;
      this.filters = params.filters;
      this.integratorsList = params.integratorsList;
      this.clientsList = params.clientsList;
      await this.createForms();
      this.onLoad = false;
    } else {
      this.init();
    }
  },
  methods: {
    async populateIntermediates() {
      this.integratorsList =
        await TravelUtilidadesService.listarIntegradoresDisponiveis().then(
          (response) => response?.result?.integradores || []
        );
    },
    async init() {
      this.loading = true;
      await this.populateIntermediates();
      this.createForms();
      this.loading = false;
    },
    async changeIntegrador(form) {
      const { integrador, cliente } = form;
      const intermediary = integrador?.valueField?.organizationUnitId;
      if (intermediary) {
        integrador.loading = true;
        cliente.loading = true;
        const clients = await this.getClients(intermediary);
        cliente.items = [
          { chaveDeCliente: null, razaoSocial: "Selecione" },
          ...clients,
        ];
        cliente.valueField = clients.length === 1 ? clients[0] : null;
        integrador.loading = false;
        cliente.loading = false;
        cliente.disabled = false;
      } else {
        cliente.items = [{ chaveDeCliente: null, razaoSocial: "Selecione" }];
        cliente.valueField = {
          chaveDeCliente: null,
          razaoSocial: "Selecione",
        };
        cliente.loading = false;
        cliente.disabled = true;
      }
    },
    async getClients(uo) {
      return ClientesTravelService.filtrar({
        body: { organizationUnitId: uo },
      })
        .then((response) => (this.clientsList = response?.result?.items))
        .catch((e) => e);
    },
    createForms() {
      this.fields = {
        integrador: {
          field: "JazzAutoCompleteField",
          label: "Integrador",
          cols: 4,
          items: [
            { organizationUnitId: null, nome: "Selecione" },
            ...this.integratorsList,
          ],
          valueField: this.findObjectFromArray(
            this.integratorsList,
            "organizationUnitId",
            this.selectedFilters?.integrador
          ),
          changeValue: (fields) => this.onLoad || this.changeIntegrador(fields),
          keyName: "nome",
          keyValue: "organizationUnitId",
          rules: [
            (fields) =>
              !!fields.integrador.valueField.organizationUnitId ||
              "O campo Integrador é obrigatório",
          ],
        },
        cliente: {
          field: "JazzAutoCompleteField",
          label: "Cliente",
          cols: 4,
          valueField: this.findObjectFromArray(
            this.clientsList,
            "chaveDeCliente",
            this.selectedFilters?.cliente
          ),
          items: [
            { chaveDeCliente: null, razaoSocial: "Selecione" },
            ...this.clientsList,
          ],
          keyName: "razaoSocial",
          keyValue: "chaveDeCliente",
          disabled: this.onLoad ? false : true,
          rules: [
            (fields) =>
              !!fields.cliente.valueField.chaveDeCliente ||
              "O campo Cliente é obrigatório",
          ],
        },
        statusFatura: {
          field: "SelectField",
          label: "Status da Fatura ",
          cols: 4,
          items: [{ id: null, descricao: "Selecione" }, ...this.invoicesStatus],
          valueField: this.findObjectFromArray(
            this.invoicesStatus,
            "id",
            this.selectedFilters?.statusFatura
          ),
          keyName: "descricao",
          keyValue: "id",
        },
        dataInicio: {
          field: "DateField",
          label: "Data Inicial",
          cols: 5,
          valueField: this.dateSubtract30Days(this.selectedFilters?.dataInicio),
        },
        dataFim: {
          field: "DateField",
          label: "Data Final",
          cols: 5,
          valueField: this.dateEnFormat(this.selectedFilters?.dataFim),
        },
      };

      if (this.integratorsList?.length === 1) {
        this.fields.integrador.valueField = this.integratorsList[0];
      }
    },

    async searchInvoices(data = null, page = 1) {
      if (data?.isValid || this.filters?.chaveDeCliente) {
        this.loadingTable = true;

        if (data?.values) {
          const { integrador, cliente, statusFatura, dataInicio, dataFim } =
            data?.values;

          this.selectedFilters = {
            integrador: integrador?.organizationUnitId.toString(),
            cliente: cliente?.chaveDeCliente,
            status: statusFatura?.id,
            dataInicio: this.dateISOFormat(
              dataInicio + " 00:00:00 +0000",
              "YYYY-MM-DD HH:mm:ss Z"
            ),
            dataFim: this.dateISOFormat(
              dataFim + " 23:59:59 +0000",
              "YYYY-MM-DD HH:mm:ss Z"
            ),
          };
          this.filters = {
            chaveDeCliente: cliente?.chaveDeCliente,
            ativo: true,
          };
        }

        ContratosService.consultarPorCliente({
          body: {
            ...this.filters,
          },
        })
          .then((response) => {
            this.getBatchInformation(response.result.items[0], page)
              .then(() => {
                if (this.batchsList.length > 0) {
                  this.filterButton = false;
                }
                this.loadingTable = false;
              });
          })
          .catch(() => {
            this.loadingTable = false;
          });
      }
    },
    getBatchInformation(activeContract, page) {
      return LotesService.consultar({
        body: {
          chaveDeContrato: activeContract?.chaveDeContrato,
          chaveDeCliente: activeContract?.chaveDeCliente,
          statusLote: this.fields.statusFatura.valueField.id,
          dataInicial: this.dateISOFormat(
            this.fields?.dataInicio?.valueField + " 00:00:00 +0000",
            "YYYY-MM-DD HH:mm:ss Z"
          ),
          dataFinal: this.dateISOFormat(
            this.fields?.dataFim?.valueField + " 23:59:59 +0000",
            "YYYY-MM-DD HH:mm:ss Z"
          ),
          pagina: page,
          porPagina: 45,
        },
      })
        .then((response) => {
          this.pagination.page = response.result.pagina;
          this.pagination.perPage = response.result.qtdPorPagina;
          this.pagination.total = response.result.totalItems;
          this.pagination.pages = response.result.qtdPaginas;
          this.batchsList = response.result.items;
        })
        .catch(() => {
        });
    },
    openInvoices(invoice) {
      this.$router.push({
        name: "ReportDetails",
        params: {
          razaoSocial: this.fields.cliente.valueField.razaoSocial,
          identificacaoFiscal:
            this.fields.cliente.valueField.identificacaoFiscal,
          chaveDeLote: invoice.chaveDeLote,
          statusLote: invoice.statusLote,
          dataVencimento: invoice.dataVencimento,
          pagination: this.pagination,
          selectedFilters: this.selectedFilters,
          filters: this.filters,
          integratorsList: this.integratorsList,
          clientsList: this.clientsList,
          batchsList: this.batchsList,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
