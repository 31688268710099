<template>
  <div>
    <JazzFormFields v-if="!loading" :hideButtonHeader="true" :fields="fields" />
    <JazzSkeletonLoader type="filters" v-else />
    <div v-if="details.historicoContrato.length !== 0">
      <JazzListHeader :hideButtonHeader="true" title="Histórico de contrato" />
      <JazzTable
        :pagination="pagination"
        :styleHeader="headerClass"
        :header="headerHistory"
        :rows="details.historicoContrato"
        empty-message="Nenhum histórico encontrado."
        :loading="loading"
        :hidePagination="true"
      >
        <template v-slot:reportRow="{ paramsItem }">
          <div class="box-history">
            <ContractHistoryRow :paramsItem="paramsItem" />
          </div>
        </template>
      </JazzTable>
    </div>
  </div>
</template>

<script>
import JazzTable from "@/components/table/JazzTable";
import JazzFormFields from "@/components/form/JazzFormFields";
import JazzListHeader from "@/components/template/JazzListHeader";
import globalMethods from "@/mixins/globalMethods";
import JazzSkeletonLoader from "@/components/template/JazzSkeletonLoader";
import ContractHistoryRow from "@/views/Client/Client/Account/CreditAccount/ContractHistoryRow";

export default {
  components: {
    ContractHistoryRow,
    JazzFormFields,
    JazzListHeader,
    JazzTable,
    JazzSkeletonLoader,
  },
  props: {
    details: Object,
  },
  mixins: [globalMethods],
  data() {
    return {
      fields: {},
      loading: false,
      modal: false,
      headerClass: {
        border: 0,
      },
      pagination: {
        page: 1,
        perPage: 45,
        total: 0,
        pages: 0,
      },
      headerHistory: [
        { title: "Data e hora", align: "start" },
        { title: "Agente", align: "start" },
        { title: "Descrição", align: "start" },
      ],
      history: [],
    };
  },
  methods: {
    populateFields() {
      this.fields = {
        numeroContrato: {
          field: "TextField",
          type: "text",
          label: "Número do contrato",
          cols: this.renegotiation ? 3 : 4,
          valueField: this.details?.numeroDoContrato.toString(),
          disabled: true,
        },
        tipoContrato: {
          field: "TextField",
          type: "text",
          label: "Tipo de contrato",
          cols: this.renegotiation ? 3 : 4,
          valueField: this.details?.tipoDeContrato.toString(),
          disabled: true,
        },
        modalidadeContrato: {
          field: "TextField",
          type: "text",
          label: "Modalidade",
          cols: 3,
          hideInput: !this.renegotiation,
          valueField: this.modalityContract,
          disabled: true,
        },
        statusContrato: {
          field: "TextField",
          type: "text",
          label: "Status",
          cols: this.renegotiation ? 3 : 4,
          valueField: this.statusContract,
          disabled: true,
        },
        valorParcela: {
          field: "TextField",
          type: "text",
          maskMoney: true,
          label: "Valor da parcela",
          cols: 3,
          valueField: this.money2(this.details?.valorParcela),
          disabled: true,
        },
        numeroParcelas: {
          field: "TextField",
          type: "text",
          label: "Parcelas",
          cols: 3,
          valueField: this.details?.parcelas.toString(),
          disabled: true,
        },
        parcelasPagas: {
          field: "TextField",
          type: "text",
          label: "Parcelas pagas",
          cols: 3,
          valueField: this.details?.parcelasPago.toString(),
          disabled: true,
        },
        parcelasEmAberto: {
          field: "TextField",
          type: "text",
          label: "Parcelas em aberto",
          cols: 3,
          valueField: this.details?.parcelasEmAberto.toString(),
          disabled: true,
        },
        valorContrato: {
          field: "TextField",
          type: "text",
          maskMoney: true,
          label: "Valor do contrato",
          cols: 3,
          valueField: this.money2(this.details?.valorContrato),
          disabled: true,
        },
        valorLiberado: {
          field: "TextField",
          type: "text",
          maskMoney: true,
          label: "Valor Liberado",
          cols: 3,
          valueField: this.money2(this.details?.valorLiberado),
          disabled: true,
        },
        valorPago: {
          field: "TextField",
          type: "text",
          maskMoney: true,
          label: "Valor pago",
          cols: 3,
          valueField: this.money2(this.details?.valorParcelasPago),
          disabled: true,
        },
        valorEmAberto: {
          field: "TextField",
          type: "text",
          maskMoney: true,
          label: "Valor em aberto",
          cols: 3,
          valueField: this.money2(this.details?.valorParcelasEmAberto),
          disabled: true,
        },
        taxaJuros: {
          field: "TextField",
          type: "text",
          label: "Taxa de juros",
          cols: 3,
          valueField: this.taxaJurosMensal,
          disabled: true,
        },
        dataContratacao: {
          field: "TextField",
          type: "text",
          label: "Data do contrato",
          cols: 3,
          valueField: this.dateFormat(this.details?.dataDeContratacao),
          disabled: true,
        },
        dataTermino: {
          field: "TextField",
          type: "text",
          label: "Término do contrato",
          cols: 3,
          valueField: this.dateFormat(this.details?.dataDeTermino.toString()),
          disabled: true,
        },
      };
    },
  },
  mounted() {
    this.renegotiation = this.details.tipoDeContrato === "Renegociação";
    this.populateFields();
  },
  computed: {
    statusContract() {
      let status =
        this.details.statusContratoAntecipacao.charAt(0).toUpperCase() +
        this.details.statusContratoAntecipacao.slice(1);
      return status === "Encerrado" ? "Liquidado" : status;
    },
    modalityContract() {
      return (
        this.details.modalidade.charAt(0).toUpperCase() +
        this.details.modalidade.slice(1)
      );
    },
    taxaJurosMensal() {
      return (
        this.details?.taxaDeJurosMensal.toString().replace(".", ",") +
        "% ao mês"
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.box-history {
  padding: 1rem;
  background: #ffffff !important;
  border-radius: 10px;
  border: 1px solid #c3c3c3;
  margin-bottom: 0.7rem;
}

.edit-arrow {
  color: $primaryColor;
}
</style>
