import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./services/axios";
import Vuetify from "./plugins/vuetify";
import MessageSnackbar from "./components/template/MessageSnackbar";
import { TiptapVuetifyPlugin } from "tiptap-vuetify";
import "tiptap-vuetify/dist/main.css";
import "vuetify/dist/vuetify.min.css";
import "./assets/scss/app.scss";

const vuetify = new Vuetify({
  theme: { disable: true },
});

Vue.config.productionTip = false;
Vue.component("MessageSnackbar", MessageSnackbar);
Vue.use(TiptapVuetifyPlugin, {
  vuetify,
  iconsGroup: "fa",
});

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
